import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { limitPagination, pagination } from '@stores/constants';
import { INewUserPaginated, IUserPaginationDefaultResponse } from '@stores/interfaces';
import axios from 'axios';

const initialState: INewUserPaginated = {
  data: [],
  pagination: { ...pagination }
}

export const getNewPatients = createAsyncThunk(
  'newPatients',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/users/new?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const newPatientsSlice = createSlice({
  name: 'newPatients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewPatients.fulfilled, (state, action: PayloadAction<INewUserPaginated>) => {
      state.data = action.payload.data
      state.pagination = action.payload.pagination
    })
  }
})

export default newPatientsSlice.reducer