import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import './style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	IProfileData,
	ProfileModalProps,
	TProfileData,
} from '@stores/interfaces';
import { getSelectedUser, getUser } from '@stores/user';
import ProfileForm from './ProfileForm';
import { UseAuth } from '@contexts/AuthContext';

const ProfileModal = (props: ProfileModalProps) => {
	const dispatch = useTypedDispatch();
	const {
		isModalOpen,
		setIsModalOpen,
		policyModalOpen,
		closable,
		setPolicyModalOpen,
	} = props;
	const { user, selectedUser } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.user.isPhysioterapist
			? state.contacts.main.selectedUser
			: state.user,
	}));
	const [functionalGoalsList, setFunctionalGoalsList] = useState<number[]>([]);
	const [userFormData, setUserFormData] = useState<TProfileData>({
		firstName: selectedUser.profile.firstName,
		lastName: selectedUser.profile.lastName,
		email: selectedUser.profile.email,
		imageUrl: selectedUser.profile?.imageUrl,
		birthDate: selectedUser.profile.birthDate,
		gender: selectedUser.profile?.gender,
		imperialHeight: selectedUser.profile?.imperialHeight,
		metricHeight: selectedUser.profile?.metricHeight,
		imperialWeight: selectedUser.profile?.imperialWeight,
		metricWeight: selectedUser.profile?.metricWeight,
		isPregnant: false,
		functionalGoals: !user.isPhysioterapist
			? user.functionalGoals?.[user.functionalGoals.length - 1]
				?.functionalGoalsIds
			: functionalGoalsList,
		consentPolicyRead: selectedUser.profile?.consentPolicyRead,
		patientId: selectedUser.profile?.patientId,
	});

	const [profileData, setProfileData] = useState<IProfileData | undefined>();
	const { credentials } = UseAuth();

	const getProfileData = async () => {
		if (user.isPhysioterapist) {
			const apiResponse = await dispatch(getSelectedUser());
			setProfileData(apiResponse.payload);
		} else {
			if (credentials?.sub) {
				const data = await dispatch(getUser(credentials?.sub));
				setProfileData(data.payload);
			}
		}
	};

	useEffect(() => {
		getProfileData();
	}, []);

	useEffect(() => {
		if (
			profileData &&
			profileData.functionalGoals &&
			profileData.functionalGoals.length > 0
		) {
			if (profileData?.functionalGoals?.length > 0) {
				const lastIndex = profileData?.functionalGoals?.length - 1;
				const data =
					profileData?.functionalGoals[lastIndex]?.functionalGoalsIds;
				setFunctionalGoalsList(data);
				setUserFormData({
					...userFormData,
					...{ ['functionalGoals']: data },
				});
			} else {
				setFunctionalGoalsList([]);
			}
		}
	}, [profileData, selectedUser]);

	useEffect(() => {
		if (credentials?.sub) {
			dispatch(getUser(credentials?.sub));
		}
	}, [dispatch]);


	return (
		<Modal
			open={isModalOpen}
			width={policyModalOpen ? 960 : "max-content"}
			footer={null}
			destroyOnClose
			closable={closable}
			maskClosable={false}
			onCancel={() => {
				setIsModalOpen(false);
				setPolicyModalOpen(false);
			}}
			style={policyModalOpen ? {} : { height: '700px', userSelect: 'none', top: 20 }}
			bodyStyle={
				policyModalOpen
					? { overflowY: 'auto', maxHeight: 'calc(100vh - 140px)' }
					: {}
			}
			className={`${policyModalOpen ? 'modal-container' : ''}`}>
			<ProfileForm
				policyModalOpen={policyModalOpen}
				setPolicyModalOpen={setPolicyModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
		</Modal>
	);
};

export default ProfileModal;
