export const FEATURES_MOCK = [
    {
      title: "Recovery & Managing Discomfort",
      backgroundStyle: "linear-gradient(135deg, rgba(105, 65, 198, 0.9), rgba(84, 205, 242, 0.9))",
      hoverBackgroundStyle: "linear-gradient(45deg, rgba(84, 205, 242, 0.9), rgba(105, 65, 198, 0.9))",
      description: "Restore ROM, Strength, and Endurance Post-Injury, Pre/Post-Surgical Rehabilitation",
      imageSrc: "/images/dashboard/FunctionalGoalCard-1.png",
    },
    {
      title: "Functional Independence & Daily Living",
      backgroundStyle: "linear-gradient(135deg, rgba(243, 33, 215, 0.9), rgba(255, 236, 97, 0.9))",
      hoverBackgroundStyle: "linear-gradient(45deg, rgba(255, 236, 97, 0.9), rgba(243, 33, 215, 0.9))",
      description: "Improve ADLs, Balance & Gait Training, Fall Prevention",
      imageSrc: "/images/dashboard/FunctionalGoalCard-2.png",
    },
    {
      title: "Performance Enhancement & Injury Prevention",
      backgroundStyle: "linear-gradient(135deg, rgba(154, 78, 255, 0.9), rgba(36, 255, 114, 0.9))",
      hoverBackgroundStyle: "linear-gradient(45deg, rgba(36, 255, 114, 0.9), rgba(154, 78, 255, 0.9))",
      description: "Injury Prevention, Sports-Specific Training & Performance Optimization",
      imageSrc: "/images/dashboard/FunctionalGoalCard-3.png",
    },
    {
      title: "Holistic Well-being & Quality of Life",
      backgroundStyle: "linear-gradient(135deg, rgba(118, 116, 189, 1), rgba(255, 236, 97, 1))",
      hoverBackgroundStyle: "linear-gradient(45deg, rgba(118, 116, 189, 1), rgba(255, 236, 97, 1))",
      description: "Improve Overall Wellness & Stress Management, Enhance Quality of Life, Improve Cardiovascular and Respiratory Function, Weight Management",
      imageSrc: "/images/dashboard/FunctionalGoalCard-4.png",
    },
  ];