import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MyTemplate from './MyTemplate';
import { Check } from '@carespace-icons/general/check';
import SystemTemplate from './SystemTemplate';
import './RomTemplate.css';
import AddRomPopup from './AddRomPopup';
import { ICustomRomTemplate, IRomProgramExercise, IRomTemplateExercise } from '@stores/interfaces';

interface IRomTemplate {
  onCancel: () => void
  refresh: boolean;
  setRefresh: (value: boolean) => void
}

const RomTemplate = (props: IRomTemplate) => {
  const { refresh, setRefresh, onCancel } = props
  const { t } = useTranslation();
  const [selectedRom, setSelectedRom] =
    useState<ICustomRomTemplate | null>();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState<
    IRomProgramExercise[]
  >([]);

  const handleCancel = () => {
    setSelectedExercises([]);
    setSelectedRom(null);
    setModalVisible(false);
  };

  const extraContent = (data) => {
    const isSelected = selectedRom?.id === data.id;

    return (
      <div
        className="extra-content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <span
          onClick={() => {
            if (isSelected) setSelectedRom(null);
            else {
              if (!('clientId' in data)) {
                data = {
                  ...data,
                  exercises: data?.exercises?.map((item: any) => ({
                    ...item,
                    strapiExerciseId:
                      typeof item?.strapiExerciseId === 'number'
                        ? item?.strapiExerciseId
                        : item?.strapiExerciseId?.id!,
                    image:
                      typeof item?.strapiExerciseId !== 'number'
                        ? item?.strapiExerciseId?.exercise_image[0]?.url!
                        : '',
                    name:
                      typeof item?.strapiExerciseId !== 'number'
                        ? item?.strapiExerciseId?.name!
                        : '',
                  })),
                };
              }
              setSelectedRom(data);
              setSelectedExercises(data?.exercises)
              setModalVisible(true);
            }
          }}
          className={`select-button ${isSelected ? 'bg-selected' : 'bg-unselected'
            }`}
        >
          <Check
            width={20}
            height={20}
            color={
              selectedRom?.id === data.id
                ? 'stroke-white'
                : 'stroke-black'
            }
            className={`check-icon ${isSelected ? 'selected' : 'unselected'
              }`}
          />
          {isSelected
            ? t('Admin.data.menu.aiAssistant.aiAssistantPrograms.selected')
            : t('Admin.data.menu.aiAssistant.aiAssistantPrograms.select')}
        </span>
      </div>
    );
  };

  return (
    <>
      <Tabs
        type="card"
        className="rom-template main-tabs mainTabs select-none p-4"
        style={{backgroundColor:'var(--collapse-bg-color)'}}
        tabBarStyle={{ margin: 0 }}
        items={[
          {
            label: t(
              'Admin.data.menu.aiAssistant.aiAssistantPrograms.myTemplates',
            ),
            key: '1',
            children:
              <MyTemplate extra={extraContent} refresh={refresh} />
          },
          {
            label: t(
              'Admin.data.menu.aiAssistant.aiAssistantPrograms.systemTemplates',
            ),
            key: '2',
            children:
              <SystemTemplate extra={extraContent} />,
          },
        ]}
      />
      {isModalVisible && <AddRomPopup
        isVisible={isModalVisible}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRom(null);
        }}
        onOk={() => {
          setModalVisible(false);
          setSelectedExercises([]);
          handleCancel();
          setRefresh(!refresh);
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedExercises={selectedExercises}
        setSelectedExercises={setSelectedExercises}
        selectedRom={selectedRom!}
        outerModal={onCancel}
      />}
    </>
  );
};

export default RomTemplate;
