import { useCallback, useEffect, useState } from 'react';
import { PosturalAnalytics, UseControls } from '../context/Controls.context';
import { TransitionStates, UseTransition } from '../context/Transition.context';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import EventEmitter from '@services/EventEmitter';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { router } from '@routers/routers';
import { useTypedDispatch } from '@stores/index';
import { setOnBoardCompletion } from '@stores/onBoard/onBoard';

function Controls(props: { isDashboard?: boolean }) {
	const navigate = useNavigate();
	const { isDashboard } = props;
	const { t } = useTranslation();
	const { transition } = UseTransition();
	const {
		onRepeat,
		onSubmit,
		onGetCurrent,
		processAndCapturePosture,
		postureAnalyticsSessionId,
		results,
		isCompleted,
	} = UseControls();
	const dispatch = useTypedDispatch();

	const { userId } = useParams();

	const current = onGetCurrent() as Partial<PosturalAnalytics>;

	const frameCallback = useCallback(
		(results: NormalizedLandmark[]) => {
			if (transition?.value === TransitionStates.CLOSING) {
				processAndCapturePosture(results);
			}
		},
		[transition, processAndCapturePosture],
	);

	useEffect(() => {
		EventEmitter.once('results', results => {
			frameCallback(results);
		});

		return () => {
			EventEmitter.off('results');
		};
	}, [frameCallback]);

	useEffect(() => {
		results && onSubmit();
	}, [results]);

	useEffect(() => {
		if (isCompleted && !isDashboard) {
			navigate(`/${userId}${router.AIASSISTANT_POSTURE_SUMMARY}`, {
				state: { id: postureAnalyticsSessionId, replace: true },
			});
		} else if (isCompleted && isDashboard) {
			dispatch(setOnBoardCompletion(true));
		}
	}, [isCompleted, navigate, userId]);

	if (current?.completed || transition?.value !== TransitionStates.RESULT)
		return null;

	return (
		<></>
		// <>
		// 	<Button
		// 		onMouseEnter={() => {
		// 			setHoverTryAgain(true);
		// 		}}
		// 		onMouseLeave={() => {
		// 			setHoverTryAgain(false);
		// 		}}
		// 		onClick={onRepeatPosition}
		// 		style={{
		// 			height: '35px',
		// 			border: 0,
		// 			borderRadius: 0,
		// 			backgroundColor: hoverTryAgain ? '#f12760' : '#E31B54',
		// 			color: '#ffffff',
		// 		}}
		// 		icon={
		// 			<MdHighlightOff
		// 				size={22}
		// 				style={{
		// 					verticalAlign: 'middle',
		// 					marginRight: 5,
		// 				}}
		// 				color="#ffffff"
		// 			/>
		// 		}>
		// 		{t('Patient.data.omnirom.tryAgain')}
		// 	</Button>
		// 	<Button
		// 		onMouseEnter={() => {
		// 			setHoverContinue(true);
		// 		}}
		// 		onMouseLeave={() => {
		// 			setHoverContinue(false);
		// 		}}
		// 		style={{
		// 			height: '35px',
		// 			border: 0,
		// 			borderRadius: 0,
		// 			backgroundColor: hoverContinue ? '#31d373' : '#01c752',
		// 			color: '#ffffff',
		// 		}}
		// 		onClick={onSubmit}
		// 		icon={
		// 			<MdCheckCircleOutline
		// 				size={22}
		// 				style={{
		// 					verticalAlign: 'middle',
		// 					marginRight: 5,
		// 				}}
		// 				color="#ffffff"
		// 			/>
		// 		}>
		// 		{t('Patient.data.omnirom.submitToConitinue')}
		// 	</Button>
		// </>
	);
}

export default Controls;
