import Loading from '@atoms/APLoading';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ICustomRomExercise } from '@stores/interfaces';
import { ETransitions, setPoseData, TTransitions } from '@stores/rom/main';
import usePrevious from '@utils/usePrevious.hook';
import { Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { memo, useCallback, useEffect, useState } from 'react';
import Camera from './camera';
import Symetrograph from './Components/Symetrograph';
import Mediapipe from './mediapipe';
import MediapipeOld from './mediapipeOld';

const limit = 0.6;

interface IVideoPoseEstimatorProps {
	isCompleted: boolean;
	currentExercise: ICustomRomExercise | null;
	transition: TTransitions | null;
	cameraId: string | null;
	loading: boolean;
	onBodyPointsVisible: (param: boolean) => void;
	onExerciseValueAndCoordinates: (
		value: number,
		ccordinates: NormalizedLandmark[],
	) => void;
	onLoading: () => void;
	onNextTransition: (param: TTransitions) => void;
	onShowIncorrectSide?: (param: boolean) => void;
	selfieMode?: boolean;
	isSwitchMode?: boolean;
	isFullscreen: boolean;
}
let latestHandResults: {
	handmarkLeft?: NormalizedLandmark[];
	handmarkRight?: NormalizedLandmark[];
} = {};

function VideoPoseEstimator(props: IVideoPoseEstimatorProps) {
	const [bodyPointsVisible, setBodyPointsVisible] = useState<boolean>(false);
	const [angleValue, setAngleValue] = useState<number>(0);
	const [coordinates, setCoordinates] = useState<NormalizedLandmark[]>([]);
	const [debuggerEnabled, setDebuggerEnabled] = useState<boolean>(false);

	const { isCustom } = useTypedSelector(state => state.rom.main);
	const dispatch = useTypedDispatch();
	const prevBodyPointsVisible = usePrevious(bodyPointsVisible);

	const {
		currentExercise,
		isFullscreen,
		onBodyPointsVisible,
		onExerciseValueAndCoordinates,
		transition,
		onNextTransition,
		isSwitchMode,
		cameraId,
		isCompleted,
	} = props;

	const isIpad =
		/iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;

	const isRightHand = currentExercise?.name?.toLowerCase()?.includes('right');

	const isWristExercise = currentExercise?.name
		?.toLowerCase()
		?.includes('wrist');

	const getWristValue = (exerciseName: string | undefined) => {
		if (
			exerciseName?.toLowerCase()?.includes('flexion') ||
			exerciseName?.toLowerCase()?.includes('extension')
		) {
			return 8;
		} else if (
			exerciseName?.toLowerCase()?.includes('supination') ||
			exerciseName?.toLowerCase()?.includes('pronation')
		) {
			return 4;
		} else if (
			exerciseName?.toLowerCase()?.includes('radial') ||
			exerciseName?.toLowerCase()?.includes('ulnar')
		) {
			return 12;
		}
		return 0;
	};

	const calculeAngle = useCallback(
		(results: NormalizedLandmark[]) => {
			if (isCustom) {
				if (bodyPointsVisible && currentExercise) {
					currentExercise?.exercises?.map((bodypoint, index) => {
						const defaultValue = { value: 0 };
						let a = defaultValue,
							b = defaultValue,
							c = defaultValue,
							d = defaultValue;
						if (bodypoint?.strapiOmniRomExercise?.pointsToValidatePosition) {
							({ a, b, c, d } =
								bodypoint?.strapiOmniRomExercise?.pointsToValidatePosition);
						}

						const { handmarkLeft, handmarkRight } = latestHandResults;
						const hand = isRightHand ? handmarkRight : handmarkLeft;

						let angleValueCalculated;
						if (isWristExercise) {
							const value = getWristValue(currentExercise?.name);

							if (hand) {
								angleValueCalculated = bodypoint?.strapiOmniRomExercise
									?.function
									? eval(`(${bodypoint?.strapiOmniRomExercise?.function})`)([
											results[a?.value],
											results[b?.value],
											hand[value],
										])
									: 0;
							} else {
								angleValueCalculated = bodypoint?.strapiOmniRomExercise
									?.function
									? eval(`(${bodypoint?.strapiOmniRomExercise?.function})`)(
											[results[a?.value], results[b?.value], results[c?.value]],
											{
												width: 1280,
												height: 720,
											},
										)
									: 0;
							}
							if (debuggerEnabled) setAngleValue(angleValueCalculated);
						} else {
							angleValueCalculated = bodypoint?.strapiOmniRomExercise?.function
								? eval(`(${bodypoint?.strapiOmniRomExercise?.function})`)(
										[
											results[a?.value],
											results[b?.value],
											results[c?.value],
											results[d?.value],
										],
										{
											width: 1280,
											height: 720,
										},
									)
								: 0;
							if (debuggerEnabled) setAngleValue(angleValueCalculated);
						}

						let result: number;
						if (
							angleValueCalculated !== undefined &&
							angleValueCalculated !== null &&
							!isNaN(angleValueCalculated)
						) {
							result = Math.floor(angleValueCalculated);
						} else {
							result = 0;
						}
						if (debuggerEnabled) setAngleValue(angleValueCalculated);
						setCoordinates(results);
						dispatch(
							setPoseData({
								index: index,
								angleResult: result,
								coordinates: results,
							}),
						);
					});
				}
			} else {
				if (bodyPointsVisible && currentExercise) {
					const { a, b, c, d } = currentExercise?.pointsToCalculateAngle!;

					let angleValueCalculated;
					if (currentExercise?.name?.toLowerCase()?.includes('wrist')) {
						const { handmarkLeft, handmarkRight } = latestHandResults;
						let hand;
						if (currentExercise?.name?.toLowerCase()?.includes('right')) {
							hand = handmarkRight;
						} else {
							hand = handmarkLeft;
						}
						const value = getWristValue(currentExercise?.name);

						if (hand) {
							angleValueCalculated = eval(`(${currentExercise?.function})`)([
								results[a?.value],
								results[b?.value],
								hand[value],
							]);
						}
					} else {
						angleValueCalculated = eval(`(${currentExercise?.function})`)(
							[
								results[a?.value],
								results[b?.value],
								results[c?.value],
								results[d?.value],
							],
							{
								width: 1280,
								height: 720,
							},
						);
					}

					const result = Math.floor(+angleValueCalculated);

					if (result !== angleValue) {
						setAngleValue(result);
						setCoordinates(results);
					}
				} else {
					setAngleValue(0);
					setCoordinates(results);
				}
			}
		},
		[bodyPointsVisible, currentExercise],
	);

	const checkBodyIsVisible = useCallback(
		(results: NormalizedLandmark[]) => {
			if (isCustom) {
				if (currentExercise) {
					const validateBodyPoints = currentExercise?.exercises?.every(
						bodyPoint => {
							const defaultValue = { value: 0 };
							let a = defaultValue,
								b = defaultValue,
								c = defaultValue;
							if (bodyPoint?.strapiOmniRomExercise?.pointsToValidatePosition) {
								({ a, b, c } =
									bodyPoint?.strapiOmniRomExercise?.pointsToValidatePosition);
							}

							const isVisible =
								(results[a.value]?.visibility! > limit &&
									results[b.value]?.visibility! > limit &&
									results[c.value]?.visibility! > limit) ||
								false;

							return isVisible;
						},
					);

					setBodyPointsVisible(validateBodyPoints || false);
				}
			} else {
				if (currentExercise) {
					const { a, b, c } = currentExercise?.pointsToValidatePosition!;
					const isVisible =
						(results[a.value]?.visibility! > limit &&
							results[b.value]?.visibility! > limit &&
							results[c.value]?.visibility! > limit) ||
						false;
					setBodyPointsVisible(isVisible);
				} else {
					setBodyPointsVisible(true);
				}
			}
		},
		[currentExercise],
	);

	const frameCallback = useCallback(
		(results: NormalizedLandmark[]) => {
			if (transition?.value === ETransitions.CALIBRATION) {
				checkBodyIsVisible(results);
			}
			if (transition?.value === ETransitions.READYSETGO) {
				calculeAngle(results);
			}
		},
		[transition],
	);

	useEffect(() => {
		window.removeEventListener('results', () => {});

		window.addEventListener('results', event => {
			const results = (event as CustomEvent).detail?.results;
			frameCallback(results);
		});

		return () => {
			window.removeEventListener('results', () => {});
		};
	}, [frameCallback]);

	useEffect(() => {
		window.removeEventListener('handmarks', () => {});
		window.addEventListener('handmarks', event => {
			const handData = (event as CustomEvent).detail;
			latestHandResults = handData;
		});

		return () => {
			window.removeEventListener('handmarks', () => {});
		};
	}, []);

	useEffect(() => {
		if (bodyPointsVisible !== prevBodyPointsVisible) {
			onBodyPointsVisible(bodyPointsVisible);
		}
	}, [prevBodyPointsVisible, bodyPointsVisible, onBodyPointsVisible]);

	useEffect(() => {
		if (transition?.value === ETransitions.CLOSING) {
			onExerciseValueAndCoordinates(angleValue, coordinates);
		}
	}, [transition, onExerciseValueAndCoordinates, angleValue, coordinates]);

	useEffect(() => {
		if (transition?.value === ETransitions.INTRO) {
			setAngleValue(0);

			if (!isIpad) {
				setBodyPointsVisible(false);
				setCoordinates([]);
			}
		}
	}, [transition, isIpad]);

	useEffect(() => {
		if (transition?.value === ETransitions.CALIBRATION && bodyPointsVisible) {
			onNextTransition(transition.next!);
		}
	}, [transition, bodyPointsVisible, onNextTransition]);

	// const MediapipeComponent = isWristExercise ? Mediapipe : MediapipeOld;
	const MediapipeComponent = Mediapipe;

	return (
		<Content>
			{!currentExercise && !isCompleted ? (
				<Loading />
			) : (
				<div id="printscreen" style={{height:'100%'}}>
					{(transition?.value == ETransitions.READYSETGO ||
						transition?.value == ETransitions.CLOSING) && (
						<Symetrograph isFullscreen={isFullscreen} />
					)}
					<Typography.Title
						style={{
							aspectRatio: '16/9',
							display: currentExercise ? 'block' : 'none',
							width: isSwitchMode ? '420px' : '100%',
							zIndex: isSwitchMode ? 3 : 2,
							position: 'relative',
						}}>
						<MediapipeComponent
							key={currentExercise?.id || 'default'}
							cameraId={cameraId}
							isFullscreen={isFullscreen}
							isSwitchMode={isSwitchMode!}
							isRightHand={isRightHand}
							wristExercise={isWristExercise}
							stopLandmarks={
								isIpad
									? (transition?.value as ETransitions) === ETransitions.INTRO
									: false
							}
						/>
					</Typography.Title>

					{debuggerEnabled && isCustom && (
						<Typography.Title
							level={1}
							style={{
								position: 'absolute',
								top: '22%',
								right: '10%',
								color: '#fff',
								fontSize: '100px',
								fontWeight: 300,
								zIndex: 2,
							}}>
							{Math.floor(angleValue || 0)}
							<Typography.Text
								style={{
									position: 'absolute',
									top: '12px',
									fontWeight: 300,
									color: '#fff',
									fontSize: '50px',
								}}>
								º
							</Typography.Text>
						</Typography.Title>
					)}

					{!isCustom && transition?.value === ETransitions.READYSETGO && (
						<Typography.Title
							level={1}
							style={{
								position: 'absolute',
								top: '22%',
								right: '10%',
								color: '#fff',
								fontSize: '100px',
								fontWeight: 300,
								zIndex: 2,
							}}>
							{Math.floor(angleValue || 0)}
							<Typography.Text
								style={{
									position: 'absolute',
									top: '12px',
									fontWeight: 300,
									color: '#fff',
									fontSize: '50px',
								}}>
								º
							</Typography.Text>
						</Typography.Title>
					)}
				</div>
			)}
		</Content>
	);
}

export default memo(VideoPoseEstimator);
