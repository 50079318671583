import { Button, Col, Divider, Row, Space, Tooltip } from 'antd'
import { useRef } from 'react'
import { MdFullscreen, MdFullscreenExit, MdOutlineSwitchCamera } from 'react-icons/md'
import RatingFeedback from '@molecules/MPVideoRecord/ratingFeedback'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import checkLogo from './VoiceRecordingLogo.json'
import CountDownTimer from '@atoms/CountDownTimer'
import { CheckCircle } from '@carespace-icons/general/checkCircle'
import { XCircle } from '@carespace-icons/general/xCircle'
import { VideoRecorder } from '@carespace-icons/media/videoRecorder'
import { StopCircle } from '@carespace-icons/media/stopCircle'
import { useTranslation } from 'react-i18next'
import { IRehabVideoHeaderDataProps, RehabVideoState } from '@stores/interfaces'
import { adminTimeLimit, patientTimeLimit } from '@stores/constants'
import { useTypedSelector } from '@stores/index'

const controlInstructions = {
	[RehabVideoState.START.toString()]: { text1: "Say 'RECORD' or 'YES' to start recording", text2: "Say 'RECORD' or 'YES' or wave with an open hand to start recording", icon: <img src={`/images/openHand.svg`} /> },
	[RehabVideoState.RECORDING.toString()]: { text1: "Say 'STOP' to stop the recording", text2: "Say 'STOP' or extend an open hand to stop the recording", icon: <img src={`/images/openHand.svg`} /> },
	[RehabVideoState.REPLAYING.toString()]: {
		text1: "Say 'NO' to redo or 'YES' to save the recording", text2: "Say 'NO' to redo or 'YES' to save, or gesture 'THUMBS DOWN' or 'THUMBS UP'", icon: <div>
			<img src={`/images/thumbDown.svg`} />
			<img src={`/images/thumbUp.svg`} />
		</div>
	},
	[RehabVideoState.RATING.toString()]: { text1: "Choose a number and say it aloud", text2: "Choose a number and say it aloud." },
};

export default function RehabHeaderContent(props: IRehabVideoHeaderDataProps) {
  const {videoState,onStartRecord,onStopRecord, onDiscardRecord , onStopRating, selectedRating , setSelectedRating , onFullscreen , isGestureEnabled, onSubmitRecord , isStartingTimer , onEndedTimer,
    toogleFacingMode , isFullscreen  , flipCamera} = props
  const micRef = useRef<LottieRefCurrentProps>(null)
  const { isPhysioterapist } = useTypedSelector((state) => state.rehab.main)
	const { t } = useTranslation()
  const timeLimit = isPhysioterapist ? adminTimeLimit : patientTimeLimit

  return (
  	<Row
					justify="end"
					style={{ textAlign: 'end', lineHeight: '35px' }}
				>
					<Col
						span={24}
						style={{
							height: '35px',
							display: 'flex',
							justifyContent: 'end',
							alignItems: 'center',
						}}
					>
						{videoState === RehabVideoState.READY && (
							<Tooltip title={t('Patient.data.rehab.getReady')}>
								<Button
									style={{
										height: '35px',
										border: 0,
										borderRadius: 0,
										backgroundColor: '#e29b0e',
										color: '#ffffff',
									}}
								>
									{t('Patient.data.rehab.getReady')}
								</Button>
							</Tooltip>
						)}

						{videoState === RehabVideoState.RECORDING && (
							<Tooltip title={t('Patient.data.rehab.stop')}>
								<Button
									onClick={onStopRecord}
									style={{
										height: '35px',
										border: 0,
										borderRadius: 0,
										backgroundColor: '#E31B54',
										color: '#ffffff',
									}}
								>
									<Space align='center'>
										<StopCircle width={21} height={21} />
										{t('Patient.data.rehab.stop')}
									</Space>
								</Button>
							</Tooltip>
						)}

						{videoState === RehabVideoState.START && (
							<Tooltip title={t('Patient.data.rehab.record')}>
								<Button
									onClick={onStartRecord}
									style={{
										height: '35px',
										border: 0,
										borderRadius: 0,
										backgroundColor: '#01c752',
										color: '#ffffff',
									}}
								>
									<Space align='center'>
										<VideoRecorder width={21} height={21} />
										{t('Patient.data.rehab.record')}
									</Space>
								</Button>
							</Tooltip>
						)}

						{videoState === RehabVideoState.RATING && (
							<div className='bg-black bg-opacity-60 absolute z-10 top-60 right-0 py-4 px-7 rounded-s-full'>
								<RatingFeedback onStopRating={onStopRating} selectedRating={selectedRating} setSelectedRating={setSelectedRating} />
							</div>
						)}
						{
							controlInstructions[videoState] &&
							<div className='bg-black flex justify-end gap-4 items-center bg-opacity-60 max-w-[500px] absolute z-10 top-20 right-0 py-4 px-7 rounded-s-full'>
								<div>
									<div className='flex justify-end items-center'>
										<div className="flex items-center justify-center align-middle" style={{ height: "50px", width: "150px" }}>
											<Lottie
												lottieRef={micRef}
												animationData={checkLogo}
												loop={true}
												autoplay={true}
											/>
										</div>
										<img src="/images/microphone.svg" />
									</div>
									<p className="font-semibold text-lg text-white">{isGestureEnabled ? controlInstructions[videoState]?.text2 : controlInstructions[videoState]?.text1}</p>
								</div>
								{isGestureEnabled && controlInstructions[videoState]?.icon}
							</div>
						}

						{videoState === RehabVideoState.REPLAYING &&
							(
								<>
									<Tooltip title={t('Patient.data.rehab.discard')}>
										<Button
											onClick={onDiscardRecord}
											style={{
												height: '35px',
												border: 0,
												borderRadius: 0,
												backgroundColor: '#E31B54',
												color: '#ffffff',
											}}
										>
											<Space align='center'>
												<XCircle width={21} height={21} />
												{t('Patient.data.rehab.discard')}
											</Space>
										</Button>
									</Tooltip>
									<Tooltip title={t('Patient.data.rehab.submit')}>
										<Button
											onClick={onSubmitRecord}
											style={{
												height: '35px',
												border: 0,
												borderRadius: 0,
												backgroundColor: '#01c752',
												color: '#ffffff',
											}}
										>
											<Space align='center'>
												<CheckCircle width={21} height={21} />
												{t('Patient.data.rehab.submit')}
											</Space>
										</Button>
									</Tooltip>
								</>
							)}
						{
							videoState === RehabVideoState.RATING ? '' :
								<Tooltip title={t('Patient.data.rehab.record')}>
									<CountDownTimer limit={timeLimit} isStartingTimer={isStartingTimer} endedTimer={onEndedTimer} />
								</Tooltip>
						}
						<Divider
							type="vertical"
							style={{ height: '34px', borderColor: '#1B1B1B' }}
						/>
						<Tooltip title="Fullscreen">
							{isFullscreen ? (
								<MdFullscreenExit
									size={30}
									style={{
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
									onClick={onFullscreen}
									color='#5e5e5e'
								/>
							) : (
								<MdFullscreen
									size={30}
									style={{
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
									onClick={onFullscreen}
									color='#5e5e5e'
								/>
							)}
						</Tooltip>
						<Divider
							type="vertical"
							style={{ height: '34px', borderColor: '#1B1B1B' }}
						/>
						{flipCamera && (
							<>
								<Tooltip title={t('Patient.data.omnirom.flipCamera')}>
									<MdOutlineSwitchCamera
										size={30}
										style={{
											verticalAlign: 'middle',
											cursor: 'pointer',
										}}
										onClick={toogleFacingMode}
									/>
								</Tooltip>
								<Divider
									type="vertical"
									style={{ height: '34px', borderColor: '#1B1B1B' }}
								/>
							</>
						)}
					</Col>
				</Row>
  )
}
