import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { limitPagination, pagination } from '@stores/constants';
import { IStatusStoreState, ISessionResponsePayload, IUpdateSessionStatusResponsePayload, IUpdateStatusBody, IUserPaginationDefaultResponse, Status, IUpdateProgramStatusResponsePayload } from '@stores/interfaces';
import axios from 'axios';

const initialState: IStatusStoreState = {
  omnirom: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  rehab: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  evaluation: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  survey: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  postures: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  }
}

export const getOmniRomOutOfParams = createAsyncThunk(
  'rom/outOfParams',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/rom/sessions/status/${Status.outOfParams}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateOmniRomSessionStatus = createAsyncThunk(
  'rom/outOfParams/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/rom/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getRehabOutOfParams = createAsyncThunk(
  'rehab/outOfParams',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/program/sessions/status/${Status.outOfParams}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateRehabSessionStatus = createAsyncThunk(
  'rehab/outOfParams/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/program/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getEvaluationOutOfParams = createAsyncThunk(
  'evaluation/outOfParams',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/evaluation/sessions/status/${Status.outOfParams}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateEvaluationSessionStatus = createAsyncThunk(
  'evaluation/outOfParams/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/evaluation/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getSurveyOutOfParams = createAsyncThunk(
  'survey/outOfParams',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/survey/sessions/status/${Status.outOfParams}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateSurveySessionStatus = createAsyncThunk(
  'survey/outOfParams/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/survey/sessions/${sessionId}/status`, body)
    return data
  }
)

export const updatePostureSessionStatus = createAsyncThunk(
  'posture-analytics/outOfParams/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/posture-analytics/sessions/${sessionId}/status`, body)
    return data
  }
)

export const outOfParamsSlice = createSlice({
  name: 'outOfParams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOmniRomOutOfParams.pending, (state) => {
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(getOmniRomOutOfParams.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.omnirom.users = action.payload.data
      state.omnirom.pagination = action.payload.pagination
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(updateOmniRomSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.omnirom.statusToRefresh = [action.payload.status, Status.outOfParams]
    })

    builder.addCase(getRehabOutOfParams.pending, (state) => {
      state.rehab.statusToRefresh = []
    })

    builder.addCase(getRehabOutOfParams.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.rehab.users = action.payload.data
      state.rehab.pagination = action.payload.pagination
      state.rehab.statusToRefresh = []
    })

    builder.addCase(updateRehabSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateProgramStatusResponsePayload>) => {
      state.rehab.statusToRefresh = [action.payload.status, Status.outOfParams]
    })

    builder.addCase(getEvaluationOutOfParams.pending, (state) => {
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(getEvaluationOutOfParams.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.evaluation.users = action.payload.data
      state.evaluation.pagination = action.payload.pagination
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(updateEvaluationSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.evaluation.statusToRefresh = [action.payload.status, Status.outOfParams]
    })

    builder.addCase(getSurveyOutOfParams.pending, (state) => {
      state.survey.statusToRefresh = []
    })

    builder.addCase(getSurveyOutOfParams.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.survey.users = action.payload.data
      state.survey.pagination = action.payload.pagination
      state.survey.statusToRefresh = []
    })

    builder.addCase(updateSurveySessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.survey.statusToRefresh = [action.payload.status, Status.outOfParams]
    })

    builder.addCase(updatePostureSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.postures.statusToRefresh = [action.payload.status, Status.outOfParams]
    })
  }
})

export default outOfParamsSlice.reducer