import CheckBox from "@atoms/CheckBox"
import { SearchOutlined } from '@ant-design/icons';
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { clearReports, createSelectedReport, getReport, getUserReports, setSearchTextForReports, updateReport } from "@stores/report/reports"
import { Button, Empty, Input, Popover, Tag, message } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdOutlineAssignment } from "react-icons/md"
import { Check } from "@carespace-icons/general/check";
import { Plus } from "@carespace-icons/general/plus";
import { FiUpload } from "react-icons/fi";
import './style.less'
import { setIsReportModal } from "@stores/aiAssistant/aiAssistant";
import { useNavigate } from "react-router-dom";
import { router } from '@routers/routers';
interface AddToReportsProps {
  setIsVisible?: (value: boolean) => void
}
export const AddToReports = (props: AddToReportsProps) => {
  const { setIsVisible } = props
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [isCreate, setIsCreate] = useState(false)
  const [open, setOpen] = useState(false);
  const [newReportName, setNewRepoprtName] = useState<string>()
  const reports = useTypedSelector(state => state.reports.reports?.data)
  const report = useTypedSelector(state => state.reports.report)
  const [reportId, setReportId] = useState<string>('');
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const user = useTypedSelector((state) => state.user)
  const reportIds = useTypedSelector(state => state.reports.reportIds)
  const reportCount = Object.keys(reportIds).filter(key => key !== 'romResultsIds').flatMap(key => reportIds[key]).length;
  const isReportModal = useTypedSelector(state => state.aiAssistant.aiAssistant.isReportModal)
  const navigate = useNavigate();
  const isDark = document.documentElement.getAttribute("data-theme") === 'vibrant'

  useEffect(() => {
    setReportId('')
  }, [user, selectedUser])

  const handleReportSummary = () => {
    navigate(`/${user.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_MY_REPORT}`);
  }

  const handleCreate = async () => {
    if (!newReportName) {
      message.error(t('Admin.data.addToReports.reportNameErr'));
    }
    else {
      const payload = {
        userId: user.isPhysioterapist ? selectedUser.id : user.id,
        name: newReportName,
        romSessionsIds: { connect: reportIds?.romSessionsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
        romResultsIds: { connect: reportIds?.romResultsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
        programSessionsIds: { connect: reportIds?.programSessionsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
        evaluationSessionsIds: { connect: reportIds?.evaluationSessionsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
        surveyResultIds: { connect: reportIds?.surveyResultIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
        postureSessionsIds: { connect: reportIds?.postureSessionsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [] },
      }
      const data = await dispatch(createSelectedReport(payload))
      if (data.payload) {
        message.success({
          content: (
            <>
              <span>{t('Admin.data.addToReports.reportCreated')}</span>
              <div style={{ marginTop: '8px' }}>
                {!isReportModal ? <Button onClick={handleReportSummary} style={{ marginLeft: '10px' }}>
                  {t('Admin.data.addToReports.summaryButton')}
                </Button> : null}
              </div>
            </>
          ),
          duration: 5,
        });
        setReportId('')
        dispatch(clearReports())
        setIsCreate(false)
        setOpen(false)
      }
    }
  }
  const handleReportFetch = async () => {
    await new Promise<void>((resolve) => {
      dispatch(getReport(reportId)).then(() => {
        resolve();
      });
    });
  }
  const handleSave = () => {
    const payload = {
      romSessionsIds: {
        connect: [
          ...new Set([...reportIds?.romSessionsIds?.map((romSessionsId: string) => ({ id: romSessionsId })) || [], ...(report?.romSessionsIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
      romResultsIds: {
        connect: [
          ...new Set([...reportIds?.romResultsIds?.map((romResultsId: string) => ({ id: romResultsId })) || [], ...(report?.romResultsIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
      programSessionsIds: {
        connect: [
          ...new Set([...reportIds?.programSessionsIds?.map((rehabSessionsId: string) => ({ id: rehabSessionsId })) || [], ...(report?.programSessionsIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
      postureSessionsIds: {
        connect: [
          ...new Set([...reportIds?.postureSessionsIds?.map((postureSessionsId: string) => ({ id: postureSessionsId })) || [], ...(report?.postureSessionsIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
      evaluationSessionsIds: {
        connect: [
          ...new Set([...reportIds?.evaluationSessionsIds?.map((evaluationSessionsId: string) => ({ id: evaluationSessionsId })) || [], ...(report?.evaluationSessionsIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
      surveyResultIds: {
        connect: [
          ...new Set([...reportIds?.surveyResultIds?.map((surveyResultId: string) => ({ id: surveyResultId })) || [], ...(report?.surveyResultIds?.map(element => ({ id: element.id })) || [])])
        ]
      },
    }

    const actualReportId = isReportModal ? report?.id : reportId;

    if (actualReportId) {
      dispatch(updateReport({ payload, reportId: actualReportId }));
      message.success({
        content: (
          <>
            <span>{t('Admin.data.addToReports.saveText')}</span>
            <div style={{ marginTop: '8px' }}>
              {!isReportModal ? <Button onClick={handleReportSummary} style={{ marginLeft: '10px' }}>
                {t('Admin.data.addToReports.summaryButton')}
              </Button> : null}
            </div>
          </>
        ),
        duration: 5,
      });
      setReportId('');
      dispatch(clearReports());
      setOpen(false);
      isReportModal ? setIsVisible(false) : null;
      dispatch(setIsReportModal(false))
    } else {
      message.error(t('Admin.data.addToReports.errorText'));
    }
  }
  const handleCheckboxChange = (id: string, value: boolean) => {
    value ? setReportId(id) : setReportId('')
  };
  const getPopupContainer = () => document.getElementById('blackPopoverContainer') || document.body;
  return (
    <>
      {isReportModal ? <div className="mb-1"><Button children={
        <span>
          <FiUpload className='file-upload-button-css' />{t('Admin.data.addToReports.title')}  {reportCount > 0 && <Tag color="purple" className='report-count-label-css'>{reportCount}</Tag>}
        </span>
      } disabled={reportCount <= 0} onClick={() => handleSave()} /></div> : <Popover
        placement="bottomRight"
        open={open}
        onOpenChange={() => {
          setOpen(!open)
          setReportId('')
          setIsCreate(false)
        }}
        content={
          <div className='addToReport-container'>
            <div className='close-icon-css' onClick={() => setOpen(!open)}><MdClose size={20} /></div>
            <div>
              <p style={{ marginBottom: '8px' }}>{t('Admin.data.addToReports.saveReport')}</p>
              <Input
                addonBefore={<SearchOutlined />}
                placeholder={t('Admin.data.addToReports.search')}
                className='search-icon-css'
                onChange={(e) => dispatch(setSearchTextForReports(e.target.value))}
              />
            </div>
            <div className='report-data-div scroll'>
              {reports?.length == 0 &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ color: 'white' }}>{t('Admin.data.addToReports.noReport')}</span>} />}
              {
                reports?.map((data) => {
                  return <div className='report-checkbox-container checkbox-tick'>
                    <CheckBox id={data.id} onClick={handleCheckboxChange} checked={data.id == reportId} label='' />
                    <p style={{ marginBottom: '8px' }}>{data.name}</p>
                  </div>
                })
              }
            </div>
            <span>
              {
                reportId !== ''
                  ? <div className='save-button-css' onClick={() => handleReportFetch().then(() => handleSave())}>
                    <Check width={20} height={20} />
                    {t('Admin.data.addToReports.save')}
                  </div>
                  : <div>
                    {
                      isCreate
                        ? <div className='new-report-container'>
                          <Input
                            placeholder={t('Admin.data.addToReports.reportName')}
                            onChange={(event) => setNewRepoprtName(event.target.value)}
                            suffix={<MdOutlineAssignment color='gray/40' />}
                          />
                          <div className='report-action-button-css-div'>
                            <span className='report-action-item' onClick={() => setIsCreate(false)}><MdClose size={20} /> {t('Admin.data.addToReports.cancel')}</span>
                            <span className='report-action-item' onClick={() => handleCreate()}><Check width={20} height={20} /> {t('Admin.data.addToReports.create')}</span>
                          </div>
                        </div>
                        : <div className='report-action-item' style={{ width: "fit-content" }} onClick={() => setIsCreate(true)}>
                          <Plus width={20} height={20} /> {t('Admin.data.addToReports.createNewReport')}
                        </div>
                    }
                  </div>
              }
            </span>
          </div>
        }
        trigger="click"
        getPopupContainer={getPopupContainer}
        overlayInnerStyle={{ backgroundColor: 'black', paddingLeft: '20px', paddingRight: '20px' }}
      >
        <Button children={
          <span className={`${isDark ? 'stroke-black' : 'stroke-primary-800'}`}>
            <FiUpload className='file-upload-button-css' />{t('Admin.data.addToReports.title')}  {reportCount > 0 && <Tag color="purple" className='report-count-label-css'>{reportCount}</Tag>}
          </span>
        } disabled={reportCount <= 0} />
      </Popover>
      }
    </>
  )
}