import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import {
	AnyAction,
	configureStore,
	ThunkAction,
	ThunkDispatch,
} from '@reduxjs/toolkit';

// REDUCERS

import rom from '@stores/rom';
import rehab from '@stores/rehab';
import performance from '@stores/performance';
import user from '@stores/user';

import contacts from '@stores/contacts';
import coachPerformance from '@stores/coachPerformance';
import coachRehab from '@stores/coachRehab';
import coachRom from '@stores/coachRom';
import painAssessmentInfoConfig from '@stores/painAssessment';
import outOfParams from '@stores/outOfParams';
import pendingReview from '@stores/pendingReview';
import reviewed from '@stores/reviewed';
import escalationRequired from '@stores/escalationRequired';
import followUpRequired from '@stores/followUpRequired';
import newPatients from '@stores/newPatients';
import reports from '@stores/report/reports';
import myLibrary from '@stores/myLibrary';
import activityStream from '@stores/activityStream';
import aiAssistant from '@stores/aiAssistant';
import settings from '@stores/settings';
import survey from '@stores/survey';
import postures from '@stores/postures';
import PostureAnalytics from '@stores/postureAnalytics';
import adminDashboardPatient from '@stores/adminPatient';
import functionalGoals from '@stores/functionalGoals';
import onBoard from './onBoard';

const stores = configureStore({
	reducer: {
		rom,
		rehab,
		performance,
		user,
		contacts,
		coachPerformance,
		coachRehab,
		coachRom,
		painAssessment: painAssessmentInfoConfig.reducer,
		outOfParams,
		pendingReview,
		reviewed,
		escalationRequired,
		followUpRequired,
		newPatients,
		reports,
		myLibrary,
		activityStream,
		aiAssistant,
		settings,
		survey,
		postures,
		postureAnalytics: PostureAnalytics,
		adminDashboardPatient,
		onBoard,
		functionalGoals,
	},
});

export default stores;

export type AppDispatch = typeof stores.dispatch;
export type ReduxState = ReturnType<typeof stores.getState>;
export type TypedDispatch = ThunkDispatch<ReduxState, unknown, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	ReduxState,
	unknown,
	AnyAction
>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
