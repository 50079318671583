import { Modal } from "antd"
import { LibraryData } from "./LibraryData"
import { IProgramExercise } from "@stores/interfaces"
import { Edit05 } from "@carespace-icons/general/edit05"
import { useTranslation } from "react-i18next"

interface AddRomLibraryExerciseProps {
  isVisible: boolean
  onOk: () => void
  onCancel: () => void
  selectedExercises: IProgramExercise[]
  setSelectedExercises: (selectedExercises: IProgramExercise[]) => void
  subActiveKey: string
  setSubActiveKey: (val: string) => void
}

export const AddRomLibraryExercise = ({ isVisible, onOk, onCancel, selectedExercises, setSelectedExercises, subActiveKey, setSubActiveKey }: AddRomLibraryExerciseProps) => {

  const { t } = useTranslation()

  return <Modal
    title={
      <div className="flex gap-4">
        <span><Edit05 width={20} height={20} color="stroke-primary-600" /></span>
        <span className="titleStyle">{t('Patient.data.omnirom.createRomProgram')}</span>
      </div>}
    style={{ top: 20 }}
    open={isVisible}
    onCancel={onCancel}
    footer={false}
    width={974}
    className='select-none'
  >
    <LibraryData onOk={onOk} selectedExercises={selectedExercises} setSelectedExercises={setSelectedExercises}
      subActiveKey={subActiveKey} setSubActiveKey={setSubActiveKey} />
  </Modal>
}