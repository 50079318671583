import { InfoCircle } from '@carespace-icons/general/infoCircle';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Button } from 'antd';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import checkLogo from './VoiceRecordingLogo.json';
import { PiMicrophoneFill } from 'react-icons/pi';
import { setTransitionTime } from '@stores/aiAssistant/program';

interface GetStartedProps {
	activeStep: number;
	setActiveStep: (activeStep: number) => void;
	savedVoice: string;
	setSavedVoice: (savedVoice: string) => void;
}

const GetStarted = (props: GetStartedProps) => {
	const { activeStep, setActiveStep, savedVoice, setSavedVoice } = props;

	const transitionOptions = [3, 5, 8, 10];
	const { transitionTime } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	return (
		<div
			style={{
				width: '100%',
				position: 'absolute',
				background: 'var(--mainmenu-bg-color)',
				zIndex: 10,
				height: '100%',
				color: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<div
				className="md:scale-[60%] lg:scale-[70%] xl:scale-[90%] 2xl:scale-[100%]"
				style={{
					width: 551,
					zIndex: 10,
					fontSize: 18,
					padding: 20,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 10,
				}}>
				<div
					style={{ fontWeight: 600 }}
					className="flex justify-around items-center">
					<Button
						className="!rounded-full flex justify-center items-center"
						onClick={() => {
							setSavedVoice('');
							setActiveStep(activeStep - 1);
						}}
						icon={<FaArrowLeft />}
					/>
					{t('Patient.data.omnirom.transitionTime')}
				</div>
				<p>
					{t('Patient.data.omnirom.transitionExercise')}{' '}
					<span style={{ fontWeight: 600 }}>
						{transitionTime} {t('Patient.data.omnirom.seconds')}
					</span>
					.
				</p>
				<p>{t('Patient.data.omnirom.keepTime')}:</p>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						marginBottom: 40,
						marginTop: 20,
					}}>
					{transitionOptions.map(time => {
						return (
							<span
								onClick={() => {
									setActiveStep(6);
									dispatch(setTransitionTime(time));
								}}
								style={{
									paddingTop: 5,
									paddingLeft: 10,
									paddingRight: 10,
									paddingBottom: 5,
									borderRadius: 8,
									cursor: 'pointer',
									border: '2px solid white',
									color: 'white',
								}}>
								{time} {t('Patient.data.omnirom.sec')}
							</span>
						);
					})}
					<span
						onClick={() => {
							setActiveStep(5);
						}}
						style={{
							paddingTop: 5,
							paddingLeft: 10,
							paddingRight: 10,
							paddingBottom: 5,
							borderRadius: 8,
							cursor: 'pointer',
							border: '2px solid white',
							color: 'white',
						}}>
						{t('Patient.data.omnirom.manualOpt')}
					</span>
				</div>
				<div className="flex justify-end items-center mt-9">
					<div
						className="flex items-center justify-center align-middle"
						style={{ height: '50px', width: '150px' }}>
						<Lottie animationData={checkLogo} loop={true} autoplay={true} />
					</div>
					<PiMicrophoneFill className="text-primary-400 w-7 h-7" />
				</div>
				<p style={{ fontWeight: 600 }}>{t('Patient.data.omnirom.sayLoud')}</p>
			</div>
		</div>
	);
};

export default GetStarted;
