import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setGalleryImages } from '@stores/contacts/contacts';
import { AddToReports } from '@atoms/AddToReports';
import { useTranslation } from 'react-i18next';
import { ICustomRom, ICustonRomPaginated } from '@stores/interfaces';
import { getRomSessionList } from '@stores/rom/results';
import { useLocation } from 'react-router-dom';
import { Collapse, Empty, Pagination, Spin } from 'antd';
import CustomSummarySession from '@pages/AiAssistantCustomRomSummary/CustomSummarySession';

const { Panel } = Collapse;

export default function AiAssistantRomSummary() {
	const { t } = useTranslation();
	const state = useLocation();
	const dispatch = useTypedDispatch();
	const { user, selectedUser, coach, exercises } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
		coach: state.contacts.main.coach,
		exercises: state.contacts.main.exercises,
	}));
	const loadingRomSessionListData = useTypedSelector(
		state => state.rom.results.exerciseLoad,
	);
	const id = user.isPhysioterapist ? selectedUser?.id : user?.id;
	const [loading, setLoading] = useState(false);
	const [customRomData, setCustomRomData] = useState<ICustonRomPaginated>();
	const [selectedCollapse, setSelectedCollapse] = useState<
		string[] | string | undefined
	>();

	const fetchData = async (page: number) => {
		setLoading(true);
		const data = await dispatch(
			getRomSessionList({ patientId: id, page: page, limit: 10 }),
		);
		setCustomRomData(data.payload);
		setLoading(false);
	};

	const onPageChange = async (pageNumber: number) => {
		setLoading(true);
		fetchData(pageNumber);
		setLoading(false);
	};

	useEffect(() => {
		onPageChange(1);
	}, [user, selectedUser]);

	const showAllResults = useTypedSelector(
		state => state.rom.results.showAllResults,
	);
	const isReportModal = useTypedSelector(
		state => state.aiAssistant.aiAssistant.isReportModal,
	);

	useEffect(() => {
		dispatch(setGalleryImages({ exercises, coach }));
	}, [exercises, coach]);

	return (
		<>
			{showAllResults && (
				<div className="ml-5 mr-5">
					{isReportModal ? null : (
						<div className="flex justify-end gap-2 pt-1">
							<AddToReports />
						</div>
					)}
				</div>
			)}
			<>
				{loading || loadingRomSessionListData ? (
					<Spin
						className="flex items-center justify-center p-2"
						tip="Loading"
						size="large"
					/>
				) : (
					<>
						{customRomData?.data?.length! > 0 ? (
							<>
								<Collapse
									bordered={false}
									defaultActiveKey={state?.state?.sessionId || selectedCollapse}
									onChange={key => setSelectedCollapse(key)}
									className={`select-none posture-collapse`}>
									{customRomData?.data?.map((session: ICustomRom) => {
										return (
											<Panel
												className="header-panel bg-gray-50 !border !border-gray-200 !rounded-lg mt-3 mb-3"
												header={session?.title}
												key={session?.id}>
												<CustomSummarySession session={session.id} />
											</Panel>
										);
									})}
								</Collapse>
								{customRomData?.pagination?.totalCount! > 5 && (
									<Pagination
										className="flex justify-center"
										current={customRomData?.pagination?.currentPage}
										showSizeChanger={false}
										onChange={onPageChange}
										total={customRomData?.pagination?.totalCount}
									/>
								)}
							</>
						) : (
							<>
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										<span>{t('Admin.data.addToReports.noData')}</span>
									}
								/>
							</>
						)}
					</>
				)}
			</>
		</>
	);
}
