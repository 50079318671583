import { getAllFeatureFlags, getRegisteredPatientList, upadteClientFeatures } from '@stores/adminPatient';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Button, Checkbox, message } from 'antd';
import { useState } from 'react';

export default function Features(props: any) {
  const { features, setFeatures, setIsModalSettingsOpened } = props;
  const superAdminFeatureFlags = useTypedSelector((state) => state.adminDashboardPatient.superAdminFeatureFlags);
  const [validationError, setValidationError] = useState<string | null>(null);
  const dispatch = useTypedDispatch();
  const user = useTypedSelector((state) => state.user);

  const updateForm = async () => {
    const payload = {
      userId: user?.id,
      nextPage: 1
    };
    if (features.length < 3) {
      setValidationError('Please select at least 3 features.');
      return;
    }
    const updatedFeature = await dispatch(upadteClientFeatures({ features, id: user?.clientId }));
    if (updatedFeature?.payload) {
      dispatch(getAllFeatureFlags(user?.clientId));
      message.success('Updated Successfully!!');
    }
    setIsModalSettingsOpened(false);
    await dispatch(getRegisteredPatientList(payload));
  };

  const handleFeatureChange = (featureId: string, checked: boolean) => {
    const updatedFeatures = checked
      ? [...features, featureId]
      : features.filter((id: string) => id !== featureId);
    setFeatures(updatedFeatures);
    if (updatedFeatures.length < 3) {
      setValidationError('Please select at least 3 features.');
    } else {
      setValidationError(null);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 font-semibold mt-4">
        {superAdminFeatureFlags?.map((option) => (
          <div key={option.id} className="flex items-center">
            <Checkbox
              id={option.id}
              checked={features.includes(option.id)}
              onChange={(e) => handleFeatureChange(option.id, e.target.checked)}
            >
              {option?.name}
            </Checkbox>
          </div>
        ))}
      </div>
      {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      <Button block style={{ marginTop: '20px' }} type="primary" onClick={updateForm}>
        Update
      </Button>
    </>
  );
}
