import CheckBox from "@atoms/CheckBox";
import moment from "moment";
import { useEffect, useState } from "react";
import ButtonExportPainAssessment from "@atoms/ACoachSummaryButtonExportPainAssessment";
import { IOnchangeStatus, IstatusIcon, Status, TCoachSummary, TDataProps, TPainAssessmentDataProps } from "@stores/interfaces";
import { Checkbox, Collapse, Dropdown, Menu, Space, message } from "antd";
import { EvaluationDataItem } from "@atoms/AEvaluationDataItem";
import { StatusNormalized } from '@stores/constants';
import { updateEvaluationSessionStatus as updateEvaluationSessionStatusOutOfParams } from '@stores/outOfParams';
import { updateEvaluationSessionStatus as updateEvaluationSessionStatusPendingReview } from '@stores/pendingReview';
import { updateEvaluationSessionStatus as updateEvaluationSessionStatusReviewed } from '@stores/reviewed';
import { updateEvaluationSessionStatus as updateEvaluationSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateEvaluationSessionStatus as updateEvaluationSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import Badge from "@atoms/Badge";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { addReportId, deleteReportId, updateReportIds } from "@stores/report/reports";

const { Panel } = Collapse

const PainAssessmentProgressData = (props: TPainAssessmentDataProps) => {
	const { onItemSelect, onOk, onCancel, selectedItems, isPainAssessmentModalVisible, apiData, fetchData, perPage, currentPage, perpage } = props
	const [data, setData] = useState<TDataProps[]>(apiData.data);
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const user = useTypedSelector((state) => state.user);
	const evaluationSessionsIds = useTypedSelector(state => state.reports.reportIds.evaluationSessionsIds)
	const [statusSave, setStatusSave] = useState('')
	const stateId = useTypedSelector(state => state.aiAssistant.aiAssistant.stateId)

	useEffect(() => {
		setData(apiData.data)
	}, [apiData])

	useEffect(() => {
		if (statusSave) {
			fetchData(perPage === 0 ? perpage : perPage!, currentPage);
		}
	}, [statusSave])

	const statusIcons: IstatusIcon = {
		outOfParams: <Badge color="black" />,
		pendingReview: <Badge color="yellow" />,
		reviewed: <Badge color="moss" />,
		escalationRequired: <Badge color="rose" />,
		followUpRequired: <Badge color="orange" />,
	};

	type IUpdateRomSessionStatus = Omit<Record<Status, any>, 'newPatients'>;

	const updateOmniRomSession: IUpdateRomSessionStatus = {
		outOfParams: updateEvaluationSessionStatusOutOfParams,
		pendingReview: updateEvaluationSessionStatusPendingReview,
		reviewed: updateEvaluationSessionStatusReviewed,
		escalationRequired: updateEvaluationSessionStatusEscalationRequired,
		followUpRequired: updateEvaluationSessionStatusFollowUpRequired,
	};

	const onChangeStatus = async (params: IOnchangeStatus, item: any) => {
		const { sessionId, status } = params;
		message.loading({
			content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
			key: sessionId,
		});
		await dispatch(
			updateOmniRomSession[item.status as keyof IUpdateRomSessionStatus]({
				sessionId,
				body: { status },
			}),
		);
		message.success({
			content: t(
				'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
			),
			key: sessionId,
			duration: 3,
		});
		fetchData(perPage === 0 ? perpage : perPage!, currentPage);
	};

	const onClickSelectAll = (id: string, value: any) => {
		const { checked } = value.target;
		const selectedData = data?.map((item: TDataProps) => {
			if (item.id === id) {
				item = { ...item, isSelected: value };
				checked
					? dispatch(addReportId({
						id: item.id,
						type: "evaluationSessionsIds"
					}))
					: dispatch(deleteReportId({
						id: item.id,
						type: "evaluationSessionsIds"
					}))
			}
			return item;
		});
		setData(selectedData);
		if (onItemSelect) {
			onItemSelect(selectedData.filter((item: TDataProps) => item.isSelected));
		}
	};

	const healthStatus = (item: any) => {
		const menu = (
			<Menu>
				{Object.entries(StatusNormalized)
					.filter(([key]) => key !== item.status)
					.map(([status, label]) => {
						return (
							<Menu.Item
								key={status}
								onClick={() => {
									onChangeStatus(
										{ sessionId: item.id, status: status as Status },
										item,
									)
									setStatusSave(status)
								}}
							>
								<Space>
									<span className="dropdown-badge">
										{statusIcons[status as keyof IstatusIcon]}
									</span>
									<span>{label}</span>
								</Space>
							</Menu.Item>
						);
					})}
			</Menu>
		);
		return (
			<div className="flex justify-between items-center">
				<div className="align-middle mt-[0.20rem] pr-3">
					{user.isPhysioterapist ? (
						<Dropdown overlay={menu}>
							<a onClick={(e) => e.stopPropagation()}>
								{statusIcons[item.status as keyof IstatusIcon]}
							</a>
						</Dropdown>
					) : (
						''
					)}
				</div>
				<div
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<Checkbox
						id={`${item.id}`}
						onChange={(e)=>onClickSelectAll(item.id,e)}
						checked={evaluationSessionsIds?.includes(item.id)}
					/>
				</div>
			</div>
		);
	};

	return (
		<>
			<Collapse defaultActiveKey={stateId} bordered={false} className='select-none'>
				{data?.map((item: TDataProps, index: number) => {
					return (
						<Panel
							className='header-panel bg-white !border !border-gray-200 !rounded-lg mt-2'
							key={item?.id} header={moment(item.createdAt).local().format('LLL')}
							extra={healthStatus(item)}
						>
							<EvaluationDataItem evaluationData={item} />
						</Panel>
					)
				})}
			</Collapse>

			{
				isPainAssessmentModalVisible && (
					<ButtonExportPainAssessment
						isModalVisible={isPainAssessmentModalVisible}
						onOk={onOk}
						onCancel={onCancel}
						selectedItems={selectedItems}
					/>
				)
			}
		</>
	)
}

export default PainAssessmentProgressData