import {
	Tabs,
	Upload,
	Form,
	Modal,
	message,
	Button
} from 'antd';
import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';

import { useTranslation } from 'react-i18next';
import {
	getPendingInvitesList,
	getRegisteredPatientList,
	postNewInviteUser,
	setPendingCount,
	setRegisteredCount,
	updateInviteUser,
} from '@stores/adminPatient';
import {
	AllFeatureFlagsInterface,
	Feature,
	IInvitePatientModalProps,
	IPaginationPluginDefaultResponse,
	UserFeatureProps,
} from '@stores/interfaces';
import { pagination, USER_ROLES } from '@stores/constants';
import * as XLSX from 'xlsx';
import BulkInvite from './BulkInvite';
import Default from './Default';
import './style.less';
import { BulkInviteTable } from './BulkInviteTable';
import { getEmailTemplate, getInviteTemplate } from '@stores/settings/setings';


import UserUploadProgress from '@atoms/AdminMenu/WellnessCheck/UserUploadProgress';
import { updateProfileDetails } from '@stores/painAssessment';

type FeatureIds = string[];

interface UserData {
	'First Name': string;
	'Last Name': string;
	Email: string;
	Phone: number;
}

interface Invitation {
	firstName: string;
	lastName: string;
	email: string;
	invitedRole: string;
	features: string[];
	message?: string;
}

const avatarColors = ['#c7cc5e', '#00cc8d', '#239ca0', '#b03535', '#742f74'];

const InvitePatientsModal = (props: IInvitePatientModalProps) => {
	const [fileData, setFileData] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [fileName, setFileName] = useState('');
	const [isUploaded, setIsUploaded] = useState(false);
	const dispatch = useTypedDispatch();
	const [activeKey, setActiveKey] = useState<string>('1');
	const { t } = useTranslation();
	const [mustSendEmail, setMustSendEmail] = useState<boolean>(true);
	const {
		isInvitePatientModalOpen,
		setIsInvitePatientModalOpen,
		closable,
		fullRowDetails,
		rowData,
		rowDataFeature,
		isRegistered,
		currentPage,
		searchValue,
		filterButton,
	} = props;
	const user = useTypedSelector(state => state.user);
	const allFeatureFlags = useTypedSelector(
		state => state.adminDashboardPatient.allFeatureFlags,
	);
	const [featureFlagList, setFeatureFlagList] = useState<
		AllFeatureFlagsInterface[]
	>([]);
	const [form] = Form.useForm();
	const [userFormData, setUserFormData] = useState<Invitation>({
		firstName: '',
		lastName: '',
		email: '',
		invitedRole: 'user',
		features: [],
		message: '',
	});
	const { emailTemplate, inviteTemplate } = useTypedSelector(
		state => state.settings.settings,
	);
	const [savedEmailTemplate, setEmailTemplate] = useState('');
	const [savedInviteTemplate, setInviteTemplate] = useState('');
	const [isUpdating, setIsUpdating] = useState(false);

	const credentials = {
		instanceLink: window.location.origin.replace(/^https?:\/\//, ''),
		username: '{{user_name}}',
		password: '********',
		inviteCode: user.client.inviteCode,
	};

	useEffect(() => {
		setInviteTemplate(inviteTemplate);
	}, [inviteTemplate]);

	useEffect(() => {
		setEmailTemplate(emailTemplate);
	}, [emailTemplate]);

	const uploadProgress = useTypedSelector(state=> state.aiAssistant.userSlice.uploadProgress)
	const getRandomColor = () =>
		avatarColors[Math.floor(Math.random() * avatarColors.length)];

	const replacePlaceholders = (htmlContent: string) => {
		return htmlContent
			.replace(
				'{{name}}',
				userFormData?.firstName
					? `${userFormData?.firstName} ${userFormData?.lastName}`
					: '',
			)
			.replace(
				'{{admin_name}}',
				user.profile?.firstName + ' ' + user.profile?.lastName || 'Admin',
			);
	};

	const formatRole = (role: string) => {
		switch (role) {
			case USER_ROLES.SUPER_ADMIN:
				return 'Super Admin';
			case USER_ROLES.ADMIN:
				return 'Admin';
			case USER_ROLES.USER:
				return 'User';
			default:
				return role?.charAt(0)?.toUpperCase() + role?.slice(1).toLowerCase();
		}
	};

	const getRoleValue = (formattedRole: string) => {
		const roleMapping: Record<string, string> = {
			'Super Admin': 'superadmin',
			Admin: 'admin',
			User: 'user',
		};
		return roleMapping[formattedRole] || formattedRole.toLowerCase();
	};

	useEffect(() => {
		if (rowData) {
			setUserFormData({
				firstName: rowData?.firstName,
				lastName: rowData?.lastName,
				email: rowData?.email,
				invitedRole: formatRole(rowData?.invitedRole || rowData?.role),
				features: rowDataFeature
					? rowDataFeature?.map((item: UserFeatureProps) => item?.feature?.name)
					: rowData?.features?.map((item: Feature) => item?.name),
			});
			form.setFieldsValue({
				firstName: rowData?.firstName,
				lastName: rowData?.lastName,
				email: rowData?.email,
				invitedRole: formatRole(rowData?.invitedRole || rowData?.role),
				features: rowDataFeature
					? rowDataFeature?.map((item: UserFeatureProps) => item?.feature?.name)
					: rowData?.features?.map((item: Feature) => item?.name),
			});
		} else {
			setUserFormData({
				invitedRole: 'User',
				features:
					allFeatureFlags &&
					allFeatureFlags.map(feature => feature?.feature?.name),
			});
			form.setFieldsValue({
				invitedRole: 'User',
				features:
					allFeatureFlags &&
					allFeatureFlags.map(feature => feature?.feature?.name),
			});
		}
		if (rowDataFeature?.length === 0) {
			const allFeatures = allFeatureFlags?.map(
				feature => feature?.feature?.name,
			);
			setUserFormData({
				firstName: rowData?.firstName,
				lastName: rowData?.lastName,
				email: rowData?.email,
				invitedRole: formatRole(rowData?.invitedRole || rowData?.role),
				features: allFeatures,
			});
			form.setFieldsValue({ features: allFeatures });
		}
	}, [rowData, activeKey]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		if (allFeatureFlags.length > 0) {
			setFeatureFlagList(allFeatureFlags);
		}
		await dispatch(getEmailTemplate());
		await dispatch(getInviteTemplate());
	};

	const getFetchData = async (pagination: IPaginationPluginDefaultResponse) => {
		const payload = {
			...pagination,
			userId: user?.id,
			role: filterButton,
			name: searchValue || '',
		};
		if (isRegistered) {
			const registeredData = await dispatch(getRegisteredPatientList(payload));
			if (!searchValue) {
				await dispatch(
					setRegisteredCount(registeredData?.payload?.pagination?.totalCount),
				);
			}
		} else {
			const pendingData = await dispatch(getPendingInvitesList(payload));
			if (!searchValue) {
				await dispatch(
					setPendingCount(pendingData?.payload?.pagination?.totalCount),
				);
			}
		}
	};

	const handleValidation = (userFormData: {
		firstName: string;
		lastName: string;
		email: string;
	}) => {
		if (!userFormData?.firstName?.trim()) {
			message.error(
				t('Admin.data.menu.userRoles.invitePatientModal.firstNameRequired'),
			);
			return false;
		} else if (!userFormData?.lastName?.trim()) {
			message.error(
				t('Admin.data.menu.userRoles.invitePatientModal.lastNameRequired'),
			);
			return false;
		} else if (!userFormData?.email?.trim()) {
			message.error(
				t('Admin.data.menu.userRoles.invitePatientModal.emailRequired'),
			);
			return false;
		}
		return true;
	};

	const onFinish = async () => {
		if (!handleValidation(userFormData)) {
			return;
		}
		const featureIds = userFormData.features.map((featureName: string) => {
			const feature = featureFlagList.find(f => f.feature.name === featureName);
			return feature ? feature.featureId : featureName;
		});
		const payload = {
			...userFormData,
			invitedRole: userFormData?.invitedRole.toLowerCase(),
			features: featureIds,
			createdById: user?.id,
			message: replacePlaceholders(savedInviteTemplate),
		};
		const savedData = await dispatch(postNewInviteUser(payload));
		if (savedData?.payload) {
			message.success(`${t('Patient.data.completeProfile.saveSuccess')}`);
		}
		setIsInvitePatientModalOpen(false);
		getFetchData(pagination);
	};

	const updateForm = async () => {
		if (!handleValidation(userFormData)) {
			return;
		}
		setIsUpdating(true);
		const featureIds = userFormData.features.map((featureName: string) => {
			const feature = featureFlagList.find(
				f => f?.feature?.name === featureName,
			);
			return feature ? feature.featureId : featureName;
		});
		try {
			if (isRegistered) {
				const payload = {
					firstName: userFormData.firstName,
					lastName: userFormData.lastName,
					email: userFormData.email,
					role: getRoleValue(userFormData?.invitedRole),
					features: featureIds,
				};
				const updatedRegistered = await dispatch(updateProfileDetails({ payload, id: fullRowDetails?.id }));
				if (updatedRegistered.payload) {
					message.success(`${t('Patient.data.completeProfile.saveSuccess')}`);
				} 
			} else {
				const payload = {
					...userFormData,
					invitedRole: getRoleValue(userFormData?.invitedRole),
					features: featureIds,
				};
				const updatedData = await dispatch(
					updateInviteUser({ payload, id: rowData?.id }),
				);
				if (updatedData?.payload) {
					message.success(t('Admin.data.menu.userRoles.updatedSuccess'));
				}
			}
			const res = {
				...pagination,
				nextPage: searchValue ? 1 : currentPage,
			};
			getFetchData(res);
		} catch (error) {
			console.error("Update failed:", error);
		} finally {
			setIsUpdating(false);
		}
	 setIsInvitePatientModalOpen(false);
	};

	const handleFeatureChange = (selectedFeatures: FeatureIds) => {
		setUserFormData(prev => ({
			...prev,
			features: selectedFeatures,
		}));
	};

	const [file, setFile] = useState<File | undefined | null>(null);
	const [content, setContent] = useState<string>('')

	const isValidEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const beforeUpload = (file: { type: string; size: number; name: string }) => {
		const isValidFormat =
			file.type === 'text/csv' || file.type.includes('spreadsheet');
		const isValidSize = file.size / 1024 / 1024 <= 3;

		if (!isValidFormat) {
			return Upload.LIST_IGNORE;
		}
		if (!isValidSize) {
			message.error(t('Admin.data.menu.userRoles.invitePatientModal.sizeErr'));
			return Upload.LIST_IGNORE;
		}

		setFileName(file.name);
		return true;
	};

	const formatKeys = (row: UserData, index: number) => {
		return {
			id: (index + 1).toString(),
			userFeature: [],
			physiotherapistPatientAssociationPatientIdRelation:
				user?.profile?.role === USER_ROLES.ADMIN
					? [
							{
								patientId: (index + 1).toString(),
								physiotherapistId: user?.id,
								physiotherapist: user,
							},
						]
					: [],
			phone: row['Phone'] || '',
			profile: {
				email: row['Email'] || '',
				firstName: row['First Name'] || '',
				lastName: row['Last Name'] || '',
				phone: row['Phone'] || '',
				avatarColor: getRandomColor(),
			},
		};
	};

	const handleUpload = ({ file }: { file: File }) => {
		setUploading(true);
		setProgress(10);

		const reader = new FileReader();
		reader.onload = e => {
			setProgress(30);
			const data = new Uint8Array(e.target?.result as ArrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });

			setProgress(50);

			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData: UserData[] = XLSX.utils.sheet_to_json(worksheet, {
				defval: '',
			});

			setProgress(70);

			const emailSet = new Set<string>();
			let hasDuplicate = false;

			const isValidData = jsonData.every(row => {
				const email = row['Email'];
				if (
					!row['First Name'] ||
					!row['Last Name'] ||
					!email ||
					!isValidEmail(email)
				) {
					return false;
				}
				if (emailSet.has(email)) {
					hasDuplicate = true;
				} else {
					emailSet.add(email);
				}
				return true;
			});

			if (!isValidData) {
				message.error(
					t('Admin.data.menu.userRoles.invitePatientModal.dataErr'),
				);
				setUploading(false);
				setProgress(0);
				return;
			}

			if (hasDuplicate) {
				message.error(
					t('Admin.data.menu.userRoles.invitePatientModal.repeatMailErr'),
				);
				setUploading(false);
				setProgress(0);
				return;
			}

			setProgress(90);

			const formattedData = jsonData.map(formatKeys);
			setFileData(formattedData);
			setFile(file);
			setProgress(100);
			setTimeout(() => {
				setUploading(false);
				message.success(
					t('Admin.data.menu.userRoles.invitePatientModal.dataSuccess'),
				);
			}, 500);
		};

		reader.readAsArrayBuffer(file);
	};

	const handleRemove = () => {
		setFile(null);
	};

	return (
		<Modal
			open={isInvitePatientModalOpen}
			centered
			footer={null}
			destroyOnClose
			closable={uploadProgress ? false : closable}
			maskClosable={false}
			width={800}
			className="select-none"
			onCancel={() => {
				if (isUploaded && fileData.length > 0) {
					Modal.confirm({
						title: t('Admin.data.survey.discardChangesTitle'),
						content: t(
							'Admin.data.menu.userRoles.invitePatientModal.discardChangesContent',
						),
						footer: (
							<div className="flex justify-end gap-2">
								<div style={{ width: '50%' }}>
									<Button
										size="middle"
										style={{ width: '40%' }}
										onClick={() => {
											Modal.destroyAll();
										}}
										type="default">
										{t('Admin.data.menu.userRoles.invitePatientModal.cancel')}
									</Button>{' '}
									<Button
										size="middle"
										style={{ width: '50%' }}
										htmlType="submit"
										onClick={() => {
											setIsInvitePatientModalOpen(false);
											Modal.destroyAll();
										}}
										type="primary">
										{t('Admin.data.menu.userRoles.invitePatientModal.save')}
									</Button>
								</div>
							</div>
						),
						className: 'select-none',
					});
				} else {
					setIsInvitePatientModalOpen(false);
				}
			}}>
			<>
				<h1 className="profile-heading">
					{rowData
						? t('Admin.data.menu.userRoles.invitePatientModal.editUser')
						: t('Admin.data.menu.userRoles.formHeading')}
				</h1>
				{rowData ? (
					<Default
						rowData={rowData}
						content={savedInviteTemplate}
						setContent={setInviteTemplate}
						activeKey={activeKey}
						instanceLink={credentials.instanceLink}
						username={credentials.username}
						password={credentials.password}
						inviteCode={credentials.inviteCode}
						userFormData={userFormData}
						form={form}
						setUserFormData={setUserFormData}
						updateForm={updateForm}
						onFinish={onFinish}
						isRegistered={isRegistered!}
						handleFeatureChange={handleFeatureChange}
						featureFlagList={featureFlagList}
						isUpdating={isUpdating}
					/>
				) : (
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						className="createProgramTab select-none"
						tabBarStyle={{ margin: 0, backgroundColor: 'white' }}
						onChange={value => setActiveKey(value)}
						items={[
							{
								label: t(
									'Admin.data.menu.userRoles.invitePatientModal.default',
								),
								key: '1',
								children: (
									<Default
										rowData={rowData}
										content={savedInviteTemplate}
										setContent={setInviteTemplate}
										activeKey={activeKey}
										instanceLink={credentials.instanceLink}
										username={credentials.username}
										password={credentials.password}
										inviteCode={credentials.inviteCode}
										userFormData={userFormData}
										form={form}
										setUserFormData={setUserFormData}
										updateForm={updateForm}
										onFinish={onFinish}
										isRegistered={isRegistered!}
										handleFeatureChange={handleFeatureChange}
										featureFlagList={featureFlagList}
									/>
								),
							},
							{
								label: t(
									'Admin.data.menu.userRoles.invitePatientModal.bulkInvite',
								),
								key: '2',
								children:
									isUploaded && fileData.length > 0 ? (
										<>
										{uploadProgress?.progress >= 0 ? <UserUploadProgress /> :
										<BulkInviteTable
											fileData={fileData}
											setIsInvitePatientModalOpen={setIsInvitePatientModalOpen}
											htmlContentTemplate={savedEmailTemplate}
											mustSendEmail={mustSendEmail}
											cancelUploaded={() => {
												setIsUploaded(false);
											}}
										/>}</>
									) : (
										<BulkInvite
											beforeUpload={beforeUpload}
											setIsUploaded={setIsUploaded}
											rowData={rowData}
											content={savedEmailTemplate}
											setContent={setEmailTemplate}
											activeKey={activeKey}
											instanceLink={credentials.instanceLink}
											username={credentials.username}
											password={credentials.password}
											inviteCode={credentials.inviteCode}
											userFormData={userFormData}
											setUserFormData={setUserFormData}
											setIsInvitePatientModalOpen={setIsInvitePatientModalOpen}
											handleUpload={handleUpload}
											handleRemove={handleRemove}
											uploading={uploading}
											progress={progress}
											file={file!}
											mustSendEmail={mustSendEmail}
											setMustSendEmail={setMustSendEmail}
										/>
									),
							},
						]}
					/>
				)}
			</>
		</Modal>
	);
};

export default InvitePatientsModal;
