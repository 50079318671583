import { XCircle } from '@carespace-icons/general/xCircle';
import { StopCircle } from '@carespace-icons/media/stopCircle';
import { VideoRecorder } from '@carespace-icons/media/videoRecorder';
import { IRehabVideoHeaderProps, RehabVideoState } from '@stores/interfaces';
import { Button, Col, Divider, Row, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import checkLogo from './VoiceRecordingLogo.json';
import { CheckCircle } from '@carespace-icons/general/checkCircle';
import { MdOutlineSwitchCamera } from 'react-icons/md';
import { useTypedSelector } from '@stores/index';
import { ArrowRight } from '@carespace-icons/arrows/arrowRight';

const controlInstructions = {
	[RehabVideoState.START.toString()]: {
		text1: "Say 'RECORD' or 'YES' to start recording",
		text2: "Say 'RECORD' or 'YES' or wave with an open hand to start recording",
		icon: <img src={`/images/openHand.svg`} />,
	},
	[RehabVideoState.RECORDING.toString()]: {
		text1: "Say 'STOP' to stop the recording",
		text2: "Say 'STOP' or extend an open hand to stop the recording",
		icon: <img src={`/images/openHand.svg`} />,
	},
	[RehabVideoState.REPLAYING.toString()]: {
		text1: "Say 'NO' to redo or 'YES' to save the recording",
		text2:
			"Say 'NO' to redo or 'YES' to save, or gesture 'THUMBS DOWN' or 'THUMBS UP'",
		icon: (
			<div>
				<img src={`/images/thumbDown.svg`} />
				<img src={`/images/thumbUp.svg`} />
			</div>
		),
	},
	[RehabVideoState.RATING.toString()]: {
		text1: 'Choose a number and say it aloud',
		text2: 'Choose a number and say it aloud.',
	},
};

export default function ProgramVideoHeaderContent(
	props: IRehabVideoHeaderProps,
) {
	const {
		videoState,
		onStopRecord,
		onStartRecord,
		onStopRating,
		autoStopRecord,
		onDiscardRecord,
		onSubmitRecord,
		isGestureEnabled,
		flipCamera,
		switchCamera,
	} = props;
	const { t } = useTranslation();
	const micRef = useRef<LottieRefCurrentProps>(null);
	const { isAuto } = useTypedSelector(state => state.aiAssistant.program.main);
	const isFullScreenInstructional = useTypedSelector(
		state => state.rehab.main.fullScreen,
	);

	useEffect(() => {
		if (videoState === RehabVideoState.RATING) {
			onStopRating();
		}
	}, [videoState]);

	return (
		<Row justify="end" style={{ textAlign: 'end', lineHeight: '35px' }}>
			<Col
				span={24}
				style={{
					height: '35px',
					display: 'flex',
					justifyContent: 'end',
					alignItems: 'center',
				}}>
				{videoState === RehabVideoState.READY && (
					<Tooltip title={t('Patient.data.rehab.getReady')}>
						<Button
							style={{
								height: '35px',
								border: 0,
								borderRadius: 0,
								backgroundColor: '#e29b0e',
								color: '#ffffff',
							}}>
							{t('Patient.data.rehab.getReady')}
						</Button>
					</Tooltip>
				)}
				{videoState === RehabVideoState.RECORDING && (
					<Tooltip title={t('Patient.data.rehab.stop')}>
						<Button
							onClick={() => (isAuto ? autoStopRecord() : onStopRecord())}
							style={{
								height: '35px',
								border: 0,
								borderRadius: 0,
								backgroundColor: '#E31B54',
								color: '#ffffff',
							}}>
							<Space align="center">
								{isAuto ? (
									<ArrowRight width={21} height={21} />
								) : (
									<StopCircle width={21} height={21} />
								)}
								{isAuto
									? t('Patient.data.omnirom.next')
									: t('Patient.data.rehab.stop')}
							</Space>
						</Button>
					</Tooltip>
				)}
				{!isAuto &&
					videoState === RehabVideoState.START &&
					!isFullScreenInstructional && (
						<Tooltip title={t('Patient.data.rehab.record')}>
							<Button
								onClick={onStartRecord}
								style={{
									height: '35px',
									border: 0,
									borderRadius: 0,
									backgroundColor: '#BDFF00',
									color: '#53389E',
								}}>
								<Space align="center">
									<VideoRecorder width={21} height={21} color="#53389E" />
									{t('Patient.data.rehab.record')}
								</Space>
							</Button>
						</Tooltip>
					)}
				{/* {videoState === RehabVideoState.RATING && (
          <div className='bg-black bg-opacity-60 absolute z-10 top-60 right-0 py-4 px-7 rounded-s-full'>
            <RatingFeedback onStopRating={onStopRating} selectedRating={selectedRating} setSelectedRating={setSelectedRating} />
          </div>
        )} */}
				{!isAuto &&
					controlInstructions[videoState] &&
					!isFullScreenInstructional && (
						<div className="bg-black flex justify-end gap-4 items-center bg-opacity-60 max-w-[500px] absolute z-10 top-20 right-0 py-4 px-7 rounded-s-full">
							<div>
								<div className="flex justify-end items-center">
									<div className="flex items-center justify-center align-middle md:w-20 md:h-5 lg:w-36 lg:h-12">
										<Lottie
											lottieRef={micRef}
											animationData={checkLogo}
											loop={true}
											autoplay={true}
										/>
									</div>
									<img src="/images/microphone.svg" />
								</div>
								<p className="font-semibold md:text-sm lg:text-lg text-white">
									{isGestureEnabled
										? controlInstructions[videoState]?.text2
										: controlInstructions[videoState]?.text1}
								</p>
							</div>
							{isGestureEnabled && controlInstructions[videoState]?.icon}
						</div>
					)}
				{!isAuto && videoState === RehabVideoState.REPLAYING && (
					<>
						<Tooltip title={t('Patient.data.rehab.discard')}>
							<Button
								onClick={onDiscardRecord}
								style={{
									height: '35px',
									border: 0,
									borderRadius: 0,
									backgroundColor: '#E31B54',
									color: '#ffffff',
								}}>
								<Space align="center">
									<XCircle width={21} height={21} />
									{t('Patient.data.rehab.discard')}
								</Space>
							</Button>
						</Tooltip>
						<Tooltip title={t('Patient.data.rehab.submit')}>
							<Button
								onClick={onSubmitRecord}
								style={{
									height: '35px',
									border: 0,
									borderRadius: 0,
									backgroundColor: '#BDFF00',
									color: '#53389E',
								}}>
								<Space align="center">
									<CheckCircle width={21} height={21} color="stroke-black" />
									{t('Patient.data.rehab.submit')}
								</Space>
							</Button>
						</Tooltip>
					</>
				)}
				{/* {
          videoState === RehabVideoState.RATING ? '' :
            <Tooltip title={t('Patient.data.rehab.record')}>
              <CountDownTimer limit={patientTimeLimit} isStartingTimer={isStartingTimer} endedTimer={onEndedTimer} />
            </Tooltip>
        } */}
				{/* <Tooltip title="Fullscreen">
          {isFullscreen ? (
            <MdFullscreenExit
              size={30}
              style={{
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={onFullscreen}
              color='#5e5e5e'
            />
          ) : (
            <MdFullscreen
              size={30}
              style={{
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={onFullscreen}
              color='#5e5e5e'
            />
          )}
        </Tooltip>
        <Divider
          type="vertical"
          style={{ height: '34px', borderColor: '#1B1B1B' }}
        /> */}
				{flipCamera && (
					<>
						<Divider
							type="vertical"
							style={{ height: '34px', borderColor: '#1B1B1B' }}
						/>
						<Tooltip
							title={
								videoState === RehabVideoState.RECORDING ||
								videoState === RehabVideoState.READY
									? ' (Disabled during recording)'
									: t('Patient.data.omnirom.flipCamera')
							}>
							<MdOutlineSwitchCamera
								size={30}
								style={{
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
								onClick={
									videoState === RehabVideoState.RECORDING ||
									videoState === RehabVideoState.READY
										? () => {}
										: switchCamera
								}
								color="#5e5e5e"
								//disabled={videoState === RehabVideoState.RECORDING}
							/>
						</Tooltip>
						<Divider
							type="vertical"
							style={{ height: '34px', borderColor: 'transparent' }}
						/>
					</>
				)}
			</Col>
		</Row>
	);
}
