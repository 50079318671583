import { KeyboardEvent, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Button, Input, message, Modal, Pagination, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	AdminDashboardPatient,
	IUserPaginationDefaultResponse,
	PhysiotherapistPatientAssociation,
	RegisteredPatientsProps,
} from '@stores/interfaces';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { UserPlus01 } from '@carespace-icons/users/userPlus01';
import { Edit01 } from '@carespace-icons/general/edit01';
import { ResetPass } from '@carespace-icons/general/resetPass';
import { Trash01 } from '@carespace-icons/general/trash01';
import TableList from '@pages/AdminUnassignedPatients/TableList';
import InvitePatientsModal from '@pages/InvitePatientsModal';
import {
	deletePatientFromPhysiotherapist,
	getRegisteredPatientList,
	getUnAssignedPatientList,
	setRegisteredCount,
	setUnAssignedCount,
} from '@stores/adminPatient';
import { FilterLines } from '@carespace-icons/general/filterLines';
import { USER_ROLES } from '@stores/constants';
import ResetPasswordModal from '@pages/ResetPassModal';

const RegisteredPatients = () => {
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const [isInvitePatientModalOpen, setIsInvitePatientModalOpen] =
		useState(false);
	const [isResetPassModalOpen, setIsResetPassModalOpen] = useState(false);
	const [userId, setUserId] = useState('');
	const [activeRowId, setActiveRowId] = useState<string | null>(null);
	const registeredPatients = useTypedSelector(
		state => state.adminDashboardPatient?.registeredPatients,
	);
	const unAssignedPatients = useTypedSelector(
		state => state.adminDashboardPatient?.unAssignedPatients,
	);
	const [patientData, setPatientData] = useState<AdminDashboardPatient[]>([]);
	const [pagination, setPagination] = useState<IUserPaginationDefaultResponse>(
		registeredPatients?.pagination || {},
	);
	const dispatch = useTypedDispatch();
	const [searchValue, setSearchValue] = useState<string>('');
	const [selectedButton, setSelectedButton] = useState('all');
	const [userData, setUserData] = useState();
	const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1);
	const [filterButton, setFilterButton] = useState('');
	const allFeatureFlags = useTypedSelector(
		state => state.adminDashboardPatient.allFeatureFlags,
	);
	const checkAdmin =
		filterButton === USER_ROLES.ADMIN
			? t('Admin.data.menu.userRoles.addPatient')
			: t('Admin.data.menu.userRoles.addPhysiotherapist');

	const options = [
		{
			label: t('Admin.data.menu.userRoles.filter'),
			value: 'filter',
			icon: <FilterLines color="stroke-black" width={20} height={18} />,
		},
		{ label: t('Admin.data.menu.userRoles.all'), value: 'all' },
		{
			label: t('Admin.data.menu.userRoles.superAdmin'),
			value: USER_ROLES.SUPER_ADMIN,
		},
		{ label: t('Admin.data.menu.userRoles.admin'), value: USER_ROLES.ADMIN },
		{ label: t('Admin.data.menu.userRoles.patient'), value: USER_ROLES.USER },
	];

	const TEAM_MEMBER_HEADER = [
		{
			name: t('Admin.data.menu.userRoles.name'),
			helperText: 'I am helper',
			sort: true,
			key: 'name',
		},
		{
			name: t('Admin.data.menu.userRoles.tools'),
			helperText: 'I am helper',
			sort: true,
			key: 'tools',
		},
	];

	const TEAM_MEMBER_SUPERaDMIN_HEADER = [
		{ name: t('Admin.data.menu.userRoles.name'), sort: true, key: 'name' },
		{ name: t('Admin.data.menu.userRoles.role'), sort: true, key: 'role' },
		{
			name: t('Admin.data.menu.userRoles.physiotherapist'),
			sort: true,
			key: 'physiotherapist',
		},
		{ name: t('Admin.data.menu.userRoles.tools'), sort: true, key: 'tools' },
	];

	useEffect(() => {
		if (registeredPatients?.data) {
			const updatedPatients = registeredPatients.data.map(patient => {
				return {
					...patient,
					userFeature:
						patient.userFeature && patient.userFeature.length > 0
							? patient.userFeature
							: allFeatureFlags,
				};
			});

			setPatientData(updatedPatients);
			setPagination(registeredPatients?.pagination || {});
		}
	}, [registeredPatients, allFeatureFlags]);

	const handleDeleteClick = async (obj: RegisteredPatientsProps) => {
		Modal.confirm({
			className: 'select-none',
			title: t('Admin.data.menu.userRoles.registeredPatients.deleteConfirm'),
			okText: t('Admin.data.menu.userRoles.registeredPatients.ok'),
			cancelText: t('Admin.data.menu.userRoles.registeredPatients.cancel'),
			onOk: async () => {
				const associations =
					obj?.physiotherapistPatientAssociationPatientIdRelation;
				if (!associations?.length) {
					return;
				}
				try {
					await Promise.all(
						associations.map((element: PhysiotherapistPatientAssociation) => {
							const id = element?.id;
							return dispatch(deletePatientFromPhysiotherapist(id));
						}),
					);
					const data = await fetchData(currentPage, filterButton);
					if (!searchValue) {
						dispatch(setRegisteredCount(data?.pagination?.totalCount));
					}
					const unAssignedCount = await dispatch(
						getUnAssignedPatientList(unAssignedPatients?.pagination),
					);
					if (!searchValue) {
						dispatch(
							setUnAssignedCount(
								unAssignedCount?.payload?.pagination?.totalCount,
							),
						);
					}
					message.success(
						t('Admin.data.menu.userRoles.registeredPatients.deleteMessage'),
					);
				} catch (error) {
					message.error(
						t('Admin.data.menu.userRoles.registeredPatients.deletedfailed'),
					);
				}
			},
			onCancel: () => {
				console.log('Cancel clicked');
			},
		});
	};

	const handleUserClick = (rowData: RegisteredPatientsProps) => {
		setActiveRowId(rowData.id);
	};

	const actions = [
		{
			icon: <UserPlus01 color="stroke-gray-600" width={15} height={15} />,
			action: (rowData: RegisteredPatientsProps) => handleUserClick(rowData),
			label: checkAdmin,
			key: 'userPlus',
		},
		{
			icon: <ResetPass color="stroke-gray-600" width={15} height={15} />,
			action: (rowData: RegisteredPatientsProps) => {
				setActiveRowId(null);
				setIsResetPassModalOpen(true);
				setUserId(rowData.id);
			},
			label: 'Reset Password',
			key: 'resetPass',
		},
		{
			icon: <Edit01 color="stroke-gray-600" width={15} height={15} />,
			action: (rowData: RegisteredPatientsProps) => {
				setActiveRowId(null);
				setIsInvitePatientModalOpen(true);
				setUserData(rowData);
			},
			label: t('Admin.data.menu.userRoles.edit'),
		},
		{
			icon: <Trash01 color="stroke-gray-600" width={15} height={15} />,
			action: (rowData: RegisteredPatientsProps) => {
				setActiveRowId(null);
				handleDeleteClick(rowData);
			},
			label: t('Admin.data.menu.userRoles.delete'),
		},
	];

	const onPageChange = async (pageNumber: number) => {
		setCurrentPage(pageNumber);
		fetchData(pageNumber, filterButton);
	};

	const fetchData = async (pageNumber: number, role?: string) => {
		const paginate = {
			...pagination,
			userId: user?.id,
			nextPage: pageNumber,
			name: searchValue,
			role: role || '',
		};
		const response = await dispatch(getRegisteredPatientList(paginate));
		return response.payload;
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			fetchData(1, filterButton);
			setCurrentPage(1);
		}
	};

	return (
		<div className="total-patient-main-div">
			<>
				<div className="border-b-2 border-inherit pb-3 mb-2" style={{color:"var(--text-color-root)"}}>
					<h3>
						{isSuperAdmin
							? t('Admin.data.menu.userRoles.registeredPatients.userLabel')
							: t('Admin.data.menu.userRoles.registeredPatients.label')}{' '}
						<span className="total-patient-length">
							<Tag color="purple">
								{pagination.totalCount} {t('Admin.data.newPatients.users')}
							</Tag>
						</span>
					</h3>
					<span>
						{t('Admin.data.menu.userRoles.registeredPatients.description')}
					</span>
				</div>
				<div className="flex justify-between items-center my-3">
					<Input
						className="w-[400px] my-3 h-10"
						placeholder={t(
							'Admin.data.menu.aiAssistant.aiAssistantPrograms.search',
						)}
						value={searchValue}
						onKeyDown={e => handleKeyDown(e)}
						onChange={e => setSearchValue(e.target.value)}
						prefix={<SearchLg height={15} width={15} color="stroke-black" />}
					/>
					{isSuperAdmin && (
						<Button.Group>
							{options.map(item => {
								const isSelected = selectedButton === item.value;
								const isSuperAdminRoleButton = [
									USER_ROLES.SUPER_ADMIN,
									USER_ROLES.ADMIN,
									USER_ROLES.USER,
								].includes(item.value);
								return (
									<Button
										key={item.label}
										icon={item.icon}
										style={{
											borderColor: 'inherit',
											borderRadius: '0px',
											backgroundColor: isSelected ? '#D0D5DD' : 'white',
											color: '#1D2939',
										}}
										onClick={() => {
											if (item.value !== 'filter') {
												setSelectedButton(item.value);
												if (isSuperAdminRoleButton) {
													setCurrentPage(1);
													setFilterButton(item.value);
													fetchData(currentPage, item.value);
												} else {
													setCurrentPage(1);
													setFilterButton('');
													fetchData(currentPage);
												}
											}
										}}>
										{item.label}
									</Button>
								);
							})}
						</Button.Group>
					)}
				</div>
				<div style={{backgroundColor:'white', borderRadius:'4px'}}>
				<TableList
					tableData={patientData}
					columns={
						isSuperAdmin ? TEAM_MEMBER_SUPERaDMIN_HEADER : TEAM_MEMBER_HEADER
					}
					actions={actions}
					activeRowId={activeRowId}
					setActiveRowId={setActiveRowId}
					isRegistered={true}
					currentPage={currentPage}
					filterButton={filterButton}
					searchValue={searchValue}
				/></div>
				{isInvitePatientModalOpen && (
					<InvitePatientsModal
						isInvitePatientModalOpen={isInvitePatientModalOpen}
						setIsInvitePatientModalOpen={setIsInvitePatientModalOpen}
						closable={true}
						isRegistered={true}
						fullRowDetails={userData}
						rowData={userData?.profile}
						rowDataFeature={userData?.userFeature}
						currentPage={currentPage}
						searchValue={searchValue}
						filterButton={filterButton}
					/>
				)}
				{isResetPassModalOpen && (
					<ResetPasswordModal
						userId={userId}
						open={isResetPassModalOpen}
						onClose={() => {
							setIsResetPassModalOpen(false);
							setUserId('');
						}}
					/>
				)}
				{pagination?.totalCount! > 10 && (
					<Pagination
						current={currentPage}
						total={pagination?.totalCount}
						onChange={onPageChange}
						style={{ textAlign: 'center' }}
						showSizeChanger={false}
					/>
				)}
			</>
		</div>
	);
};

export default RegisteredPatients;