import React, { useState } from 'react';
import CheckBox from '@atoms/CheckBox';
import { useTypedDispatch } from '@stores/index';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import { HealthSigns, Options, THealthSignsOptions } from './interface';

interface AssociatedSymptomsProps {
	list: THealthSignsOptions[];
	savedSymptomsData: HealthSigns;
	setSavedSymptomsData: (value: HealthSigns) => void;
}

const AssociatedSymptoms: React.FC<AssociatedSymptomsProps> = ({
	list,
	savedSymptomsData,
	setSavedSymptomsData,
}) => {
	const dispatch = useTypedDispatch();

	const setSelectedList = (name: string, value: boolean) => {
		let temp_data =
			(Array.isArray(savedSymptomsData?.strapiHealthSignsIds) &&
				savedSymptomsData?.strapiHealthSignsIds.filter(
					(item) => item != name,
				)) ||
			[];
		if (value) {
			if (name == 17) {
				temp_data = [];
			}
			temp_data.push(name);
		}
		setSavedSymptomsData({
			...savedSymptomsData,
			strapiHealthSignsIds: temp_data,
		});
		dispatch(painAssessmentInfoAction.associatedSymptomsInfo(temp_data));
	};

	return (
		<div className="grid grid-cols-2 gap-4 font-semibold p-4">
			{list?.map((option: Options) => (
				<div key={option.id} className="flex items-center">
					<CheckBox
						label={option?.attributes?.name}
						id={option.id}
						onClick={setSelectedList}
						checked={
							Array.isArray(savedSymptomsData?.strapiHealthSignsIds) &&
							savedSymptomsData?.strapiHealthSignsIds.includes(option.id)
						}
						isDisabled={
							Array.isArray(savedSymptomsData?.strapiHealthSignsIds) &&
							savedSymptomsData?.strapiHealthSignsIds.includes(17) &&
							option.id != 17
						}
					/>
					<label className="ml-1"></label>
				</div>
			))}
		</div>
	);
};

export default AssociatedSymptoms;
