import React from 'react';
import { Menu, Space } from 'antd';
import { IstatusIcon, Status, IOnchangeStatus, IRomSession } from '@stores/interfaces';
import { StatusNormalized } from '@stores/constants';

interface StatusMenuProps {
  status: string | undefined;
  romData: IRomSession | undefined;
  statusIcons: IstatusIcon;
  onChangeStatus: (params: IOnchangeStatus, item: IRomSession)=>void
}

const StatusMenu: React.FC<StatusMenuProps> = ({
  status,
  romData,
  statusIcons,
  onChangeStatus
}) => {

  return (
    <Menu>
      {Object.entries(StatusNormalized)
        .filter(([key]) => key !== status)
        .map(([statusKey, label]) => {
          return (
            <Menu.Item
              key={statusKey}
              onClick={(e) => {
                e.domEvent.stopPropagation();
                onChangeStatus({ sessionId: romData!.id, status: statusKey as Status }, romData!);
              }}
            >
              <Space>
                <span className="dropdown-badge">
                  {statusIcons[statusKey as keyof IstatusIcon]}
                </span>
                <span>{label}</span>
              </Space>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default StatusMenu;
