import { ArrowLeft } from "@carespace-icons/arrows/arrowLeft"
import { ArrowRight } from "@carespace-icons/arrows/arrowRight"
import { MagicWand01 } from "@carespace-icons/editor/magicWand01"
import { Check } from "@carespace-icons/general/check"
import { FaceFrown } from "@carespace-icons/users/faceFrown"
import { FaceHappy } from "@carespace-icons/users/faceHappy"
import { FaceNeutral } from "@carespace-icons/users/faceNeutral"
import { FaceSad } from "@carespace-icons/users/faceSad"
import { FaceSmile } from "@carespace-icons/users/faceSmile"
import { UserLeft01 } from "@carespace-icons/users/userLeft01"
import { ISurveyOptionList, ISurveyQuestionOptions } from "@stores/interfaces"
import { Input, Modal } from "antd"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.css"
import { useNavigate } from "react-router-dom"
import { useTypedSelector } from "@stores/index"
import { FaceWink } from "@carespace-icons/users/faceWink"
import { router } from '@routers/routers';

const { TextArea } = Input

const hoverColors = [
  "-rose-700",
  "-rose-500",
  "-orange-400",
  "-orange-300",
  "-yellow-400",
  "-yellow-300",
  "-green-300",
  "-green-500",
  "-green-700",
  "-cyan-500"
];

export const SurveyOptionList = ({ fetchSurveyData, saveSurvey, selectedOptionList, setSelectedSurvey, setSelectedOptionList, item, index, selectedSurveyData, selectedSurvey, setSelectedSurveyData }: ISurveyOptionList) => {
  const { t } = useTranslation()
  const [showThankyou, setShowThankyou] = useState(false)
  const [showExit, setShowExit] = useState(true)
  const [openText, setOpenText] = useState<string>('')
  const [selectedOptions, setSelectedOptions] = useState<ISurveyQuestionOptions[]>([])
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [clicked, setClicked] = useState(false)
  const lastDivRef = useRef<HTMLDivElement>(null);
  const [isModal, setIsModal] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)
  const [totalScore, setTotalScore] = useState(0)
  const navigate = useNavigate()
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)

  useEffect(() => {
    if (index == selectedOptionList.length)
      setClicked(false)
  })

  useEffect(() => {
    setSelectedOptions([])
    setClicked(false)
  }, [selectedSurvey])

  const handleOptionClick = (data: ISurveyQuestionOptions) => {
    if (selectedOptions.find(option => option?.id === data?.id)) setSelectedOptions(selectedOptions.filter(option => option?.id !== data?.id))
    else {
      if (item?.questionType == "singleSelect") {
        setSelectedOptions([data])
      } else {
        setSelectedOptions([...selectedOptions, data])
      }
    }
  }

  const handleNextClick = () => {
    setClicked(true)
    index + 1 != selectedSurvey?.questionList?.length && setSelectedSurveyData([...selectedSurveyData, selectedSurvey?.questionList![index + 1]])
    if (item?.questionType == "singleSelect") {
      setSelectedOptionList([
        ...selectedOptionList,
        {
          questionType: item?.questionType,
          question: item?.title,
          questionDescription: item?.description,
          selectedAnswer: selectedOptions[0]?.option,
          score: selectedOptions[0]?.score,
        }
      ])
    } else if (item?.questionType == "rate") {
      setSelectedOptionList([
        ...selectedOptionList,
        {
          questionType: item?.questionType,
          question: item?.title,
          questionDescription: item?.description,
          selectedAnswer: item?.optionList![hoverIndex].option,
          ratingLevel: hoverIndex + 1,
          score: item?.optionList![hoverIndex].score
        }
      ])
    } else if (item?.questionType == "openText") {
      setSelectedOptionList([
        ...selectedOptionList,
        {
          questionType: item?.questionType,
          question: item?.title,
          questionDescription: item?.description,
          selectedAnswer: openText,
        }
      ])
    } else {
      setSelectedOptionList([
        ...selectedOptionList,
        {
          questionType: item?.questionType,
          question: item?.title,
          questionDescription: item?.description,
          answerList: selectedOptions?.map(option => option?.option),
          score: selectedOptions?.reduce((total, option) => total + (option?.score || 0), 0),
        }
      ])
    }
    if (index === selectedSurvey?.questionList?.length! - 1) {

      setShowThankyou(true);
      setShowSubmit(true);
      setShowExit(false);
    }
  }

  useEffect(() => {
    let score = 0;
    if (selectedOptionList) {
      selectedOptionList.forEach(item => {
        if (item && item.score) {
          score += item.score;
        }
      });
    }
    setTotalScore(score);
  }, [selectedOptionList])

  useEffect(() => {
    showThankyou && saveSurvey()
  }, [showThankyou])

  const isSelected = (id: string) => selectedOptions.find(option => option?.id == id)

  useEffect(() => {
    if (lastDivRef.current) {
      lastDivRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [isSelected])

  const getColor = (index: number) => {
    return index == hoverIndex ? hoverColors[index] : "-gray-300"
  };

  const getTextColor = (color: string) => {
    const colorMap = {
      "-rose-700": "#c01048",
      "-rose-500": "#F43F5E",
      "-orange-400": "#f38744",
      "-orange-300": "#f7b27a",
      "-yellow-400": "#fac515",
      "-yellow-300": "#fde272",
      "-green-300": "#73e2a3",
      "-green-500": "#16b364",
      "-green-700": "#087443",
      "-cyan-500": "#06aed4"
    };
    return { color: colorMap[color] || "#D1D5DB" };
  }

  const ratingExps = [
    <FaceFrown color={'stroke' + getColor(0)} width={45} height={45} />,
    <FaceFrown color={'stroke' + getColor(1)} width={45} height={45} />,
    <FaceSad color={'stroke' + getColor(2)} width={45} height={45} />,
    <FaceSad color={'stroke' + getColor(3)} width={45} height={45} />,
    <FaceNeutral color={'stroke' + getColor(4)} width={45} height={45} />,
    <FaceNeutral color={'stroke' + getColor(5)} width={45} height={45} />,
    <FaceSmile color={'stroke' + getColor(6)} width={45} height={45} />,
    <FaceSmile color={'stroke' + getColor(7)} width={45} height={45} />,
    <FaceWink color={'stroke' + getColor(8)} width={45} height={45} />,
    <FaceHappy color={'stroke' + getColor(9)} width={45} height={45} />
  ]

  const renderFeedbackWithLineBreaks = (text: string) => {
    return text?.split('\n')?.map((line, index) => (
      <p key={index}>
        {line}
        <br />
      </p>
    ));
  };

  return <div className="survey-option-list">
    {
      (item?.questionType == "singleSelect" || item?.questionType == "multiSelect") ? item?.optionList?.map((data) => {
        return <div
          onClick={() => !clicked && handleOptionClick(data)}
          style={{
            backgroundColor: isSelected(data?.id) ? "var(--survey-selected-bg)" : "var(--survey-unselected-bg)",
            borderColor: isSelected(data?.id) ? "var(--survey-selected-border)" : "#FFFFFF"
          }}
          className="option-container"
        >
          {data?.option}
          {isSelected(data?.id!) && <Check width={18} height={18} color="stroke-primary-500 float-right" />}
        </div>
      }) : item?.questionType == "rate" ? <div className="rate-container">
        <p className="question-type" style={{ padding: "0 1.25rem", marginTop: "1.25rem" }}>{t('Admin.data.survey.selectRate')}</p>
        <div className="options-row">
          {
            item?.optionList?.map((item, index) => {
              if (item?.option?.length > 0) return <div
                onMouseOver={() => !clicked && setHoverIndex(index)}
                className='rate'
                onClick={() => !clicked && handleNextClick()}
              >
                {ratingExps[index]}
                <span style={{
                  fontSize: "0.875rem", height: '42px',
                  ...getTextColor(getColor(index))
                }}
                >{item.option}</span>
              </div>
            })
          }</div>
      </div> : <div>
        <TextArea
          rows={4}
          className='open-text'
          placeholder={t('Admin.data.survey.surveyDescription')}
          value={openText}
          onChange={(e) => !clicked && setOpenText(e.target.value)}
        />
      </div>
    }
    {
      showThankyou && <div>
        <div className="ai-response">
          <span className="icon">
            <MagicWand01 width={20} height={20} color="stroke-primary-600" />
          </span>
          <div className="content">
            <p className="ai-assistant">{t('Admin.data.menu.aiAssistant.aiVirtualAssessment.aiAssistant')}</p>
          </div>
        </div>
        <div className="tankyou-text">
          {t('Admin.data.survey.thankYouText')}
          <div className="survey-container">
            <p className="survey-total">
              {t('Admin.data.survey.yourTotal')} {totalScore}
            </p>
            <p className="survey-point">
              {renderFeedbackWithLineBreaks(selectedSurvey.resultFeedback)}
            </p>
          </div>
        </div>
      </div>
    }
    <div style={{
      display: "flex",
      justifyContent: showExit ? "space-between" : "flex-end"
    }}>
      {showExit &&
        (!clicked || showSubmit) && <div
          onClick={() => {
            setIsModal(true)
          }}
          className="exit-button"
        >
          <span style={{ marginRight: "0.25rem" }}>{t('Admin.data.survey.exit')}</span> <UserLeft01 width={16} height={16} color="stroke-primary-600" />
        </div>
      }
      <Modal
        title="Your changes will be discarded. Do you want to proceed?"
        className='select-none'
        closable={false}
        centered
        open={isModal}
        onOk={() => {
          setSelectedSurvey(null)
          setSelectedSurveyData([])
          setSelectedOptionList([])
        }}
        onCancel={() => setIsModal(false)}
        okText={t('Admin.data.addToReports.yes')}
        cancelText={t('Admin.data.addToReports.no')}
      ></Modal>
      {
        showSubmit
          ? <div
            onClick={() => {
              setSelectedSurvey(null)
              setSelectedSurveyData([])
              setSelectedOptionList([])
              fetchSurveyData(1)
              navigate(`/${user.isPhysioterapist ? selectedUser.id : user.id}${router.AIASSISTANT_SURVEY_SUMMARY}`)
            }}
            className="button-activated"
          >
            {t('Admin.data.survey.done')} <Check width={18} height={18} color="stroke-white" />
          </div>
          : !clicked && <>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              {index != 0 && <div
                onClick={() => {
                  const newList = [...selectedSurveyData]
                  newList.pop()
                  setSelectedSurveyData(newList)
                  const newOpts = [...selectedOptionList]
                  newOpts.pop()
                  setSelectedOptionList(newOpts)
                }}
                className="button-activated"
              >
                <ArrowLeft width={18} height={18} color="stroke-white" /> {t('Admin.data.survey.back')}
              </div>}
              {
                (selectedOptions?.length !== 0 || openText != '')
                  ? item?.questionType != "rate" && <div
                    onClick={() => handleNextClick()}
                    className="button-activated"
                  >
                    {!(index === selectedSurvey?.questionList?.length! - 1) ? t('Admin.data.survey.next') : t('Admin.data.survey.submit')} <ArrowRight width={18} height={18} color="stroke-white" />
                  </div>
                  : item?.questionType != "rate" && <div
                    className="text-white p-2 rounded-lg bg-primary-300 w-fit mt-2 cursor-pointer"
                  >
                    {!(index === selectedSurvey?.questionList?.length! - 1) ? t('Admin.data.survey.next') : t('Admin.data.survey.submit')} <ArrowRight width={18} height={18} color="stroke-white" />
                  </div>
              }
            </div>
          </>
      }
    </div>
  </div>
}