import SummuryOptionsList from "@atoms/ACoachSummaryButtonExportPainAssessment/SummaryOptionsList"
import { IAddButtonItemsProps, IEvaluation, IEvaluationPainAssessment, IStrapiHealthSign, IStrapiMedicalHistory } from "@stores/interfaces";
import { Col, Empty, Image, message, Row } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import '../ReportPreviewModal.css'
import AddMoreResults from "./AddMoreResults";
import AddNotes from "./AddNotes";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { updateReportNotes } from "@stores/report/reports";
import { useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import ReportDiagnosisCodes from "./ReportDiagnosisCodes";
import ReportNotesTemplate from "./ReportNotes/ReportNotesTemplate";
import ReportNotesDownloadTemplate from "./ReportNotes/ReportNotesDownloadTemplate";

interface ReportEvaluationDataProps {
  isEditMode: boolean
  isDownload?: boolean
  setActiveComponent: (value: IAddButtonItemsProps) => void
  setIsVisible: (val: boolean) => void
}

export default function ReportEvaluationData(props: ReportEvaluationDataProps) {
  const { isDownload, isEditMode, setActiveComponent, setIsVisible } = props
  const { t } = useTranslation();
  const report = useTypedSelector(state => state.reports.report)
  const [evaluationNotes, setEvaluationNotes] = useState<string>()
  const dispatch = useTypedDispatch()

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      evaluationSessionsNotes: {
        notes: evaluationNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setEvaluationNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('evaluationSessionsNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId || ''
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  return (
    <>
    {!isDownload &&
      <AddMoreResults setActiveComponent={setActiveComponent} setIsVisible={setIsVisible} />}
      <ReportDiagnosisCodes isDownload={isDownload} />
      {report?.evaluationSessionsIds?.length === 0 ?
        <>{!isDownload && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span >{t('Admin.data.addToReports.noData')}</span>} />}</> :
        <div>
          <p className="font-bold text-base text-gray-600 mt-2 text-center">{t('Admin.data.addToReports.evaluationData')}</p>
          {
            report?.evaluationSessionsIds?.map((data: IEvaluation, index: number) => {
              return (
                <div className='border-b-2 border-inherit pb-6'>
                  <p className="font-semibold text-gray-600 mt-3">{t('Patient.data.myProgress.evaluation.dateOfAssessment')} <span className="font-regular">{moment(data.createdAt).local().format('LLL')}</span></p>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.painSummary')}</span>
                  </div>
                  <ul key={index} id="painSummaryEditor" contentEditable={isEditMode} className="medium-editable container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
                    {
                      data?.painAssessments?.map((item: IEvaluationPainAssessment) => {
                        return (
                          <li className="border-b-2 border-inherit" key={index}>
                            <SummuryOptionsList item={item} />
                          </li>
                        )
                      })
                    }
                  </ul>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.associatedSymptoms')}</span>
                  </div>
                  <ul id="associatedSymptomsEditor" contentEditable={isEditMode} className="medium-editable container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
                    <div className="border-b-2 border-inherit p-4">
                      <span className="font-bold text-sm text-gray-700">{t('Patient.data.myProgress.evaluation.doYouHaveAnyOfTheseSymptoms')}</span>
                    </div>
                    {
                      data?.healthSigns?.strapiHealthSigns?.map((element: IStrapiHealthSign, index: number) => {
                        return (
                          <li className="grid text-sm mb-1 mt-1 leading-7 pl-4" key={index}>
                            <span className="font-regular">{element.attributes.name}</span>
                          </li>
                        );
                      })
                    }
                  </ul>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.medicalHistory')}</span>
                  </div>
                  <ul id="medicalHistoryEditor" contentEditable={isEditMode} className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
                    <div className="border-b-2 border-inherit p-4">
                      <span className="font-bold text-sm text-gray-700">{t('Patient.data.myProgress.evaluation.haveYouBeenDiagnosedWithAnyOfTheFollowing')}</span>
                    </div>
                    {data?.medicalHistories?.strapiMedicalHistories?.map((data: IStrapiMedicalHistory, i: number) => {
                      return (
                        <li className="grid text-sm mb-1 mt-1 leading-7 pl-4" key={i}>
                          <span className="font-regular">{data.attributes.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })
          }
          {
            report?.evaluationSessionsNotes?.some(
              (note) =>
                note.notes?.trim()
            ) && (<>
              {report?.evaluationSessionsNotes?.length! > 0 ? (
                <>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.subjectiveNotes")}</span>
                  </div>
                  <>
                    {report?.evaluationSessionsNotes?.map((note, index) =>
                      isDownload ? (
                        <ReportNotesDownloadTemplate
                          index={index}
                          isEditMode={isEditMode}
                          note={note}
                        />
                      ) : (
                        <ReportNotesTemplate
                          index={index}
                          isEditMode={isEditMode}
                          note={note}
                        />
                      )
                    )}
                  </>
                </>) : null}</>)
          }
          {
            report?.evaluationSessionsIds?.length != 0 && !isDownload ?
              <AddNotes sendMessage={sendMessage} onClick={() => handleSubmit()} onChange={(event) => setEvaluationNotes(event.target.value)} />
              : null
          }
        </div>}
    </>
  )
}
