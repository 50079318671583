import Button from '@atoms/Button';
import { Avatar, Empty, Input, message, Modal, Pagination, Spin } from 'antd';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import './AssignedPTModal.css'
import { getAllAdmin, savePhysiotherapistToPatient, updateShowPopStatus } from '@stores/adminPatient';
import { getUserById } from '@stores/contacts/contacts';
import { Physiotherapist } from '@stores/interfaces';

const AssignedPTModal = ({setShowModal , PtDetails} : {setShowModal: (value: boolean) => void, PtDetails: Physiotherapist}) => {

  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isPTModal, setPTModal] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const allAdminList = useTypedSelector((state) => state.adminDashboardPatient.allAdminList)
  const { user } = useTypedSelector((state) => ({ user: state.user }))

  const [ptId, setPtId] = useState<any>(PtDetails?.id || null);
  const [PTName, setPTName] = useState('');

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (PtDetails) {
      setPtId(PtDetails?.id);
      setPTName(`${PtDetails?.profile?.firstName || ''} ${PtDetails?.profile?.lastName || ''}`.trim());
    } else {
      console.warn('No PtDetails provided');
    }
  }, [PtDetails]);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  }

  const fetchData = async (page: number) => {
    setLoading(true)
    const payload = {
      limit: 10,
      page: page,
      search: searchText
    }
    await dispatch(getAllAdmin(payload));
    setLoading(false)
  }

  const handleBack = () => {
    setPTModal(false);
    setIsModalOpen(true);
  }

  const handleClick = (user: any) => {
    setPTName(`${user?.profile?.firstName || ''} ${user?.profile?.lastName || ''}`.trim());
    setPtId(user?.id);
    setPTModal(false);
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    setIsModalOpen(false);
    if (PtDetails?.id !== ptId) {
      const payload = {
        physiotherapistId: ptId,
        patientId: user?.id,
      };
      const data = await dispatch(savePhysiotherapistToPatient(payload));
      if (data) {
        message.success(t('Admin.data.menu.userRoles.successPatientAssigned'));
      }
    }

    if (user?.physiotherapistPatientAssociationPatientIdRelation?.some((relation) => relation.showPopup)) {
      for (const relation of user.physiotherapistPatientAssociationPatientIdRelation) {
        if (relation.showPopup) {
          await dispatch(updateShowPopStatus({ showPopup: false, id: relation.id }));
        }
      }
      message.success(t('Admin.data.menu.userRoles.updatedSuccess'));
      await dispatch(getUserById(user?.id));
    }
    setShowModal(false);
  }

  useEffect(() => {
    fetchData(currentPage)
  }, [])

  useEffect(() => {
    fetchData(1)
  }, [searchText])

  return (
    <Modal
      open={isModalOpen}
      centered
      width={420}
      footer={null}
      destroyOnClose
      maskClosable={false}
      closable={false}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      style={{ userSelect: 'none' }}
      className={`${'modal-container'}`}>
      {isPTModal ? <>
        <div style={{ marginBottom: "10px", cursor: "pointer" }} onClick={handleBack}>
          <ArrowLeft color='stroke-gray-400' />
        </div>
        {!loading ? <>
          {allAdminList?.data?.length > 0 ? <>
            <Input
              prefix={<SearchOutlined style={{ color: "gray" }} />}
              placeholder={t('Admin.data.menu.userRoles.userPopup.search')}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            {allAdminList?.data?.map((users: any) =>
              <div onClick={() => handleClick(users)} className='flex-container-pt-list'>
                {users?.profile?.imageUrl ? (
                  <Avatar src={users?.profile?.imageUrl} alt="avatar" size={35} />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: users?.profile?.avatarColor || '#1890FF',
                    }}
                    className='custom-avatar-pt-profile'
                    alt="avatar"
                    size={35}>
                    {users?.profile?.firstName ? users?.profile?.firstName?.charAt(0)?.toUpperCase() : 'U'}
                  </Avatar>
                )}
                <span style={{ marginLeft: 8 }}>
                  {users?.profile?.firstName +
                    ' ' +
                    users?.profile?.lastName}
                </span>
              </div>
            )}
            <hr className='line-seperation' />
            {
              <Pagination
                className="createProgramModalPagination flex justify-center"
                current={currentPage}
                onChange={onPageChange}
                total={allAdminList?.pagination?.total || 0}
                showSizeChanger={false}
              />
            }
          </> : <>
            <Empty />
          </>}</> : <>
          <Spin className="flex items-center justify-center p-2" tip="Loading" size="large" />
        </>}</> :
        <div className='main-container'>
          <b className='assigned-pt-title'>{t('Admin.data.menu.userRoles.userPopup.title')}</b>
          <p>{t('Admin.data.menu.userRoles.userPopup.description')}</p>
          <p className='pt-container'><b className='text-pt'>{t('Admin.data.menu.userRoles.userPopup.physioAssignLabel')}&nbsp;</b> {PTName}</p>
          <p>{t('Admin.data.menu.userRoles.userPopup.physioAssignDesc')}</p>
          <div className='button-container'>
            <Button size="full" onClick={() => setPTModal(true)} color="white-bg">{t('Admin.data.menu.userRoles.userPopup.chooseAnother')}</Button>
            <Button size="full" onClick={handleSubmit}>{t('Admin.data.menu.userRoles.userPopup.continue')}</Button>
          </div>
        </div>}
    </Modal>
  )
}

export default AssignedPTModal