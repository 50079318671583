import { useState } from 'react';
import ScrollableCalendar from '@atoms/ScrollableCalendar';
import WeeklyCalendar from '@molecules/WeeklyCalendar';
import { EvaluationItem, IActivityStreamData } from '@stores/interfaces';
import './style.css'

interface TActivityCalender  {
  activityStreamData: EvaluationItem[]
  updateOmniROMData: (value:Date) => void
  isFilter: boolean
  clearFilter: () => void
}

const ActivityCalender = (props:TActivityCalender) => {
const {activityStreamData, updateOmniROMData, isFilter, clearFilter} = props

  const [isMonthOpen, setIsmonthOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(new Date())

  const onClickMonth =(selcted:Date)=> {
    setIsmonthOpen(!isMonthOpen);
    setSelectedMonthAndYear(selcted)
  }

  const onClickDate =(selectedDate:Date)=> {
    setSelectedDate(selectedDate);
    setIsmonthOpen(!isMonthOpen);
    updateOmniROMData(selectedDate)
  }

  const onClickWeekDate =(selectedDate:Date)=> {
    setSelectedDate(selectedDate);
    updateOmniROMData(selectedDate)
  }


  return (
    <>
      <div className='w-[100%] mb-3 mt-3'>
        <WeeklyCalendar onMonthClick={onClickMonth} onClick={onClickWeekDate} selectedDate={selectedDate} setSelectedDate={setSelectedDate}  activityStreamData={activityStreamData} isFilter={isFilter} clearFilter={clearFilter}/>
      </div>
      {isMonthOpen && 
        <ScrollableCalendar selectedMonthAndYear={selectedMonthAndYear} onChange={onClickDate}  isMonthOpen={isMonthOpen} activityStreamData={activityStreamData} />
      }
    </>
  );
};

export default ActivityCalender;