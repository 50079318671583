import { ArrowLeft } from "@carespace-icons/arrows/arrowLeft";
import { useTranslation } from "react-i18next";
import './style.css'
import { Button } from "antd";

const ConsentPolicyModal = (props: any) => {
  const { setPolicyModalOpen } = props
  const { t } = useTranslation()
  return (
    <>
      <Button style={{border: 'none'}} onClick={() => setPolicyModalOpen(false)}>
        <ArrowLeft width={20} height={20} color="stroke-primary-700" />
        <span className="back-profile">{t('Patient.data.completeProfile.backProfile')}</span>
      </Button>
      <div className="consent-form-div">
        <p className="complete-profile">{t('Patient.data.completeProfile.informConsentPolicy')}</p>
        <p className="complete-profile">{t('Patient.data.completeProfile.readCarefully')}</p>
        <p className="consent-para">{t('Patient.data.completeProfile.consent_p_1')}</p>
        <p className="consent-para">{t('Patient.data.completeProfile.consent_p_2')}</p>
        <p className="consent-para">{t('Patient.data.completeProfile.consent_p_3')}</p>
        <p className="consent-para">{t('Patient.data.completeProfile.consent_p_4')}</p>
      </div>
    </>
  )
}

export default ConsentPolicyModal;