import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogOut04 } from '@carespace-icons/general/logOut04';
import { Phone02 } from '@carespace-icons/media/phone02';
import { HomeLine } from '@carespace-icons/general/homeLine';
import { List } from '@carespace-icons/layoult/list';
import { PlaySquare } from '@carespace-icons/media/playSquare';
import ThemeSelector from '@pages/Themes/ThemeSelector';
import { MdOutlineAssignment } from 'react-icons/md';
import { Image01 } from '@carespace-icons/files/image01';
import { PlusSquare } from '@carespace-icons/general/plusSquare';
import { MagicWand01 } from '@carespace-icons/editor/magicWand01';
import { BarChartSquarePlus } from '@carespace-icons/charts/barChartSquarePlus';
import { BarChart01 } from '@carespace-icons/charts/barChart01';
import { FileShield02 } from '@carespace-icons/security/fileShield02';
import { Heart } from '@carespace-icons/general/heart';
import { NotificationText } from '@carespace-icons/alerts/notificationText';
import { Settings01 } from '@carespace-icons/general/settings01';
import { UserSquare } from '@carespace-icons/users/userSquare';
import { Mail01 } from '@carespace-icons/communication/mail01';
import { USER_ROLES } from '@stores/constants';
import { useTypedSelector } from '@stores/index';

export const useAdminMenuConstants = () => {
	const { t } = useTranslation();
	const getThemeDetails = useTypedSelector(
				state => state.settings.settings.getTheme
		);
		const { user } = useTypedSelector((state) => ({
			user: state.user,
		}));

	 const menuMapping: Record<string, string> = {
					virtualEvaluation: 'virtual-evaluation',
					listEvaluation: 'virtual-evaluation',
					startScan: 'omni-rom',
					romSummary: 'omni-rom',
					captures: 'omni-rom',
					postureScan: 'omni-rom',
					startRomScan: 'omni-rom',
					postureSummary: 'omni-rom',
					postureCaptures: 'omni-rom',
					customSummary: 'omni-rom',
					customCaptures: 'omni-rom',
					generateProgram: 'lets-move',
					programs: 'lets-move',
					listSessions: 'lets-move',
					createReport: 'reports',
					myReport: 'reports',
					assignSurvey: 'survey',
					createSurvey: 'survey',
					surveySummary: 'survey',
					startSurveyUser: 'survey',
					activity: 'userActivity'
			};

    const menuAdminList = [
            {
                label: t('Admin.data.menu.home.home'),
                key: '',
                icon: <HomeLine width={20} height={20} color={'stroke-white'} />,
            },
            {
                label: '',
                key: 'activity',
                icon: <Mail01 width={20} height={20} color={'stroke-white'} />,
            },
            {
                label: '',
                key: 'contacts',
                icon: <UserSquare width={20} height={20} color={'stroke-white'} />,
            },
            {
                label: t('Tools'),
                key: 'tools',
                icon: <MagicWand01 width={20} height={20} color={'stroke-white'} />,
            },
        ];
    
        const menuSuperAdminList = [
            {
                label: '',
                key: 'contacts',
                icon: <UserSquare width={20} height={20} color={'stroke-white'} />,
            },
            {
                label: t('Tools'),
                key: 'tools',
                icon: <MagicWand01 width={20} height={20} color={'stroke-white'} />,
            }
        ];
    
	const menuUser = [
		{
			label: 'Activity',
			key: 'userActivity',
			icon: <NotificationText width={20} height={20} color={'stroke-primary-700'} />,
		},
		{
			key: 'virtual-evaluation',
			icon: <FileShield02 width={20} height={20} color={'stroke-primary-700'} />,
			label: t('Evaluation'),
			options: [
				{
					key: 'virtualEvaluation',
					icon: (
						<MdOutlineAssignment
							className="text-primary-700"
							height={16.67}
							width={13.33}
						/>
					),
					name: 'Start Virtual Evaluation',
				},
				{
					key: 'listEvaluation',
					icon: <List color="stroke-primary-700" height={15} width={15} />,
					name: 'Evaluation Results',
				},
			],
		},
		{
			key: 'omni-rom',
			icon: <PlaySquare width={20} height={20} color={'stroke-primary-700'} />,
			label: t('Omni-Rom'),
			options: [
				{
					key: 'startScan',
					icon: (
						<PlaySquare color="stroke-primary-700" height={16.67} width={15} />
					),
					name: 'Start Scan',
				},
				{
					key: 'romSummary',
					icon: <List color="stroke-primary-700" height={15} width={15} />,
					name: 'OmniROM Summary',
				},
				{
					key: 'captures',
					icon: <Image01 color="stroke-primary-700" height={15} width={15} />,
					name: 'OmniROM Captures',
				},
				{
					key: 'postureSummary',
					icon: <List color="stroke-primary-700" height={15} width={15} />,
					name: 'Posture Summary',
				},
				{
					key: 'postureCaptures',
					icon: <Image01 color="stroke-primary-700" height={15} width={15} />,
					name: 'Posture Captures',
				},
			],
		},
		{
			key: 'lets-move',
			icon: <List width={20} height={20} color={'stroke-primary-700'} />,
			label: t("Let's Move"),
			options: [
				{
					key: 'generateProgram',
					icon: (
						<MagicWand01 height={15} width={15} color="stroke-primary-700" />
					),
					name: 'Generate Program',
				},
				{
					key: 'listSessions',
					icon: <List color="stroke-primary-700" height={15} width={15} />,
					name: 'Program Summary',
				},
			],
		},
		{
			key: 'survey',
			icon: <MagicWand01 width={20} height={20} color={'stroke-primary-700'} />,
			label: t('Survey'),
			options: [
				{
					key: 'createSurvey',
					icon: (
						<PlusSquare height={15} width={15} color="stroke-primary-700" />
					),
					name: 'Create',
				},
				{
					key: 'startSurveyUser',
					icon: (
						<MdOutlineAssignment
							className="text-primary-700"
							height={16.67}
							width={13.33}
						/>
					),
					name: 'Start Survey',
				},
				{
					key: 'surveySummary',
					icon: <List color="stroke-primary-700" height={15} width={15} />,
					name: 'Survey Summary',
				},
			],
		},
		{
			key: 'reports',
			icon: <BarChart01 width={20} height={20} color={'stroke-primary-700'} />,
			label: t('Reports'),
			options: [
				{
					key: 'createReport',
					icon: (
						<BarChartSquarePlus
							color="stroke-primary-700"
							height={15.83}
							width={15.83}
						/>
					),
					name: 'Create Reports',
				},
				{
					key: 'myReport',
					icon: (
						<BarChart01 color="stroke-primary-700" height={15} width={15} />
					),
					name: 'Report Summary',
				},
			],
		},
	];

	const adminProfileData = [
		...(getThemeDetails.locked && user?.profile?.roles === USER_ROLES.ADMIN ? [] : [
			{
					label: t('Apply Theme'),
					key: 'theme-selector',
					icon: <ThemeSelector />
			}
	]),
		{
			label: t('Settings'),
			key: 'settings',
			icon: <Settings01 width={24} height={24} color={'stroke-primary-700'} />,
		},

		{
			label: 'Download App',
			key: 'downloadApp',
			icon: <Phone02 width={20} height={20} color={'stroke-primary-700'} />,
		},
		{
			label: 'Logout',
			key: 'logout',
			icon: <LogOut04 width={24} height={24} color={'stroke-primary-700'} />,
		},
	];
	return { menuUser, adminProfileData, menuAdminList, menuSuperAdminList, menuMapping };
};
