import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Form, message, Space, Segmented } from 'antd';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	postEmailTemplate,
	postInviteTemplate,
} from '@stores/settings/setings';
import {
	DEFAULT_EMAIL_TEMPLATE,
	DEFAULT_INVITE_TEMPLATE,
} from './defaultInviteTemplates';

const toolbarOptions = [
	[{ font: [] }],
	[{ size: ['small', false, 'large', 'huge'] }],
	['bold', 'italic', 'underline', 'strike'],
	[{ color: [] }, { background: [] }],
	[{ align: [] }],
	[{ list: 'ordered' }, { list: 'bullet' }],
	['blockquote', 'link', 'image'],
	['clean'],
];

const BulkImportEmailTemplate = ({
	setIsModalSettingsOpened,
}: {
	setIsModalSettingsOpened: (val: boolean) => void;
}) => {
	const dispatch = useTypedDispatch();
	const [tab, setTab] = useState('Invite');
	const [emailMessage, setEmailMessage] = useState('');
	const [inviteMessage, setInviteMessage] = useState('');
	const { emailTemplate, inviteTemplate } = useTypedSelector(
		state => state.settings.settings,
	);

	useEffect(() => {
		setInviteMessage(inviteTemplate ? inviteTemplate : DEFAULT_INVITE_TEMPLATE);
	}, [inviteTemplate]);

	useEffect(() => {
		setEmailMessage(emailTemplate ? emailTemplate : DEFAULT_EMAIL_TEMPLATE);
	}, [emailTemplate]);

	const handleEditorChangeEmail = (content: string) => {
		setEmailMessage(content);
	};

	const handleEditorChangeInvite = (content: string) => {
		setInviteMessage(content);
	};

	const handleSaveTemplate = async () => {
		await dispatch(postEmailTemplate(emailMessage));
		await dispatch(postInviteTemplate(inviteMessage));
		message.success('Template saved!');
		setIsModalSettingsOpened(false);
	};

	return (
		<Space
			className="flex"
			direction="vertical"
			size="large"
			style={{ marginTop: '25px' }}>
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
				}}>
				<Segmented
					options={['Invite', 'Bulk Invite']}
					value={tab}
					onChange={value => setTab(value)}
				/>
				<span
					className="settings-templates-reset-to-default"
					onClick={() => {
						tab === 'Invite'
						? setInviteMessage(DEFAULT_INVITE_TEMPLATE)
						: setEmailMessage(DEFAULT_EMAIL_TEMPLATE)
					}}>
					Reset to Default
				</span>
			</div>
			{tab === 'Invite' && (
				<Form layout="vertical">
					<Form.Item>
						<ReactQuill
							modules={{ toolbar: toolbarOptions }}
							theme="snow"
							value={inviteMessage}
							onChange={handleEditorChangeInvite}
							className="custom-quill"
						/>
					</Form.Item>
					<Form.Item style={{ marginBottom: '0' }}>
						<Button
							block
							htmlType="submit"
							type="primary"
							onClick={handleSaveTemplate}>
							Save
						</Button>
					</Form.Item>
				</Form>
			)}
			{tab === 'Bulk Invite' && (
				<Form layout="vertical">
					<Form.Item>
						<ReactQuill
							modules={{ toolbar: toolbarOptions }}
							theme="snow"
							value={emailMessage}
							onChange={handleEditorChangeEmail}
							className="custom-quill"
						/>
					</Form.Item>
					<Form.Item style={{ marginBottom: '0' }}>
						<Button
							block
							htmlType="submit"
							type="primary"
							onClick={handleSaveTemplate}>
							Save
						</Button>
					</Form.Item>
				</Form>
			)}
		</Space>
	);
};
export default BulkImportEmailTemplate;
