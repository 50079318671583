import { memo, useEffect, useRef } from 'react';
import { gridSpacing, videoSize } from '../constants';
import { UseFullScreen } from '../context/FullScreen.context';

interface Axys {
	x: number;
	y: number;
}

function drawLine(ctx: CanvasRenderingContext2D, a: Axys, b: Axys) {
	ctx.beginPath();
	ctx.moveTo(a.x, a.y);
	ctx.lineTo(b.x, b.y);
	ctx.strokeStyle = '#BDFF00';
	ctx.stroke();
}

function drawSymetrograph(ctx: CanvasRenderingContext2D) {
	const width = videoSize.width;
	const height = videoSize.height;

	ctx.clearRect(0, 0, width, height);

	for (let x = 0; x <= width; x += gridSpacing) {
		drawLine(ctx, { x, y: 0 }, { x, y: height });
	}

	for (let y = 0; y <= height; y += gridSpacing) {
		drawLine(ctx, { x: 0, y }, { x: width, y });
	}
}

function Symetrograph() {
	const canvasSymetrographRef = useRef<HTMLCanvasElement>(null);
	const { isFullScreen } = UseFullScreen();

	useEffect(() => {
		drawSymetrograph(
			canvasSymetrographRef.current?.getContext(
				'2d',
			) as CanvasRenderingContext2D,
		);
	}, []);

	return (
		<canvas
			id="canvasSymetrograph"
			ref={canvasSymetrographRef}
			width={videoSize.width}
			height={videoSize.height}
			style={{
				maxWidth: isFullScreen ? '100%' : 1280,
				height: isFullScreen ? '100%' : 720,
				aspectRatio: '16/9',
				width: '100%',
				zIndex: 3,
				position: 'absolute',
				pointerEvents: 'none',
				top: 0,
				left: 0,
			}}
		/>
	);
}

export default memo(Symetrograph);
