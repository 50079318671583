import { Checkbox, Empty, Modal, message } from "antd"
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useState } from "react"
import { AddSurveyPopupProps, ISurveyQuestion, ISurveyQuestionOptions, QuestionListPayload } from "@stores/interfaces";
import { Check } from "@carespace-icons/general/check";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { createSurvey, createSurveyTemplate, updateSurvey } from "@stores/survey/survey";
import { SurveyQuestionItem } from "./SurveyQuestionItem";
import SurveyPopUpForm from "./SurveyPopUpForm";
import Button from "@atoms/Button";

export const AddSurveyPopup = (props: AddSurveyPopupProps) => {
  const { imgFile, setImgFile, surveyTitle, setSurveyTitle, surveyResultFeedback, setSurveyResultFeedback,
    surveyDescription, setSurveyDescription, surveyInstructions, setSurveyInstructions, questionList, setQuestionList, onOk, onCancel, isEdit, survey, refresh, setRefresh, cancelTriggered, setSelectedSurvey } = props
  const [isSaveTemplate, setSaveTemplate] = useState(false)
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [questionValue, setQuestionValue] = useState('');
  const [deletedQuestionIdsList, setDeletedQuestionIdsList] = useState<{ id: string }[]>([])
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const [questionType, setQuestionType] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (survey) {
      setSurveyTitle(survey?.title!)
      setSurveyDescription(survey?.description!)
      setSurveyInstructions(survey?.instructions!)
      setSurveyResultFeedback(survey?.resultFeedback!)
      setQuestionList(() => {
        const newList = (survey?.questionList || []).map((item) => {
          const sortedOptionList = (item?.optionList || []).map(option => ({
            id: option?.id,
            option: option?.option,
            score: option?.score,
            order: option?.order
          })).sort((a, b) => a.order - b.order);

          return {
            id: item?.id,
            title: item?.title,
            description: item?.description,
            questionType: item?.questionType,
            scored: item?.scored,
            order: item?.order,
            optionList: sortedOptionList
          };
        });

        return newList.sort((a, b) => a.order - b.order);
      });
    }
  }, [survey])

  useEffect(() => {
    if (cancelTriggered) {
      if (checkChanges()) {
        Modal.confirm({
          title: t('Admin.data.survey.discardChangesTitle'),
          content: t('Admin.data.survey.discardChangesContent'),
          onOk: () => {
            onCancel()
            handleClearSelectedSurvey()
          },
        });
      } else {
        handleClearSelectedSurvey()
        onCancel();
      }
    }
  }, [cancelTriggered])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleClearSelectedSurvey = () => {
    setSelectedSurvey(null)
    setImgFile([])
    setSurveyTitle("");
    setSurveyDescription("");
    setSurveyInstructions("")
    setSurveyResultFeedback("")
    setQuestionList([])
  }

  const getTaskPos = (id: string) => questionList?.findIndex((exercise) => exercise.id === id);

  const handleDragEnd = (event: { active: ISurveyQuestion; over: ISurveyQuestion }) => {
    const { active, over } = event;

    if (active.id === over.id) return;
    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    const newArray = [...questionList];
    [newArray[originalPos], newArray[newPos]] = [newArray[newPos], newArray[originalPos]];
    setQuestionList(newArray);
  };

  const validateSurvey = () => {
    message.destroy();
    if (survey?.clinicallyValidated != true) {
      if (!surveyTitle?.trim()) {
        message.warning(t('Admin.data.survey.errSurveyTitle'));
        return;
      }
      if (!surveyDescription?.trim()) {
        message.warning(t('Admin.data.survey.errSurveyDescription'));
        return;
      }
      if (!surveyInstructions?.trim()) {
        message.warning(t('Admin.data.survey.errSurveyInstructions'));
        return;
      }
      if (!surveyResultFeedback?.trim()) {
        message.warning(t('Admin.data.survey.errSurveyResultFeedback'));
        return;
      }
      if (questionList?.length === 0) {
        message.warning(t('Admin.data.survey.errQuestion'));
        return;
      }
      for (const question of questionList) {
        if (question?.questionType !== 'openText') {
          if (!question.optionList && !isEdit) {
            message.warning(t('Admin.data.survey.errOption'));
            return;
          } if ((question.optionList?.length === 0) && question.questionType != "rate") {
            message.warning(t('Admin.data.survey.errOption'));
            return;
          } else if ((question.optionList?.length != 10) && (question.optionList?.some(option => option.option != '' || (question?.scored && isNaN(option.score)))) && question.questionType === "rate") {
            message.warning(t('Admin.data.survey.errRate'));
            return;
          } else if (!question.optionList) {
            if (question.questionType != "rate") message.warning(t('Admin.data.survey.errOption'));
            else message.warning(t('Admin.data.survey.errRate'));

            return;
          }
        }
      }
    }
    isEdit ? handleEditSurvey() : handleCreateSurvey();
  };

  const handleCreateSurvey = async () => {
    const payload = {
      userId: selectedUser?.id,
      physioterapistId: user?.id,
      title: surveyTitle,
      image: survey?.image ? survey?.image : '',
      clinicallyValidated: survey?.clinicallyValidated || false,
      description: surveyDescription,
      instructions: surveyInstructions,
      resultFeedback: surveyResultFeedback,
      questionList: {
        create: questionList?.map((question, index) => {
          return {
            title: question?.title,
            description: question?.description,
            questionType: question?.questionType,
            scored: question?.scored || false,
            order: index + 1,
            optionList: {
              create: question?.optionList?.map((option, index) => {
                return {
                  option: option?.option,
                  ...(question?.scored && { score: option?.score }),
                  order: index + 1,
                }
              }) || []
            },
          }
        })
      },
    };
    const data = await dispatch(createSurvey({
      surveyData: payload,
      images: imgFile!,
    }))
    if (data.payload) {
      onOk()
      message.success(t('Admin.data.survey.surveySuccess'));
      if (isSaveTemplate) {
        handleCreateSurveyTemplate();
        onOk()
      } else {
        setRefresh(!refresh);
        setSurveyTitle("");
        setSurveyDescription("");
        setQuestionList([])
        setSurveyInstructions("")
        setSurveyResultFeedback("")
        setQuestionType(undefined);
        onOk();
        setSaveTemplate(false);
      }
    }
  };

  const handleCreateSurveyTemplate = async () => {
    const payload = {
      title: surveyTitle,
      description: surveyDescription,
      instructions: surveyInstructions,
      resultFeedback: surveyResultFeedback,
      image: survey?.image ? survey?.image : '',
      surveyTemplateQuestion: {
        create: questionList?.map(question => {
          return {
            title: question?.title,
            description: question?.description,
            questionType: question?.questionType,
            scored: question?.scored,
            optionList: {
              create: question?.optionList?.map((option) => {
                return {
                  option: option?.option,
                  ...(question?.scored && {
                    score
                      : option?.score
                  }),
                }
              }) || []
            },
          }
        })
      },
    };

    const data = await dispatch(createSurveyTemplate({
      surveyTemplateData: payload,
      images: imgFile!,
    }));
    if (data.payload) {
      onOk();
      setRefresh(!refresh);
    }
  };


  const handleEditSurvey = async () => {
    let create: ISurveyQuestion[] = [], update: ISurveyQuestion[] = []
    questionList.map((question, index) => {
      if (question?.id?.length > 10) {
        const updateList = {
          id: question?.id,
          title: question?.title,
          description: question?.description,
          questionType: question?.questionType,
          order: index + 1,
          optionList: (() => {
            const create1: ISurveyQuestionOptions[] = [];
            const update1: ISurveyQuestionOptions[] = [];
            let orderCounter = 1;

            question?.optionList?.forEach(option => {
              if (option?.id?.length! < 10) {
                create1.push({
                  option: option.option,
                  score: option.score,
                  order: orderCounter++
                });
              } else {
                update1.push({
                  id: option.id,
                  option: option.option,
                  score: option.score,
                  order: orderCounter++
                });
              }
            });

            return {
              create: create1,
              update: update1,
              delete: question?.deletedOptionList || []
            };
          })()
        };

        update.push(updateList);
      } else {
        const createList = {
          title: question?.title,
          description: question?.description,
          questionType: question?.questionType,
          scored: question?.scored,
          optionList: {
            create: question?.optionList?.map((option, index) => {
              return {
                option: option?.option,
                ...(question?.scored && {
                  score
                    : option?.score
                }),
                order: index + 1,
              }
            }) || []
          }
        }
        create.push(createList)
      }
    })
    const payload = {
      userId: selectedUser?.id,
      title: surveyTitle,
      description: surveyDescription,
      instructions: surveyInstructions,
      resultFeedback: surveyResultFeedback,
      questionList: {
        create: create,
        update: update,
        delete: deletedQuestionIdsList
      }
    };

    const data = await dispatch(updateSurvey({ surveyId: survey?.id!, surveyData: payload }));
    if (data.payload) {
      onOk()
      message.success(t('Admin.data.survey.surveyUpdated'))
      if (isSaveTemplate) {
        handleCreateSurveyTemplate();
      }
    }
  };


  const handleQuestionTitle = (index: number, title: string) => {
    setQuestionList(prevQuestionList => {
      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[index], title: title };
      updatedQuestionList[index] = updatedQuestion;

      return updatedQuestionList;
    })
    message.success(t('Admin.data.survey.questionUpdated'))
  }

  const handleQuestionDelete = (index: number) => {
    if (questionList![index]?.id && questionList![index]?.id?.length > 10) {
      setDeletedQuestionIdsList([...deletedQuestionIdsList, {
        id: questionList![index]?.id
      }])
    }

    setQuestionList(questionList?.filter((_, i) => i != index))
    message.success(t('Admin.data.survey.questionDeleted'))
  };

  const addRatingOptionByIndex = (index: number, ratingOptions: string[]) => {
    setQuestionList(prevQuestionList => {
      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[index], ratingOptions: ratingOptions };
      updatedQuestionList[index] = updatedQuestion;

      return updatedQuestionList;
    })
  }

  const updateOptionByIndex = (questionIndex: number, options: ISurveyQuestionOptions[]) => {
    setQuestionList(prevQuestionList => {
      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[questionIndex], optionList: options };
      updatedQuestionList[questionIndex] = updatedQuestion;

      return updatedQuestionList;
    });
  };

  const addOptionsByIndex = (index: number, options) => {
    setQuestionList(prevQuestionList => {
      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[index], optionList: [...updatedQuestionList[index].optionList || [], options] };
      updatedQuestionList[index] = updatedQuestion;

      return updatedQuestionList;
    });
  };

  const updateOptionTitle = (questionIndex: number, optionIndex: number, title: string, score: number) => {
    setQuestionList(prevQuestionList => {
      const targetQuestion = prevQuestionList[questionIndex];

      const updatedOptionList = [...targetQuestion.optionList];
      const updatedOption = { ...updatedOptionList[optionIndex], option: title, score: score };
      updatedOptionList[optionIndex] = updatedOption;

      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[questionIndex], optionList: updatedOptionList };
      updatedQuestionList[questionIndex] = updatedQuestion;

      return updatedQuestionList;
    });
    message.success(t('Admin.data.survey.optionUpdated'))
  };

  const deleteOptionByIndex = (index: number, optionIndex: number) => {
    setQuestionList(prevQuestionList => {
      const targetQuestion = prevQuestionList![index];
      const updatedOptionList = [...targetQuestion.optionList];
      const [deletedOption] = updatedOptionList.splice(optionIndex, 1);

      let updatedDeletedOptionList = targetQuestion.deletedOptionList ? [...targetQuestion.deletedOptionList] : [];
      if (deletedOption.id) {
        updatedDeletedOptionList.push({ id: deletedOption.id });
      }

      const updatedQuestionList = [...prevQuestionList];
      const updatedQuestion = { ...updatedQuestionList[index], optionList: updatedOptionList, deletedOptionList: updatedDeletedOptionList };
      updatedQuestionList[index] = updatedQuestion;

      return updatedQuestionList;
    });
    message.success(t('Admin.data.survey.optionDeleted'))
  }

  const checkChanges = () => {
    if (survey || surveyTitle || surveyDescription || questionList.length > 0 || imgFile?.length! > 0) {
      if (survey?.title !== surveyTitle || survey?.description !== surveyDescription) {
        return true;
      }

      const surveyQuestions = survey?.questionList;
      if (surveyQuestions?.length !== questionList.length) {
        return true;
      }

      for (let i = 0; i < surveyQuestions.length; i++) {
        const surveyQuestion = surveyQuestions[i];
        const question = questionList[i];

        if (
          surveyQuestion.id !== question.id ||
          surveyQuestion.title !== question.title ||
          surveyQuestion.description !== question.description ||
          surveyQuestion.questionType !== question.questionType ||
          surveyQuestion.order !== question.order ||
          !compareOptionLists(surveyQuestion?.optionList!, question?.optionList!)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const compareOptionLists = (optionList1: ISurveyQuestionOptions[], optionList2: ISurveyQuestionOptions[]) => {
    if (optionList1?.length !== optionList2?.length) {
      return false;
    }

    for (let i = 0; i < optionList1.length; i++) {
      const option1 = optionList1[i];
      const option2 = optionList2[i];

      if (
        option1.id !== option2.id ||
        option1.option !== option2.option ||
        option1.score !== option2.score ||
        option1.order !== option2.order
      ) {
        return false;
      }
    }

    return true;
  };

  return <div className='select-none add-survey-popup'>
    <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
      <p className="template-button-container">
        {survey?.clinicallyValidated && t("Admin.data.survey.clinicallyValidatedSurvey")}
      </p>
    </div>
    <div style={{ maxHeight: "60vh", overflowX: "hidden", overflowY: "auto" }}>
      <SurveyPopUpForm isEdit={isEdit} setImgFile={setImgFile} imgFile={imgFile} surveyTitle={surveyTitle} setSurveyTitle={setSurveyTitle} questionType={questionType}
        surveyDescription={surveyDescription} questionValue={questionValue} setQuestionValue={setQuestionValue} setQuestionType={setQuestionType}
        setQuestionList={setQuestionList} questionList={questionList} surveyInstructions={surveyInstructions} setSurveyInstructions={setSurveyInstructions} surveyResultFeedback={surveyResultFeedback} setSurveyResultFeedback={setSurveyResultFeedback} {...props} />
      {/* <div style={{ maxHeight: "20vh", overflowX: "hidden", overflowY: "auto" }}> */}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={questionList} strategy={verticalListSortingStrategy}>
          {
            questionList?.length! > 0 && questionList?.map((question, index: number) => {
              return (
                <SurveyQuestionItem survey={survey!} question={question} index={index} updateOptionTitle={updateOptionTitle} handleQuestionTitle={handleQuestionTitle} handleQuestionDelete={handleQuestionDelete} addOptionsByIndex={addOptionsByIndex} deleteOptionByIndex={deleteOptionByIndex} addRatingOptionByIndex={addRatingOptionByIndex} updateOptionByIndex={updateOptionByIndex} />
              )
            })
          }
        </SortableContext>
      </DndContext>
      {
        questionList?.length === 0 &&
        <div className="empty">
          <Empty className='empty-image' image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-gray-300'>{t('Admin.data.survey.noQuestionAdded')}</span>} />
        </div>
      }
    </div>
    <div className='template-checkbox'>
      <Checkbox id={"selectSaveTemplate"} checked={isSaveTemplate} onClick={() => { setSaveTemplate(!isSaveTemplate) }} label='' />
      <p className='template-checkbox-text'>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.saveTemplate')}</p>
    </div>
    <Button onClick={validateSurvey} size="full"><Check />&nbsp;
      {survey?.clinicallyValidated ? t('Patient.data.activityStream.send') : t('Admin.data.menu.aiAssistant.aiAssistantPrograms.save')}</Button>
  </div >
}

