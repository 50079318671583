import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface PostSettingApiKeyPayload {
	clientId: string;
	openaiApiKey: string;
}

export const getSettingApiKey = createAsyncThunk(
	'getSettingApiKey',
	async (): Promise<any> => {
		const { data } = await axios.get(`/settings`);
		return data;
	},
);

export const postSettingApiKey = createAsyncThunk(
	'postSettingApiKey',
	async (openaiApiKey: string): Promise<any> => {
		const response = await axios.post('/settings', openaiApiKey);
		return response.data;
	},
);

export const postTheme = createAsyncThunk(
	'postTheme',
	async (payload: {name: string, locked:boolean}): Promise<any> => {
		const response = await axios.post('/settings/themes', payload);
		return response.data;
	},
);

export const getTheme = createAsyncThunk(
	'getTheme',
	async (): Promise<any> => {
		const response = await axios.get('/settings/themes');
		return response.data;
	},
);

export const patchSettingApiKey = createAsyncThunk(
	'patchSettingApiKey',
	async ({
		settingsId,
		payload,
	}: {
		settingsId: string;
		payload: PostSettingApiKeyPayload;
	}): Promise<any> => {
		const response = await axios.patch(`/settings/${settingsId}`, payload);
		return response.data;
	},
);

export const getEmailTemplate = createAsyncThunk(
	'getEmailTemplate',
	async (): Promise<any> => {
		const { data } = await axios.get(`/settings/templates/email`);
		return data;
	},
);

export const postEmailTemplate = createAsyncThunk(
	'postEmailTemplate',
	async (template: string): Promise<any> => {
		const response = await axios.post('/settings/templates/email', {
			templateBody: template,
		});
		return response.data;
	},
);
export const getInviteTemplate = createAsyncThunk(
	'getInviteTemplate',
	async (): Promise<any> => {
		const { data } = await axios.get(`/settings/templates/invite`);
		return data;
	},
);

export const postInviteTemplate = createAsyncThunk(
	'postInviteTemplate',
	async (template: string): Promise<any> => {
		const response = await axios.post('/settings/templates/invite', {
			templateBody: template,
		});
		return response.data;
	},
);

const initialState = {
	clientId: '',
	openaiApiKey: '',
	inviteCode: '',
	emailTemplate: '',
	inviteTemplate: '',
	getTheme: {
			id: "",
			name: "",
			clientId: "",
			createdAt: "",
			updatedAt: "",
			locked:false
	}
};

const settings = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getSettingApiKey.fulfilled, (state, action) => {
			state.openaiApiKey = action.payload;
			state.inviteCode = action.payload?.client?.inviteCode;
		});
		builder.addCase(getSettingApiKey.rejected, (state, action) => {});

		builder.addCase(postSettingApiKey.fulfilled, (state, action) => {
			state.openaiApiKey = action.payload;
		});
		builder.addCase(postSettingApiKey.rejected, (state, action) => {});

		builder.addCase(patchSettingApiKey.fulfilled, (state, action) => {
			state.openaiApiKey = action.payload;
		});
		builder.addCase(patchSettingApiKey.rejected, (state, action) => {});

		builder.addCase(getTheme.fulfilled, (state, action) => {
			state.getTheme = action.payload;
		});
		builder.addCase(getTheme.rejected, (state, action) => {});

		builder.addCase(postTheme.fulfilled, (state, action) => {
			state.getTheme = action.payload;
		});
		builder.addCase(postTheme.rejected, (state, action) => {});

		builder.addCase(getEmailTemplate.fulfilled, (state, action) => {
			state.emailTemplate = action.payload.templateBody;
		});
		builder.addCase(getEmailTemplate.rejected, (state, action) => {});

		builder.addCase(postEmailTemplate.fulfilled, (state, action) => {});
		builder.addCase(postEmailTemplate.rejected, (state, action) => {});

		builder.addCase(getInviteTemplate.fulfilled, (state, action) => {
			state.inviteTemplate = action.payload.templateBody;
		});
		builder.addCase(getInviteTemplate.rejected, (state, action) => {});

		builder.addCase(postInviteTemplate.fulfilled, (state, action) => {});
		builder.addCase(postInviteTemplate.rejected, (state, action) => {});
	},
});

export default settings.reducer;
