import { combineReducers } from '@reduxjs/toolkit';
import mainReducer from '@stores/rom/main';
import resultsReducer from '@stores/rom/results';
import customRomReducer from '@stores/rom/customRom';
import romTemplateReducer from '@stores/rom/romTemplates';

export default combineReducers({
	main: mainReducer,
	results: resultsReducer,
	customRom: customRomReducer,
	romTemplates: romTemplateReducer,
});
