import Cookies from 'js-cookie';
import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useEffect,
} from 'react';
import { useTypedDispatch } from '@stores/index';
import { getTheme } from '@stores/settings/setings';
import { Spin } from 'antd';

interface ThemeContextType {
	theme: string;
	toggleTheme: (newTheme: string) => void;
	loading: boolean;
}

const ThemeContext = createContext<ThemeContextType>({
	theme: 'default',
	toggleTheme: () => {},
	loading: true,
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
	children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
	const dispatch = useTypedDispatch();
	const [theme, setTheme] = useState<string>('default');
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchTheme = async () => {
			try {
				const response = await dispatch(getTheme());
				let selectedTheme = response?.payload?.name || Cookies.get('theme') || 'default';
				setTheme(selectedTheme);
				document.documentElement.setAttribute('data-theme', selectedTheme);
			} catch (error) {
				document.documentElement.setAttribute('data-theme', 'default');
			} finally {
				setLoading(false);
			}
		};

		fetchTheme();
	}, [dispatch]);

	const toggleTheme = (newTheme: string) => {
		setTheme(newTheme);
		document.documentElement.setAttribute('data-theme', newTheme);
		Cookies.set('theme', newTheme, { expires: 365 });
	};

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme, loading }}>
			{loading ? (
				<Spin className="spin-css" tip="Loading" size="large" /> 
			) : (
				children
			)}
		</ThemeContext.Provider>
	);
};
