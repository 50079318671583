import { useTypedSelector } from '@stores/index';
import {
	Options,
	TPainAssessment,
	THealthSignsOptions,
	TStrapiMedicalHistories,
	EvaluationData,
	PainAssessments,
} from './interface';
import moment from 'moment';
import SummuryOptionList from './summaryOptionList';
import { Copy07 } from '@carespace-icons/general/copy07';
import html2pdf from 'html2pdf.js';
import { Tooltip, message } from 'antd';
import { Download01 } from '@carespace-icons/general/download01';
import { TStrapiBodyPoints } from '@molecules/MBodyPoints/interface';
import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface SummaryReviewInterface {
	list?: THealthSignsOptions[];
	medicalHistoryOptionsData?: Options[];
	savedEvaluationData?: EvaluationData;
	bodyOptionsList?: TStrapiBodyPoints;
}

const SummaryReview = (props: SummaryReviewInterface) => {
	const {
		list,
		medicalHistoryOptionsData,
		savedEvaluationData,
		bodyOptionsList,
	} = props;
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const associatedSymptomsData = useTypedSelector(
		state => state.painAssessment.associatedSymptoms,
	);
	const medicalHistoryData = useTypedSelector(
		state => state.painAssessment.medicalHistory,
	);
	const currentDate = moment().format('DDMMYYYY');
	const formattedDate = moment(new Date()).format('MMMM DD, YYYY hh:mm A');

	const copyToClipboard = () => {
		message.success(
			t('Patient.data.virtualEvaluation.summary.copyToClipboard'),
		);
		navigator.clipboard
			.writeText(document.getElementById('summary')!.innerText)
			.catch((error: Error) => console.log(error));
	};

	const downloadPdf2 = async () => {
		message.success(t('Patient.data.virtualEvaluation.summary.downloaded'));
		html2pdf()
			.from(document.getElementById('summary'))
			.set({
				margin: 10,
				filename: `${user.isPhysioterapist ? selectedUser.profile.firstName : user.profile.firstName}-${currentDate}.pdf`,
				image: { type: 'png', quality: 100 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
			})
			.outputPdf()
			.save()
			.catch((error: Error) => {
				console.log(error);
			});
	};
	const { t } = useTranslation();
	return (
		<div>
			<div className="pt-5 pr-5 flex justify-end">
				<Tooltip
					placement="topRight"
					title={t('Patient.data.virtualEvaluation.summary.download')}
					color="purple"
					showArrow={false}>
					<span className="cursor-pointer" onClick={() => downloadPdf2()}>
						<Download01 color="stroke-gray-400" width={20} height={20} />
					</span>
				</Tooltip>
				<Tooltip
					placement="topRight"
					title={t('Patient.data.virtualEvaluation.summary.copy')}
					color="purple"
					showArrow={false}>
					<span
						className="cursor-pointer pl-4"
						onClick={() => copyToClipboard()}>
						<Copy07 color="stroke-gray-400" width={20} height={20} />
					</span>
				</Tooltip>
			</div>
			<div id="summary" className="font-semibold p-6" style={{color: 'var(--text-color-root)'}}>
				<ul className="w-[70vw] gap-4 border-b-2 border-inherit">
					<li className="text-sm text-start leading-7">
						<p className="font-semibold">
							{t('Patient.data.virtualEvaluation.summary.patientID')}{' '}
							<span className="font-regular">
								{user.isPhysioterapist ? selectedUser.id : user.id}
							</span>
						</p>
						<p className="font-semibold">
							{t('Patient.data.virtualEvaluation.summary.patientName')}{' '}
							<span className="font-regular">
								{user.isPhysioterapist
									? selectedUser.profile.firstName +
										' ' +
										selectedUser.profile.lastName
									: user.profile.firstName + ' ' + user.profile.lastName}
							</span>
						</p>
					</li>
				</ul>
				<div className="text-left mt-4 mb-4" style={{color:'var(--text-color-root)'}}>
					<p className="font-semibold">
						{t('Patient.data.virtualEvaluation.summary.dateOfAssessment')}{' '}
						<span className="font-regular">{formattedDate}</span>
					</p>
				</div>
				{savedEvaluationData?.painAssessments &&
				savedEvaluationData.painAssessments.length > 0 ? (
					<>
						<div className="text-left mt-4 mb-4" style={{color:'var(--text-color-root)'}}>
							<span className="font-semibold text-base">
								{t('Patient.data.myProgress.evaluation.painSummary')}
							</span>
						</div>
						<ul className="text-start border-b-2 border-inherit bg-gray-100 rounded-lg border-1 p-3">
							{savedEvaluationData?.painAssessments?.map(
								(item: PainAssessments, index: number) => {
									return (
										<li
											className="grid text-sm mb-2 mt-1 leading-7"
											key={index}>
											<SummuryOptionList item={item} />
										</li>
									);
								},
							)}
						</ul>
					</>
				) : null}
				<div className="text-left mt-4 mb-4" style={{color:'var(--text-color-root)'}}>
					<span className="font-semibold text-base">
						{t('Patient.data.myProgress.evaluation.associatedSymptoms')}
					</span>
				</div>
				<div className="text-left border-b-2 border-inherit bg-gray-100 rounded-lg border-1 p-3">
					<div className="border-b-2 border-inherit pb-2">
						<span className="font-bold text-sm text-gray-700">
							{t(
								'Patient.data.virtualEvaluation.associatedSymptoms.doYouHaveAnyOfTheseSymptoms',
							)}
						</span>
					</div>
					<ul className="text-start">
						{associatedSymptomsData?.strapiHealthSignsIds?.map(
							(id: string, index: number) => {
								const associatedSymptom = list.find(
									(symptom: Options) => symptom.id === id,
								);
								return (
									<li className="grid text-sm mb-1 mt-1 leading-7" key={index}>
										<span className="font-regular">
											{associatedSymptom?.attributes.name}
										</span>
									</li>
								);
							},
						)}
					</ul>
				</div>
				<div className="text-left mt-4 mb-4" style={{color:'var(--text-color-root)'}}>
					<span className="font-semibold text-base">
						{t('Patient.data.myProgress.evaluation.medicalHistory')}
					</span>
				</div>
				<div className="text-left border-b-2 border-inherit bg-gray-100 rounded-lg border-1 p-3">
					<div className="border-b-2 border-inherit pb-2">
						<span className="font-bold text-sm text-gray-700">
							{t(
								'Patient.data.virtualEvaluation.medicalHistory.haveYouBeenDiagnosedWithAnyOfTheFollowing',
							)}
						</span>
					</div>
					<ul className="text-start">
						{medicalHistoryData?.strapiMedicalHistoriesIds?.map(
							(id: string, index: number) => {
								const medicalHistoryName = medicalHistoryOptionsData.find(
									(symptom: Options) => symptom.id == id,
								);
								return (
									<li className="grid text-sm mb-1 mt-1 leading-7" key={index}>
										<span className="font-regular">
											{medicalHistoryName?.attributes.name}
										</span>
									</li>
								);
							},
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};
export default SummaryReview;
