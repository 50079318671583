import { TOnBoardSymptomsProps } from '@stores/interfaces';
import { Button, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';
import { updatePreExistingConditionAgree } from '@stores/onBoard/onBoard';
import { useTypedDispatch, useTypedSelector } from '@stores/index';

const PreExistingConditions = (props: TOnBoardSymptomsProps) => {
	const { setActiveStep, setProgressPercent } = props;
	const { t } = useTranslation();
	const [isChecked, setIsChecked] = useState(false);
  const user = useTypedSelector((state) => state.user)
  const dispatch = useTypedDispatch();
  const selectedUser = useTypedSelector((state) => state.contacts.main.selectedUser);
  const preExistingConditionAgree = useTypedSelector((state) => state.onBoard.onBoard.preExistingConditionAgree)

  useEffect(() => {
    if(user?.profile?.preExistingConditionsRead || selectedUser?.profile?.preExistingConditionsRead){
      setIsChecked(true);
    } else if (preExistingConditionAgree){
      setIsChecked(true);
    }
  },[])

	const handleNextClick = async() => {
    const id = user?.id || selectedUser?.id;
    const savedData = await dispatch(updatePreExistingConditionAgree(id))
		if (savedData.payload) {
			setActiveStep!(6);
			setProgressPercent(60);
		}
	};

	const questions = [
		{
			id: 1,
			text: t('Patient.data.onboard.question1'),
			gradientClass: 'gradient-blue-purple',
		},
		{
			id: 2,
			text: t('Patient.data.onboard.question2'),
			gradientClass: 'gradient-pink-yellow',
		},
		{
			id: 3,
			text: t('Patient.data.onboard.question3'),
			gradientClass: 'gradient-purple-green',
		},
		{
			id: 4,
			text: t('Patient.data.onboard.question4'),
			gradientClass: 'gradient-blue-yellow',
		},
	];

	return (
		<div className="container-div">
			<h2 className="heading">{t('Patient.data.onboard.preExistingTitle')}</h2>
			<span className="subtext">{t('Patient.data.onboard.preExistingDescription1')} <span className="bold-text">{t('Patient.data.onboard.yes')}</span> {t('Patient.data.onboard.preExistingDescription2')}</span>
			<div className="content-wrapper">
				<div className="image-container">
					<img
						src="/images/PreExistingCondition.png"
						alt={t('Patient.data.onboard.preExistingTitle')}
						className="image"
					/>
				</div>
				<div className="questions-container">
					{questions.map(question => (
						<div key={question.id} className="question-item">
							<div className={`question-circle ${question.gradientClass}`}>
								{question.id}
							</div>
							<div className="question-text">{question.text}</div>
						</div>
					))}
				</div>
			</div>
			<div className="checkbox-container">
				<Checkbox
					checked={isChecked}
					onChange={e => setIsChecked(e.target.checked)}>
					{t('Patient.data.onboard.checkboxDescription')}
				</Checkbox>
			</div>
			<div className="button-container-pre-existing">
				<Button
					className="back-button-pre"
					onClick={() => setActiveStep!(2)}
					size="large"
          type='primary'
					block
					style={{ height: '43px', width: '308px' }}>
					{t('Patient.data.onboard.back')}
				</Button>
				<Button
					className="proceed-button-pre"
					size="large"
					block
					type="primary"
					disabled={!isChecked}
					onClick={handleNextClick}
					style={{ height: '43px', background: '#6941C6', color: '#fff', width: '308px'}}>
					{t('Patient.data.onboard.proceed')}
				</Button>
			</div>
		</div>
	);
};

export default PreExistingConditions;
