import { useState } from 'react';

function normalizeValue(value: unknown) {
	return typeof value === 'string' ||
		typeof value === 'number' ||
		typeof value === 'boolean'
		? String(value)
		: JSON.stringify(value);
}

const useLocalStorage = (key: string, defaultValue: unknown) => {
	const [localStorageValue, setLocalStorageValue] = useState(() => {
		try {
			const value = localStorage.getItem(key);

			if (value !== null) {
				try {
					return JSON.parse(value);
				} catch {
					return value as unknown;
				}
			}
			const initialValue = normalizeValue(defaultValue);

			localStorage.setItem(key, initialValue);
			return defaultValue;
		} catch {
			return defaultValue;
		}
	});

	const setLocalStorageStateValue = (valueOrFn: unknown) => {
		try {
			const newValue =
				typeof valueOrFn === 'function'
					? valueOrFn(localStorageValue)
					: valueOrFn;

			const valueToStore = normalizeValue(newValue);

			localStorage.setItem(key, valueToStore);
			setLocalStorageValue(newValue);
		} catch (error) {
			console.error('Erro ao salvar no localStorage:', error);
		}
	};

	return [localStorageValue, setLocalStorageStateValue];
};

export default useLocalStorage;
