import React from 'react';
import CheckBox from '@atoms/CheckBox';
import { useTypedDispatch } from '@stores/index';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import { MedicalHistories, Options } from './interface';

interface MedicalHistoryProps {
	savedMedicalHistoryData: MedicalHistories;
	medicalHistoryOptionsData: Options[];
	setSavedMedicalHistoryData: (value: MedicalHistories) => void;
}

const MedicalHistory: React.FC<MedicalHistoryProps> = ({
	savedMedicalHistoryData,
	medicalHistoryOptionsData,
	setSavedMedicalHistoryData,
}) => {
	const dispatch = useTypedDispatch();

	const setSelectedList = (name: string, value: boolean) => {
		let temp_data =
			(Array.isArray(savedMedicalHistoryData?.strapiMedicalHistoriesIds) &&
				savedMedicalHistoryData?.strapiMedicalHistoriesIds.filter(
					(item) => item != name,
				)) ||
			[];
		if (value) {
			if (value) {
				if (name == 16) {
					temp_data = [];
				}
				temp_data.push(name);
			}
		}
		setSavedMedicalHistoryData({
			...savedMedicalHistoryData,
			strapiMedicalHistoriesIds: temp_data,
		});
		dispatch(painAssessmentInfoAction.medicalHistoryInfo(temp_data));
	};

	return (
		<div className="grid grid-cols-2 gap-4 font-semibold p-4">
			{medicalHistoryOptionsData?.map((option: Options) => (
				<div key={option.id} className="flex items-center">
					<CheckBox
						label={option?.attributes?.name}
						id={option.id}
						onClick={setSelectedList}
						checked={
							Array.isArray(
								savedMedicalHistoryData?.strapiMedicalHistoriesIds,
							) &&
							savedMedicalHistoryData?.strapiMedicalHistoriesIds.includes(
								option.id,
							)
						}
						isDisabled={
							Array.isArray(
								savedMedicalHistoryData?.strapiMedicalHistoriesIds,
							) &&
							savedMedicalHistoryData?.strapiMedicalHistoriesIds.includes(16) &&
							option.id != 16
						}
					/>
					<label className="ml-1"></label>
				</div>
			))}
		</div>
	);
};

export default MedicalHistory;
