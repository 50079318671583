import {
	FilesetResolver,
	NormalizedLandmark,
	PoseLandmarker,
	PoseLandmarkerResult,
} from '@mediapipe/tasks-vision';
import { memo, useCallback, useEffect, useRef } from 'react';
import EventEmitter from '@services/EventEmitter';
import { videoSize } from '../constants';
import { UseFullScreen } from '../context/FullScreen.context';
import { UseSwitchVideo } from '../context/SwitchVideo.context';

let poseLandmarker: PoseLandmarker | null = null;
let animationFrameId: number;
const fps = 120;
const fpsInMiliceconds = 1000 / fps;
let timeOutId: NodeJS.Timeout;

function throttle<T extends (...args: any[]) => void>(
	func: T,
	delay: number,
): (...args: Parameters<T>) => void {
	let lastCall = 0;
	return function (...args: Parameters<T>): void {
		const now = performance.now();
		if (now - lastCall < delay) {
			return;
		}
		lastCall = now;
		func(...args);
	};
}

function Mediapipe() {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { isFullScreen } = UseFullScreen();
	const { cameraId } = UseSwitchVideo();

	const triggerResultsEvent = (
		eventName: string,
		data: NormalizedLandmark[],
	) => {
		EventEmitter.emit(eventName, data);
	};

	const throttledTriggerResultsEvent = throttle(triggerResultsEvent, 250);

	const drawCallback = useCallback(
		(results: PoseLandmarkerResult) => {
			if (!results.landmarks[0]) return;
			throttledTriggerResultsEvent('results', results.landmarks[0]);
		},
		[throttledTriggerResultsEvent],
	);

	const predictWebcam = useCallback(() => {
		if (videoRef?.current && poseLandmarker) {
			timeOutId = setTimeout(() => {
				try {
					poseLandmarker?.detectForVideo(
						videoRef.current as HTMLVideoElement,
						performance.now(),
						drawCallback,
					);
					animationFrameId = requestAnimationFrame(predictWebcam);
				} catch (error) {
					console.error('Error on predictWebcam:', error);
					cancelAnimationFrame(animationFrameId);
					if (timeOutId) clearTimeout(timeOutId);
				}
			}, fpsInMiliceconds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const stopStreamedVideo = useCallback(() => {
		cancelAnimationFrame(animationFrameId);
		if (timeOutId) clearTimeout(timeOutId);

		if (videoRef?.current) {
			const stream = videoRef.current.srcObject as MediaStream;
			if (stream) {
				const tracks = stream.getTracks();

				tracks.forEach(track => {
					track.stop();
				});
			}

			videoRef.current.srcObject = null;
			videoRef.current.removeEventListener('loadeddata', () => {});
		}
	}, []);

	const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

	const setupCamera = useCallback(() => {
		if (!hasGetUserMedia()) {
			console.warn('getUserMedia() is not supported by your browser');
			return;
		}

		if (!poseLandmarker) {
			console.warn('Wait! poseLandmaker not loaded yet.');
			setTimeout(setupCamera, 1000);
			return;
		}

		const constraints = {
			video: {
				deviceId: {
					exact: cameraId as string,
				},
				width: videoSize.width,
				height: videoSize.height,
				frameRate: {
					ideal: 15,
					max: 20,
				},
			},
			audio: false,
		};

		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			if (videoRef?.current) {
				videoRef.current.srcObject = stream;
				videoRef.current.addEventListener('loadeddata', predictWebcam);
			}
		});
	}, [cameraId, predictWebcam]);

	const createPoseLandmarker = useCallback(async () => {
		const vision = await FilesetResolver.forVisionTasks(
			'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm',
		);
		poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
			baseOptions: {
				modelAssetPath:
					'https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_full/float16/latest/pose_landmarker_full.task',
				delegate: 'CPU',
			},
			runningMode: 'VIDEO',
			numPoses: 1,
			minPoseDetectionConfidence: 0.6,
			minTrackingConfidence: 0.6,
		});
	}, []);

	useEffect(() => {
		createPoseLandmarker();
		return () => {
			stopStreamedVideo();
		};
	}, [createPoseLandmarker, stopStreamedVideo]);

	useEffect(() => {
		if (cameraId) {
			stopStreamedVideo();
			setTimeout(() => {
				setupCamera();
			}, 1000);
		}
	}, [cameraId, setupCamera, stopStreamedVideo]);

	return (
		<video
			id="video"
			autoPlay
			playsInline
			ref={videoRef}
			style={{
				maxWidth: isFullScreen ? '100%' : 1280,
				width: '100%',
				aspectRatio: '16/9',
				pointerEvents: 'none',
				height: isFullScreen ? '100vh' : 720,
				objectFit:'cover'
			}}
		/>
	);
}
export default memo(Mediapipe);
