import { Select } from "antd"
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu01 } from "@carespace-icons/general/menu01";
import { MdAdd } from "react-icons/md";
import { PlayCircle } from "@carespace-icons/media/playCircle";
import { showCustomModal } from "@atoms/ACustomModalInfo";
import { IRomTemplateExercise } from "@stores/interfaces";
import { useTranslation } from "react-i18next";

interface AddExerciseItemProps {
  exercise: IRomTemplateExercise
  id: string
  handleRemoveExercise: (id: number | string) => void
  length: number
  updatePropertyAtIndex: (index: number, newReps: number, property: string) => void
  index: number
}

const Option = Select

export const AddExerciseItem = ({ index, updatePropertyAtIndex, length, exercise, id, handleRemoveExercise }: AddExerciseItemProps) => {

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const { t } = useTranslation()
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const image = exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.image?.url : exercise?.image?.url || exercise?.image || exercise?.OmniRomExerciseId?.image?.url
  const video = exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.video?.url : exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.video : exercise?.video?.url || exercise?.video || exercise?.OmniRomExerciseId?.video?.url
  const name = exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.name : exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.title : exercise?.name || exercise.title || exercise?.OmniRomExerciseId?.name
  const description = exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.description : exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.description : exercise.description || exercise?.OmniRomExerciseId?.description

  return <div
    className="flex items-center relative"
    key={exercise.id}
    style={style}
    {...attributes}
  >
    <div
      ref={setNodeRef}
      {...listeners}
      className="p-1 bg-gray-200 h-[100px] flex items-center justify-center"
    >
      <Menu01 color="stroke-gray-600" />
    </div>
    <div className="bg-gray-50 my-2 pl-2 pr-2 pt-1 pb-1 h-[100px] grid grid-cols-3 w-full gap-4 items-center">
      <div className="col-span-2 flex gap-2 items-center">
        <div
          onClick={(e) => {
            e.stopPropagation();
            showCustomModal({
              video: video,
              name: name,
              description: description,
            })
          }}
        >

          <div className="image-wrapper w-40 flex-shrink-0">
            {image ?
              <img src={image} className="rounded-lg w-[160px] h-[90px] object-cover" /> :
              <video src={video} controls={false} className="rounded-lg w-[160px] h-[90px] object-cover" />}
            <div className="play-button">
              <PlayCircle width={50} height={50} />
            </div>
          </div>

        </div>
        <p className="col-span-1 mx-3 font-semibold">{name}</p>
      </div>
      <div className="flex justify-end">
        <div className='flex flex-col'>
          <p className='font-semibold'>{t('Patient.data.omnirom.transitionTime')}</p>
          <div className='flex flex-row'>
            <Select
              className="!w-full !h-[45px] !bg-white rounded-lg border border-[#d9d9d9] hover:border-l hover:border-primary-600 focus:ring-1 focus:ring-primary-200"
              bordered={false}
              onChange={(e) => { updatePropertyAtIndex(index, parseInt(e), 'transitionTime') }}
              value={exercise?.transitionTime?.toString() || "3"}
              placeholder={"Transition Time"}
            >
              <Option value="3">3 {t('Patient.data.omnirom.secs')}</Option>
              <Option value="5">5 {t('Patient.data.omnirom.secs')}</Option>
              <Option value="8">8 {t('Patient.data.omnirom.secs')}</Option>
              <Option value="0">{t('Patient.data.omnirom.manual')}</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
    {length > 1 && <div
      className="p-1 bg-gray-100 h-[100px] flex items-center justify-center"
    >
      <MdAdd onClick={() => handleRemoveExercise(exercise?.id)} className="w-6 h-6 text-white transform rotate-45 rounded-full bg-red-400 hover:bg-red-600" />
    </div>}
  </div>
}