import { Button, Carousel, Popover } from "antd";
import { Key, useRef } from "react";
import { Target04 } from "@carespace-icons/general/target04";
import { useTranslation } from "react-i18next";
import { Calendar } from "@carespace-icons/time/calendar";
import { ChevronLeft } from "@carespace-icons/arrows/chevronLeft";
import { ChevronRight } from "@carespace-icons/arrows/chevronRight";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "@stores/index";

interface TSuggestedProgram {
  id: string
  exercises: {
    strapiExerciseId: {
      exercise_image: {
        url: string;
      }[];
    };
  }[];
  name: string;
  thumbnail: {
    url: string
  }
  functional_goals: {
    name: string;
  }[];
  duration: number;
  durationType: string;
  description: string;
}

const ExistingPatientDashboard = (props: { suggestedProgramData: TSuggestedProgram[], handleEnroll: (item: TSuggestedProgram) => void }) => {
  const { suggestedProgramData, handleEnroll } = props
  const buttonStyle = {
    color: "var(--button-text-color)",
    border: 'inherit',
    width: '200px',
    height: "50px",
    marginTop: '6px',
    marginBottom: '6px'
  };
  const { t } = useTranslation()
  const carouselRef = useRef(null);
  const navigate = useNavigate()
  const user = useTypedSelector(state => state.user);
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <div className="relative">
      <Carousel
        ref={carouselRef}
        autoplay>
        {suggestedProgramData?.map((item: { id: string; exercises: { strapiExerciseId: { exercise_image: { url: string }[]; }; }[]; name: string; thumbnail: { url: string }; functional_goals: { name: string; }[]; duration: number; durationType: string; description: string; }, index: Key) => (
          <div className="relative overflow-hidden cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            navigate(`/${user?.id}/overview`, {
              state: {
                selectedProgram: item,
              }
            })
          }}>
            <div className="relative h-[500px] w-full zoom-animation">
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <img
                src={item?.thumbnail?.url || item?.exercises && item?.exercises[0]?.strapiExerciseId?.exercise_image[0]?.url}
                alt={item?.name}
                className={`h-[500px] w-full ${item?.thumbnail ? "object-cover" : "object-contain"
                  }`}
              />
            </div>
            <div className="absolute bottom-0 left-0 z-10 text-white p-5">
              <div className="flex gap-4">
                <p className="font-sans pt-1 rounded-full text-sm font-bold leading-5 flex items-center justify-center gap-2"><Calendar /> {item?.duration} {item?.durationType
                  ? item?.duration > 1
                    ? item?.durationType.charAt(0).toUpperCase() + item?.durationType.slice(1)
                    : item?.durationType.charAt(0).toUpperCase() + item?.durationType.slice(1).replace(/s$/, "")
                  : ""}</p>
              </div>
              <div className='mb-4'>
                <p className="font-inter text-[60px] font-bold  tracking-[-0.02em] text-left text-white">{item?.name}</p>
                <div className="flex gap-4">
                  {item?.functional_goals && item?.functional_goals[0] && <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 text-center">{item?.functional_goals && item?.functional_goals[0]?.name}</p>}
                  {item?.functional_goals && item?.functional_goals[1] && <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 text-center">{item?.functional_goals && item?.functional_goals[1]?.name}</p>}
                  {item?.functional_goals?.length! > 2 &&
                    <Popover
                      content={
                        <div className="select-none">
                          <div className="text-left">
                            <h3 style={{ fontSize: '1rem' }}>
                              <Target04 width={17} height={17} color="stroke-gray-700" /> {t('Patient.data.newDashboard.functionalGoals')}
                            </h3>
                          </div>
                          <hr style={{ margin: '8px 0' }} />
                          {item?.functional_goals?.map((goal: { name: string }, index: number) => (
                            (
                              <li key={goal.name} className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5">
                                {goal?.name}
                              </li>
                            )
                          ))}</div>} trigger="hover" placement="topLeft" >
                      <p className="font-sans border-2 border-white pt-1 pb-1 pl-4 pr-4 rounded-full text-sm font-bold leading-5 flex items-center cursor-pointer" onClick={(e) => e.stopPropagation()}>
                        {t('Patient.data.newDashboard.showAll')}
                      </p>
                    </Popover>}
                </div>
              </div>
              <Popover
                content={item?.description}
                trigger="hover"
                placement='topLeft'
                overlayInnerStyle={{
                  maxWidth: "50vw",
                  whiteSpace: "normal",
                }}
                overlayStyle={{
                  overflowX: "hidden",
                }}
                autoAdjustOverflow={true}
              >
                <p
                  className="font-sans cursor-pointer text-base font-normal leading-6 text-left text-gray-400 w-[50%] mb-4 line-clamp-2"
                >
                  {item?.description}
                </p>
              </Popover>
              <Button
                className="start-session-css"
                style={buttonStyle}
                onClick={(e) => {
                  e.stopPropagation()
                  handleEnroll(item)
                }}
              >
                <span className='font-sans text-[22px] font-bold leading-[38px] text-left'>{t('Patient.data.newDashboard.enroll')}</span>
              </Button>
            </div>
          </div>
        ))}
      </Carousel>
      <span
        className="p-2 absolute top-1/2 left-2 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
        onClick={scrollLeft}
      >
        <ChevronLeft width={30} height={30} />
      </span>
      <span
        className="p-2 absolute top-1/2 right-2 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
        onClick={scrollRight}
      >
        <ChevronRight width={30} height={30} />
      </span>
    </div>
  );
};

export default ExistingPatientDashboard;
