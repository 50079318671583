import { Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectType {
	name: string;
	onChange: (name: string, value: string | string[]) => void;
	multiple?: boolean;
	defaultValue?: string | [];
	options?: [];
	isError?: boolean;
}

const SelectCustom = (props: SelectType) => {
	const { name, onChange, multiple, defaultValue, options, isError } = props;

	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleChange = (value: string | string[]) => {
		setOpen(false);
		onChange(name, value);
	};

	useEffect(() => {
		handleChange(defaultValue);
	}, [defaultValue]);

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				{multiple ? (
					<Select
						mode="multiple"
						size="large"
						placeholder={t(
							'Patient.data.virtualEvaluation.painAssesment.selectAnOption',
						)}
						defaultValue={defaultValue}
						onChange={handleChange}
						style={{ width: '100%' }}
						open={open}
						options={options}
						showSearch={false}
						status={isError ? 'error' : ''}
						onDropdownVisibleChange={setOpen}
					/>
				) : (
					<Select
						size="large"
						placeholder={t(
							'Patient.data.virtualEvaluation.painAssesment.selectAnOption',
						)}
						defaultValue={defaultValue}
						onChange={handleChange}
						style={{ width: '100%' }}
						options={options}
						showSearch={false}
						status={isError ? 'error' : ''}
					/>
				)}
			</Space>
		</>
	);
};

export default SelectCustom;
