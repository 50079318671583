import { Button } from 'antd'
import './style.css'
import MBodyPoints from '../MBodyPoints';
import { useEffect, useState } from 'react';
import { EvaluationData, PainAssessments } from '@pages/Home/interface';
import { TOnBoardSymptomsProps } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getEvaluationData } from '@stores/myLibrary/myLibrary';

export default function PainAssessment(props: any) {
  const { setActiveStep, setProgressPercent } = props
  const [isLoading, setIsLoading] = useState(true);
  const [savedBodyPointData, setSavedBodyPointData] = useState<number[]>([]);
  const [savedEvaluationData, setSavedEvaluationData] = useState<EvaluationData>();
  const { t } = useTranslation()
  const dispatch = useTypedDispatch();
  const user = useTypedSelector((state) => state.user);
  const painAssessmentInfo = useTypedSelector((state) => state.painAssessment.painAssessment);

  useEffect(() => {
    fetchSavedData();
  }, [user])

  const fetchSavedData = async () => {
    let id = user.id;
    const payload = {
      userId: id,
      page: 1,
      limit: 1,
    };
    const data = await dispatch(getEvaluationData(payload));
    const apiData = data.payload;
    if (apiData.data.length > 0) {
      setSavedEvaluationData(apiData.data[0]);
      setSavedBodyPointData(
        apiData.data[0].painAssessments.map(
          (element: PainAssessments) => element.strapiBodyPointId,
        ),
      );
      // setSavedSymptomsData(apiData.data[0].healthSigns);
      // setSavedMedicalHistoryData(apiData.data[0].medicalHistories);
    };
  }

  const handleNextClick = () => {
    setActiveStep(4);
    setProgressPercent(40);
  }

  return (
    <>
      <div className='medical-container'>
        <h1 style={{ fontSize: '25px', color: '#6941C6', fontWeight: '700' }}>
          {t('Patient.data.virtualEvaluation.painAssesment.title')}
        </h1>
        <h3 style={{ fontSize: '18px', color: '#98A2B3', fontWeight: '300', marginBottom: '20px' }}> {t('Patient.data.virtualEvaluation.painAssesment.description')}</h3>
      </div>
      <div className="pain-assessment select-none">
        <MBodyPoints
          assessmentData={savedEvaluationData?.painAssessments || []}
          savedBodyPointData={savedBodyPointData}
          savedEvaluationData={savedEvaluationData}
          setSavedEvaluationData={setSavedEvaluationData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
      <div className='medical-container'>
        <Button className="mt-4 mr-2 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6" onClick={() => { setActiveStep(2) }} size="large" type="primary" block style={{ height: '43px', width: "290px" }}>
          {t('Patient.data.onboard.back')}
        </Button>&nbsp;
        <Button className="rounded-full font-inter text-base font-bold leading-6" size="large" type="primary" block style={{ height: '43px', background: "#6941C6", color: "#fff", width: "290px" }} onClick={handleNextClick}>
          {t('Patient.data.onboard.next')}
        </Button>
      </div>
    </>
  )
}
