import { Button } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"

interface GestureControlProps {
  setGestureEnabled: (isGestureEnabled: boolean) => void
  setActiveStep: (activeStep: number) => void
  activeStep: number
  savedVoice: string
  setSavedVoice: (savedVoice: string) => void
}

export const GestureControl = ({ setGestureEnabled, setActiveStep, activeStep, savedVoice, setSavedVoice }: GestureControlProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (activeStep === 4) {
      if (savedVoice.toLowerCase().includes("cancel") || savedVoice.toLowerCase().includes("cancal")) handleNo()
      else if (savedVoice.toLowerCase().includes("ok") || savedVoice.toLowerCase().includes("okay") || savedVoice.toLowerCase().includes("ok.")) handleYes()
    }
  }, [savedVoice])

  const handleNo = () => {
    setGestureEnabled(false)
    setActiveStep(-1)
  }

  const handleYes = () => {
    setGestureEnabled(true)
    setActiveStep(-1)
  }

  const handleBackArrow = () => {
    setSavedVoice('');
    setActiveStep(activeStep - 1)
  }

  return <div className="flex flex-col items-center select-none">
    <div className=" mt-[130px] mb-[15px] w-[468px]">
      <div className="flex justify-around items-center">
        <Button
          className="!rounded-full flex justify-center items-center"
          onClick={() => handleBackArrow()}
          icon={<FaArrowLeft />}
        />
        <p className="w-[280px] text-white text-lg font-semibold text-center m-auto ml-[72px]">{t('Admin.data.rehab.rehabPreAssessment.gestureControl')}</p>
      </div>
      <p className="text-white text-base mt-2.5 text-center">{t('Admin.data.rehab.rehabPreAssessment.gestureControlDescription')}</p>
    </div>
    <div className="flex justify-center gap-[25px] p-[15px]">
      <Button
        className="!w-[100px] !h-11 !bg-transparent border-2 !border-white hover:!border-white !text-white hover:!bg-success-500 flex justify-center items-center !font-semibold !text-base !rounded-full"
        onClick={() => handleNo()}
      >
        {t('Admin.data.rehab.rehabPreAssessment.cancelText')}
      </Button>
      <Button
        className="!w-[100px] !h-11 !bg-transparent border-2 !border-white hover:!border-white !text-white hover:!bg-warning-300 flex justify-center items-center !font-semibold !text-base !rounded-full"
        onClick={() => handleYes()}
      >
        {t('Admin.data.rehab.rehabPreAssessment.okText')}
      </Button>
    </div>
  </div>
}