import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { ICoachRehabInitialState, ICoachRehabCoach, ICoachRehabExercises, IRehabPreAssesment } from '@stores/interfaces'

export const getCoach = createAsyncThunk(
	'getCoach',
	async (dataToRequest: string): Promise<ICoachRehabCoach> => {
		const { data } = await axios.get(`/rehab/patient-results/${dataToRequest}`)
		return data
	}
)

export const getExercises = createAsyncThunk(
	'getExercises',
	async (): Promise<ICoachRehabExercises> => {
		const { data } = await axios.get(`/rehab/exercises`)
		return data
	}
)

export const postPreAssesmentResult = createAsyncThunk(
	'postPreAssesmentResult', 
	async (preassessment: IRehabPreAssesment) => {
	const { data } = await axios.post(`/rehab/sessions`, preassessment)
	return data
})

export const postResult = createAsyncThunk('postResult', async (assessment) => {
	const { data } = await axios.post(`/rehab/assessments`, assessment)
	return data
})

const initialState: ICoachRehabInitialState = {
	coach: null,
	exercises: null,
	result: [],
}

export const coachRehab = createSlice({
	name: 'coachRehab',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCoach.fulfilled, (state, action) => {
			state.coach = action.payload
		})
		builder.addCase(getCoach.rejected, (state, action) => {})

		builder.addCase(getExercises.fulfilled, (state, action) => {
			state.exercises = action.payload
		})
		builder.addCase(getExercises.rejected, (state, action) => {})

		builder.addCase(postResult.fulfilled, (state, action) => {
			const data = action.payload
			return {
				...state,
				result: { data, ...state.result },
			}
		})
		builder.addCase(postResult.rejected, (state, action) => {})
	},
})

export default coachRehab.reducer
