import { Target04 } from '@carespace-icons/general/target04';
import { USER_ROLES } from '@stores/constants';
import { useTypedSelector } from '@stores/index';
import { Popover } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface DataProps {
	gender: string;
	height: number;
	isPregnant: boolean;
	patientId: string;
	weight: number;
}

interface FunctionalGoalsProps {
	id: string;
	userId: string;
	functionalGoalsIds: [];
	active: boolean;
	createdAt: string;
	updatedAt: string;
}

interface UserDetailsProps {
	active: boolean;
	birthDate: Date;
	data: DataProps;
	email: string;
	firstName: string;
	fullName: string;
	id: string;
	imageUrl: string;
	lastName: string;
	fusionAuthUserId: string;
	functionalGoals: FunctionalGoalsProps[];
	role: string;
}

const UserInfo = (props: any) => {
	const { userDetails, functionalData } = props;
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user);
	const isAdmin = user.profile.role === USER_ROLES.ADMIN;
	const FunctionalGoals = useTypedSelector(
		state => state.functionalGoals.functionalGoals,
	);

	const popoverContent = (
		<div className="text-white select-none">
			{functionalData?.length > 0 ? (
				FunctionalGoals?.data
					?.filter(item => functionalData.includes(item.id))
					.map(filteredItem => (
						<div key={filteredItem.id} className="flex gap-2 mb-1">
							<p className="rounded-full bg-white text-black h-2 w-2 mt-2"></p>
							<p>{filteredItem.attributes.name}</p>
						</div>
					))
			) : (
				<p>{t('Admin.data.managePatient.noFunctionalGoals')}</p>
			)}
		</div>
	);

	const formatBirthdate = (birthdate: Date) => {
		if (!birthdate) return 'N/A';
		const birthdateDate = new Date(birthdate);
		const options = { month: 'long', day: 'numeric', year: 'numeric' };
		const formattedBirthdate = moment(birthdate).format('MMMM DD, YYYY');
		const age = calculateAge(birthdateDate);
		return `${formattedBirthdate} (Age: ${age})`;
	};

	const calculateAge = (birthdate: Date) => {
		const today = new Date();
		const birthdateDate = new Date(birthdate);
		let age = today.getFullYear() - birthdateDate.getFullYear();
		const monthDiff = today.getMonth() - birthdateDate.getMonth();
		if (
			monthDiff < 0 ||
			(monthDiff === 0 && today.getDate() < birthdateDate.getDate())
		) {
			age--;
		}
		return age;
	};

	const formatHeight = (height: number) => {
		if (!height) return 'N/A';
		// const feet = Math.floor(height / 12);
		// const inches = height % 12;
		return `${height}`;
	};

	const formatWeight = (weight: number) => {
		if (!weight) return 'N/A';
		// const lbs = weight * 2.20462;
		// const roundedLbs = Math.round(lbs);
		return `${weight}lb`;
	};

	const getPopupContainer = () =>
		document.getElementById('blackPopoverContainer') || document.body;

	return (
		<div className={isAdmin ? 'flex gap-11' : 'flex gap-14'}>
			<ul className={isAdmin ? 'flex gap-11' : 'flex gap-14'}>
				<li>
					<p className="text-xs font-regular">
						{t('Admin.data.managePatient.birthdate')}
					</p>
					<p className="text-sm font-semibold mt-1">
						{formatBirthdate(userDetails?.profile?.birthDate)}
					</p>
				</li>
				<li>
					<p className="text-xs font-regular">
						{t('Admin.data.managePatient.weight')}
					</p>
					<p className="text-sm font-semibold mt-1">
						{formatWeight(userDetails?.profile?.imperialWeight || userDetails?.profile?.metricWeight)}
					</p>
				</li>
				<li>
					<p className="text-xs font-regular">
						{t('Admin.data.managePatient.height')}
					</p>
					<p className="text-sm font-semibold mt-1">
						{formatHeight(userDetails?.profile?.imperialHeight || userDetails?.profile?.metricHeight)}
					</p>
				</li>
				<li>
					<p className="text-xs font-regular">
						{t('Admin.data.managePatient.gender')}
					</p>
					<p
						className="text-sm font-semibold mt-1"
						style={{ textTransform: 'capitalize' }}>
						{userDetails?.profile?.gender || 'N/A'}
					</p>
				</li>
				{userDetails?.profile?.patientId && (
					<li>
						<p className="text-xs font-regular">
							{t('Admin.data.managePatient.patientId')}
						</p>
						<p className="text-sm font-semibold mt-1">
							{userDetails?.profile?.patientId || 'N/A'}
						</p>
					</li>
				)}
			</ul>
			<ul>
				<div id="blackPopoverContainer" className="cursor-pointer mt-1">
					<Popover
						placement="bottomRight"
						content={<div>{popoverContent}</div>}
						trigger="click"
						getPopupContainer={getPopupContainer}
						overlayInnerStyle={{
							backgroundColor: 'black',
							paddingLeft: '20px',
							paddingRight: '20px',
						}}>
						<li className="border border-gray-300 flex rounded-md p-2 gap-2 ">
							<Target04 width={17} height={17} color="stroke-gray-700" />
							<p className="text-sm font-semibold text-gray-700 -mt-[2px]">
								{t('Admin.data.managePatient.functionalGoals')}
							</p>
						</li>
					</Popover>
				</div>
			</ul>
		</div>
	);
};
export default UserInfo;
