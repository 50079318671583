import { BrowserRouter, useSearchParams } from 'react-router-dom';

import Public from '@routers/Public';
import Private from '@routers/Private';
import { AuthProvider, UseAuth } from '@contexts/AuthContext';
import Loading from '@atoms/Loading';
import { useEffect, useState } from 'react';

function Routers() {
	const [searchParams] = useSearchParams();
	const { isLoading, isAuthenticated, onLogin } = UseAuth();
	const [isRequestingLogin, setRequestLogin] = useState(isAuthenticated);
	const code = searchParams.get('code');
	const token = searchParams.get('token');
	const refreshToken = searchParams.get('refreshToken');

	useEffect(() => {
		if (
			!code &&
			!token &&
			!refreshToken &&
			!isAuthenticated &&
			!isRequestingLogin &&
			!isLoading
		) {
			onLogin();
			setRequestLogin(true);
		}
	}, [
		isAuthenticated,
		isRequestingLogin,
		isLoading,
		onLogin,
		code,
		token,
		refreshToken,
	]);

	return isAuthenticated ? <Private /> : <Loading />;
}

function Index() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Routers />
				<Public />
			</AuthProvider>
		</BrowserRouter>
	);
}

export default Index;
