import { IRomPatientResult } from '@stores/interfaces';
import CustomSummaryContent from './CustomSummaryContent';
import CustomSummaryContentResult from './CustomSummaryContentResult';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

interface ICustomSummarySelectionContent {
  item: IRomPatientResult[];
}

const CustomSummarySelectionContent = (props: ICustomSummarySelectionContent) => {
  const { item } = props;
  const { t } = useTranslation()

  const hasResults = item.some((exercise) => exercise.results && exercise.results.length > 0);
  const hasRomExercise = item.some((exercise) => exercise.romProgramexercise && exercise.romProgramexercise.id);


  return (
    <>
      {hasResults ? (
        <CustomSummaryContentResult item={item} />
      ) : hasRomExercise ? (
        <CustomSummaryContent item={item} />
      ) : (
        <div className="coach-rom-summary-container-row-div">
          <img
            style={{ width: 100, height: 100 }}
            className="coach-rom-summary-container-row-div-img"
            src="/images/NoExe.png"
            alt="No Exercises Image"
          />
          <h1 className="coach-rom-summary-container-row-div-h1">
            {t('Patient.data.myProgress.omniRom.empty')}
          </h1>
        </div>
      )}
    </>
  );
};

export default CustomSummarySelectionContent;
