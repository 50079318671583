import React, { useState } from 'react';
import { Modal, Input, Button, Form, message, Checkbox } from 'antd';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { updateUserPassword } from '@stores/user';
import { useTranslation } from 'react-i18next';

interface IResetPasswordModalProps {
	open: boolean;
	onClose: () => void;
	userId: string;
}

interface IResetPasswordFormData {
	newPassword: string;
	confirmPassword: string;
	forceChangePassword: boolean;
}

const ResetPasswordModal = ({
	open,
	onClose,
	userId,
}: IResetPasswordModalProps) => {
	const { t } = useTranslation();
	const { user } = useTypedSelector(state => ({
		user: state.user,
	}));
	const [loading, setLoading] = useState(false);
	const dispatch = useTypedDispatch();
	const onFinish = async (data: IResetPasswordFormData) => {
		if (data.newPassword !== data.confirmPassword) {
			message.error(
				t('Admin.data.menu.userRoles.registeredPatients.passwordMismatch'),
			);
			return;
		}
		setLoading(true);
		await dispatch(
			updateUserPassword({
				userId: userId,
				data: {
					password: data.newPassword,
					confirmPassword: data.confirmPassword,
					forceChangePassword: data?.forceChangePassword || false,
				},
			}),
		);
		setLoading(false);
		onClose();
	};
	return (
		<Modal
			title={
				<div style={{ textAlign: 'center', width: '100%' }}>Reset Password</div>
			}
			open={open}
			onCancel={onClose}
			footer={null}>
			<Form layout="vertical" onFinish={onFinish}>
				<Form.Item
					label={t('Admin.data.menu.userRoles.registeredPatients.newPassword')}
					name="newPassword"
					rules={[
						{
							required: true,
							message: t(
								'Admin.data.menu.userRoles.registeredPatients.notEmptyPasswordRule',
							),
						},
						{
							min: 8,
							message: t(
								'Admin.data.menu.userRoles.registeredPatients.minimumPasswordLengthRule',
							),
						},
					]}>
					<Input.Password
						className="input-item"
						placeholder={t(
							'Admin.data.menu.userRoles.registeredPatients.enterNewPassword',
						)}
					/>
				</Form.Item>
				<Form.Item
					label={t(
						'Admin.data.menu.userRoles.registeredPatients.confirmPassword',
					)}
					name="confirmPassword"
					dependencies={['newPassword']}
					rules={[
						{
							required: true,
							message: t(
								'Admin.data.menu.userRoles.registeredPatients.confirmPasswordRule',
							),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(
										t(
											'Admin.data.menu.userRoles.registeredPatients.passwordMismatch',
										),
									),
								);
							},
						}),
					]}>
					<Input.Password
						className="input-item"
						placeholder={t(
							'Admin.data.menu.userRoles.registeredPatients.confirmNewPassword',
						)}
					/>
				</Form.Item>
				{user?.isPhysioterapist && <Form.Item
					className="flex items-center justify-center"
					name="forceChangePassword"
					valuePropName="checked">
					<Checkbox>
						{t(
							'Admin.data.menu.userRoles.registeredPatients.forcePasswordChangeText',
						)}
					</Checkbox>
				</Form.Item>}
				<Form.Item
					className="flex items-center justify-center"
					style={{ margin: '12px' }}>
					<Button
						size="large"
						htmlType="submit"
						type="primary"
						loading={loading}>
						Update
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ResetPasswordModal;
