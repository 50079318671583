import { Empty, Pagination, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { CreateSurveyModal } from "./CreateSurveyModal";
import './style.css';
import { getSurvey, getSurveyTemplate, updateSurvey } from "@stores/survey/survey";
import { ISurvey, ISurveyQuestion } from "@stores/interfaces";
import { EditSurveyCard } from "./EditSurveyCard";
import { useLocation } from "react-router-dom";
import { setSurveyModal } from "@stores/aiAssistant/aiAssistant";

export const AiAssistantStartSurvey = () => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState(false)
  const survey = useTypedSelector(state => state.survey.survey.survey);
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | null>()
  const [previousQuestion, setPreviousQuestion] = useState<ISurveyQuestion[]>([])
  const [surveyEdit, setSurveyEdit] = useState(false)
  const [openSurveyModal, setOpenSurveyModal] = useState(false)
  const [surveyData, setSurveyData] = useState<ISurvey[] | null>(survey?.data)
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const { state } = useLocation()
  const surveyModal = useTypedSelector(state => state.aiAssistant.aiAssistant.surveyModal)

  useEffect(() => setSurveyData(survey?.data), [survey, refresh])

  useEffect(() => {
    setOpenSurveyModal(state?.openSurveyModal || false)
  }, [state])

  useEffect(() => {
    setOpenSurveyModal(surveyModal)
  }, [surveyModal])

  useEffect(() => {
    fetchHomeData(1)
  }, [user, selectedUser, refresh])

  const fetchHomeData = async (pageNumber: number) => {
    const payload = {
      userId: user.isPhysioterapist ? selectedUser.id : user.id,
      limit: 10,
      page: pageNumber
    }
    await dispatch(getSurvey(payload))
    setLoading(false)
  }

  const fetchData = (pageNumber: number, searchValue: string) => {
    const payload = {
      limit: 10,
      page: pageNumber,
      search: searchValue ? searchValue : ""
    }
    dispatch(getSurveyTemplate(payload)).then(() => setLoading(false))
  }

  const updateSelectedSurveyQuestions = (questionList: ISurveyQuestion[]) => {
    setSelectedSurvey((prevState) => {
      return prevState ? { ...prevState, questionList: questionList } : prevState;
    });
  }

  const handleCancel = () => {
    setOpenSurveyModal(false)
    setSelectedSurvey(null)
    setModalVisible(false)
  }

  const handleApproveSurvey = async (survey: ISurvey, value: boolean) => {
    const surveyData = {
      userId: selectedUser?.id,
      physioterapistId: user?.id,
      active: value,
      status: value ? 'approved' : 'draft'
    }
    await dispatch(updateSurvey({
      surveyId: survey?.id,
      surveyData: surveyData
    })).then(() => value ? message.success(t('Admin.data.survey.surveyApprove')) : message.success(t('Admin.data.survey.surveyDisapprove')))
  }

  return <div className="ai-assistant-start-survey">
    {
      user.isPhysioterapist && <div
        className="create-survey-button"
        onClick={() => { setSurveyEdit(false); setOpenSurveyModal(true) }}
      >
        {t('Admin.data.survey.createSurvey')}
      </div>
    }
    <CreateSurveyModal
      isVisible={openSurveyModal}
      onCancel={() => {
        setOpenSurveyModal(false)
        setRefresh(!refresh)
        dispatch(setSurveyModal(false))
      }}
      fetchData={fetchData}
      refresh={refresh}
      setRefresh={setRefresh}
      previousQuestion={previousQuestion}
      setPreviousQuestion={setPreviousQuestion}
      fetchHomeData={fetchHomeData}
      selectedSurvey={selectedSurvey!}
      setSelectedSurvey={setSelectedSurvey}
      surveyEdit={surveyEdit}
      setSurveyEdit={setSurveyEdit}
    />
    {isLoading ? <Spin className="flex items-center justify-center p-2" tip="Loading" size="large" /> :
      <><div>
        {
          survey?.data?.length == 0
            ? <Empty className='empty' image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ color: "#D1D5DB" }}>{t('Admin.data.survey.noSurvey')}</span>} />
            : <div className="card-list">
              {surveyData?.map((item: ISurvey, index: number) => {
                return <EditSurveyCard surveyEdit={surveyEdit} setSurveyEdit={setSurveyEdit} survey={survey} fetchHomeData={fetchHomeData} item={item} setSelectedSurvey={setSelectedSurvey} setModalVisible={setModalVisible} handleApproveSurvey={handleApproveSurvey} />
              })}
            </div>
        }
        {
          survey?.data?.length != 0 && <div>
            <Pagination
              current={survey?.pagination?.currentPage}
              showSizeChanger={false}
              onChange={fetchHomeData}
              total={survey?.pagination?.totalCount}
            />
          </div>
        }
      </div>
      </>}
    {
      isModalVisible && <CreateSurveyModal
        isEdit={true}
        onOk={() => {
          setModalVisible(false)
          handleCancel()
          fetchData(1, '')
          setRefresh(!refresh)
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        isVisible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        selectedQuestion={selectedSurvey?.questionList!}
        setSelectedQuestion={updateSelectedSurveyQuestions}
        selectedSurvey={selectedSurvey!}
        setSelectedSurvey={setSelectedSurvey}
        surveyEdit={surveyEdit}
        setSurveyEdit={setSurveyEdit}
      />
    }
  </div>
}