import { GroupedData } from "@stores/interfaces"
import moment from "moment"
import { useTranslation } from "react-i18next"
import AddNotes from "./AddNotes"
import { useState } from "react"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { updateReportNotes } from "@stores/report/reports"
import { message } from "antd"
import ReportNotesTemplate from "./ReportNotes/ReportNotesTemplate"
import ReportNotesDownloadTemplate from "./ReportNotes/ReportNotesDownloadTemplate"
import CustomSummaryContentResult from "@pages/AiAssistantCustomRomSummary/CustomSummaryContentResult"

interface ReportRomSummaryDataProps {
  isEditMode: boolean
  isDownload?: boolean
}

export default function ReportRomSummaryData(props: ReportRomSummaryDataProps) {
  const { isEditMode, isDownload } = props
  const { t } = useTranslation();
  const [romSesssionNotes, setRomSessionNotes] = useState('')
  const report = useTypedSelector(state => state.reports.report)
  const allEmpty = report?.romSessionsIds?.every(summary => summary?.romPatientResults?.length === 0);
  const [groupData, setGroupData] = useState<GroupedData[]>([]);
  const dispatch = useTypedDispatch()

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      romSessionsNotes: {
        notes: romSesssionNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setRomSessionNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('romSessionsNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId || ''
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  const calculateScore = (normal: number, value: number): number => {
    if (value === 0) {
      return 0;
    }
    const score = Math.round((value / normal) * 100);
    const valueScore = score > 100 ? 100 : score
    return isFinite(valueScore) ? valueScore : 0;
  };

  const calculateRanking = (score: number): number => {
    if (score >= 91) return 10;
    if (score >= 81) return 9;
    if (score >= 71) return 8;
    if (score >= 61) return 7;
    if (score >= 51) return 6;
    if (score >= 41) return 5;
    if (score >= 31) return 4;
    if (score >= 21) return 3;
    if (score >= 11) return 2;
    if (score >= 1) return 1;
    return 0;
  };

  const rankingColor = (ranking: number) => {
    if (ranking >= 1 && ranking <= 6) return '#F04438';
    if (ranking >= 7 && ranking <= 9) return '#F97066';
    if (ranking === 10) return '#3CCB7F';
    return 'gray';
  };

  return (
    <>
      {!isDownload ?
        <div className='pb-2'>
          {report?.romSessionsIds && !allEmpty ? (
            <>
              {report?.romSessionsIds?.map((item) => {
                return (<>
                  {item?.romPatientResults?.length! > 0 && <>
                    <p className="font-bold text-base text-gray-600 mt-5 text-center">{t('Admin.data.addToReports.romSummaryData')}</p>

                    <div>
                      <p className='py-[15px] text-gray-600'><b className='font-bold'>{t('Admin.data.addToReports.sessionDate')}</b> {moment(item.createdAt).local().format('LLL')}</p>
                      <CustomSummaryContentResult item={item?.romPatientResults!} />
                    </div></>}
                </>
                )
              })}
              {
                report?.romSessionsNotes?.some(
                  (note) =>
                    note.notes?.trim()
                ) && (<>
                  {report?.romSessionsNotes?.length! > 0 ? (
                    <>
                      {!isDownload && <>
                        <div className="text-left mt-3 mb-3">
                          <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
                        </div>
                        {report?.romSessionsNotes?.map((note, index) => {
                          return <ReportNotesTemplate
                            index={index}
                            isEditMode={isEditMode}
                            note={note}
                          />
                        })}</>}
                    </>) : null}</>)}
            </>) : null}
          {
            report?.romSessionsIds?.length != 0 ?
              <AddNotes sendMessage={sendMessage} onClick={() => handleSubmit()} onChange={(event) => setRomSessionNotes(event.target.value)} />
              : null
          }
        </div> :
        <>
          <div className='pb-2'>
            <p className="font-bold text-base text-gray-600 mt-5 text-center">{t('Admin.data.addToReports.romSummaryData')}</p>
            {report?.romSessionsIds?.map((data) => {
              return (
                <>{data?.romPatientResults?.length! > 0 &&
                  <>
                    <div>
                      <p className='py-[15px] text-gray-600'><b className='font-bold'>{t('Admin.data.addToReports.sessionDate')}</b> {moment(data.createdAt).local().format('LLL')}</p>
                      {data?.romPatientResults?.map((data) => {
                        return (<>
                          <div className='p-[15px] my-2.5 bg-gray-100 mt-1 rounded-lg border-1 border-inherit flex flex-col gap-2 text-sm text-gray-500'>
                            {
                              <div className='container '>
                                {data?.title && <p className='font-normal'><b className='font-bold'>Program Name :</b>{data?.title?.toUpperCase() || "Standard Motion"}</p>}
                                <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.screenshot')} </b><a href={data?.screenshot} target='_blank'>{data?.screenshot}</a></p>
                                {data?.results?.map((data) => {
                                  const score = calculateScore(data?.romProgramExercise?.normal, data?.value);
                                  const ranking = calculateRanking(score);
                                  const scoreColor = () => {
                                    if (score >= 100) return '#32D583';
                                    if (score >= 60 && score < 100) return '#F97066';
                                    if (score >= 0 && score < 60) return '#F04438';
                                    return 'gray';
                                  };
                                  return (<>
                                    <p className='font-normal'><b className='font-bold'>Exercise Name :</b>{data?.strapiOmniRomExercise?.name || data?.romProgramExercise?.strapiOmniRomExercise?.name || data?.romProgramExercise?.exerciseLibrary?.title || data?.strapiOmniRomExercise?.name || data?.exerciseLibrary?.title || "Standard Motion"}</p>
                                    <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.value')} </b>{data?.value || 0}°</p>
                                    <p className='font-normal'><b className='font-bold'>Normal :</b>{data?.normal || 0}°</p>
                                    <p className='font-normal'><b className='font-bold'>Min :</b>{data?.min || 0}°</p>
                                    <p className='font-normal'><b className='font-bold'>Max :</b>{data?.max || data?.normal || 0}°</p>
                                    <p className='font-normal'><b className='font-bold'>WFL :</b>{data?.wfl || 0}°</p>
                                    <p className='font-normal'><b className='font-bold'>Score : </b><span style={{ color: scoreColor() }}>{score || 0}%</span></p>
                                    <p className='font-normal'><b className='font-bold'>Ranking :</b> <span style={{ color: rankingColor(ranking) }}>{ranking || 0}</span></p>
                                  </>)
                                })}
                              </div>
                            }
                          </div></>)
                      })}
                    </div>
                    <>
                      {report?.romSessionsNotes?.length! > 0 && (
                        <>
                          <div className="text-left mt-3 mb-3">
                            <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
                          </div>
                          {report?.romSessionsNotes?.map((note, index) => {
                            return <ReportNotesDownloadTemplate
                              index={index}
                              isEditMode={isEditMode}
                              note={note}
                            />
                          })}</>)}
                    </>
                  </>}</>
              )
            })}
          </div>
        </>}
    </>
  )
}
