import { NotificationMessage } from '@carespace-icons/alerts/notificationMessage';
import { BarLineChart } from '@carespace-icons/charts/barLineChart';
import { Image03 } from '@carespace-icons/files/image03';
import { ActivityHeart } from '@carespace-icons/general/activityHeart';
import { VideoRecorder } from '@carespace-icons/media/videoRecorder';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	IActivityStreamPanel,
	IActivitySTreamPostMedia,
	IOnchangeStatus,
	IProgramExercise,
	IRomSession,
	IstatusIcon,
	Status,
} from '@stores/interfaces';
import {
	Avatar,
	Badge,
	Button,
	Col,
	Collapse,
	Dropdown,
	Image,
	Row,
	Tag,
	message,
} from 'antd';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineAssignment, MdOutlineRemoveRedEye } from 'react-icons/md';
import { ProgramSession } from '@atoms/AProgramSession';
import { SurveySession } from '@atoms/ASurveySession';
import StartSurvey from '@atoms/AStartSurvey/StartSurvey';
import { AEvaluation } from '../../../atoms/AEvaluation/AEvaluation';
import AROM from '../../../atoms/AROM/AROM';
import AProgram from '../../../atoms/AProgram/AProgram';
import { selectExercise } from '@stores/aiAssistant/program';
import { useNavigate } from 'react-router-dom';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusOutOfParams } from '@stores/outOfParams';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusPendingReview } from '@stores/pendingReview';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusReviewed } from '@stores/reviewed';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import StatusMenu from '@atoms/AStatusMenu/AStatusMenu';
import './style.css';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import ActivityReport from '../ActivityReport/ActivityReport';
import { getRomSessionByIdforActivity } from '@stores/rom/customRom';
import { router } from '@routers/routers';
import { useTheme } from '@pages/Themes/ThemeContext';

function ActivityStreamPanel(props: IActivityStreamPanel) {
	const {
		item,
		index,
		scrollEnabled,
		evaluationData,
		lastMessageRef,
		handleScrollTo,
	} = props;
	const {theme} = useTheme()
	const { Panel } = Collapse;
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const { t } = useTranslation();
	const [exercises, setExercises] = useState<IProgramExercise[]>([]);
	const [programName, setProgramName] = useState('');
	const [programStatus, setProgramStatus] = useState<boolean>();
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const startProgramSession = () => {
		dispatch(selectExercise(exercises));
		navigate(
			`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_PROGRAM_START}`,
		);
		dispatch(setActiveTab(''));
	};
	const [romData, setRomData] = useState<IRomSession>();
	const [bodyRegion, setBodyRegion] = useState('');
	const [status, setStatus] = useState();
	const [refresh, setRefresh] = useState(false);
	const [activeKey, setActiveKey] = useState<string[]>([]);
	const [hoveredSession, setHoveredSession] = useState<string | null>(null);

	useEffect(() => {
		if (item?.data?.activityStream?.romSessionId) {
			const fetchData = async (romId: string) => {
				const action = await dispatch(getRomSessionByIdforActivity({ customRomId: romId }));
				setRomData(action.payload);
				setBodyRegion(action.payload?.strapiOmniRomExerciseGroup?.name?.toUpperCase())
				setStatus(action.payload?.status)
			};
			fetchData(item?.data?.activityStream?.romSessionId);
		}
	}, [item, refresh]);

	const statusIcons: IstatusIcon = {
		outOfParams: <Badge color="black" />,
		pendingReview: <Badge color="yellow" />,
		reviewed: <Badge color="green" />,
		escalationRequired: <Badge color="red" />,
		followUpRequired: <Badge color="orange" />,
	};

	type IUpdateRomSessionStatus = Omit<Record<Status, any>, 'newPatients'>;

	const updateOmniRomSession: IUpdateRomSessionStatus = {
		outOfParams: updateOmniRomSessionStatusOutOfParams,
		pendingReview: updateOmniRomSessionStatusPendingReview,
		reviewed: updateOmniRomSessionStatusReviewed,
		escalationRequired: updateOmniRomSessionStatusEscalationRequired,
		followUpRequired: updateOmniRomSessionStatusFollowUpRequired,
	};

	const onChangeStatus = async (params: IOnchangeStatus, item: IRomSession) => {
		const { sessionId, status } = params;
		message.loading({
			content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
			key: sessionId,
		});
		await dispatch(
			updateOmniRomSession[item.status as keyof IUpdateRomSessionStatus]({
				sessionId,
				body: { status },
			}),
		);
		message.success({
			content: t(
				'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
			),
			key: sessionId,
			duration: 3,
		});
		setRefresh(!refresh);
	};

	const handlePanelChange = (key: string | string[]) => {
		setActiveKey(key instanceof Array ? key : [key]);
	};

	useEffect(() => {
		if (item.value) {
			setActiveKey([item.data.id]);
		}
	}, [item]);

	useEffect(() => {
		if (lastMessageRef.current && scrollEnabled) {
			lastMessageRef.current.scrollIntoView({ behavior: 'auto' });
		}
	}, [handlePanelChange]);

	const buttonStyle = {
		color: 'var(--button-text-color)',
		border: 'inherit',
	};

	return (
		<Collapse
			bordered={false}
			style={{ width: '80%' }}
			className={`pt-panel as-panel stream-panel ${user.isPhysioterapist ? (item.data.physioterapistId ? 'margin-auto' : 'margin-left') : item.data.physioterapistId ? 'margin-left' : 'margin-auto'} bg-white-custom`}
			activeKey={activeKey}
			onChange={handlePanelChange}>
			<>
				<Panel
					key={item.data.id}
					className={`header-panel panel  ${item.title !== '' && item.subtitle !== '' ? 'bg-white-custom ' : 'bg-slate-custom'}`}
					ref={index === evaluationData.length - 1 ? lastMessageRef : null}
					header={
						<div className="panel-head"
							onMouseEnter={() => setHoveredSession(item?.data?.activityStream?.romSessionId!)}
							onMouseLeave={() => setHoveredSession(null)}>
							<div className="panel-profile">
								<>
									{selectedUser?.profile?.imageUrl ||
										user?.profile?.imageUrl ? (
										<Avatar
											src={
												user?.isPhysioterapist
													? selectedUser?.profile?.imageUrl
													: user?.profile?.imageUrl
											}
											alt="avatar"
											size={30}
										/>
									) : (
										<Avatar
											style={{
												backgroundColor: user?.isPhysioterapist
													? selectedUser?.profile?.avatarColor
													: user?.profile?.avatarColor
											}}
											className="custom-inline-flex-panel"
											alt="avatar"
											size={32}>
											{user?.isPhysioterapist
												? selectedUser?.profile?.firstName
													?.charAt(0)
													?.toUpperCase()
												: user?.profile?.firstName?.charAt(0)?.toUpperCase()}
										</Avatar>
									)}
								</>
							</div>
							{item.title != '' && item.subtitle != '' ? (
								<div className="custom-flex-grow">
									<div className="custom-flex custom-items-center">
										<div className="custom-rounded-full">
											<div className="custom-rounded-inner">
												<>
													{item?.data?.activityStream?.evaluationSessionId && (
														<BarLineChart
															width={20}
															height={20}
															color={theme === 'vibrant' ? "stroke-black" :"stroke-gray-600"}
														/>
													)}
													{item?.data?.activityStream?.programSessionId && (
														<ActivityHeart
															width={20}
															height={20}
															color={theme === 'vibrant' ? "stroke-black" :"stroke-gray-600"}

														/>
													)}
													{item?.data?.activityStream?.romSessionId && (
														<VideoRecorder
															width={20}
															height={20}
															color={theme === 'vibrant' ? "stroke-black" :"stroke-gray-600"}
														/>
													)}
													{item?.data?.activityStream?.surveyResultId && (
														<MdOutlineAssignment className={theme === 'vibrant' ? "custom-md-outline" : "custom-md-outline-gray"}
														/>
													)}
												</>
											</div>
										</div>
										<div className="panel-item">
											{item && (
												<>
													<div className='flex-items-center' style={{ justifyContent: "space-between" }}>
														<div className="custom-panel-details">
															<div className="custom-panel-column-item">
																<h3 className="custom-font-bold">{item.title}</h3>
																<p>{item.subtitle}</p>
															</div>
															{item?.data?.activityStream?.romSessionId && (
																<div className="flex-items-center-justify-center">
																	{hoveredSession === item?.data?.activityStream?.romSessionId &&
																		<div className="flex-items-center-justify-end gap-2-mr-2">
																			{bodyRegion ? (
																				<Tag color="purple">{bodyRegion}</Tag>
																			) : null}
																			<div className="dropdown">
																				{user.isPhysioterapist ? (
																					<Dropdown
																						overlay={
																							<StatusMenu
																								status={status}
																								romData={romData}
																								statusIcons={statusIcons}
																								onChangeStatus={onChangeStatus}
																							/>
																						}>
																						<a onClick={e => e.stopPropagation()}>
																							<span className="badge-custom">
																								{statusIcons[status!]}
																							</span>
																						</a>
																					</Dropdown>
																				) : (
																					''
																				)}
																			</div>
																		</div>
																	}
																</div>
															)}
														</div>
														<ActivityReport item={item} />
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							) : (
								<div className="custom-flex-grow">
									<div className="flex-items-center">
										{!item?.data?.physioterapistId && (
											<div className="flex-items-center-justify-center custom-image-div">
												<div className="custom-icon-div flex-items-center-justify-center">
													{item?.data?.activityStreamPost
														?.activityStreamPostMedia &&
														item.data.activityStreamPost
															.activityStreamPostMedia[0]?.image ? (
														<Image03
															width={20}
															height={20}
															color="stroke-gray-600"
														/>
													) : item?.data?.activityStreamPost
														?.activityStreamPostMedia &&
														item.data.activityStreamPost
															.activityStreamPostMedia[0]?.video ? (
														<VideoRecorder
															width={20}
															height={20}
															color="stroke-gray-600"
														/>
													) : (
														<NotificationMessage
															width={20}
															height={20}
															color="stroke-gray-600"
														/>
													)}
												</div>
											</div>
										)}
										<div className="panel-item">
											{item?.data?.physioterapistId &&
												!item?.data?.activityStream?.surveyId &&
												!item?.data?.activityStream?.programId ? (
												<>
													<h3 className="custom-font-bold">
														{t('Admin.data.inbox.feedback')}
													</h3>
													<p>
														{t('Admin.data.inbox.hello')}{' '}
														{user.isPhysioterapist
															? selectedUser?.profile?.fullName
															: user?.profile?.fullName}
														, {t('Admin.data.inbox.instruction')}{' '}
													</p>
												</>
											) : (
												<>
													{item?.data?.activityStreamPost
														?.activityStreamPostMedia &&
														item.data.activityStreamPost
															.activityStreamPostMedia[0]?.video ? (
														<h3 className="custom-font-bold">
															{t('Patient.data.activityStream.newVideoMsg')}
														</h3>
													) : item?.data?.activityStreamPost
														?.activityStreamPostMedia ||
														(item?.data?.activityStreamPost
															?.activityStreamPostMedia[0]?.image &&
															item?.data?.activityStreamPost?.message) ? (
														<h3 className="custom-font-bold">
															{t('Patient.data.activityStream.newMsg')}
														</h3>
													) : null}
												</>
											)}
											{item?.data?.physioterapistId &&
												item?.data?.activityStream?.surveyId && (
													<>
														<h3 className="custom-font-bold custom-mt-3">
															{t('Admin.data.survey.assignedSurvey')}
														</h3>
													</>
												)}
											{item?.data?.physioterapistId &&
												item?.data?.activityStream?.programId && (
													<>
														<div className="custom-flex-flex-row-items-center-justify-between">
															<div className="custom-flex-col">
																<h3 className="custom-font-bold">
																	You have been assigned a new program
																</h3>
																{programName && (
																	<span className="custom-mx-1">
																		{programName}
																	</span>
																)}
															</div>
															{programStatus && (
																<div>
																	<Button
																		className="start-session-css"
																		style={buttonStyle}
																		icon={
																			<PlayCircle
																				height={12}
																				width={12}
																				color="var(--button-text-color)"
																			/>
																		}
																		onClick={e => {
																			e.stopPropagation();
																			startProgramSession();
																		}}>
																		{t(
																			'Patient.data.myProgress.rehab.startSession',
																		)}
																	</Button>
																</div>
															)}
														</div>
													</>
												)}
										</div>
									</div>
								</div>
							)}
						</div>
					}>
					{item?.data?.activityStream?.evaluationSessionId && (
						<AEvaluation
							evaluationId={item?.data?.activityStream?.evaluationSessionId}
						/>
					)}
					{item?.data?.activityStream?.romSessionId && (
						<AROM
							romId={item?.data?.activityStream?.romSessionId}
							romData={romData}
							setRomData={setRomData}
							setBodyRegion={setBodyRegion}
							setStatus={setStatus}
							onChangeStatus={onChangeStatus}
							refresh={refresh}
							setRefresh={setRefresh}
						/>
					)}
					{item?.data?.activityStream?.programId && (
						<AProgram
							programId={item?.data?.activityStream?.programId}
							setProgramName={setProgramName}
							setProgramStatus={setProgramStatus}
							exercises={exercises}
							setExercises={setExercises}
						/>
					)}
					{item?.data?.activityStream?.programSessionId && (
						<ProgramSession id={item?.data?.activityStream?.programSessionId} />
					)}
					{item?.data?.activityStream?.surveyResultId && (
						<SurveySession id={item?.data?.activityStream?.surveyResultId} />
					)}
					{item?.data?.physioterapistId &&
						item?.data?.activityStream?.surveyId && (
							<>
								<StartSurvey id={item?.data?.activityStream?.surveyId} />
							</>
						)}
					{item?.data?.activityStreamPost ? (
						<>
							{evaluationData.map(evaluationItem => {
								if (
									evaluationItem.data.activityStreamId &&
									evaluationItem.data.activityStreamId ===
									item?.data?.activityStreamPost?.activityStreamId
								) {
									return (
										<div
											className="activity-padding"
											key={index}
											onClick={() =>
												handleScrollTo(evaluationItem.data.activityStreamId)
											}>
											<div
												className="activity-item"
												style={{ background: 'rgba(213, 217, 235, 1)' }}>
												<div className="activity-header">
													<div
														className="colored-bar"
														style={{ background: 'rgba(105, 65, 198, 1)' }}>
														&nbsp;
													</div>
													<img
														src={
															user.isPhysioterapist
																? selectedUser?.profile?.imageUrl ||
																'/images/default-profile.png'
																: user?.profile?.imageUrl ||
																'/images/default-profile.png'
														}
														onError={e => {
															(e.target as HTMLImageElement).src =
																'/images/default-profile.png';
														}}
														className="profile-image"
													/>
													<p className="custom-font-bold custom-text-gray-blue-500">
														{user.isPhysioterapist
															? selectedUser?.profile?.fullName
															: user?.profile?.fullName}
													</p>
												</div>
												<div className="custom-title">
													<div
														className="custom-space"
														style={{
															background: 'rgba(105, 65, 198, 1)',
															padding: '5px',
														}}>
														&nbsp;
													</div>
													<div className="custom-inner-title">
														<p className="custom-font-bold custom-text-gray-blue-500">
															{evaluationItem.title}
														</p>
														<p className="custom-text-gray-blue-500">
															{evaluationItem.subtitle}
														</p>
													</div>
												</div>
											</div>
										</div>
									);
								}
								return null;
							})}
							<div>
								{item?.data?.activityStreamPost?.activityStreamPostMedia.map(
									(
										postMedia: IActivitySTreamPostMedia,
										index: Key | null | undefined,
									) => (
										<div key={index} className="custom-padding">
											{postMedia?.image && (
												<Row gutter={[16, 16]} align="middle">
													<Col span={8}>
														<Image
															src={postMedia?.image}
															className="custom-image"
															height={140}
															preview={{
																src: postMedia?.image,
																mask: <MdOutlineRemoveRedEye size={18} />,
															}}
														/>
													</Col>
													<Col span={16} className="popup-message-head">
														<h3 className="popup-message">
															{item?.data?.activityStreamPost?.message}
														</h3>
													</Col>
												</Row>
											)}
											{postMedia?.video && (
												<Row gutter={[16, 16]} align="middle">
													<Col span={8}>
														<video
															src={postMedia?.video}
															controls
															className="custom-image"
															preload="metadata"
															width={250}
															height={140}
														/>
													</Col>
													<Col span={16} className="popup-message-head">
														<h3 className="popup-message">
															{item?.data?.activityStreamPost?.message}
														</h3>
													</Col>
												</Row>
											)}
										</div>
									),
								)}
							</div>
						</>
					) : null}
					{item?.data?.activityStreamPost?.activityStreamPostMedia?.length ===
						0 && item?.data?.activityStreamPost?.message ? (
						<h3 className="popup-message plain-message">
							{item?.data?.activityStreamPost?.message}
						</h3>
					) : null}
				</Panel>
			</>
		</Collapse>
	);
}

export default ActivityStreamPanel;
