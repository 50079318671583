import { Modal, QRCode } from "antd";
import { useTranslation } from "react-i18next";

interface AdminQRModalProps {
  qrCodeModalOpen: boolean
  setQrCodeModalOpen: (val: boolean) => void 
  closable: boolean
  qrCodeUrl: string
}

const AdminQRCodeModal = (props: AdminQRModalProps) => {
  const { t } = useTranslation();
  const { qrCodeModalOpen, setQrCodeModalOpen, closable, qrCodeUrl } = props;

  return (
    <Modal
      open={qrCodeModalOpen}
      centered
      footer={null}
      destroyOnClose
      closable={closable}
      maskClosable={false}
      width={500}
      className="select-none"
      onCancel={() => setQrCodeModalOpen(false)}
    >
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1 style={{ marginBottom: '20px' }}> 
          {t('Scan this QR code to open the associated URL.')}
        </h1>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <QRCode value={qrCodeUrl} size={300} />
        </div>
      </div>
    </Modal>
  );
};

export default AdminQRCodeModal;

