import ReactVideoRecorder, { VideoActionsProps } from 'react-video-recorder';
import {
	forwardRef,
	useEffect,
	useMemo,
	useRef,
	useState,
	useCallback,
} from 'react';
import './style.css';
import counterSound from '../../organisms/ORom/TransitionTime/counterSound.mp3';
import { RehabVideoState } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '@stores/index';
import { Tooltip } from 'antd';
import { FaVolumeMute } from 'react-icons/fa';
import { AiFillSound } from 'react-icons/ai';
interface IVideoRecordProps {
	timeLimit: number;
	cameraId: string | null;
	onRecordFinished: (videoBlob: Blob) => void;
	audio?: boolean;
	videoBlob?: null | Blob;
	videoState?: RehabVideoState;
	isFullscreen?: boolean;
}

function VideoRecord(props: IVideoRecordProps, ref: object | null) {
	const {
		cameraId,
		timeLimit,
		onRecordFinished,
		audio,
		videoBlob,
		videoState,
		isFullscreen,
	} = props;
	const [saving, setSaving] = useState(false);
	const [mute, setMute] = useState(false);
	const [isRunningCountdown, setIsRunningCountdown] = useState(false);
	const [counterTime, setCounterTime] = useState<number>(0);
	const audioRef = useRef<HTMLAudioElement>(new Audio(counterSound));
	const { transitionTime } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const countdownRef = useRef<number | null>(null);
	const { t } = useTranslation();
	const constraints = {
		audio: audio ? audio : false,
		video: {
			deviceId: {
				exact: cameraId as string,
			},
			width: 1280,
			height: 720,
			aspectRatio: {
				exact: 1.777777777777778,
				ideal: 1.777777777777778,
			},
			resizeMode: 'crop-and-scale',
		},
	};
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.muted = mute;
		}
	}, [mute]);

	const videoElement = useMemo(() => {
		if (videoBlob) {
			return (
				<video
					ref={videoRef}
					autoPlay
					loop
					muted={mute}
					src={URL?.createObjectURL(videoBlob)}
				/>
			);
		}
		return null;
	}, [videoBlob]);

	const runCountDown = ({ isRunningCountdown }: VideoActionsProps) => {
		setIsRunningCountdown(isRunningCountdown);
		if (isRunningCountdown) {
			return (
				<>
					{counterTime >= 1 && (
						<div
							style={{
								width: '100%',
								position: 'absolute',
								zIndex: 10,
								height: '100%',
								color: 'white',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<div
								style={{
									fontSize: 40,
									color: 'white',
									fontWeight: 'bold',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column',
									textShadow: '2px 2px 4px #000000',
								}}>
								{t('Patient.data.omnirom.getPosition')}
								<br />
								<span
									className="bg-primary-600 shadow-black shadow-md rounded-full flex items-center justify-center"
									style={{
										width: 100,
										height: 100,
										fontSize: 40,
										fontWeight: 'bold',
										color: 'white',
									}}>
									{counterTime}
								</span>
								<audio ref={audioRef} src={counterSound} preload="auto" />
							</div>
						</div>
					)}
				</>
			);
		}
	};

	const onRecordingComplete = (vidBlob: Blob) => {
		setSaving(true);
		if (ref && 'current' in ref && ref.current) {
			(
				ref.current as { handleStopReplaying: () => void }
			).handleStopReplaying();
		}
		setTimeout(() => {
			setSaving(false);
			if (vidBlob) {
				onRecordFinished(vidBlob);
			}
		}, 3000);
	};

	const stopStreamedVideo = useCallback(() => {
		if (ref?.current) {
			const stream = ref.current.srcObject as MediaStream;
			if (stream) {
				const tracks = stream.getTracks();

				tracks.forEach(track => {
					track.stop();
				});
			}

			ref.current.srcObject = null;
		}
	}, []);

	useEffect(() => {
		return () => {
			stopStreamedVideo();
		};
	}, [stopStreamedVideo]);

	useEffect(() => {
		setMute(true);
	}, [videoState]);

	useEffect(() => {
		if (isRunningCountdown) {
			audioRef.current.play();
			setCounterTime(transitionTime!);
			let counter = transitionTime!;

			countdownRef.current = window.setInterval(() => {
				if (audioRef.current) {
					audioRef.current.currentTime = 0;
					counter > 0 && audioRef.current.play();
				}
				counter -= 1;
				setCounterTime(counter);

				if (counter === 0) {
					clearInterval(countdownRef.current!);
				}
			}, 1000);
		}

		return () => {
			if (countdownRef.current) {
				clearInterval(countdownRef.current);
				audioRef.current = new Audio(counterSound);
			}
		};
	}, [isRunningCountdown]);

	return (
		<>
			<ReactVideoRecorder
				ref={ref}
				isOnInitially
				isReplayingVideo={false}
				wrapperClassName={`video-record-item program-video-recorder w-full ${isFullscreen ? 'full-height' : ''}`}
				timeLimit={timeLimit}
				onRecordingComplete={onRecordingComplete}
				constraints={constraints}
				renderActions={runCountDown}
				countdownTime={transitionTime! * 1000}
			/>
			{saving && (
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-white text-3xl">
					Saving...
				</div>
			)}
			{(videoState === RehabVideoState.RATING ||
				videoState === RehabVideoState.REPLAYING) && (
				<div className="absolute top-0 left-0 w-[420px]">
					{videoElement}
					<div className="absolute left-2 bottom-2 bg-black rounded-full p-2">
						<Tooltip
							title={
								mute
									? t('Patient.data.rehab.unmute')
									: t('Patient.data.rehab.mute')
							}>
							{!mute ? (
								<AiFillSound
									size={20}
									style={{
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
									onClick={() => setMute(true)}
									color="white"
								/>
							) : (
								<FaVolumeMute
									size={20}
									style={{
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
									onClick={() => setMute(false)}
									color="white"
								/>
							)}
						</Tooltip>
					</div>
				</div>
			)}
		</>
	);
}
export default forwardRef(VideoRecord);
