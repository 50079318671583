import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	IPayload,
	ISurvey,
	ISurveyPaginated,
	ISurveyResponse,
	ISurveyResult,
	ISurveyResultPaginated,
} from '@stores/interfaces';
import strapi from '@strapi';
import { message } from 'antd';
import axios from 'axios';

export const getSurvey = createAsyncThunk(
	'getSurvey',
	async (payload: IPayload): Promise<ISurveyPaginated> => {
		try {
			const { userId, limit, page, approved = false } = payload;
			const { data } = await axios.get(
				`/survey/${userId}?limit=${limit}&page=${page}&approved=${approved}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getSurveyResultData = createAsyncThunk(
	'getSurveyResultData',
	async (payload: IPayload): Promise<ISurveyPaginated> => {
		try {
			const { userId, limit, page, search, approved = false } = payload;
			const { data } = await axios.get(
				`/survey/${userId}?limit=${limit}&page=${page}&approved=${approved}${search === '' ? '' : `&search=${search}`}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getSurveyData = createAsyncThunk(
	'getSurveyData',
	async (payload: { id: string }): Promise<ISurvey> => {
		try {
			const { id } = payload;
			const { data } = await axios.get(`/survey/get/${id}`);

			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getSurveyResult = createAsyncThunk(
	'getSurveyResult',
	async (payload: IPayload): Promise<ISurveyResultPaginated> => {
		try {
			const { userId, limit, page } = payload;
			const { data } = await axios.get(
				`/survey/${userId}?limit=${limit}&page=${page}&sessions=true`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getSurveyByIdList = createAsyncThunk(
	'getSurveyByIdList',
	async (payload: {
		surveyId: string;
		limit: number;
		page: number;
	}): Promise<ISurveyResultPaginated> => {
		try {
			const { surveyId, limit, page } = payload;
			const response = await axios.get(
				`/survey/sessions/${surveyId}?limit=${limit}&page=${page}`,
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	},
);

export const createSurvey = createAsyncThunk(
	'createSurvey',
	async (
		payload: { surveyData: ISurvey; images: File[] },
		{ dispatch },
	): Promise<ISurvey> => {
		try {
			const { data } = await axios.post(`/survey`, payload.surveyData);

			if (payload.images.length > 0) {
				await dispatch(
					uploadImage({
						id: data?.id,
						images: payload.images[0],
						target: 'survey',
					}),
				);
			}

			return data;
		} catch (error) {
			(error?.response?.data.statusCode === 409) ? message.error(error?.response?.data.message) : message.error("Something Went Wrong !")
			throw error
		}
	},
);

export const uploadImage = createAsyncThunk(
	'uploadImage',
	async (payload: {
		id: string;
		images: File;
		target: string;
	}): Promise<ISurvey> => {
		try {
			const { id, images, target } = payload;

			const formData = new FormData();
			formData.append('images', images);

			const { data } = await axios.patch(`/${target}/${id}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const updateSurvey = createAsyncThunk(
	'updateSurvey',
	async (payload: {
		surveyId: string;
		surveyData: ISurvey;
	}): Promise<ISurvey> => {
		try {
			const { surveyId, surveyData } = payload;
			const { data } = await axios.patch(`/survey/${surveyId}`, surveyData);
			return data;
		} catch (error) {
			(error?.response?.data.statusCode === 409) ? message.error(error?.response?.data.message) : message.error("Something Went Wrong !")
			throw error
		}
	},
);

export const getSurveyTemplate = createAsyncThunk(
	'getSurveyTemplate',
	async (payload: IPayload): Promise<ISurveyPaginated> => {
		try {
			const { limit, page, search } = payload;
			const { data } = await axios.get(
				`/survey/template/list?limit=${limit}&page=${page}${search === '' ? '' : `&search=${search}`}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getSystemSurveyTemplate = createAsyncThunk(
	'getSystemSurveyTemplate',
	async (payload: IPayload): Promise<ISurveyPaginated> => {
		try {
			const { limit, page, search } = payload;
			const { data } = await strapi.get(
				`https://strapi.carespace.ai/api/survey-templates?pagination[page]=${page}&pagination[pageSize]=${limit}&populate[image][fields]=url&populate[surveyTemplateQuestion][populate]=optionList&filters[$or][0][title][$contains]=${search}&filters[$or][1][description][$contains]=${search}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getClinicalSurveyTemplate = createAsyncThunk(
	'getClinicalSurveyTemplate',
	async (payload: IPayload): Promise<ISurveyPaginated> => {
		try {
			const { limit, page, search } = payload;
			const { data } = await strapi.get(
				`https://strapi.carespace.ai/api/survey-templates?pagination[page]=${page}&pagination[pageSize]=${limit}&populate[image][fields]=url&populate[surveyTemplateQuestion][populate]=optionList&filters[clinicallyValidated][$eq]=true&filters[$or][0][title][$contains]=${search}&filters[$or][1][description][$contains]=${search}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const createSurveyTemplate = createAsyncThunk(
	'createSurveyTemplate',
	async (
		payload: { surveyTemplateData: ISurvey; images: File[] },
		{ dispatch },
	): Promise<ISurvey> => {
		try {
			const { data } = await axios.post(
				`/survey/template`,
				payload?.surveyTemplateData,
			);

			if (payload.images.length > 0) {
				await dispatch(
					uploadImage({
						id: data?.id,
						images: payload.images[0],
						target: 'surveyTemplate',
					}),
				);
			}

			return data;
		} catch (error) {
			(error?.response?.data.statusCode === 409) ? message.error(error?.response?.data.message) : message.error("Something Went Wrong !")
		}
	},
);

export const saveSurveyResult = createAsyncThunk(
	'saveSurveyResult',
	async (payload: {
		surveyId: string;
		surveyData: ISurveyResult;
	}): Promise<ISurveyResult> => {
		try {
			const { surveyId, surveyData } = payload;
			const data = await axios.post(`/survey/session/${surveyId}`, surveyData);
			return data?.data;
		} catch (error) {
			throw error;
		}
	},
);

export const deleteSurvey = createAsyncThunk(
	'deleteSurvey',
	async (surveyId: string): Promise<ISurvey> => {
		try {
			const { data } = await axios.patch(`/survey/${surveyId}?delete=true`);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

const initialState: ISurveyResponse = {
	survey: {
		data: [],
		pagination: null,
	},
	surveyTemplate: {
		myTemplate: {
			data: [],
			pagination: null,
		},
		systemTemplate: {
			data: [],
			pagination: null,
		},
		clinicalTemplate: {
			data: [],
			pagination: null,
		},
	},
	surveyResult: {
		data: [],
		pagination: null,
	},
};

const survey = createSlice({
	name: 'survey',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSurvey.fulfilled, (state, action) => {
			state.survey = action.payload;
		});
		builder.addCase(getSurvey.rejected, (state, action) => { });

		builder.addCase(getSurveyResult.fulfilled, (state, action) => {
			state.surveyResult = action.payload;
		});
		builder.addCase(getSurveyResult.rejected, (state, action) => { });

		builder.addCase(createSurvey.fulfilled, (state, action) => {

		});
		builder.addCase(createSurvey.rejected, (state, action) => { });

		builder.addCase(updateSurvey.fulfilled, (state, action) => { });
		builder.addCase(updateSurvey.rejected, (state, action) => { });

		builder.addCase(uploadImage.fulfilled, (state, action) => { });
		builder.addCase(uploadImage.rejected, (state, action) => { });

		builder.addCase(saveSurveyResult.fulfilled, (state, action) => {
			message.success('Survey successfully completed');
		});
		builder.addCase(saveSurveyResult.rejected, (state, action) => {
		});

		builder.addCase(getSurveyTemplate.fulfilled, (state, action) => {
			state.surveyTemplate.myTemplate = action.payload;
		});
		builder.addCase(getSurveyTemplate.rejected, (state, action) => { });

		builder.addCase(getSystemSurveyTemplate.fulfilled, (state, action) => {
			state.surveyTemplate.systemTemplate = action.payload;
		});
		builder.addCase(getSystemSurveyTemplate.rejected, (state, action) => { });

		builder.addCase(getClinicalSurveyTemplate.fulfilled, (state, action) => {
			state.surveyTemplate.clinicalTemplate = action.payload;
		});
		builder.addCase(getClinicalSurveyTemplate.rejected, (state, action) => { });
	},
});

export default survey.reducer;
