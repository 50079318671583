import { Edit02 } from "@carespace-icons/general/edit02";
import { Save01 } from "@carespace-icons/general/save01";
import { Trash01 } from "@carespace-icons/general/trash01"
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ISurvey, ISurveyQuestionOptions } from "@stores/interfaces";
import { Input, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const SurveyQuestionOptionItem = ({ survey, scored, option, deleteOptionByIndex, updateOptionTitle }: { survey: ISurvey,scored:boolean, option: ISurveyQuestionOptions, deleteOptionByIndex: () => void, updateOptionTitle: (title: string, score: number) => void }) => {

  const [isEdit, setIsEdit] = useState(false)
  const [title, setTitle] = useState<string>(option?.option || '')
  const [score, setScore] = useState<number>(option?.score || 0)
  const [isScored, setScored] = useState(scored)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: option?.id! });
  const { t } = useTranslation()
  const style = {
    transition,
    transform: CSS.Transform.toString({
      x: transform?.x!,
      y: transform?.y!,
      scaleX: 1,
      scaleY: 1,
    }),
    border: !isEdit ? '1px solid lightgray' : ''
  };

  const validateOption = () => {
    if (title?.trim() == '') {
      message.error(t('Admin.data.survey.answerEmptyError'));
    } else if (isNaN(score)) {
      message.error(t('Admin.data.survey.scoreEmptyError'));
    } else {
      setIsEdit(false);
      updateOptionTitle(title, score);
    }
  }

  return <div
    className="survey-question-option-item"
    key={option.id}
    style={style}
    {...attributes}
  >
    
    <div className="option-content">
      {isEdit ? (
        <Input
          className="input-title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      ) : (
        <p>{option?.option}</p>
      )}
    </div>
    {isScored != false && <>
    {isEdit ? (
      <Input
        className="input-score"
        value={score}
        type="number"
        onChange={(e) => setScore(parseInt(e.target.value))}
      />
    ) : (
      <>
      <div style={{width:"10%", display:"flex", justifyContent:"end"}}>
      <div className="score-display" >
        <span>{score ? score : 0}</span>
      </div></div></>
    )}</>}
    {!survey?.clinicallyValidated &&<>
    {isEdit ? (
      <span className="save-button" onClick={validateOption}>
        <Save01 color="stroke-gray-400" width={20} height={20} />
      </span>
    ) : (
      <span className="edit-button" onClick={() => setIsEdit(true)}>
        <Edit02 color="stroke-gray-400" width={20} height={20} />
      </span>
    )}
    <div className="delete-button" onClick={deleteOptionByIndex}>
      <Trash01 color="stroke-gray-400" width={20} height={20} />
    </div>
    <div
      className="drag-handle"
      ref={setNodeRef}
      {...listeners}
    >
      <img src="/images/menu.svg" />
    </div></>}
  </div>
}