import { PhonePlus } from "@carespace-icons/communication/phonePlus"
import { setActiveTab } from "@stores/aiAssistant/aiAssistant"
import { router } from '@routers/routers';
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { Button } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

interface ImportantNoticeProps {
	activeStep: number,
	setActiveStep: (activeStep: number) => void,
	setPainLevel: (painLevel: number) => void,
	savedVoice: string
	painLevel: number
	setSavedVoice: (savedVoice: string) => void
}

export const ImportantNotice = (props: ImportantNoticeProps) => {
	const { activeStep, setActiveStep, setPainLevel, savedVoice, painLevel, setSavedVoice } = props
	const { t } = useTranslation()
	const user = useTypedSelector(state => state.user)
	const { selectedUser } = useTypedSelector((state) => state.contacts.main)
	const navigate = useNavigate()
	const dispatch = useTypedDispatch()

	useEffect(() => {
		if (activeStep === 2 && painLevel >= 8) {
			if (savedVoice.toLowerCase().includes("dashboard")) {
				navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.ACTIVITY}`)
				dispatch(setActiveTab("activity"))
			}
		}
	}, [savedVoice])

	return <div className="flex flex-col items-center select-none">
		<div className="mt-[130px] mb-[15px] w-[636px] h-[28px] flex justify-around items-center">
			<Button
				className="!rounded-full flex justify-center items-center"
				onClick={() => {
					setSavedVoice('')
					setPainLevel(-1)
					setActiveStep(activeStep - 1)
				}}
				icon={<FaArrowLeft />}
			/>
			<p className="text-white text-lg font-semibold text-center m-auto">{t('Admin.data.rehab.rehabPreAssessment.importantNotice')}</p>
		</div>
		<div className="h-auto w-[636px] rounded-xl bg-[#F9FAFB1A]">
			<div className="p-[25px] pb-0">
				<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.dearPatient')},</p><br />
				<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.noticeDescription1')}</p><br />
				<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.noticeDescription2')}</p><br />
				<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.noticeDescription3')}</p>
			</div>
			<div className="p-[25px] flex justify-center gap-[25px]">
				<Button
					className="!w-[225px] !p-5 !h-10 !border-2 !bg-[#9E77ED] !border-white hover:!bg-[#6941C6] hover:!border-none !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
					icon={<PhonePlus width={20} height={20} color={'stroke-white'} />}
				>
					{t('Admin.data.rehab.rehabPreAssessment.contactPhysiotherapist')}
				</Button>
				<Button
					className="!w-[134px] !h-10 !p-5 !bg-[#12B76A] !border-2 !border-white hover:!bg-[#6941C6] hover:!border-none !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
					icon={<FaArrowLeft width={20} height={20} />}
					onClick={() => {
						navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.ACTIVITY}`)
						dispatch(setActiveTab("activity"))
					}}
				>
					{t('Admin.data.rehab.rehabPreAssessment.dashboard')}
				</Button>
			</div>
		</div>
	</div>
}
