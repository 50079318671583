import { useTypedDispatch, useTypedSelector } from '@stores/index'
import { resetAll, setOmniromRecord, setOmniromUpload } from '@stores/rehab/main'
import { useNavigate } from 'react-router-dom'
import { Content } from 'antd/lib/layout/layout'
import { Button, Col, Row, Space, Typography } from 'antd'
import './style.css'
import { useTranslation } from 'react-i18next'
import { setActiveTab } from '@stores/aiAssistant/aiAssistant'
import { router } from '@routers/routers';

function ResultRecordScreen() {
	const dispatch = useTypedDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user)
	const { selectedUser } = useTypedSelector((state) => state.contacts.main);
	const { isOmniRomRecord, isOmniRomUpload } = useTypedSelector((state) => state.rehab.main)
	const userId = user?.isPhysioterapist ? selectedUser?.id : user?.id;

	const handleReturnAdmin = () => {
		if (isOmniRomRecord || isOmniRomUpload) {
			navigate(`/${userId}${router.AIASSISTANT_START_SCAN}`, {
				state: { showPopup: true }
			})
		} else {
			navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_PROGRAMS}`)
			dispatch(setActiveTab('programs'))
		}
	}

	const handleRecordNew = () => {
		dispatch(resetAll())
		if (isOmniRomRecord || isOmniRomUpload) {
			dispatch(setOmniromRecord(true));
			dispatch(setOmniromUpload(false));
			navigate(router.OMNIROMADDEXERCISES, {
				state: { omniRomRecord: true }
			})
		}
	}

	const handleUploadNew = () => {
		dispatch(resetAll());
		dispatch(setOmniromUpload(true));
		dispatch(setOmniromRecord(false));
		navigate(router.OMNIROMADDEXERCISES, {
			state: { omniRomUpload: true }
		})
	}

	return (
		<Content className='result-record-screen'>
			<Row align='middle' justify='center' className='h-full'>
				<Col>
					<Typography.Title level={3} style={{ color: 'white' }}>
						{t('Admin.data.rehab.addExercise.whatWouldYouLikeToDo')}
					</Typography.Title>
					<Space>
						<Button onClick={handleReturnAdmin} type='primary' className='!text-#6739b7'>{t('Admin.data.rehab.addExercise.returnToAdminConsole')}</Button>
						<Button onClick={handleRecordNew} type='primary' className='!text-#6739b7'>{t('Admin.data.rehab.addExercise.recordAnotherExercise')}</Button>
						{(isOmniRomRecord || isOmniRomUpload) && <Button onClick={handleUploadNew} type='primary' className='!text-#6739b7'>Upload new Exercise</Button>}
					</Space>
				</Col>
			</Row>
		</Content>
	)
}
export default ResultRecordScreen
