import ActivityStream from '@pages/ActivityStream'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface IActivity {
  setSavedValue?: (value: string) => void
}
export default function AiAssistantActivity(props: IActivity) {
  const { setSavedValue } = props
  const { t } = useTranslation()

  useEffect(() => {
    setSavedValue && setSavedValue("")
  }, [])
  return (
    <>
      <div>
        <div>
          <ActivityStream isHeader={false} />
        </div>
      </div>
    </>
  )
}
