import { useEffect, useState } from 'react';
import { Options, PainAssessments } from './interface';
import { TStrapiBodyPoints } from '@molecules/MBodyPoints/interface';
import { useTranslation } from 'react-i18next';
import {
	getOptionsData,
	getBodyPointStrapi,
} from '@stores/myLibrary/myLibrary';
import { useTypedDispatch } from '@stores/index';

export interface BodyPointOption {
	aggravatingFactors: [];
	relievingFactors: [];
	painCauses: [];
	painDurations: [];
	painFrequencies: [];
	painStatuses: [];
}

interface TOption {
	item: PainAssessments;
}

const SummuryOptionList = (props: TOption) => {
	const [bodyPointOptionList, setBodyPointOptionList] =
		useState<BodyPointOption>();
	const [bodyPointList, setBodyPointList] = useState<TStrapiBodyPoints[]>();
	const { item } = props;
	const dispatch = useTypedDispatch();
	useEffect(() => {
		getBodyPointOptions();
	}, []);

	const getBodyPointOptions = async () => {
		const data = await dispatch(getBodyPointStrapi());
		const strapiData = data.payload;
		setBodyPointList(strapiData);
		const getOptionData = await dispatch(
			getOptionsData({ id: item.strapiBodyPointId }),
		);
		const apiData = getOptionData.payload;
		setBodyPointOptionList(apiData);
	};
	const { t } = useTranslation();
	return (
		<>
			{
				<p className="font-semibold">
					{' '}
					Pain Level :{' '}
					<span className="font-regular">
						{item.painLevel ? item.painLevel : 0}
					</span>
				</p>
			}
			{
				<p className="font-semibold">
					{' '}
					{t('Patient.data.summaryOptionList.location')}{' '}
					<span className="font-regular">
						{bodyPointList
							?.find(
								(element: TStrapiBodyPoints) =>
									element.id == item.strapiBodyPointId,
							)
							?.attributes.name.toUpperCase()}
					</span>
				</p>
			}
			{item.strapiAggravatingFactorsIds?.length > 0 && (
				<p className="font-semibold">
					{t('Patient.data.summaryOptionList.agrFactor')}{' '}
					<span className="font-regular">
						{bodyPointOptionList?.aggravatingFactors
							? bodyPointOptionList?.aggravatingFactors
									.filter((element: Options) =>
										item.strapiAggravatingFactorsIds.includes(element.id),
									)
									.map(
										(filteredElement: Options) =>
											filteredElement.attributes.name,
									)
									.join(' , ')
							: null}
					</span>
				</p>
			)}
			{item.strapiRelievingFactorsIds?.length > 0 && (
				<p className="font-semibold">
					{t('Patient.data.summaryOptionList.relFactor')}{' '}
					<span className="font-regular">
						{bodyPointOptionList?.relievingFactors
							? bodyPointOptionList?.relievingFactors
									.filter((element: Options) =>
										item.strapiRelievingFactorsIds.includes(element.id),
									)
									.map(
										(filteredElement: Options) =>
											filteredElement.attributes.name,
									)
									.join(' , ')
							: null}
					</span>
				</p>
			)}
			{item.strapiPainCausesIds?.length > 0 && (
				<p className="font-semibold">
					{t('Patient.data.summaryOptionList.cop')}{' '}
					<span className="font-regular">
						{bodyPointOptionList?.painCauses
							? bodyPointOptionList?.painCauses
									.filter((element: Options) =>
										item.strapiPainCausesIds.includes(element.id),
									)
									.map(
										(filteredElement: Options) =>
											filteredElement.attributes.name,
									)
									.join(' , ')
							: null}
					</span>
				</p>
			)}
			<p className="font-semibold">
				{t('Patient.data.summaryOptionList.dop')}{' '}
				<span className="font-regular">
					{bodyPointOptionList?.painDurations
						? bodyPointOptionList?.painDurations?.find(
								(element: Options) => element.id == item.strapiPainDurationId,
							)?.attributes?.name
						: null}
				</span>
			</p>
			<p className="font-semibold">
				{t('Patient.data.summaryOptionList.fop')}{' '}
				<span className="font-regular">
					{bodyPointOptionList?.painFrequencies
						? bodyPointOptionList?.painFrequencies?.find(
								(element: Options) => element.id == item.strapiPainFrequencyId,
							)?.attributes?.name
						: null}
				</span>
			</p>
			<p className="font-semibold">
				{t('Patient.data.summaryOptionList.sop')}{' '}
				<span className="font-regular">
					{bodyPointOptionList?.painStatuses
						? bodyPointOptionList?.painStatuses.find(
								(element: Options) => element.id == item.strapiPainStatusId,
							)?.attributes.name
						: null}
				</span>
			</p>
		</>
	);
};

export default SummuryOptionList;
