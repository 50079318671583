import { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	getActivityStreamByDate,
	getActivityUpdateStream,
	postActivityStream,
} from '@stores/activityStream/activityStream';
import {
	ActivityType,
	EvaluationItem,
	IActivityStreamData,
	IUpdateData,
	ReactVideorRecordRef,
	RehabVideoState,
} from '@stores/interfaces';
import { countdownTime, USER_ROLES } from '@stores/constants';
import VideoModal from './VideoModal';
import { useTranslation } from 'react-i18next';
import ActivityStreamFooter from './ActivityStreamFooter';
import ActivityStreamData from './ActivityStreamData';

export default function ActivityStream({ isHeader }: ActivityType) {
	const [activityStreamData, setActivityStreamData] = useState<
		IActivityStreamData[]
	>([]);
	const [eventData, setEventData] = useState<IActivityStreamData[]>([]);
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const id: string = user.isPhysioterapist || user.profile.role === USER_ROLES.SUPER_ADMIN ? selectedUser?.id : user.id;
	const dispatch = useTypedDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const [imgFile, setImgFile] = useState<File[] | undefined>([]);
	const [messages, setMessage] = useState('');
	const [messageDescription, setMessageDescription] = useState('');
	const [evaluationData, setEvaluationData] = useState<EvaluationItem[]>([]);
	const [isVideoModalVisible, setVideoModalVisible] = useState(false);
	const [isImageModalVisible, setImageModalVisible] = useState(false);
	const videoRef = useRef<ReactVideorRecordRef>(null);
	const [videoBlob, setVideoBlob] = useState<null | Blob>(null);
	const [videoState, setVideoState] = useState<RehabVideoState>(
		RehabVideoState.START,
	);
	const imgInputRef = useRef<HTMLInputElement>(null);
	const microphoneRef = useRef(null);
	const [isStartingTimer, setStartingTimer] = useState(false);
	const { t } = useTranslation();
	const [isReply, setIsReply] = useState(false);
	const [profileData, setProfileData] = useState({});
	const [functionalData, setFunctionalData] = useState<[]>([]);
	const lastMessageRef = useRef<HTMLDivElement>(null);
	const [shareItem, setShareItem] = useState<EvaluationItem[]>([]);
	const [updateData, setUpdateData] = useState<IUpdateData>();
	const containerRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isFilter, setIsFilter] = useState(false);
	const [scrollEnabled, setScrollEnabled] = useState(true);
	const { facingMode } = useTypedSelector(state => state.rehab.main);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const functionalGoals = useTypedSelector((state) => state.functionalGoals.functionalGoals)

	const showVideoModal = () => {
		setVideoModalVisible(true);
		setMessage('');
	};

	const clearFilter = () => {
		setIsFilter(false);
		fetchData(new Date(), false);
	};

	const showImageModal = () => {
		setImageModalVisible(true);
		setMessage('');
	};

	const handleClose = () => {
		videoRef?.current?.handleStopReplaying();
		if (videoRef?.current?.recordedBlobs) videoRef.current.recordedBlobs = [];
		videoRef?.current?.handleStopRecording();
		setVideoState(RehabVideoState.START);
		setStartingTimer(false);
		setVideoModalVisible(false);
		setImageModalVisible(false);
		setImgFile([]);
		setVideoBlob(null);
		setMessage('');
		setMessageDescription('');
	};
	const onRecordFinished = (videoBlob: Blob) => {
		if (videoBlob) {
			setVideoBlob(videoBlob);
		}
	};

	const onStartRecord = () => {
		videoRef?.current?.handleStartRecording();
		setVideoState(RehabVideoState.READY);
		setTimeout(() => {
			setStartingTimer(true);
			setVideoState(RehabVideoState.RECORDING);
		}, countdownTime);
	};
	const onEndedTimer = () => {
		onStopRecord();
	};
	const onStopRecord = () => {
		videoRef?.current?.handleStopRecording!();
		setVideoState(RehabVideoState.REPLAYING);
		setStartingTimer(false);
	};

	const onDiscardRecord = () => {
		videoRef?.current?.handleStopReplaying();
		if (videoRef?.current?.recordedBlobs) videoRef.current.recordedBlobs = [];
		setVideoBlob(null);
		setVideoState(RehabVideoState.START);
	};

	const formatDate = (date: Date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const fetchData = async (newDate: Date, isDateSelected: boolean) => {
		const today = newDate;
		const start = new Date(today);
		const oneDayMilliseconds = 24 * 60 * 60 * 1000;
		const tomorrowDate = new Date(newDate.getTime() + oneDayMilliseconds);
		start.setDate(isDateSelected ? today.getDate() : today.getDate() - 90);
		const startDate = formatDate(start);
		const endDate = formatDate(tomorrowDate);
		setIsLoading(true);
		setEvaluationData([]);
		const data = await dispatch(
			getActivityStreamByDate({ id, startDate, endDate }),
		);
		setActivityStreamData(data?.payload?.data);
		eventData?.length === 0 && setEventData(data?.payload?.data);
		setUpdateData(data?.payload);
		checkEvaluation(data?.payload?.data);
		setIsLoading(false);
	};

	const handleKeyPress = (e: {
		shiftKey: string;
		key: string;
		preventDefault: () => void;
	}) => {
		if (e.key === 'Enter' && e.shiftKey) {
			return;
		} else if (e.key === 'Enter') {
			e.preventDefault();
			if (messages.trim() !== '') {
				sendMessage();
			} else {
				message.error(t('Patient.data.activityStream.messageErr'));
			}
		}
	};

	const updateOmniROMData = (newDate: Date) => {
		setIsFilter(true);
		fetchData(newDate, true);
	};

	const sendMessage = async () => {
		const isVideoBlobValid = videoBlob;
		const isImgFileValid = imgFile && imgFile.length !== 0;
		if (
			(messages.trim() !== '' &&
				!isVideoModalVisible &&
				!isImageModalVisible &&
				messages) ||
			isVideoBlobValid ||
			isImgFileValid
		) {
			const payload = {
				userId: id,
				activityStreamId: shareItem[0]?.data.activityStreamId!,
				physioterapistId: user.isPhysioterapist && user.id,
				message: messages ? messages : messageDescription,
				image: imgFile,
				video: videoBlob,
			};
			const loadingMessage = message.loading(
				t('Patient.data.activityStream.sendingMessage'),
				0,
			);
			try {
				await dispatch(postActivityStream(payload));
				fetchData(new Date(), false);
				setMessage('');
				setImgFile([]);
				message.success(t('Patient.data.activityStream.sendMessage'));
				handleClose();
				setIsReply(false);
				setShareItem([]);
				if (lastMessageRef.current) {
					lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
				}
			} catch (error) {
				message.error(t('Patient.data.activityStream.sendMessageErr'));
			} finally {
				loadingMessage();
			}
		} else {
			if (
				!isVideoBlobValid &&
				!isImgFileValid &&
				!isImageModalVisible &&
				!isVideoModalVisible
			) {
				message.error(t('Patient.data.activityStream.messageErr'));
			} else if (!isImgFileValid && isImageModalVisible) {
				message.error(t('Patient.data.activityStream.imageErr'));
			} else {
				message.error(t('Patient.data.activityStream.videoErr'));
			}
		}
	};

	const checkEvaluation = (activityStreamData: IActivityStreamData[]) => {
		const evaluationArray: EvaluationItem[] = [];
		if (activityStreamData) {
			for (const item of activityStreamData) {
				if (item?.activityStream?.evaluationSessionId) {
					evaluationArray.push({
						title: t('Patient.data.activityStream.evaluation'),
						subtitle: `${user.isPhysioterapist ? selectedUser.profile.fullName : user.profile.fullName} ${t('Patient.data.activityStream.evaluationDescription')}`,
						data: item,
						value: false,
					});
				} else if (item?.activityStream?.romSessionId) {
					evaluationArray.push({
						title: t('Patient.data.activityStream.omniRom'),
						subtitle: `${user.isPhysioterapist ? selectedUser.profile.fullName : user.profile.fullName} ${t('Patient.data.activityStream.omniRomDescription')}`,
						data: item,
						value: false,
					});
				} else if (item?.activityStream?.surveyResultId) {
					evaluationArray.push({
						title: t('Admin.data.survey.surveySummary'),
						subtitle: `${user.isPhysioterapist ? selectedUser?.profile?.fullName : user.profile.fullName} ${t('Admin.data.survey.completedSurvey')}`,
						data: item,
						value: false,
					});
				} else if (item?.activityStream?.surveyId) {
					evaluationArray.push({
						title: '',
						subtitle: '',
						data: item,
						value: true,
					});
				} else if (item?.activityStream?.programId) {
					evaluationArray.push({
						title: '',
						subtitle: '',
						data: item,
						value: true,
					});
				} else if (item?.activityStream?.programSessionId) {
					evaluationArray.push({
						title: t('Patient.data.activityStream.program'),
						subtitle: `${user.isPhysioterapist ? selectedUser.profile.fullName : user.profile.fullName} ${t('Patient.data.activityStream.programDescription')}`,
						data: item,
						value: false,
					});
				} else if (item?.activityStreamPost) {
					evaluationArray.push({
						title: '',
						subtitle: '',
						data: item,
						value: false,
					});
				}
			}
		}
		setEvaluationData(evaluationArray.reverse());
	};

	const handleImgChange = (e: { target: { files: File[] } }) => {
		const files = e.target.files;
		message.success(t('Patient.data.activityStream.imageSelected'));
		if (files[0]) {
			setImgFile([files[0]]);
		}
	};

	const handleShare = (item: EvaluationItem) => {
		setShareItem([item]);
		setIsReply(true);
		inputRef.current?.focus();
	};

	const getProfileData = async () => {
		if (user.isPhysioterapist) {
			setProfileData(selectedUser);
		} else {
			setProfileData(user);
		}
	};

	const handleScrollTo = (index: string | null) => {
		const element = document.getElementById(`evaluationItem-${index}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleLoad = () => {
		if (updateData?.pagination?.hasNextPage) {
			const fetchUpdateData = async (newDate: Date) => {
				const today = newDate;
				const startDate = new Date(today);
				startDate.setDate(today.getDate() - 90);
				const formattedToday = today.toISOString().split('T')[0];
				const formattedStartDate = startDate.toISOString().split('T')[0];
				const endCursor = updateData?.pagination?.endCursor;
				setIsLoading(true);
				setEvaluationData([]);
				const data = await dispatch(
					getActivityUpdateStream({
						id,
						formattedToday,
						formattedStartDate,
						endCursor,
					}),
				);
				setActivityStreamData(prevData => [
					...prevData,
					...data?.payload?.data,
				]);
				setUpdateData(data.payload);
				setIsLoading(false);
			};
			fetchUpdateData(new Date());
		}
	};

	const openImgInput = () => {
		imgInputRef.current?.click();
	};

	useEffect(() => {
		getProfileData();
	}, []);

	useEffect(() => {
		fetchData(new Date(), false);
	}, [id]);

	useEffect(() => {
		if (selectedUser?.id) {
			getProfileData();
		}
		if (!isSuperAdmin) {
			setFunctionalData(functionalGoals?.data)
		}
	}, [selectedUser, dispatch]);

	useEffect(() => {
		setEvaluationData([]);
		checkEvaluation(activityStreamData);
		if (lastMessageRef.current && scrollEnabled) {
			lastMessageRef.current.scrollIntoView({ behavior: 'auto' });
		}
	}, [activityStreamData, user, selectedUser]);

	useEffect(() => {
		let timer;
		timer = setTimeout(() => {
			setScrollEnabled(false);
		}, 3000);
		() => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<div
			className={`select-none`}
			ref={containerRef}
			style={{
				maxHeight:
					isHeader !== false
						? isReply
							? 'calc(100vh - 190px)'
							: 'calc(100vh - 80px)'
						: 'none',
				overflowY:
					evaluationData && evaluationData.length > 0 && isHeader !== false
						? 'auto'
						: 'hidden',
				width: '100%',
			}}>
			<ActivityStreamData
				isHeader={isHeader}
				evaluationData={evaluationData}
				updateOmniROMData={updateOmniROMData}
				isFilter={isFilter}
				clearFilter={clearFilter}
				handleScrollTo={handleScrollTo}
				isLoading={isLoading}
				scrollEnabled={scrollEnabled}
				handleLoad={handleLoad}
				functionalData={functionalData}
				eventData={eventData}
				isReply={isReply}
				handleShare={handleShare}
				updateData={updateData}
				lastMessageRef={lastMessageRef}
			/>
			<ActivityStreamFooter
				isReply={isReply}
				shareItem={shareItem}
				setIsReply={setIsReply}
				showVideoModal={showVideoModal}
				showImageModal={showImageModal}
				handleScrollTo={handleScrollTo}
				microphoneRef={microphoneRef}
				sendMessage={sendMessage}
				setShareItem={setShareItem}
				imgInputRef={imgInputRef}
				handleImgChange={handleImgChange}
				messages={messages}
				setMessage={setMessage}
				handleKeyPress={handleKeyPress}
				inputRef={inputRef}
			/>
			<VideoModal
				videoBlob={videoBlob}
				videoState={videoState}
				imgFile={imgFile}
				facingMode={facingMode}
				onRecordFinished={onRecordFinished}
				isVideoModalVisible={isVideoModalVisible}
				isImageModal={isImageModalVisible}
				openImgInput={openImgInput}
				handleClose={handleClose}
				onStartRecord={onStartRecord}
				onStopRecord={onStopRecord}
				onDiscardRecord={onDiscardRecord}
				onEndedTimer={onEndedTimer}
				isStartingTimer={isStartingTimer}
				videoRef={videoRef}
				messageDescription={messageDescription}
				setMessageDescription={setMessageDescription}
				sendMessage={sendMessage}
				handleKeyPress={handleKeyPress}
			/>
		</div>
	);
}
