import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import strapi from '@strapi';
import { stringify } from 'qs';
import {
	createContext,
	useContext,
	useState,
	useMemo,
	useCallback,
	PropsWithChildren,
	useEffect,
} from 'react';
import { useControls } from '../hooks/UseControls';
import html2canvas from 'html2canvas';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import axios from 'axios';
import { landmarksMapping, videoSize } from '../constants';
import { setUploadProgress } from '@stores/postures/postures';
import { setVideoRecordState } from '@stores/onBoard/onBoard';

type ViewTypeLeftOrRight = 'left' | 'right';

type ViewTypeFrontOrBack = 'front' | 'back';

export interface PosturalBodyPoints {
	head?: number;
	ear: number;
	shoulder: number;
	elbow: number;
	hip: number;
	knee: number;
	ankle?: number;
	coordinates: NormalizedLandmark[];
	screenshot: string | null;
}

export interface StrapiPostureAnalytics {
	id: number;
	name: string;
	view: 'front' | 'left' | 'right' | 'back';
	video: {
		id: number;
		url: string;
	};
}

export interface PostureAnalyticsCompleted {
	userId: string;
	view: 'front' | 'left' | 'right' | 'back';
	head?: number;
	ear: number;
	shoulder: number;
	elbow: number;
	hip: number;
	knee: number;
	ankle?: number;
	coordinates: NormalizedLandmark[];
	screenshot: string | null;
	postureAnalyticsSessionId: string;
	completed: boolean;
}

export type PosturalAnalytics = StrapiPostureAnalytics &
	PostureAnalyticsCompleted;

interface ControlsContextData {
	isRepeat: boolean;
	isCompleted: boolean;
	positions: Partial<PosturalAnalytics>[];
	results: PosturalBodyPoints | null;
	onRepeat: (repeat: boolean) => void;
	onGetCurrent: (
		isReturnIndex?: boolean,
	) => number | Partial<PosturalAnalytics>;
	onNext: (startIndex?: number) => void;
	onReset: () => void;
	onMarkAsCompleted: () => void;
	onSubmit: () => void;
	postureAnalyticsSessionId: string;
	processAndCapturePosture: (landmarks: NormalizedLandmark[]) => void;
}

const ControlsContext = createContext<ControlsContextData>({
	isRepeat: false,
	isCompleted: false,
	postureAnalyticsSessionId: "",
	positions: [],
	results: null,
	onRepeat: () => { },
	onGetCurrent: () => 0,
	onNext: () => { },
	onReset: () => { },
	onMarkAsCompleted: () => { },
	onSubmit: () => { },
	processAndCapturePosture: () => { },
});

export const getPrintScreen = async () => {
	const canvas = await html2canvas(
		document.getElementById('printscreen_posture_analytics'),
	);

	return canvas.toDataURL();
};

function invertSignal(number: number, condition: boolean): number {
	return condition ? -number : number;
}

const vectorAngle = (x: number[], y: number[]): number => {
	return Math.acos(
		x.reduce((acc: number, n: number, i: number) => acc + n * y[i], 0) /
		(Math.hypot(...x) * Math.hypot(...y)),
	);
};

function drawAngleMeasurementHorizontal(
	A: NormalizedLandmark,
	B: NormalizedLandmark,
) {
	const C = { x: B.x, y: A.y };

	const AB = { x: B.x - A.x, y: B.y - A.y };
	const CA = { x: C.x - A.x, y: C.y - A.y };

	const angle =
		vectorAngle(
			[AB.x * videoSize.width, AB.y * videoSize.height],
			[CA.x * videoSize.width, CA.y * videoSize.height],
		) *
		(180 / Math.PI);

	return Math.trunc(invertSignal(angle, B.y > A.y));
}

function drawAngleMeasurementVerticalLeftAndRight(
	A: NormalizedLandmark,
	B: NormalizedLandmark,
	lineBase: NormalizedLandmark,
): number {
	const C = { x: lineBase.x, y: A.y };
	const D = { x: lineBase.x, y: B.y };

	const AD = { x: D.x - A.x, y: D.y - A.y };
	const DC = { x: C.x - D.x, y: C.y - D.y };

	const angle: number =
		vectorAngle(
			[AD.x * videoSize.width, AD.y * videoSize.height],
			[DC.x * videoSize.width, DC.y * videoSize.height],
		) *
		(180 / Math.PI);

	const result = A.x < C.x ? angle - 180 : 180 - angle;

	return Math.trunc(result);
}

function drawAngleMeasurementVertical(
	A: NormalizedLandmark,
	B: NormalizedLandmark,
	C: NormalizedLandmark,
): number {
	const newA: NormalizedLandmark = {
		...A,
		x: (A.x + B.x) / 2,
		y: (A.y + B.y) / 2,
	};

	const newB: NormalizedLandmark = {
		...B,
		x: (A.x + B.x) / 2,
		y: 0,
	};

	const AB = { x: newB.x - newA.x, y: newB.y - newA.y };
	const CA = { x: C.x - newA.x, y: C.y - newA.y };

	const angle =
		vectorAngle(
			[AB.x * videoSize.width, AB.y * videoSize.height],
			[CA.x * videoSize.width, CA.y * videoSize.height],
		) *
		(180 / Math.PI);

	return Math.trunc(invertSignal(angle, newB.y > newA.y));
}

export function ControlsProvider({ children }: Readonly<PropsWithChildren>) {
	const [positions, setPositions] = useState<Partial<PosturalAnalytics>[]>([]);
	const [isRepeat, setIsRepeat] = useState(false);
	const [postureAnalyticsSessionId, setPostureAnalyticsSessionId] = useState<
		string | null
	>(null);
	const [results, setResults] = useState<PosturalBodyPoints | null>(null);

	const dispatch = useTypedDispatch();
	const { onGetCurrent, onNext, onReset, onMarkAsCompleted, isCompleted } =
		useControls(positions);

	// remove after change redux
	const userId = useTypedSelector(state => {
		const selectedUser = state.contacts.main.selectedUser;
		const user = state.user;
		return user.isPhysioterapist ? selectedUser?.id : user?.id;
	});

	const onRepeat = useCallback(
		async (repeat = true) => {
			setIsRepeat(repeat);
		},
		[setIsRepeat],
	);

	const onGetPositions = async () => {
		const query = stringify({
			fields: ['name', 'view'],
			order: ['order:desc'],
			populate: {
				video: {
					fields: ['url'],
				},
			},
		});

		const { data } = await strapi.get(
			`https://strapi.carespace.ai/api/posture-analytics?${query}`,
		);

		const positions = data.data as PosturalAnalytics[];

		setPositions(positions);
	};

	const isLeftOrRightView = (view: string): view is ViewTypeLeftOrRight => {
		return view === 'left' || view === 'right';
	};

	const onStartSession = useCallback(async () => {
		try {
			const { data } = await axios.post('/posture-analytics/sessions', {
				userId,
			});

			data?.id && setPostureAnalyticsSessionId(data.id);
		} catch (error) {
			console.error(error);
		}
	}, [userId]);

	const getResultLeftAndRight = (
		view: ViewTypeLeftOrRight,
		landmarks: NormalizedLandmark[],
	): Omit<PosturalBodyPoints, 'coordinates' | 'screenshot'> => {
		return {
			ear: drawAngleMeasurementVerticalLeftAndRight(
				landmarks[landmarksMapping[view].ear[0]],
				landmarks[landmarksMapping[view].ear[1]],
				landmarks[landmarksMapping[view].ear[2]],
			),
			shoulder: drawAngleMeasurementVerticalLeftAndRight(
				landmarks[landmarksMapping[view].shoulder[0]],
				landmarks[landmarksMapping[view].shoulder[1]],
				landmarks[landmarksMapping[view].shoulder[2]],
			),
			elbow: drawAngleMeasurementVerticalLeftAndRight(
				landmarks[landmarksMapping[view].elbow[0]],
				landmarks[landmarksMapping[view].elbow[1]],
				landmarks[landmarksMapping[view].elbow[2]],
			),
			hip: drawAngleMeasurementVerticalLeftAndRight(
				landmarks[landmarksMapping[view].hip[0]],
				landmarks[landmarksMapping[view].hip[1]],
				landmarks[landmarksMapping[view].hip[2]],
			),
			knee: drawAngleMeasurementVerticalLeftAndRight(
				landmarks[landmarksMapping[view].knee[0]],
				landmarks[landmarksMapping[view].knee[1]],
				landmarks[landmarksMapping[view].knee[2]],
			),
		};
	};

	const getResultFrontAndBack = (
		view: ViewTypeFrontOrBack,
		landmarks: NormalizedLandmark[],
	): Omit<PosturalBodyPoints, 'coordinates' | 'screenshot'> => {
		return {
			head: drawAngleMeasurementVertical(
				landmarks[landmarksMapping[view].head[0]],
				landmarks[landmarksMapping[view].head[1]],
				landmarks[landmarksMapping[view].head[2]],
			),
			ear: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].ear[0]],
				landmarks[landmarksMapping[view].ear[1]],
			),
			shoulder: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].shoulder[0]],
				landmarks[landmarksMapping[view].shoulder[1]],
			),
			elbow: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].elbow[0]],
				landmarks[landmarksMapping[view].elbow[1]],
			),
			hip: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].hip[0]],
				landmarks[landmarksMapping[view].hip[1]],
			),
			knee: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].knee[0]],
				landmarks[landmarksMapping[view].knee[1]],
			),
			ankle: drawAngleMeasurementHorizontal(
				landmarks[landmarksMapping[view].ankle[0]],
				landmarks[landmarksMapping[view].ankle[1]],
			),
		};
	};

	const processAndCapturePosture = useCallback(
		async (landmarks: NormalizedLandmark[]) => {
			const printscreen = await getPrintScreen();

			const { view } = onGetCurrent(false) as Partial<PosturalAnalytics>;

			if (view && isLeftOrRightView(view)) {
				const result = {
					...getResultLeftAndRight(view, landmarks),
					coordinates: landmarks,
					screenshot: printscreen,
				};

				setResults(result);
			} else if (view) {
				const result = {
					...getResultFrontAndBack(view, landmarks),
					coordinates: landmarks,
					screenshot: printscreen,
				};
				setResults(result);
			} else {
				console.error('View not found');
			}
		},
		[setResults, onGetCurrent],
	);

	const onSubmit = useCallback(async () => {
		const { view } = onGetCurrent(false) as Partial<PosturalAnalytics>;
		dispatch(setVideoRecordState(true))

		const body = {
			userId,
			view,
			postureAnalyticsSessionId,
			...results,
		};

		try {
			await axios.post('/posture-analytics', body, {
				onUploadProgress: progressEvent => {
					const progress = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total!,
					);
					dispatch(setUploadProgress(progress));
				},
			});

			onMarkAsCompleted();
			dispatch(setUploadProgress(0));
		} catch (error) {
			console.error(error);
		}
	}, [
		onGetCurrent,
		onMarkAsCompleted,
		userId,
		postureAnalyticsSessionId,
		results,
	]);

	useEffect(() => {
		onGetPositions();
		onStartSession();

		return () => {
			setPositions([]);
			setIsRepeat(false);
			setResults(null);
			setPostureAnalyticsSessionId(null);
		};
	}, []);

	const values = useMemo(
		() => ({
			positions,
			onGetCurrent,
			onNext,
			onReset,
			isRepeat,
			isCompleted,
			onRepeat,
			onMarkAsCompleted,
			onSubmit,
			postureAnalyticsSessionId,
			processAndCapturePosture,
			results,
		}),
		[
			positions,
			isRepeat,
			isCompleted,
			onRepeat,
			onGetCurrent,
			onNext,
			onReset,
			onMarkAsCompleted,
			onSubmit,
			postureAnalyticsSessionId,
			processAndCapturePosture,
			results,
		],
	);

	return (
		<ControlsContext.Provider value={values}>
			{children}
		</ControlsContext.Provider>
	);
}

export function UseControls() {
	return useContext(ControlsContext);
}
