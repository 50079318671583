import { UserPlus01 } from '@carespace-icons/users/userPlus01';
import InvitePatientsModal from '@pages/InvitePatientsModal';
import { useTheme } from '@pages/Themes/ThemeContext';
import { getPendingInvitesList, getRegisteredPatientList, getUnAssignedPatientList } from '@stores/adminPatient';
import { pagination, USER_ROLES } from '@stores/constants';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { IStatusNormalizedPatients, ISubMenuAdminPatients, StatusPatients } from '@stores/interfaces';
import { Badge, Button } from 'antd';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function AdminMenuContactsContent(props: any) {
  const { onClick , activeSubPatients, setActiveSubPatients} = props
  const [isInvitePatientModalOpen, setIsInvitePatientModalOpen] = useState(false);
  const user = useTypedSelector(state => state.user);
  const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const {theme} = useTheme()

  console.log(theme)
  const subMenuPatients: ISubMenuAdminPatients[] = [
    {
      label: isSuperAdmin ? t('Admin.data.menu.userRoles.unAssignedPatients.userLabel') : t('Admin.data.menu.userRoles.unAssignedPatients.label'),
      key: isSuperAdmin ? '' : 'unassigned-patients',
      icon: <Badge color="black" />,
      status: StatusPatients.unAssignedPatients,
    },
    {
      label: t('Admin.data.menu.userRoles.pendingInvites.label'),
      key: 'pending-invites',
      icon: <Badge color="#dabe08" />,
      status: StatusPatients.pendingInvites,
    },
    {
      label: isSuperAdmin ? t('Admin.data.menu.userRoles.registeredPatients.userLabel') : t('Admin.data.menu.userRoles.registeredPatients.label'),
      key: 'registered-patients',
      icon: <Badge color="#84caff" />,
      status: StatusPatients.registeredPatients,
    },
  ];

  const totalPatientsStatus = useTypedSelector(state => { 
    return {
      unAssignedPatients: state.adminDashboardPatient.unAssignedCount,
      pendingInvites: state.adminDashboardPatient.pendingCount,
      registeredPatients: state.adminDashboardPatient.registeredCount,
    };
  });

  const buttonStyle = {
    color: "var(--button-text-color)",
    border: 'inherit',
    width: '100%',
    marginBottom: '20px',
  };

  const handleClickEvent = (key: string) => {
    const payload = {
      ...pagination,
      userId: user?.id
    }
    if (key === '' || key === 'unassigned-patients') {
      dispatch(getUnAssignedPatientList(pagination));
    }
    else if (key === 'pending-invites') {
      dispatch(getPendingInvitesList(pagination));
    } else if (key === 'registered-patients') {
      dispatch(getRegisteredPatientList(payload));
    }
  }

  return (
    <>
      <h3 className="submenu-title">{isSuperAdmin ? t('Admin.data.menu.userRoles.usersLabel') : t('Admin.data.menu.userRoles.PatientsLabel')}</h3>
      <Button style={buttonStyle} className='start-session-css' icon={<UserPlus01 height={15} width={16} color={theme === 'vibrant' ? "stroke-white" : "stroke-[#533F85]"} />} onClick={(e) => {
        e.stopPropagation(); setIsInvitePatientModalOpen(true)
      }}>{t('Admin.data.menu.userRoles.invitePatients')}</Button>
      {
        isInvitePatientModalOpen && <InvitePatientsModal isInvitePatientModalOpen={isInvitePatientModalOpen} setIsInvitePatientModalOpen={setIsInvitePatientModalOpen} closable={true} />
      }
      <ul>
        {subMenuPatients.map((item: any, index) => {
          return (
            <li
              className={`submenu-item ${activeSubPatients === item.key ? 'submenu-item-active' : ''}`}
              key={index}
              onClick={() => {
                setActiveSubPatients(item.key);
                onClick(item.key);
                handleClickEvent(item.key);
              }}>
              <span className="icon-container">{item.icon}</span>
              <span className="label-container">{item.label}</span>
              {item.key != 'summary' && (
                <span className="status-count">
                  {
                    totalPatientsStatus[
                    item?.status as keyof IStatusNormalizedPatients
                    ]
                  }
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </>
  )
}
