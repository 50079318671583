import React, { useEffect, useState } from 'react';
import { Send03 } from '@carespace-icons/communication/send03';
import { Image03 } from '@carespace-icons/files/image03';
import { Plus } from '@carespace-icons/general/plus';
import { Trash01 } from '@carespace-icons/general/trash01';
import { XClose } from '@carespace-icons/general/xClose';
import { Microphone01 } from '@carespace-icons/media/microphone01';
import { StopCircle } from '@carespace-icons/media/stopCircle';
import { VideoRecorder } from '@carespace-icons/media/videoRecorder';
import { useTypedSelector } from '@stores/index';
import { EvaluationItem, IFooterProps } from '@stores/interfaces';
import { Popover, Tooltip, message } from 'antd';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import checkLogo from './VoiceRecordingLogo.json';
import SpeechRecognition, {
	useSpeechRecognition,
} from 'react-speech-recognition';
import './style.css';
import Avatar from '@atoms/Avatar/Avatar';

function ActivityStreamVideoFooter(props: IFooterProps) {
	const {
		isReply,
		shareItem,
		setIsReply,
		showVideoModal,
		showImageModal,
		microphoneRef,
		sendMessage,
		imgInputRef,
		handleImgChange,
		messages,
		setMessage,
		handleKeyPress,
		inputRef,
		handleScrollTo,
		setShareItem,
	} = props;
	const isCollapsible = useTypedSelector(state => state.aiAssistant.aiAssistant.isCollapsible)
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [recordingState, setRecordingState] = useState(false);
	const [recordedState, setRecordedState] = useState(false);
	const { t } = useTranslation();
	const [isPopup, setPopup] = useState(false);
	const [isRecording, setIsRecording] = useState(false);

	const {
		transcript,
		listening,
		resetTranscript,
		browserSupportsSpeechRecognition,
	} = useSpeechRecognition();

	if (!browserSupportsSpeechRecognition) {
		return <span>Browser doesn't support speech recognition.</span>;
	}

	const startListening = () =>
		SpeechRecognition.startListening({ continuous: true });
	const stopListening: any = SpeechRecognition.stopListening;
	const toggleRecording = () => {
		if (!isRecording) {
			startListening();
			message.info(t('Patient.data.activityStream.recStarted'));
		} else {
			stopListening();
			message.warning(t('Patient.data.activityStream.recorded'));
			setMessage(transcript);
			inputRef.current.focus();
		}
		setIsRecording(prevState => !prevState);
	};

	const handlePopupItemClick = () => {
		setPopup(false);
	};

	useEffect(() => {
		if (listening) {
			setMessage(transcript);
			inputRef.current.scrollTop = inputRef.current.scrollHeight;
		}
	}, [transcript, listening, setMessage]);

	useEffect(() => {
		inputRef.current.focus();
	}, [selectedUser, user]);

	return (
		<div className="activity-stream-footer">
			<div className="fixed-footer"
			style={{
				...(!isCollapsible && { width: '80vw' })
			  }}
			>
				{isReply &&
					shareItem.map((item: EvaluationItem, index: number) => (
						<div
							key={index}
							className="reply-item"
							onClick={() => {
								handleScrollTo(item.data.activityStreamId);
							}}>
							<div>
								<div className="reply-item-header">
									<div className="custom-space-footer">&nbsp;</div>
									<Avatar
										user={user.isPhysioterapist ? selectedUser : user}
										size={25}
									/>
									<p className="custom-footer-username">
										{user.isPhysioterapist
											? selectedUser?.profile?.fullName
											: user?.profile?.fullName}
									</p>
								</div>
								<div className="reply-item-body">
									<div className="custom-space-footer">&nbsp;</div>
									<div className="reply-item-content">
										<p className="custom-font-bold custom-text-gray-blue-500">
											{item.title}
										</p>
										<p className="custom-text-gray-blue-500">{item.subtitle}</p>
									</div>
								</div>
							</div>
							<div
								className="custom-close"
								onClick={event => {
									setShareItem([]);
									setIsReply(false);
									event.stopPropagation();
								}}>
								<XClose color="stroke-gray-600" />
							</div>
						</div>
					))}
				<div className="footer-controls">
					{recordingState && (
						<div className="lottie-animation">
							<Lottie animationData={checkLogo} loop={true} autoplay={true} />
						</div>
					)}
					{!isRecording && !recordedState && (
						<Popover
							placement="top"
							open={isPopup}
							onOpenChange={visible => setPopup(visible)}
							content={
								<>
									<Tooltip
										placement="topRight"
										color="purple"
										showArrow={false}>
										<div
											className="popup-item"
											onClick={() => {
												showVideoModal();
												handlePopupItemClick();
											}}>
											<VideoRecorder color="stroke-primary-400" />{' '}
											<span className="custom-icon-text">Video</span>
										</div>
									</Tooltip>
									<Tooltip
										placement="topRight"
										color="purple"
										showArrow={false}>
										<div
											className="popup-item"
											onClick={() => {
												showImageModal();
												handlePopupItemClick();
											}}>
											<Image03 color="stroke-primary-400" />{' '}
											<span className="custom-icon-text">Image</span>
										</div>
									</Tooltip>
									<Tooltip
										placement="topRight"
										color="purple"
										showArrow={false}>
										<div
											className={`popup-item`}
											onClick={() => {
												setRecordingState(true);
												toggleRecording();
												handlePopupItemClick();
											}}
											ref={microphoneRef}>
											<Microphone01 color="stroke-primary-400" />{' '}
											<span className="custom-icon-text">
												{isRecording ? 'Stop' : 'Record'}
											</span>
										</div>
									</Tooltip>
								</>
							}
							trigger="click"
							overlayInnerStyle={{
								backgroundColor: 'var(--user-menu-dropdown-bg-color)',
								paddingLeft: '20px',
								paddingRight: '20px',
							}}>
							<Tooltip placement="topRight" color="purple" showArrow={false}>
								<div
									className="custom-icon-class"
									onClick={() => {
										setPopup(!isPopup);
									}}>
									{!isPopup ? (
										<Plus color="stroke-gray-600" />
									) : (
										<div className="custom-x-icon-class">
											<XClose color="stroke-white" />
										</div>
									)}
								</div>
							</Tooltip>
						</Popover>
					)}
					{isRecording && !recordedState && (
						<Tooltip
							placement="topRight"
							title={t('Patient.data.activityStream.stopTooltip')}
							color="purple"
							showArrow={false}>
							<div
								className="custom-stop-icon-class "
								onClick={() => {
									toggleRecording();
									setRecordingState(false);
									setRecordedState(true);
								}}>
								<StopCircle color="stroke-orange-dark-500" />
							</div>
						</Tooltip>
					)}
					{recordedState && (
						<>
							<Tooltip
								placement="topRight"
								title={t('Patient.data.activityStream.discardTooltip')}
								color="purple"
								showArrow={false}>
								<div
									className="custom-ml-2 custom-stop-icon-class "
									onClick={() => {
										setMessage('');
										resetTranscript();
										message.success(
											t('Patient.data.activityStream.recDiscard'),
										);
										setRecordedState(false);
									}}>
									<Trash01 color="stroke-orange-dark-500" />
								</div>
							</Tooltip>
						</>
					)}
					<input
						type="file"
						accept="image/*"
						className="hidden"
						ref={imgInputRef}
						onChange={handleImgChange}
					/>
					<textarea
						value={messages}
						onChange={e => setMessage(e.target.value)}
						onKeyDown={handleKeyPress}
						ref={inputRef}
						placeholder={t('Patient.data.activityStream.message')}
						className={`textarea ${
							recordingState
								? 'custom-pointer-events-none'
								: 'custom-pointer-events-auto'
						}`}
						style={{
							height: '50px',
							padding: messages.length > 70 ? '4px' : '15px',
							overflowY: messages.length > 70 ? 'auto' : 'hidden',
						}}
						rows={1}
					/>
					{!isRecording && (
						<Tooltip
							placement="topRight"
							title={t('Patient.data.activityStream.send')}
							color="purple"
							showArrow={false}>
							<div
								className="custom-ml-2 custom-stop-icon-class"
								onClick={() => {
									sendMessage();
									setRecordedState(false);
									resetTranscript();
								}}>
								<Send03
									color={recordedState ? 'stroke-green-500' : 'stroke-gray-600'}
								/>
							</div>
						</Tooltip>
					)}
				</div>
			</div>
		</div>
	);
}

export default ActivityStreamVideoFooter;
