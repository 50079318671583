import { Col, Collapse, Row, Tabs, Image, Modal } from 'antd';
import moment from 'moment';
import { Line } from `@ant-design/charts`;
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setGalleryImages } from '@stores/contacts/contacts';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';
import ReactCompareImage from 'react-compare-image';
import { IRomCaptureResultProps } from '@stores/interfaces';
import '../../Contacts/Contacts.css';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const { Panel } = Collapse;

export default function RomCaptureResult(props: IRomCaptureResultProps) {
  const { aiAssistant, extraContentCaptureCollapse, config, onSelectImage } = props
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [compareImage, setCompareImage] = useState(false);
  const { coach, exercises, gallery } =
    useTypedSelector((state) => ({
      coach: state.contacts.main.coach,
      exercises: state.contacts.main.exercises,
      gallery: state.contacts.main.gallery
    }));

  const showCompare = () => setCompareImage(!compareImage);

  const headerCaptureCollapse = (label: any) => {
    return <>{label}</>;
  };

  useEffect(() => {
    dispatch(setGalleryImages({ exercises, coach }));
  }, [exercises, coach, dispatch]);


  return (
    <Collapse
      className={`select-none ${aiAssistant ? 'bg-white' : 'bg-gray-50'
        } py-0.5`}
      bordered={false}
    >
      {exercises?.map((exercise: { id: string; name: any; }, index: any) => {
        const dataItems = coach?.filter(
          (dataItem: { strapiOmniRomExerciseId: any; }) => dataItem?.strapiOmniRomExerciseId === exercise?.id,
        );
        config.data = dataItems?.map((item: { createdAt: moment.MomentInput; value: any; screenshot: any; }) => {
          const DateUtc = moment(item.createdAt)
            .local()
            .format('LLL');
          return {
            date: DateUtc,
            ROM: item.value,
            printScreen: item.screenshot,
          };
        });

        return (
          dataItems?.length > 0 &&
          exercise?.name && (
            <Panel
              className="header-panel bg-white !border !border-gray-200 !rounded-xl mt-2"
              header={headerCaptureCollapse(exercise?.name)}
              key={`panel-${index}`}
              extra={extraContentCaptureCollapse(
                exercise?.id,
                dataItems?.length,
              )}
            >
              <Tabs
                type="card"
                className="rounded-lg m-5"
                defaultActiveKey="1"
                items={[
                  {
                    label: t('Patient.data.myProgress.omniRom.chart'),
                    key: '1',
                    children: <Line {...config} />,
                  },
                  {
                    label: t(
                      'Patient.data.myProgress.omniRom.screenshots',
                    ),
                    key: '2',
                    children: (
                      <div style={{ marginTop: "20px" }}>
                        {gallery?.exerciseImages?.length > 0 && (
                          <div
                            className={`gallery-top ${gallery.imagesToCompare.length === 2
                              ? 'active'
                              : ''
                              }`}

                          >
                            <button
                              disabled={
                                gallery.imagesToCompare.length < 2
                              }
                              className={`btn-compare ${gallery.imagesToCompare.length === 2
                                ? 'active'
                                : ''
                                }`}
                              onClick={showCompare}
                            >
                              {t('Patient.data.myProgress.omniRom.compare')}
                            </button>
                          </div>
                        )}
                        <Row gutter={[14, 8]}>

                          {Object.keys(gallery?.images).length &&
                            gallery?.images[
                            dataItems[0]?.strapiOmniRomExerciseId
                            ] &&
                            gallery?.images[
                              dataItems[0]?.strapiOmniRomExerciseId
                            ].map((gImage: { date: moment.MomentInput; isSelected: any; src: string | undefined; exercise: { id: string; }; result: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | Iterable<ReactNode> | null | undefined; }, index: number) => {
                              const DateUtc = moment(gImage?.date)
                                .local()
                                .format('LLL');
                              return (
                                <Col
                                  key={`col-${index}`}
                                  xs={{ span: 12 }}
                                  sm={{ span: 12 }}
                                  lg={{ span: 5 }}
                                  xxl={{ span: 5 }}
                                >
                                  <div className="screenshot tipChart">
                                    <Image
                                      className={
                                        gImage.isSelected
                                          ? 'active'
                                          : ''
                                      }
                                      style={{ objectFit: 'contain' }}
                                      width="100%"
                                      height={125}
                                      src={gImage.src}
                                      preview={{
                                        src: gImage.src,
                                        mask: <MdOutlineRemoveRedEye size={18} />,
                                        width: "auto",
                                        height: "auto"
                                      }}
                                    />
                                    <CheckCircleFilled
                                      className={
                                        gImage.isSelected
                                          ? 'select-icon active'
                                          : 'select-icon'
                                      }
                                      onClick={onSelectImage.bind(
                                        this,
                                        index,
                                        gImage?.exercise?.id,
                                      )}
                                    />
                                    <div className="date">
                                      {DateUtc}
                                    </div>
                                    <div className="value">
                                      {gImage.result}°
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                        {compareImage && (

                          <Modal
                            title={t(
                              'Patient.data.myProgress.omniRom.compareImages',
                            )}
                            open={compareImage}
                            onOk={showCompare}
                            onCancel={showCompare}
                            className="gallery-modal"
                            centered
                            width="60%"
                          >
                            <div className="screen-gallery">
                              <ReactCompareImage
                                className="gallery-compare"
                                leftImage={
                                  gallery.imagesToCompare[0]?.src
                                }
                                rightImage={
                                  gallery.imagesToCompare[1]?.src
                                }
                                leftImageLabel={
                                  moment(
                                    gallery.imagesToCompare[0]?.date,
                                  )
                                    .local()
                                    .format('LLL') +
                                  t(
                                    'Patient.data.myProgress.omniRom.result',
                                  ) +
                                  gallery.imagesToCompare[0]?.result +
                                  '°'
                                }
                                rightImageLabel={
                                  moment(
                                    gallery.imagesToCompare[1]?.date,
                                  )
                                    .local()
                                    .format('LLL') +
                                  t(
                                    'Patient.data.myProgress.omniRom.result',
                                  ) +
                                  gallery.imagesToCompare[1]?.result +
                                  '°'
                                }
                              />
                            </div>
                          </Modal>
                        )}
                      </div>
                    ),
                  },
                ]}
              ></Tabs>
            </Panel>
          )
        );
      })}
    </Collapse>
  )
}
