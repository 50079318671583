import { MagicWand01 } from "@carespace-icons/editor/magicWand01"
import { useTranslation } from "react-i18next"
import { Button, Col, Empty, Image, Pagination, Row, Spin } from "antd"
import { TbClipboardPlus } from "react-icons/tb"
import { useEffect, useRef, useState } from "react"
import { SurveyOptionList } from "./SurveyOptionList"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { getSurvey, saveSurveyResult } from "@stores/survey/survey"
import { useLocation, useNavigate } from "react-router-dom"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import SelectedSurvey from "./CreateSurveyModal/SelectedSurvey"
import { ISurvey, ISurveyQuestion, ISurveyResultQuestionList } from "@stores/interfaces"
import { setActiveTab, setStateId, setSurveyStateId } from "@stores/aiAssistant/aiAssistant"

export const StartSurveyUser = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [loading, setLoading] = useState(true)
  const [selectedSurvey, setSelectedSurvey] = useState<ISurvey | null>()
  const [selectedSurveyData, setSelectedSurveyData] = useState<ISurveyQuestion[] | null>()
  const [selectedOptionList, setSelectedOptionList] = useState<ISurveyResultQuestionList[]>([])
  const [showThankyou, setShowThankyou] = useState(false)
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const survey = useTypedSelector((state) => state.survey.survey.survey)
  const lastDivRef = useRef<HTMLDivElement>(null);
  const { state } = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.active) {
      dispatch(setActiveTab("startSurveyUser"))
      setSelectedSurvey(state?.item);
      setShowThankyou(false);
      setSelectedSurveyData([state?.item?.questionList[0]]);
      navigate(".", { state: { ...state, active: false }, replace: true });
    }
  }, [state]);


  useEffect(() => {
    if (lastDivRef.current) {
      lastDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedSurveyData, showThankyou]);

  const saveSurvey = async () => {
    const surveyData = {
      title: selectedSurvey?.title,
      description: selectedSurvey?.description,
      userId: selectedSurvey?.userId,
      questionList: {
        create: selectedOptionList
      }
    }

    const data = await dispatch(saveSurveyResult({
      surveyId: selectedSurvey?.id!,
      surveyData: surveyData
    }))
    dispatch(setStateId(data?.payload?.id))
    dispatch(setSurveyStateId(data?.payload?.surveyId))
  }

  useEffect(() => {
    fetchSurveyData(1)
  }, [user, selectedUser])

  const fetchSurveyData = async (page: number) => {
    setLoading(true)
    const payload = {
      userId: user.isPhysioterapist ? selectedUser.id : user.id,
      limit: 10,
      page: page,
      approved: true
    }
    await dispatch(getSurvey(payload))
    setLoading(false)
  }

  const getQuestionTypeDescription = (questionType: string) => {
    switch (questionType) {
      case 'rate':
        return t('Admin.data.survey.selectRate');
      case 'openText':
        return t('Admin.data.survey.detailedAnswer');
      case 'multiSelect':
        return t('Admin.data.survey.allOptions');
      case 'singleSelect':
        return t('Admin.data.survey.oneOption');
      default:
        return '';
    }
  };

  const buttonStyle = {
		color: 'var(--button-text-color)',
		border: 'inherit',
		width: '100%',
	};

  if(loading){
    return <Spin className="spin-css" tip="Loading" size="large"/>
  }
  return <div className="start-survey-user">
    {
      selectedSurvey ? <div className={`selected-survey-container`}>
        <div style={{ opacity: selectedSurvey ? 100 : 0 }} className={`selected-survey-container-animator`}>
          <SelectedSurvey selectedSurvey={selectedSurvey} />
          {
            selectedSurveyData && selectedSurveyData?.map((item, index) => {
              return <div>
                <div className="survey-option-list-container" style={{color:'var(--text-color-root)'}}>
                  <div className="selected-survey-title-container">
                    <span className="magic-wand">
                      <MagicWand01 width={20} height={20} color="stroke-primary-600" />
                    </span>
                    <div className="question-container">
                      <p className="font-semibold" style={{color:'var(--text-color)'}}>{index + 1} - {item?.title} </p>
                      {item?.questionType != "rate" &&
                        <p className="question-type"  style={{color:'var(--text-color-root)'}}>{getQuestionTypeDescription(item?.questionType)}</p>}
                    </div>
                  </div>
                  <SurveyOptionList fetchSurveyData={fetchSurveyData} saveSurvey={saveSurvey} selectedOptionList={selectedOptionList} setSelectedOptionList={setSelectedOptionList} item={item} index={index} selectedSurveyData={selectedSurveyData} selectedSurvey={selectedSurvey} setSelectedSurvey={setSelectedSurvey} setSelectedSurveyData={setSelectedSurveyData} />
                </div>
              </div>
            })
          }

          <div ref={lastDivRef} style={{ paddingTop: "1.25rem", paddingBottom: "1.25rem" }}></div>
        </div>
      </div>
        : <div style={{ opacity: selectedSurvey ? 0 : 100 }} className={`survey-list-container`}>
          {
            survey?.data?.length! > 0 ?
              <>
                <Row className="list-container-row" gutter={[16, 16]}>
                  {
                    survey?.data?.map(item => {
                      return <Col xs={12} sm={12} md={12} lg={12} xl={12} key={item?.id}>
                        <div className="list-container-col">
                          <div className="image-container">
                            {item?.image ?
                              <Image src={item?.image} className="image-not-found" alt={t('Admin.data.survey.noImage')} onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.src = "images/white-image.png";
                              }}
                                preview={{
                                  src: item?.image || "images/white-image.png",
                                  mask: <MdOutlineRemoveRedEye size={18} />,
                                }}
                              /> :
                              <Image src="/images\white-image.png" className="image-not-found" alt={t('Admin.data.survey.noImage')}
                                preview={{
                                  src: "images/white-image.png",
                                  mask: <MdOutlineRemoveRedEye size={18} />,
                                }} />
                            }
                          </div>
                          <div className="survey-content-container">
                            <p className="survey-title">{item?.title} {item?.isFinished && <span style={{ color: 'rgb(152 162 179)' }}>({item?.isFinished && (t('Admin.data.survey.completed'))})</span>}</p>
                            <p className="survey-question-count">{t('Admin.data.survey.questions')}: {item?.questionList?.length}</p>
                            <p className="survey-description">{item?.description}</p>
                            <Button
                              className='start-survey-button non-finished-survey-css'
                              style={buttonStyle}
                              icon={<TbClipboardPlus height={18} width={18} color="var(--button-text-color)" />}
                              onClick={() => {
                                setSelectedSurvey(item)
                                setShowThankyou(false)
                                setSelectedSurveyData([item?.questionList![0]])
                              }}
                            >{item?.isFinished ? t('Admin.data.survey.startSurveyAgain') : t('Admin.data.survey.startSurvey')}</Button>
                          </div>
                        </div>
                      </Col>
                    })
                  }
                </Row>
              </>
              :
              <Empty className="empty" image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ color: "rgb(208 213 221)" }}>No Surveys Found</span>} />
          }
          {survey?.data?.length! > 0 &&
            <Pagination
              current={survey?.pagination?.currentPage}
              showSizeChanger={false}
              onChange={fetchSurveyData}
              total={survey?.pagination?.totalCount}
            />
          }
        </div>
    }
  </div>

}