import { IRehabVideoHeaderProps, RehabVideoState } from '@stores/interfaces';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { MdInfoOutline, MdMenu } from 'react-icons/md';
import { useTypedSelector } from '@stores/index';
import { useEffect, useRef } from 'react';
import ProgramVideoHeaderContent from './ProgramVideoHeaderContent';
import { LottieRefCurrentProps } from 'lottie-react';

const { Text } = Typography;

function ProgramVideoHeader(props: IRehabVideoHeaderProps) {
	const { videoState, onToggleMenu, onToggleInfo, savedVoice } = props;

	const { exercises, currentExercise } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const currentExerciseIndex = exercises.findIndex(
		exercise => exercise.id == currentExercise?.id,
	);
	const micRef = useRef<LottieRefCurrentProps>(null);

	useEffect(() => {
		micRef.current?.play();
		setTimeout(() => {
			micRef.current?.stop();
		}, 2000);
	}, [savedVoice]);

	return (
		<Row
			align="middle"
			justify="center"
			style={{ height: '35px', backgroundColor: 'black' }}>
			<Col span={16}>
				<Row
					style={{
						paddingLeft: 8,
						width: '100%',
						lineHeight: '35px',
						display: 'flex',
						justifyContent: 'start',
						gap: 10,
					}}>
					<Col
						className={`md:col-span-4 lg:col-span-3 xl:${videoState == RehabVideoState.START ? 'col-span-3' : 'col-span-1'}`}
						style={{
							height: '35px',
							display: 'flex',
							alignItems: 'center',
						}}>
						<Space>
							{videoState == RehabVideoState.START && (
								<Tooltip title="Menu">
									<MdMenu
										size={30}
										style={{ cursor: 'pointer' }}
										onClick={onToggleMenu}
										color="#5e5e5e"
									/>
								</Tooltip>
							)}
							<Tooltip title="Info">
								<MdInfoOutline
									size={26}
									style={{ cursor: 'pointer' }}
									onClick={onToggleInfo}
									color="#5e5e5e"
								/>
							</Tooltip>
						</Space>
					</Col>
					<Col
						className={videoState == RehabVideoState.START ? '' : 'ml-[10px]'}
						span={15}
						style={{ top: '1px' }}>
						<Text style={{ color: 'white' }}>
							{currentExercise?.name ? (
								<>
									{currentExercise &&
										`${currentExerciseIndex + 1}/${exercises.length} ${currentExercise?.name?.toUpperCase()}`}
								</>
							) : (
								<>
									{currentExercise &&
										`${currentExerciseIndex + 1}/${exercises.length} ${currentExercise?.exerciseLibrary?.title?.toUpperCase()}`}
								</>
							)}
						</Text>
					</Col>
				</Row>
			</Col>
			<Col span={8}>
				<ProgramVideoHeaderContent {...props} />
			</Col>
		</Row>
	);
}

export default ProgramVideoHeader;
