import { Check } from '@carespace-icons/general/check'
import { closestCorners, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Checkbox, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { MdAddCircleOutline } from 'react-icons/md'
import { AddExerciseItem } from './AddExerciseItem'
import { ICustomRomTemplate, IRomTemplateExercise } from '@stores/interfaces'
import TextArea from 'antd/es/input/TextArea'

interface IAddRomItem {
  selectedExercises: IRomTemplateExercise[];
  setSelectedExercises: (value: IRomTemplateExercise[]) => void;
  handleRemoveExercise: (id: number | string) => void;
  romName: string;
  setRomName: (value: string) => void
  romDescription: string;
  setRomDescription: (value: string) => void
  isSaveTemplate: boolean;
  setSaveTemplate: (value: boolean) => void;
  SetLibraryModalVisible: (val: boolean) => void
  handleCreateProgramTemplate: () => void
  handleCreateRomProgram: () => void
  handleUpdateRomProgram: () => void
  isEdit: boolean
}

const AddRomItem = (props: IAddRomItem) => {
  const { handleCreateRomProgram, handleUpdateRomProgram, isEdit, handleCreateProgramTemplate, setSaveTemplate, isSaveTemplate, romName, setRomName, romDescription, setRomDescription, selectedExercises, setSelectedExercises, handleRemoveExercise, SetLibraryModalVisible } = props
  const { t } = useTranslation()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getTaskPos = (id: string) => selectedExercises?.findIndex((exercise: { id: string }) => exercise.id === id);

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    const newArray = [...selectedExercises];
    [newArray[originalPos], newArray[newPos]] = [newArray[newPos], newArray[originalPos]];
    setSelectedExercises(newArray);
  };

  const updatePropertyAtIndex = (index: number, newReps: number, property: string) => {
    setSelectedExercises(selectedExercises.map((exercise: IRomTemplateExercise, idx: number) => {
      if (idx === index) {
        return {
          ...exercise,
          [property]: newReps,
        };
      }
      return exercise;
    }))
  };

  const handleSave = () => {
    if (!romName?.trim()) {
      message.error(t('Patient.data.omnirom.romNameErr'))
    } else if (!romDescription?.trim()) {
      message.error(t('Patient.data.omnirom.romDescriptionErr'))
    } else {
      if (isEdit) {
        handleUpdateRomProgram()
        isSaveTemplate && handleCreateProgramTemplate()
      } else {
        handleCreateRomProgram()
        isSaveTemplate && handleCreateProgramTemplate()
      }
    }
  }
  return (
    <>
      <p className='font-semibold'>{t('Patient.data.omnirom.romTitle')}</p>
      <div className='flex items-end mt-2 gap-2'>
        <Input
          className='w-full h-[45px]'
          placeholder={t('Patient.data.omnirom.romTitle')}
          value={romName}
          onChange={(e) => setRomName(e.target.value)}
        />
      </div>
      <p className='font-semibold mt-2'>{t('Patient.data.omnirom.romDescription')}</p>
      <div className='flex items-end mt-2 gap-2'>
        <TextArea
          className='w-full h-[45px]'
          rows={3}
          placeholder={t('Patient.data.omnirom.romDescription')}
          value={romDescription}
          onChange={(e) => setRomDescription(e.target.value)}
        />
      </div>
      <div onClick={() => SetLibraryModalVisible(true)} className="border-2 cursor-pointer border-gray-300 border-dashed p-3 w-full rounded-lg my-2 text-center text-base text-gray-400">
        <MdAddCircleOutline className="w-5 h-5 text-gray-400 mr-2" />
        {t('Patient.data.omnirom.addExercises')}
      </div>
      <div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto" }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={selectedExercises!} strategy={verticalListSortingStrategy}>
            {selectedExercises?.map((item: IRomTemplateExercise, index: number) => (
              <AddExerciseItem
                index={index}
                handleRemoveExercise={handleRemoveExercise}
                id={item.id}
                exercise={item}
                length={selectedExercises?.length}
                updatePropertyAtIndex={updatePropertyAtIndex}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className='flex items-center justify-center gap-2 checkbox-tick'>
        <Checkbox id={"selectSaveTemplate"} checked={isSaveTemplate} onChange={() => setSaveTemplate(!isSaveTemplate)} />
        <p>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.saveTemplate')}</p>
      </div>
      <div className="w-full my-2 bg-primary-600 cursor-pointer rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2"
        onClick={() => handleSave()} >
        <Check />
        {t('Admin.data.menu.aiAssistant.aiAssistantPrograms.save')}
      </div>
    </>
  )
}

export default AddRomItem