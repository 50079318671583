import { TPainAssessmentProps, TStrapiFactors } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';

interface TSummaryOption {
	item: TPainAssessmentProps;
}

const SummuryOptionsList = (props: TSummaryOption) => {
	const { item } = props;
	const { t } = useTranslation();
	const joinAttributes = (attributes: TStrapiFactors[]) => {
		return attributes
			.map((element: TStrapiFactors) => element.attributes.name)
			.join(' , ');
	};

	return (
		<>
			{
				<p className="font-semibold  pl-4 pt-2">
					{' '}
					Pain Level :{' '}
					<span className="font-regular">
						{item.painLevel ? item.painLevel : 0}
					</span>
				</p>
			}
			{item.strapiBodyPoint && (
				<p className="font-semibold  pl-4 pt-2">
					{' '}
					{t('Patient.data.summaryOptionList.location')}{' '}
					<span className="font-regular">
						{(item?.strapiBodyPoint?.attributes.name).toUpperCase()}
					</span>
				</p>
			)}
			{item.strapiAggravatingFactorsIds?.length > 0 && (
				<p className="font-semibold pl-4 pt-3">
					{t('Patient.data.summaryOptionList.agrFactor')}{' '}
					<span className="font-regular">
						{joinAttributes(item.strapiAggravatingFactors)}
					</span>
				</p>
			)}
			{item.strapiRelievingFactorsIds?.length > 0 && (
				<p className="font-semibold pl-4 pt-3">
					{t('Patient.data.summaryOptionList.relFactor')}{' '}
					<span className="font-regular">
						{joinAttributes(item.strapiRelievingFactors)}
					</span>
				</p>
			)}
			{item.strapiPainCausesIds?.length > 0 && (
				<p className="font-semibold pl-4 pt-3">
					{t('Patient.data.summaryOptionList.cop')}{' '}
					<span className="font-regular">
						{joinAttributes(item.strapiPainCauses)}
					</span>
				</p>
			)}
			{item.strapiPainDurationId > 0 && (
				<p className="font-semibold pl-4 pt-3">
					{t('Patient.data.summaryOptionList.dop')}{' '}
					<span className="font-regular">
						{item.strapiPainDuration.attributes.name}
					</span>
				</p>
			)}
			{item.strapiPainFrequencyId > 0 && (
				<p className="font-semibold pl-4 pt-3">
					{t('Patient.data.summaryOptionList.fop')}{' '}
					<span className="font-regular">
						{item.strapiPainFrequency.attributes.name}
					</span>
				</p>
			)}
			{item.strapiPainStatusId > 0 && (
				<p className="font-semibold pl-4 pt-2 pb-3">
					{t('Patient.data.summaryOptionList.sop')}{' '}
					<span className="font-regular">
						{item.strapiPainStatus.attributes.name}
					</span>
				</p>
			)}
		</>
	);
};

export default SummuryOptionsList;
