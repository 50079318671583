import React, { useEffect, useState } from 'react';
import { useTypedDispatch } from "@stores/index";
import { getProgramActivityStreamByData } from "@stores/activityStream/activityStream";
import { Col, Modal, Row, Spin, Typography } from "antd";
import { InfoCircle } from "@carespace-icons/general/infoCircle";
import { useTranslation } from "react-i18next";
import { FaceFrown } from '@carespace-icons/users/faceFrown';
import { FaceSad } from '@carespace-icons/users/faceSad';
import { FaceNeutral } from '@carespace-icons/users/faceNeutral';
import { FaceSmile } from '@carespace-icons/users/faceSmile';
import { FaceWink } from '@carespace-icons/users/faceWink';
import { FaceHappy } from '@carespace-icons/users/faceHappy';
import { IProgramSessionActivity } from '@stores/interfaces';
import './style.css'


const { Title, Text } = Typography;

export const ProgramSession = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [programData, setProgramData] = useState<IProgramSessionActivity>();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const fetchData = async (id: string) => {
      const data = await dispatch(getProgramActivityStreamByData(id));
      setProgramData(data?.payload);
    };

    fetchData(id);
  }, [id, dispatch]);

  const CustomModalInfo = (props: { name: string; description: string; video: string }) => {
    const { name, description, video } = props;
    const modalStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const modalContent = (
      <div className="modal-style" >
        <video
          controls
          className="video"
          preload="metadata"
          src={video}
          width="100%"
          height="100%"
        />
        <div className="no-select-margin-top">
          <Title level={5}>{name}</Title>
          <Text>{description}</Text>
        </div>
      </div>
    );
    Modal.info({
      title: null,
      content: modalContent,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: 'none' } },
      style: modalStyle,
      closable: true
    });
  };

  if (!programData) {
    return <div><Spin className="spinner" tip="Loading" size="large" /></div>;
  }
  const handleExtraHeader = (dataItems: IProgramSessionActivity) => {
    const getStatusComponent = () => {
      switch (dataItems?.overallCondition) {
        case "improving":
          return <span className='status status-improving'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.improving')}</span>
        case "worsening":
          return <span className='status status-worsening'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.worsening')}</span>
        case "noChange":
          return <span className='status status-nochange'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.noChange')}</span>
        default:
          return null;
      }
    };

    const painLevelData = {
      0: { color: 'text-cyan-500', icon: <FaceHappy color={'stroke-cyan-500'} width={20} height={20} /> },
      1: { color: 'text-green-700', icon: <FaceWink color={'stroke-green-700'} width={20} height={20} /> },
      2: { color: 'text-green-500', icon: <FaceSmile color={'stroke-green-500'} width={20} height={20} /> },
      3: { color: 'text-green-300', icon: <FaceSmile color={'stroke-green-300'} width={20} height={20} /> },
      4: { color: 'text-yellow-300', icon: <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} /> },
      5: { color: 'text-yellow-400', icon: <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} /> },
      6: { color: 'text-orange-300', icon: <FaceSad color={'stroke-orange-300'} width={20} height={20} /> },
      7: { color: 'text-orange-400', icon: <FaceSad color={'stroke-orange-400'} width={20} height={20} /> },
      8: { color: 'text-rose-500', icon: <FaceFrown color={'stroke-rose-500'} width={20} height={20} /> },
      9: { color: 'text-rose-600', icon: <FaceFrown color={'stroke-rose-600'} width={20} height={20} /> },
      10: { color: 'text-rose-700', icon: <FaceFrown color={'stroke-rose-700'} width={20} height={20} /> },
    };

    const getPainLevel = () => {
      const painLevelDataItem = painLevelData[dataItems?.painLevel];
      if (painLevelDataItem) {
        return (
          <div className={`!flex gap-2 ${painLevelDataItem.color}`}>
            <span className='text-sm font-semibold'>{dataItems?.painLevel}</span>
            {painLevelDataItem.icon}
          </div>
        );
      }
      return null;
    }

    const statusComponent = getStatusComponent()
    const painLevelComponent = getPainLevel()
    return (
      <div className='status-container' onClick={(e) => e.stopPropagation()}>
        <div className='custom-flex'>
          <span className='custom-padding'>{statusComponent}</span>
          <span className='pain-level-icon-css'>{painLevelComponent}</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='program-container'>
      <p className=''><b>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.programName')} :</b> {programData?.program?.name}</p>
      <p>{handleExtraHeader(programData)}</p>
      </div>
      <Row>
      {programData?.programSessionResult?.map((item) => (
        <>
          <Col key={item.id}>
            <div className="video-item">
              <div className='video-id' key={item.id}>
                <video
                  controls
                  className="video rounded-md"
                  preload="metadata"
                  src={item?.video}
                  width="100%"
                  height="100%"
                />
                <span
                  className="custom-modal"
                  onClick={() => CustomModalInfo({
                    video: item?.programExercise?.exerciseLibrary?.videoUrl || item?.programExercise?.strapiExercise?.attributes?.assets[0]?.video?.data?.attributes?.url,
                    name: item?.programExercise?.exerciseLibrary?.title || item?.programExercise?.strapiExercise?.attributes?.name,
                    description: item?.programExercise?.exerciseLibrary?.description || item?.programExercise?.strapiExercise?.attributes?.description,
                  })}
                >
                  <InfoCircle width={17} height={17} color="stroke-gray-700" />
                </span>
                <div className="custom-name-container">
                  <span>{item?.programExercise?.exerciseLibrary?.title || item?.programExercise?.strapiExercise?.attributes?.name}</span>
                </div>
              </div>
            </div>
          </Col>
        </>
      ))}
      </Row>
    </>
  );
};
