import { useEffect, useState } from "react"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { Button, Input, Pagination, Spin, Tag } from "antd"
import { useTranslation } from "react-i18next"
import { AdminDashboardPatient, IUserPaginationDefaultResponse } from "@stores/interfaces"
import './style.css';
import { SearchLg } from "@carespace-icons/general/searchLg"
import { UserPlus01 } from "@carespace-icons/users/userPlus01"
import TableList from "./TableList"
import { getUnAssignedPatientList } from "@stores/adminPatient"
import { USER_ROLES } from "@stores/constants"

const UnAssignedPatients = () => {
  const { t } = useTranslation();
  const user = useTypedSelector(state => state.user);
  const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  const unAssignedPatients = useTypedSelector((state) => state.adminDashboardPatient.unAssignedPatients)
  const [patientData, setPatientData] = useState<AdminDashboardPatient[]>([]);
  const [pagination, setPagination] = useState<IUserPaginationDefaultResponse>(unAssignedPatients?.pagination || {})
  const dispatch = useTypedDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1)

  const TEAM_MEMBER_HEADER = [
    { name: t('Admin.data.menu.userRoles.name'), sort: true, key: "name" },
  ]

  const TEAM_MEMBER_SUPERADMIN_HEADER = [
    { name: t('Admin.data.menu.userRoles.name'), sort: true, key: "name" },
    { name: t('Admin.data.menu.userRoles.role'), sort: true, key: "role" },
  ]

  const handleUserClick = (rowData:AdminDashboardPatient) => {
    setActiveRowId(rowData.id)
  }

  const actions = [
    { icon: <UserPlus01 color="stroke-gray-600" width={15} height={16} />, action: (rowData:AdminDashboardPatient) => handleUserClick(rowData) , label: t('Admin.data.menu.userRoles.addPhysiotherapist') },
  ];

  const onPageChange = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  }

  const fetchData = async (pageNumber: number, role?: string) => {
    const payload = {
      ...pagination,
      nextPage: pageNumber,
      name: searchValue,
      role: role || '',
    }
    dispatch(getUnAssignedPatientList(payload))
  }

  useEffect(() => {
    if (unAssignedPatients?.data) {
      setPatientData(unAssignedPatients?.data);
      setPagination(unAssignedPatients?.pagination || {});
    }
  }, [unAssignedPatients])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchData(1)
      setCurrentPage(1)
    }
  };

  return (
    <div className="total-patient-main-div">
      <>
        <div className="border-b-2 border-inherit pb-3 mb-2" style={{color:"var(--text-color-root)"}}>
          <h3>{isSuperAdmin ? t('Admin.data.menu.userRoles.unAssignedPatients.userLabel') : t('Admin.data.menu.userRoles.unAssignedPatients.label')} <span className='total-patient-length'><Tag color="purple">{pagination.totalCount} {t('Admin.data.newPatients.users')}</Tag></span></h3>
          <span>{t('Admin.data.menu.userRoles.unAssignedPatients.description')}</span>
        </div>
        <div className="flex justify-between items-center my-3">
          <Input
            className='w-[400px] my-3 h-10'
            placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
            value={searchValue}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setSearchValue(e.target.value)}
            prefix={<SearchLg height={15} width={15} color='stroke-black' />}
          />
        </div>
        <div style={{backgroundColor:'white', borderRadius:'4px'}}>
        <TableList columns={isSuperAdmin ? TEAM_MEMBER_SUPERADMIN_HEADER : TEAM_MEMBER_HEADER} tableData={patientData} actions={actions} activeRowId={activeRowId} setActiveRowId={setActiveRowId} isRegistered={false} currentPage={currentPage} />
        </div>
        {
          pagination?.totalCount! > 10 && <Pagination current={currentPage}
            total={pagination?.totalCount} onChange={onPageChange}
            style={{ textAlign: 'center' }} showSizeChanger={false} />
        }
      </>
    </div>
  )
}

export default UnAssignedPatients