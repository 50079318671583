import CheckBox from '@atoms/CheckBox';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { Avatar, Empty, Popover, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowUp } from '@carespace-icons/arrows/arrowUp';
import { ArrowDown } from '@carespace-icons/arrows/arrowDown';
import AddPatientToAdmin from '@pages/AddPatientToAdmin';
import { useNavigate } from 'react-router-dom';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { getUserById, saveState } from '@stores/contacts/contacts';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Physiotherapist, UserFeatureProps } from '@stores/interfaces';
import { CloseCircleFilled } from "@ant-design/icons";
import { router } from '@routers/routers';

import { USER_ROLES } from '@stores/constants';
interface ColumnType {
	key: string;
	name: string;
	sortable?: boolean;
}
interface ActionType {
	icon: React.ReactNode;
	action?: (data: any) => void;
	label: string;
	key?: string
}
interface TableDataType {
	id: string;
	[key: string]: any;
}
interface TableListProps {
	columns: ColumnType[];
	tableData: TableDataType[];
	selectable?: boolean;
	actions?: ActionType[];
	onSort?: (key: string, order: 'asc' | 'desc') => void;
	onSelect?: (selectedItems: TableDataType[]) => void;
	activeRowId: string | null,
	setActiveRowId: (val: string | null) => void;
	handleTogglePhysiotherapist: (patientId: string, physiotherapist: Physiotherapist) => void
	currentPage: number;
	isRegistered: boolean
	filterButton: string;
	searchValue: string;
	isBulkInvite?: boolean;
}

const navigationConfig = {
	activity: { path: router.ACTIVITY },
	myReport: { path: router.AIASSISTANT_MY_REPORT },
	listEvaluation: { path: router.AIASSISTANT_LIST_EVALUATION },
	romSummary: { path: router.AIASSISTANT_ROM_SUMMARY },
	postureSummary: { path: router.AIASSISTANT_POSTURE_SUMMARY },
	listSessions: { path: router.AIASSISTANT_LIST_SESSIONS },
	surveySummary: { path: router.AIASSISTANT_SURVEY_SUMMARY },
};

const CONSTANTS = {
	ACTIVITY: 'activity'
}

const TableList = ({
	columns,
	tableData,
	selectable,
	actions,
	onSort,
	onSelect, activeRowId, setActiveRowId, currentPage, isRegistered, filterButton, searchValue,
	handleTogglePhysiotherapist,
	isBulkInvite = false
}: TableListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const [tableListData, setTableListData] = useState<TableDataType[]>([]);
	const [sortKey, setSortKey] = useState<string | null>(null);
	const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
	const [selectedAll, setSelectedAll] = useState(false);
	const [hoverIndex, setHoverIndex] = useState(-1);
	const { loading } = useTypedSelector(state => state.adminDashboardPatient)
	const user = useTypedSelector((state) => state.user)

	const getRoleStyles = (role: string) => {
		switch (role) {
			case USER_ROLES.USER:
				return 'bg-[#EFF8FF] text-[#175CD3]';
			case USER_ROLES.ADMIN:
				return 'bg-[#F4F3FF] text-[#5925DC]';
			case USER_ROLES.SUPER_ADMIN:
				return 'bg-[#FFFAEB] text-[#B54708]';
			default:
				return null;
		}
	};

	const formatRole = (role: string) => {
		switch (role) {
			case USER_ROLES.SUPER_ADMIN:
				return "Super Admin";
			case USER_ROLES.ADMIN:
				return "Admin";
			case USER_ROLES.USER:
				return "User";
			default:
				return role?.charAt(0)?.toUpperCase() + role?.slice(1).toLowerCase();
		}
	};

	useEffect(() => {
		setTableListData(tableData || []);
	}, [tableData]);

	const handleUserChange = async (userId: string) => {
		if (userId) {
			navigate(`/${userId}/${CONSTANTS.ACTIVITY}`);
			await dispatch(getUserById(userId));
			dispatch(setActiveTab(CONSTANTS.ACTIVITY));
		}
	};

	const handleSort = (key: string) => {
		const newSortOrder = sortBy === 'asc' ? 'desc' : 'asc';
		setSortKey(key);
		setSortBy(newSortOrder);
		if (onSort) {
			onSort(key, newSortOrder);
		}
	};

	const toggleSelectAll = (checked: boolean) => {
		const updatedData = tableListData.map((item) => ({
			...item,
			isSelected: checked,
		}));
		setTableListData(updatedData);
		setSelectedAll(checked);
		if (onSelect) {
			onSelect(updatedData.filter((item) => item.isSelected));
		}
	};

	const toggleSelectRow = (id: string, checked: boolean) => {
		const updatedData = tableListData.map((item) =>
			item.id === id ? { ...item, isSelected: checked } : item
		);
		setTableListData(updatedData);
		if (onSelect) {
			onSelect(updatedData.filter((item) => item.isSelected));
		}
	};

	const [isNavigate, setIsNavigate] = useState<boolean>(false)
	const [selectePath, setSelectedPath] = useState({
		userId: '',
		selectedPath: '',
		selectedFeature: ''
	})

	const handleToolsChange = (userId: string, tabValue: string) => {
		const featureMap = {
			'Activity Stream': CONSTANTS.ACTIVITY,
			'Posture Analytics': 'postureSummary',
			'Evaluation': 'listEvaluation',
			'OmniROM': 'romSummary',
			'Programs': 'listSessions',
			'Survey': 'surveySummary',
			'Reports': 'myReport',
		};
		const selectedFeature = featureMap[tabValue];
		if (selectedFeature) {
			const selectedPath = navigationConfig[selectedFeature].path;

			dispatch(
				saveState({
					activeTab: tabValue,
					userId: userId,
				})
			);
			setSelectedPath({
				userId: userId,
				selectedPath: selectedPath,
				selectedFeature: selectedFeature
			})
			setIsNavigate(true);
		}
	};

	useEffect(() => {
		if (isNavigate && selectePath?.selectedFeature) {
			navigate(`/${selectePath?.userId}${selectePath?.selectedPath}`);
			dispatch(setActiveTab(selectePath?.selectedFeature));
		}
	}, [isNavigate, selectePath])

	const filteredActions = tableListData?.map((item) => {
		if (item?.profile?.role === USER_ROLES.SUPER_ADMIN || filterButton === USER_ROLES.SUPER_ADMIN) {
			return actions?.filter(
				(action) => action?.key !== 'userPlus'
			);
		}
		return actions;
	});

	return loading
	? <Spin className="spin-css" tip="Loading" size="large" />
	: (
		<div className={`w-full rounded-md border-gray-200 ${isBulkInvite ? 'border-t-2 border-b-2' : 'border-2 shadow-lg'}`}>
			<table className="w-full">
				<thead>
					<tr className="bg-gray-50 border-b-gray-200 border-b-2 h-11 font-semi-bold text-gray-600 text-xs">
						{selectable && (
							<th className="text-left pl-2 pr-2">
								<CheckBox
									id="selectAll"
									checked={selectedAll}
									onClick={() => toggleSelectAll(!selectedAll)}
								/>
							</th>
						)}
						{columns?.map((col, index) => {
							const isBlank =
								col.key === "physiotherapist" &&
								(filterButton === USER_ROLES.SUPER_ADMIN || filterButton === USER_ROLES.ADMIN);
							return (
								<th
									key={index}
									className={twJoin(
										'text-left pl-2 pr-2',
										col.sortable && 'cursor-pointer'
									)}
									onClick={() => !isBlank && col.sortable && handleSort(col.key)}
								>
									{isBlank ? '' : (
										<>
											{col.name}
											{col.sortable && sortKey === col.key && (
												<span className="ml-4 inline-block">
													{sortBy === 'asc' ? (
														<ArrowUp color="stroke-gray-600" width={10} height={10} />
													) : (
														<ArrowDown color="stroke-gray-600" width={10} height={10} />
													)}
												</span>
											)}
										</>
									)}
								</th>
							);
						})}
						{actions && !isBulkInvite && actions.length > 0 ? <th className="text-center pl-2 pr-2">{t('Admin.data.menu.userRoles.invitePatientModal.action')}</th> : <th />}
					</tr>
				</thead>
				<tbody>
					{tableListData.length > 0 ? (
						tableListData.map((rowData, rowIndex) => (
							<tr
								key={rowData.id}
								className="border-b-gray-200 border-b-2 text-sm text-gray-600 cursor-pointer"
								onMouseOver={() => setHoverIndex(rowIndex)}
								onMouseLeave={() => setHoverIndex(-1)}
							>
								{selectable && (
									<td className="pl-2 pr-2 align-middle p-2 text-sm">
										<CheckBox
											id={rowData.id}
											checked={rowData.isSelected}
											onClick={(e) =>
												toggleSelectRow(rowData?.id, e.currentTarget.checked)
											}
										/>
									</td>
								)}
								{columns.map((col) => {
									const isBlank =
										col.key === "physiotherapist" &&
										(filterButton === USER_ROLES.SUPER_ADMIN || filterButton === USER_ROLES.ADMIN);
									return (
										<td key={col.key} className="pl-4 pr-4">
											{isBulkInvite && col.key == 'index' ? (
												<div>
													{rowIndex + 1}
												</div>
											) : col.key === 'name' ? (
												<div className="flex py-2 items-center" onClick={() => {
													handleUserChange(rowData?.id)
												}}>
													{rowData?.profile?.imageUrl ? (
														<Avatar
															src={rowData?.profile?.imageUrl}
															alt={`${rowData?.profile?.firstName} ${rowData?.profile?.lastName}`}
														/>
													) : (
														<Avatar
															style={{ backgroundColor: rowData?.profile?.avatarColor || '#1890FF' }}
															alt={`${rowData?.profile?.firstName} ${rowData?.profile?.lastName}`}
														>
															{rowData?.profile?.firstName ? rowData?.profile?.firstName.charAt(0).toUpperCase() : 'U'}
														</Avatar>
													)}
													<span className="ml-2">
														<span className="block text-gray-900">
															<strong>
																{rowData?.profile?.firstName ? <>
																	{rowData?.profile?.firstName}{' '} {rowData?.profile?.lastName} </> : ''}
															</strong>
														</span>
														<span className="block text-gray-600">
															{rowData?.profile?.email}
														</span>
													</span>
												</div>
											) : col.key === 'phone' ? (
												<span>{rowData?.profile?.phone}</span>
											): col.key === 'role' ? (
												<span className={`text-xs font-medium rounded-full py-1 px-2 mr-2 -ml-3 ${getRoleStyles(rowData?.profile?.role)}`}>
													{formatRole(rowData?.profile?.role)}
												</span>
											) : col.key === 'physiotherapist' ? (
												<>
													{
														isBlank ? '' : <>
															{rowData?.physiotherapistPatientAssociationPatientIdRelation?.length > 0 &&
																rowData?.physiotherapistPatientAssociationPatientIdRelation.map((relation, index) => {
																	const physiotherapist = relation?.physiotherapist;
																	if (!physiotherapist) return null;

																	const { profile } = physiotherapist;
																	const fullName = `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim();

																	return (
																		<Tooltip key={index} placement="top" title={fullName} color="purple">
																			{profile?.imageUrl ? (
																				<Avatar
																					src={profile.imageUrl}
																					alt={fullName}
																					style={{ marginRight: '-6px', border: '2px solid' }}
																				/>
																			) : (
																				isBulkInvite
																				? <div className="relative inline-block group">
																					<Avatar
																						style={{
																							backgroundColor: profile?.avatarColor || '#1890FF',
																							marginRight: '-6px', border: '2px solid',
																						}}
																						alt={fullName}
																					>
																						{profile?.firstName ? profile.firstName.charAt(0).toUpperCase() : ''}
																					</Avatar>
																					{user?.profile?.role === USER_ROLES.SUPER_ADMIN &&
																					<CloseCircleFilled
																						className="absolute -top-2 -right-2 text-red-500 text-lg cursor-pointer bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
																						onClick={() => handleTogglePhysiotherapist(relation?.patientId, physiotherapist)}
																					/>}
																				</div>
																				: <Avatar
																						style={{
																							backgroundColor: profile?.avatarColor || '#1890FF',
																							marginRight: '-6px', border: '2px solid',
																						}}
																						alt={fullName}
																					>
																						{profile?.firstName ? profile.firstName.charAt(0).toUpperCase() : ''}
																					</Avatar>
																			)}
																		</Tooltip>
																	);
																})}
														</>
													}
												</>
											) : col.key === 'tools' ? (
												<>
													{rowData?.userFeature?.map((item: UserFeatureProps, index: number) => (
														<span key={index}
															className="text-gray-800 !font-medium text-xs !rounded-full !bg-gray-300 !py-1 !px-2 !mr-2  inline-block max-w-[120px] whitespace-nowrap mb-1"
															onClick={() => {
																if (rowData?.id) {
																	handleToolsChange(rowData?.id, item?.feature?.name);
																}
															}}
														>
															{item?.feature?.name}
														</span>

													))}
												</>
											) : (
												rowData[col.key]
											)}
										</td>
									)
								})}
								{actions && (
									<td className="p-2 text-center w-[120px] min-w-[120px] max-w-[120px] whitespace-nowrap">
										{activeRowId === rowData.id && (
											<Popover
												placement="leftTop"
												open={activeRowId === rowData.id}
												onOpenChange={() => {
													setActiveRowId(activeRowId === rowData.id ? null : rowData.id);
												}}
												content={<AddPatientToAdmin selectedPhysiotherapists={rowData?.physiotherapistPatientAssociationPatientIdRelation} handleTogglePhysiotherapist={(physio: Physiotherapist) => handleTogglePhysiotherapist((rowIndex + 1).toString(), physio)} activeRowId={activeRowId} setActiveRowId={setActiveRowId} currentPage={currentPage} isRegistered={isRegistered} filterButton={filterButton} searchName={searchValue} role={rowData.profile.role} isBulkInvite={isBulkInvite} />}
												trigger="click"
												overlayInnerStyle={{ width: '450px' }}
											/>
										)}
										<div className={`flex justify-center gap-2 ${hoverIndex == rowIndex ? 'opacity-100' : 'opacity-0'}`}>
											{filteredActions[rowIndex]?.map((action, actionIndex) => {
												const isUserPlusAction = action?.key === "userPlus";
												const tooltipLabel = isUserPlusAction
													? rowData?.profile?.role === USER_ROLES.ADMIN
														? t('Admin.data.menu.userRoles.addPatient') : t('Admin.data.menu.userRoles.addPhysiotherapist')
													: action.label;
												return (
													<td key={actionIndex}>
														<Tooltip title={tooltipLabel} color="purple">
															<span
																className="inline-block cursor-pointer"
																onClick={() => action?.action(rowData)}>
																{action.icon}
															</span>
														</Tooltip>
													</td>
												);
											})}
										</div>
									</td>
								)}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={columns.length + (actions ? 1 : 0)} className="text-center p-3">
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={t('No Data')}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>

	);
};

export default TableList;

