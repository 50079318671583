import { useEffect, useRef, useState } from 'react';
import { AI_MENU_DATA } from './AI_MENU_DATA';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { AiAssistantKeys, SearchBarProps, SearchItem } from '@stores/interfaces';
import { MdClose } from 'react-icons/md';
import './style.less'
import { setActiveTab, setPerPage, setProgramModal, setProgramStateId, setStateId, setSurveyModal, setSurveyStateId } from '@stores/aiAssistant/aiAssistant';
import { setShowAllResults } from '@stores/rom/results';
import AiAssistantNotFoundComponent from '@pages/AiAssistantNotFoundComponent';
import { USER_ROLES } from '@stores/constants';
import { useNavigate } from 'react-router-dom';
import { router } from '@routers/routers';
import { Menu01 } from '@carespace-icons/general/menu01';
import AiTab from '@pages/AiTab';
import { useTheme } from '@pages/Themes/ThemeContext';

export default function AiAssistantSearchBar(props: SearchBarProps) {
  const { newRecentSearch, setNewRecentSearch, inputText, setInputText, dataset, setRenderedComponent } = props
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [filteredData, setFilteredData] = useState([{}]);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)
  const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
  const navigate = useNavigate();
  const activeTab = useTypedSelector(
    state => state.aiAssistant.aiAssistant.activeTab,
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".menu-toggle") 
      ) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdown = (value: SearchItem) => {
    dispatch(setActiveTab(value.key));
    dispatch(setStateId(""))
    dispatch(setProgramStateId(""))
    dispatch(setSurveyStateId(""))
    value.key === "home" && navigate(router.ROOT, {
      state: { activeMenu: 'home'}, 
    });
    value.key === "assignSurvey" && dispatch(setSurveyModal(true));
    value.key === "generateProgram" && dispatch(setProgramModal(true));
    setOpenDropdown(false);
    setNewRecentSearch("")
    resetShowAllState(value.key)
  }

  const handleRecentDropdown = (value: string) => {
    setNewRecentSearch(value);
    if (value.toLowerCase() === "home") {
      navigate(router.ROOT, {
        state: { activeMenu: 'home'}, 
      }); 
    } else {
      handleSubmit(value); 
    }
    setOpenDropdown(false);
    setInputText('')
    resetShowAllState(value)
  };

  const resetShowAllState = (val: string) => {
    if (val != AiAssistantKeys.romSummary) {
      dispatch(setShowAllResults(true));
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(inputText);
      setOpenDropdown(false)
    }
  };

  const handleSubmit = (value: string) => {
    let searchQuery = value?.trim();
    if (searchQuery) {
      const isInDropdown = AI_MENU_DATA?.data?.some(item =>
        item.list.some(subItem => subItem?.name?.toLowerCase() === searchQuery?.toLowerCase())
      );

      if (!isInDropdown) {
        if (!recentSearches.includes(searchQuery)) {
          setRecentSearches(prevSearches => [...prevSearches, searchQuery]);
        }
      }
      setNewRecentSearch(searchQuery);
      const isRestrictedPattern = dataset.some(item => {
        if (!user.isPhysioterapist && !isSuperAdmin &&
          (item.key === AiAssistantKeys.newPatients ||
            item.key === AiAssistantKeys.pendingReview ||
            item.key === AiAssistantKeys.reviewed ||
            item.key === AiAssistantKeys.escalationRequired ||
            item.key === AiAssistantKeys.outOfParams ||
            item.key === AiAssistantKeys.followUpRequired ||
            item.key === AiAssistantKeys.generateProgram) &&
          item.patterns.some(pattern => searchQuery.toLowerCase().includes(pattern.toLowerCase()))) {
          return true;
        }
        return false;
      });
      if (isRestrictedPattern) {
        dispatch(setActiveTab(AiAssistantKeys.noComponentFound));
        return;
      }
      setInputText('')
    }

    for (const data of dataset) {
      for (const pattern of data.patterns) {
        if (searchQuery.toLowerCase().includes(pattern.toLowerCase())) {
          dispatch(setActiveTab(data.key));
          return;
        }
      }
    }
    dispatch(setActiveTab(AiAssistantKeys.notUnderstandQuestion))
    setRenderedComponent(<AiAssistantNotFoundComponent />)
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputText(value);
    dispatch(setActiveTab(value))
    dispatch(setStateId(""))
    dispatch(setProgramStateId(""))
    dispatch(setSurveyStateId(""))
    const numbers = value.match(/\d+/g);
    if (numbers) dispatch(setPerPage(parseInt(numbers[0], 10)))
    else if (value.toLowerCase().includes("latest") || value.toLowerCase().includes("recent") || value.toLowerCase().includes("top")) setPerPage(1)
    else dispatch(setPerPage(0))
    const filtered = AI_MENU_DATA.data.filter(item => {
      return (
        item.title.toLowerCase().includes(value.replace(/\s/g, '').toLowerCase()) ||
        item.list.some(subItem => subItem.name.replace(/\s/g, '').toLowerCase().includes(value.replace(/\s/g, '').toLowerCase()))
      );
    });

    const matchedQuestion = dataset.find(item =>
      item.patterns.some(pattern => value.toLowerCase().includes(pattern.toLowerCase()))
    );

    let combinedFilteredData = [];

    if (matchedQuestion) {
      combinedFilteredData.push({
        title: t('Admin.data.menu.aiAssistant.aiAssistantSearchBar.selectHere'),
        list: matchedQuestion.extraResponse!.map((question) => ({
          name: question
        }))
      });
    }
    combinedFilteredData = combinedFilteredData.concat(filtered)
    setFilteredData(combinedFilteredData)
  };
  const removeRecentSearch = (searchQuery: string) => {
    setRecentSearches(prevSearches => prevSearches.filter(item => item !== searchQuery))
    setNewRecentSearch("")
  };

  const customDropdown = () => (
    <div className='custom-dropdown-search-content'>
      <input placeholder="Search" onChange={handleSearch} onKeyDown={handleKeyDown} className='input-ai-searcbar' />
      {recentSearches.length > 0 &&
        <>
          <div className="recent-search-div">
            <strong className='search-label'>{t('Admin.data.menu.aiAssistant.aiAssistantSearchBar.recentSearch')}</strong>
          </div>
          {recentSearches.map((item) => (
            <span className="recent-search-close-icon" onClick={() => {
              handleSearch
              handleRecentDropdown(item)
            }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong className='recent-item-text'>{item}</strong>
                <MdClose color="white" onClick={(e) => { e.stopPropagation(); removeRecentSearch(item) }} /></div>
            </span>
          ))}
        </>}
      {inputText ? filteredData.map((item, index: number) => (
        <div key={index} className='filtered-data-div'>
          <div className="filtered-sub-container">
            <strong className='filtered-item-title'>{item?.title}</strong>
            {item?.list?.filter((value: SearchItem) => !(value.key === 'generateProgram' && !user.isPhysioterapist))
              .map((value: SearchItem, subIndex: number) => (
                <span className="filtered-item-icon-css fontStyles" key={subIndex} onClick={() => handleRecentDropdown(value.name)}>
                  <span className='list-icon-container'>{value?.icon}</span> <strong className='list-name-label'>{value?.name}</strong>
                </span>
              ))}
          </div>
        </div>
      )) : AI_MENU_DATA.data.map((item, index) => (
        <div key={index} className='filtered-data-div'>
          <div className="filtered-sub-container">
            <strong className='filtered-item-title'>{item?.title}</strong>
            {item.list
              .filter((value) => {
                if ((value.key === 'generateProgram' || value.key === 'createSurvey' || value.key === 'assignSurvey') && !user.isPhysioterapist && !isSuperAdmin) {
                  return false;
                }
                return true;
              })
              .map((value, subIndex) => (
                <span className="filtered-item-icon-css fontStyles" key={subIndex} onClick={() => handleDropdown(value)}>
                  <span className='list-icon-container'>{value?.icon}</span> <strong className='list-name-label'>{value?.name}</strong>
                </span>
              ))}
          </div>
        </div>
      ))}
    </div>
  );

  const toggleDropdown = () => {
    setOpenDropdown((prev) => {
      return !prev;
    });
  };

  const closeDropdown = (e) => {
    e.stopPropagation(); 
    setOpenDropdown(false);
  };

  const currentLocation = window.location.pathname
  const {theme} = useTheme()

  return (
    <div className="fixed w-full z-[101] select-none mr-[0.90rem]" style={{backgroundColor: '#fff' }}>
      {!location.pathname.includes('download-app') && <>
      {activeTab !== '' &&
        !location.pathname.includes('/program/start') && <AiTab />
      }
      </>}
    </div>
  );
}
