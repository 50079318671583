export const measurementTypes = {
  "IMPERIAL": "imperial",
  "METRIC": "metric",
  "CENTIMETER": "Cm",
  "FEET": "Ft",
  "INCH": "In",
  "KILOGRAM": "Kg",
  "LB": "Lb"
}

export const status = {
  "APPROVED": "approved"
}