import React from 'react';
import { message as AntMessage } from 'antd';
import { XClose } from '@carespace-icons/general/xClose';
import './AlreadyRegisteredUser.css';
import { useTranslation } from 'react-i18next';

interface User {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
}

interface ResponseData {
  payload: {
    usersAlreadyCreated: User[];
  };
}

interface AlreadyRegisteredUserProps {
  message: typeof AntMessage;
  responseData: ResponseData;
}

const AlreadyRegisteredUser = ({ message, responseData }: AlreadyRegisteredUserProps) => {

  const {t} = useTranslation()
  return (
    <div className="bulk-invite-error-container select-none">
      <p
        role="img"
        aria-label="close-circle"
        className="close-icon"
        onClick={() => message.destroy()}
      >
        <XClose color='stroke-gray-600' />
      </p>

      <p style={{marginBottom:'10px'}}>
        <strong>{t('Admin.data.menu.userRoles.invitePatientModal.alreadyAccount')}</strong>
      </p>

      <table className="error-table">
        <thead>
          <tr>
            <th>{t('Admin.data.menu.userRoles.invitePatientModal.firstName')}</th>
            <th>{t('Admin.data.menu.userRoles.invitePatientModal.lastName')}</th>
            <th>{t('Admin.data.menu.userRoles.invitePatientModal.emailText')}</th>
            <th>{t('Admin.data.menu.userRoles.invitePatientModal.mobilePhone')}</th>
          </tr>
        </thead>
        <tbody>
          {responseData.payload.usersAlreadyCreated?.map((user, index) => (
            <tr key={index}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.mobilePhone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AlreadyRegisteredUser;
