export const ResultScreen = ({ screenshot }: { screenshot: string }) => {
  if (!screenshot) return null;

  return (
    <div
      style={{
        aspectRatio: '16/9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 5,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <img
        style={{
          maxHeight: '85%',
          width: 'auto',
          aspectRatio: '16/9',
          display: 'block',
        }}
        src={screenshot ?? ''}
        alt="screenshot"
      />
    </div>
  );
}