import { Col, Row, Image, Modal, Empty } from 'antd';
import moment from 'moment';
import { CheckCircleFilled } from '@ant-design/icons';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ReactCompareImage from 'react-compare-image';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useTypedSelector } from '@stores/index';
import { IPosture, PostureAnalyticsItem } from '@stores/interfaces';

export default function PostureCaptureScreenshots({ angle }: { angle: string }) {
  const { t } = useTranslation();
  const [compareImage, setCompareImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState<PostureAnalyticsItem[]>([]);
  const postureData = useTypedSelector((state) => state.postures.postures.data);
  const showCompare = () => setCompareImage(!compareImage);

  const onSelectImage = (image:PostureAnalyticsItem) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(image)) {
        return prevSelectedImages.filter((img) => img !== image);
      } else if (prevSelectedImages.length < 2) {
        return [...prevSelectedImages, image];
      }
      return prevSelectedImages;
    });
  };

  const filteredImages = postureData?.flatMap((session:IPosture) =>
    session?.postureAnalytics?.filter((item) => item?.view === angle)
  );

  return (
    <>
      {selectedImages?.length > 0 && (
        <div
          className={`gallery-top ${selectedImages?.length === 2
            ? 'active'
            : ''
            }`}>
          <button
            disabled={
              selectedImages?.length < 2
            }
            className={`btn-compare ${selectedImages?.length === 2
              ? 'active'
              : ''
              }`}
            onClick={showCompare}
          >
            {t('Patient.data.myProgress.omniRom.compare')}
          </button>
        </div>
      )}
      <Row gutter={[14, 8]} style={{padding: '12px'}}>
        {filteredImages && filteredImages?.length > 0 ? (
          filteredImages?.map((dataItem, index) => {
            const DateUtc = moment(dataItem?.createdAt).local().format('LLL');
            return (
              <Col
                key={`col-${index}`}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                lg={{ span: 5 }}
                xxl={{ span: 5 }}
              >
                <div className="screenshot tipChart">
                  <Image
                    className={selectedImages?.includes(dataItem) ? 'active' : ''}
                    style={{ objectFit: 'cover' }}
                    width="100%"
                    height={125}
                    src={dataItem.screenshot}
                    preview={{
                      src: dataItem.screenshot,
                      mask: <MdOutlineRemoveRedEye size={18} />,
                      width: 'auto',
                      height: 'auto',
                    }}
                  />
                  <CheckCircleFilled
                    className={selectedImages?.includes(dataItem) ? 'select-icon active' : 'select-icon'}
                    onClick={() => onSelectImage(dataItem)}
                  />
                  <div className="date">{DateUtc}</div>
                  <div className="value">{dataItem?.ear}°</div>
                </div>
              </Col>
            );
          })
        ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span className="text-gray-300">{t('Patient.data.postures.noCaptureFound')}</span>
                }
              />
            </div>
        )}
      </Row>
      {compareImage && selectedImages?.length === 2 &&  (
        <Modal
          title={t('Patient.data.myProgress.omniRom.compareImages')}
          open={compareImage}
          onOk={showCompare}
          onCancel={showCompare}
          className="gallery-modal"
          centered
          width="60%"
        >
          <div className="screen-gallery">
            <ReactCompareImage
              className="gallery-compare"
              leftImage={selectedImages[0]?.screenshot}
              rightImage={selectedImages[1]?.screenshot}
              leftImageLabel={`${moment(selectedImages[0]?.createdAt).local().format('LLL')} ${t('Patient.data.myProgress.omniRom.result')}${selectedImages[0].ear}°`}
              rightImageLabel={`${moment(selectedImages[1]?.createdAt).local().format('LLL')} ${t('Patient.data.myProgress.omniRom.result')}${selectedImages[1].ear}°`}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
