import { Tooltip } from 'antd';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { UseFullScreen } from '../context/FullScreen.context';
import { Divider } from 'antd';

function FullScreen() {
	const { isFullScreen, onFullScreen } = UseFullScreen();

	return (
		<>
			<Divider
				type="vertical"
				style={{ height: '34px', borderColor: '#1B1B1B' }}
			/>
			<Tooltip title={'Fullscreen'}>
				{isFullScreen ? (
					<MdFullscreenExit
						size={30}
						style={{
							verticalAlign: 'middle',
							cursor: 'pointer',
						}}
						onClick={onFullScreen}
						color="#5e5e5e"
					/>
				) : (
					<MdFullscreen
						size={30}
						style={{
							verticalAlign: 'middle',
							cursor: 'pointer',
						}}
						onClick={onFullScreen}
						color="#5e5e5e"
					/>
				)}
			</Tooltip>
		</>
	);
}

export default FullScreen;
