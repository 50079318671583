import { EvaluationDataItem } from "@atoms/AEvaluationDataItem";
import { getEvaluationActivityStreamById } from "@stores/activityStream/activityStream";
import { useTypedDispatch } from "@stores/index";
import { TDataProps } from "@stores/interfaces";
import { Spin } from "antd";
import { useEffect, useState } from "react";

export const AEvaluation = ({ evaluationId }: { evaluationId: string }) => {
  const [evaluationData, setEvaluationData] = useState<TDataProps | undefined >()
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (evaluationId: string) => {
      const action = await dispatch(getEvaluationActivityStreamById({ evaluationId }));
      setEvaluationData(action.payload);
      setLoading(false);
    };

    fetchData(evaluationId);
  }, [evaluationId, dispatch]);

  return <>{loading ? <Spin className="flex items-center justify-center p-2" tip="Loading" size="large" /> :<EvaluationDataItem evaluationData={evaluationData!}/>}</>
}
