import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ICustomRom, ICustomRomExercise } from '@stores/interfaces';
import { Empty, Pagination, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { getCustomRomList } from '@stores/rom/customRom';
import { CustomRomDataItem } from './CustomRomDataItem';
import { useTranslation } from 'react-i18next';

interface RomCustomOptionsProps {
	isLoading: boolean;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
	setLoading: (value: boolean) => void;
}

export default function CustomOptionsList(props: RomCustomOptionsProps) {
	const { isLoading, setLoading, refresh, setRefresh } = props;
	const [programData, setProgramData] = useState<ICustomRom[]>();
	const [page, setPage] = useState<number>(1);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const customRom = useTypedSelector(state => state.rom.customRom.customRom);
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		fetchHomeData(page);
	}, [user, selectedUser, refresh]);

	useEffect(() => setProgramData(customRom.data), [customRom, refresh]);

	const updateProgramExercises = (
		id: string,
		exercises: ICustomRomExercise[],
	) => {
		setProgramData(prevProgramData =>
			prevProgramData?.map(program =>
				program.id === id ? { ...program, exercises } : program,
			),
		);
	};

	const fetchHomeData = async (pageNumber: number) => {
		setPage(pageNumber);
		const payload = {
			userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
			limit: 10,
			page: pageNumber,
			searchValue: '',
		};
		await dispatch(getCustomRomList(payload));
		setLoading(false);
	};

	return (
		<div>
			<p className="scan-option-label">
				{t('Patient.data.omnirom.customOptions')}
			</p>
			{isLoading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{programData?.length == 0 ? (
						<Empty
							className="bg-gray-50 p-10 m-0"
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<span className="text-gray-300">
									{t('Patient.data.omnirom.noProgramsFound')}
								</span>
							}
						/>
					) : (
						<div className="collapse-wrapper">
							{programData?.map(program => (
								<CustomRomDataItem
									updateProgramExercises={exercises =>
										updateProgramExercises(program?.id, exercises)
									}
									program={program}
									setRefresh={setRefresh}
									refresh={refresh}
								/>
							))}
						</div>
					)}
					{customRom?.data?.length != 0 && (
						<div>
							<Pagination
								current={customRom?.pagination?.currentPage}
								showSizeChanger={false}
								onChange={fetchHomeData}
								total={customRom?.pagination?.totalCount}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
}
