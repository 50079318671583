import { Line } from "@ant-design/charts";
import { Tabs } from "antd";
import { useState } from "react";
import moment from "moment";
import CustomCaptureScreenshots from "./CustomCaptureScreenshots";

const createChartConfig = (exercise) => {
  const colorCodes = ['#5B8FF9', '#32D583', '#FEC84B', '#DC6803', '#00BFFF', '#00FF7F', '#EE82EE', '#FF8C00', '#48D1CC', '#BE9A60', '#B9375E', '#3E96F4', '#76A787', '#FF33A6'];

  const exerciseColors = exercise.map((_, index: number) =>
    colorCodes[index % colorCodes.length]
  );

  return {
    data: exercise.flatMap((data, index) => {
      return data.romResults.map((item) => ({
        date: moment(item.createdAt).local().format('LLL'),
        value: item.value,
        motionName: data.strapiOmniRomExercise?.name,
        color: exerciseColors[index],
        image: item?.romPatientResult?.screenshot
      }));
    }),
    padding: 'auto',
    xField: 'date',
    yField: 'value',
    seriesField: 'motionName',
    tooltip: {
      customContent: (title, data) => {
        if (data.length === 0) return null;

        const values = data
          .map(({ value, data: { motionName } }) => {
            return `<div class="value">${motionName}: ${value}°</div>`;
          })
          .join('');

        const imageUrl = data[0]?.data?.image

        return `
          <div class="chart-tool-tip">
            <div class="image">
              ${imageUrl && `<img src="${imageUrl}" alt="Captured Image" style="width: 150px; height: auto;" />`}
            </div>
            <div class="date">${title}</div>
            ${values}
          </div>
        `;
      },
    },
    point: {
      size: 5,
      shape: 'circle',
      style: (datum) => ({
        fill: datum.color,
        lineWidth: 1,
      }),
    },
    meta: {
      value: {
        min: 0,
        max: 200,
        tickInterval: 30,
      },
    },
    slider: {
      start: 0.0,
      end: 1,
    },
    color: exerciseColors,
    legend: {
      itemName: {
        style: {
          fontSize: 12,
          fill: '#000',
        },
      },
      layout: 'horizontal',
      position: 'top',
      offsetX: 0,
      offsetY: 0,
      itemMargin: 10,
      items: exercise.map((item, index) => ({
        name: item.strapiOmniRomExercise?.name,
        color: exerciseColors[index],
        marker: {
          symbol: 'square',
          style: {
            stroke: exerciseColors[index],
            fill: exerciseColors[index],
            lineWidth: 7,
          },
        },
      })),
    },
  };
};

export const CustomCaptureData = ({ exercise }) => {
  const [activeKey, setActiveKey] = useState('1');

  const chartConfig = createChartConfig(exercise);

  return (
    <div className="custom-capture-data">
      <Tabs
        type="card"
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={(value) => setActiveKey(value)}
        className="tab"
        items={[
          {
            label: "Chart",
            key: '1',
            children: (
              <div className="chart">
                <Line {...chartConfig} />
              </div>
            ),
          },
          {
            label: "Screenshots",
            key: '4',
            children: <CustomCaptureScreenshots exercise={exercise} />,
          },
        ]}
      />
    </div>
  );
};

export default CustomCaptureData;
