import { showCustomModal } from '@atoms/ACustomModalInfo';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import {
	IProgramData,
	IProgramExercise,
	IProgramTemplateResponse,
	IProgramTemplate,
	IProgramFilterOptions,
} from '@stores/interfaces';
import { Collapse, Empty, Input, Pagination, Spin, Tabs } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExerciseFilters } from './ExerciseFilters';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getProgramTemplate } from '@stores/aiAssistant/program';
import {
	getMySystemTemplatesData,
	setPopoverState,
} from '@stores/functionalGoals';

const { Panel } = Collapse;

interface ExerciseProps {
	searchValue?: string;
	setSearchValue: (value: string) => void;
	extraContent: (data: IProgramData) => ReactNode;
	program: IProgramTemplateResponse;
	fetchData: (pageNumber: number) => void;
	open: boolean;
	setOpen: (value: boolean) => void;
	activeSubKey: string;
	setActiveSubKey: (value: string) => void;
}

export const TemplateModalData = (props: ExerciseProps) => {
	const {
		searchValue,
		extraContent,
		setSearchValue,
		program,
		fetchData,
		open,
		setOpen,
		activeSubKey,
		setActiveSubKey,
	} = props;
	const [filterExercise, setFilterExercise] = useState('System Templates');
	const { t } = useTranslation();
	const [filterOptions, setFilterOptions] = useState<IProgramFilterOptions>({
		bodyRegionIds: [],
		exerciseCategoriesIds: [],
		jointsIds: [],
		functionalGoalsIds: [],
	});

	const onPageChange = (pageNumber: number) => {
		setSuggestedProgramData([]);
		handleApply(pageNumber);
	};

	const compare = (a: IProgramExercise, b: IProgramExercise) => {
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}
		return 0;
	};
	const [isLoading, setIsLoading] = useState(false);
	const id = useTypedSelector(state => state.user.id);
	const [suggestedProgramData, setSuggestedProgramData] = useState([]);
	const dispatch = useTypedDispatch();
	const popoverState = useTypedSelector(
		state => state.functionalGoals.popoverState,
	);

	useEffect(() => {
		setSearchValue('');
		handleApply(1);
	}, [activeSubKey]);
	const handleApply = async (pageNumber: number) => {
		setIsLoading(true);
		dispatch(setPopoverState(false));
		const payload = {
			id: id,
			limit: 10,
			page: pageNumber ? pageNumber : 1,
			title: searchValue ? searchValue : '',
			searchValue: searchValue ? searchValue : '',
			filterOptions: filterOptions,
		};
		if (activeSubKey === '2') {
			try {
				const data = await dispatch(getProgramTemplate(payload))?.unwrap();
				setSuggestedProgramData(data);
			} catch {
				setSuggestedProgramData([]);
			}
		} else {
			try {
				const data = await dispatch(getMySystemTemplatesData(payload));
				setSuggestedProgramData(data.payload);
			} catch {
				setSuggestedProgramData([]);
			}
		}
		setIsLoading(false);
	};

	const handleKeyDown = (e: { key: string }) => {
		if (e.key === 'Enter') {
			handleApply(1);
		}
	};
	function renderData() {
		return (
			<div className={`${popoverState ? 'min-h-[650px]' : ''}`}>
				<div className="flex justify-between items-center gap-4 pl-4 pr-4">
					{activeSubKey != '2' && (
						<ExerciseFilters
							open={open}
							setOpen={setOpen}
							setFilterOptions={setFilterOptions}
							handleApply={handleApply}
							filterExercise={filterExercise}
							setFilterExercise={setFilterExercise}
							activeSubKey={'2'}
							filterOptions={filterOptions}
						/>
					)}
					<Input
						className="w-full my-3 h-10"
						placeholder={t(
							'Admin.data.menu.aiAssistant.aiAssistantPrograms.search',
						)}
						value={searchValue}
						onKeyDown={handleKeyDown}
						onChange={e => setSearchValue(e.target.value)}
						suffix={<SearchLg height={15} width={15} color="stroke-black" />}
					/>
				</div>
				<div
					style={{ maxHeight: '42vh', overflowX: 'hidden', overflowY: 'auto' }}>
					{isLoading ? (
						<div className="flex justify-center items-center h-40">
							<Spin tip="Loading" size="large" />
						</div>
					) : (
						<>
							{!suggestedProgramData?.data ||
							suggestedProgramData?.data?.length === 0 ? (
								<Empty
									className="bg-gray-50 p-10 m-0"
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										<span className="text-gray-300">
											{t(
												'Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram',
											)}
										</span>
									}
								/>
							) : (
								<Collapse
									className="select-none p-4 rounded-md bg-gray-50 hover:!bg-gray-50 flex flex-col gap-2"
									bordered={false}>
									{suggestedProgramData?.data?.map((data: IProgramTemplate) => {
										const sortedExercises = [...(data?.exercises || [])].sort(
											(a, b) => a.order - b.order,
										);
										return (
											<Panel
												className="header-panel !w-full bg-white !border !border-gray-200 !rounded-xl"
												key={''}
												header={
													<p className="text-sm font-semibold text-gray-600">
														{data?.name}
													</p>
												}
												extra={extraContent(data)}>
												{sortedExercises?.sort(compare)?.map(item => {
													return (
														<div className="px-4 py-2">
															<div
																className="flex cursor-pointer gap-4 p-4 rounded-lg border-2 border-inherit w-full"
																key={item?.name}>
																<div
																	onClick={e => {
																		e.stopPropagation();
																		showCustomModal({
																			video: item?.video
																				? item?.video
																				: item?.exerciseLibrary?.videoUrl ||
																					item?.strapiExerciseId
																						?.exercise_video[0]?.url,
																			name: item?.name
																				? item?.name
																				: item?.exerciseLibrary?.title ||
																					item?.strapiExerciseId?.name,
																			description: item?.description
																				? item?.description
																				: item?.exerciseLibrary?.description ||
																					item?.strapiExerciseId?.description,
																		});
																	}}
																	className={`custom-image-container`}>
																	<div className="w-40 flex-shrink-0 image-wrapper">
																		{item?.strapiExerciseId || item?.image ? (
																			<img
																				src={
																					(item?.strapiExerciseId
																						?.exercise_image &&
																						item?.strapiExerciseId
																							?.exercise_image[0]?.url) ||
																					item?.image
																				}
																				alt=""
																				className="rounded-lg w-full h-full"
																			/>
																		) : (
																			<video
																				src={item?.exerciseLibrary?.videoUrl}
																				className="rounded-lg w-full h-full"
																			/>
																		)}
																		<div className="play-button">
																			<PlayCircle width={50} height={50} />
																		</div>
																	</div>
																</div>
																<div className="flex-1 rounded-lg p-2">
																	<p className="text-gray-600 font-semibold text-lg">
																		{item?.name
																			? item?.name
																			: item?.exerciseLibrary?.title ||
																				item?.strapiExerciseId?.name}
																	</p>
																	{item?.name ? (
																		<p className="text-gray-400 font-semibold text-xs">
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.weekly',
																			)}{' '}
																			{item?.weeklyReps}X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.daily',
																			)}{' '}
																			{item?.dailyReps}X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.sets',
																			)}{' '}
																			{item?.sets}X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reps',
																			)}{' '}
																			{item?.reps}X
																		</p>
																	) : (
																		<p className="text-gray-400 font-semibold text-xs">
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.weekly',
																			)}{' '}
																			{item?.exerciseLibrary?.weeklyReps ||
																				item?.weeklyReps}
																			X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.daily',
																			)}{' '}
																			{item?.exerciseLibrary?.dailyReps ||
																				item?.dailyReps}
																			X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.sets',
																			)}{' '}
																			{item?.exerciseLibrary?.sets ||
																				item?.sets}
																			X{' '}
																			{t(
																				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reps',
																			)}{' '}
																			{item?.exerciseLibrary?.reps ||
																				item?.reps}
																			X
																		</p>
																	)}
																	<p className="text-gray-600 mt-2">
																		{item?.description
																			? item?.description
																			: item?.exerciseLibrary?.description ||
																				item?.strapiExerciseId?.description}
																	</p>
																</div>
															</div>
														</div>
													);
												})}
											</Panel>
										);
									})}
								</Collapse>
							)}
						</>
					)}
				</div>
				<div>
					{!isLoading && suggestedProgramData?.data?.length > 0 && (
						<Pagination
							className="createProgramModalPagination flex justify-center"
							current={
								suggestedProgramData?.pagination?.currentPage ||
								suggestedProgramData?.meta?.pagination?.page
							}
							showSizeChanger={false}
							onChange={onPageChange}
							total={
								suggestedProgramData?.pagination?.totalCount ||
								suggestedProgramData?.meta?.pagination?.total
							}
						/>
					)}
				</div>
			</div>
		);
	}

	return (
		<Tabs
			type="card"
			defaultActiveKey={activeSubKey}
			activeKey={activeSubKey}
			className="mainTabs select-none p-4 bg-gray-100"
			tabBarStyle={{ margin: 0 }}
			onChange={(activeSubKey: string) => {
				setActiveSubKey(activeSubKey);
			}}
			items={[
				{
					label: t(
						'Admin.data.menu.aiAssistant.aiAssistantPrograms.systemTemplates',
					),
					key: '1',
					children: <>{renderData()}</>,
				},
				{
					label: t(
						'Admin.data.menu.aiAssistant.aiAssistantPrograms.myTemplates',
					),
					key: '2',
					children: <>{renderData()}</>,
				},
			]}
		/>
	);
};
