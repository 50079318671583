import { useEffect, useState, useRef } from "react";
import strapi from "@strapi";
import { Modal, Typography } from "antd";
import { ChevronRight } from "@carespace-icons/arrows/chevronRight";
import { ChevronLeft } from "@carespace-icons/arrows/chevronLeft";
import { useTranslation } from "react-i18next";
import { stringify } from "qs";

interface IApiDataItem {
  attributes?: {
    exercise_video?: {
      data: {
        attributes: {
          url: string;
        };
      }[];
    };
    trueToForm?: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    exercise_image?: {
      data: {
        attributes: {
          url: string;
        };
      }[];
    };
    name?: string;
  };
}

export default function TrueToFormPatientOnboard() {
  const [apiData, setApiData] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1)
  const [modalVisible, setModalVisible] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchData(10, currentPage);
  }, [currentPage]);

  const fetchData = async (limit: number, current: number) => {
    const query = stringify({
      populate: {
        exercise_video: {
          fields: ["url"],
        },
        exercise_image: {
          fields: ["url"],
        },
        trueToForm: {
          data: {
            attributes: {
              fields: ["url"],
            },
          },
        },
      },
      pagination: {
        page: current,
        pageSize: limit,
      },
    });
    const { data } = await strapi.get(`/exercises?${query}`);
    if (data?.data?.length) {
      setApiData((prev) => [...prev, ...data?.data]);
      setTotalPage(data?.meta?.pagination?.pageCount)
    }
  };

  const handleNextPage = () => {
    totalPage != currentPage && setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition - 400;
      scrollToPosition(targetPosition, 300);
    }
    if (scrollRef.current?.scrollLeft === 0) {
      handlePreviousPage();
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition + 400;
      scrollToPosition(targetPosition, 300);
    }
    if (
      scrollRef.current &&
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
        scrollRef.current.scrollWidth
    ) {
      handleNextPage();
    }
  };

  const scrollToPosition = (targetPosition: number, duration: number) => {
    const startTime = performance.now();
    const start = scrollRef.current?.scrollLeft;

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = start! + (targetPosition - start!) * progress;
      }
      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const handleImageClick = (videoUrl: string) => {
    setActiveVideoUrl(videoUrl);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
   setModalVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setActiveVideoUrl(null);
  };

  return (
    <div className="h-auto rounded-[16px] p-4 mb-5" style={{backgroundColor:'var(--card-bg-color)'}}>
      <p className="flex flex-col pb-4 sm:flex-row sm:items-center sm:justify-between text-primary-800 font-inter text-[22px] font-bold">
        <span>{t("Patient.data.onboard.trueToFormTitle")}</span>
        <span className="text-gray-800 text-[16px] font-normal">
          {t("Patient.data.onboard.trueToFormDescription")}
        </span>
      </p>
      <div className="image-navigation-container">
        <div className="horizontal-scroll-container relative" ref={scrollRef}>
          {apiData?.map((item: IApiDataItem, index) => {
            const videoUrl = item?.attributes?.trueToForm?.data?.attributes?.url;
            if (videoUrl) {
              return (
                <div
                  key={index}
                  className="custom-image-container-true-form"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageClick(videoUrl);
                  }}
                >
                  <div className="image-wrapper-form">
                    <img
                      src={item?.attributes?.exercise_image?.data[0]?.attributes?.url}
                      alt={item?.attributes?.name}
                      className="rounded-lg w-full h-full transition-all duration-300"
                    />
                    <div className="title-true-form">
                      <span className="text-xl font-semibold text-white">
                        {item?.attributes?.name}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="navigation-buttons">
          <span
            className="p-2 absolute top-1/2 left-3 transform -translate-y-1/2 bg-black/50 rounded-full cursor-pointer"
            onClick={scrollLeft}
          >
            <ChevronLeft />
          </span>
          <span
            className="p-2 absolute top-1/2 right-3 transform -translate-y-1/2 bg-black/50 rounded-full cursor-pointer"
            onClick={scrollRight}
          >
            <ChevronRight />
          </span>
        </div>
      </div>
      <Modal
        open={modalVisible}
        onCancel={handleCloseModal}
        width='60%'
        footer={null}
        centered
        destroyOnClose
      >
        <div style={{marginTop : '26px'}}>
          {activeVideoUrl && (
            <video
              ref={videoRef}
              autoPlay
              controls
              className="w-full h-auto"
              src={activeVideoUrl}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
