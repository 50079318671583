import { AddToReports } from '@atoms/AddToReports';
import PainAssessmentProgressData from '@pages/Contacts/Details/PainAssessmentProgressData';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { TCoachSummary, TDataProps } from '@stores/interfaces';
import { getSummaryTabData } from '@stores/myLibrary/myLibrary';
import { Empty, Pagination, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ListEvaluation = () => {
	const defaultApiData = {
		data: [],
		pagination: {
			currentPage: 1,
			from: 1,
			pageCount: 1,
			perPage: 1,
			to: 1,
			total: 1,
		},
	};
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const perPage = useTypedSelector(
		state => state.aiAssistant.aiAssistant.perpage,
	);
	const [selectedItems, setSelectedItems] = useState<TDataProps[]>([]);
	const [isPainAssessmentModalVisible, setIsPainAssessmentModalVisible] =
		useState(false);
	const [apiData, setApiData] = useState<TCoachSummary>(defaultApiData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perpage, setPerPage] = useState(10);
	const userId = user.isPhysioterapist ? selectedUser?.id : user.id;
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData(perPage === 0 ? perpage : perPage!, currentPage);
	}, [userId, perPage]);

	const handleItemSelect = (items: TDataProps[]) => {
		setSelectedItems(items);
	};

	const handleModalOk = () => {
		setIsPainAssessmentModalVisible(false);
	};

	const handleModalCancel = () => {
		setIsPainAssessmentModalVisible(false);
	};

	const fetchData = async (limit: number, page: number) => {
		const payload = {
			userId: userId,
			page: page,
			limit: limit,
		};
		const getSummaryData = await dispatch(getSummaryTabData(payload));
		const data = getSummaryData.payload;
		setApiData(data);
		setIsLoading(false);
	};

	const onPageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		fetchData(perpage, pageNumber);
	};

	const isReportModal = useTypedSelector(
		state => state.aiAssistant.aiAssistant.isReportModal,
	);

	return (
		<div className="pl-5 pr-5">
			{!isReportModal && (
				<div className="lower-content-container">
					<AddToReports />
				</div>
			)}
			<>
				<div className="card-container">
					<div
						className={`${user.isPhysioterapist && 'mr-4'} rounded-md border-inherit `}>
						{isLoading ? (
							<Spin
								className="flex items-center justify-center"
								tip="Loading"
								size="large"
							/>
						) : (
							<>
								{apiData?.data?.length > 0 ? (
									<div className="collapse-subpanel-wrap">
										<PainAssessmentProgressData
											onItemSelect={handleItemSelect}
											onOk={handleModalOk}
											onCancel={handleModalCancel}
											selectedItems={selectedItems}
											isPainAssessmentModalVisible={
												isPainAssessmentModalVisible
											}
											currentPage={currentPage}
											apiData={apiData}
											fetchData={fetchData}
											perPage={perPage}
											perpage={perpage}
										/>{' '}
									</div>
								) : (
									<div className="p-5">
										<Empty
											description={t(
												'Admin.data.menu.aiAssistant.aiAssistantListEvaluation.noEvaluationFound',
											)}
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<div className="mt-5">
					{apiData &&
						apiData.pagination &&
						apiData?.data?.length > 0 &&
						!perPage && (
							<Pagination
								current={currentPage}
								onChange={onPageChange}
								total={apiData.pagination.total || 0}
								showSizeChanger={false}
							/>
						)}
				</div>
			</>
		</div>
	);
};
