import { Input, Select, message } from 'antd'
import { useRef, useState } from 'react'
import { MdAddCircleOutline, MdOutlineAssignment } from "react-icons/md";
import { LayerSingle } from "@carespace-icons/layoult/layerSingle";
import { LayersThree02 } from "@carespace-icons/layoult/layersThree02";
import { FaceHappy } from "@carespace-icons/users/faceHappy";
import Button from "@atoms/Button";
import { Image03 } from '@carespace-icons/files/image03';
import { useTranslation } from 'react-i18next';
import { ISurevyPopupFormProps } from '@stores/interfaces';

const { Option } = Select
const { TextArea } = Input

export default function SurveyPopUpForm(props: ISurevyPopupFormProps) {
  const { survey, isEdit, setImgFile, imgFile, surveyTitle, surveyInstructions, setSurveyInstructions, surveyResultFeedback, setSurveyResultFeedback, setSurveyTitle, surveyDescription, setSurveyDescription, questionValue, setQuestionValue, setQuestionType, questionType, setQuestionList, questionList } = props
  const { t } = useTranslation()
  const imgInputRef = useRef<HTMLInputElement>(null);
  const [imgHover, setImgHover] = useState(false)
  const [idCounter, setIdCounter] = useState(1);
  const [isScored, setIsScored] = useState(false);

  const handleClickScore = () => {
    setIsScored(!isScored);
  };

  const handleImgChange = (e: { target: { files: any; }; }) => {
    const files = e.target.files;
    message.success(t('Patient.data.activityStream.imageSelected'));
    if (files[0]) {
      setImgFile([files[0]]);
    }
  };

  const handleQuestion = () => {
    if (!questionValue.trim()) {
      message.warning(t("Admin.data.survey.errQuestionTitle"));
      return;
    }
    if (!questionType) {
      message.warning(t("Admin.data.survey.errQuestionTitle"));
      return;
    }
    const newQuestion = {
      id: idCounter.toString(),
      title: questionValue,
      description: "Description",
      questionType: questionType,
      scored: isScored
    }

    setQuestionList([...questionList, newQuestion])
    setQuestionValue("");
    setQuestionType(undefined);
    setIdCounter(idCounter + 1)
  };
  return (
    <div className='survey-popup-form'>
      <div className="image-upload imageUpload">
        {!survey?.id ?
          <label
            className="image-upload-label"
            onMouseOver={() => setImgHover(true)}
            onMouseOut={() => setImgHover(false)}
          >
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={imgInputRef}
              onChange={handleImgChange}
            />
            {
              survey?.image
                ? <div className="image-preview">
                  <div style={{ opacity: imgHover ? 100 : 0 }} className="image-hover">
                    <span className="text">{t('Admin.data.survey.changeImage')}</span>
                  </div>
                  <img src={imgFile![0] ? URL.createObjectURL(imgFile![0]) : survey?.image} className="image" />
                </div>
                : <div className="upload-placeholder">
                  <div style={{ opacity: imgHover ? 100 : 0 }} className={`image-hover`}>
                    <span className="text">{imgFile![0] ? <>{t('Admin.data.survey.changeImage')}</> : <>{t('Admin.data.survey.upload')}</>}</span>
                  </div>
                  {!imgFile![0] ? <div className="upload-instructions"><Image03 width={50} height={50} color="stroke-gray-200" />
                    <p>{t('Admin.data.survey.uploadImage')}</p></div> : <><img src={URL.createObjectURL(imgFile![0])} width={154} height={154} /></>}
                </div>
            }
          </label>:<label
            className="image-upload-label"
          >
            {
              survey?.image
                ? <div className="image-preview">
                  <img src={imgFile![0] ? URL.createObjectURL(imgFile![0]) : survey?.image} className="image" />
                </div>
                : <div className="upload-placeholder">
                  {!imgFile![0] ? <div className="upload-instructions"><Image03 width={50} height={50} color="stroke-gray-200" />
                    <p>{t('Admin.data.survey.uploadImage')}</p></div> : <><img src={URL.createObjectURL(imgFile![0])} width={154} height={154} /></>}
                </div>
            }
          </label>
        }
        <div style={{ width: '100%' }}>
          <p style={{margin:"5px 0px"}}>{t('Admin.data.survey.surveyTitle')}</p>
          <Input
            className='survey-title'
            value={surveyTitle}
            readOnly={survey?.clinicallyValidated}
            onChange={(e) => setSurveyTitle(e.target.value)}
          />
          <p style={{margin:"5px 0px"}}>{t('Admin.data.survey.surveyDescription')}</p>
          {survey?.clinicallyValidated ? (
        <TextArea
          className='survey-title'
          value={surveyDescription}
          readOnly
          autoSize={{ minRows: 1.6, maxRows: 5 }}
        />
      ) : (
          <TextArea
              className='survey-title'
              value={surveyDescription}
              onChange={(e) => setSurveyDescription(e.target.value)}
              readOnly={survey?.clinicallyValidated}
              autoSize={{ minRows:1.6, maxRows: 5 }}
            />)}
        </div>
      </div>
      <div style={{display:"flex", flexDirection: "row", gap:"10px"}}>
      <div style={{display:"flex", flexDirection: "column", width:"50%"}}>
      <p style={{margin:"5px 0px"}}>{t('Admin.data.survey.surveyInstructions')}</p>
      {survey?.clinicallyValidated ? (
        <TextArea
          className='survey-title'
          value={surveyInstructions}
          autoSize={{ minRows:5, maxRows: 5 }}
          readOnly
        />
      ) : (
        <TextArea
          className='survey-title'
          value={surveyInstructions}
          onChange={(e) => setSurveyInstructions(e.target.value)}
          autoSize={{ minRows:5, maxRows: 5 }}
        />
      )}</div>
      <div style={{display:"flex", flexDirection: "column", width:"50%"}}>
      <p style={{ margin: '5px 0px' }}>{t('Admin.data.survey.surveyResultFeedback')}</p>
      {survey?.clinicallyValidated ? (
        <TextArea
          className='survey-title'
          value={surveyResultFeedback}
          autoSize={{ minRows:5, maxRows: 5 }}
          readOnly
        />
      ) : (
        <TextArea
          className='survey-title'
          value={surveyResultFeedback}
          autoSize={{ minRows:5, maxRows: 5 }}
          onChange={(e) => setSurveyResultFeedback(e.target.value)}
        />
      )}</div></div>

      {!survey?.clinicallyValidated && <>
        <p style={{ margin: "5px 0px" }}>{t("Admin.data.survey.questionTitle")}</p>
        <div className='question-section'>
          <Input
            className='question-input'
            value={questionValue}
            onChange={(e) => setQuestionValue(e.target.value)}
            style={{ width: "100%" }}
          />
          <div className="select-container">
            <div className='select-wrapper'>
              <div className='select-row'>
                {questionType != "openText" &&
                  <span onClick={handleClickScore} className={`score-btn ${isScored ? 'active' : ''}`}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {isScored ? t("Admin.data.survey.scored") : t("Admin.data.survey.unscored")}
                    </span>
                  </span>}
                <Select
                  className="question-type-select"
                  bordered={false}
                  onChange={(value) => setQuestionType(value)}
                  value={questionType}
                  placeholder={t('Admin.data.survey.type')}
                >
                  <Option value="rate"><span style={{ marginRight: 4 }}><FaceHappy color={'stroke-gray-900'} width={17} height={17} /> </span>{t('Admin.data.survey.rate')}</Option>
                  <Option value="singleSelect"><span style={{ marginRight: 4 }}><LayerSingle color={'stroke-gray-900'} width={17} height={17} /> </span>{t('Admin.data.survey.singleSelect')}</Option>
                  <Option value="multiSelect"><span style={{ marginRight: 4 }}><LayersThree02 color={'stroke-gray-900'} width={17} height={17} /> </span>{t('Admin.data.survey.multiSelect')}</Option>
                  <Option value="openText"><span style={{ marginRight: 4 }}><MdOutlineAssignment className="icon" /> </span>{t('Admin.data.survey.openText')}</Option>
                </Select>
                <Button onClick={handleQuestion} size="sm">
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MdAddCircleOutline className="button-icon" />
                    {t('Admin.data.survey.add')}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div></>}
    </div>
  )
}
