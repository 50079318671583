import { useState } from 'react';
import './style.css'
import { Content } from 'antd/lib/layout/layout';
import ImageModalContent from './ImageModalContent';
import BodypointsSelectionStep from '../BodyPointsSelectionStep';
import { RehabVideoState } from '@stores/interfaces';

interface IRomImageModal {
  setVideoState?: (value: RehabVideoState | undefined) => void
}
const RomImageModal = (props: IRomImageModal) => {
  const [activeStep, setActiveStep] = useState(1)
  const { setVideoState } = props

  return (
    <Content style={{
      margin: 'auto',
      width: '1000px',
      height: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    }}
      className='select-none'>
      {activeStep === 1 && <ImageModalContent {...props} activeStep={activeStep} setActiveStep={setActiveStep} />}
      {activeStep === 2 && <BodypointsSelectionStep setVideoState={setVideoState!} />}
    </Content>
  );
};

export default RomImageModal;
