import { Image03 } from '@carespace-icons/files/image03';
import { IAddCoverImageProps } from '@stores/interfaces';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

export default function AddCoverUpload(props: IAddCoverImageProps) {
	const {
		previewImage,
		setOpenCoverImage,
		setPreviewImage,
		setImgFile,
		setPreviewUnSplashedImage,
	} = props;
	const { t } = useTranslation();

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (file) {
			if (file.type.startsWith('image/')) {
				const fileUrl = URL.createObjectURL(file);
				setImgFile(file);
				setPreviewImage(fileUrl);
			} else {
				message.error(
					t('Admin.data.menu.aiAssistant.aiAssistantPrograms.imageRequirement'),
				);
			}
		}
	};

	return (
		<div style={{ height: '275px' }}>
			<div
				style={{
					border: '2px dashed #d9d9d9',
					borderRadius: '8px',
					padding: '20px',
					textAlign: 'center',
					cursor: 'pointer',
					height: '250px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				className="relative group"
				onClick={() => {
					document.getElementById('fileInput')?.click();
				}}>
				{previewImage ? (
					<img
						src={previewImage}
						alt="Preview"
						className="w-full h-[200px] object-contain rounded-lg"
					/>
				) : (
					<div>
						<Image03 width={50} height={50} color="stroke-gray-300" />
						<p style={{ marginTop: '10px', color: '#d0d5dd' }}>
							{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.dropImage')}
						</p>
					</div>
				)}
				<div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
					<span className="text-white font-semibold">
						{previewImage
							? t('Admin.data.survey.changeImage')
							: t('Admin.data.survey.upload')}
					</span>
				</div>
			</div>
			<input
				type="file"
				id="fileInput"
				style={{ display: 'none' }}
				accept="image/*"
				onChange={handleFileChange}
			/>
			<Button
				type="primary"
				className="float-right mt-3"
				onClick={() => {
					setPreviewUnSplashedImage(null);
					setOpenCoverImage(false);
				}}>
				{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.save')}
			</Button>
		</div>
	);
}
