import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	IGetPatientResults,
	IRomInitialState,
	FacingMode,
	IRomPatientResult,
	StrapiOmniRomExercises,
	StrapiOmniRomExerciseGroup,
	ICustomRomSession,
	ICustomRomExercise,
	IRomProgramExercise,
	IStrapiExercise,
} from '@stores/interfaces';
import { getExercisesResults } from '@stores/rom/results';
import axios, { Canceler } from 'axios';
import { format, formatISO } from 'date-fns';
import strapi from '@strapi';
import { stringify } from 'qs';
import { ReduxState } from '..';
import html2canvas from 'html2canvas';

export const getPrintScreen = async () => {
	const canvas = await html2canvas(document.getElementById('printscreen')!);
	return canvas.toDataURL();
};

export const createSession = createAsyncThunk(
	'rom/session/createSession',
	async (body: { id: string; userId: string; customRomId: string }) => {
		const { data } = await axios.post(`/rom/sessions`, body);
		const programData = await axios.get(`/rom/programs/${data.romProgramId}`);
		data.exercises = programData?.data?.exercises;
		return data;
	},
);

export const createOnBoardSession = createAsyncThunk(
	'rom/session/createOnBoardSession',
	async (body: { userId: string }) => {
		const { userId } = body;
		const { data } = await axios.post(`/rom/sessions`, {
			userId: userId,
			strapiOmniRomProgramId: 9,
		});
		const programData = await axios.get(`/rom/programs/${data.romProgramId}`);
		data.exercises = programData?.data?.exercises
		return data;
	},
);

export const createCustomSession = createAsyncThunk(
	'rom/session/createCustomSession',
	async (body: { userId: string; romProgramId: string }) => {
		const { data } = await axios.post(`/rom/sessions`, body);
		return data;
	},
);

export const getPhysicalAssessmentsMetrics = createAsyncThunk(
	'rom/getPhysicalAssessmentsMetrics',
	async (body: IGetPatientResults): Promise<IRomPatientResult[]> => {
		const { userId, exerciseId, rangeDate } = body;
		const { data } = await axios.get(
			`/rom/patients/${userId}/results?romExerciseId=${exerciseId}&initialDate=${rangeDate.start}&finalDate=${rangeDate.end}`,
		);

		return data;
	},
);

export const getOmniRomExerciseGroups = createAsyncThunk(
	'strapi/OmniRomExerciseGroup',
	async (): Promise<StrapiOmniRomExerciseGroup[]> => {
		const query = stringify({
			populate: {
				frontend: '*',
			},
		});
		const { data } = await strapi.get(`/omni-rom-exercise-groups?${query}}`);
		return data.data;
	},
);

export const fetchExercises = createAsyncThunk(
	'rom/fetchExercises',
	async (_, thunkAPI): Promise<StrapiOmniRomExercises[]> => {
		const { getState } = thunkAPI;
		const state = getState() as ReduxState;

		const query = stringify({
			filters: {
				omniRomExerciseGroups: {
					id: state.rom.main.strapiOmniRomExerciseGroupId,
				},
			},
			sort: ['order:asc'],
			populate: {
				video: {
					fields: 'url',
				},
				image: {
					fields: 'url',
				},
				pointsToCalculateAngle: {
					populate: {
						a: true,
						b: true,
						c: true,
					},
				},
				pointsToValidatePosition: {
					populate: {
						a: true,
						b: true,
						c: true,
					},
				},
				omniRomExerciseGroups: true,
				reference: true,
			},
		});

		const { data } = await strapi.get(`/omni-rom-exercises?${query}`);
		return data.data;
	},
);

export const closeSession = createAsyncThunk(
	'closeSession',
	async (sessionId: string): Promise<ICustomRomSession> => {
		try {
			const { data } = await axios.patch(`/rom/sessions/${sessionId}`);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const savePatientResults = createAsyncThunk(
	'saveExercise',
	async (payload: any, { dispatch }): Promise<ICustomRomExercise> => {
		try {
			const { data } = await axios.post(
				`/rom/sessions/patient-results`,
				payload,
				{
					onUploadProgress: progressEvent => {
						const progress = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total!,
						);
						dispatch(setUploadProgress(progress));
					},
				},
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const updatePatientResults = createAsyncThunk(
	'updateSessionExercise',
	async (payload: { exerciseId: string; exerciseData: any }): Promise<any> => {
		try {
			const { exerciseId, exerciseData } = payload;
			const { data } = await axios.patch(
				`/rom/session/patient-results/${exerciseId}`,
				exerciseData,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export type Progress = {
	total: number;
	completed: number;
	current: number;
};

export enum ETransitions {
	INTRO = 'intro',
	CALIBRATION = 'calibration',
	READYSETGO = 'readySetGo',
	CLOSING = 'closing',
	RESULT = 'result',
	OPENNING = 'openning',
}

export type TTransitions = {
	next: TTransitions | null;
	value: ETransitions;
};

export const linkedListTransitions = () =>
	Object.values(ETransitions).reduceRight(
		(next: TTransitions | null, value) => ({ value, next }),
		null,
	);

const date = new Date();

const initialState: IRomInitialState = {
	user: null,
	exercises: null,
	currentExercise: null,
	uploadProgress: 0,
	transition: linkedListTransitions(),
	goToExercise: null,
	pose: {
		angleResult: 0,
		angleResults: [],
		coordinates: [],
		multiCoordinates: [],
	},
	bodyPointsVisible: false,
	progress: {
		total: 0, // numero de exercícios
		completed: 0,
		current: 0,
	},
	gallery: {
		isOpened: false,
		images: [],
		imagesToCompare: [],
		rangeDate: {
			start: formatISO(date.setDate(date.getDate() - 7)),
			end: formatISO(date.setDate(date.getDate() + 8)),
		},
	},
	tutorial: {
		watched:
			JSON.parse(localStorage.getItem('watchedTutorial') as string) || false,
		isOpened: false,
	},
	splash: {
		isOpened: true,
	},
	assessment: {
		isOpened: false,
	},
	menu: {
		isOpened: false,
	},
	paused: true,
	resultsExercises: [],
	finishedExercises: {
		finished: false,
		isOpened: false,
	},
	loading: true,
	isRepetingExercise: false,
	aspectArea: {
		width: 1280,
		height: 720,
	},
	selfieMode: false,
	metricsData: {
		userId: '',
		strapiOmniRomExerciseId: '',
		romSessionId: '',
		value: null,
		coordinates: '',
		screenshot: '',
	},
	resultExerciseValidation: null,
	enableToSendResult: false,
	waitSavedPhysicalAssessmentsMetrics: null,
	resultIndex: null,
	incorrectSide: false,
	facingMode: FacingMode.USER,
	cameraId: null,
	bodyRegion: null,
	session: null,
	omniRomExerciseGroups: null,
	strapiOmniRomExerciseGroupId: null,
	fetchFullScanExercise: null,
	isCustom: false,
};

export const main = createSlice({
	name: 'main',
	initialState,
	reducers: {
		updateUser: (state, action) => {
			state.user = action.payload || null;
		},
		startDateRange: (state, action) => {
			state.gallery.rangeDate.start = action.payload;
		},
		endDateRange: (state, action) => {
			state.gallery.rangeDate.end = action.payload;
		},
		setFullScanExercise: (state, action) => {
			state.fetchFullScanExercise = action.payload;
		},
		goToGallery: (state, action) => {
			state.paused = true;
			state.gallery.isOpened = action.payload.isOpened;
			state.gallery.imagesToCompare = [];

			if (action.payload.exercise)
				state.currentExercise = { ...action.payload.exercise };

			if (action.payload.isOpened) {
				state.gallery.images.map(img => {
					img.isSelected = false;
					return img;
				});
			}

			state.gallery.rangeDate.start = initialState.gallery.rangeDate.start;
			state.gallery.rangeDate.end = initialState.gallery.rangeDate.end;
		},
		goToAssessment: state => {
			state.splash.isOpened = false;
			state.assessment.isOpened = !state.assessment.isOpened;
			state.paused = state.assessment.isOpened;
		},
		setUploadProgress: (state, action) => {
			state.uploadProgress = action.payload;
		},
		watchTutorial: (state, action) => {
			state.tutorial.watched = action.payload;
			state.tutorial.isOpened = action.payload;
			state.paused = true;
		},
		toggleTutorial: (state, action) => {
			state.assessment.isOpened = false;
			state.splash.isOpened = false;
			state.tutorial.isOpened = action.payload;
			state.paused = state.tutorial.isOpened;
		},
		toggleSplash: state => {
			state.splash.isOpened = !state.splash.isOpened;
		},
		togglePlayPause: state => {
			state.paused = !state.paused;
		},
		setPause: state => {
			state.paused = true;
		},
		setPlay: state => {
			state.paused = false;
		},
		nextTransition: (state, action) => {
			state.transition = action.payload;
			state.paused = false;
			state.isRepetingExercise = false;
		},
		goToExercise: (state, action) => {
			state.gallery.isOpened = false;
			state.assessment.isOpened = false;
			state.paused = false;
			(state.transition = linkedListTransitions()),
				(state.isRepetingExercise = false);
			state.pose.angleResult = 0;
			state.currentExercise = action.payload;
			state.resultExerciseValidation = null;
		},
		setIsRepetingExercise: (state, action) => {
			state.isRepetingExercise = action.payload;
		},
		repetingExercise: (state, action) => {
			state.isRepetingExercise = action.payload;
			state.metricsData = initialState.metricsData;
			state.paused = false;
			(state.transition = linkedListTransitions()),
				(state.pose.angleResult = 0);
			state.resultExerciseValidation = null;
		},
		nextExercise: state => {
			let nextExerciseIndex = 0;
			const countExercises = state.exercises?.length ?? 0;

			if (countExercises > 0) {
				let currentExerciseIndex =
					state.exercises?.findIndex(
						elm => elm.id === state?.currentExercise?.id,
					) ?? 0;

				if (currentExerciseIndex === countExercises - 1) {
					currentExerciseIndex = 0;
				}

				for (
					let index = currentExerciseIndex;
					index <= countExercises;
					index++
				) {
					if (!state.resultsExercises[index]) {
						nextExerciseIndex = index;
						break;
					}
				}

				const countFinishedExercises = state.resultsExercises.filter(
					exer => !!exer,
				).length;

				state.currentExercise = state.exercises![nextExerciseIndex];
				state.progress.current = countFinishedExercises;
				state.progress.completed =
					(state.progress.current * 100) / state.progress.total;
				state.paused = false;
				state.goToExercise = null;
				(state.transition = linkedListTransitions()),
					(state.enableToSendResult = false);
				state.pose.angleResult = 0;
				state.resultExerciseValidation = null;

				if (countFinishedExercises === countExercises) {
					state.currentExercise = null;
					state.finishedExercises.finished = true;
					state.finishedExercises.isOpened = true;
					state.paused = true;
					state.isRepetingExercise = false;
					state.enableToSendResult = false;
					state.pose.angleResult = 0;
					state.resultExerciseValidation = null;
				}
			}
		},
		resetResultsExercises: state => {
			state.resultsExercises = [];
			state.currentExercise = null;
			state.finishedExercises.finished = false;
			state.finishedExercises.isOpened = false;
			state.paused = false;
			state.progress.current = 1;
			state.pose.angleResult = 0;
			state.resultExerciseValidation = null;
		},
		exerciseValue: (state, action) => {
			state.pose.angleResult = action.payload.value;
			state.pose.coordinates = action.payload.coordinates;
		},
		getBodyPointsVisible: (state, action) => {
			state.bodyPointsVisible = action.payload;
			state.paused = false;
		},
		toggleLoading: (state, action) => {
			state.loading = action.payload;
		},
		selectImagesToCompare: (state, action) => {
			const index = action.payload;

			state.gallery.images[index].isSelected =
				state.gallery.imagesToCompare.length < 2
					? !state.gallery.images[index].isSelected
					: false;

			state.gallery.imagesToCompare = [
				...state.gallery.images.filter(elm => elm.isSelected),
			];
		},
		toggleMenu: state => {
			state.menu.isOpened = !state.menu.isOpened;
			state.paused = state.menu.isOpened ? true : false;
			state.gallery.imagesToCompare = [];
		},
		updateAspectArea: (state, action) => {
			state.aspectArea = {
				...state.aspectArea,
				...action.payload,
			};
		},
		toggleSelfieMode: state => {
			state.selfieMode = !state.selfieMode;
		},
		setSession: (state, action) => {
			const {
				session,
				exercises,
			}: {
				session: ICustomRomSession;
				exercises: ICustomRomExercise[];
			} = action.payload;

			state.session = session;
			state.exercises = exercises;
			state.resultsExercises = exercises.filter(
				exercise =>
					session.customRomSessionExercise?.find(
						sessionExercise =>
							sessionExercise?.customRomExerciseId == exercise?.id,
					) || false,
			);
			state.currentExercise =
				exercises.find(
					exercise =>
						!state.resultsExercises?.find(
							cExercise => cExercise.id == exercise.id,
						),
				) || exercises[0];
		},
		selectExercise: (state, action) => {
			state.exercises = action.payload;
			state.resultsExercises = [];
			state.currentExercise = action.payload[0];
			state.isCustom = true;
		},
		setResultExerciseValidateValue: (state, action) => {
			const exerciseResult = {
				...state.currentExercise,
				reference: state?.currentExercise?.strapiOmniRomExercise?.reference,
				result: +state.pose.angleResult,
				screenshot: action.payload.screenshot,
			};

			const { reference, result } = exerciseResult;

			if (state.isCustom) {
				state.resultExerciseValidation = {
					results: state.pose.angleResults,
					screenshot: action.payload.screenshot,
				};
			}

			if (reference) {
				const { min, normal } = reference;
				if (min < normal) {
					state.enableToSendResult = result >= min;
				} else {
					state.enableToSendResult = result <= min;
				}

				state.resultExerciseValidation = { ...exerciseResult };
				state.isRepetingExercise = false;
			} else {
				state.isRepetingExercise = true;
			}
		},
		setMetricsDataValue: (state, action) => {
			state.metricsData = {
				...action.payload,
				value: +state.pose.angleResult,
				coordinates: state.pose.coordinates,
			};
			state.enableToSendResult = false;
		},
		setIncorrectSide: (state, action) => {
			state.incorrectSide = action.payload;
			state.paused = false;
		},
		setFacingMode: state => {
			state.facingMode =
				state.facingMode === FacingMode.USER
					? FacingMode.ENVIRONMENT
					: FacingMode.USER;
		},
		setCameraId: (state, action) => {
			state.cameraId = action.payload;
		},
		resetAll: state => Object.assign(state, initialState),
		setStrapiOmniRomExerciseGroupId: (state, action) => {
			state.strapiOmniRomExerciseGroupId = action.payload;
		},
		completedExercise: (state, action) => {
			state.resultsExercises?.push(action.payload);
		},
		setPoseData: (state, action) => {
			const index = action.payload.index;
			state.pose.angleResults[index] = action.payload.angleResult;
			state.pose.multiCoordinates[index] = action.payload.coordinates;
		},
		setAngularResult: (state, action) => {
			state.pose.angleResults = new Array(action.payload.length).fill(0);
		},
		clearPoseData: state => {
			state.pose.angleResults = [];
			state.pose.multiCoordinates = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(getOmniRomExerciseGroups.fulfilled, (state, action) => {
			state.omniRomExerciseGroups = action.payload;
		});

		builder.addCase(fetchExercises.fulfilled, (state, action) => {
			state.exercises = action.payload;
			state.progress.total = state.exercises?.length!;
			state.currentExercise = { ...state.exercises![0] };
			state.resultsExercises = new Array(state.exercises!.length).fill(
				undefined,
			);
		});

		builder.addCase(
			getPhysicalAssessmentsMetrics.fulfilled,
			(state, action) => {
				state.gallery.images = [];

				if (action?.payload?.length > 0) {
					const payload = [...action?.payload];

					const images = payload.map(elem => {
						return {
							id: elem.id,
							exercise: elem.strapiOmniRomExerciseId,
							src: elem.screenshot,
							thumbnail: elem.screenshot,
							thumbnailWidth: 320,
							thumbnailHeight: 180,
							isSelected: false,
							date: format(new Date(elem.createdAt), 'MM/dd/yyyy'),
							result: elem.value,
						};
					});
					state.gallery.images = [...images];
				}
			},
		);
		builder.addCase(getPhysicalAssessmentsMetrics.rejected, state => {
			state.gallery.images = [];
		});

		builder.addCase(createSession.fulfilled, (state, action) => {
			state.session = action.payload;
			state.exercises = action.payload.exercises;
			state.resultsExercises = [];
			state.currentExercise = state.exercises[0];
		});

		builder.addCase(createOnBoardSession.fulfilled, (state, action) => {
			state.session = action.payload;
			state.exercises = action.payload.exercises;
			state.resultsExercises = [];
			state.currentExercise = state.exercises[0];
		});

		builder.addCase(createCustomSession.fulfilled, (state, action) => {
			state.session = action.payload;
		});

		builder.addCase(getExercisesResults.fulfilled, (state, action) => {
			if (action.payload) {
				state.session = action.payload;
			}
		});

		builder.addCase(savePatientResults.fulfilled, state => {
			state.metricsData = null;
			state.uploadProgress = 0;
			state.transition = linkedListTransitions();
		});
		builder.addCase(savePatientResults.rejected, state => {
			state.uploadProgress = 0;
		});

		builder.addCase(updatePatientResults.fulfilled, () => {});
		builder.addCase(updatePatientResults.rejected, () => {});

		builder.addCase(closeSession.fulfilled, () => {});
		builder.addCase(closeSession.rejected, () => {});
	},
});

// Action creators are generated for each case reducer function
export const {
	updateUser,
	startDateRange,
	endDateRange,
	goToExercise,
	goToGallery,
	goToAssessment,
	watchTutorial,
	toggleTutorial,
	toggleSplash,
	togglePlayPause,
	nextTransition,
	resetResultsExercises,
	nextExercise,
	exerciseValue,
	getBodyPointsVisible,
	toggleLoading,
	selectImagesToCompare,
	toggleMenu,
	repetingExercise,
	updateAspectArea,
	setPause,
	setPlay,
	setUploadProgress,
	toggleSelfieMode,
	setResultExerciseValidateValue,
	setIsRepetingExercise,
	setMetricsDataValue,
	setIncorrectSide,
	setFacingMode,
	setCameraId,
	resetAll,
	setStrapiOmniRomExerciseGroupId,
	setFullScanExercise,
	selectExercise,
	setSession,
	completedExercise,
	setPoseData,
	clearPoseData,
} = main.actions;

export default main.reducer;
