import { FaceFrown } from "@carespace-icons/users/faceFrown";
import { FaceHappy } from "@carespace-icons/users/faceHappy";
import { FaceNeutral } from "@carespace-icons/users/faceNeutral";
import { FaceSad } from "@carespace-icons/users/faceSad";
import { FaceSmile } from "@carespace-icons/users/faceSmile";
import { Rate } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IRating {
  onStopRating: () => void
  selectedRating: number
  setSelectedRating: (value: number) => void
}

const RatingFeedback = (props: IRating) => {
  const { onStopRating, selectedRating, setSelectedRating } = props
  const [hoverIndex, setHoverIndex] = useState(-1);
  const { t } = useTranslation()

  const desc = ['TOO EASY', 'EASY', 'NORMAL', 'HARD', 'TOO HARD'];

  const getColor = (index: any) => {
    return index < hoverIndex ? hoverColors[index] : "stroke-white"
  };

  const hoverColors = [
    "stroke-green-200",
    "stroke-green-500",
    "stroke-yellow-200",
    "stroke-yellow-500",
    "stroke-yellow-700",
  ]


  const customIcons: Record<number, React.ReactNode> = {
    0: <FaceHappy color={getColor(0)} width={33} height={33} />,
    1: <FaceSmile color={getColor(1)} width={33} height={33} />,
    2: <FaceNeutral color={getColor(2)} width={33} height={33} />,
    3: <FaceFrown color={getColor(3)} width={33} height={33} />,
    4: <FaceSad color={getColor(4)} width={33} height={33} />,
  };

  const handleRating = (value: number) => {
    setSelectedRating(value)
    onStopRating();
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <p className="font-semibold text-lg text-white">{t('Admin.data.rehab.rehabPostAssessment.difficultyExercise')}</p>
      <span className="mt-2 flex items-center gap-3">
        <p className="!text-white font-semibold text-sm">TOO EASY</p>
        <Rate
          value={selectedRating}
          defaultValue={3}
          character={({ index }) => (
            <div className="flex flex-col justify-center items-center">
              {customIcons[index]}
              <p className="!text-white font-semibold text-sm">{index + 1}</p>
            </div>
          )}
          tooltips={desc}
          onChange={handleRating}
          onHoverChange={(index) => {
            setHoverIndex(index);
          }}
        />
        <p className="!text-white font-semibold text-sm">TOO HARD</p>
      </span>
    </div>
  )

}
export default RatingFeedback;