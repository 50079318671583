import { showCustomModal } from "@atoms/ACustomModalInfo"
import { SearchLg } from "@carespace-icons/general/searchLg"
import { List } from "@carespace-icons/layoult/list"
import { PlayCircle } from "@carespace-icons/media/playCircle"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { IRomProgramExercise, IStrapiPagination, StrapiOmniRomExercises } from "@stores/interfaces"
import { fetchExercises } from "@stores/rom/romTemplates"
import { Col, Empty, Input, Pagination, Row, Spin } from "antd"
import { KeyboardEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CiGrid41 } from "react-icons/ci";

interface ExerciseProps {
  setSelectedExercises: (selectedExercises: IRomProgramExercise[]) => void
  selectedExercises: IRomProgramExercise[]
  isGrid: boolean
  setGrid: (isGrid: boolean) => void
}

export const CarespaceLibraryData = (props: ExerciseProps) => {
  const { selectedExercises, setSelectedExercises, isGrid, setGrid } = props
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const exerciseLoad = useTypedSelector(state => state.rom.romTemplates.exerciseLoad);
  const [searchValue, setSearchValue] = useState<string>('')
  const carespaceLibraryExercises = useTypedSelector(state => state.rom.romTemplates.carespaceLibraryExercises);
  const [suggestedExercisesData, setSuggestedExercisesData] = useState<StrapiOmniRomExercises[]>(carespaceLibraryExercises?.data)
  const [suggestedExercisesPagination, setSuggestedExercisesPagination] = useState<IStrapiPagination>(carespaceLibraryExercises?.meta.pagination)

  useEffect(() => {
    onPageChange(1)
  }, []);

  useEffect(() => {
    setSuggestedExercisesData(carespaceLibraryExercises?.data)
    setSuggestedExercisesPagination(carespaceLibraryExercises?.meta?.pagination)
  }, [carespaceLibraryExercises])

  const onPageChange = async (pageNumber: number) => {
    setSuggestedExercisesData([])
    await dispatch(fetchExercises({ page: pageNumber, search: searchValue }))
  }

  const handleDivClick = (item: StrapiOmniRomExercises) => {
    if (selectedExercises?.find(exercise =>
      exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.id.toString() === item?.id.toString() :
        exercise?.OmniRomExerciseId ? exercise?.OmniRomExerciseId?.id.toString() === item?.id.toString() :
          exercise?.id.toString() === item?.id.toString())) {
      setSelectedExercises(selectedExercises?.filter(exercise =>
        exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.id.toString() != item?.id.toString() :
          exercise?.OmniRomExerciseId ? exercise?.OmniRomExerciseId?.id.toString() != item?.id.toString() :
            exercise?.id.toString() != item?.id.toString()))
    } else {
      const kinematicsArray = ['Flexion', 'Extension', 'Abduction', 'External Rotation', 'Internal Rotation'];
      const exercise = {
        id: item?.id,
        strapiOmniRomExerciseId: item?.id,
        name: item?.name,
        transitionTime: item?.transitionTime || 3,
        description: item?.description,
        image: item?.image?.url,
        video: item?.video?.url,
        bodypoint: [
          {
            name: item?.reference?.name,
            kinematics: kinematicsArray.find((kinematic) => item?.name?.includes(kinematic)),
            normal: item?.reference?.normal,
            wfl: item?.reference?.wfl,
            min: item?.reference?.min,
            function: item?.function,
            pointsToCalculateAngle: item?.pointsToCalculateAngle,
            pointsToValidatePosition: item?.pointsToValidatePosition,
            max: item?.reference?.max ? item?.reference?.max : item?.reference?.normal
          }
        ]
      };
      setSelectedExercises([...selectedExercises, exercise])
    }
  }

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await dispatch(fetchExercises({ page: 1, search: searchValue }))
    }
  };

  return (
    <div>
      <Input
        className='w-full my-3 h-10'
        placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
        value={searchValue}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => setSearchValue(e.target.value)}
        suffix={<SearchLg height={15} width={15} color='stroke-black' />}
      />
      {!exerciseLoad && suggestedExercisesData && suggestedExercisesData && suggestedExercisesData.length > 0 && <>
        <div className="flex gap-2 items-center justify-end mb-3">
          {selectedExercises.length != 0 && <p className="rounded-full py-0.5 px-2 bg-success-500 text-white">{selectedExercises.length}</p>}
          <div className="flex justify-end">
            <span onClick={() => setGrid(false)} className="w-9 h-9 text-center p-1 cursor-pointer rounded-l-lg border border-gray-300"><List color={isGrid ? 'stroke-gray-300' : 'stroke-gray-600'} height={24} width={24} /></span>
            <span onClick={() => setGrid(true)} className="w-9 h-9 text-center p-1 cursor-pointer rounded-r-lg border border-l-0 border-gray-300"><CiGrid41 className={`w-6 h-6 ${isGrid ? 'text-gray-500' : 'text-gray-300'}`} /></span>
          </div>
        </div>
      </>
      }
      <div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto", backgroundColor:'white', borderRadius:'10px',padding:'10px' }}>
        {
          exerciseLoad
            ? <div className="flex justify-center items-center h-40">
              <Spin tip="Loading" size="large" />
            </div>
            : suggestedExercisesData && suggestedExercisesData && suggestedExercisesData.length > 0 ?
              <>{
                !isGrid
                  ? suggestedExercisesData?.map((item: StrapiOmniRomExercises, index: number) => {
                    const isSelected = selectedExercises?.find(exercise =>
                      exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.id.toString() === item?.id.toString() :
                        exercise?.id.toString() === item?.id.toString() || exercise?.OmniRomExerciseId?.id.toString() === item?.id.toString())
                    return (
                      <div className="mb-4" key={index}>
                        <div className={`flex gap-4 p-4 !rounded-lg border-4 w-full items-center justify-center cursor-pointer ${isSelected ? 'border-4 border-green-500' : 'hover:border-primary-500'}`}
                          onClick={() => handleDivClick(item)} key={item.id}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              showCustomModal({
                                video: item?.video?.url,
                                name: item?.name,
                                description: item?.description,
                              })
                            }}
                            className="custom-image-container">
                            <div className="w-40 flex-shrink-0 image-wrapper">
                              <img src={item?.image?.url} className="rounded-lg w-full h-full" />
                              <div className="play-button">
                                <PlayCircle width={50} height={50} />
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 rounded-lg p-2">
                            <p className="text-gray-600 font-semibold text-lg">{item?.name}</p>
                            <p className="text-gray-600 mt-2">{item?.description ? item.description : t('Patient.data.omnirom.noDescription')}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  : <><Row gutter={[16, 16]}>
                    {suggestedExercisesData?.map((item) => {
                      const isSelected = selectedExercises?.find(exercise =>
                        exercise?.strapiOmniRomExercise ? exercise?.strapiOmniRomExercise?.id.toString() === item?.id.toString() :
                          exercise?.id.toString() === item?.id.toString() || exercise?.OmniRomExerciseId?.id.toString() === item?.id.toString())
                      return (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                          <div
                            className={`flex gap-4 p-4 !rounded-lg border-2 hover:border-4 w-full cursor-pointer ${isSelected ? 'border-4 border-green-500' : 'hover:border-primary-500'}`}
                            onClick={() => handleDivClick(item)}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                showCustomModal({
                                  video: item?.video?.url,
                                  name: item?.name,
                                  description: item?.description,
                                })
                              }}
                              className={`custom-image-container flexCenter`}
                              style={{ width: "3.5rem" }}>
                              <div className="w-14 flex-shrink-0 image-wrapper">
                                <img src={item?.image?.url} className="rounded-lg w-full h-full" />
                                <div className="play-button">
                                  <PlayCircle width={30} height={30} />
                                </div>
                              </div>
                            </div>
                            <div className="flex-1 rounded-lg p-2">
                              <p className="text-gray-600 font-semibold text-lg">{item?.name}</p>
                              <p className="text-gray-600 mt-2">{item?.description ? item.description : t('Patient.data.omnirom.noDescription')}</p>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  </>}

              </>
              : <Empty
                description='No Exercises Found'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
        }
      </div>
      {!exerciseLoad &&
        < Pagination
          className="createProgramModalPagination flex justify-center"
          current={
            suggestedExercisesPagination?.page
          }
          onChange={onPageChange}
          total={
            suggestedExercisesPagination?.total
          }
          showSizeChanger={false}
        />}
    </div>
  )
}