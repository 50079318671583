import { useEffect, useState } from 'react';
import { getSuggestedProgram, setExistingPatientData } from '@stores/onBoard/onBoard';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getRomSessionList } from '@stores/rom/results';
import { getPostureByPage } from '@stores/postures/postures';
import { message, Spin } from 'antd';
import './style.css'
import MyProgramsPatientOnboard from '@pages/PatientOnboard/MyProgramsPatientOnboard';
import TrueToFormPatientOnboard from '@pages/PatientOnboard/TrueToFormPatientOnboard';
import RecommendationDashboard from '@pages/PatientDashboard/RecommendationDashboard';
import ExistingPatientDashboard from '@pages/PatientDashboard/ExistingPatientDashboard';
import PatientDashboardBanner from '@pages/PatientDashboard/PatientDashboardBanner';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import SelectedProgram from '@pages/PatientDashboard/SelectedProgram';
import SuggestedPrograms from '@pages/PatientDashboard/SuggestedPrograms';
import { ACTIVETAB } from '@stores/constants';
import { createProgram, getProgramList } from '@stores/aiAssistant/program';
import { IProgramExercise } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';

export interface ISelectedProgram {
  exercises?: {
    strapiExerciseId: {
      exercise_image: {
        url: string;
      }[];
      exercise_video: {
        url: string;
      }[];
      name: string;
      description: string;
    };
    weeklyReps: string;
    dailyReps: string;
    sets: string;
    reps: string;
  }[];
  functional_goals?: {
    name: string;
  }[];
  duration?: string;
  durationType?: string;
  name?: string;
  description?: string;
  updatedAt?: string;
  thumbnail: {
    url?: string
  }
}

interface ISuggestedProgramDetails {
  data: Array<{
    id: number;
    name: string;
    description?: string;
  }>;
  meta: {
    pagination: {
      page: number;
      pageCount: number;
      pageSize: number;
      total: number;
    };
  };
}

export default function NewPatientOnboardDashboard() {
  const dispatch = useTypedDispatch()
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(true)
  const [isLoadingExist, setLoadingExist] = useState(true)
  const [suggestedProgramData, setSuggestedProgramData] = useState([]);
  const [suggestedProgram, setSuggestedProgram] = useState([]);
  const { suggestedProgramDetails, savedFunctionalGoals } = useTypedSelector((state) => ({
    suggestedProgramDetails: state.onBoard.onBoard.getSuggestedProgram,
    savedFunctionalGoals: state.onBoard.onBoard.savedFunctionalGoals,
  }));
  const existingPatientData = useTypedSelector((state) => state.onBoard.onBoard.existingPatientData)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector(state => state.contacts.main);
  const [programData, setProgramData] = useState<[]>([]);
  const [pagination, setPagination] = useState(1);
  const [functionalGoal, setFunctionalGoal] = useState<number[]>([])
  const [isFunctionGoalChanged, setIsFunctionGoalChanged] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(1)

  const programDetails = async (current: number, functionalGoalsIds:number[]) => {
    await dispatch(getSuggestedProgram({current, functionalGoalsIds}))
    setLoading(false)
  }

  useEffect(() => {
    dispatch(setActiveTab(ACTIVETAB.HOME))
    user?.id && isExistingPatientThere()
  }, [])

  const isExistingPatientThere = async () => {
    setLoadingExist(true)
    const romData = await dispatch(getRomSessionList({ patientId: user?.id, page: 1, limit: 10 }))
    const postureData = await dispatch(getPostureByPage({ userId: user?.id, page: 1 }))
    dispatch(setExistingPatientData(romData?.payload?.data?.length > 0 && postureData?.payload?.data?.length >0))
    setLoadingExist(false)
  }

  useEffect(() => {
    if (suggestedProgramDetails && total !== suggestedProgram.length) {
      suggestedProgramDetails?.meta?.pagination?.page === 1 && setSuggestedProgramData(suggestedProgramDetails?.data?.slice(0, 3));
      if(isFunctionGoalChanged){
        setSuggestedProgram(suggestedProgramDetails?.data);
      }else{
        setSuggestedProgram(prev => [...prev, ...(suggestedProgramDetails?.data || [])])
      }
      setTotal(suggestedProgramDetails.meta.pagination.total)
    }
  }, [suggestedProgramDetails, isFunctionGoalChanged])

  useEffect(()=> {
    if (savedFunctionalGoals?.functionalGoalsIds?.length > 0) {
      if (!areArraysEqual(savedFunctionalGoals?.functionalGoalsIds, functionalGoal)) {
        setIsFunctionGoalChanged(true)
        setFunctionalGoal(savedFunctionalGoals.functionalGoalsIds)
      }else{
        setIsFunctionGoalChanged(false)
      }
    }
  },[savedFunctionalGoals])

  const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  useEffect(() => {
      if (user?.functionalGoals?.length > 0) {
        const lastIndex = user?.functionalGoals?.length - 1;
        const functionGoalIds = user?.functionalGoals[lastIndex]?.functionalGoalsIds
        if(total !== suggestedProgram.length){
          programDetails(currentPage, functionGoalIds)
        }
      }else{
        programDetails(currentPage, [])
      }
  }, [currentPage])

  if (isLoading || isLoadingExist) {
    return <Spin className="spin-css" tip="Loading" size="large" />;
  }

  const fetchHomeData = async (pageNumber: number) => {
    const payload = {
      userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
      limit: 10,
      page: pageNumber,
    };
    const data = await dispatch(getProgramList(payload));
    if (data?.payload?.data?.length) {
      if (pageNumber === 1) {
        setProgramData(data?.payload?.data);
      } else {
        setProgramData((prev) => {
          const newData = data?.payload?.data?.filter(
            (item) => !prev?.find((prevItem) => prevItem?.id === item?.id)
          );
          return [...prev, ...newData];
        });
      }
    }
    setPagination(data?.payload?.pagination);
  };

  const handleEnroll = async (item: any) => {
    let exercises = [];
    item?.exercises?.forEach(
      (exercise: IProgramExercise, index: number) => {
        const tempExercise = {
          strapiExerciseId: exercise?.strapiExerciseId?.id || exercise?.strapiExerciseId || '',
          exerciseLibraryId: exercise.exerciseLibraryId || '',
          weeklyReps: exercise.weeklyReps,
          dailyReps: exercise.dailyReps,
          sets: exercise.sets,
          reps: exercise.reps,
          order: index + 1,
        };
        exercises.push(tempExercise);
      },
    );
    const formData = new FormData();
    formData.append('name', item?.name);
    formData.append('duration', item?.duration);
    formData.append('durationType', item?.durationType);
    formData.append('description', item?.description);
    formData.append('thumbnail', item?.thumbnail?.url || item?.thumbnail);
    if (user?.id) {
      formData.append('userId', String(user?.id));
    } else {
      console.error('userId is missing or invalid');
      return;
    }
    formData.append('strapiProgramTemplateId', item?.id);
    exercises?.forEach((exercise, index) => {
      Object.keys(exercise).forEach(key => {
        if (exercise[key]) {
          formData.append(
            `exercises[create][${index}][${key}]`,
            exercise[key],
          );
        }
      });
    });
    const data = await dispatch(createProgram(formData));
    if (data?.payload?.id) {
      message.success(t('Patient.data.newDashboard.enrolled'));
      fetchHomeData(1);
    }
  }

  return (
    <div className={`select-none`} style={{ backgroundColor : 'var(--home-bg-color)'}}>
      <>
        {!existingPatientData || !user?.profile?.isWellnessCheck ?
          <div className='m-5'><PatientDashboardBanner /></div> :
          <ExistingPatientDashboard suggestedProgramData={suggestedProgramData} handleEnroll={handleEnroll} />}
        <div className='m-5'>
          <MyProgramsPatientOnboard programData={programData} setProgramData={setProgramData} fetchHomeData={fetchHomeData} pagination={pagination} />
          {user?.profile?.isWellnessCheck && <>
            <SuggestedPrograms suggestedProgramData={suggestedProgram} currentPage={currentPage} setCurrentPage={setCurrentPage} handleEnroll={handleEnroll} />
            <RecommendationDashboard />
            <TrueToFormPatientOnboard /></>}
        </div>
      </>
    </div>
  )
}
