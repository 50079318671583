import { Edit05 } from '@carespace-icons/general/edit05';
import { Target04 } from '@carespace-icons/general/target04';
import { USER_ROLES } from '@stores/constants';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Button, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WeightHistoryChart } from '@atoms/AWeightHistoryChart';
import ManagedBy from './ManagedBy';
import WellnessCheck from './WellnessCheck';
import { newImageMapping } from './FunctionalGoals/ImageMapping';
import { FEATURES_MOCK } from '@pages/PatientOnboard/FunctionalGoals/FeaturesMock';
import AnimationCard from '@pages/PatientOnboard/FunctionalGoals/AnimationCard';
import { getSuggestedProgram, setDisplayedFunctionalGoalTitle, setSelectedFunctionalGoalTitle, updateFunctionalGoal } from '@stores/onBoard/onBoard';
interface UserProps {
	userDetails: {};
	functionalData: [] | undefined;
}

const functionalGoalsMap: Record<string, number[]> = {
	"Recovery & Managing Discomfort": [1, 3, 4],
	"Functional Independence & Daily Living": [2, 8],
	"Performance Enhancement & Injury Prevention": [6, 7],
	"Holistic Well-being & Quality of Life": [5, 9, 10],
};

const titleToIconMap: Record<string, number> = {
	"Recovery & Managing Discomfort": 1,
	"Functional Independence & Daily Living": 2,
	"Performance Enhancement & Injury Prevention": 3,
	"Holistic Well-being & Quality of Life": 4
};

const UserDetails = (props: UserProps) => {
	const { userDetails, functionalData } = props;
	const { t } = useTranslation();
	const [functionalModal, setFunctionalModal] = useState(false);
	const { user, selectedUser } = useTypedSelector((state) => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
	}))
	const isAdmin = user.profile.role === USER_ROLES.ADMIN;
	const isSuperAdmin = user.profile.role === USER_ROLES.SUPER_ADMIN;
	const dispatch = useTypedDispatch();
	const savedFunctionalGoals = useTypedSelector((state) => state.onBoard.onBoard.savedFunctionalGoals);
	const [savedData, setSavedData] = useState({});
	const displayedFunctionalGoalTitle = useTypedSelector((state) => state.onBoard.onBoard.displayedFunctionalGoalTitle);
	const [selectedCards, setSelectedCards] = useState<Record<string, number[]>>({});
	const [tempSelectedCards, setTempSelectedCards] = useState<Record<string, number[]>>(selectedCards);

	useEffect(() => {
    setSavedData(savedFunctionalGoals)
	},[savedData])

	useEffect(() => {
		let savedIds = savedData?.functionalGoalsIds?.length
			? savedData.functionalGoalsIds
			: userDetails?.functionalGoals?.length
				? userDetails.functionalGoals[userDetails.functionalGoals.length - 1]?.functionalGoalsIds || []
				: [];

		if (savedIds.length > 0) {
			const preSelectedCards = Object.fromEntries(
				Object.entries(functionalGoalsMap).map(([title, ids]) => [
					title,
					ids.filter((id) => savedIds.includes(id)),
				]).filter(([, matchedIds]) => matchedIds.length > 0)
			);
			setSelectedCards(preSelectedCards);
		} else {
			setSelectedCards({});
		}
	}, [savedData, userDetails, isAdmin, selectedUser, isSuperAdmin]);

	useEffect(() => {
		setTempSelectedCards(selectedCards);
	}, [selectedCards, selectedUser]);

	useEffect(() => {
		const selectedTitles = Object.keys(selectedCards);
		dispatch(setSelectedFunctionalGoalTitle(selectedTitles));
		dispatch(setDisplayedFunctionalGoalTitle(selectedTitles[0] || null));
	}, [selectedCards, dispatch, selectedUser]);

	const handleCardSelect = (title: string) => {
		setTempSelectedCards((prev) => {
			const updatedCards = { ...prev };
			if (updatedCards[title]) {
				delete updatedCards[title];
			} else {
				updatedCards[title] = functionalGoalsMap[title] || [];
			}
			return updatedCards;
		});
	};

	const handleNextClick = async () => {
		const functionalGoalsIds = Object.values(tempSelectedCards).flat();
		if (functionalGoalsIds.length === 0) {
			message.error(t("Patient.data.virtualEvaluation.associatedSymptoms.pleaseSelectAtLeastOneOption"));
			return;
		}

		const payload = { functionalGoalsIds };
		const current = 1;
		await dispatch(updateFunctionalGoal({ userId: user.isPhysioterapist ? selectedUser.id : user?.id, payload }));
		if (!user.isPhysioterapist) {
			await dispatch(getSuggestedProgram({ current, functionalGoalsIds }));
		}

		setSelectedCards(tempSelectedCards);
		dispatch(setSelectedFunctionalGoalTitle(Object.keys(tempSelectedCards)));
		dispatch(setDisplayedFunctionalGoalTitle(Object.keys(tempSelectedCards)[0] || null));
		setFunctionalModal(false);
	};

	useEffect(() => {
		if (!functionalModal) {
			setSelectedCards(tempSelectedCards);
		}
	}, [functionalModal, selectedUser]);

	const popoverContent = (
		<div>
			{Object.keys(selectedCards).length > 0 ? (
				<div className="popover-content-div">
					{Object.keys(selectedCards).map((title) => {
						const iconId = titleToIconMap[title];
						const iconSrc = iconId ? newImageMapping[iconId] : null;
						const isSelected = displayedFunctionalGoalTitle === title;

						return (
							<div
								key={title}
								className={`function-goal-icon-css ${isSelected ? "function-goal-icon-selected" : "function-goal-icon-non-selected"
									}`}
								onClick={() => dispatch(setDisplayedFunctionalGoalTitle(title))}
							>
								{iconSrc ? (
									<img
										src={iconSrc}
										alt={title}
										className="w-12 h-12 ml-[2px] cursor-pointer filter brightness-0 invert"
									/>
								) : (
									<p className="text-white text-sm font-normal">{t("Admin.data.managePatient.noIcon")}</p>
								)}
							</div>
						);
					})}
				</div>
			) : (
				<div className="m-2 rounded-md text-center h-16 grid place-items-center" style={{backgroundColor:'var(--adminmenu-bg-color)'}}>
					<p className="text-white text-sm font-normal">
						{t("Admin.data.managePatient.noFunctionalGoals")}
					</p>
				</div>
			)}
		</div>
	);

	return (
		<div className="user-details-main-container">
			{!user.isPhysioterapist && !user?.profile?.isWellnessCheck && <WellnessCheck />}
			{userDetails?.profile?.weight?.length > 0 && (
				<WeightHistoryChart userDetails={userDetails} />
			)}
			<ul
				style={{
					background: 'var(--user-menu-bg-color)',
					borderRadius: '0.5rem',
					marginTop: '10px',
				}}>
				<div className="functional-button-div mx-3 justify-between">
					<div className='flex items-center'>
						<Target04 width={17} height={17} color="stroke-white" />
						<p className="functional-title gap-10 flex items-baseline">
							{t('Admin.data.managePatient.functionalGoals')}</p>
					</div>
					<div>
						<span
							className="start-session-css text-xs px-2 py-1 rounded-full font-semibold cursor-pointer"
							style={{
								color: 'var(--button-text-color)',
								border: 'inherit',
							}}
							onClick={e => {
								e.stopPropagation();
								setFunctionalModal(true)
							}}>{t('Admin.data.managePatient.edit')}</span>
					</div>
				</div>
				{displayedFunctionalGoalTitle && (
					<div className="m-2 rounded-md text-center h-16 grid place-items-center" style={{backgroundColor:'var(--adminmenu-bg-color)'}}>
						<p className="text-white text-sm font-medium">{displayedFunctionalGoalTitle}</p>
					</div>
				)}
				<div style={{ marginTop: '8px' }}>
					{popoverContent}
				</div>
				<Modal
					title={
						<>
							<div className="flex gap-4">
								<span>
									<Edit05 width={20} height={20} color="stroke-primary-600" />
								</span>
								<span className="titleStyle">
									{t('Admin.data.managePatient.editFunctionalGoal')}
								</span>
							</div>
						</>
					}
					open={functionalModal}
					onCancel={() => { setTempSelectedCards(selectedCards); setFunctionalModal(false) }}
					footer={false}
					width={1500}
					className="select-none">
					<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '-10px' }}>
						{FEATURES_MOCK.map((feature, index) => (
							<AnimationCard
								key={index}
								{...feature}
								handleCardSelection={handleCardSelect}
								isSelected={!!tempSelectedCards[feature.title]}
							/>
						))}
					</div>
					<div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Button size="large" type="primary" className="back-button-inside-css" block style={{ height: "43px", background:"var(--button-color-primary)", color: "#fff", width: "308px" }} onClick={handleNextClick}>
							{t("Patient.data.onboard.save")}
						</Button>
					</div>
				</Modal>
			</ul>
			<ManagedBy />
		</div>
	);
};
export default UserDetails;