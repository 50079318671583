import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getSurveyData } from '@stores/survey/survey';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Row, Spin, message } from 'antd';
import { TbClipboardPlus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { ISurvey } from '@stores/interfaces';
import './style.less'
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { router } from '@routers/routers';


const StartSurvey = ({ id }: { id: string }) => {

  const { t } = useTranslation();
  const [surveyData, setSurveyData] = useState<ISurvey | null>(null);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate()
  const user = useTypedSelector(state => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (id: string) => {
      const action = await dispatch(getSurveyData({ id }));
      setSurveyData(action.payload);
      setLoading(false)
    };

    fetchData(id);
  }, [id, dispatch]);

  const isFinished = surveyData?.isFinished;

  const buttonStyle = {
    color: '#533F85',
    border: 'inherit',
    width: '100%',
  };

  return (
    <div className='start-survey'>
      {loading ? <Spin className="spinner" tip="Loading" size="large" /> :
        <div>
          <Row gutter={[16, 16]} className='start-survey-row'>
            <Col span={16} key={surveyData?.id}>
              <div className="custom-container-survey">
                <div className="custom-image-container">
                  {surveyData?.image ?
                    <Image src={surveyData?.image} className="image" alt={t('Admin.data.survey.noImage')} onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = "images/white-image.png";
                    }}
                      preview={{
                        src: surveyData?.image || "images/white-image.png",
                        mask: <MdOutlineRemoveRedEye size={18} />,
                      }}
                    /> :
                    <Image src="/images\white-image.png" className="image" alt={t('Admin.data.survey.noImage')}
                      preview={{
                        src: "images/white-image.png",
                        mask: <MdOutlineRemoveRedEye size={18} />,
                      }} />
                  }
                </div>
                <div className="survey-container">
                  <p className="survey-title">{surveyData?.title} {surveyData?.isFinished && <span className="text-gray-400">({surveyData?.isFinished && (t('Admin.data.survey.completed'))})</span>}</p>
                  <p className="questions">{t('Admin.data.survey.questions')}: {surveyData?.questionList?.length}</p>
                  <p className="description">{surveyData?.description}</p>
                  {surveyData?.status === "approved" ?
                    <Button
                      className='survey-button non-finished-survey-css'
                      style={buttonStyle}
                      icon={<TbClipboardPlus height={18} width={18} color="#533F85" />}
                      onClick={() => {
                        navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_SURVEY_USER}`, {
                          state: {
                            item: surveyData,
                            active: true
                          }
                        })
                        dispatch(setActiveTab('startSurvey'))
                      }}
                    >{surveyData?.isFinished ? t('Admin.data.survey.startSurveyAgain') : t('Admin.data.survey.startSurvey')}</Button>
                    : <Button
                      className="survey-button no-button-style"
                      icon={<TbClipboardPlus height={18} width={18} />}
                      onClick={() => {
                        message.error(t('Admin.data.survey.noSurvey'))
                      }}
                    >{t('Admin.data.survey.noSurvey')}</Button>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      }
    </div>
  );
}

export default StartSurvey;
