import { Trash01 } from "@carespace-icons/general/trash01";
import { Collapse, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SurveyQuestionItemProps } from "@stores/interfaces";
import { Save01 } from "@carespace-icons/general/save01";
import SurveyRateDataItem from "./SurveyRateDataItem";
import { useTranslation } from "react-i18next";
import { Edit02 } from "@carespace-icons/general/edit02";

const { Panel } = Collapse

export const SurveyQuestionItem = ({ survey, question, index, handleQuestionTitle, handleQuestionDelete, updateOptionTitle, updateOptionByIndex, addOptionsByIndex, deleteOptionByIndex, addRatingOptionByIndex }: SurveyQuestionItemProps) => {
  const { t } = useTranslation()
  const [questionTitle, setQuestionTitle] = useState<string>(question?.title || '')
  const [isQuestionEdit, setQuestionEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(!(question?.id?.length > 10 && question?.questionType === 'rate'))
  const [ratingOptions, setRatingOptions] = useState<{
    id: string,
    option: string,
    score: number,
    isDeleted: boolean,
  }[]>([]);

  useEffect(() => {
    setRatingOptions(() => {
      if (question?.questionType === 'rate') {
        let initialOptions = question?.optionList?.map(rate => {
          if (rate.option == '') return {
            ...rate,
            isDeleted: true,
          }
          else return {
            ...rate,
            isDeleted: false,
          }
        }) ?? [];
        if (initialOptions.length > 10) {
          initialOptions = initialOptions.slice(0, 10);
        }
        return [...initialOptions, ...Array(10 - initialOptions.length).fill({
          option: '',
          score: NaN,
          isDeleted: false,
        })];
      } else {
        return [];
      }
    })
  }, [question])

  const handleReset = () => {
    setRatingOptions(ratingOptions?.map(rate => {
      return {
        ...rate,
        score: rate?.isDeleted ? NaN : rate?.score,
        isDeleted: false
      }
    }))
  }

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question?.id });

  const style = {
    transition,
    transform: CSS.Transform.toString({
      x: transform?.x!,
      y: transform?.y!,
      scaleX: 1,
      scaleY: 1,
    }),
  };

  const getTaskPos = (id: string) => question?.optionList?.findIndex((option) => option.id === id);

  const handleDragEnd = (event: { active: { id: string }; over: { id: string } }) => {
    const { active, over } = event;

    if (!active.id || !over.id || active.id === over.id) return;

    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);

    if (originalPos === -1 || newPos === -1) return;

    const newArray = [...question?.optionList!];
    [newArray[originalPos!], newArray[newPos!]] = [newArray[newPos!], newArray[originalPos!]];

    updateOptionByIndex(index, newArray);
  };

  const handleInputChange = (index: number, value: string) => {
    const newRatingOptions = [...ratingOptions];
    newRatingOptions[index] = { ...newRatingOptions[index], option: value };
    setRatingOptions(newRatingOptions);
  };

  const handleDeleteRate = (index: number) => {
    const newRatingOptions = [...ratingOptions];
    newRatingOptions[index] = { ...newRatingOptions[index], option: "", isDeleted: true, score: 0 };
    setRatingOptions(newRatingOptions);
  };

  const handleScoreChange = (index: number, score: number) => {
    const newRatingOptions = [...ratingOptions];
    newRatingOptions[index] = { ...newRatingOptions[index], score: score };
    setRatingOptions(newRatingOptions);
  };

  const validateQuestion = () => {
    if (questionTitle?.trim() === "") {
      message.error(t('Admin.data.survey.errQuestionEmpty'))
    } else {
      setQuestionEdit(false)
      handleQuestionTitle(index, questionTitle)
    }
  }

  useEffect(() => {
    setQuestionTitle(question?.title || '');
  }, [question]);

  return <div
    className="survey-question-item"
    key={question.id}
    style={style}
    {...attributes}
  >
    {question?.questionType === 'openText'
      ? <div className="survey-open-text-item">
        {
          isQuestionEdit
            ? <Input
              className='input'
              value={questionTitle}
              onChange={(e) => setQuestionTitle(e.target.value)}
            />
            : <p>{question?.title}  ({question?.questionType?.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')})</p>
        }
        {!survey?.clinicallyValidated &&
          <span className="option-container">
            {
              isQuestionEdit
                ? <span onClick={() => { validateQuestion() }}>
                  <Save01 color="stroke-gray-400" width={20} height={20} />
                </span>
                : <span onClick={() => setQuestionEdit(true)}>
                  <Edit02 color="stroke-gray-400" width={20} height={20} />
                </span>
            }
            <span onClick={() => handleQuestionDelete(index)}>
              <Trash01 color="stroke-gray-400" width={20} height={20} />
            </span>
            <div
              ref={setNodeRef}
              {...listeners}
              style={{ marginTop: "4px", paddingRight: "9px" }}
            >
              <img src="/images/menu.svg" />
            </div>
          </span>}
      </div>
      : <Collapse bordered={false} className="survey-panel">
        <Panel
          className="question-panel"
          header={<div className="question-panel-header" style={{ height: "34px" }}>
            {
              isQuestionEdit
                ? <Input
                  className='question-input'
                  value={questionTitle}
                  onChange={(e) => setQuestionTitle(e.target.value)}
                />
                : `${question?.title} (${question?.questionType?.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')})`
            }
          </div>}
          key={index}
          extra={<>
            {!survey?.clinicallyValidated &&
              <span className="extra-options">
                {
                  isQuestionEdit
                    ? <span onClick={() => { validateQuestion() }}>
                      <Save01 color="stroke-gray-400" width={20} height={20} />
                    </span>
                    : <span onClick={() => setQuestionEdit(!isQuestionEdit)}>
                      <Edit02 color="stroke-gray-400" width={20} height={20} />
                    </span>
                }
                <span onClick={() => handleQuestionDelete(index)}>
                  <Trash01 color="stroke-gray-400" width={20} height={20} />
                </span>
                <div
                  ref={setNodeRef}
                  {...listeners}
                  style={{ marginTop: "4px" }}
                >
                  <img src="/images/menu.svg" />
                </div>
              </span>}</>}
        >
          <SurveyRateDataItem survey={survey} question={question} handleReset={handleReset} handleDeleteRate={handleDeleteRate} updateOptionByIndex={updateOptionByIndex} isEdit={isEdit} ratingOptions={ratingOptions} handleDragEnd={handleDragEnd} setIsEdit={setIsEdit} index={index} handleInputChange={handleInputChange} handleScoreChange={handleScoreChange} updateOptionTitle={updateOptionTitle} addOptionsByIndex={addOptionsByIndex} deleteOptionByIndex={deleteOptionByIndex} addRatingOptionByIndex={addRatingOptionByIndex} />
        </Panel>
      </Collapse>
    }
  </div>
}