import Lottie from 'lottie-react';
import animationData from './coord.json';
import { Col, Row } from 'antd';
import { useEffect } from 'react';

const readLabel = (label: string = 'STEP BACK') => {
	if ('speechSynthesis' in window) {
		const utterance = new SpeechSynthesisUtterance(label);
		window.speechSynthesis.speak(utterance);
	} else {
		console.error('Speech synthesis not supported in this browser.');
	}
};

function TransitionCalibration({ label = 'STEP BACK' }: { label?: string }) {
	useEffect(() => {
		readLabel(label);
		const intervalId = setInterval(() => {
			readLabel(label);
		}, 5000);
		return () => clearInterval(intervalId);
	}, []);

	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 3,
				width: '100%',
				height: "98%",
				backgroundColor: 'rgb(0, 0, 0, 0.7)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<Row align="middle" justify="center">
				<Col>
					<Lottie
						className="md:h-[340px] lg:h-[470px] xl:h-[580px] 2xl:h-[720px]"
						loop={false}
						autoplay={true}
						animationData={animationData}
						style={{ width: '100%' }}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default TransitionCalibration;
