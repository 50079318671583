import { Button, Card } from "antd"
import './style.css';
import { TOnBoardSymptomsProps } from "@stores/interfaces";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import FeatureCard from "./FeatureCard";
import { setPainStatusButton, wellCheckNess } from "@stores/onBoard/onBoard";

export interface FeatureProps {
  title: string;
  backgroundStyle: string;
  hoverBackgroundStyle: string;
  options: string[];
  imageSrc: string;
}

export default function PatientPainStatus(props: TOnBoardSymptomsProps) {
  const { setActiveStep, setProgressPercent } = props
  const { t } = useTranslation()
  const dispatch = useTypedDispatch();
  const user = useTypedSelector((state) => state.user)

  const features: FeatureProps[] = [
    {
      title: t('Patient.data.onboard.getScore'),
      backgroundStyle: 'linear-gradient(135deg, rgba(105, 65, 198, 0.9), rgba(84, 205, 242, 0.9))',
      hoverBackgroundStyle: 'linear-gradient(45deg, rgba(84, 205, 242, 0.9), rgba(105, 65, 198, 0.9))',
      options: [
        t('Patient.data.onboard.getScoreOption1'),
        t('Patient.data.onboard.getScoreOption2'),
        t('Patient.data.onboard.getScoreOption3'),
      ],
      imageSrc: '/images/dashboard/card-3.jpg',
    },
    {
      title:t('Patient.data.onboard.commitEarly'),
      backgroundStyle: 'linear-gradient(135deg, rgba(243, 33, 215, 0.9), rgba(255, 236, 97, 0.9))',
      hoverBackgroundStyle: 'linear-gradient(45deg, rgba(255, 236, 97, 0.9), rgba(243, 33, 215, 0.9))',
      options: [
        t('Patient.data.onboard.commitEarlyOption1'),
        t('Patient.data.onboard.commitEarlyOption2'),
        t('Patient.data.onboard.commitEarlyOption3'),
      ],
      imageSrc: '/images/dashboard/card-2.jpg',
    },
    {
      title: t('Patient.data.onboard.recoverSupport'),
      backgroundStyle: 'linear-gradient(135deg, rgba(154, 78, 255, 0.9), rgba(36, 255, 114, 0.9))',
      hoverBackgroundStyle: 'linear-gradient(45deg, rgba(36, 255, 114, 0.9), rgba(154, 78, 255, 0.9))',
      options: [
        t('Patient.data.onboard.recoverSupportOption1'),
        t('Patient.data.onboard.recoverSupportOption2'),
        t('Patient.data.onboard.recoverSupportOption3'),
        t('Patient.data.onboard.recoverSupportOption4'),
        t('Patient.data.onboard.recoverSupportOption5'),
        t('Patient.data.onboard.recoverSupportOption6'),
      ],
      imageSrc: '/images/dashboard/card-1.jpg',
    }
  ];


  return (
    <>
      <div className="container mx-auto mt-4">
        <div className="whole-day-header-container mb-10">
          <h1 className="whole-day-header-title">{t('Patient.data.onboard.choosePath')}</h1>
          <h3 className="whole-day-header-subtitle">
            {t('Patient.data.onboard.selectCategory')}
          </h3>
        </div>
        {features.length > 1 ? (
          <>
            <div className="flex justify-center space-x-5">
              {features.map((feature, index) => (
                <FeatureCard setActiveStep={setActiveStep} setProgressPercent={setProgressPercent} key={index} {...feature} />
              ))}
            </div>
            <div className="pain-subcontainer">
              <Button className="mt-4 mb-4 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6" onClick={() => { setActiveStep(1) }} size="large" type="primary" block style={{ height: '43px', width: "230px" }}>
                {t('Patient.data.onboard.back')}
              </Button>
            </div>
          </>
        ) : features.length === 1 &&
        <div className="flex flex-col items-center justify-center">
          <div
            className="flex justify-center space-x-5 mb-10"
            style={{ width: "80%" }}
          >
            <Card className="feature-card" style={{ width: "80%" }}>
              <div className="select-none">
                <div className="h-auto" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <div className="flex h-auto">
                    <img src={features[0].imageSrc} alt="" className="object-cover w-[50%] h-[600px]" />
                    <div className="w-[50%] flex items-center justify-center flex-col p-4" style={{
                      backgroundImage: "url('/images/dashboard/card-bg.png')",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}>
                      <div className="flex justify-center items-center text-white font-bold p-1 gap-15">
                        <h3 className="font-bold text-white mt-2 text-[22px] text-center">{features[0].title}</h3>
                      </div>

                      <div className="overflow-hidden sm:min-h-[150px] md:min-h-[180px] lg:min-h-[118px]">
                        {features[0]?.options.map((option, idx) => (
                          <div className="text-white font-inter text-[15px] font-light leading-[18.15px] text-center mb-1 mr-1 ml-1 p-1 text-base">
                            <p
                              key={idx}
                              className="bg-[#6941C666] text-white text-center px-4 py-2 rounded-lg"
                              style={{ opacity: 0.9 }}
                            >
                              {option}
                            </p>
                          </div>))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="whole-day-btn-container mt-0">
            <Button
              className="bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6" onClick={() => { setActiveStep(1) }} size="large" type="primary" block style={{ height: '43px', width: "230px" }}>
              {t("Patient.data.onboard.back")}
            </Button>

            <Button size="large" type="primary" className="rounded-full font-inter text-base font-bold leading-6" block style={{ height: '43px', background: "#6941C6", color: "#fff", width: "230px" }}
              onClick={async () => {
                if (features[0]?.options?.length <= 3) {
                  setProgressPercent(50);
                  setActiveStep(5);
                  dispatch(setPainStatusButton('noPain'));
                  const params = { userId: user?.id, isWellCheckNess: true }
                  await dispatch(wellCheckNess(params))
                } else {
                  setProgressPercent(30);
                  setActiveStep(3);
                  dispatch(setPainStatusButton(''));
                  const params = { userId: user?.id, isWellCheckNess: false }
                  await dispatch(wellCheckNess(params))
                }
              }}
            >
              {t("Patient.data.onboard.next")}
            </Button>
          </div>
        </div>
        }
      </div>
    </>
  );
}