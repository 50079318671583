import { User01 } from '@carespace-icons/users/user01';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	Button,
	Checkbox,
	DatePicker,
	Form,
	Input,
	message,
	Select,
	Spin,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Ruler } from '@carespace-icons/education/ruler';
import { Speedometer01 } from '@carespace-icons/general/speedometer01';
import { useEffect, useState } from 'react';
import { UseAuth } from '@contexts/AuthContext';
import { getUserById } from '@stores/contacts/contacts';
import './style.css';
import ConsentPolicyModal from './ConsentPolicyModal';
import { IUser, TOnBoardSymptomsProps } from '@stores/interfaces';
import { saveProfileDetails } from '@stores/onBoard/onBoard';
import { measurementTypes } from '../Constants';
import { DEFAULT_FORM_DATA, MONTHS } from '@stores/constants';
import { Phone01 } from '@carespace-icons/media/phone01';

const { Option } = Select;

export default function OnboardProfileForm(props: TOnBoardSymptomsProps) {
	const { setActiveStep, setProgressPercent } = props;
	const userId = useTypedSelector(state => state.user.id);
	const [user, setUser] = useState<IUser>();
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const defaultFormData = DEFAULT_FORM_DATA
	const [userFormData, setUserFormData] = useState(defaultFormData);
	const [policyModalOpen, setPolicyModalOpen] = useState(false);
	const [errors, setErrors] = useState(defaultFormData);
	const [isLoading, setLoading] = useState(true);
	const [feet, setFeet] = useState(0);
	const [inch, setInch] = useState(0);
	const [unit, setUnit] = useState('');
	const currentYear = dayjs().year();
	const currentMonth = dayjs().month() + 1;
	const currentDay = dayjs().date();
	const [dob, setDob] = useState({
		day: userFormData.birthDate ? dayjs(userFormData.birthDate).date() : '',
		month: userFormData.birthDate
			? dayjs(userFormData.birthDate).month() + 1
			: '',
		year: userFormData.birthDate ? dayjs(userFormData.birthDate).year() : '',
	});
	const years = [...Array(100).keys()]
		.map(i => ({
			value: currentYear - i,
			label: currentYear - i,
		}))
		.filter(y => y.value <= currentYear);

	const months = MONTHS.filter(m => dob.year < currentYear || m.value <= currentMonth);

	const [days, setDays] = useState(
		[...Array(31).keys()].map(i => ({ value: i + 1, label: i + 1 })),
	);
	

	useEffect(() => {
		setDob({
			day: userFormData.birthDate ? dayjs(userFormData.birthDate).date() : '',
			month: userFormData.birthDate
				? dayjs(userFormData.birthDate).month() + 1
				: '',
			year: userFormData.birthDate ? dayjs(userFormData.birthDate).year() : '',
		});
	}, [userFormData]);

	const handleChange = (field: string, value: string | number) => {
		const updatedDob = { ...dob, [field]: value };
		setDob(updatedDob);

		if (updatedDob.day && updatedDob.month && updatedDob.year) {
			const formattedDate = `${updatedDob.year}-${String(updatedDob.month).padStart(2, '0')}-${String(updatedDob.day).padStart(2, '0')}`;
			handleOnChangeForm('birthDate', formattedDate);
		}
	};

	useEffect(() => {
		if (dob.month && dob.year) {
			const daysInMonth = dayjs(`${dob.year}-${dob.month}-01`).daysInMonth();
			const maxDay =
				dob.year === currentYear && dob.month === currentMonth
					? currentDay
					: daysInMonth;

			setDays(
				[...Array(maxDay).keys()].map(i => ({
					value: i + 1,
					label: i + 1,
				})),
			);

			if (dob.day > maxDay) {
				setDob(prev => ({ ...prev, day: '' }));
			}
		}
	}, [dob.month, dob.year]);

	useEffect(() => {
		getData();
	}, [userId]);
	const getData = async () => {
		if (userId) {
			const data = await dispatch(getUserById(userId));
			setUser(data.payload);
			setUserFormData({
				firstName: data.payload.profile?.firstName,
				lastName: data.payload.profile?.lastName,
				email: data.payload.profile?.email,
				mobilePhone: data.payload.profile?.mobilePhone,
				imageUrl: data.payload.profile?.imageUrl,
				birthDate: data.payload.profile?.birthDate,
				gender: data.payload.profile?.gender,
				imperialHeight: data.payload.profile?.imperialHeight,
				metricHeight: data.payload.profile?.metricHeight,
				imperialWeight:
					data.payload.profile?.weight &&
					data.payload.profile?.weight[0]?.imperialWeight,
				metricWeight:
					data.payload.profile?.weight &&
					data.payload.profile?.weight[0]?.metricWeight,
				isPregnant: false,
				consentPolicyRead: data.payload.profile?.consentPolicyRead,
				patientId: data.payload.profile?.patientId,
				measurementSystem: data.payload.profile?.measurementSystem,
			});
			if (data.payload.profile?.imperialHeight) {
				const [feetPart, inchPart] = data.payload?.profile?.imperialHeight
					.toString()
					.split('.');
				setFeet(Number(feetPart) || 0);
				setInch(parseInt(inchPart));
			}
			setUnit(
				data.payload.profile?.measurementSystem === measurementTypes.METRIC
					? measurementTypes.METRIC
					: measurementTypes.IMPERIAL,
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		setUserFormData(prev => ({
			...prev,
			imperialHeight: parseFloat(`${feet}.${inch}`),
			measurementSystem: measurementTypes.IMPERIAL,
		}));
	}, [feet, inch]);

	const handleToggle = () => {
		setPolicyModalOpen(true);
	};

	const validateForm = () => {
		const newErrors: any = {};
		const phoneRegex = /^\d{10}$/; 
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		if (!userFormData.firstName) {
			newErrors.firstName = t('Patient.data.completeProfile.firstNameErr');
		}
		if (!userFormData.lastName) {
			newErrors.lastName = t('Patient.data.completeProfile.lastNameErr');
		}
		if (!userFormData.birthDate) {
			newErrors.birthDate = t('Patient.data.completeProfile.dobError');
		}
		if (!userFormData.gender) {
			newErrors.gender = t('Patient.data.completeProfile.genderErr');
		}
		if (!userFormData.consentPolicyRead) {
			newErrors.consentPolicyRead = t('Patient.data.completeProfile.youAgree');
		}
		if (
			!userFormData.metricHeight &&
			!userFormData.imperialHeight &&
			!feet &&
			!inch
		) {
			newErrors.height = t('Patient.data.completeProfile.heightErr');
		} else if (
			(userFormData.metricHeight && userFormData.metricHeight <= 0) ||
			(userFormData.imperialHeight && userFormData.imperialHeight <= 0)
		) {
			newErrors.height = t('Patient.data.completeProfile.heightPositive');
		} else if (
			userFormData.imperialHeight &&
			userFormData.imperialHeight > 0 &&
			(feet <= 0 || inch < 0)
		) {
			newErrors.height = t('Patient.data.completeProfile.feetInchErr');
		}
		if (!userFormData.metricWeight && !userFormData.imperialWeight) {
			newErrors.weight = t('Patient.data.completeProfile.weightErr');
		} else if (
			(userFormData.metricWeight && userFormData.metricWeight <= 0) ||
			(userFormData.imperialWeight && userFormData.imperialWeight <= 0)
		) {
			newErrors.weight = t('Patient.data.completeProfile.weightPositive');
		}
		if (!userFormData.email || !emailRegex.test(userFormData.email)) {
			newErrors.email = t('Admin.data.menu.userRoles.invitePatientModal.emailRequired');
		}
		if (userFormData.mobilePhone && !phoneRegex.test(userFormData.mobilePhone)) {
			newErrors.mobilePhone = t('Patient.data.completeProfile.mobileErr');
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleFormSubmit = async () => {
		if (!validateForm()) {
			message.error(t('Patient.data.completeProfile.pleaseCorrect'));
			return;
		} else {
			try {
				let payload: any = {
					firstName: userFormData.firstName,
					lastName: userFormData.lastName,
					birthDate: userFormData.birthDate,
					gender: userFormData.gender,
					isPregnant: false,
					consentPolicyRead: userFormData.consentPolicyRead,
					measurementSystem: userFormData.measurementSystem,
					email: userFormData.email,
					mobilePhone: userFormData.mobilePhone
				};
				if (userFormData.measurementSystem === measurementTypes.IMPERIAL) {
					payload.imperialHeight = userFormData.imperialHeight;
					payload.imperialWeight = userFormData.imperialWeight;
				} else {
					payload.metricHeight = userFormData.metricHeight;
					payload.metricWeight = userFormData.metricWeight;
				}
				const data = await dispatch(saveProfileDetails({ id: user?.id, payload: payload }));
				if (data.payload) {
					message.success(`${t('Patient.data.completeProfile.saveSuccess')}`);
					setProgressPercent(20);
					setActiveStep!(2);
				}
			} catch (error: any) {
				message.error(`${t('Patient.data.completeProfile.saveError 2nd block')}`);
			}
		}
	};

	const handleHeightChange = (unit: string, value) => {
		setUnit(
			unit === measurementTypes.CENTIMETER
				? measurementTypes.METRIC
				: measurementTypes.IMPERIAL,
		);
		if (unit === measurementTypes.CENTIMETER) {
			setUserFormData(prev => ({
				...prev,
				metricHeight: value,
				measurementSystem: measurementTypes.METRIC,
			}));
		} else {
			setUserFormData(prev => ({
				...prev,
				imperialHeight: parseFloat(`${feet}.${inch}`),
				measurementSystem: measurementTypes.IMPERIAL,
			}));
		}
		setErrors(prevErrors => ({ ...prevErrors, height: undefined }));
	};

	const handleWeightChange = unit => {
		setUnit(
			unit === measurementTypes.KILOGRAM
				? measurementTypes.METRIC
				: measurementTypes.IMPERIAL,
		);

		setUserFormData(prev => ({
			...prev,
			measurementSystem:
				unit === measurementTypes.KILOGRAM
					? measurementTypes.METRIC
					: measurementTypes.IMPERIAL,
		}));
		setErrors(prevErrors => ({ ...prevErrors, weight: undefined }));
	};

	const handleOnChangeForm = (
		key: string,
		value: string | number | boolean | null,
	) => {
		setUserFormData(prevData => ({ ...prevData, [key]: value }));
		setErrors(prevErrors => ({ ...prevErrors, [key]: undefined }));
	};

	if (isLoading) {
		return <Spin className="spin-css" tip="Loading" size="large" />;
	}

	return (
		<div style={{ textAlign: 'center' }} className="onboard-profile-form">
			<div className="form-div">
				<h1 style={{ fontSize: '25px', color: '#6941C6', fontWeight: '700' }}>
					{t('Patient.data.onboard.completeProfile')}
				</h1>
				<h3 style={{ fontSize: '18px', color: '#98A2B3' }}>
					{t('Patient.data.onboard.addPersonal')}
				</h3>
				<div className="form-container">
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
						<Input
							style={{
								height: '2.75rem',
							}}
							prefix={<User01 width={13} height={15} color="stroke-gray-600" />}
							placeholder={t('Patient.data.completeProfile.firstName')}
							value={userFormData.firstName}
							onChange={event =>
								handleOnChangeForm('firstName', event.target.value)
							}
						/>
						{errors.firstName && (
							<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
								{errors.firstName}
							</div>
						)}
					</div>
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
						<Input
							style={{
								height: '2.75rem',
							}}
							prefix={<User01 width={13} height={15} color="stroke-gray-600" />}
							placeholder={t('Patient.data.completeProfile.lastName')}
							value={userFormData.lastName}
							onChange={event =>
								handleOnChangeForm('lastName', event.target.value)
							}
						/>
						{errors.lastName && (
							<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
								{errors.lastName}
							</div>
						)}
					</div>
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
												<Input
													className="input-item"
													prefix={
														<User01 width={13} height={15} color="stroke-gray-500" />
													}
													placeholder={t('Admin.data.menu.userRoles.invitePatientModal.emailText')}
													value={userFormData.email}
													onChange={event =>
														handleOnChangeForm('email', event.target.value)
													}
												/>
												{errors.email && (
													<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
														{errors.email}
													</div>
												)}
											</div>
											<div style={{ textAlign: 'left', marginBottom: '16px' }}>
												<Input
													className="input-item"
													type='number'
													prefix={
														<Phone01 width={13} height={15} color="stroke-gray-500" />
													}
													placeholder={t('Admin.data.menu.userRoles.invitePatientModal.mobilePhone')}
													value={userFormData.mobilePhone}
													onChange={event =>
														handleOnChangeForm('mobilePhone', event.target.value)
													}
												/>
												{errors.mobilePhone && (
													<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
														{errors.mobilePhone}
													</div>
												)}
						</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							marginBottom: '16px',
						}}>
						<div className="flex gap-2">
							<Select
								showSearch
								popupMatchSelectWidth={false}
								placeholder={t('Patient.data.completeProfile.date')}
								style={{
									width: '120px',
									height: '2.75rem',
									textAlign: 'start',
								}}
								value={dob.day || undefined}
								onChange={value => handleChange('day', value)}
								options={days}
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toString()
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							/>
							<Select
								showSearch
								popupMatchSelectWidth={false}
								placeholder={t('Patient.data.completeProfile.month')}
								style={{
									width: '150px',
									height: '2.75rem',
									textAlign: 'start',
								}}
								value={dob.month || undefined}
								onChange={value => handleChange('month', value)}
								options={months}
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toString()
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							/>
							<Select
								showSearch
								popupMatchSelectWidth={false}
								placeholder={t('Patient.data.completeProfile.year')}
								style={{
									width: '150px',
									height: '2.75rem',
									textAlign: 'start',
								}}
								value={dob.year || undefined}
								onChange={value => handleChange('year', value)}
								options={years}
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toString()
										.toLowerCase()
										.includes(input.toLowerCase())
								}
							/>
						</div>
						{errors.birthDate && (
							<div
								style={{
									color: '#ff4d4f',
									fontSize: '12px',
									textAlign: 'start',
								}}>
								{errors.birthDate}
							</div>
						)}
					</div>
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
						<div
							className="weight-input-container"
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '2.75rem',
							}}>
							<Input
								type="number"
								prefix={
									<Speedometer01
										width={13}
										height={15}
										color="stroke-gray-600"
									/>
								}
								placeholder={t('Patient.data.completeProfile.weight')}
								className="border-r-0 rounded-tr-none rounded-br-none"
								value={
									unit === measurementTypes.METRIC
										? userFormData.metricWeight
										: userFormData.imperialWeight || ''
								}
								onChange={e =>
									handleOnChangeForm(
										unit === measurementTypes.METRIC
											? 'metricWeight'
											: 'imperialWeight',
										parseFloat(e.target.value) || null,
									)
								}
								style={{ flex: 1, padding: '8px', height: '2.75rem' }}
							/>
							<select
								className="border border-l-0 rounded-tl-none rounded-bl-none rounded-[6px]"
								value={unit === measurementTypes.METRIC ? 'Kg' : 'Lb'}
								onChange={e => handleWeightChange(e.target.value)}
								style={{
									width: 50,
									height: 46,
								}}>
								<option value="Lb">Lb</option>
								<option value="Kg">Kg</option>
							</select>
						</div>
						{errors.weight && (
							<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
								{errors.weight}
							</div>
						)}
					</div>

					{unit === measurementTypes.METRIC ? (
						<div style={{ textAlign: 'left', marginBottom: '16px' }}>
							<div
								className="weight-input-container"
								style={{
									display: 'flex',
									alignItems: 'center',
									height: '2.75rem',
								}}>
								<Input
									type="number"
									prefix={
										<Ruler width={13} height={15} color="stroke-gray-600" />
									}
									className="border-r-0 rounded-tr-none rounded-br-none"
									placeholder={t('Patient.data.completeProfile.height')}
									value={userFormData.metricHeight || ''}
									onChange={e =>
										handleHeightChange(
											measurementTypes.CENTIMETER,
											parseFloat(e.target.value) || null,
										)
									}
									style={{ flex: 1, padding: '8px', height: '2.75rem' }}
								/>
								<select
									value={measurementTypes.CENTIMETER}
									onChange={e =>
										handleHeightChange(
											e.target.value,
											userFormData.imperialHeight,
										)
									}
									className="border border-l-0 rounded-tl-none rounded-bl-none rounded-[6px]"
									style={{
										width: 55,
										height: 46,
									}}>
									<option value={measurementTypes.FEET}>
										{measurementTypes.FEET}
									</option>
									<option value={measurementTypes.CENTIMETER}>
										{measurementTypes.CENTIMETER}
									</option>
								</select>
							</div>
							{errors.height && (
								<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
									{errors.height}
								</div>
							)}
						</div>
					) : (
						<div style={{ textAlign: 'left', marginBottom: '16px' }}>
							<div
								className="weight-input-container"
								style={{
									display: 'flex',
									alignItems: 'center',
									height: '2.75rem',
								}}>
								<Input
									type="number"
									prefix={
										<Ruler width={13} height={15} color="stroke-gray-600" />
									}
									placeholder={t('Patient.data.completeProfile.height')}
									value={feet || ''}
									onChange={e => {
										setFeet(parseInt(e.target.value));
									}}
									style={{ flex: 1, padding: '8px', height: '2.75rem' }}
									suffix={
										<div className="flex gap-4">
											<p>{measurementTypes.FEET}</p>
											<p className="text-gray-400">|</p>
										</div>
									}
									className="border-r-0 rounded-tr-none rounded-br-none"
								/>
								<Input
									type="number"
									prefix={
										<Ruler width={13} height={15} color="stroke-gray-600" />
									}
									placeholder={t('Patient.data.completeProfile.height')}
									value={inch || ''}
									onChange={e => {
										setInch(parseInt(e.target.value));
									}}
									style={{ flex: 1, padding: '8px', height: '2.75rem' }}
									className="border-r-0 border-l-0 rounded-r-none rounded-l-none "
								/>
								<>
									<select
										value={measurementTypes.FEET}
										className="border-l-0 rounded-tl-none rounded-bl-none border-inherit rounded-[6px]"
										onChange={e => {
											handleHeightChange(
												e.target.value,
												userFormData.metricHeight,
											);
										}}
										style={{
											width: 50,
											height: 44,
										}}>
										<option value={measurementTypes.FEET}>
											{measurementTypes.INCH}
										</option>
										<option value={measurementTypes.CENTIMETER}>
											{measurementTypes.CENTIMETER}
										</option>
									</select>
								</>
							</div>
							{errors.height && (
								<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
									{errors.height}
								</div>
							)}
						</div>
					)}
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
						<Select
							placeholder={t('Patient.data.completeProfile.gender')}
							value={userFormData.gender}
							onChange={event => handleOnChangeForm('gender', event)}
							allowClear
							style={{ width: '100%', height: '2.75rem' }}>
							<Option value="Male">
								{t('Patient.data.completeProfile.male')}
							</Option>
							<Option value="Female">
								{t('Patient.data.completeProfile.female')}
							</Option>
							<Option value="Other">
								{t('Patient.data.completeProfile.other')}
							</Option>
						</Select>
						{errors.gender && (
							<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
								{errors.gender}
							</div>
						)}
					</div>
					<div style={{ textAlign: 'left', marginBottom: '16px' }}>
						<Checkbox
							checked={userFormData.consentPolicyRead}
							onChange={event =>
								handleOnChangeForm('consentPolicyRead', event.target.checked)
							}>
							<span className="checkbox-form">
								<span style={{ color: '#6941C6', cursor: 'pointer' }}>
									{t('Patient.data.completeProfile.readAgree')}
								</span>{' '}
								<span
									className="gradient-text underline consent-form-title"
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
										handleToggle();
									}}
									style={{
										textDecorationColor: '#6941C6',
										textDecorationThickness: '2px',
										cursor: 'pointer',
									}}>
									{t('Patient.data.completeProfile.consentPolicy')}.
								</span>
							</span>
						</Checkbox>
						{errors.consentPolicyRead && (
							<div style={{ color: '#ff4d4f', fontSize: '12px' }}>
								{errors.consentPolicyRead}
							</div>
						)}
					</div>
				</div>
				<div style={{ marginTop: '20px', marginBottom: '20px' }}>
					<Button
						size="large"
						htmlType="button"
						type="primary"
						block
						className="rounded-full font-inter text-base font-bold leading-6"
						style={{ height: '43px', background: '#6941C6', color: '#fff' }}
						onClick={handleFormSubmit}>
						{t('Patient.data.onboard.save')}
					</Button>
				</div>
			</div>
			{policyModalOpen && (
				<ConsentPolicyModal
					policyModalOpen={policyModalOpen}
					setPolicyModalOpen={setPolicyModalOpen}
				/>
			)}
		</div>
	);
}
