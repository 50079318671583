import { showCustomModal } from '@atoms/ACustomModalInfo';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ICustomRomTemplate, IPaginationPluginDefaultResponse, IRomTemplateExercise } from '@stores/interfaces';
import { getRomTemplates } from '@stores/rom/customRom';
import { Collapse, Empty, Input, Pagination, Spin } from 'antd';
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

interface IRomMyTemplate {
  extra: (data: ICustomRomTemplate) => ReactNode
  refresh: boolean;
}

interface IMyTemplateRom {
  data: ICustomRomTemplate[];
  pagination: IPaginationPluginDefaultResponse;
}

const MyTemplate = (props: IRomMyTemplate) => {

  const { extra, refresh } = props

  const [myTemplateRom, setMyTemplateRom] = useState<IMyTemplateRom>()

  const { Panel } = Collapse;
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const user = useTypedSelector((state) => state.user);
  const { selectedUser } = useTypedSelector((state) => state.contacts.main);
  const dispatch = useTypedDispatch()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    getTemplate(1)
  }, [user, selectedUser, searchValue, refresh])

  const getTemplate = async (page: number) => {
    setLoading(true)
    const data = await dispatch(getRomTemplates({ page: page, search: searchValue }))
    setMyTemplateRom(data?.payload)
    setLoading(false)
  }

  const onPageChange = (pageNumber: number) => {
    getTemplate(pageNumber);
  }

  return (
    <>
      <Input
        className='w-full my-3 h-10'
        placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        suffix={<SearchLg height={15} width={15} color='stroke-black' />}
      />
      {isLoading ? <Spin className="spinner" tip="Loading" size="large" /> : <>
        {myTemplateRom === undefined || myTemplateRom?.data.length === 0 ? (
          <Empty
            className="bg-gray-50 p-10 m-0"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="text-gray-300">
                {t('Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram')}
              </span>
            }
          />
        ) : (
          <div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto" }}>
            <Collapse className="select-none p-4 rounded-md bg-gray-50 hover:!bg-gray-50 flex flex-col gap-2" bordered={false}>
              {
                myTemplateRom?.data?.map((data: ICustomRomTemplate) => {
                  const sortedExercises = [...(data?.exercises || [])].sort((a, b) => a.order - b.order);
                  return <Panel
                    className='header-panel !w-full bg-white !border !border-gray-200 !rounded-xl'
                    key={""}
                    header={<p className="text-sm font-semibold text-gray-600">{data?.title}</p>}
                    extra={extra(data)}
                  >
                    {
                      sortedExercises?.map((item) => {
                        const image = item?.strapiOmniRomExercise?.image?.url
                        const video = item?.strapiOmniRomExercise ? item?.strapiOmniRomExercise?.video?.url : item?.exerciseLibrary ? item?.exerciseLibrary?.video : item?.video
                        const name = item?.strapiOmniRomExercise ? item?.strapiOmniRomExercise?.name : item?.exerciseLibrary ? item?.exerciseLibrary?.title : item?.name
                        const description = item?.strapiOmniRomExercise ? item?.strapiOmniRomExercise?.description : item?.exerciseLibrary ? item?.exerciseLibrary?.description : item?.description
                        return (
                          <div className="px-4 py-2">
                            <div className='flex cursor-pointer gap-4 p-4 rounded-lg border-2 border-inherit w-full items-center justify-center' key={item?.name}>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showCustomModal({
                                    video: video,
                                    name: name,
                                    description: description,
                                  })
                                }}
                                className={`custom-image-container`}>
                                <div className="w-40 flex-shrink-0 image-wrapper">
                                  {image ?
                                    <img
                                      className="video"
                                      src={image}
                                      width="100%"
                                      height="100%"
                                    /> :
                                    <video
                                      className="video"
                                      preload="metadata"
                                      src={video}
                                      width="100%"
                                      height="100%"
                                    />}
                                  <div className="play-button">
                                    <PlayCircle width={50} height={50} />
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1 rounded-lg p-2">
                                <p className="text-gray-600 font-semibold text-lg">{name}</p>

                                <p className="text-gray-400 font-semibold text-xs">{t('Patient.data.omnirom.transitionTime')} : {item?.transitionTime}</p>

                                <p className="text-gray-600 mt-2">{description}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Panel>
                })
              }
            </Collapse></div>)}
        {!isLoading && <div>
          <Pagination
            className='createProgramModalPagination flex justify-center'
            current={myTemplateRom?.pagination?.currentPage}
            showSizeChanger={false}
            onChange={onPageChange}
            total={myTemplateRom?.pagination?.totalCount}
          />
        </div>}
      </>}
    </>
  )
}

export default MyTemplate