import { memo, useCallback, useEffect, useRef } from 'react';
import { dashArray, videoSize } from '../constants';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import EventEmitter from '@services/EventEmitter';
import { UseTransition } from '../context/Transition.context';
import { PosturalAnalytics, UseControls } from '../context/Controls.context';
import { UseFullScreen } from '../context/FullScreen.context';

function drawDashedLineVertical(
	ctx: CanvasRenderingContext2D,
	color: 'red' | 'green',
	x: number,
) {
	ctx.strokeStyle = color;
	ctx.lineWidth = 2;
	ctx.beginPath();
	ctx.setLineDash(dashArray);
	ctx.moveTo(x, 0);
	ctx.lineTo(x, videoSize.height);
	ctx.stroke();
}

function drawDashedLineHorizontal(
	ctx: CanvasRenderingContext2D,
	landmark: NormalizedLandmark,
) {
	const y = landmark.y * videoSize.height;

	ctx.strokeStyle = '#9747FF';
	ctx.lineWidth = 2;
	ctx.setLineDash(dashArray);
	ctx.beginPath();
	ctx.moveTo(0, y);
	ctx.lineTo(videoSize.width, y);
	ctx.stroke();
}

function Lines() {
	const canvasLinesRef = useRef<HTMLCanvasElement>(null);
	const { onGetCurrent } = UseControls();
	const { transition } = UseTransition();
	const { isFullScreen } = UseFullScreen();

	const current = onGetCurrent() as Partial<PosturalAnalytics>;

	const frameCallback = useCallback(
		(results: NormalizedLandmark[]) => {
			const canvasLinesCtx = canvasLinesRef.current?.getContext(
				'2d',
			) as CanvasRenderingContext2D;

			const width: number = videoSize.width;
			const height: number = videoSize.height;

			canvasLinesCtx.save();
			canvasLinesCtx.clearRect(0, 0, width, height);

			if (current?.view === 'left' || current?.view === 'right') {
				// vertical base line ankle
				drawDashedLineVertical(canvasLinesCtx, 'red', results[28].x * width);
			} else {
				// vertical nariz
				drawDashedLineVertical(canvasLinesCtx, 'red', results[0].x * width);

				// vertical middle shoulder and hip
				drawDashedLineVertical(
					canvasLinesCtx,
					'green',
					((results[12].x + results[11].x) * width) / 2,
				);

				// ear
				drawDashedLineHorizontal(canvasLinesCtx, results[8]);

				// shoulder
				drawDashedLineHorizontal(canvasLinesCtx, results[12]);

				// elbow
				drawDashedLineHorizontal(canvasLinesCtx, results[14]);

				// hip
				drawDashedLineHorizontal(canvasLinesCtx, results[24]);

				// knee
				drawDashedLineHorizontal(canvasLinesCtx, results[26]);

				// ankle
				drawDashedLineHorizontal(canvasLinesCtx, results[28]);
			}

			canvasLinesCtx.restore();
		},
		[canvasLinesRef, current],
	);

	useEffect(() => {
		EventEmitter.on('results', results => {
			frameCallback(results);
		});

		return () => {
			EventEmitter.removeListener('results');
		};
	}, [current, frameCallback, transition]);

	return (
		<canvas
			id="canvasLines"
			ref={canvasLinesRef}
			width={videoSize.width}
			height={videoSize.height}
			style={{
				maxWidth: isFullScreen ? '100%' : 1280,
				height: isFullScreen ? '100%' : 720,
				aspectRatio: '16/9',
				width: '100%',
				zIndex: 5,
				position: 'absolute',
				pointerEvents: 'none',
				top: 0,
				left: 0,
			}}
		/>
	);
}

export default memo(Lines);
