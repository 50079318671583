import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import Strapi from './Strapi'
import { getUserInfoFromLocalStorage } from '@stores/user';


const backendOptions = {
  loadPath: "/frontend-translation?populate=Patient,Admin",
  request: async (options: any, url: any, payload: any, callback: any) => {
    try {
      const { data } = await Strapi.get('/i18n/locales');
      const defaultLn = data.find(ln => ln.isDefault);
      const { preferredLanguages } = getUserInfoFromLocalStorage();
      let ln = '&locale=' + defaultLn.code;

      if (Array.isArray(preferredLanguages)) {
        const userLanguage = preferredLanguages[0];
        const hasUserLanguage = data.some(ln => ln.code === userLanguage)
        if (hasUserLanguage)
          ln = '&locale=' + userLanguage
      }

      const { data: translation } = await Strapi.get(`${url}${ln}`);
      const { data: { attributes } } = translation

      callback(null, {
        data: attributes,
        status: 200,
      });

    } catch (e) {
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: false,
    backend: backendOptions,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
