import { FaceNeutral } from "@carespace-icons/users/faceNeutral"
import { FaceSad } from "@carespace-icons/users/faceSad"
import { FaceSmile } from "@carespace-icons/users/faceSmile"
import { Button } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"

interface OverallConditionProps {
	activeStep: number,
	setActiveStep: (activeStep: number) => void,
	setOverallCondition: (overallCondition: string) => void
	savedVoice: string
	painLevel: number
	setSavedVoice: (savedVoice: string) => void
}

export const OverallCondition = (props: OverallConditionProps) => {
	const { t } = useTranslation()
	const { activeStep, setActiveStep, setOverallCondition, savedVoice, painLevel, setSavedVoice } = props

	useEffect(() => {
		if (activeStep === 2 && painLevel < 8) {
			if (savedVoice.toLowerCase().includes("improving") || savedVoice.toLowerCase().includes("impr") || savedVoice.toLowerCase().includes("imp")) {
				setOverallCondition("improving");
				setActiveStep(activeStep + 1);
			} else if (savedVoice.toLowerCase().includes("change") || savedVoice.toLowerCase() === "no" || savedVoice.toLowerCase() === "no change") {
				setSavedVoice('')
				setOverallCondition("noChange");
				setActiveStep(activeStep + 1);
			} else if (savedVoice.toLowerCase().includes("worsening") || savedVoice.toLowerCase().includes("wor") || savedVoice.toLowerCase().includes("war") || savedVoice.toLowerCase().includes("as") || savedVoice.toLowerCase().includes("or") || savedVoice.toLowerCase().includes("vor")) {
				setOverallCondition("worsening");
				setActiveStep(activeStep + 1);
			}
		}
	}, [savedVoice])

	const handleBackArrow = () => {
		setSavedVoice('');
		setActiveStep(activeStep - 1)
	}

	return <div className="select-none">
		<div className="mt-[130px] mb-[15px] w-[468px] h-[28px] flex justify-between items-center">
			<Button
				className="!rounded-full flex justify-center items-center"
				onClick={() => handleBackArrow()}
				icon={<FaArrowLeft />}
			/>
			<p className="text-white text-lg font-semibold text-center m-auto">{t('Admin.data.rehab.rehabPreAssessment.overallCondition')}</p>
		</div>
		<div className="w-[520px] h-[74px]">
			<div className="flex items-center justify-between p-[15px]">
				<Button
					className="!w-36 !h-11 !bg-success-500 hover:!bg-success-700 border-2 !border-white !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
					icon={<FaceSmile width={20} height={20} />}
					onClick={() => {
						setOverallCondition("improving")
						setActiveStep(activeStep + 1)
					}}
				>
					{t('Admin.data.rehab.rehabPreAssessment.improving')}
				</Button>
				<Button
					className="!w-36 !h-11 !bg-warning-300 border-2 hover:!bg-warning-500 !border-white !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
					icon={<FaceNeutral width={20} height={20} />}
					onClick={() => {
						setOverallCondition("noChange")
						setActiveStep(activeStep + 1)
					}}
				>
					{t('Admin.data.rehab.rehabPreAssessment.noChange')}
				</Button>
				<Button
					className="!w-36 !h-11 !bg-rose-700 hover:!bg-rose-900 border-2 !border-white !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
					icon={<FaceSad width={20} height={20} />}
					onClick={() => {
						setOverallCondition("worsening")
						setActiveStep(activeStep + 1)
					}}
				>
					{t('Admin.data.rehab.rehabPreAssessment.worsening')}
				</Button>
			</div>
		</div>
	</div>
}