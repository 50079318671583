import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { ICoachRomInitialState, ICoachRomExercises, ICoachRomCoach } from '@stores/interfaces'

export const getCoach = createAsyncThunk(
	'getCoach',
	async (dataToRequest: string): Promise<ICoachRomCoach> => {
		const { data } = await axios.get(`/rom/patient-results/${dataToRequest}`)
		return data
	}
)

export const getExercises = createAsyncThunk(
	'getExercises',
	async (): Promise<ICoachRomExercises> => {
		const { data } = await axios.get(`/rom/exercises`)
		return data
	}
)

export const postResult = createAsyncThunk('postResult', async (assessment) => {
	const { data } = await axios.post(`/rom/assessments`, assessment)
	return data
})

const initialState: ICoachRomInitialState = {
	coach: null,
	exercises: null,
	result: [],
}

export const coachRom = createSlice({
	name: 'coachRom',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCoach.fulfilled, (state, action) => {
			state.coach = action.payload
		})
		builder.addCase(getCoach.rejected, (state, action) => {})

		builder.addCase(getExercises.fulfilled, (state, action) => {
			state.exercises = action.payload
		})
		builder.addCase(getExercises.rejected, (state, action) => {})

		builder.addCase(postResult.fulfilled, (state, action) => {
			const data = action.payload
			return {
				...state,
				result: { data, ...state.result },
			}
		})
		builder.addCase(postResult.rejected, (state, action) => {})
	},
})

export default coachRom.reducer
