import { Copy07 } from "@carespace-icons/general/copy07";
import { Download01 } from "@carespace-icons/general/download01";
import { Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface ReportHeaderProps {
  copyToClipboard: () => void
  downloadPdf2: () => void
  confirmPopUp: boolean
  handleSave: () => void
  setConfirmPopUp: (value: boolean) => void
}

export default function ReportHeader(props: ReportHeaderProps) {
  const {downloadPdf2, copyToClipboard, confirmPopUp, handleSave , setConfirmPopUp} = props
  const { t } = useTranslation();
  
  return (
    <div className="pr-7 flex justify-end">
    <Tooltip placement="topRight" title={t('Patient.data.myProgress.evaluation.download')} color="purple" showArrow={false}>
      <span className="cursor-pointer" onClick={() => downloadPdf2()}>
        <Download01 color="stroke-gray-400" width={20} height={20} />
      </span>
    </Tooltip>
    <Tooltip placement="topRight" title={t('Patient.data.myProgress.evaluation.copy')} color="purple" showArrow={false}>
      <span className="cursor-pointer pl-4" onClick={() => copyToClipboard()}>
        <Copy07 color="stroke-gray-400" width={20} height={20} />
      </span>
    </Tooltip>
    <Modal
      title={t('Admin.data.addToReports.confirmPopupStatus')}
      className='select-none'
      centered
      open={confirmPopUp}
      onOk={handleSave}
      onCancel={() => setConfirmPopUp(false)}
      okText="Yes"
      cancelText="No"
    ></Modal>
  </div>
  )
}
