import { Popover, Row, Col, Badge } from 'antd';
import ExerciseFilterSection from './ExerciseFilterSection';
import './style.css';
import { ChevronUp } from '@carespace-icons/arrows/chevronUp';
import { ChevronDown } from '@carespace-icons/arrows/chevronDown';
import { IProgramFilterOptions, TSideFilters } from '@stores/interfaces';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect, useState } from 'react';
import { setPopoverState } from '@stores/functionalGoals';
import { useTranslation } from 'react-i18next';

interface ExerciseFiltersProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	setFilterOptions: (value: IProgramFilterOptions) => void;
	handleApply: (value: number) => void;
	filterExercise: string;
	activeKey: string;
	setFilterExercise: (value: string) => void;
	filterOptions: {
		bodyRegionIds: [];
		exerciseCategoriesIds: [];
		jointsIds: [];
		functionalGoalsIds: [];
		sideIds: [];
	};
}

export const ExerciseFilters = ({
	filterExercise,
	filterOptions,
	activeKey,
	setFilterExercise,
	setFilterOptions,
	handleApply,
	open,
	setOpen,
}: ExerciseFiltersProps) => {
	const filterBodyRegion = useTypedSelector(
		state => state?.functionalGoals?.bodyRegions?.data,
	);
	const filterExerciseCategories = useTypedSelector(
		state => state.functionalGoals?.categories?.data,
	);
	const filterJoints = useTypedSelector(
		state => state.functionalGoals?.joints?.data,
	);
	const filterFunctionalGoals = useTypedSelector(
		state => state?.functionalGoals?.functionalGoals?.data,
	);
	const jointWithBodyRegion = useTypedSelector(
		state => state?.functionalGoals?.jointWithBodyRegion?.data,
	);
	const popoverState = useTypedSelector(
		state => state.functionalGoals.popoverState,
	);
	const { t } = useTranslation();
	const filterExercises = [
		{ id: '1', name: 'System Exercises' },
		{ id: '1', name: 'My Exercises' },
	];
	const filterTemplates = [
		{ id: '1', name: 'System Templates' },
		{ id: '2', name: 'My Templates' },
	];

	const filterSides: TSideFilters[] = [
		{
			id: '1',
			attributes: {
				name: t(
					'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.left',
				),
			},
		},
		{
			id: '2',
			attributes: {
				name: t(
					'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.right',
				),
			},
		},
		{
			id: '3',
			attributes: {
				name: t(
					'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.both',
				),
			},
		},
	];
	const dispatch = useTypedDispatch();
	const [totalOptionsCount, setTotalOptionsCount] = useState(0);
	const [disabledJoints, setDisabledJoints] = useState<number[]>([]);

	useEffect(() => {
		const selectedBodyRegionIds = filterOptions?.bodyRegionIds;
		let selectedJoints: number[] = [];
		if (selectedBodyRegionIds?.length > 0) {
			selectedJoints = jointWithBodyRegion
				?.filter(bodyRegion => selectedBodyRegionIds?.includes(bodyRegion?.id))
				?.flatMap(bodyRegion => bodyRegion?.joints?.map(joint => joint?.id));
		} else {
			selectedJoints = filterJoints?.map(joint => joint?.id);
		}
		const disabledJoints = filterJoints
			?.filter(joint => !selectedJoints?.includes(joint?.id))
			?.map(joint => joint?.id);
		setDisabledJoints(disabledJoints);
	}, [filterOptions.bodyRegionIds, jointWithBodyRegion, filterJoints]);

	const filterSections = [
		{
			key: 'bodyRegionIds',
			title: t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.region',
			),
			keyTitle: 'bodyRegionIds',
			options: filterBodyRegion,
		},
		{
			key: 'sideIds',
			title: t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.side',
			),
			keyTitle: 'sideIds',
			options: filterSides,
		},
		{
			key: 'jointsIds',
			title: t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.joint',
			),
			keyTitle: 'jointsIds',
			options: filterJoints,
		},
		{
			key: 'exerciseCategoriesIds',
			title: t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.category',
			),
			keyTitle: 'exerciseCategoriesIds',
			options: filterExerciseCategories,
		},
		{
			key: 'functionalGoalsIds',
			title: t(
				'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.functionalGoals',
			),
			keyTitle: 'functionalGoalsIds',
			options: filterFunctionalGoals,
		},
	];

	const handleOptionClick = (key: string, id: number) => {
		setFilterOptions((prevSelectedOptions) => {
			const currentOptions = prevSelectedOptions[key] || [];
			const isOptionSelected = currentOptions.includes(id);

			return {
				...prevSelectedOptions,
				[key]: isOptionSelected
					? currentOptions.filter((selectedId: number) => selectedId !== id)
					: [...currentOptions, id],
			};
		});
	};

	const handleReset = () => {
		setFilterOptions({
			bodyRegionIds: [],
			exerciseCategoriesIds: [],
			jointsIds: [],
			functionalGoalsIds: [],
			sideIds: [],
		});
	};

	useEffect(() => {
		const totalCount = Object.keys(filterOptions)?.reduce((count, key) => {
			let filteredOptions = filterOptions[key] || [];
			if (key === 'jointsIds') {
				filteredOptions = filteredOptions?.filter(
					(id: number) => !disabledJoints?.includes(id),
				);
			}
			return count + filteredOptions?.length;
		}, 0);
		setTotalOptionsCount(totalCount);
	}, [filterOptions, disabledJoints]);

	return (
		<div className="w-40">
			<Popover
				placement="bottomLeft"
				open={popoverState}
				content={
					<>
						<div className="w-full flex justify-end items-end h-7">
							{filterExercise !== 'My Exercises' &&
								filterExercise !== 'My Templates' && (
									<div
										className="w-20 cursor-pointer flex justify-center items-center rounded-lg  gap-2 text-primary 800 font-semibold p-2"
										onClick={handleReset}>
										{t(
											'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reset',
										)}
									</div>
								)}
						</div>
						<div className="exercise-filter w-[850px] h-[350px] overflow-x-auto gap-[10px]">
							{filterExercise !== 'My Exercises' &&
								filterExercise !== 'My Templates' && (
									<>
										<div className="flex">
											<Col span={12}>
												<ExerciseFilterSection
													key={filterSections[0].key}
													title={filterSections[0].title}
													keyTitle={filterSections[0].keyTitle!}
													options={filterSections[0].options}
													handleOptionClick={id =>
														handleOptionClick(filterSections[0].key, id)
													}
													filterOptions={filterOptions}
													setFilterOptions={setFilterOptions}
													disabledJoints={disabledJoints}
												/>
											</Col>
											<Col span={12}>
												<ExerciseFilterSection
													key={filterSections[1].key}
													title={filterSections[1].title}
													keyTitle={filterSections[1].keyTitle!}
													options={filterSections[1].options}
													handleOptionClick={id =>
														handleOptionClick(filterSections[1].key, id)
													}
													filterOptions={filterOptions}
													setFilterOptions={setFilterOptions}
													filterSides={filterSides}
												/>
											</Col>
										</div>
										{[2, 3, 4].map(index => (
											<Row key={filterSections[index].key}>
												<div>
													<ExerciseFilterSection
														title={filterSections[index].title}
														keyTitle={filterSections[index].keyTitle!}
														options={filterSections[index].options}
														handleOptionClick={id =>
															handleOptionClick(filterSections[index].key, id)
														}
														filterOptions={filterOptions}
														setFilterOptions={setFilterOptions}
														disabledJoints={disabledJoints}
													/>
												</div>
											</Row>
										))}
									</>
								)}
						</div>
						<>
							<div className="flex gap-2 justify-center items-center">
								<div
									className="mt-[10px] w-1/4 cursor-pointer bg-success-500 flex justify-center items-center rounded-lg gap-2 text-white font-semibold p-2 -mb-[3px]"
									onClick={() => {
										handleApply(1);
									}}>
									<p>
										{t(
											'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.apply',
										)}
									</p>
								</div>
								<div
									className="mt-[10px] w-1/4 cursor-pointer bg-primary-600 flex justify-center items-center rounded-lg gap-2 text-white font-semibold p-2 -mb-[3px]"
									onClick={() => {
										dispatch(setPopoverState(false))
										handleReset();
									}}>
									<p>
										{t(
											'Admin.data.menu.userRoles.pendingInvites.cancel',
										)}
									</p>
								</div>
							</div>
						</>
					</>
				}>
				<span
					onClick={() => {
						dispatch(setPopoverState(!popoverState));
					}}
					className="h-10 flex justify-between items-center text-gray-600">
					<p className="flex items-center justify-center gap-[2rem]">
						{t(
							'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.filters',
						)}{' '}
						{totalOptionsCount > 0 && (
							<Badge count={totalOptionsCount} color="#7F56D9" />
						)}
					</p>
					{popoverState ? (
						<ChevronUp width={20} height={20} color="stroke-gray-600" />
					) : (
						<ChevronDown width={20} height={20} color="stroke-gray-600" />
					)}
				</span>
			</Popover>
		</div>
	);
};
