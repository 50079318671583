import { Checkbox, DatePicker, Flex, Input, message, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	closestCorners,
} from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline } from 'react-icons/md';
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { AddExerciseItem } from './AddExerciseItem';
import { Check } from '@carespace-icons/general/check';
import { IAddProgramItemProps, IProgramExercise } from '@stores/interfaces';
import { Image03 } from '@carespace-icons/files/image03';
import { useRef, useState } from 'react';
import AddCoverImage from './AddCoverImage';

const { Option } = Select;

export default function AddProgramItem(props: IAddProgramItemProps) {
	const {
		selectedExercises,
		isSaveTemplateVisible,
		isSaveTemplate,
		setSelectedExercises,
		validateProgram,
		handleRemoveExercise,
		setSaveTemplate,
		SetLibraryModalVisible,
		programName,
		setProgramName,
		setProgramStartDate,
		programStartDate,
		duration,
		setDuration,
		setDurationType,
		durationType,
		programDescription,
		setProgramDescription,
		setImgFile,
		previewImage,
		setPreviewImage,
		previewUnSplashedImage,
		setPreviewUnSplashedImage,
	} = props;
	const { t } = useTranslation();
	const currentDate = dayjs().format('YYYY-MM-DD');

	const getTaskPos = (id: string) =>
		selectedExercises?.findIndex(exercise => exercise.id === id);
	const [openCoverImage, setOpenCoverImage] = useState(false);

	const handleDragEnd = (event: {
		active: IProgramExercise;
		over: IProgramExercise;
	}) => {
		const { active, over } = event;
		if (active.id === over.id) return;
		const originalPos = getTaskPos(active.id);
		const newPos = getTaskPos(over.id);
		const newArray = [...selectedExercises];
		[newArray[originalPos], newArray[newPos]] = [
			newArray[newPos],
			newArray[originalPos],
		];
		setSelectedExercises(newArray);
	};

	const updatePropertyAtIndex = (
		index: number,
		newReps: number,
		property: string,
	) => {
		setSelectedExercises(
			selectedExercises.map((exercise, idx) => {
				if (idx === index) {
					return {
						...exercise,
						[property]: newReps,
					};
				}
				return exercise;
			}),
		);
	};

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	return (
		<>
			<div className="flex">
				<div className="w-1/5 relative">
					<div
						className="upload-placeholder cursor-pointer relative group"
						onClick={() => setOpenCoverImage(true)}
						style={{
							position: 'relative',
							overflow: 'hidden',
							borderRadius: '8px',
							top: "20%"
						}}>
						{previewImage && previewImage !== 'null' && previewImage !== 'undefined' ? (
							<img
								src={previewImage}
								alt="Preview"
							/>
						) : previewUnSplashedImage && previewUnSplashedImage !== 'null' && previewUnSplashedImage !== 'undefined' ? (
							<img
								src={previewUnSplashedImage}
								alt="Preview"
							/>
						) : (
							<div className="upload-instructions flex flex-col items-center justify-center w-full rounded-lg group-hover:bg-gray-100">
								<Image03 width={50} height={50} color="stroke-gray-200"/>
								<p>{t('Admin.data.survey.uploadImage')}</p>
							</div>
						)}
						<div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
							<span className="text-white font-semibold">
								{previewImage
									? t('Admin.data.survey.changeImage')
									: t('Admin.data.survey.upload')}
							</span>
						</div>
					</div>
					{openCoverImage && (
						<AddCoverImage
							openCoverImage={openCoverImage}
							setOpenCoverImage={setOpenCoverImage}
							setImgFile={setImgFile}
							previewImage={previewImage}
							setPreviewImage={setPreviewImage}
							previewUnSplashedImage={previewUnSplashedImage}
							setPreviewUnSplashedImage={setPreviewUnSplashedImage}
						/>
					)}
				</div>
				<div className="w-4/5">
					<div className="flex gap-2">
						<div className="flex flex-col w-[520px] ">
							<p className="font-semibold">
								{t(
									'Admin.data.menu.aiAssistant.aiAssistantPrograms.titleLabel',
								)}
								*
							</p>
							<Input
								className="w-full h-[45px]"
								placeholder={t(
									'Admin.data.menu.aiAssistant.aiAssistantPrograms.titleLabel',
								)}
								value={programName}
								onChange={e => setProgramName(e.target.value)}
							/>
						</div>
						<div className="flex items-end gap-2 ml-auto">
							<Flex gap="small" className="gap-2 ml-auto">
								<div className="flex flex-col">
									<p className="font-semibold">
										{t(
											'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.startDate',
										)}
										*
									</p>
									<DatePicker
										className="w-40 h-[45px]"
										onChange={date =>
											setProgramStartDate(
												date ? dayjs(date).format('YYYY-MM-DD') : '',
											)
										}
										format="YYYY-MM-DD"
										value={
											programStartDate ? dayjs(currentDate, 'YYYY-MM-DD') : null
										}
										disabledDate={(current: Dayjs) =>
											current.isBefore(dayjs(), 'day')
										}
									/>
								</div>
								<div className="flex justify-center">
									<div className="flex flex-col">
										<p className="font-semibold">
											{t(
												'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.duration',
											)}
											*
										</p>
										<div className="flex flex-row">
											<Input
												type="number"
												className="bg-white rounded-r-none rounded-l-lg w-24 h-[45px]"
												onClick={e => e.stopPropagation()}
												value={duration}
												onChange={e => setDuration(parseInt(e.target.value))}
												placeholder={t(
													'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.duration',
												)}
											/>
											<Select
												className="!w-46 !h-[45px] !rounded-l-none !bg-white rounded-r-lg border border-[#d9d9d9] border-l-0 hover:border-l hover:border-primary-600 focus:ring-1 focus:ring-primary-200"
												bordered={false}
												onChange={value => setDurationType(value)}
												value={durationType}
												placeholder={t(
													'Admin.data.menu.aiAssistant.aiAssistantPrograms.durationType',
												)}>
												<Option value="days">
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantPrograms.days',
													)}
												</Option>
												<Option value="weeks">
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantPrograms.weeks',
													)}
												</Option>
												<Option value="months">
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantPrograms.months',
													)}
												</Option>
												<Option value="years">
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantPrograms.years',
													)}
												</Option>
											</Select>
										</div>
									</div>
								</div>
							</Flex>
						</div>
					</div>
					<div className="flex flex-col mt-4">
						<p className="font-semibold">
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantPrograms.descriptionLabel',
							)}
							*
						</p>
						<Input
							className="w-full h-[45px]"
							placeholder={t(
								'Admin.data.menu.aiAssistant.aiAssistantPrograms.descriptionLabel',
							)}
							value={programDescription}
							onChange={e => setProgramDescription(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div
				onClick={() => SetLibraryModalVisible(true)}
				className="border-2 cursor-pointer border-gray-300 border-dashed p-3 w-full rounded-lg my-2 text-center text-base text-gray-400">
				<MdAddCircleOutline className="w-5 h-5 text-gray-400 mr-2" />
				{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.addExercises')}
			</div>
			<div
				style={{ maxHeight: '52vh', overflowX: 'hidden', overflowY: 'auto' }}>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCorners}
					onDragEnd={handleDragEnd}>
					<SortableContext
						items={selectedExercises}
						strategy={verticalListSortingStrategy}>
						{selectedExercises?.map((item, index) => (
							<AddExerciseItem
								handleRemoveExercise={handleRemoveExercise}
								index={index}
								id={item.id}
								exercise={item}
								length={selectedExercises?.length}
								updatePropertyAtIndex={updatePropertyAtIndex}
							/>
						))}
					</SortableContext>
				</DndContext>
			</div>
			{!isSaveTemplateVisible && (
				<div className="flex items-center justify-center gap-2 checkbox-tick">
					<Checkbox
						id={'selectSaveTemplate'}
						checked={isSaveTemplate}
						onChange={() => setSaveTemplate(!isSaveTemplate)}
					/>
					<p>
						{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.saveTemplate')}
					</p>
				</div>
			)}
			<div
				className="w-full my-2 bg-primary-600 cursor-pointer rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2"
				onClick={validateProgram}>
				<Check />
				{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.save')}
			</div>
		</>
	);
}
