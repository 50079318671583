export const BODY_POINTS = [
	{
			"id": 2,
			"attributes": {
					"name": "Left Shoulder",
					"position": "front"
			},
			"romStyle": {
				"top": "91px",
				"left": null,
				"right": "62px",
				"bottom": null
			}
	},
	{
			"id": 4,
			"attributes": {
					"name": "Left Elbow",
					"position": "front"
			},
			"romStyle": {
				"top": "166px",
				"left": null,
				"right": "45px",
				"bottom": null
			}
	},
	{
			"id": 6,
			"attributes": {
					"name": "Left Wrist",
					"position": "front"
			},
			"romStyle": {
				"top": "229px",
				"left": null,
				"right": "24px",
				"bottom": null
			}
	},
	{
			"id": 8,
			"attributes": {
					"name": "Left Hip",
					"position": "front"
			},
			"romStyle": {
				"top": "204px",
				"left": null,
				"right": "84px",
				"bottom": null
			}
	},
	{
			"id": 10,
			"attributes": {
					"name": "Left Knee",
					"position": "front"
			},
			"romStyle": {
				"top": null,
				"left": null,
				"right": "93px",
				"bottom": "135px"
			}
	},
	{
			"id": 12,
			"attributes": {
					"name": "Left Ankle",
					"position": "front"
			},
			"romStyle": {
				"top": null,
				"left": null,
				"right": "96px",
				"bottom": "26px"
			}
	},
	{
			"id": 13,
			"attributes": {
					"name": "Neck",
					"position": "back"
			},
			"romStyle": {
				"top": "63px",
				"left": "110px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 14,
			"attributes": {
					"name": "Back",
					"position": "back"
			},
			"romStyle": {
				"top": "112px",
				"left": "110px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 15,
			"attributes": {
					"name": "Low Back",
					"position": "back"
			},
			"romStyle": {
				"top": "172px",
				"left": "110px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 16,
			"attributes": {
					"name": "Left Glute",
					"position": "back"
			},
			"romStyle": {
				"top": null,
				"left": "91px",
				"right": null,
				"top": "252px"
			}
	},
	{
			"id": 18,
			"attributes": {
					"name": "Left Calf",
					"position": "back"
			},
			"romStyle": {
				"top": null,
				"left": "91px",
				"right": null,
				"bottom": "90px"
			}
	},
	{
			"id": 20,
			"attributes": {
					"name": "Right Ankle",
					"position": "front"
			},
			"romStyle": {
				"top": null,
				"left": "96px",
				"right": null,
				"bottom": "26px"
			}
	},
	{
			"id": 21,
			"attributes": {
					"name": "Right Calf",
					"position": "back"
			},
			"romStyle": {
				"top": null,
				"left": null,
				"right": "91px",
				"bottom": "90px"
			}
	},
	{
			"id": 22,
			"attributes": {
					"name": "Right Elbow",
					"position": "front"
			},
			"romStyle": {
				"top": "166px",
				"left": "45px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 23,
			"attributes": {
					"name": "Right Glute",
					"position": "back"
			},
			"romStyle": {
				"top": "252px",
				"left": null,
				"right": "91px",
				"bottom": null
			}
	},
	{
			"id": 24,
			"attributes": {
					"name": "Right Hip",
					"position": "front"
			},
			"romStyle": {
				"top": "204px",
				"left": "84px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 25,
			"attributes": {
					"name": "Right Knee",
					"position": "front"
			},
			"romStyle": {
				"top": null,
				"left": "93px",
				"right": null,
				"bottom": "135px"
			}
	},
	{
			"id": 26,
			"attributes": {
					"name": "Right Shoulder",
					"position": "front"
			},
			"romStyle": {
				"top": "91px",
				"left": "62px",
				"right": null,
				"bottom": null
			}
	},
	{
			"id": 27,
			"attributes": {
					"name": "Right Wrist",
					"position": "front"
			},
			"romStyle": {
				"top": "229px",
				"left": "24px",
				"right": null,
				"bottom": null
			}
	}
]