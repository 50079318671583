import { Modal, Typography } from 'antd';

interface ICustomModalProps {
  name?: string;
  description: string;
  video?: string;
}

const showCustomModal = ({ name, description, video }: ICustomModalProps) => {
  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const modalContent = (
    <div className="select-none" style={{ textAlign: 'center', marginTop: '10%' }}>
      <video
        controls
        className="video"
        preload="metadata"
        src={video}
        width="100%"
        height="100%"
      />
      <div className="select-none" style={{ marginTop: '24px' }}>
        <Typography.Title level={5}>{name}</Typography.Title>
        <Typography.Text>{description}</Typography.Text>
      </div>
    </div>
  );

  Modal.info({
    title: null,
    content: modalContent,
    maskClosable: true,
    icon: null,
    okButtonProps: { style: { display: 'none' } },
    style: modalStyle,
    closable: true,
  });
};

export { showCustomModal };
