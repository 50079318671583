import { Button, message, Spin } from "antd";
import "./style.css";
import { TOnBoardSymptomsProps } from "@stores/interfaces";
import { useTranslation } from "react-i18next";
import AnimationCard from "./AnimationCard";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { useEffect, useState } from "react";
import { getFunctionalGoals } from "@stores/functionalGoals";
import { savedFunctionalGoal, updateFunctionalGoal } from "@stores/onBoard/onBoard";
import { FEATURES_MOCK } from "./FeaturesMock";

export interface FeatureProps {
  title: string;
  backgroundStyle: string;
  hoverBackgroundStyle: string;
  description: string;
  imageSrc: string;
}

export default function FunctionalGoals(props: TOnBoardSymptomsProps) {
  const { setActiveStep, setProgressPercent } = props;
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const savedFunctionalGoals = useTypedSelector((state) => state.onBoard.onBoard.savedFunctionalGoals);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedCards, setSelectedCards] = useState<Record<string, number[]>>({});
  const { user, selectedUser } = useTypedSelector((state) => ({
    user: state.user,
    selectedUser: state.contacts.main.selectedUser,
  }))

  const functionalGoalsMap: Record<string, number[]> = {
    "Recovery & Managing Discomfort": [1, 3, 4],
    "Functional Independence & Daily Living": [2, 8],
    "Performance Enhancement & Injury Prevention": [6, 7],
    "Holistic Well-being & Quality of Life": [5, 9, 10],
  };

  useEffect(() => {
    let savedIds: number[] = [];
    if (savedFunctionalGoals?.functionalGoalsIds?.length) {
      savedIds = savedFunctionalGoals.functionalGoalsIds;
    } else if (selectedUser?.functionalGoals?.length) {
      const lastIndex = selectedUser.functionalGoals.length - 1;
      savedIds = selectedUser.functionalGoals[lastIndex]?.functionalGoalsIds || [];
    }  
    if (savedIds.length > 0) {
      const preSelectedCards: Record<string, number[]> = {};
      Object.entries(functionalGoalsMap).forEach(([title, ids]) => {
        const matchedIds = ids.filter((id) => savedIds.includes(id));
        if (matchedIds.length > 0) {
          preSelectedCards[title] = matchedIds;
        }
      });
      setSelectedCards(preSelectedCards);
    } else {
      setSelectedCards({});
    }
  }, [savedFunctionalGoals, selectedUser]);
  
  useEffect(() => {
    fetchingFunctionalGoalData();
  }, [])

  const fetchingFunctionalGoalData = async () => {
    await dispatch(getFunctionalGoals());
    setIsDataLoaded(true);
  };

  const handleNextClick = async () => {
    const functionalGoalsIds = Object.values(selectedCards).flat();
    if (functionalGoalsIds.length === 0) {
      message.error(t("Patient.data.virtualEvaluation.associatedSymptoms.pleaseSelectAtLeastOneOption"));
      return;
    }
    const payload = {
      functionalGoalsIds,
    };
    savedFunctionalGoals.length > 0 ? await dispatch(updateFunctionalGoal({ userId: user?.id, payload })) : await dispatch(savedFunctionalGoal({ userId: user?.id, payload }))
    setActiveStep!(7);
    setProgressPercent(70);
  };

  const handleCardSelect = (title: string) => {
    setSelectedCards((prev) => {
      const newState = { ...prev };
      if (newState[title]) {
        delete newState[title];
      } else {
        newState[title] = functionalGoalsMap[title] || [];
      }
      return newState;
    });
  };  

  if (!isDataLoaded) {
    return <Spin className="spin-css" tip="Loading" size="large" />;
  }

  return (
    <>
      <div className="container functional-container">
        <div className="whole-day-header-container functional-sub-container">
          <h1 style={{ fontSize: "25px", color: "#6941C6", fontWeight: "700" }}>
            {t("Patient.data.onboard.functionalGoals")}
          </h1>
          <h3 style={{ fontSize: "18px", color: "#98A2B3", fontWeight: "300" }}>
            {t("Patient.data.onboard.defineObj")}
          </h3>
        </div>
        <div className="functional-card-div space-x-5">
          {FEATURES_MOCK.map((feature, index) => (
            <AnimationCard
              key={index}
              setActiveStep={setActiveStep}
              setProgressPercent={setProgressPercent}
              {...feature}
              handleCardSelection={handleCardSelect}
              isSelected={!!selectedCards[feature.title]}
            />
          ))}
        </div>
        <div className="back-button-div-css">
          <Button className="back-button-inside-css" onClick={() => setActiveStep!(5)} size="large" type="primary" block style={{ height: "43px", width: "308px" }}>
            {t("Patient.data.onboard.back")}
          </Button>
          <Button size="large" type="primary" className="back-button-inside-css" block style={{ height: "43px", background: "#6941C6", color: "#fff", width: "308px" }} onClick={handleNextClick}>
            {t("Patient.data.onboard.save")}
          </Button>
        </div>
      </div>
    </>
  );
}
