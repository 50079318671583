import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { limitPagination, pagination } from '@stores/constants';
import { IStatusStoreState, ISessionResponsePayload, IUpdateSessionStatusResponsePayload, IUpdateStatusBody, IUserPaginationDefaultResponse, Status, IUpdateProgramStatusResponsePayload } from '@stores/interfaces';
import axios from 'axios';

const initialState: IStatusStoreState = {
  omnirom: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  rehab: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  evaluation: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  survey: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  postures: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  }
}

export const getOmniRomEscalationRequired = createAsyncThunk(
  'rom/escalationRequired',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/rom/sessions/status/${Status.escalationRequired}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateOmniRomSessionStatus = createAsyncThunk(
  'rom/escalationRequired/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/rom/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getRehabEscalationRequired = createAsyncThunk(
  'rehab/escalationRequired',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/program/sessions/status/${Status.escalationRequired}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateRehabSessionStatus = createAsyncThunk(
  'rehab/escalationRequired/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/program/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getEvaluationEscalationRequired = createAsyncThunk(
  'evaluation/escalationRequired',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/evaluation/sessions/status/${Status.escalationRequired}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateEvaluationSessionStatus = createAsyncThunk(
  'evaluation/escalationRequired/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/evaluation/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getSurveyEscalationRequired = createAsyncThunk(
  'survey/escalationRequired',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/survey/sessions/status/${Status.escalationRequired}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateSurveySessionStatus = createAsyncThunk(
  'survey/escalationRequired/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/survey/sessions/${sessionId}/status`, body)
    return data
  }
)

export const updatePostureSessionStatus = createAsyncThunk(
  'posture-analytics/escalationRequired/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/posture-analytics/sessions/${sessionId}/status`, body)
    return data
  }
)

export const escalationRequiredSlice = createSlice({
  name: 'escalationRequired',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOmniRomEscalationRequired.pending, (state) => {
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(getOmniRomEscalationRequired.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.omnirom.users = action.payload.data
      state.omnirom.pagination = action.payload.pagination
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(updateOmniRomSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.omnirom.statusToRefresh = [action.payload.status, Status.escalationRequired]
    })

    builder.addCase(getRehabEscalationRequired.pending, (state) => {
      state.rehab.statusToRefresh = []
    })

    builder.addCase(getRehabEscalationRequired.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.rehab.users = action.payload.data
      state.rehab.pagination = action.payload.pagination
      state.rehab.statusToRefresh = []
    })

    builder.addCase(updateRehabSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateProgramStatusResponsePayload>) => {
      state.rehab.statusToRefresh = [action.payload.status, Status.escalationRequired]
    })

    builder.addCase(getEvaluationEscalationRequired.pending, (state) => {
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(getEvaluationEscalationRequired.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.evaluation.users = action.payload.data
      state.evaluation.pagination = action.payload.pagination
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(updateEvaluationSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.evaluation.statusToRefresh = [action.payload.status, Status.escalationRequired]
    })

    builder.addCase(getSurveyEscalationRequired.pending, (state) => {
      state.survey.statusToRefresh = []
    })

    builder.addCase(getSurveyEscalationRequired.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.survey.users = action.payload.data
      state.survey.pagination = action.payload.pagination
      state.survey.statusToRefresh = []
    })

    builder.addCase(updateSurveySessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.survey.statusToRefresh = [action.payload.status, Status.escalationRequired]
    })

    builder.addCase(updatePostureSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.postures.statusToRefresh = [action.payload.status, Status.escalationRequired]
    })

  }
})

export default escalationRequiredSlice.reducer