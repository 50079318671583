import React, { useEffect, useState } from 'react';
import { useTypedDispatch } from "@stores/index";
import { getSurveyActivityStreamByData } from "@stores/activityStream/activityStream";
import { Empty, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { ISurveyResult, ISurveyQuestion, ISurveyResultQuestionList } from '@stores/interfaces';
import { FaceSad } from '@carespace-icons/users/faceSad';
import { FaceFrown } from '@carespace-icons/users/faceFrown';
import { FaceNeutral } from '@carespace-icons/users/faceNeutral';
import { FaceSmile } from '@carespace-icons/users/faceSmile';
import { FaceHappy } from '@carespace-icons/users/faceHappy';
import './style.css'
import { FaceWink } from '@carespace-icons/users/faceWink';

export const SurveySession = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [surveyData, setSurveyData] = useState<ISurveyResult | null>(null);
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async (id: string) => {
      setLoading(true)
      const data = await dispatch(getSurveyActivityStreamByData(id));
      setSurveyData(data?.payload);
      setLoading(false)
    };

    fetchData(id);
  }, [id, dispatch]);

  const getPainLevel = (painLevel: number, question: ISurveyResultQuestionList) => {
    switch (painLevel) {
      case 1:
        return (
          <div className='!flex gap-2'>
            <FaceFrown color={'stroke-rose-700'} width={20} height={20} />
            <span className='text-sm font-semibold text-rose-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 2:
        return (
          <div className='!flex gap-2'>
            <FaceFrown color={'stroke-rose-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-rose-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 3:
        return (
          <div className='!flex gap-2'>
            <FaceSad color={'stroke-orange-400'} width={20} height={20} />
            <span className='text-sm font-semibold text-orange-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 4:
        return (
          <div className='!flex gap-2'>
            <FaceSad color={'stroke-orange-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-orange-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 5:
        return (
          <div className='!flex gap-2'>
            <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} />
            <span className='text-sm font-semibold text-yellow-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 6:
        return (
          <div className='!flex gap-2'>
            <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-yellow-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 7:
        return (
          <div className='!flex gap-2'>
            <FaceSmile color={'stroke-green-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 8:
        return (
          <div className='!flex gap-2'>
            <FaceSmile color={'stroke-green-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 9:
        return (
          <div className='!flex gap-2'>
            <FaceWink color={'stroke-green-700'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 10:
        return (
          <div className='!flex gap-2'>
            <FaceHappy color={'stroke-cyan-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-cyan-500'>{question?.selectedAnswer}</span>
          </div>
        );
      default:
        return null;
    }
  }

  let totalScore = 0;
  surveyData?.questionList?.forEach((question: ISurveyQuestion) => {
    totalScore += question.score || 0;
  });

  return (
    <>
      {loading ? <Spin className="spinner" tip="Loading" size="large" /> :
        <>
          {!surveyData || Object.keys(surveyData).length === 0 ?
            <Empty className='empty' image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-gray-300'>{t('Admin.data.survey.noResult')}</span>} /> :
            <div className="custom-flex-col">
              <div className="flex-between custom-mb">
                <div className="custom-surveyTitle-container">
                  <p className=''><b>{t('Admin.data.survey.surveyName')} :</b> {surveyData?.title}</p>
                </div>
                <div className="total-score">
                  {totalScore !== 0 &&
                    <p className="survey-total-score">{t('Admin.data.survey.score')}: {totalScore}</p>
                  }
                </div>
              </div>
              {surveyData?.questionList?.map((question: ISurveyQuestion, index: number) => (
                <div key={index} className="survey-container">
                  <div className="flex-between">
                    <p className="question">{index + 1} - {question.question}</p>
                    {question.score !== null &&
                      <p className="score">{question.score}</p>
                    }
                  </div>
                  <hr />
                  {question.answerList.length === 0 && question.questionType === 'rate' ? (
                    <p>{getPainLevel(question.ratingLevel!, question)}</p>
                  ) : question.answerList.length > 0 && question.questionType !== 'rate' ? (
                    <ul>
                      {question.answerList.map((answer: string, aIndex: number) => (
                        <li key={aIndex} className="select-answer">- {answer}</li>
                      ))}
                    </ul>
                  ) : (
                    <ul>
                      <li className="select-answer">- {question.selectedAnswer}</li>
                    </ul>
                  )}
                </div>
              ))}
            </div>
          }</>}
    </>
  );
};
