import PainAssessment from '@pages/Home/PainAssessment';

export default function AiVirtualAssessment() {
	return (
		<>
			<div className="m-5">
				<div className="mt-5">
					<PainAssessment />
				</div>
			</div>
		</>
	);
}
