import { Button, message, Spin } from 'antd';
import './style.css';
import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { HealthSigns, THealthSignsOptions } from '@pages/Home/interface';
import { TOnBoardSymptomsProps } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import { getEvaluationData, getHealthSignOptions } from '@stores/myLibrary/myLibrary';
import { myLibraryInfoAction } from '@stores/library';

export default function AssociatedSymptoms(props: TOnBoardSymptomsProps) {
  const { setActiveStep, setProgressPercent } = props;
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const user = useTypedSelector((state) => state.user);
  const [selectedButtons, setSelectedButtons] = useState<any>([]);
  const healthSignOptions = useTypedSelector((state) => state.myLibrary.myLibrary.healthSignOptions);
  const associatedSymptoms = useTypedSelector((state) => state.painAssessment.associatedSymptoms);
  const [savedSymptomsData, setSavedSymptomsData] = useState<HealthSigns>();
  const [disableOtherOptions, setDisableOtherOptions] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    fetchAssociatedSymptoms();
    fetchSavedData();
  }, [])

  const fetchAssociatedSymptoms = async () => {
    try {
      const data = await dispatch(getHealthSignOptions());
      let apiData = data.payload;
      dispatch(myLibraryInfoAction.healthSignOptionsInfo(apiData));
      setIsDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSavedData = async () => {
    let id = user.id;
    const payload = {
      userId: id,
      page: 1,
      limit: 1,
    };
    const data = await dispatch(getEvaluationData(payload));
    const apiData = data.payload;
    if (apiData.data.length > 0) {
      setSavedSymptomsData(apiData.data[0].healthSigns);
    };
  }

  useEffect(() => {
    if (associatedSymptoms?.strapiHealthSignsIds?.length) {
      const savedIds = associatedSymptoms?.strapiHealthSignsIds;
      const preSelectedButtons = healthSignOptions?.data?.filter((item) => savedIds?.includes(item?.id));
      setSelectedButtons(preSelectedButtons);
      setDisableOtherOptions(savedIds?.includes(17));
    } else if (savedSymptomsData?.strapiHealthSignsIds?.length) {
      const savedIds = savedSymptomsData.strapiHealthSignsIds;
      const preSelectedButtons = healthSignOptions?.data?.filter((item) => savedIds?.includes(item?.id));
      setSelectedButtons(preSelectedButtons);
      setDisableOtherOptions(savedIds?.includes(17));
    }
  }, [associatedSymptoms, healthSignOptions, savedSymptomsData]);

  const handleButtonClick = (item: THealthSignsOptions) => {
    if (item.id === 17) {
      if (selectedButtons.some((selected: THealthSignsOptions) => selected.id === 17)) {
        setSelectedButtons(selectedButtons?.filter((selected: THealthSignsOptions) => selected.id !== 17));
        setDisableOtherOptions(false);
      } else {
        setSelectedButtons([item]);
        setDisableOtherOptions(true);
      }
    } else if (!disableOtherOptions) {
      if (selectedButtons.some((selected: THealthSignsOptions) => selected.id === item.id)) {
        setSelectedButtons(selectedButtons?.filter((selected: THealthSignsOptions) => selected.id !== item.id));
      } else {
        setSelectedButtons([...selectedButtons, item]);
      }
    }
  };

  const handleNextClick = () => {
    if (selectedButtons.length === 0) {
      message.error('Please select at least one option');
      return;
    }
    const strapiHealthSigns = selectedButtons.map((item: THealthSignsOptions) => item.id);
    const payload = {
      strapiHealthSignsIds: strapiHealthSigns,
    };
    dispatch(painAssessmentInfoAction.associatedSymptomsInfo(payload));
    setActiveStep!(5);
    setProgressPercent(50);
  };

  if (!isDataLoaded) {
    return <Spin className="spin-css" tip="Loading" size="large" />;
  }

  return (
    <div className="medical-container">
      <h1 style={{ fontSize: '25px', color: '#6941C6', fontWeight: '700' }}>
        {t('Patient.data.virtualEvaluation.associatedSymptoms.title')}
      </h1>
      <h3 style={{ fontSize: '18px', color: '#98A2B3', fontWeight: '300' }}>
        {t('Patient.data.virtualEvaluation.associatedSymptoms.description')}
      </h3>
      <div className="medical-subcontainer">
        <div className="content-div-associated">
          {healthSignOptions?.data?.map((item: THealthSignsOptions) => (
            <Button
              key={item.id}
              className={`onboard-list-button-css ${selectedButtons.some((selected) => selected.id === item.id) ? 'selected' : ''
                }`}
              onClick={() => handleButtonClick(item)}
              disabled={disableOtherOptions && item.id !== 17}
            >
              {item?.attributes?.name}
            </Button>
          ))}
        </div>
        <div className="mt-2 w-[590px] flex items-end gap-2">
          <Button
            onClick={() => setActiveStep!(3)}
            size="large"
            type="primary"
            block
            className="mt-2 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6"
            style={{ height: '43px', width: '308px' }}
          >
            {t('Patient.data.onboard.back')}
          </Button>
          <Button
            size="large"
            type="primary"
            block
            className="rounded-full font-inter text-base font-bold leading-6"
            style={{ height: '43px', background: '#6941C6', color: '#fff', width: '308px' }}
            onClick={handleNextClick}
          >
            {t('Patient.data.onboard.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
