import { Divider, Tooltip } from 'antd';
import { MdOutlineSwitchCamera } from 'react-icons/md';
import { UseSwitchVideo } from '../context/SwitchVideo.context';

function SwitchVideo() {
	const { isSwitchMode, onSwitchCamera } = UseSwitchVideo();

	if (!isSwitchMode) return null;

	return (
		<>
			<Divider
				type="vertical"
				style={{ height: '34px', borderColor: '#1B1B1B' }}
			/>
			<Tooltip title={'Switch Camera'}>
				<MdOutlineSwitchCamera
					size={30}
					style={{
						verticalAlign: 'middle',
						cursor: 'pointer',
					}}
					onClick={onSwitchCamera}
					color="#5e5e5e"
				/>
			</Tooltip>
		</>
	);
}

export default SwitchVideo;
