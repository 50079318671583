import { useEffect, useState } from 'react';
import OmniRom from './OmniRom';
import Rehab from './Rehab';
import Evaluation from './Evaluation';
import Survey from './Survey';
import { Badge, Tabs, TabsProps, Tag } from 'antd';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useTranslation } from 'react-i18next';
import { clearState } from '@stores/contacts/contacts';

const { TabPane } = Tabs;

function index() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('Evaluation')
  const dispatch = useTypedDispatch()
  const savedState = useTypedSelector(state => state.contacts.main.ptDashboard)
  const outOfParamsStatCount = useTypedSelector(state => state.adminDashboardPatient.statsCount.outOfParams)
  const count = outOfParamsStatCount.evaluation + outOfParamsStatCount.letsMove + outOfParamsStatCount.omniRom + outOfParamsStatCount.survey;
  const TAB_ITEM: TabsProps['items'] = [
    {
      key: "Evaluation", label: <div className={`dashboard-badge-count-div ${activeTab === 'Evaluation' ? 'active-tab' : ''}`}>
        <Badge color='black' count={outOfParamsStatCount.evaluation}>
          <span className='dashboard-badge-label-span'>Evaluation</span></Badge></div>,
      disabled: false, children: <Evaluation />
    },
    {
      key: "OmniROM", label: <div className={`dashboard-badge-count-div ${activeTab === 'OmniROM' ? 'active-tab' : ''}`}><Badge color='black' count={outOfParamsStatCount.omniRom}>
        <span className='dashboard-badge-label-span'>{t('Admin.data.outOfParameters.omniRom.omniRom')}</span></Badge></div>, disabled: false, children: <OmniRom />
    },
    {
      key: "Let's Move", label: <div className={`dashboard-badge-count-div ${activeTab === "Let's Move" ? 'active-tab' : ''}`}><Badge color='black' count={outOfParamsStatCount.letsMove}>
        <span className='dashboard-badge-label-span'>{t('Admin.data.managePatient.letsMove.letsMove')}</span></Badge></div>, disabled: false, children: <Rehab />
    },
    {
      key: "Surveys", label: <div className={`dashboard-badge-count-div ${activeTab === 'Surveys' ? 'active-tab' : ''}`}><Badge color='black' count={outOfParamsStatCount.survey}>
        <span className='dashboard-badge-label-span'>Surveys</span></Badge></div>, disabled: false, children: <Survey />
    }
  ]

  useEffect(() => {
    setActiveTab(savedState.activeTab || 'Evaluation')
  }, [])

  return (
    <div className="total-patient-main-div">
      <h3><span className='badge-css'><Badge color='black'/></span> {t('Admin.data.outOfParameters.outOfParameters')} <span className='total-patient-length'><Tag color="purple">{count} {t('Admin.data.outOfParameters.users')}</Tag></span></h3>
        <Tabs  onChange={(value) => {
           dispatch(clearState())
         setActiveTab(value)
      }}
        activeKey={activeTab}
        className="custom-tabs"
      >
        {TAB_ITEM.map(item => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default index