import { ReportNotes } from '@stores/interfaces'
import { Col, Image, Row } from 'antd'
import moment from 'moment'
import { MdOutlineRemoveRedEye } from 'react-icons/md'

interface IReportNotesTemplate{
  isEditMode: boolean,
  index:number,
  note:ReportNotes
}
const ReportNotesTemplate = (props:IReportNotesTemplate) => {
  const { isEditMode, index, note } = props
  return (
    <>
    <p className="font-semibold text-gray-600 mt-3">Date : <span className="font-regular">{moment(note?.createdAt).local().format('LLL')}</span></p>
    <ul contentEditable={isEditMode} className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
      <div className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
        <li key={index} className="grid text-sm mb-1 mt-1 leading-7 pl-4 pt-2 pb-2 pr-2 ">
          {!note?.image && !note?.video ? (
            <Row gutter={[16, 16]} align="middle">
              <Col span={24}>
                <span className="font-regular custom-padding">{note.notes}</span>
              </Col>
            </Row>
          ) :
            <div key={index} className="custom-padding">
              {note?.image && (
                <Row gutter={[16, 16]} align="middle">
                  <Col span={6}>
                    <Image
                      src={note?.image}
                      className="custom-image"
                      height={140}
                      preview={{
                        src: note?.image,
                        mask: <MdOutlineRemoveRedEye size={18} />,
                      }}
                    />
                  </Col>
                  <Col span={18} className="popup-message-head">
                    <p className="popup-message">{note?.notes}</p>
                  </Col>
                </Row>
              )}
              {note?.video && (
                <Row gutter={[16, 16]} align="middle">
                  <Col span={6}>
                    <video
                      src={note?.video}
                      controls
                      className="custom-image"
                      preload="metadata"
                      width={250}
                      height={140}
                    />
                  </Col>
                  <Col span={18} className="popup-message-head">
                    <p className="popup-message">{note?.notes}</p>
                  </Col>
                </Row>
              )}
            </div>
          }
        </li>
      </div>
    </ul>
    </>
  )
}

export default ReportNotesTemplate