import { useEffect, useState } from 'react'
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, closestCorners } from "@dnd-kit/core";
import Button from "@atoms/Button";
import { FaceFrown } from "@carespace-icons/users/faceFrown";
import { FaceHappy } from "@carespace-icons/users/faceHappy";
import { FaceNeutral } from "@carespace-icons/users/faceNeutral";
import { FaceSad } from "@carespace-icons/users/faceSad";
import { FaceSmile } from "@carespace-icons/users/faceSmile";
import { SurveyQuestionOptionItem } from './SurveyQuestionOptionItem';
import { Input, message } from 'antd';
import { MdAddCircleOutline } from 'react-icons/md';
import { Check } from '@carespace-icons/general/check';
import { Pencil01 } from '@carespace-icons/editor/pencil01';
import { sortableKeyboardCoordinates, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ISurveyRateDataProps } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { Trash01 } from '@carespace-icons/general/trash01';
import { RefreshCcw01 } from '@carespace-icons/arrows/refreshCcw01';
import { FaceWink } from '@carespace-icons/users/faceWink';

const ratingExps = [
  <FaceFrown color={'stroke-rose-700'} width={45} height={45} />,
  <FaceFrown color={'stroke-rose-500'} width={45} height={45} />,
  <FaceSad color={'stroke-orange-400'} width={45} height={45} />,
  <FaceSad color={'stroke-orange-300'} width={45} height={45} />,
  <FaceNeutral color={'stroke-yellow-400'} width={45} height={45} />,
  <FaceNeutral color={'stroke-yellow-300'} width={45} height={45} />,
  <FaceSmile color={'stroke-green-300'} width={45} height={45} />,
  <FaceSmile color={'stroke-green-500'} width={45} height={45} />,
  <FaceWink color={'stroke-green-700'} width={45} height={45} />,
  <FaceHappy color={'stroke-cyan-500'} width={45} height={45} />
]

export default function SurveyRateDataItem(props: ISurveyRateDataProps) {
  const { survey, handleDeleteRate, handleReset, question, isEdit, ratingOptions, handleDragEnd, index, setIsEdit, updateOptionByIndex, handleScoreChange, handleInputChange, updateOptionTitle, addOptionsByIndex, deleteOptionByIndex, addRatingOptionByIndex } = props
  const [idCounter, setIdCounter] = useState(1);
  const { t } = useTranslation()
  const [answerValue, setAnswerValue] = useState<string>()
  const [score, setScore] = useState<number>()
  const showReset = ratingOptions?.filter(rate => !rate?.isDeleted)?.length < 7

  const validateRating = () => {
    if (ratingOptions.some(rate => (rate.option == '' && rate?.isDeleted == false) || (question?.scored && isNaN(rate.score) && rate?.isDeleted == false))) {
      message.warning(t('Admin.data.survey.errRateEmpty'))
    } else {
      updateOptionByIndex(index, ratingOptions)
      setIsEdit(false)
    }
  }

  const handleAnswer = () => {
    if (answerValue?.trim() === "") {
      message.error(t('Admin.data.survey.answerEmptyError'));
    } else if (question?.scored && isNaN(score!)) {
      message.error(t('Admin.data.survey.scoreEmptyError'));
    } else {
      const newOption = {
        id: idCounter.toString(),
        option: answerValue,
        score: score
      }
      addOptionsByIndex(index, newOption)
      setAnswerValue('')
      setIdCounter(idCounter + 1)
      setScore(0)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div className="survey-rate-data-item">
      {
        question?.questionType === 'rate'
          ? <div className="rate-section">
            <div className="rate-items">
              {
                [...Array(10)].map((_, i) => {
                  if (ratingOptions[i]?.isDeleted != true)
                    return (
                      <span className="rate-item">
                        {ratingExps[i]}
                        <div style={{ width: "90%" }}>
                          <p>{t("Admin.data.survey.answerTitle")}</p>
                          {
                            isEdit
                              ? <Input
                                style={{ width: "100%" }}
                                className='input'
                                value={ratingOptions[i]?.option}
                                onChange={(e) => handleInputChange(i, e.target.value)}
                                readOnly={survey?.clinicallyValidated}
                              />
                              : <p className='display-input'>{ratingOptions[i]?.option}</p>
                          }
                        </div>
                        {question?.scored &&
                          <div style={{ width: "10%" }}>
                            <p>{t("Admin.data.survey.scoreTitle")}</p>
                            {
                              isEdit
                                ? <Input
                                  style={{ width: "100%" }}
                                  className='input score-input'
                                  value={ratingOptions[i]?.score}
                                  type="number"
                                  readOnly={survey?.clinicallyValidated}
                                  onChange={(e) => handleScoreChange(i, parseInt(e.target.value))}
                                />
                                : <p style={{ width: "100%" }} className='display-input score-display'>{ratingOptions[i]?.score}</p>
                            }
                          </div>
                        }
                        {
                          ratingOptions?.filter(item => item?.isDeleted == false).length > 2 && isEdit && !survey?.clinicallyValidated && <span className='delete-rate-item' onClick={() => handleDeleteRate(i)}>
                            <Trash01 color="stroke-gray-400" width={20} height={20} />
                          </span>
                        }
                      </span>
                    )
                })
              }
            </div>
            {!survey?.clinicallyValidated &&
              <div className="action-button">
                {
                  !isEdit
                    ? <Button onClick={() => setIsEdit(true)} size="sm">
                      <span className="button-content">
                        <Pencil01 width={17} height={17} color="stroke-white" />
                        {t('Admin.data.survey.edit')}
                      </span>
                    </Button>
                    : <div style={{ display: "flex", gap: 8 }}>
                      {showReset && <Button onClick={() => handleReset()} size="sm">
                        <span className="button-content">
                          <RefreshCcw01 width={17} height={17} color="stroke-white" />
                          Reset
                        </span>
                      </Button>}
                      <Button onClick={() => validateRating()} size="sm">
                        <span className="button-content">
                          <Check width={20} height={20} color="stroke-white" />
                          {t('Admin.data.survey.save')}
                        </span>
                      </Button>
                    </div>
                }
              </div>}
          </div>
          : <div className='add-answer-section'>
            {!survey?.clinicallyValidated && <>
              <div className="title-section">
                <p>{t("Admin.data.survey.answerTitle")}</p>
                <Input
                  className='answer-input'
                  value={answerValue}
                  onChange={(e) => setAnswerValue(e.target.value)}
                />
              </div>
              <div className="score-section">
                {question?.scored && <p>{t('Admin.data.survey.score')}</p>
                }<div className="score-input-section">
                  {question?.scored && <Input type="number"
                    className='score-input'
                    onChange={(e) => {
                      const value = e.target.value;
                      setScore(parseInt(value));
                    }}
                    value={score}
                  />}
                  <Button onClick={() => handleAnswer()} size="lg">
                    <span className="button-content">
                      <MdAddCircleOutline className="add-icon" />
                      {t('Admin.data.survey.addItem')}
                    </span>
                  </Button>
                </div>
              </div></>}
          </div>
      }
      {/* <div style={{ maxHeight: "20vh", overflowX: "hidden", overflowY: "auto" }}> */}
      {
        question?.questionType !== 'rate' && question?.optionList?.length! > 0 && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={question?.optionList || []}
              strategy={verticalListSortingStrategy}
            >
              {
                (question?.optionList || []).map((option, optionIndex) => (
                  <SurveyQuestionOptionItem
                    survey={survey}
                    scored={question?.scored!}
                    key={option.id}
                    updateOptionTitle={(title: string, score: number) => updateOptionTitle(index, optionIndex, title, score)}
                    deleteOptionByIndex={() => deleteOptionByIndex(index, optionIndex)}
                    option={option}
                  />
                ))
              }
            </SortableContext>
          </DndContext>
        )
      }
    </div>
    // </div >
  )
}
