import { List } from "@carespace-icons/layoult/list";
import { Image01 } from "@carespace-icons/files/image01";
import { Share07 } from "@carespace-icons/general/share07";
import { BarChart01 } from "@carespace-icons/charts/barChart01";
import { BarChartSquarePlus } from "@carespace-icons/charts/barChartSquarePlus";
import { MdOutlineAssignment } from "react-icons/md";
import { Activity } from "@carespace-icons/general/activity";
import { PlusSquare } from "@carespace-icons/general/plusSquare";
import { MagicWand01 } from "@carespace-icons/editor/magicWand01";
import { PlaySquare } from "@carespace-icons/media/playSquare";
import { Home02 } from "@carespace-icons/general/home02";

export const AI_MENU_DATA = {
  data: [
    {
      "id": "",
      "title": "Home",
      "list": [
        {
          "key": "home",
          "name": 'Home',
          "icon": <Home02 color='stroke-white' height={15} width={15} />
        },
      ]
    },
    {
      "id": "",
      "title": "Activity",
      "list": [
        {
          "key": "activity",
          "icon": <Activity color='stroke-white' height={15} width={15} />,
          "name": 'Activity Stream'
        },
      ]
    },
    {
      "id": "",
      "title": "Evaluation",
      "list": [
        {
          "key": "virtualEvaluation",
          "icon": <MdOutlineAssignment className="text-white" height={16.67} width={13.33} />,
          "name": 'Start Virtual Evaluation'
        },
        {
          "key": "listEvaluation",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Evaluation Results'
        }
      ]
    },
    {
      "id": "",
      "title": "OmniROM",
      "list": [
        {
          "key": "startScan",
          "icon": <PlaySquare color='stroke-white' height={16.67} width={15} />,
          "name": 'Start Scan'
        },
        {
          "key": "romSummary",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'OmniROM Summary'
        },
        {
          "key": "captures",
          "icon": <Image01 color='stroke-white' height={15} width={15} />,
          "name": 'OmniROM Captures'
        },
        {
          "key": "postureSummary",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Posture Summary'
        },
        {
          "key": "postureCaptures",
          "icon": <Image01 color='stroke-white' height={15} width={15} />,
          "name": 'Posture Captures'
        },
        // {
        //   "key": "customSummary",
        //   "icon": <List color='stroke-white' height={15} width={15} />,
        //   "name": 'Custom Summary'
        // },
        // {
        //   "key": "customCaptures",
        //   "icon": <Image01 color='stroke-white' height={15} width={15} />,
        //   "name": 'Custom Captures'
        // }
      ]
    },
    {
      "id": "",
      "title": "Let's Move",
      "list": [
        {
          "key": "generateProgram",
          "icon": <MagicWand01 height={15} width={15} color='stroke-white' />,
          "name": 'Generate Program'
        },
        {
          "key": "programs",
          "icon": <PlusSquare color='stroke-white' height={15} width={15} />,
          "name": 'Program List'
        },
        {
          "key": "listSessions",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Program Summary'
        },
      ]
    },
    {
      "id": "",
      "title": "Surveys",
      "list": [
        {
          "key": "assignSurvey",
          "icon": <PlusSquare height={15} width={15} color='stroke-white' />,
          "name": 'Assign Survey'
        },
        {
          "key": "createSurvey",
          "icon": <MagicWand01 height={15} width={15} color='stroke-white' />,
          "name": 'Create Survey'
        },
        {
          "key": "startSurveyUser",
          "icon": <MdOutlineAssignment className="text-white" height={16.67} width={13.33} />,
          "name": 'Start Survey'
        },
        {
          "key": "surveySummary",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Survey Summary'
        },
      ]
    },
    {
      "id": "",
      "title": "Reports",
      "list": [
        {
          "key": "createReport",
          "icon": <BarChartSquarePlus color='stroke-white' height={15.83} width={15.83} />,
          "name": 'Create Reports'
        },
        {
          "key": "myReport",
          "icon": <BarChart01 color='stroke-white' height={15} width={15} />,
          "name": 'Report Summary'
        },
      ]
    },
  ]
}


export const NO_COMPONENT_FOUND_LABELS = [
  "I'm sorry, but I didn't quite catch that. Could you please rephrase your question?",
  "Apologies, but I'm not sure I understand your question. Can you try asking it differently?",
  "I’m sorry, I don't understand what you're asking. Can you clarify?",
  "I apologize, but I didn't understand your question. Could you provide more details?",
  "Sorry, I’m having trouble understanding your question. Could you explain it in another way?",
  "I'm not sure I follow. Could you ask your question in a different way?",
  "I didn't quite get that. Can you please rephrase your question?",
  "Sorry, I'm a bit confused by your question. Could you clarify what you mean?",
  "I’m sorry, but I don’t understand what you’re asking. Can you try rephrasing it?",
  "Apologies, but I'm not sure what you mean. Could you ask your question in a different way?"
];


export const COMMON_SEARCHES_DATA = {
  data: [
    {
      "id": "",
      "title": "Activity",
      "list": [
        {
          "key": "activity",
          "icon": <Activity color='stroke-white' height={15} width={15} />,
          "name": 'Activity Stream'
        },
      ]
    },
    {
      "id": "",
      "title": "Evaluation",
      "list": [
        {
          "key": "listEvaluation",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Evaluation Results'
        }
      ]
    },
    {
      "id": "",
      "title": "OmniROM",
      "list": [
        {
          "key": "romSummary",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'OmniROM Summary'
        },
      ]
    },
    {
      "id": "",
      "title": "Let's Move",
      "list": [
        {
          "key": "listSessions",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Program Summary'
        },
      ]
    },
    {
      "id": "",
      "title": "Surveys",
      "list": [
        {
          "key": "surveySummary",
          "icon": <List color='stroke-white' height={15} width={15} />,
          "name": 'Survey Summary'
        },
      ]
    },
    {
      "id": "",
      "title": "Reports",
      "list": [
        {
          "key": "myReport",
          "icon": <BarChart01 color='stroke-white' height={15} width={15} />,
          "name": 'Report Summary'
        },
      ]
    },
  ]
}