import { Route, Routes } from 'react-router-dom';
import Layoult from '@molecules/MLayoult';
import AiAssistantMyReport from '@pages/AiAssistantMyReport';
import AiVirtualAssessment from '@pages/AiVirtualAssessment';
import AiAssistantRomSummary from '@pages/AiAssistantRomSummary';
import AiAssistantSurveySummary from '@pages/AiAssistantSurveySummary';
import AiAssistantStartScan from '@pages/AiAssistantStartScan';
import AiAssistantActivity from '@pages/AiAssistantActivity';
import ANoComponentFound from '@atoms/ANoComponentFound/ANoComponentFound';
import { Program } from '@organisms/OProgram';
import { ListEvaluation } from '@pages/AiAssistant/Conversation/ListEvaluation';
import { LetsMoveSessions } from '@pages/AiAssistant/Conversation/LetsMoveSessions';
import AiAssistantCreateReportModal from '@pages/AiAssistantCreateReport';
import { StartSurveyUser } from '@pages/AiAssistantStartSurvey/StartSurveyUser';
import { Programs } from '@pages/AIAssistantProgram';
import AiAssistantNotFoundComponent from '@pages/AiAssistantNotFoundComponent';
import PostureScan from '@pages/PostureScan';
import AiPostureSummary from '@pages/AiPosture/AiPostureSummary';
import { PostureCaptures } from '@pages/AiAssistantPostureCaptures';
import { CustomCaptures } from '@pages/AiAssistantCustomCaptures';
import CoachRom from '@pages/Rom';
import { AiAssistant } from '@pages/AiAssistant';
import { router } from '@routers/routers';
import NewPatientOnboardDashboard from '@pages/NewPatientOnboardDashboard';
import SelectedProgram from '@pages/PatientDashboard/SelectedProgram';
import DashboardInviteCode from '@pages/DashboardInviteCode/DashboardInviteCode';

const Patient = () => (
	<>
		<Routes>
			<Route path={router.ROOT} element={<Layoult />}>
				<Route path={router.ROOT} element={<AiAssistant />}>
					<Route index element={<NewPatientOnboardDashboard />} />
					<Route path={router.USERACTIVITY} element={<AiAssistantActivity />} />
					<Route path={router.USERVIRTUALEVALUATION} element={<AiVirtualAssessment />} />
					<Route path={router.USERVIRTUALEVALUATIONRESULT} element={<ListEvaluation />} />
					<Route path={router.USERROMSUMMARY} element={<AiAssistantRomSummary />} />
					<Route path={router.USERPROGRAMSTART} element={<Program />} />
					<Route path={router.USERPAGENOTFOUND} element={<ANoComponentFound />} />
					<Route path={router.USERSEARCHNOTFOUND} element={<AiAssistantNotFoundComponent />} />
					<Route path={router.USERROMSCAN} element={<AiAssistantStartScan />} />
					<Route path={router.USERROMSTARTSCAN} element={<CoachRom />} />
					<Route path={router.USERROMCAPTURES} element={<CustomCaptures />} />
					<Route path={router.POSTURE_ANALYTICS_SCAN} element={<PostureScan />} />
					<Route path={router.POSTURE_ANALYTICS_SUMMARY} element={<AiPostureSummary />} />
					<Route path={router.POSTURE_ANALYTICS_CAPTURES} element={<PostureCaptures />} />
					<Route path={router.PROGRAM_CREATE} element={<Programs />} />
					<Route path={router.PROGRAM_SUMMARY} element={<LetsMoveSessions />} />
					<Route path={router.SURVEY_START} element={<StartSurveyUser />} />
					<Route path={router.SURVEY_SUMMARY} element={<AiAssistantSurveySummary />} />
					<Route path={router.REPORT_CREATE} element={<AiAssistantCreateReportModal />} />
					<Route path={router.USERREPORTSUMMARY} element={<AiAssistantMyReport />} />
					<Route path={router.USER_DOWNLOAD_APP} element={<DashboardInviteCode />} />
					<Route path={":userId/overview"} element={<SelectedProgram />} />
				</Route>
			</Route>
		</Routes>
	</>
);

export default Patient;
