import { useEffect, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import animationData from './coord.json';
import './style.css';

interface ITransitionClosingProps {
	isPaused: boolean;
	onNextTransition: () => void;
}

function TransitionClosing(props: ITransitionClosingProps) {
	const lottieRef = useRef<LottieRefCurrentProps>(null);

	useEffect(() => {
		if (props?.isPaused) lottieRef.current?.pause();
		else {
			lottieRef.current?.play();
		}
	}, [props]);

	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 3,
				width: '100%',
			}}>
			<Lottie
				lottieRef={lottieRef}
				loop={false}
				autoplay={true}
				animationData={animationData}
				style={{ width: '100%', height: '100%' }}
				onComplete={props.onNextTransition}
			/>
		</div>
	);
}

export default TransitionClosing;
