import { IPosture, IPostureData, IRomPatientResult, PostureAnalyticsItem } from "@stores/interfaces"
import { Col, Empty, Image, message, Row } from "antd"
import moment from "moment"
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import AddNotes from "./AddNotes"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { updateReportNotes } from "@stores/report/reports"
import CoachPostureSummary from "@pages/AiPosture/CoachPostureSummary"
import ReportNotesDownloadTemplate from "./ReportNotes/ReportNotesDownloadTemplate"
import ReportNotesTemplate from "./ReportNotes/ReportNotesTemplate"

interface ReportRomCapturesDataProps {
  isEditMode: boolean
  isDownload?: boolean;
}

export default function ReportPostureData(props: ReportRomCapturesDataProps) {
  const { isEditMode, isDownload } = props
  const { t } = useTranslation();
  const report = useTypedSelector(state => state.reports.report)
  const [postureSessionsNotes, setPostureSessionNotes] = useState<string>()
  const dispatch = useTypedDispatch()

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      postureSessionsNotes: {
        notes: postureSessionsNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setPostureSessionNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('postureSessionsNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId || ''
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  return (
    <>
      <div className='pb-2 pt-2'>
        {!isDownload ? <>
          <p className="font-bold text-base text-gray-600 m-2 text-center">{t("Patient.data.postures.postureData")}</p>
          <div className="bg-gray-100 rounded-lg pt-1">
            <Row gutter={[14, 8]} style={{ display: "flex", alignItems: "center", columnGap: "40px", paddingLeft: "20px" }}>
              {report?.postureSessionsIds?.map((item: IPosture) => {
                if (item.postureAnalytics.length > 0) {
                  return (
                    <>
                      <p className="font-semibold text-gray-600 mt-2">
                        {t('Patient.data.myProgress.evaluation.dateOfAssessment')}{' '}
                        <span className="font-regular">
                          {moment(item.createdAt).local().format('LLL')}
                        </span>
                      </p>
                      <CoachPostureSummary item={item} />
                    </>
                  );
                }
                return null;
              })}
            </Row>
          </div>
        </> :
          <>
            <p className="font-bold text-base text-gray-600 mt-5 text-center mb-5">
              {t("Patient.data.postures.postureData")}
            </p>
            {report?.postureSessionsIds?.map((item: IPosture) => (
              <Fragment key={item.id}>
                {item?.postureAnalytics?.map((analyticsItem: PostureAnalyticsItem) => (
                  <Fragment key={analyticsItem.id}>
                    <p className="py-[15px] text-gray-600">
                      <b className="font-bold">
                        {t("Admin.data.addToReports.sessionDate")}
                      </b>{" "}
                      {moment(analyticsItem.createdAt).local().format("LLL")}
                    </p>
                    <div className="p-[15px] my-2.5 bg-gray-100 mt-1 rounded-lg border-1 border-inherit flex flex-col gap-2 text-sm text-gray-500">
                      <p className="font-normal">
                        <b className="font-bold">
                          {t("Admin.data.addToReports.value")}{" "}
                        </b>
                        {analyticsItem.view}
                      </p>
                      <p className="font-normal">
                        <b className="font-bold">
                          {t("Admin.data.addToReports.screenshot")}{" "}
                        </b>
                        <a href={analyticsItem.screenshot} target="_blank" rel="noreferrer">
                          {analyticsItem.screenshot}
                        </a>
                      </p>
                      <p className="font-normal">
                        <p>
                          <b>{t("Admin.data.addToReports.ear")}</b> : {analyticsItem.ear}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.head")}</b> : {analyticsItem.head}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.ankle")}</b> : {analyticsItem.ankle}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.elbow")}</b> : {analyticsItem.elbow}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.hip")}</b> : {analyticsItem.hip}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.knee")}</b> : {analyticsItem.knee}
                        </p>
                        <p>
                          <b>{t("Admin.data.addToReports.shoulder")}</b> : {analyticsItem.shoulder}
                        </p>

                      </p>
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            ))}

          </>
        }
        {
          report?.postureSessionsNotes?.some(
            (note) =>
              note.notes?.trim()
          ) && (<>
            {report?.postureSessionsNotes?.length! > 0 ? (
              <>
                <div className="text-left mt-3 mb-3">
                  <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
                </div>
                {!isDownload ? <>
                  {report?.postureSessionsNotes?.map((note, index) => {
                    return <ReportNotesTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                  })}</> : <>
                  {report?.postureSessionsNotes?.map((note, index) => {
                    return <ReportNotesDownloadTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                  })}
                </>}
              </>) : null}</>)}
        {
          report?.postureSessionsIds?.length != 0 && !isDownload ?
            <AddNotes sendMessage={sendMessage} onClick={() => handleSubmit()} onChange={(event) => setPostureSessionNotes(event.target.value)} />
            : null
        }
      </div>
    </>
  )
}
