import { Modal, Tooltip, message } from 'antd';
import { useTypedSelector } from '@stores/index';
import moment from 'moment';
import SummuryOptionsList from './SummaryOptionsList';
import {
	TDataProps,
	TPainAssessmentProps,
	TStrapiHealthSigns,
	TStrapiMedicalHistories,
} from '@stores/interfaces';
import { Copy07 } from '@carespace-icons/general/copy07';
import { Download01 } from '@carespace-icons/general/download01';
import html2pdf from 'html2pdf.js';
import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
interface IRomModalExportInfosProps {
	onOk: () => void;
	onCancel: () => void;
	isModalVisible: boolean;
	selectedItems: TDataProps[];
}

function ButtonExportPainAssessment(props: IRomModalExportInfosProps) {
	const { isModalVisible, onOk, onCancel, selectedItems } = props;

	const user = useTypedSelector(state => state.user);
	const { t } = useTranslation();
	const copyToClipboard = () => {
		message.success(
			t('Patient.data.virtualEvaluation.summary.copyToClipboard'),
		);
		navigator.clipboard
			.writeText(document.getElementById('summary')!.innerText)
			.catch((error: Error) => console.log(error));
	};

	const downloadPdf2 = async () => {
		message.success(t('Patient.data.virtualEvaluation.summary.downloaded'));
		html2pdf()
			.from(document.getElementById('summary'))
			.set({
				margin: 10,
				filename: 'Summary.pdf',
				image: { type: 'png', quality: 100 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
			})
			.outputPdf()
			.save()
			.catch((error: Error) => {
				console.log(error);
			});
	};

	return (
		<Modal
			title={t('Patient.data.myProgress.evaluation.summaryReview')}
			open={isModalVisible}
			onOk={onOk}
			onCancel={onCancel}
			footer={false}
			width={1200}
			className="select-none">
			<div className="pr-7 flex justify-end">
				<Tooltip
					placement="topRight"
					title={t('Patient.data.myProgress.evaluation.download')}
					color="purple"
					showArrow={false}>
					<span className="cursor-pointer" onClick={() => downloadPdf2()}>
						<Download01 color="stroke-gray-400" width={20} height={20} />
					</span>
				</Tooltip>
				<Tooltip
					placement="topRight"
					title={t('Patient.data.myProgress.evaluation.copy')}
					color="purple"
					showArrow={false}>
					<span
						className="cursor-pointer pl-4"
						onClick={() => copyToClipboard()}>
						<Copy07 color="stroke-gray-400" width={20} height={20} />
					</span>
				</Tooltip>
			</div>
			<div id="summary" className="font-semibold p-6">
				<ul className="grid grid-cols-3 gap-4 border-b-2 border-inherit -mt-7">
					<li className="grid text-sm text-start leading-7">
						<p className="font-semibold">
							{t('Patient.data.myProgress.evaluation.patientID')}{' '}
							<span className="font-regular">{user.id}</span>
						</p>
						<p className="font-semibold">
							{t('Patient.data.myProgress.evaluation.patientName')}{' '}
							<span className="font-regular">{user.profile.fullName}</span>
						</p>
					</li>
				</ul>

				<ul className="text-start border-inherit mt-3">
					{selectedItems.map((data: TDataProps, index: number) => {
						return (
							<div className="border-b-2 border-inherit pb-6">
								<p className="font-semibold text-gray-800 mt-3">
									{t('Patient.data.myProgress.evaluation.dateOfAssessment')}{' '}
									<span className="font-regular">
										{moment(data.createdAt).local().format('LLL')}
									</span>
								</p>
								<div className="text-left mt-3 mb-3">
									<span className="font-semibold text-base text-gray-900">
										{t('Patient.data.myProgress.evaluation.painSummary')}
									</span>
								</div>
								<ul className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
									{data?.painAssessments?.map((item: TPainAssessmentProps) => {
										return (
											<li className="border-b-2 border-inherit" key={index}>
												<SummuryOptionsList item={item} />
											</li>
										);
									})}
								</ul>
								<div className="text-left mt-3 mb-3">
									<span className="font-semibold text-base text-gray-900">
										{t('Patient.data.myProgress.evaluation.associatedSymptoms')}
									</span>
								</div>
								<ul className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
									<div className="border-b-2 border-inherit p-4">
										<span className="font-bold text-sm text-gray-700">
											{t(
												'Patient.data.myProgress.evaluation.doYouHaveAnyOfTheseSymptoms',
											)}
										</span>
									</div>
									{data?.healthSigns?.strapiHealthSigns?.map(
										(element: TStrapiHealthSigns, index: number) => {
											return (
												<li
													className="grid text-sm mb-1 mt-1 leading-7 pl-4"
													key={index}>
													<span className="font-regular">
														{element.attributes.name}
													</span>
												</li>
											);
										},
									)}
								</ul>
								<div className="text-left mt-3 mb-3">
									<span className="font-semibold text-base text-gray-900">
										{t('Patient.data.myProgress.evaluation.medicalHistory')}
									</span>
								</div>
								<ul className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
									<div className="border-b-2 border-inherit p-4">
										<span className="font-bold text-sm text-gray-700">
											{t(
												'Patient.data.myProgress.evaluation.haveYouBeenDiagnosedWithAnyOfTheFollowing',
											)}
										</span>
									</div>
									{data?.medicalHistories?.strapiMedicalHistories?.map(
										(data: TStrapiMedicalHistories, i: number) => {
											return (
												<li
													className="grid text-sm mb-1 mt-1 leading-7 pl-4"
													key={i}>
													<span className="font-regular">
														{data.attributes.name}
													</span>
												</li>
											);
										},
									)}
								</ul>
							</div>
						);
					})}
				</ul>
			</div>
		</Modal>
	);
}

export default ButtonExportPainAssessment;
