import { useEffect, useState } from 'react';
import { PosturalAnalytics } from '../context/Controls.context';

export const useControls = (
	initialArray: Partial<PosturalAnalytics>[] = [],
) => {
	const [array, setArray] = useState<Partial<PosturalAnalytics>[]>([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const isCompleted = !!array.length && array.every(item => item.completed);

	useEffect(() => {
		if (initialArray.length === 0) return;

		setArray(
			initialArray?.map(item => ({
				...item,
				completed: false,
			})),
		);
		setCurrentIndex(0);
	}, [initialArray]);

	const onNext = (startIndex?: number): Partial<PosturalAnalytics> | null => {
		let index = currentIndex;

		if (array.length === 0) return null;

		if (startIndex) {
			index = startIndex;
			setCurrentIndex(index);
		}

		while (index < array.length && array[index].completed) {
			index++;
		}

		if (index < array.length) {
			setCurrentIndex(index);
			return array[index];
		} else {
			return null;
		}
	};

	const onMarkAsCompleted = () => {
		if (currentIndex >= 0 && currentIndex < array.length) {
			const newArray = [...array];
			newArray[currentIndex].completed = true;
			setArray(newArray);
			onNext();
		}
	};

	const onReset = () => {
		const resetArray = array.map(item => ({
			...item,
			completed: false,
		}));
		setArray(resetArray || []);
		setCurrentIndex(0);
	};

	const onGetCurrent = (
		isReturnIndex = false,
	): number | Partial<PosturalAnalytics> => {
		if (isReturnIndex) return currentIndex;

		return array[currentIndex];
	};

	return { onNext, onReset, onGetCurrent, onMarkAsCompleted, isCompleted };
};
