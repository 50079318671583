import Stepper from '@atoms/Stepper';
import MBodyPoints from '@molecules/MBodyPoints';
import React, { useEffect, useState } from 'react';
import AssociatedSymptoms from './AssociatedSymptoms';
import MedicalHistory from './MedicalHistory';
import Button from '@atoms/Button';
import './style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import SummaryReview from './SummaryReview';
import { useNavigate } from 'react-router-dom';
import {
	painAssessmentInfoAction,
	saveEvaluation,
} from '@stores/painAssessment';
import { Spin, message } from 'antd';
import { myLibraryInfoAction } from '@stores/library';
import {
	PainAssessments,
	HealthSigns,
	Options,
	THealthSignsOptions,
	MedicalHistories,
	EvaluationData,
} from './interface';
import { useTranslation } from 'react-i18next';
import {
	getHealthSignOptions,
	getEvaluationData,
	getMedicalHistoriesOptions,
} from '@stores/myLibrary/myLibrary';
import { setActiveTab, setStateId } from '@stores/aiAssistant/aiAssistant';

const PainAssessment: React.FC = () => {
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const [activeStep, setActiveStep] = useState<number>(0);
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();
	const steps = [
		{
			label: t('Patient.data.virtualEvaluation.painAssesment.title'),
			sublabel: t('Patient.data.virtualEvaluation.painAssesment.description'),
			onClick: () => setActiveStep(0),
		},
		{
			label: t('Patient.data.virtualEvaluation.associatedSymptoms.title'),
			sublabel: t(
				'Patient.data.virtualEvaluation.associatedSymptoms.description',
			),
			onClick: () => setActiveStep(1),
		},
		{
			label: t('Patient.data.virtualEvaluation.medicalHistory.title'),
			sublabel: t('Patient.data.virtualEvaluation.medicalHistory.description'),
			onClick: () => setActiveStep(2),
		},
		{
			label: t('Patient.data.virtualEvaluation.summary.title'),
			sublabel: t('Patient.data.virtualEvaluation.summary.description'),
			onClick: () => setActiveStep(3),
		},
	];
	const [list, setList] = useState<THealthSignsOptions[]>([]);
	const [savedSymptomsData, setSavedSymptomsData] = useState<HealthSigns>();
	const [savedBodyPointData, setSavedBodyPointData] = useState<number[]>([]);
	const [medicalHistoryOptionsData, setMedicalHistoryOptionsData] = useState<
		Options[]
	>([]);
	const [savedMedicalHistoryData, setSavedMedicalHistoryData] =
		useState<MedicalHistories>();
	const [savedEvaluationData, setSavedEvaluationData] =
		useState<EvaluationData>();
	const [apiDataLoaded, setApiDataLoaded] = useState(false);

	useEffect(() => {
		fetchSavedData();
		getOptionsData();
	}, [selectedUser]);

	const getOptionsData = async () => {
		fetchHelthSigns();
		fetchMedicalHistory();
	};

	const fetchSavedData = async () => {
		setIsLoading(true);
		let id = user.isPhysioterapist ? selectedUser?.id : user.id;
		const payload = {
			userId: id,
			page: 1,
			limit: 1,
		};
		const data = await dispatch(getEvaluationData(payload));
		const apiData = data.payload;
		if (apiData.data.length > 0) {
			setSavedEvaluationData(apiData.data[0]);
			setSavedBodyPointData(
				apiData.data[0].painAssessments.map(
					(element: PainAssessments) => element.strapiBodyPointId,
				),
			);
			setSavedSymptomsData(apiData.data[0].healthSigns);
			setSavedMedicalHistoryData(apiData.data[0].medicalHistories);
		}
		setApiDataLoaded(true);
		setIsLoading(false);
	};

	const fetchHelthSigns = async () => {
		setIsLoading(true);
		try {
			const data = await dispatch(getHealthSignOptions());
			let apiData = data?.payload?.data;
			dispatch(myLibraryInfoAction.healthSignOptionsInfo(apiData));
			setList(apiData);
			dispatch(
				painAssessmentInfoAction.associatedSymptomsInfo(
					savedEvaluationData?.healthSigns,
				),
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchMedicalHistory = async () => {
		setIsLoading(true);
		try {
			const data = await dispatch(getMedicalHistoriesOptions());
			let apiData = data?.payload?.data;
			dispatch(myLibraryInfoAction.medicalHistoriesOptionsinfo(apiData));
			setMedicalHistoryOptionsData(apiData);
			dispatch(
				painAssessmentInfoAction.medicalHistoryInfo(
					savedEvaluationData?.medicalHistories,
				),
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleStepClick = (stepIndex: number) => {
		setActiveStep(stepIndex);
	};

	const handleSave = async () => {
		let payload: any = {
			painAssessments: [],
			healthSigns: { strapiHealthSignsIds: [], notes: '' },
			medicalHistories: { strapiMedicalHistoriesIds: [] },
		};
		if (activeStep === 1) {
			payload = {
				strapiHealthSignsIds: Array.isArray(
					savedSymptomsData?.strapiHealthSignsIds,
				) && [...savedSymptomsData?.strapiHealthSignsIds],
				id: savedSymptomsData?.id,
				userId: savedSymptomsData?.userId,
				notes: '',
			};
		} else if (activeStep === 2) {
			payload = {
				strapiMedicalHistoriesIds: Array.isArray(
					savedMedicalHistoryData?.strapiMedicalHistoriesIds,
				) && [...savedMedicalHistoryData?.strapiMedicalHistoriesIds],
				id: savedMedicalHistoryData?.id,
				userId: savedMedicalHistoryData?.userId,
				notes: '',
			};
		} else if (activeStep === 3) {
			payload = {
				userId: user.isPhysioterapist ? selectedUser?.id : user.id,
				painAssessments: savedEvaluationData?.painAssessments.map(item => {
					const {
						id,
						painLevel,
						strapiBodyPointId,
						strapiAggravatingFactorsIds,
						strapiRelievingFactorsIds,
						strapiPainCausesIds,
						strapiPainDurationId,
						strapiPainFrequencyId,
						strapiPainStatusId,
						notes = '',
					} = item;
					const painAssessment: PainAssessments = {
						userId: savedEvaluationData.userId,
						painLevel: painLevel ?? 5,
						strapiBodyPointId,
						strapiAggravatingFactorsIds,
						strapiRelievingFactorsIds,
						strapiPainCausesIds,
						strapiPainDurationId,
						strapiPainFrequencyId,
						strapiPainStatusId,
						notes,
					};
					if (id) painAssessment.id = id;
					return painAssessment;
				}),
				healthSigns: {
					strapiHealthSignsIds: savedSymptomsData?.strapiHealthSignsIds,
					id: savedSymptomsData?.id,
					userId: savedSymptomsData?.userId,
					notes: '',
				},
				medicalHistories: {
					strapiMedicalHistoriesIds:
						savedMedicalHistoryData?.strapiMedicalHistoriesIds,
					id: savedMedicalHistoryData?.id,
					userId: savedMedicalHistoryData?.userId,
					notes: '',
				},
			};
			savedData(payload);
			return;
		}
		savedData(payload);
	};

	const savedData = async (payload: any) => {
		if (activeStep == 1) {
			if (payload?.strapiHealthSignsIds?.length > 0) {
				let filterdData =
					savedSymptomsData?.strapiHealthSignsIds.filter(
						item => item != payload,
					) || [];
				filterdData.push(payload);
				setSavedSymptomsData(payload);
				dispatch(painAssessmentInfoAction.associatedSymptomsInfo(payload));
				setActiveStep(2);
			} else {
				message.error(
					t(
						'Patient.data.virtualEvaluation.associatedSymptoms.pleaseSelectAtLeastOneOption',
					),
				);
			}
		} else if (activeStep == 2) {
			if (payload?.strapiMedicalHistoriesIds?.length > 0) {
				let filterdData =
					savedMedicalHistoryData?.strapiMedicalHistoriesIds.filter(
						item => item != payload,
					) || [];
				filterdData.push(payload);
				setSavedMedicalHistoryData(payload);
				dispatch(painAssessmentInfoAction.medicalHistoryInfo(payload));
				setActiveStep(3);
			} else {
				message.error(
					t(
						'Patient.data.virtualEvaluation.associatedSymptoms.pleaseSelectAtLeastOneOption',
					),
				);
			}
		} else if (activeStep == 3) {
			try {
				const data = await saveEvaluation(payload);
				message.success(t('Patient.data.dataNotifications.dataSave'));
				dispatch(setActiveTab('listEvaluation'));
				dispatch(setStateId(data.id));
			} catch (error) {
				console.log(error);
				message.error('Error Saving Data');
			} finally {
				setIsLoading(false);
			}
		} else {
			setActiveStep(1);
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	return (
		<div className="pain-assessment select-none">
			<div className="h-32 pl-40 pr-40 relative mt-4">
				<Stepper
					steps={steps}
					activeStep={activeStep}
					setActiveStep={handleStepClick}
					isStepperClickuble={false}
				/>
			</div>
			{isLoading ? (
				<div className="text-center mt-10">
					<Spin tip="Loading" size="large" />
				</div>
			) : (
				<>
					{activeStep === 0 && apiDataLoaded && (
						<MBodyPoints
							assessmentData={savedEvaluationData?.painAssessments || []}
							savedBodyPointData={savedBodyPointData}
							savedEvaluationData={savedEvaluationData}
							setSavedEvaluationData={setSavedEvaluationData}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
						/>
					)}
					{activeStep === 1 && apiDataLoaded && (
						<div className="text-center">
							<div className="font-bold text-xl mb-5" style={{color:'var(--text-color)'}}>
								{t(
									'Patient.data.myProgress.evaluation.doYouHaveAnyOfTheseSymptoms',
								)}
							</div>
							<div className="border-2 bg-gray-50 shadow-md w-fit inline-block" style={{borderRadius:'16px'}}>
								<AssociatedSymptoms
									list={list}
									savedSymptomsData={savedSymptomsData || []}
									setSavedSymptomsData={setSavedSymptomsData}
								/>
							</div>
						</div>
					)}
					{activeStep === 2 && apiDataLoaded && (
						<div className="text-center">
							<div className="font-bold text-xl mb-5" style={{color:'var(--text-color)'}}>
								{t(
									'Patient.data.myProgress.evaluation.haveYouBeenDiagnosedWithAnyOfTheFollowing',
								)}
							</div>
							<div className="border-2 bg-gray-50 shadow-md w-fit inline-block" style={{borderRadius:'16px'}}>
								<MedicalHistory
									medicalHistoryOptionsData={medicalHistoryOptionsData}
									savedMedicalHistoryData={savedMedicalHistoryData || []}
									setSavedMedicalHistoryData={setSavedMedicalHistoryData}
								/>
							</div>
						</div>
					)}
					{activeStep === 3 && (
						<div className="text-center mt-5">
							<div className="mb-5 grid">
								<span className="text-4xl font-semibold" style={{color:'var(--text-color-root)'}}>
									{t('Patient.data.virtualEvaluation.summary.title')}
								</span>
								<span className="font-regular text-xl text-gray-600 mt-3">
									{t('Patient.data.virtualEvaluation.summary.description2')}
								</span>
							</div>
							<div className="w-fit inline-block">
								<SummaryReview
									list={list}
									medicalHistoryOptionsData={medicalHistoryOptionsData}
									savedEvaluationData={savedEvaluationData}
								/>
							</div>
						</div>
					)}
					<div className="text-center mt-4 mb-5">
						{activeStep > 0 && (
							<span className="mr-4">
								<Button
									children={t('Patient.data.virtualEvaluation.back')}
									onClick={() => handleBack()}
								/>
							</span>
						)}
						<Button
							children={`${activeStep == 3
								? t('Patient.data.virtualEvaluation.submit')
								: t('Patient.data.virtualEvaluation.next')
								}`}
							onClick={() => handleSave()}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default PainAssessment;
