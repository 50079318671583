import { FaceFrown } from "@carespace-icons/users/faceFrown";
import { FaceHappy } from "@carespace-icons/users/faceHappy";
import { FaceNeutral } from "@carespace-icons/users/faceNeutral";
import { FaceSad } from "@carespace-icons/users/faceSad";
import { FaceSmile } from "@carespace-icons/users/faceSmile";
import { FaceWink } from "@carespace-icons/users/faceWink";
import { Rate as Rating } from 'antd';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const wordToNumber = {
	'zero': 0,
	'one': 1,
	'two': 2,
	'three': 3,
	'four': 4,
	'five': 5,
	'six': 6,
	'seven': 7,
	'eight': 8,
	'nine': 9,
	'ten': 10
};

const hoverColors: StrokeColors[] = [
	"stroke-blue-400",
	"stroke-green-300",
	"stroke-green-500",
	"stroke-green-400",
	"stroke-yellow-200",
	"stroke-yellow-400",
	"stroke-orange-300",
	"stroke-orange-400",
	"stroke-rose-500",
	"stroke-rose-600",
	"stroke-rose-700",
]

interface RateProps {
	activeStep: number,
	setActiveStep: (activeStep: number) => void,
	setPainLevel: (painLevel: number) => void
	savedVoice: string
}

export const Rate = (props: RateProps) => {

	const { activeStep, setActiveStep, setPainLevel, savedVoice } = props
	const [hoverIndex, setHoverIndex] = useState(-1);
	const { t } = useTranslation()

	useEffect(() => {
			const spokenNumber = savedVoice.trim().toLowerCase(); 
			if (wordToNumber.hasOwnProperty(spokenNumber)) {
				const numberValue = wordToNumber[spokenNumber];
				setPainLevel(numberValue);
				setActiveStep(activeStep + 1);
			} else {
				const numericValue = parseInt(spokenNumber); 
				if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10) {
					setPainLevel(numericValue);
					setActiveStep(activeStep + 1);
				} 
		}
	}, [savedVoice]);
	
	const getColor = (index: number) => {
		return index < hoverIndex ? hoverColors[index] : "stroke-gray-300"
	};

	const ratingIcons = {
		0: <FaceHappy color={getColor(0)} width={36} height={36} />,
		1: <FaceWink color={getColor(1)} width={36} height={36} />,
		2: <FaceSmile color={getColor(2)} width={36} height={36} />,
		3: <FaceSmile color={getColor(3)} width={36} height={36} />,
		4: <FaceNeutral color={getColor(4)} width={36} height={36} />,
		5: <FaceNeutral color={getColor(5)} width={36} height={36} />,
		6: <FaceSad color={getColor(6)} width={36} height={36} />,
		7: <FaceSad color={getColor(7)} width={36} height={36} />,
		8: <FaceFrown color={getColor(8)} width={36} height={36} />,
		9: <FaceFrown color={getColor(9)} width={36} height={36} />,
		10: <FaceFrown color={getColor(10)} width={36} height={36} />,
	};

	const handleRating = (value: number) => {
		setPainLevel(value - 1)
		setActiveStep(activeStep + 1);
	}

	return (
		<div className="flex flex-col items-center select-none">
			<p className="mt-[130px] mb-[15px] w-[468px] h-[28px] text-white text-lg font-semibold text-center">{t('Admin.data.rehab.rehabPreAssessment.rateYourPain')}</p>
			<div className="h-[90px] w-[530px] rounded-xl bg-[#F9FAFB1A]">
				<div className="flex justify-around items-center m-3">
					<Rating
						count={11}
						character={({ index }) => {
							return <div className="flex justify-around items-center">
								<div className="flex flex-col items-center">
									<p className="font-semibold text-[#D0D5DD] text-center text-sm mb-1">{index}</p>
									{ratingIcons[index]}
								</div>
							</div>
						}}
						onChange={handleRating}
						onHoverChange={(index) => {
							setHoverIndex(index);
						}}
					/>
				</div>
			</div>
		</div>
	);
};
