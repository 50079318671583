import axios from 'axios';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
	IPerformanceExercises,
	IRomPainAssessmentInitialState,
	IUsers,
	IPagination,
	IContactsInitialState,
	IRehabAssignedExercisesForPatient,
	IContactsRomPatientsResults,
	IRehabExerciseRequestParam,
	IGraphSessionRequestParam,
	StrapiOmniRomExercises,
} from '@stores/interfaces';

import { updateRehabSessionStatus as updateRehabSessionStatusOutOfParams } from '@stores/outOfParams';
import { updateRehabSessionStatus as updateRehabSessionStatusPendingReview } from '@stores/pendingReview';
import { updateRehabSessionStatus as updateRehabSessionStatusReviewed } from '@stores/reviewed';
import { updateRehabSessionStatus as updateRehabSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateRehabSessionStatus as updateRehabSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import { stringify } from 'qs';
import { ReduxState } from '..';
import strapi from '@strapi';
import { STORAGE_KEY_USER } from '@constants/authContants';

export const getUsers = createAsyncThunk(
	'getUsers',
	async (name?: string): Promise<IUsers[]> => {
		const { data } = await axios.get(`/users?name=${name}`);
		return data;
	},
);

export const getUsersPagination = createAsyncThunk(
	'getUsersPagination',
	async (pagination: IPagination): Promise<IPagination> => {
		const { data } = await axios.post('/users/pagination');
		return data;
	},
);

export const getUsersPaginationFilter = createAsyncThunk(
	'getUsersPaginationFilter',
	async body => {
		const { data } = await axios.post('/users/pagination/filter');
		return data;
	},
);

export const getExercises = createAsyncThunk(
	'contacts/rom/fetchExercises',
	async (_, thunkAPI): Promise<StrapiOmniRomExercises[]> => {
		const { getState } = thunkAPI;
		const state = getState() as ReduxState;

		let filters = {};
		if (state?.rom?.main?.strapiOmniRomExerciseGroupId) {
			filters = {
				omniRomExerciseGroups: {
					id: state.rom.main.strapiOmniRomExerciseGroupId,
				},
			};
		}

		const query = stringify({
			filters,
			sort: ['order:asc'],
			populate: {
				video: {
					fields: 'url',
				},
				omniRomExerciseGroups: true,
				reference: true,
			},
		});
		// TODO: optimize query select only necessary fields

		const { data } = await strapi.get(`/omni-rom-exercises?${query}`);
		return data.data;
	},
);

export const getCoach = createAsyncThunk(
	'getCoach',
	async (dataToRequest: string): Promise<IContactsRomPatientsResults> => {
		const { data } = await axios.get(`/rom/patients/${dataToRequest}/results`);
		return data;
	},
);

export const getContacts = createAsyncThunk('getContacts', async () => {
	const { data } = await axios.get('users');
	return data;
});

export const getPainAssessments = createAsyncThunk(
	'getPainAssessments',
	async (user: string): Promise<IRomPainAssessmentInitialState> => {
		const { data } = await axios.get(`/rom/patients/${user}/pain-assessments`);
		return data;
	},
);

export const getPerformanceExercises = createAsyncThunk(
	'getPerformanceExercises',
	async (): Promise<IPerformanceExercises> => {
		const { data } = await axios.get(`/performance/exercises`);
		return data;
	},
);

export const getPerformanceUserProgress = createAsyncThunk(
	'getPerformanceUserProgress',
	async (user: string): Promise<IPerformanceExercises> => {
		const { data } = await axios.get(`/performance/progress/patients/${user}`);
		return data;
	},
);

export const getRehabAssignedExercisesFromPatient = createAsyncThunk(
	'getRehabAssignedExercisesFromPatient',
	async (patientId: string): Promise<IRehabAssignedExercisesForPatient> => {
		const { data } = await axios.get(
			`/rehab/patients/${patientId}/exercises?all=true`,
		);
		return data;
	},
);

export const getRehabExercisesFromPatient = createAsyncThunk(
	'getRehabExercisesFromPatient',
	async (payload: IRehabExerciseRequestParam) => {
		const { patientId, page, limit } = payload;
		const { data } = await axios.get(
			`/rehab/patients/${patientId}/sessions?limit=${limit}&page=${page}`,
		);
		return data;
	},
);

export const createAccount = createAsyncThunk(
	'createAccount',
	async credentials => {
		const { data } = await axios.post('auth/register', credentials);
		return data;
	},
);

export const removeUser = createAsyncThunk('removeUser', async user => {
	const { data } = await axios.delete('users/delete', { data: { user } });
	return data;
});

export const coachGetUserResults = createAsyncThunk(
	'coachGetUserResults',
	async userId => {
		const { data } = await axios.get(`/rehab/patients/${userId}/evaluations`);
		return data;
	},
);

export const setRehabAssignedExercisesFromPatient = createAsyncThunk(
	'rehabAssignedExercisesFromPatient',
	async body => {
		const { data } = await axios.patch(
			`/rehab/exercises/${body?.id}`,
			body?.data,
		);

		return data;
	},
);

export const getGraphSessionData = createAsyncThunk(
	'getGraphSessionData',
	async (payload: IGraphSessionRequestParam) => {
		const { patientId, rehabExerciseToPatientId } = payload;
		const { data } = await axios.get(
			`/rehab/patients/${patientId}/results/${rehabExerciseToPatientId}?limit=1000&page=1`,
		);
		return data;
	},
);

export const getUserById = createAsyncThunk(
	'getUserById',
	async (userId: string, { dispatch }) => {
		const response = await axios.get(`/users/${userId}`);
		dispatch(setSelectUser(response.data));
		return response.data;
	},
);

const local = localStorage.getItem('selectedUser');

const initialState: IContactsInitialState = {
	userDropDownList: [],
	users: null,
	selectedUsers: [],
	rehabSelectedUsers: [],
	backList: true,
	rehabAction: null,
	selectedUser:
		local !== 'undefined' && local !== null ? JSON.parse(local) : null,
	pagination: {
		totalDocs: 0,
		offset: 0,
		limit: 10,
		totalPages: 0,
		page: 1,
		pagingCounter: 1,
		hasPrevPage: false,
		hasNextPage: false,
		prevPage: null,
		nextPage: 2,
	},
	contacts: [],
	isContactsListLoaded: false,
	wasActionExecuted: null,
	coach: null,
	exercises: null,
	preAssessment: [],
	weeklyAssessment: [],
	gallery: {
		exerciseImages: [],
		images: [],
		imagesToCompare: [],
	},
	coachUserExercisesResults: [],
	performance: {
		exercises: [],
		results: [],
	},
	createAccount: {
		status: false,
		fieldErrors: [],
	},
	graphSessionData: {
		data: [],
		pagination: null,
	},
	startUserSession: false,
	rehabExercisesFromUser: false,
	rehabPatientExercises: [],
	rehabPatientEvaluations: [],
	rehabAssignedExercisesForPatient: null,
	sessions: null,
	rehabSessions: null,
	ptDashboard: {
		userId: '',
		activeTab: '',
	},
};

export const contacts = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		saveState: (state, action) => {
			state.ptDashboard.activeTab = action.payload.activeTab;
			state.ptDashboard.userId = action.payload.userId;
		},
		clearState: state => {
			state.ptDashboard.activeTab = '';
			state.ptDashboard.userId = '';
		},
		backToList: (state, action) => {
			state.backList = !state.backList;
			state.startUserSession = !state.startUserSession;
		},
		changeExerciseStatus: (state, action) => {
			const newExerciseData = action.payload;
			state.coachUserExercisesResults = state.coachUserExercisesResults.map(
				element => {
					element.exercises.map(item => {
						if (item.exercise.id === newExerciseData.id)
							item.exercise = newExerciseData;
						return item;
					});
					return element;
				},
			);
		},
		clearHistory: (state, action) => {
			state.wasActionExecuted = true;
		},
		goRehab: (state, action) => {
			state.rehabAction = action.payload;
			state.backList = true;
		},
		rehabSelectUsers: (state, action) => {
			const { checked, user } = action.payload;
			state.rehabExercisesFromUser = false;
			state.rehabSelectedUsers = checked
				? [...new Set([...state.rehabSelectedUsers, user])]
				: state.rehabSelectedUsers.filter(userData => userData.id !== user.id);
			state.startUserSession = false;
		},
		selectUser: (state, action) => {
			const user = action.payload;
			state.selectedUser = state.users?.find((usr) => usr.id === user);
			localStorage.setItem('selectedUser', JSON.stringify(state.selectedUser));
		},
		setSelectUser: (state, action) => {
			state.selectedUser = action.payload;
			localStorage.setItem('selectedUser', JSON.stringify(state.selectedUser));
		},
		selectUsers: (state, action) => {
			const { checked, user } = action.payload;
			state.selectedUser = checked
				? [...new Set([...state.selectedUsers, user])]
				: state.selectedUsers.filter(_id => _id !== user);
		},
		selectAllUsers: (state, action) => {
			const { checked } = action.payload;
			state.selectedUsers = checked ? state.users?.map(user => user.id) : null;
		},
		unselectUser: (state, action) => {
			state.selectedUser = null;
			state.rehabSelectedUsers = [];
			state.backList = false;
			state.startUserSession = false;
		},
		setRole: (state, action) => {
			state.contacts = action.payload;
			state.wasActionExecuted = true;
		},
		setUsers: (state, action) => {
			state.users = action.payload;
			state.selectedUser = state.users.length ? state.users[0] : null;
		},
		resetCreateAccountStatus: (state, action) => {
			state.createAccount.status = false;
		},
		resetFeedbackStatus: (state, action) => {
			state.wasActionExecuted = null;
		},
		selectImageToCompare: (state, action) => {
			const { index, id, gallery } = action.payload;
			let galleryImages = JSON.parse(JSON.stringify(gallery?.images));

			galleryImages[id][index].isSelected =
				gallery?.imagesToCompare.length < 2
					? !galleryImages[id][index].isSelected
					: false;

			const newState = {
				images: galleryImages,
				imagesToCompare: galleryImages[id].filter(elm => elm.isSelected),
				exerciseImages: galleryImages[id],
			};

			return {
				...state,
				gallery: newState,
			};
		},
		setGalleryImages: (state, action) => {
			const { exercises, coach } = action.payload;
			const images = {};

			exercises?.forEach(elem => {
				const data = coach?.filter(
					dataItem => dataItem?.strapiOmniRomExerciseId === elem.id,
				);

				if (data?.length > 0) {
					images[elem.id] = data.map(element => {
						return {
							id: element.id,
							exercise: element.strapiOmniRomExercise,
							src: element.screenshot,
							isSelected: false,
							date: element.createdAt,
							result: element.value,
						};
					});
				}
			});

			return {
				...state,
				gallery: {
					...state.gallery,
					images: images,
				},
			};
		},
		startUserSession: (state, action) => {
			state.startUserSession = true;
		},
	},

	extraReducers: builder => {
		builder.addCase(getUsers.fulfilled, (state, action) => {
			const users = action.payload;

			state.userDropDownList = [
				...new Map(
					[...state.userDropDownList, ...users].map(user => [user.id, user]),
				).values(),
			];
		});
		builder.addCase(getUsers.rejected, (state, action) => {});

		builder.addCase(getUsersPagination.fulfilled, (state, action) => {
			const { docs, ...pagination } = action.payload;
			const docsList = Array.isArray(docs) ? [...docs] : [];

			state.users = [...docsList];
			state.pagination = { ...pagination };
			state.selectedUser = null;
			state.startUserSession = false;
		});

		builder.addCase(getUsersPaginationFilter.fulfilled, (state, action) => {
			const { docs, ...pagination } = action.payload;
			const docsList = Array.isArray(docs) ? [...docs] : [];

			state.users = [...docsList];
			state.pagination = { ...pagination };
			state.selectedUser = null;
			state.startUserSession = false;
		});

		builder.addCase(getCoach.fulfilled, (state, action) => {
			state.coach = action.payload;
		});

		builder.addCase(getContacts.fulfilled, (state, action) => {
			state.contacts = action.payload;
			state.isContactsListLoaded = true;
		});

		builder.addCase(getExercises.fulfilled, (state, action) => {
			state.exercises = action.payload;
		});

		builder.addCase(getPainAssessments.fulfilled, (state, action) => {
			state.preAssessment = action.payload;
		});

		builder.addCase(getPerformanceExercises.fulfilled, (state, action) => {
			state.performance.exercises = action.payload;
		});

		builder.addCase(getPerformanceUserProgress.fulfilled, (state, action) => {
			state.performance.results = action.payload;
		});

		builder.addCase(
			getRehabAssignedExercisesFromPatient.fulfilled,
			(state, action) => {
				state.rehabAssignedExercisesForPatient = action.payload;
			},
		);
		builder.addCase(
			getRehabAssignedExercisesFromPatient.rejected,
			(state, action) => {},
		);

		builder.addCase(getRehabExercisesFromPatient.fulfilled, (state, action) => {
			state.rehabSessions = action.payload;
			state.rehabExercisesFromUser =
				state.rehabExercisesFromUser.length > 0 ? true : false;
		});
		builder.addCase(
			getRehabExercisesFromPatient.rejected,
			(state, action) => {},
		);
		builder.addCase(coachGetUserResults.fulfilled, (state, action) => {
			state.rehabPatientEvaluations = action.payload;
		});
		builder.addCase(coachGetUserResults.rejected, (state, action) => {});

		builder.addCase(createAccount.fulfilled, (state, action) => {
			const { user } = action.payload;
			state.users = [...(state.users as IUsers[]), user];
			state.selectedUser = user;
			state.createAccount = {
				...state.createAccount,
				status: true,
				fieldErrors: [],
			};
		});
		builder.addCase(createAccount.rejected, (state, action) => {});

		builder.addCase(getGraphSessionData.fulfilled, (state, action) => {
			state.graphSessionData = action.payload;
		});
		builder.addCase(getGraphSessionData.rejected, (state, action) => {});

		builder.addCase(getUserById.fulfilled, (state, action) => {
			state.selectedUser = action.payload;
		});
		builder.addCase(getUserById.rejected, (state, action) => {});

		builder.addCase(removeUser.fulfilled, (state, action) => {
			const removedUser = action.payload;
			const newContactsList = state.users.filter(
				c => !removedUser.includes(c.id),
			);
			state.user = newContactsList;
			state.wasActionExecuted = true;
			state.selectedUser = state.users[0];
		});
		builder.addCase(removeUser.rejected, (state, action) => {});

		builder.addMatcher(
			isAnyOf(
				updateRehabSessionStatusOutOfParams.fulfilled,
				updateRehabSessionStatusReviewed.fulfilled,
				updateRehabSessionStatusFollowUpRequired.fulfilled,
				updateRehabSessionStatusEscalationRequired.fulfilled,
				updateRehabSessionStatusPendingReview.fulfilled,
			),
			(state, action) => {
				if (state.sessions?.length > 0) {
					state.sessions = state.sessions.map(session => {
						if (session.id === action.payload.id) {
							return {
								...session,
								status: action.payload.status,
							};
						}
						return session;
					});
				}
			},
		);
	},
});

export const {
	backToList,
	changeExerciseStatus,
	clearHistory,
	goRehab,
	rehabSelectUsers,
	resetCreateAccountStatus,
	resetFeedbackStatus,
	selectAllUsers,
	selectImageToCompare,
	selectUser,
	selectUsers,
	setGalleryImages,
	setRole,
	setUsers,
	startUserSession,
	unselectUser,
	setSelectUser,
	resetSelectedImageToCompare,
	saveState,
	clearState,
} = contacts.actions;

export default contacts.reducer;
