import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUser, TFormDataType, TProfileData } from '@stores/interfaces';
import { message } from 'antd';
import axios from 'axios';

export const saveEvaluation = async (payload: TFormDataType) => {
	const data = await axios.post('/evaluation', payload);
	return data?.data;
};

export const saveProfile = createAsyncThunk(
	'saveProfile',
	async ({ payload, id }: { payload: TProfileData; id: string }): Promise<IUser> => {
		try {
			const data = await axios.patch(`/users/${id}`, payload);
			return data?.data;
		} catch (error: any) {
			const errorMessage =
				error?.response?.data?.message || 'An unexpected error occurred';
			message.error(errorMessage);
			throw errorMessage;
		}
	},
);

export const updateProfileDetails = createAsyncThunk(
	'updateProfileDetails',
	async ({ payload, id }: { payload: any; id: string }): Promise<any> => {
		try {
			const { data } = await axios.patch(`/users/${id}`, payload);
			return data;
		} catch (error: any) {
			const errorMessage =
				error?.response?.data?.message || 'An unexpected error occurred';
			message.error(errorMessage);
			throw errorMessage;
		}
	},
);

export const updateEvaluation = async (payload: TFormDataType, id: string) => {
	const data = await axios.patch(`/evaluation/${id}`, payload);
	return data?.data;
};

const initialState = {
	painAssessment: [],
	associatedSymptoms: {},
	medicalHistory: {},
	optionsData: [],
	bodyPointStrapi: [],
	isContactClick: false,
	loading: false,
};

export const painAssessmentInfoConfig = createSlice({
	name: 'patient',
	initialState,
	reducers: {
		painAssessmentInfo(state, action) {
			state.painAssessment = action.payload;
		},
		associatedSymptomsInfo(state, action) {
			state.associatedSymptoms = action.payload;
		},
		medicalHistoryInfo(state, action) {
			state.medicalHistory = action.payload;
		},
		optionsInfo(state, action) {
			state.optionsData = action.payload;
		},
		bodyPointStrapiInfo(state, action) {
			state.bodyPointStrapi = action.payload;
		},
		onContactClick(state, action) {
			state.isContactClick = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(updateProfileDetails.fulfilled, (state, action) => {});

		builder.addCase(updateProfileDetails.rejected, (state, action) => {});

		builder.addCase(saveProfile.fulfilled, (state, action) => {
      console.log("111")
			state.loading = false;
		});
		builder.addCase(saveProfile.pending, (state, action) => {
      console.log("222")
			state.loading = true;
		});
		builder.addCase(saveProfile.rejected, (state, action) => {
      console.log("333")
			state.loading = false;
		});
	},
});

export const painAssessmentInfoAction = painAssessmentInfoConfig.actions;
export default painAssessmentInfoConfig;
