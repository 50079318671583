import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronLeft } from '@carespace-icons/arrows/chevronLeft'
import { ChevronRight } from '@carespace-icons/arrows/chevronRight'
import { PlayCircle } from '@carespace-icons/media/playCircle'
import { getProgramById } from '@stores/aiAssistant/program'
import { useTypedDispatch, useTypedSelector } from '@stores/index'
import { ICustomModalProps, IProgramData, IProgramExercise } from '@stores/interfaces'
import { Empty, Modal, Spin, Typography } from 'antd'
import moment from 'moment'
import './style.css'

const AProgram = ({ programId, exercises, setExercises, setProgramStatus, setProgramName }: { programId: string, exercises: IProgramExercise[], setProgramStatus?: (value: boolean) => void, setExercises: (value: IProgramExercise[]) => void, setProgramName: (value: string) => void }) => {
  const { t } = useTranslation()
  const [bulletPosition, setBulletPosition] = useState<number>(0)
  const dispatch = useTypedDispatch()
  const [showNextPrevious, setShowNextPrevious] = useState(false)
  const [program, setProgram] = useState<IProgramData | undefined>()
  const [isModalVisible, setModalVisible] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null);
  const [approved, setApproved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const action = await dispatch(getProgramById(programId));
      const payload = action.payload as IProgramData;

      setProgram(payload);
      setProgramName(payload?.name);
      setProgramStatus!(payload?.active);
      setIsLoading(false);
    };

    fetchData();
  }, [programId, dispatch]);

  useEffect(() => {
    if (program) {
      setApproved(program.status?.toLowerCase() === 'approved');
      setExercises(() => {
        const sortedExercise = [...(program.exercises || [])];
        return sortedExercise.sort((a, b) => a.order - b.order);
      });
    }
  }, [program, isModalVisible]);

  const scrollRight = () => {
    const currentPosition = scrollRef.current?.scrollLeft || 0;
    const targetPosition = currentPosition + 400;
    scrollToPosition(targetPosition, 300);
  };

  const scrollToPosition = (targetPosition: number, duration: number) => {
    const startTime = performance.now();
    const start = scrollRef.current?.scrollLeft || 0;

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = start + (targetPosition - start) * progress;
      }

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const onScroll = () => {
    if (scrollRef.current) {
      setBulletPosition(Math.floor(scrollRef.current.scrollLeft * position() / scrollRef.current.scrollWidth));
    }
  };

  const CustomModalInfo = (props: ICustomModalProps) => {
    const { name, description, video } = props;
    const modalStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const modalContent = (
      <div className="centered-select-none">
        <video
          controls
          className="video"
          preload="metadata"
          src={video || ""}
          width="100%"
          height="100%"
        />
        <div className="non-selectable">
          <Typography.Title level={5}>{name}</Typography.Title>
          <Typography.Text>{description}</Typography.Text>
        </div>
      </div>
    );
    Modal.info({
      title: null,
      content: modalContent,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: 'none' } },
      style: modalStyle,
      closable: true
    });
  };

  const scrollLeft = () => {
    const currentPosition = scrollRef.current?.scrollLeft || 0;
    const targetPosition = currentPosition - 400;
    scrollToPosition(targetPosition, 300);
  };

  const position = () => Math.floor((scrollRef.current?.scrollWidth || 0) / 1300) < 10 ? Math.floor((scrollRef.current?.scrollWidth || 0) / 1300) : 10;
  const scrollWidth = () => scrollRef.current?.scrollWidth || 0;



  return (
    <div>
      {
        !isLoading ?
          program ? <div className='program-container-div' onClick={(e) => e.stopPropagation()}>
            <div className="program-inner-container">
              <span className='start-date'>
                {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.startDate')}
              </span>
              <span className="custom-margin">
                {program?.startAt ? moment(program?.startAt).local().format('LL') : "N/A"}
              </span>
              |
              <span className='duration'>
                {t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.duration')}
              </span>
              <span>
                {`${program.duration} ${program.durationType}`}
              </span>
            </div>

            {
              exercises.length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='custom-text-gray'>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram')}</span>} />
                : <div
                  onMouseOver={() => setShowNextPrevious(true)}
                  onMouseOut={() => setShowNextPrevious(false)}
                  className="custom-navigation-program-activity"
                >
                  <div ref={scrollRef} className="custom-program-container" onScroll={onScroll}>
                    <div className="custom-section">
                      {
                        exercises.map(item => {
                          return <div key={item.id} className="custom-modal-program hover:shadow-2xl">
                            <div
                              onClick={() => CustomModalInfo({
                                video: item.video || item.exerciseLibrary?.videoUrl,
                                name: item.name || item.exerciseLibrary?.title,
                                description: item.description || item.exerciseLibrary?.description,
                              })}
                              className='custom-exercise-library image-wrapper'>
                              {item.image ? <img
                                src={item.image}
                                alt=""
                                className="image-program"
                              /> :
                                <video
                                  className="video"
                                  preload="metadata"
                                  src={item.exerciseLibrary?.videoUrl}
                                  width="100%"
                                  height="100%"
                                />}
                              <div className="custom-program-icon play-button">
                                <PlayCircle width={50} height={50} />
                              </div>
                            </div >
                            <div className="program-card-description-div">
                              <p className="custom-text-gray-500 custom-font-semibold custom-text-lg">{item.name || item.exerciseLibrary?.title}</p>
                              <p className="custom-text-gray-400 custom-font-semibold custom-text-xs">{t('Patient.data.rehab.weekly').toLowerCase()} {item.weeklyReps}X     {t('Patient.data.rehab.daily').toLowerCase()} {item.dailyReps}X    {t('Patient.data.rehab.sets').toLowerCase()} {item.sets}X     {t('Patient.data.rehab.reps').toLowerCase()} {item.reps}X</p>
                              <p className="custom-text-gray-500">{item.description || item.exerciseLibrary?.description}</p>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                  {
                    exercises.length > 3 && (showNextPrevious && <div>
                      <span className="custom-arrow custom-arrow-left" onClick={scrollLeft}>
                        <ChevronLeft />
                      </span>
                      <span className="custom-arrow custom-arrow-right" onClick={scrollRight}>
                        <ChevronRight />
                      </span>
                    </div>
                    )
                  }
                </div>
            }
          </div>
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='custom-text-gray-300'>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram')}</span>} />
          : <div className="flexible-program">
            <Spin tip="Loading" size="large" />
          </div>
      }
    </div>
  )
}

export default AProgram