import { useTranslation } from 'react-i18next';
import { IRomSession } from '@stores/interfaces';
import CustomSummaryContentResult from '@pages/AiAssistantCustomRomSummary/CustomSummaryContentResult';


export default function AROM({ romData }: { romData: IRomSession | undefined }) {

	const { t } = useTranslation();

	return (
		<>
			{!romData ? (
				<div className="coach-rom-summary-container-row-div">
					<img
						style={{ width: 100, height: 100 }}
						className="coach-rom-summary-container-row-div-img"
						src="/images/NoExe.png"
						alt="No Exercises Image"
					/>
					<h1 className="coach-rom-summary-container-row-div-h1">
						{t('Patient.data.myProgress.omniRom.empty')}
					</h1>
				</div>
			) : (
				<div>
					<CustomSummaryContentResult item={romData?.romPatientResults!} />
				</div>
			)}
		</>
	);
}
