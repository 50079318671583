import { useEffect, useState } from 'react';
import { useTypedDispatch } from '@stores/index';
import { XClose } from '@carespace-icons/general/xClose';
import { BODY_POINTS_OPTION } from './options';
import SelectCustom from '@atoms/ASelect';
import { myLibraryInfoAction } from '@stores/library';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import { BODY_POINTS } from './bodyPoint_constants';
import {
	BodyPointDropdownOption,
	BodyPointOption,
	TBodyOptionType,
	TFormDataType,
	TOptionFormatedType,
	TStrapiBodyPoints,
} from './interface';
import Loading from '@atoms/Loading';
import { EvaluationData, PainAssessments } from '@pages/Home/interface';
import { useTranslation } from 'react-i18next';
import {
	getBodyPointStrapi,
	getOptionsData,
} from '@stores/myLibrary/myLibrary';
import { Trash01 } from '@carespace-icons/general/trash01';
import { Slider, Tooltip, message } from 'antd';
import './style.css';

interface TBodyPointType {
	assessmentData: PainAssessments[];
	savedEvaluationData?: EvaluationData;
	setSavedEvaluationData: (value: EvaluationData) => void;
	isLoading: boolean;
	setIsLoading: (value: boolean) => void;
}

const MBodyPoints = (props: TBodyPointType) => {
	const {
		isLoading,
		setIsLoading,
		assessmentData,
		savedEvaluationData,
		setSavedEvaluationData,
	} = props;

	const defaultFormData = {
		strapiBodyPointId: 0,
		strapiAggravatingFactorsIds: [],
		strapiRelievingFactorsIds: [],
		strapiPainCausesIds: [],
		strapiPainDurationId: 0,
		strapiPainFrequencyId: 0,
		strapiPainStatusId: 0,
	};
	const dispatch = useTypedDispatch();
	const [activeLeftName, setActiveLeftName] = useState('');
	const [activeRightName, setactiveRightName] = useState('');
	const [bodyPointOptionsLeft, setBodyPointOptionsLeft] = useState([]);
	const [bodyPointOptionsRight, setBodyPointOptionsRight] = useState([]);
	const [formDataLeft, setFormDataLeft] = useState(defaultFormData);
	const [formDataRight, setFormDataRight] = useState(defaultFormData);
	const [savedData, setSavedData] = useState<PainAssessments[]>([]);
	const [isUpdateFront, setIsUpdateFront] = useState(false);
	const [isUpdateBack, setIsUpdateBack] = useState(false);
	const [bodyPointData, setBodypointData] = useState<TStrapiBodyPoints[]>([]);
	const [isSaveClick, setIsSaveClick] = useState(false);
	const [errorObject, setErrorObject] = useState<string[]>([]);
	const [selectedBodyPiont, setSelectedBodyPoint] = useState(0);
	const [painLevelLeft, setPainLevelLeft] = useState(5);
	const [painLevelRight, setPainLevelRight] = useState(5);

	useEffect(() => {
		fetchData();
	}, [savedEvaluationData]);

	const fetchData = async () => {
		const data = await dispatch(getBodyPointStrapi());
		const strapiData = data.payload;
		dispatch(painAssessmentInfoAction.bodyPointStrapiInfo(strapiData));
		dispatch(
			myLibraryInfoAction.painAssessmentSavedBodyPointInfo(assessmentData),
		);
		let temp_data: TStrapiBodyPoints[] = [];
		strapiData.forEach((item: TStrapiBodyPoints) => {
			let new_item = { ...item };
			let find = BODY_POINTS.find(
				(strapi: TStrapiBodyPoints) => strapi.id == item.id,
			);
			new_item.romStyle = find?.romStyle;
			savedEvaluationData?.painAssessments.some(assessment =>
				assessment.strapiBodyPointId == item.id
					? (new_item.isSaved = true)
					: (new_item.isSaved = false),
			);
			temp_data.push(new_item);
		});

		setBodypointData(temp_data);
		setSavedData(assessmentData);
		dispatch(painAssessmentInfoAction.painAssessmentInfo(assessmentData));
		setIsLoading(false);
	};

	const onBodyPoint = async (bodyPoint: TStrapiBodyPoints) => {
		setSelectedBodyPoint(bodyPoint.id);
		bodyPoint.isSaved ? setIsUpdateFront(true) : setIsUpdateFront(false);
		setIsSaveClick(false);
		setActiveLeftName(bodyPoint.attributes.name);
		getOptionList(bodyPoint.attributes.position, bodyPoint?.id);
		setFormDataLeft(getFormData(bodyPoint.id, 'left'));
	};

	const getOptionList = async (position: string, id: number) => {
		if (id) {
			if (position == 'front') {
				setBodyPointOptionsLeft([]);
			} else {
				setBodyPointOptionsRight([]);
			}
			const data = await dispatch(getOptionsData({ id }));
			const apiData = data.payload;
			dispatch(painAssessmentInfoAction.optionsInfo(apiData));
			let temp_data: [] = [];
			if (apiData) {
				BODY_POINTS_OPTION.forEach((item: BodyPointOption) => {
					let name = item?.name;
					let key = item?.key;
					let options = {
						name: name,
						options: [],
						key: key,
						selectedOption: [],
					};
					let find = savedData?.find(
						(saved: PainAssessments) => saved.strapiBodyPointId == id,
					);
					if (name == 'Aggravating Factors') {
						options.options = createOptions(apiData?.aggravatingFactors);
						options.selectedOption = find?.strapiAggravatingFactorsIds || [];
					} else if (name == 'Relieving Factors') {
						options.options = createOptions(apiData?.relievingFactors);
						options.selectedOption = find?.strapiRelievingFactorsIds || [];
					} else if (name == 'Cause') {
						options.options = createOptions(apiData?.painCauses);
						options.selectedOption = find?.strapiPainCausesIds || [];
					} else if (name == 'Duration') {
						options.options = createOptions(apiData?.painDurations);
						options.selectedOption = find?.strapiPainDurationId || undefined;
					} else if (name == 'Frequency') {
						options.options = createOptions(apiData?.painFrequencies);
						options.selectedOption = find?.strapiPainFrequencyId || undefined;
					} else if (name == 'Status') {
						options.options = createOptions(apiData?.painStatuses);
						options.selectedOption = find?.strapiPainStatusId || undefined;
					}
					if (find?.id) {
						if (position == 'front') {
							setIsUpdateFront(true);
						} else {
							setIsUpdateBack(true);
						}
					}
					temp_data.push(options);
				});
			}
			if (position == 'front') {
				setBodyPointOptionsLeft(temp_data);
			} else {
				setBodyPointOptionsRight(temp_data);
			}
		}
	};

	const createOptions = (data: TBodyOptionType[]) => {
		let tempData: TOptionFormatedType[] = [];
		data.forEach((item: TBodyOptionType) => {
			tempData.push({
				label: item.attributes.name,
				value: item.id,
			});
		});
		return tempData;
	};

	const setActivePointRight = async (bodyPoint: TStrapiBodyPoints) => {
		setSelectedBodyPoint(bodyPoint.id);
		bodyPoint.isSaved ? setIsUpdateBack(true) : setIsUpdateBack(false);
		setIsSaveClick(false);
		setactiveRightName(bodyPoint.attributes.name);
		getOptionList(bodyPoint.attributes.name, bodyPoint?.id);
		setFormDataRight(getFormData(bodyPoint.id, 'right'));
	};

	const getFormData = (bodyPointId: number, side: string) => {
		const foundData = savedEvaluationData?.painAssessments.find(
			element => element.strapiBodyPointId === bodyPointId,
		);
		if (foundData) {
			const {
				painLevel,
				strapiBodyPointId,
				strapiAggravatingFactorsIds,
				strapiRelievingFactorsIds,
				strapiPainCausesIds,
				strapiPainDurationId,
				strapiPainFrequencyId,
				strapiPainStatusId,
			} = foundData;

			side == 'left'
				? setPainLevelLeft(painLevel ? painLevel : 5)
				: setPainLevelRight(painLevel ? painLevel : 5);

			const temp_data = {
				strapiBodyPointId,
				strapiAggravatingFactorsIds,
				strapiRelievingFactorsIds,
				strapiPainCausesIds,
				strapiPainDurationId,
				strapiPainFrequencyId,
				strapiPainStatusId,
			};
			return temp_data;
		} else {
			const temp_data = { ...defaultFormData };
			side == 'left' ? setPainLevelLeft(5) : setPainLevelRight(5);
			temp_data.strapiBodyPointId = bodyPointId;
			return temp_data;
		}
	};

	const onSelect = (name: string, value: string | string[]) => {
		let filteredErrorObject = errorObject.filter(
			(item: string) => item != name,
		);
		setErrorObject(filteredErrorObject);
		setFormDataLeft({
			...formDataLeft,
			...{ [name]: value },
		});
	};

	const onSelectBack = (name: string, value: string | string[]) => {
		let filteredErrorObject = errorObject.filter(
			(item: string) => item != name,
		);
		setErrorObject(filteredErrorObject);
		setFormDataRight({
			...formDataRight,
			...{ [name]: value },
		});
	};

	const onClickSaveLeft = async () => {
		saveData(formDataLeft, 'left');
		setBodypointData(
			bodyPointData.map(element => {
				if (element.id === selectedBodyPiont) {
					return {
						...element,
						isSaved: true,
					};
				}
				return element;
			}),
		);
	};

	const onClickUpdateLeft = async () => {
		updateData(formDataLeft, 'left');
	};

	const saveData = (payload: PainAssessments, position: string) => {
		let temp_data = savedEvaluationData?.painAssessments || [];
		let filterdData = temp_data.filter(
			item => item.strapiBodyPointId != payload.strapiBodyPointId,
		);
		filterdData.push({
			...payload,
			painLevel:
				position === 'left'
					? painLevelLeft
					: position === 'back'
						? painLevelRight
						: 0,
		});
		let saved_temp_data = { ...savedEvaluationData };
		saved_temp_data.painAssessments = filterdData;

		setIsSaveClick(true);
		let isValid = formValidation(payload);
		if (!isValid) {
			message.error(
				t(
					'Patient.data.virtualEvaluation.painAssesment.pleaseFillAllTheRequiredFields',
				),
			);
			setIsSaveClick(false);
			return;
		}
		if (position === 'left') {
			setIsUpdateFront(true);
		} else if (position === 'back') {
			setIsUpdateBack(true);
		}
		setSavedData(saved_temp_data.painAssessments);
		setSavedEvaluationData(saved_temp_data);
		message.success(t('Patient.data.dataNotifications.dataSave'));
		setIsSaveClick(false);
	};
	console.log(savedData, painLevelLeft, painLevelRight);
	const updateData = (payload: any, position: string) => {
		var updatedData = savedEvaluationData?.painAssessments || [];
		const indexToUpdate = updatedData.findIndex(
			item => item.strapiBodyPointId == payload.strapiBodyPointId,
		);
		if (indexToUpdate !== -1) {
			const updatedArray = [...updatedData];
			updatedArray[indexToUpdate] = {
				...payload,
				painLevel:
					position === 'left'
						? painLevelLeft
						: position === 'back'
							? painLevelRight
							: 0,
			};

			const updatedSavedEvaluationData = {
				...savedEvaluationData,
				painAssessments: updatedArray,
			};
			setSavedEvaluationData(updatedSavedEvaluationData);
			setSavedData(updatedSavedEvaluationData.painAssessments);
		}

		if (position === 'left') {
			setFormDataLeft(payload);
		} else if (position === 'back') {
			setFormDataRight(payload);
		}
		message.success(t('Patient.data.dataNotifications.dataUpdate'));
		setIsSaveClick(false);
	};

	const formValidation = (payload: TFormDataType) => {
		let error_data: string[] = [];
		if (payload.strapiAggravatingFactorsIds.length === 0) {
			error_data.push('strapiAggravatingFactorsIds');
		}
		if (payload.strapiPainCausesIds.length === 0) {
			error_data.push('strapiPainCausesIds');
		}
		if (payload.strapiRelievingFactorsIds.length === 0) {
			error_data.push('strapiRelievingFactorsIds');
		}
		if (payload.strapiPainDurationId === 0 || !payload.strapiPainDurationId) {
			error_data.push('strapiPainDurationId');
		}
		if (payload.strapiPainFrequencyId === 0 || !payload.strapiPainFrequencyId) {
			error_data.push('strapiPainFrequencyId');
		}
		if (payload.strapiPainStatusId === 0 || !payload.strapiPainStatusId) {
			error_data.push('strapiPainStatusId');
		}
		setErrorObject(error_data);
		if (error_data.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const deleteRecord = (position: string) => {
		let temp_data = [...savedEvaluationData.painAssessments];
		let filterdData = temp_data.filter(
			item => item.strapiBodyPointId != selectedBodyPiont,
		);
		let saved_temp_data = { ...savedEvaluationData };
		saved_temp_data.painAssessments = filterdData;
		setSavedEvaluationData(saved_temp_data);
		setSavedData(saved_temp_data.painAssessments);
		setBodypointData(
			bodyPointData.map(element => {
				if (element.id === selectedBodyPiont) {
					return {
						...element,
						isSaved: false,
					};
				}
				return element;
			}),
		);
		message.success(t('Patient.data.dataNotifications.dataDelete'));
		if (position == 'left') setActiveLeftName('');
		else setactiveRightName('');
	};
	const closeLeft = () => {
		setActiveLeftName('');
	};

	const closeRight = () => {
		setactiveRightName('');
	};

	const onClickSaveRight = () => {
		saveData(formDataRight, 'back');
		setBodypointData(
			bodyPointData.map(element => {
				if (element.id === selectedBodyPiont) {
					return {
						...element,
						isSaved: true,
					};
				}
				return element;
			}),
		);
	};
	const { t } = useTranslation();
	const onClickUpdateRight = () => {
		updateData(formDataRight, 'back');
	};

	return (
		<div className="pre-assessment flex">
			<div className="flex overflow-x-auto w-full">
				{activeLeftName ? (
					<div className="w-1/3 pl-6 pr-6" style={{ minWidth: '280px' }}>
						<div className="w-full rounded-lg p-3 border-2 border-[#eaecf0] hover:shadow-2xl">
							<div className="relative mb-10">
								{isUpdateFront && (
									<Tooltip
										placement="topRight"
										title={t(
											'Patient.data.virtualEvaluation.painAssesment.delete',
										)}
										color="purple"
										showArrow={false}>
										<span
											className="cursor-pointer absolute right-9 top-[1px]"
											onClick={() => deleteRecord('left')}>
											<Trash01 color="stroke-gray-600" width={20} height={20} />
										</span>
									</Tooltip>
								)}
								<span
									className="absolute right-1 cursor-pointer"
									onClick={closeLeft}>
									<XClose color="stroke-gray-600" />
								</span>
								<h2 className="text-primary-600 font-semibold text-base absolute text-left">
									{activeLeftName.toUpperCase()}
								</h2>
							</div>
							<div style={{color:'var(--text-color-root)'}}>{t('Patient.data.onboard.painLevel')}</div>
							<div className="flex items-center justify-between mb-2">
								<span className="text-gray-500 text-sm font-semibold">0</span>
								<div className="w-full px-2">
									<Slider
										min={0}
										max={10}
										defaultValue={5}
										value={painLevelLeft}
										onChange={setPainLevelLeft}
										trackStyle={{ backgroundColor: "var(--menu-item-active)" }}
										handleStyle={{
											borderColor: "var(--menu-item-active)",
											backgroundColor: '#fff',
										}}
									/>
								</div>
								<span className="text-gray-500 text-sm font-semibold">10</span>
							</div>
							{bodyPointOptionsLeft.map(
								(item: BodyPointDropdownOption, i: number) => {
									let multiple =
										item.key != 'strapiPainDurationId' &&
										item.key != 'strapiPainFrequencyId' &&
										item.key != 'strapiPainStatusId';
									return (
										<div className="mt-2" key={i}>
											<div>{item.name}</div>
											<SelectCustom
												onChange={onSelect}
												options={item.options}
												name={item.key}
												multiple={multiple}
												defaultValue={item.selectedOption}
												isError={errorObject.includes(item.key) && isSaveClick}
											/>
										</div>
									);
								},
							)}
							<div className="text-center mt-4">
								<div className="text-center mt-4">
									<span>
										{isUpdateFront ? (
											<button
												children={t(
													'Patient.data.virtualEvaluation.painAssesment.update',
												)}
												onClick={onClickUpdateLeft}
												className="bg-primary-600 w-full rounded-md hover:bg-primary-700 active:shadow-primary-100 text-white py-1"
											/>
										) : (
											<button
												children={t(
													'Patient.data.virtualEvaluation.painAssesment.save',
												)}
												onClick={onClickSaveLeft}
												className="bg-primary-600 rounded-md w-full hover:bg-primary-700 active:shadow-primary-100 text-white py-1"
											/>
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="w-1/3"></div>
				)}
				{isLoading ? (
					<Loading />
				) : (
					<div className="containers bg-gray-50 select-none" style={{borderRadius:'16px'}}>
						<div className="body-shape-front">
							<img src={'/images/front.svg'} width={240} alt="" />
							{bodyPointData
								.filter(
									(val: TStrapiBodyPoints) =>
										val.attributes.position === 'front',
								)
								.map((hotspot: TStrapiBodyPoints, index: number) => (
									<div
										key={index}
										style={hotspot.romStyle}
										className={
											hotspot?.attributes.name === activeLeftName
												? 'hotspot-circle active'
												: hotspot.isSaved
													? 'hotspot-circle saved'
													: 'hotspot-circle'
										}
										onClick={e => {
											e.preventDefault();
											onBodyPoint(hotspot);
										}}>
										<span className="tooltiptext">
											{hotspot?.attributes.name}
										</span>
									</div>
								))}
						</div>
						<div className="body-shape-back">
							<img src={'/images/back.svg'} width={240} alt="" />
							{bodyPointData
								.filter(
									(val: TStrapiBodyPoints) =>
										val.attributes.position === 'back',
								)
								.map((hotspot: TStrapiBodyPoints, index: number) => (
									<div
										key={index}
										style={hotspot.romStyle}
										className={
											hotspot.attributes.name === activeRightName
												? 'hotspot-circle active'
												: hotspot.isSaved
													? 'hotspot-circle saved'
													: 'hotspot-circle'
										}
										onClick={e => {
											e.preventDefault();
											setActivePointRight(hotspot);
										}}>
										<span className="tooltiptext">
											{hotspot.attributes.name}
										</span>
									</div>
								))}
						</div>
					</div>
				)}
				{activeRightName ? (
					<div className="w-1/3 pl-6 pr-6" style={{ minWidth: '280px' }}>
						<div className="w-full rounded-lg p-3 border-2 border-[#eaecf0] hover:shadow-2xl">
							<div className="relative mb-10">
								{isUpdateBack && (
									<Tooltip
										placement="topRight"
										title={t(
											'Patient.data.virtualEvaluation.painAssesment.delete',
										)}
										color="purple"
										showArrow={false}>
										<span
											className="cursor-pointer absolute right-9 top-[1px]"
											onClick={() => deleteRecord('right')}>
											<Trash01 color="stroke-gray-600" width={20} height={20} />
										</span>
									</Tooltip>
								)}
								<span
									className="absolute right-1 cursor-pointer"
									onClick={closeRight}>
									<XClose color="stroke-gray-600" />
								</span>
								<h2 className="text-primary-600 font-semibold text-base absolute text-left">
									{activeRightName.toUpperCase()}
								</h2>
							</div>
							<div style={{color:'var(--text-color-root)'}}>{t('Patient.data.onboard.painLevel')}</div>
							<div className="flex items-center justify-between mb-2">
								<span className="text-gray-500 text-sm font-semibold">0</span>
								<div className="w-full px-2">
									<Slider
										min={0}
										max={10}
										defaultValue={5}
										value={painLevelRight}
										onChange={setPainLevelRight}
										trackStyle={{ backgroundColor: "var(--menu-item-active)" }}
										handleStyle={{
											borderColor: 'var(--menu-item-active)',
											backgroundColor: '#fff',
										}}
									/>
								</div>
								<span className="text-gray-500 text-sm font-semibold">10</span>
							</div>
							{bodyPointOptionsRight.map(
								(item: BodyPointDropdownOption, i: number) => {
									let multiple =
										item.key != 'strapiPainDurationId' &&
										item.key != 'strapiPainFrequencyId' &&
										item.key != 'strapiPainStatusId';
									return (
										<div className="mt-2" key={i}>
											<div>{item.name}</div>
											<SelectCustom
												onChange={onSelectBack}
												options={item.options}
												name={item.key || ''}
												multiple={multiple}
												defaultValue={item.selectedOption}
												isError={errorObject.includes(item.key) && isSaveClick}
											/>
										</div>
									);
								},
							)}
							<div className="text-center mt-4">
								<div className="text-center mt-4">
									<span>
										{isUpdateBack ? (
											<button
												children={t(
													'Patient.data.virtualEvaluation.painAssesment.update',
												)}
												onClick={onClickUpdateRight}
												className="bg-primary-600 rounded-md w-full hover:bg-primary-700 active:shadow-primary-100 text-white py-1"
											/>
										) : (
											<button
												children={t(
													'Patient.data.virtualEvaluation.painAssesment.save',
												)}
												onClick={onClickSaveRight}
												className="bg-primary-600 rounded-md w-full hover:bg-primary-700 active:shadow-primary-100 text-white py-1"
											/>
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="w-1/3"></div>
				)}
			</div>
		</div>
	);
};

export default MBodyPoints;
