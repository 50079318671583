export const BODY_POINTS_OPTION = [
    {
        name: 'Aggravating Factors',
        key: 'strapiAggravatingFactorsIds'
    },
    {
        name: 'Relieving Factors',
        key: 'strapiRelievingFactorsIds'
    },
    {
        name: 'Cause',
        key: 'strapiPainCausesIds'
    },
    {
        name: 'Duration',
        key: 'strapiPainDurationId'
    },
    {
        name: 'Frequency',
        key: 'strapiPainFrequencyId'
    },
    {
        name: 'Status',
        key: 'strapiPainStatusId'
    }
]