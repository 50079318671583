import { Button, message, Spin } from 'antd'
import './style.css'
import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { TOnBoardSymptomsProps } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { painAssessmentInfoAction, saveEvaluation } from '@stores/painAssessment';
import { getEvaluationData, getMedicalHistoriesOptions } from '@stores/myLibrary/myLibrary';
import { myLibraryInfoAction } from '@stores/library';
import { MedicalHistories, THealthSignsOptions } from '@pages/Home/interface';

export default function MedicalHistory(props: TOnBoardSymptomsProps) {
  const { setActiveStep, setProgressPercent } = props
  const [selectedButtons, setSelectedButtons] = useState<any>([]);
  const medicalHistoriesOptions = useTypedSelector((state) => state.myLibrary.myLibrary.medicalHistoriesOptions);
  const { t } = useTranslation()
  const dispatch = useTypedDispatch();
  const medicalHistory = useTypedSelector((state) => state.painAssessment.medicalHistory);
  const user = useTypedSelector((state) => state.user);
  const painAssessmentInfo = useTypedSelector((state) => state.painAssessment.painAssessment);
  const associatedSymptoms = useTypedSelector((state) => state.painAssessment.associatedSymptoms);
  const [disableOtherOptions, setDisableOtherOptions] = useState(false);
  const painStatusButton = useTypedSelector((state) => state.onBoard.onBoard.painStatusButton);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [savedMedicalHistoryData, setSavedMedicalHistoryData] = useState<MedicalHistories>();

  useEffect(() => {
    fetchMedicalHistory();
    fetchSavedData();
  }, [user])

  const fetchMedicalHistory = async () => {
    try {
      const data = await dispatch(getMedicalHistoriesOptions());
      let apiData = data.payload;
      dispatch(myLibraryInfoAction.medicalHistoriesOptionsinfo(apiData));
      setIsDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSavedData = async () => {
    let id = user.id;
    const payload = {
      userId: id,
      page: 1,
      limit: 1,
    };
    const data = await dispatch(getEvaluationData(payload));
    const apiData = data?.payload;
    if (apiData?.data?.length > 0) {
      setSavedMedicalHistoryData(apiData?.data[0]?.medicalHistories);
    };
  }

  useEffect(() => {
    if (medicalHistory?.strapiMedicalHistoriesIds?.length) {
      const savedIds = medicalHistory?.strapiMedicalHistoriesIds;
      const preSelectedButtons = medicalHistoriesOptions?.data?.filter((item) => savedIds?.includes(item?.id));
      setSelectedButtons(preSelectedButtons);
      setDisableOtherOptions(savedIds?.includes(16));
    } else if (savedMedicalHistoryData?.strapiMedicalHistoriesIds?.length) {
      const savedIds = savedMedicalHistoryData?.strapiMedicalHistoriesIds;
      const preSelectedButtons = medicalHistoriesOptions?.data?.filter((item) => savedIds?.includes(item?.id));
      setSelectedButtons(preSelectedButtons);
      setDisableOtherOptions(savedIds?.includes(16));
    }
  }, [medicalHistory, medicalHistoriesOptions, savedMedicalHistoryData]);

  const handleButtonClick = (item: THealthSignsOptions) => {
    if (item.id === 16) {
      if (selectedButtons.some((selected: THealthSignsOptions) => selected?.id === 16)) {
        setSelectedButtons(selectedButtons?.filter((selected: THealthSignsOptions) => selected?.id !== 16));
        setDisableOtherOptions(false);
      } else {
        setSelectedButtons([item]);
        setDisableOtherOptions(true);
      }
    } else if (!disableOtherOptions) {
      if (selectedButtons.some((selected: THealthSignsOptions) => selected?.id === item?.id)) {
        setSelectedButtons(selectedButtons?.filter((selected: THealthSignsOptions) => selected?.id !== item?.id));
      } else {
        setSelectedButtons([...selectedButtons, item]);
      }
    }
  }

  const handleNextClick = async () => {
    if (selectedButtons.length === 0) {
      message.error('Please select at least one option');
      return;
    }
    const strapiMedicalHistories = selectedButtons.map((item: THealthSignsOptions) => item.id);
    const payload = {
      strapiMedicalHistoriesIds: strapiMedicalHistories,
    };
    dispatch(painAssessmentInfoAction.medicalHistoryInfo(payload));

    const finalPayload = {
      userId: user?.id,
      painAssessments: painStatusButton === "noPain" ? [] : painAssessmentInfo,
      healthSigns: painStatusButton === "noPain" ? [] : associatedSymptoms,
      medicalHistories: payload
    }
    const data = await saveEvaluation(finalPayload);
    if (data) {
      setActiveStep!(6);
      setProgressPercent(60);
    }
  }

  if (!isDataLoaded) {
    return <Spin className="spin-css" tip="Loading" size="large" />;
  }

  return (
    <div className="medical-container">
      <h1 style={{ fontSize: '25px', color: '#6941C6', fontWeight: '700' }}>
        {t('Patient.data.virtualEvaluation.medicalHistory.title')}
      </h1>
      <h3 style={{ fontSize: '18px', color: '#98A2B3', fontWeight: '300' }}>{t('Patient.data.virtualEvaluation.medicalHistory.description')}</h3>
      <div className='medical-subcontainer'>
        <div className="content-div-associated">
          {medicalHistoriesOptions?.data.map((item: THealthSignsOptions) => {
            return (
              <Button key={item?.id}
                className={`onboard-list-button-css ${selectedButtons.some((selected: THealthSignsOptions) => selected?.id === item?.id) ? 'selected' : ''}`}
                onClick={() => handleButtonClick(item)}
                disabled={disableOtherOptions && item.id !== 16}
              >
                {item?.attributes?.name}
              </Button>
            )
          })}
        </div>
        <div className='mt-2 w-[590px] flex items-end gap-4'>
          <Button className="mt-2 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6" onClick={() => { painStatusButton === "noPain" ? setActiveStep!(2) : setActiveStep!(4) }} size="large" type="primary" block style={{ height: '43px', width: "308px" }}>
            {t('Patient.data.onboard.back')}
          </Button>
          <Button className="rounded-full font-inter text-base font-bold leading-6" size="large" type="primary" block style={{ height: '43px', background: "#6941C6", color: "#fff", width: "308px" }} onClick={handleNextClick}>
            {t('Patient.data.onboard.next')}
          </Button>
        </div>
      </div>
    </div>
  )
}
