import { MagicWand01 } from '@carespace-icons/editor/magicWand01';
import { Modal } from 'antd';
import { IProgramExercise } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { MyExrciseModalData } from './MyExerciseModalData';
import { Plus } from '@carespace-icons/general/plus';
import { useState } from 'react';

interface AddLibraryExerciseModalProps {
	isVisible: boolean;
	onOk: () => void;
	onCancel: () => void;
	selectedExercises: IProgramExercise[];
	setSelectedExercises: (selectedExercises: IProgramExercise[]) => void;
}

export const AddLibraryExerciseModal = ({
	isVisible,
	onOk,
	onCancel,
	selectedExercises,
	setSelectedExercises,
}: AddLibraryExerciseModalProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	return (
		<Modal
			title={
				<div className="flex gap-4">
					<span className="rounded-full bg-primary-100 py-1 px-1.5">
						<MagicWand01 width={20} height={20} color="stroke-primary-600" />
					</span>
					<span className="titleStyle">
						{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.create')}
					</span>
				</div>
			}
			style={{ top: 20 }}
			open={isVisible}
			onCancel={onCancel}
			footer={false}
			width={974}
			className="select-none">
			<div className="rounded-none p-4 bg-gray-50">
				<MyExrciseModalData
					open={open}
					setOpen={setOpen}
					selectedExercises={selectedExercises}
					setSelectedExercises={setSelectedExercises}
				/>
				{selectedExercises.length !== 0 && (
					<div
						className="w-full cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2"
						onClick={() => onOk()}>
						<Plus />
						<p>
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.add',
							)}{' '}
							{selectedExercises.length}{' '}
							{selectedExercises.length === 1
								? t('Admin.data.menu.aiAssistant.aiAssistantPrograms.exercise')
								: t(
									'Admin.data.menu.aiAssistant.aiAssistantPrograms.exercises',
								)}
						</p>
					</div>
				)}
			</div>
		</Modal>
	);
};
