import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IPerformanceSaveUserProgress, IPerformanceInitialState, IPerformance, IPerformanceExercises } from '@stores/interfaces'

export const fetchExercises = createAsyncThunk(
	'performance/fetchExercises',
	async (): Promise<IPerformanceExercises[]> => {
		try {
			const { data } = await axios.get(`/performance/exercises`)
			return data
		} catch (error) {
			return error.response
		}
	}
)

export const saveUserProgress = createAsyncThunk(
	'performance/saveUserProgress',
	async (body: IPerformanceSaveUserProgress): Promise<IPerformance> => {
		try {
			const { data } = await axios.post('/performance/progress', body)
			return data
		} catch (error) {
			return error.response
		}
	}
)

const initialPoseConfidence = {
	down: 0,
	up: 0,
}

const initialState: IPerformanceInitialState = {
	user: null,
	exercises: [],
	exercisesCompleted: [],
	currentExercise: null,
	isMenuOpened: false,
	isHiddenMenu: true,
	progress: {
		total: 0, // numero de exercícios
		completed: 0,
		current: 0,
	},
	isInvertVideo: false,
	exerciseProgress: {
		total: 10,
		totalSets: 2,
		current: 0,
		sets: 0,
	},
	complete: false,
	// poseConfidence: { ...initialPoseConfidence },
	aspectArea: {
		width: 1280,
		height: 720,
	},
	shouldRedirect: false,
}

export const main = createSlice({
	name: 'main',
	initialState,
	reducers: {
		resetAll: (state, action) => {
			state.exerciseProgress = { ...initialState.exerciseProgress }
			state.complete = false
			state.progress = { ...initialState.progress }
			state.exercisesCompleted = []
			state.progress.total = state.exercises.length
			state.shouldRedirect = initialState.shouldRedirect
		},
		updateUser: (state, action) => {
			state.user = action.payload
		},
		setNumberOfReps: (state, action) => {
			state.exerciseProgress.current = action.payload
		},
		setNumberOfSets: (state, action) => {
			state.exerciseProgress.current = 0
			state.exerciseProgress.sets = state.exerciseProgress.sets + 1
			state.complete = // melhorar aqui
				state.exerciseProgress.sets >= state.currentExercise.setsPerSession
		},
		toggleMenu: (state, action) => {
			state.isMenuOpened = !state.isMenuOpened
		},
		goToExercise: (state, action) => {
			state.currentExercise = action.payload
		},
		nextExercise: (state, action) => {
			const arr1 = state.exercises.map((exer) => exer.id)
			const arr2 = state.exercisesCompleted.map((exer) => exer.exercise)
			const diff = arr1.filter((x) => !arr2.includes(x))

			if (diff.length > 0) {
				const exerciseIndex = state.exercises.findIndex((elm) => {
					return state.currentExercise
						? elm.id === state.currentExercise
						: elm.id === diff[0]
				})
				state.currentExercise = { ...state.exercises[exerciseIndex] }
				state.progress.current = arr1.length - diff.length

				state.exerciseProgress = {
					...initialState,
				}
				state.exerciseProgress.total = state.currentExercise.repetitions
				state.exerciseProgress.totalSets = state.currentExercise.setsPerSession

				state.progress.completed =
					(state.progress.current * 100) / state.progress.total

				return
			}

			state.currentExercise = null
		},
		invertVideo: (state, action) => {
			state.isInvertVideo = !state.isInvertVideo
		},
		updateAspectArea: (state, action) => {
			state.aspectArea = {
				...state.aspectArea,
				...action.payload,
			}
		},
		shouldRedirectTo: (state, action) => {
			state.shouldRedirect = true
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchExercises.fulfilled, (state, action) => {
			state.exercises = [...action.payload]
			state.currentExercise = state.exercises[0]
			state.currentExercise['repetitions'] = state.exerciseProgress.total
			state.currentExercise['setsPerSession'] = state.exerciseProgress.totalSets
			state.exercisesCompleted = []
			state.isHiddenMenu = state.exercises.length === 0 ? true : false
			state.progress.total = state.exercises.length
		})
		builder.addCase(saveUserProgress.fulfilled, (state, action) => {
			console.log(
				'🚀 ~ file: main.js ~ line 140 ~ builder.addCase ~ action',
				action.payload
			)
			// state.complete = true;
		})
	},
})

export const {
	resetAll,
	updateUser,
	setNumberOfReps,
	setNumberOfSets,
	goToExercise,
	nextExercise,
	toggleMenu,
	invertVideo,
	updateAspectArea,
	shouldRedirectTo,
} = main.actions

export default main.reducer
