import { useEffect, useState } from 'react';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import moment from 'moment';
import { twJoin } from 'tailwind-merge';
import { EvaluationItem, IActivityStreamData } from '@stores/interfaces';
import { CalendarHeart02 } from '@carespace-icons/time/calendarHeart02';
import { useTranslation } from 'react-i18next';
import './style.css';

type weekArrayType = {
  dateNumber: number,
  currentDate: boolean,
  event: boolean,
  year: number,
  month: number,
  monthName: string
}

type THorizontalCalendarProps = {
  onMonthClick: (value: Date) => void
  setSelectedDate: (value: Date) => void
  onClick: (value: Date) => void
  selectedDate: Date
  activityStreamData: EvaluationItem[]
  isFilter: boolean
  clearFilter: () => void
}

const WeeklyCalendar = (props: THorizontalCalendarProps) => {

  const { onMonthClick, onClick, selectedDate, setSelectedDate, activityStreamData, isFilter, clearFilter } = props;
  const { t } = useTranslation()
  const [weekDays, setWeekDays] = useState<weekArrayType[]>([
    { dateNumber: 0, currentDate: false, event: false, year: 0, month: 0, monthName: '' }
  ])
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [weekStartDay, setWeekStartDay] = useState(new Date);

  useEffect(() => {
    getWeekArray(selectedDate)
  }, [selectedDate])
  useEffect(() => {
    setWeekDays((prevWeekDays) => {
      return prevWeekDays.map((date) => ({
        ...date,
        event: getEvent(date.year, date.month, new Date(date.year, date.month, date.dateNumber)),
      }));
    });
  }, [activityStreamData]);
  const getWeekArray = (dateNum: Date) => {
    let selectedDate = dateNum;
    let today = new Date;
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth();
    let day = selectedDate.getDate();
    let startingDate = new Date(year, month, day - selectedDate.getDay());
    setWeekArray(startingDate, year, month, today);
  }
  const onClickWeekDay = (newDay: weekArrayType) => {
    const selectedDate = new Date(newDay.year, newDay.month, newDay.dateNumber);
    onClick(selectedDate);
  };
  const setWeekArray = (startingDate: Date, year: number, month: number, today: Date) => {
    let weekArray: weekArrayType[] = [];
    for (let i = 0; i < 21; i++) {
      let date = new Date(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate() + i);
      let isEventDate = getEvent(year, month, date);
      weekArray.push({
        dateNumber: date.getDate(),
        currentDate: (date.toDateString() === today.toDateString()),
        event: isEventDate,
        year: date.getFullYear(),
        month: date.getMonth(),
        monthName: date.toLocaleString('default', { month: 'short' }),
      })
    }

    setWeekStartDay(new Date(weekArray[0].year, weekArray[0].month, weekArray[0].dateNumber))
    setWeekDays(weekArray);
  }

  const getEvent = (year: number, month: number, date: Date) => {
    let isEventDate = false;
    activityStreamData?.forEach((item: EvaluationItem) => {
      let eventDate = item?.data?.createdAt ? moment(item?.data?.createdAt).format('MM/DD/YYYY') : null;
      let curr = moment(date).format('MM/DD/YYYY');
      if (eventDate === curr) {
        isEventDate = true;
      }
    });
    return isEventDate
  }

  const handleNextWeek = () => {
    let currentWeekEnd: weekArrayType = weekDays[20]
    let month = currentWeekEnd.month;
    let getDate: Date = new Date(currentWeekEnd.year, currentWeekEnd.month, currentWeekEnd.dateNumber);
    let lastDay = getLastDayOfTheMonth(getDate)
    if (currentWeekEnd.dateNumber === lastDay) {
      month = month + 1
    }
    let nextWeekStart: Date = new Date(currentWeekEnd.year, month, currentWeekEnd.dateNumber + 1);
    getWeekArray(nextWeekStart);
  };

  const getLastDayOfTheMonth = (nextWeekStart: Date) => {
    const currentDate = new Date(nextWeekStart);
    currentDate.setMonth(currentDate.getMonth() + 1, 1);
    currentDate.setDate(currentDate.getDate() - 1);
    const lastDayOfMonth = currentDate.getDate();
    return lastDayOfMonth;
  }

  const handlePreviousWeek = () => {
    let currentWeekEnd: weekArrayType = weekDays[0]
    currentWeekEnd.dateNumber
    const now = new Date(currentWeekEnd.year, currentWeekEnd.month, currentWeekEnd.dateNumber);
    let newStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 21)
    getWeekArray(new Date(newStart))
  };

  return (
    <>
      <div className='weekly-container'>
        <p className="weekly-div" onClick={() => onMonthClick(weekStartDay)}>{weekStartDay.toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
        <div className={`weekly-icon ${isFilter ? "custom-w-15" : "custom-w-3"}`}>
          <LeftArrow onPreviousWeek={handlePreviousWeek} />
          <RightArrow onNextWeek={handleNextWeek} />
          {isFilter && <span className="weekly-today" onClick={() => { clearFilter(); setSelectedDate(new Date()); }}><CalendarHeart02 color='stroke-primary-700' height={18} width={18} /> {t('Patient.data.activityStream.today')}</span>}
        </div>
      </div>
      <ul className='weekly-main-container'>
        {
          weekDays.map((date: weekArrayType, i) => {

            let css = [
              'custom-class-css-week',
              date.currentDate ? 'custom-css-current-date' : '',
              date.event && !date.currentDate ? 'custom-css-event-current-date' : '',
              !date.event && !date.currentDate ? 'custom-bg-gray-week' : '',
              selectedDate && selectedDate.getTime() === new Date(date.year, date.month, date.dateNumber).getTime()
                ? 'custom-bg-primary-week'
                : ''
            ].filter(Boolean).join(' ');

            return <li className='custom-w-full' key={i}>
              <span className={
                `custom-block-text-base ${date.currentDate || (date.event && !date.currentDate) ? 'custom-week-date-css' : ''
                } ${!date.event && !date.currentDate ? 'custom-week-date-gray-css' : ''
                } ${selectedDate && selectedDate.getTime() === new Date(date.year, date.month, date.dateNumber).getTime()
                  ? 'custom-week-date-css'
                  : ''
                }`
              }>
                {date.currentDate ? 'Today' : days[i % 7]}</span>
              <span onClick={() => onClickWeekDay(date)} className={css}>{date.dateNumber}</span>
            </li>;
          })
        }
      </ul>
    </>
  );
};

export default WeeklyCalendar;
