import { LogOut04 } from '@carespace-icons/general/logOut04';
import { LogomarkWhite } from '@carespace-icons/Logo/logomarkWhite'
import { UseAuth } from '@contexts/AuthContext';
import { router } from '@routers/routers';
import { useTypedSelector } from '@stores/index';
import { Avatar, Popover, Progress } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface ProgressBarProps {
  progressPercent: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progressPercent }) => {

  const { onLogout } = UseAuth();
  const {t} = useTranslation()
  const user = useTypedSelector(state => state.user);
  const selectedUser = useTypedSelector(
    state => state.contacts.main.selectedUser,
  );
  const profileDetails = useTypedSelector(
    state => state.onBoard.onBoard.profileDetails,
  );
  const navigate = useNavigate()

  const popoverContent = (
    <div>
      <div className="text-left">
        <h3 style={{fontSize: '1rem'}}>
          {profileDetails
            ? `${profileDetails?.profile?.firstName} ${profileDetails?.profile?.lastName}`
            : `${user?.profile?.firstName} ${user?.profile?.lastName}`}
        </h3>
        <p style={{color:'#aaa'}}>{user?.profile?.email}</p>
      </div>

      <hr style={{ margin: '8px 0' }} />
      <div className='flex items-center justify-start gap-2 cursor-pointer' onClick={() => onLogout()}>
        <span>{t('Admin.data.menu.logout')}</span>
        <LogOut04 color='stroke-purple-700' width={20} height={20} /></div>
    </div>
  );

  return (
    <div className="select-none fixed w-full z-50">
      <div className='ai-assistant-search-bar'>
        <div className='search-main-container' style={{ display: 'flex', justifyContent: 'space-between', width:"100%" }}>
          <div style={{ marginLeft: '20px' }}><LogomarkWhite /></div>
          <div style={{ width: '320px', marginRight: '20px', display: "flex", alignItems: "center", gap: "10px" }}>
            <Progress
              percent={progressPercent}
              strokeColor="#BDFF00"
              trailColor="#d3d3d3"
              format={(percent) => <span style={{ color: 'white' }}>{percent}%</span>}
            />
            <Popover content={popoverContent} trigger="hover" placement="leftBottom" >
              <div style={{ cursor: "pointer" }}>
                {selectedUser?.profile?.imageUrl ||
                  user?.profile?.imageUrl ? (
                  <Avatar
                    src={
                      user?.isPhysioterapist
                        ? selectedUser?.profile?.imageUrl
                        : user?.profile?.imageUrl
                    }
                    alt="avatar"
                    size={30}
                  />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: user?.isPhysioterapist
                        ? selectedUser?.profile?.avatarColor
                        : user?.profile?.avatarColor
                    }}
                    className="custom-inline-flex"
                    alt="avatar"
                    size={30}>
                    {user?.isPhysioterapist
                      ? selectedUser?.profile?.firstName
                        ?.charAt(0)
                        ?.toUpperCase()
                      : user?.profile?.firstName
                        ?.charAt(0)
                        ?.toUpperCase()}
                  </Avatar>
                )}
              </div></Popover>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar;