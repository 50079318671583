import { LoadingOutlined } from '@ant-design/icons';
import { ICustomModalProps } from '@stores/interfaces';
import { Modal, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AddToReports } from '@atoms/AddToReports';
import AiProgramSummary from '@pages/AiProgramSummary';
import { useTypedSelector } from '@stores/index';

const antIcon: ReactNode = (
	<LoadingOutlined
		style={{ fontSize: '50px', color: '#6b2ba0' }}
		spin
		twoToneColor="#52c41a"
	/>
);

export const LetsMoveSessions = () => {
	const { t } = useTranslation();
	const isReportModal = useTypedSelector(
		state => state.aiAssistant.aiAssistant.isReportModal,
	);

	const CustomModalInfo = (props: ICustomModalProps) => {
		const { name, description, video } = props;
		const modalStyle = {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		};

		const modalContent = (
			<div
				className="select-none"
				style={{ textAlign: 'center', marginTop: '10%' }}>
				<video
					controls
					className="video"
					preload="metadata"
					src={video}
					type="video/mp4"
					width="100%"
					height="100%"
				/>
				<div className="select-none" style={{ marginTop: '24px' }}>
					<Typography.Title level={5}>{name}</Typography.Title>
					<Typography.Text>{description}</Typography.Text>
				</div>
			</div>
		);
		Modal.info({
			title: null,
			content: modalContent,
			maskClosable: true,
			icon: null,
			okButtonProps: { style: { display: 'none' } },
			style: modalStyle,
			closable: true,
		});
	};

	return (
		<div className={'pl-5 pr-5'}>
			<div>
				{isReportModal ? null : (
					<div className="flex justify-end gap-2 mt-1">
						<AddToReports />
					</div>
				)}
			</div>
			<AiProgramSummary antIcon={antIcon} CustomModalInfo={CustomModalInfo} />
		</div>
	);
};
