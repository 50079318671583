import { XCircle } from '@carespace-icons/general/xCircle'
import { VideoRecorder } from '@carespace-icons/media/videoRecorder'
import { StopCircle } from '@carespace-icons/media/stopCircle'
import { RehabVideoState } from '@stores/interfaces'
import { Button, Space, Tooltip } from 'antd'
import { patientTimeLimit } from '@stores/constants'
import { useTranslation } from 'react-i18next'
import CountDownTimer from '@atoms/CountDownTimer'
import { FaCircle } from 'react-icons/fa'
import './style.css'

interface IVideoHeaderProps {
  videoState: RehabVideoState
  onStartRecord: () => void
  onStopRecord: () => void
  onDiscardRecord: () => void
  onEndedTimer: () => void
  isStartingTimer: boolean
}

function ActivityStreamVideoHeader(props: IVideoHeaderProps) {
  const {
    videoState,
    onStartRecord,
    onStopRecord,
    onDiscardRecord,
    onEndedTimer,
    isStartingTimer
  } = props

  const { t } = useTranslation()

  return (
    <div>
      {videoState === RehabVideoState.READY && (
        <div className='ready-state'
        >
          <span className='startin'
          >
            {t('Patient.data.activityStream.startIn')}
          </span>
        </div>
      )}

      {videoState === RehabVideoState.RECORDING && (
        <div className='recording-state'
        >
          <Tooltip>
            <Button
              onClick={onStopRecord}
              style={{
                height: '35px',
                border: 0,
                borderRadius: "8px",
                backgroundColor: 'rgba(244, 93, 93, 1)',
                color: '#ffffff',
                bottom: "0px"
              }}
            >
              <Space align='center'>
                <StopCircle width={21} height={21} />
                {t('Patient.data.activityStream.stop')}
              </Space>
            </Button>
          </Tooltip>
        </div>
      )}

      {videoState === RehabVideoState.START && (
        <>
          <div className='start-state'
          >
            <span className="startin">{t('Patient.data.activityStream.pressRecord')}</span>
            <span className="startin">{t('Patient.data.activityStream.areReady')}</span>
            <br />
            <Tooltip>
              <Button
                onClick={onStartRecord}
                style={{
                  height: '35px',
                  border: 0,
                  borderRadius: "50px",
                  backgroundColor: '#01c752',
                  color: '#ffffff',
                }}
              >
                <Space align='center'>
                  <VideoRecorder width={21} height={21} />
                  {t('Patient.data.activityStream.record')}
                </Space>
              </Button>
            </Tooltip>
          </div>
        </>
      )}

      {videoState === RehabVideoState.REPLAYING &&
        (
          <>
             <div className='replay-state'
        >
          <Tooltip>
            <Button
              onClick={onDiscardRecord}
              style={{
                height: '35px',
                border: 0,
                borderRadius: "8px",
                backgroundColor: 'rgba(244, 93, 93, 1)',
                color: '#ffffff',
                bottom: "25px"
              }}
            >
              <Space align='center'>
              <XCircle width={21} height={21} />
                  {t('Patient.data.activityStream.discard')}
                  </Space>
                </Button>
              </Tooltip>
            </div>
          </>
        )}
      <div className='record-state'
      >
        {
          videoState === RehabVideoState.RECORDING ? <Tooltip>
            <div className="customText">
              <CountDownTimer limit={patientTimeLimit} isStartingTimer={isStartingTimer} endedTimer={onEndedTimer} />
              &nbsp;<FaCircle color='rgba(244, 93, 93, 1)' />&nbsp;</div>
          </Tooltip> : ''

        }
      </div>
    </div>
  )
}

export default ActivityStreamVideoHeader