import { Col, Empty, Image, Input, Pagination, Row, Select, Spin } from "antd"
import { useTranslation } from "react-i18next";
import { ExerciseProps, ISurvey } from "@stores/interfaces";
import { useEffect, useState } from "react";
import { List } from "@carespace-icons/layoult/list";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useTypedDispatch } from "@stores/index";
import { getClinicalSurveyTemplate, getSurveyTemplate, getSystemSurveyTemplate } from "@stores/survey/survey";
import { Grid01 } from "@carespace-icons/layoult/grid01";

const { Option } = Select

export const TemplateSurvey = (props: ExerciseProps) => {
  const { setActiveKey, searchValue, setSearchValue, setSelectedSurvey } = props
  const [templateType, setTemplateType] = useState<string>('systemTemplates')
  const [templateData, setTemplateData] = useState<ISurveyTemplatePaginated>()
  const [isGrid, setGrid] = useState(false)
  const dispatch = useTypedDispatch()
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setGrid(false)
    if (templateType == "myTemplates") {
      fetchMyTemplateData(1)
    } else if (templateType == "systemTemplates") {
      fetchSystemTemplateData(1)
    } else if (templateType == "medicalValidity") {
      fetchClinicalTemplateData(1)
    }
  }, [templateType])

  const onPageChange = (pageNumber: number) => {
    if (templateType == "myTemplates") {
      fetchMyTemplateData(pageNumber);
    } else if (templateType == "systemTemplates") {

      fetchSystemTemplateData(pageNumber);
    } else if (templateType == "medicalValidity") {

      fetchClinicalTemplateData(pageNumber);
    }
  }

  const fetchMyTemplateData = async (page: number) => {
    setLoading(true)
    const payload = {
      page: page,
      limit: 10,
      search: searchValue
    }
    const data = await dispatch(getSurveyTemplate(payload))
    if (data?.payload) {
      setTemplateData(data?.payload)
    }
    setLoading(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (templateType == "myTemplates") {
        fetchMyTemplateData(1);
      } else if (templateType == "systemTemplates") {

        fetchSystemTemplateData(1);
      } else if (templateType == "medicalValidity") {

        fetchClinicalTemplateData(1);
      }
    }
  };

  const fetchSystemTemplateData = async (page: number) => {
    setLoading(true)
    const payload = {
      page: page,
      limit: 10,
      search: searchValue
    }
    const data = await dispatch(getSystemSurveyTemplate(payload))
    if (data?.payload) {
      setTemplateData(data?.payload)
    }
    setLoading(false)
  }

  const fetchClinicalTemplateData = async (page: number) => {
    setLoading(true)
    const payload = {
      page: page,
      limit: 10,
      search: searchValue
    }
    const data = await dispatch(getClinicalSurveyTemplate(payload))
    if (data?.payload) {
      setTemplateData(data?.payload)
    }
    setLoading(false)
  }

  return <div className="template-survey">
    <div className="template-survey-header-container">
      <Select
        className="template-survey-select"
        bordered={false}
        onChange={(value) => { setSearchValue(""); setTemplateType(value) }}
        value={templateType}
      >
        <Option value="systemTemplates">{t("Admin.data.survey.systemTemplatesSurvey")}</Option>
        <Option value="myTemplates">{t("Admin.data.survey.myTemplatesSurvey")}</Option>
        <Option value="medicalValidity">{t("Admin.data.survey.clinicallyValidatedSurvey")}</Option>
      </Select>
      <Input
        className='search-input'
        placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
        value={searchValue}
        onKeyDown={(e) => handleKeyDown(e)}
        bordered={false}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {!loading &&
        <div className="card-style">
          <div className="card-style-div">
            <span onClick={() => setGrid(false)} className="button-style-grid-false"><List color={isGrid ? 'stroke-gray-300' : 'stroke-gray-600'} height={24} width={24} /></span>
            <span onClick={() => setGrid(true)} className="button-style-grid-true"><Grid01 color={!isGrid ? 'stroke-gray-300' : 'stroke-gray-600'} height={22} width={22} /></span>
          </div>
        </div>}
    </div>
    <div style={{backgroundColor:'var(--collapse-bg-color)', padding:'10px', borderRadius:'8px'}}>
    <div style={{ maxHeight: "52vh", overflowX: "hidden", overflowY: "auto" }}>
      {loading ? <Spin className="spinner" tip="Loading" size="large" /> : <>
        {
          templateData && templateData?.data && templateData?.data?.length > 0 ?
            !isGrid
              ? templateData?.data?.map((item: ISurvey) => {
                return <div style={{ marginBottom: 16 }}>
                  <div className={`card-style-grid-false`}
                    key={item.id}>
                    <div className="image">
                      {item?.image || item?.image?.url ?
                        <Image src={item?.image?.url ? item?.image?.url : item?.image} className="image-not-found" alt={t('Admin.data.survey.noImage')} onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = "/images/white-image.png";
                        }}
                          preview={{
                            src: item?.image?.url ? item?.image?.url : item?.image || "/images/white-image.png",
                            mask: <MdOutlineRemoveRedEye size={18} />,
                          }}
                        /> :
                        <Image src="/images\white-image.png" className="image-not-found" alt={t('Admin.data.survey.noImage')}
                          preview={{
                            src: "/images/white-image.png",
                            mask: <MdOutlineRemoveRedEye size={18} />,
                          }} />
                      }
                    </div>
                    <div
                      onClick={() => {
                        setSelectedSurvey({
                          ...item,
                          image: item?.image?.url ? item?.image?.url : item?.image,
                          questionList: item?.surveyTemplateQuestion
                        })
                        setActiveKey('2')
                      }}
                      className="card-content">
                      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                      {item?.clinicallyValidated && <p className="template-button-container">
                          {t("Admin.data.survey.clinicallyValidatedSurvey")}
                        </p>}
                      </div>
                      <p className="card-title" style={{color:"var(--text-gray)"}}>{item?.title}</p>
                      <p className="question-count" style={{color:"var(--text-gray)"}}>{t('Admin.data.survey.questions')}: {item?.surveyTemplateQuestion?.length}</p>
                      <p className="card-description" style={{color:"var(--text-gray)"}}>{item?.description}</p>
                    </div>
                  </div>
                </div>
              })
              : <Row gutter={[16, 16]}>
                {templateData?.data?.map((item: ISurvey) => (
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} key={item.id}>
                    <div className="card-style-grid-true" onClick={() => {
                      setSelectedSurvey({
                        ...item,
                        image: item?.image?.url ? item?.image?.url : item?.image,
                        questionList: item?.surveyTemplateQuestion
                      })
                      setActiveKey('2')
                    }}>
                      <div onClick={(e) => e.stopPropagation()}>
                        {item?.image || item?.image?.url ? (
                          <Image
                            src={item?.image?.url ? item?.image?.url : item?.image}
                            className="image-not-found"
                            alt={t('Admin.data.survey.noImage')}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = "/images/white-image.png";
                            }}
                            preview={{
                              src: item?.image?.url ? item?.image?.url : item?.image || "/images/white-image.png",
                              mask: <MdOutlineRemoveRedEye size={18} />,
                            }}
                          />
                        ) : (
                          <Image
                            src="/images/white-image.png"
                            className="image-not-found"
                            alt={t('Admin.data.survey.noImage')}
                            preview={{
                              src: "/images/white-image.png",
                              mask: <MdOutlineRemoveRedEye size={18} />,
                            }}
                          />
                        )}
                      </div>
                      <div className="card-content">
                        <p className="card-title">{item?.title}</p>
                        <span className="question-count-container">
                          <p className="question-count">{t('Admin.data.survey.questions')}: {item?.surveyTemplateQuestion?.length}</p>
                        </span>
                        <span className="question-count-container">
                          <p className="description-text">{item?.description}</p>
                        </span>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>

            : <div className="empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-gray-300'>{t('Admin.data.survey.noTemplate')}</span>} />
            </div>
        }</>}
    </div>
    {
      !loading && templateData && templateData?.data && templateData?.data?.length > 0 && <Pagination
        current={templateType === "systemTemplates" ? templateData?.meta?.pagination?.currentPage : templateData?.pagination?.currentPage}
        showSizeChanger={false}
        onChange={onPageChange}
        total={templateType === "systemTemplates" ? templateData?.meta?.pagination?.totalCount : templateData?.pagination?.totalCount}
      />
    }
    </div>
  </div>
}