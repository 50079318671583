import { Content } from 'antd/lib/layout/layout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PiArrowDownRightBold, PiArrowUpLeftBold } from 'react-icons/pi';
import { UseInstructionalVideo } from '../context/InstructionalVideo.context';
import { UseSwitchVideo } from '../context/SwitchVideo.context';
import { TransitionStates, UseTransition } from '../context/Transition.context';
import { PosturalAnalytics, UseControls } from '../context/Controls.context';
import { UseFullScreen } from '../context/FullScreen.context';

function InstructionalVideo() {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { videoPaused, onTogglePause } = UseInstructionalVideo();
	const { isSwitchMode } = UseSwitchVideo();
	const { onNextTransition, transition } = UseTransition();
	const { onGetCurrent, isRepeat, onRepeat } = UseControls();
	const { sizes, isFullScreen } = UseFullScreen();

	const [open, setOpen] = useState(false);
	const [full, setFull] = useState(true);

	const current = onGetCurrent() as Partial<PosturalAnalytics>;

	useEffect(() => {
		videoPaused ? videoRef.current?.pause() : videoRef.current?.play();
	}, [videoPaused]);

	const onToggleVideoOpened = () => {
		if (open && full) setFull(false);
		setOpen(!open);
	};

	const onended = useCallback(() => {
		setFull(false);
		onTogglePause();
		if (open && full) onNextTransition();
		if (transition?.value === TransitionStates.INTRO) {
			onNextTransition();
		}
	}, [transition, full, open, onNextTransition, onTogglePause]);

	useEffect(() => {
		if (current) {
			setOpen(true);
			setFull(true);
		}

		return () => {
			setOpen(false);
			setFull(true);
		};
	}, [current]);

	useEffect(() => {
		if (videoRef?.current && isRepeat) {
			videoRef.current.currentTime = 0;
			videoRef.current.play();
			setOpen(true);
			setFull(true);
			onRepeat(false);
		}
	}, [isRepeat, videoRef, onRepeat]);

	if (!current) return null
	return (
		<Content
			className="md:w-[180px] lg:w-[250px] xl:w-[330px] 2xl:w-[420px]"
			style={{
				position: 'absolute',
				width: isSwitchMode || full ? '100%' : '',
				zIndex: isSwitchMode ? 5 : 6,
				backgroundColor: open ? '#2c1c4e' : 'transparent',
				height: isFullScreen && full ? "100vh" : 'auto',
				opacity: 0.85,
				overflow: 'hidden',
			}}>
			<video
				style={{
					visibility: open || isSwitchMode ? 'visible' : 'hidden',
					width: '100%',
					height: isFullScreen && full ? '100%' :'auto',
					objectFit:'cover'
				}}
				id="instructionalvideo"
				ref={videoRef}
				autoPlay
				muted
				preload="metadata"
				key={current?.video?.url}
				src={current?.video?.url}
				onEnded={onended}
			/>
			{!isSwitchMode &&
				(open ? (
					<PiArrowUpLeftBold
						size={40}
						style={{
							color: '#fff',
							position: 'absolute',
							right: 0,
							bottom: 0,
							backgroundColor: '#5E59C1',
							cursor: 'pointer',
						}}
						onClick={onToggleVideoOpened}
					/>
				) : (
					<PiArrowDownRightBold
						size={40}
						style={{
							color: '#fff',
							position: 'absolute',
							left: 0,
							top: 0,
							backgroundColor: '#5E59C1',
							cursor: 'pointer',
						}}
						onClick={onToggleVideoOpened}
					/>
				))}
		</Content>
	);
}

export default InstructionalVideo;
