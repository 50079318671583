import React, { useState } from 'react'
import Button from '@atoms/Button';
import UploadExport from '@carespace-icons/general/uploadExport';
import { Checkbox, DatePicker, Input, TimeRangePickerProps, message } from 'antd';
import { HeartHand } from '@carespace-icons/general/heartHand';
import { Compass } from '@carespace-icons/education/compass';
import { MdOutlineAssignment } from 'react-icons/md';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { createReport } from '@stores/report/reports';
import { useNavigate } from 'react-router-dom';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { router } from '@routers/routers';

const { RangePicker } = DatePicker;

export default function CreateReportModalContent() {
  const [reportName, setReportName] = useState('')
  const { t } = useTranslation();
  const [selectedDates, setSelectedDates] = useState<null | (Dayjs | null)[]>([])
  const { selectedUser } = useTypedSelector(
    (state) => state.contacts.main
  )
  const user = useTypedSelector((state) => state.user)
  const [evaluation, setEvaluation] = useState(true);
  const [romSummary, setRomSummary] = useState(true);
  const [romCaptures, setRomCaptures] = useState(true);
  const [postureCaptures, setPostureCaptures] = useState(true);
  const [letsMoveSessions, setLetsMoveSessions] = useState(true);
  const [surveySessions, setSurveySessions] = useState(true);
  const navigate = useNavigate()
  const dispatch = useTypedDispatch()
  const isDark = document.documentElement.getAttribute("data-theme") === 'vibrant'

  const handleCreate = async () => {
    if (!reportName) {
      message.error(t('Admin.data.addToReports.reportNameErr'));
    }
    else if (!selectedDates || !selectedDates[0] || !selectedDates[1]) {
      message.error(t('Admin.data.addToReports.dateErr'))
    }
    else {
      const payload = {
        userId: user.isPhysioterapist ? selectedUser.id : user.id,
        name: reportName,
        evaluation: evaluation,
        romSummary: romSummary,
        romCaptures: romCaptures,
        letsMoveSessions: letsMoveSessions,
        postureResults: postureCaptures,
        surveyResults: surveySessions,
        startDate: selectedDates[0].hour(0).minute(1).second(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        endDate: selectedDates[1].add(1, 'day').hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }
      let reportCreated = false;
      const data = await dispatch(createReport(payload)).unwrap()
      reportCreated = true;
      if (data) {
        setReportName("")
        setSelectedDates([])
        navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_MY_REPORT}`)
        dispatch(setActiveTab("createReport"))
      }
    }
  };

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    setSelectedDates(dates || [])
  };

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  return (
    <>
      <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder={t('Admin.data.addToReports.reportName')}
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          suffix={<MdOutlineAssignment color="gray" />}
          style={{ marginRight: '4px', flex: '6', height: '40px' }}
        />
        <RangePicker
          presets={rangePresets}
          value={selectedDates}
          onChange={onRangeChange}
          style={{ flex: '4', marginLeft: '4px', height: '40px' }}
          placement="bottomRight"
          disabledDate={(current: Dayjs) => current && current.isAfter(dayjs(), 'day')}
        />
      </div>
      <div style={{ padding: '12px', backgroundColor: '#fff', borderRadius:'8px', marginBottom:'10px' }}>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="1" checked={evaluation} onChange={(e) => setEvaluation(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t('Admin.data.addToReports.evaluation')}</p>
        </div>
        <hr />
        <div className="create-report-item-content">
          <span className="create-report-icon-span-css fontStyles">
            <Compass color={`${isDark ? "stroke-white" : "stroke-black"}`} height={13.28} width={13.33} /> <strong style={{ fontWeight: 600, marginLeft: "8px" }}>{t('Admin.data.addToReports.omniRom')}</strong>
          </span>
        </div>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="2" checked={romSummary} onChange={(e) => setRomSummary(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t('Admin.data.addToReports.romSummary')}</p>
        </div>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="3" checked={romCaptures} onChange={(e) => setRomCaptures(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t('Admin.data.addToReports.captures')}</p>
        </div>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="3" checked={postureCaptures} onChange={(e) => setPostureCaptures(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t("Patient.data.postures.postureSummary")}</p>
        </div>
        <hr />
        <div className="create-report-item-content">
          <span className="create-report-icon-span-css fontStyles">
            <HeartHand color={`${isDark ? "stroke-white" : "stroke-black"}`} height={13.28} width={13.33} /> <strong style={{ fontWeight: 600, marginLeft: "8px" }}>{t('Admin.data.managePatient.letsMove.letsMove')}</strong>
          </span>
        </div>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="4" checked={letsMoveSessions} onChange={(e) => setLetsMoveSessions(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t('Admin.data.addToReports.sessions')}</p>
        </div>
        <hr />
        <div className="create-report-item-content">
          <span className="create-report-icon-span-css fontStyles">
            <MdOutlineAssignment color={`${isDark ? "stroke-white" : "stroke-black"}`} height={13.28} width={13.33} /> <strong style={{ fontWeight: 600, marginLeft: "8px" }}>{t('Admin.data.survey.survey')}</strong>
          </span>
        </div>
        <div className='create-report-content-icon-div checkbox-tick'>
          <Checkbox id="5" checked={surveySessions} onChange={(e) => setSurveySessions(e.target.checked)} />
          <p className='create-report-icon-styling fontStyles'>{t('Admin.data.survey.surveySummary')}</p>
        </div>
      </div>
      <Button onClick={handleCreate} size="full" >
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <UploadExport />
          {t('Admin.data.addToReports.create')}
        </span>
      </Button>
    </>
  )
}
