import { IReport, ISurveyResult, ISurveyResultQuestionList } from '@stores/interfaces';
import moment from 'moment';
import { FaceHappy } from '@carespace-icons/users/faceHappy'
import { FaceSmile } from '@carespace-icons/users/faceSmile'
import { FaceNeutral } from '@carespace-icons/users/faceNeutral'
import { FaceFrown } from '@carespace-icons/users/faceFrown'
import { FaceSad } from '@carespace-icons/users/faceSad'
import { useTranslation } from 'react-i18next';
import "./ReportSurveyData.css"
import AddNotes from './AddNotes';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { updateReportNotes } from '@stores/report/reports';
import { Col, Image, message, Row } from 'antd';
import { useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ReportNotesTemplate from './ReportNotes/ReportNotesTemplate';
import ReportNotesDownloadTemplate from './ReportNotes/ReportNotesDownloadTemplate';
import { FaceWink } from '@carespace-icons/users/faceWink';

interface ReportSurveyDataProps {
  isEditMode: boolean
  isDownload?: boolean
}

export default function ReportSurveyData(props: ReportSurveyDataProps) {
  const { isEditMode, isDownload } = props
  const { t } = useTranslation();
  const dispatch = useTypedDispatch()
  const report = useTypedSelector(state => state.reports.report)
  const [surveyResultNotes, setSurveyResultNotes] = useState<string>()

  const getPainLevel = (painLevel: number, question: ISurveyResultQuestionList) => {
    switch (painLevel) {
      case 1:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceFrown color={'stroke-rose-700'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-rose-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 2:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceFrown color={'stroke-rose-500'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-rose-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 3:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceSad color={'stroke-orange-400'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-orange-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 4:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceSad color={'stroke-orange-300'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-orange-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 5:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-yellow-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 6:
        return (
          <div className='report-survey-painlevel'>
            <div className={`${isDownload ? 'pt-[5px]' : ""}`}>
              <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-yellow-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 7:
        return (
          <div className='report-survey-painlevel'>
            <div className='pt-[5px]'>
              <FaceSmile color={'stroke-green-300'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-green-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 8:
        return (
          <div className='report-survey-painlevel'>
            <div className='pt-[5px]'>
              <FaceSmile color={'stroke-green-500'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-green-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 9:
        return (
          <div className='report-survey-painlevel'>
            <div className='pt-[5px]'>
              <FaceWink color={'stroke-green-700'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-green-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 10:
        return (
          <div className='report-survey-painlevel'>
            <div className='pt-[5px]'>
              <FaceHappy color={'stroke-cyan-500'} width={20} height={20} />
            </div>
            <span className='report-survey-painlevel-label text-cyan-500'>{question?.selectedAnswer}</span>
          </div>
        );
      default:
        return null;
    }
  }

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      surveyResultNotes: {
        notes: surveyResultNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setSurveyResultNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('surveyResultNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  return (
    <div>
      <p className="report-survey-summary">{t('Admin.data.survey.surveySummary')}</p>
      {
        report?.surveyResultIds?.map((item: ISurveyResult, index: number) => {
          let totalScore = 0;
          item.questionList.forEach((question: ISurveyResultQuestionList) => {
            totalScore += question.score || 0;
          });
          return (
            <div className='report-survey-container'>
              <p className="report-survey-date">{t('Patient.data.myProgress.evaluation.dateOfAssessment')} <span className="font-regular">{moment(item.createdAt).local().format('LLL')}</span></p>
              {<div className="report-survey-summary-container">
                <div className="report-survey-list">
                  <div className="report-survey-inner-list">
                    <p className="report-survey-title">
                      {item.title ? item.title : `Survey Result - ${index + 1}`}
                    </p>
                  </div>

                  {totalScore !== 0 && (
                    <div className="report-score-container">
                      <p className={`${!isDownload ? 'report-score-label pb-1' : "report-score-label !pb-4"}`}>
                        {t('Admin.data.survey.score')} : {totalScore}
                      </p>
                    </div>
                  )}
                </div>
                {
                  item.questionList.map((question: ISurveyResultQuestionList, index: number) => (
                    <div key={index} className="report-survey-question-container">
                      <div className="report-survey-question-inner-container">
                        <p className="report-survey-question">{index + 1} - {question.question}</p>
                        {question?.score != null &&
                          <p className={`${!isDownload ? 'report-survey-question-score pb-1' : "report-survey-question-score !pb-3"}`}>{question.score}</p>
                        }
                      </div>
                      <hr />
                      {question.answerList?.length === 0 && question.questionType === 'rate' ? (
                        <p>{getPainLevel(question.ratingLevel!, question)}</p>
                      ) : question.answerList?.length! > 0 && question.questionType !== 'rate' ? (
                        <ul>
                          {question.answerList?.map((answer: string, index: number) => (
                            <li key={index} style={{ marginBottom: "0.25rem" }}>- {answer}</li>
                          ))}
                        </ul>
                      ) : <>
                        <ul>
                          <li key={index} style={{ marginBottom: "0.25rem" }}>- {question.selectedAnswer}</li>
                        </ul>
                      </>
                      }
                    </div>
                  ))
                }
              </div>}
            </div>
          )
        })
      }
      {
        report?.surveyResultNotes?.some(
          (note) =>
            note.notes?.trim()
        ) && (<>
          {report?.surveyResultNotes?.length! > 0 ? (
            <>
              <div className="text-left mt-3 mb-3">
                <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
              </div>
              {!isDownload ? <>
                {report?.surveyResultNotes?.map((note, index) => {
                  return <ReportNotesTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                })}</> : <>
                {report?.surveyResultNotes?.map((note, index) => {
                  return <ReportNotesDownloadTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                })}
              </>}
            </>) : null}</>)}
      {
        report?.surveyResultIds?.length != 0 && !isDownload ?
          <AddNotes sendMessage={sendMessage} onClick={() => { handleSubmit() }} onChange={(event) => setSurveyResultNotes(event.target.value)} />
          : null
      }
    </div>
  )
}
