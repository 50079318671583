import { message, Result, Tooltip } from 'antd';
import { SafetyCertificateOutlined, CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface InviteCodeSettingsProps {
	inviteCode: string;
}

const InviteCodeSettings = (props: InviteCodeSettingsProps) => {
	const { inviteCode } = props;
	const { t } = useTranslation()
	const title = () => {
		return (
			<>
				{inviteCode}
				{'   '}
				<Tooltip title={t('Admin.data.menu.setting.openAi.copyClipboard')}>
					<CopyOutlined
						onClick={() => { navigator.clipboard.writeText(inviteCode); message.success(t('Admin.data.menu.setting.openAi.copied')) }}
					/>
				</Tooltip>
			</>
		);
	};

	return (
		<Result
			icon={<SafetyCertificateOutlined />}
			title={title()}
			subTitle={t('Admin.data.menu.setting.openAi.shareCode')}
		/>
	);
};

export default InviteCodeSettings;
