import React, { useEffect, useState } from 'react';

type Step = {
    label: string;
    sublabel?: string;
    onClick: () => void;
};

type StepperProps = {
    steps: Step[];
    activeStep: number;
    setActiveStep: (step: number) => void;
    isStepperClickuble?: boolean
};

const Stepper: React.FC<StepperProps> = ({ steps, activeStep, setActiveStep, isStepperClickuble = false }) => {

    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {
        setSelected(activeStep);
    }, [activeStep])

    const handleStepClick = (stepIndex: number) => {
        if(isStepperClickuble){
            setSelected(stepIndex);
            setActiveStep(stepIndex);
        }
    };

    return (
        <div className="flex items-center container select-none" style={{minWidth: '680px'}}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    {index > 0 && (
                        <div
                            className={`flex-auto border-2 border-gray-300 ${index <= selected ? 'border-primary-600' : 'border-gray-200'
                                }`}
                        ></div>
                    )}
                    <div
                        key={index}
                        className={`flex items-center justify-center h-10 w-10 rounded-full cursor-pointer ${index === selected ? 'bg-primary-100 border-4 border-primary-200' : 'bg-gray-50'
                            }`}
                        onClick={() => handleStepClick(index)}
                    >
                        {index < selected ? (
                            <span className="text-primary-600 font-bold"><img src={'/assets/tick_mark.svg'} /></span>
                        ) : (
                            <span className={`w-3 h-3 rounded-full ${index === selected ? 'bg-primary-600' : 'bg-gray-200'}`}></span>
                        )}
                        <span className={`absolute items-center justify-center flex top-14  text-base font-semibold ${index === selected ? 'text-primary-600' : 'text-gray-700'}`} style={{minWidth: '180px'}}>
                            {step.label}
                        </span>
                        {step.sublabel && (
                            <span className={`absolute top-20  text-base font-regular ${index === selected ? 'text-primary-600' : 'text-gray-600'}`} style={{minWidth: '150px'}}>
                                {step.sublabel}
                            </span>
                        )}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Stepper;

