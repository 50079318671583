import SummuryOptionsList from "@atoms/ACoachSummaryButtonExportPainAssessment/SummaryOptionsList";
import { IStrapiHealthSign, IStrapiMedicalHistory, TDataProps } from "@stores/interfaces";
import { useTranslation } from "react-i18next";

export const EvaluationDataItem = ({ evaluationData }: {evaluationData: TDataProps}) => {
  const { t } = useTranslation()

  return <div className="m-3 mt-0">
    <div className="text-left mb-3">
      <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.painSummary')}</span>
    </div>
    <ul id="painSummaryEditor" className="medium-editable container bg-gray-100 mt-1 rounded-lg border border-gray-100" style={{color: 'var(--p-text-color-root)'}}>
      {
        evaluationData?.painAssessments?.map((item, index) => {
          return (
            <li className="border-b-2 border-inherit" key={index} style={{color: 'var(--text-color-root)'}}>
              <SummuryOptionsList item={item} />
            </li>
          )
        })
      }
    </ul>
    <div className="text-left mt-3 mb-3" style={{color: 'var(--p-text-color-root)'}}>
      <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.associatedSymptoms')}</span>
    </div>
    <ul id="associatedSymptomsEditor" className="medium-editable container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
      <div className="border-b-2 border-inherit p-4">
        <span className="font-bold text-sm text-gray-700">{t('Patient.data.myProgress.evaluation.doYouHaveAnyOfTheseSymptoms')}</span>
      </div>
      {
        evaluationData?.healthSigns?.strapiHealthSigns?.map((element: IStrapiHealthSign, index: number) => {
          return (
            <li className="grid text-sm mb-1 mt-1 leading-7 pl-4" key={index} style={{color:'var(--text-color-root)'}}>
              <span className="font-regular">{element.attributes.name}</span>
            </li>
          );
        })
      }
    </ul>
    <div className="text-left mt-3 mb-3">
      <span className="font-semibold text-base text-gray-900">{t('Patient.data.myProgress.evaluation.medicalHistory')}</span>
    </div>
    <ul id="medicalHistoryEditor" className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
      <div className="border-b-2 border-inherit p-4">
        <span className="font-bold text-sm text-gray-700">{t('Patient.data.myProgress.evaluation.haveYouBeenDiagnosedWithAnyOfTheFollowing')}</span>
      </div>
      {
        evaluationData?.medicalHistories?.strapiMedicalHistories?.map((data: IStrapiMedicalHistory, i: number) => {
          return (
            <li className="grid text-sm mb-1 mt-1 leading-7 pl-4" key={i} style={{color:'var(--text-color-root)'}}>
              <span className="font-regular">{data.attributes.name}</span>
            </li>
          )
        })
      }
    </ul>
  </div>
}