import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { goToExercise } from '@stores/rom/main';
import { IRomProgramExercise } from '@stores/interfaces';
import { List, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';

interface IExerciseListMenu {
	onToggleMenu: () => void;
}

const ExerciseListMenu = (props: IExerciseListMenu) => {
	const { onToggleMenu } = props;
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { exercises, resultsExercises, currentExercise } = useTypedSelector(
		state => state.rom.main,
	);

	const onClickMenu = (exercise: IRomProgramExercise) => {
		if (currentExercise?.id !== exercise.id) {
			onToggleMenu();
			dispatch(goToExercise(exercise));
		}
	};

	if (!exercises) {
		return (
			<Spin
				spinning
				size={'large'}
				style={{
					backgroundColor: '#ffffffb8',
					height: '100%',
					maxHeight: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			/>
		);
	}

	return (
		<List
			style={{
				height: '100%',
				maxHeight: '100%',
			}}
			header={
				<Typography.Title
					level={5}
					style={{
						padding: '0 10px 0 40px',
						margin: 0,
						color: '#ffffff80',
					}}>
					{t('Patient.data.omnirom.exercises')}
				</Typography.Title>
			}
			dataSource={exercises || []}
			renderItem={exercise => {
				const isCompleted = resultsExercises.find(
					exer => exer?.id === exercise?.id,
				);
				return (
					<List.Item
						style={{
							padding: '10px 20px 10px 40px',
							borderColor: '#ffffff33',
							cursor:
								currentExercise?.id !== exercise.id ? 'pointer' : 'default',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						onClick={() => onClickMenu(exercise)}>
						<Typography.Text
							style={{ color: isCompleted ? '#ffffff' : '#ffffff80' }}
							className="flex items-center justify-center">
							{(exercise?.name
								? exercise?.name
								: exercise?.title
							)?.toUpperCase()}
						</Typography.Text>
						{isCompleted && (
							<MdCheck className="exercise-check md-icon" size="24px" />
						)}
					</List.Item>
				);
			}}
		/>
	);
};

export default ExerciseListMenu;
