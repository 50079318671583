import { ArrowRight } from '@carespace-icons/arrows/arrowRight';
import { Plus } from '@carespace-icons/general/plus';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setRomUploadDetails } from '@stores/rom/customRom';
import { Button, Col, Input, message, Row, Typography } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiImageAdd } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';

interface ImageModalContentProps {
  handleKeyPress: () => void
  activeStep: () => void
  setActiveStep: () => void
}

export default function ImageModalContent(props: ImageModalContentProps) {
  const { handleKeyPress, activeStep, setActiveStep } = props
  const { t } = useTranslation()
  const dispatch = useTypedDispatch();
  const { state } = useLocation()
  const { isOmniRomRecord, isOmniRomUpload } = useTypedSelector((state) => state.rehab.main)
  const imgInputRef = useRef<HTMLInputElement>(null);
  const [imgFile, setImgFile] = useState<File[] | undefined>([]);
  const [messageTitle, setMessageTitle] = useState('')
  const [messageDescription, setMessageDescription] = useState('');
  const navigate = useNavigate();

  const openImgInput = () => {
    imgInputRef.current?.click();
  };

  const handleFileChange = (e: { target: { files: File[] } }) => {
    const files = e.target.files;
    if (files[0]) {
      setImgFile([files[0]]);
    }
  };

  const validateFields = () => {
    if ((isOmniRomUpload || state?.omniRomUpload) && (!imgFile || imgFile.length === 0)) {
      message.error(t('Patient.data.omnirom.videoErr'));
      return false;
    }
    if (!messageTitle.trim()) {
      message.error(t('Patient.data.omnirom.titleErr'));
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validateFields()) return;
    const payload = {
      title: messageTitle,
      description: messageDescription,
      videos: imgFile
    }
    const data = dispatch(setRomUploadDetails(payload));
    data?.payload && setActiveStep(activeStep + 1)
  }

  const videoElement = useMemo(() => {
    if (!imgFile || imgFile.length === 0) return null;
    return (
      <video
        src={URL.createObjectURL(imgFile[0]!)}
        controls
        autoPlay
        loop
        muted
        style={{ width: '99%' }}
        height={500}
      />
    );
  }, [imgFile]);

  const handleCancel = () => {
		navigate(-1);
	}

  return (
    <>
      <Typography.Title style={{ color: 'white' }} level={3}>{(isOmniRomRecord || state?.omniRomRecord) ? t('Patient.data.omnirom.recordExercise') : t('Patient.data.omnirom.addExercise')}</Typography.Title>
      <div style={{ width: '100%', maxWidth: '800px', backgroundColor: 'white', padding: '25px', borderRadius: '12px', marginTop: '20px', height: (isOmniRomRecord || state?.omniRomRecord) ? "400px" : "450px" }} className={`${(isOmniRomRecord || state?.omniRomRecord) ? 'max-h-[550px]' : 'max-h-[550px]'}`}>
        <Row gutter={[16, 16]}>
          {(isOmniRomUpload || state?.omniRomUpload) && (imgFile ?? []).length === 0 && (
            <Col span={16} className='custom-col' onClick={openImgInput}>
              <div className='flexible-modal'>
                <Plus width={50} height={50} color='stroke-gray-300' />
                <p className="custom-text">{t('Patient.data.omnirom.uploadVideo')}</p>
              </div>
            </Col>
          )}

          {(isOmniRomUpload || state?.omniRomUpload) && imgFile && imgFile.length > 0 && (
            <Col span={16}>
              <div className="flexCenter" style={{ height: "100%" }}>
                {videoElement}
                <div
                  className="absolute-bottom-left"
                  onClick={openImgInput}
                >
                  <BiImageAdd style={{ height: "20px", width: "20px" }} /> {t('Patient.data.omnirom.uploadAnotherVideo')}
                </div>
              </div>
            </Col>
          )}

          <Col span={(isOmniRomRecord || state?.omniRomRecord) ? 24 : 8}>
            <div className="flex-center">
              <p className="custom-label">{t('Patient.data.omnirom.title')}</p>
            </div>
            <Input.TextArea
              id="messageTitle"
              name="messageTitle"
              onKeyDown={handleKeyPress}
              placeholder={t('Patient.data.omnirom.addTitle')}
              rows={1}
              value={messageTitle}
              onChange={(e) => { setMessageTitle(e.target.value) }}
              className='mb-3'
              style={{ border: "1px solid rgba(212, 212, 212, 1)", borderRadius: "8px" }}
            />
            <div className="flex-center">
              <p className="custom-label">{t('Patient.data.omnirom.description')}</p>
            </div>
            <Input.TextArea
              id="messageDescription"
              name="messageDescription"
              onKeyDown={handleKeyPress}
              placeholder={t('Patient.data.omnirom.addTitle')}
              rows={(isOmniRomRecord || state?.omniRomRecord) ? 8 : 10}
              value={messageDescription}
              onChange={(e) => { setMessageDescription(e.target.value) }}
              className='mb-3'
              style={{ border: "1px solid rgba(212, 212, 212, 1)", borderRadius: "8px" }}
            />
          </Col>

          <input
            ref={imgInputRef}
            type="file"
            accept="video/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <div className='w-full flex gap-4'>
          <Button onClick={handleCancel} type='primary' className='w-1/2'>
              {t('Admin.data.menu.userRoles.pendingInvites.cancel')}
          </Button>
          <Button onClick={() => handleSave()} type='primary' className='w-1/2'>
              {t('Patient.data.omnirom.next')}<ArrowRight width={20} height={20} />
          </Button>
          </div>
        </Row>
      </div>
    </>
  )
}
