import { Image03 } from '@carespace-icons/files/image03';
import { Edit05 } from '@carespace-icons/general/edit05';
import { Modal } from 'antd';
import { useState } from 'react';
import './AddCoverImage.css';
import { useTranslation } from 'react-i18next';
import AddCoverUpload from './AddCoverUpload';
import { IAddCoverImageProps } from '@stores/interfaces';

export default function AddCoverImage(props: IAddCoverImageProps) {
	const { openCoverImage, setOpenCoverImage } = props;
	const { t } = useTranslation();

	const headerButton = [
		{
			id: '1',
			name: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.uploadLabel'),
			icon: <Image03 width={20} height={20} color="stroke-gray-600" />,
		},
	];

	const [selected, setSelected] = useState('Upload');

	return (
		<Modal
			title={
				<>
					<div className="flex gap-4">
						<span>
							<Edit05 width={20} height={20} color="stroke-primary-600" />
						</span>
						<span className="titleStyle">
							{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.coverImage')}
						</span>
					</div>
				</>
			}
			onOk={() => setOpenCoverImage(false)}
			open={openCoverImage}
			style={{ top: 20 }}
			onCancel={() => setOpenCoverImage(false)}
			footer={false}
			width={900}
			className="select-none"
			maskClosable={false}>
			<hr />
			<div className="flex gap-5 items-center h-16">
				{headerButton?.map((item, index) => {
					return (
						<button
							key={index}
							className={`flex gap-2 ${selected === item?.name ? 'bg-gray-200 p-2 rounded-lg' : ''}`}
							onClick={() => {
								setSelected(item?.name);
								console.log('sele', item?.name);
							}}>
							<span>{item.icon}</span>
							<span>{item.name}</span>
						</button>
					);
				})}
			</div>
			<hr />
			{selected === 'Upload' && <AddCoverUpload {...props} />}
		</Modal>
	);
}
