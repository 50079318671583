import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { limitPagination, pagination } from '@stores/constants';
import { INewAdminPatientsList, IPostNewInviteUserData, IShowPopStatus, IUserRegisterdPatientResponse } from '@stores/interfaces';
import { message } from 'antd';
import axios from 'axios';

const initialState: INewAdminPatientsList = {
	unAssignedPatients: {
		data: [],
		pagination: { ...pagination },
	},
	pendingInvites: {
		data: [],
		pagination: { ...pagination },
	},
	registeredPatients: {
		data: [],
		pagination: { ...pagination },
	},
	allAdminList: {
		data: [],
		pagination: { ...pagination },
	},
  loading: false,
	superAdminFeatureFlags: [],
	allFeatureFlags: [],
	unAssignedCount: 0,
	pendingCount: 0,
	registeredCount: 0,
	statsCount: {
		newUsers: 0,
		pendingReview: {
			evaluation: 0,
			omniRom: 0,
			letsMove: 0,
			survey: 0,
		},
		outOfParams: {
			evaluation: 0,
			omniRom: 0,
			letsMove: 0,
			survey: 0,
		},
		followUpRequired: {
			evaluation: 0,
			omniRom: 0,
			letsMove: 0,
			survey: 0,
		},
		escalationRequired: {
			evaluation: 0,
			omniRom: 0,
			letsMove: 0,
			survey: 0,
		},
		reviewed: {
			evaluation: 0,
			omniRom: 0,
			letsMove: 0,
			survey: 0,
		},
	},
};

export const getUnAssignedPatientList = createAsyncThunk(
  'unAssignedPatients',
  async (payload: IUserRegisterdPatientResponse): Promise<any> => {
    try {
      const { nextPage , name , role} = payload
      const roleParam = role ? `&role=${role}` : '';
      const { data } = await axios.get(`/users/unassigned?limit=${limitPagination}&page=${nextPage}&name=${name ? name : ''}${roleParam}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getPendingInvitesList = createAsyncThunk(
  'pendingInvites',
  async (payload: IUserRegisterdPatientResponse): Promise<any> => {
    try {
      const { nextPage , name , role} = payload
      const roleParam = role ? `&role=${role}` : '';
      const { data } = await axios.get(`/users/pending-invites?limit=${limitPagination}&page=${nextPage}&name=${name ? name : ''}${roleParam}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getRegisteredPatientList = createAsyncThunk(
  'registeredPatients',
  async (payload: IUserRegisterdPatientResponse): Promise<any> => {
    try {
      const { nextPage, userId , role, name} = payload
      const roleParam = role ? `&role=${role}` : '';
      const { data } = await axios.get(`/users/assigned/${userId}?limit=${limitPagination}&page=${nextPage}&name=${name ? name : ''}${roleParam}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getAllAdmin = createAsyncThunk(
  'getAllAdmin',
  async (payload: any): Promise<any> => {
    try {
      const { limit, page } = payload;
      const { data } = await axios.get(`/users/physiotherapists?limit=${limit}&page=${page}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getSuperAdminFeatures = createAsyncThunk(
  'getSuperAdminFeatures',
  async (): Promise<any> => {
    try {
      const { data } = await axios.get(`/feature-flags`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getAllFeatureFlags = createAsyncThunk(
  'getAllFeatureFlags',
  async (id: string): Promise<any> => {
    try {
      const { data } = await axios.get(`/feature-flags/client/${id}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const upadteClientFeatures = createAsyncThunk(
  'upadteClientFeatures',
  async ({ features, id }: { features: any; id: string }): Promise<any> => {
    try {
      const { data } = await axios.patch(`/feature-flags/client/${id}`, features)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const savePhysiotherapistToPatient = createAsyncThunk(
  'savePhysiotherapistToPatient',
  async (payload: {
    physiotherapistId: string;
    patientId: string | null;
  }): Promise<any> => {
    try {
      const { data } = await axios.post('/users/associate-patient-physiotherapist', payload)
      return data;
    } catch (error: any) {
      error?.response?.data?.statusCode === 400 && message.error(error?.response?.data?.message)
    }
  }
)

export const postNewInviteUser = createAsyncThunk(
  'postNewInviteUser',
  async (payload: IPostNewInviteUserData): Promise<any> => {
    try {
      const { data } = await axios.post('/users/invite', payload)
      return data;
    } catch (error: any) {
      error?.response?.data?.statusCode === 400 ? message.error(error?.response?.data?.message) : message.error('Something Went Wrong !')
    }
  }
)

export const updateInviteUser = createAsyncThunk(
  'updateInviteUser',
  async ({ payload, id }: { payload: IPostNewInviteUserData; id: string }): Promise<any> => {
    try {
      const { data } = await axios.patch(`/users/invite/${id}`, payload)
      return data;
    } catch (error: any) {
      error?.response?.data?.statusCode === 400 ? message.error(error?.response?.data?.message) : message.error('Something Went Wrong !')
    }
  }
)

export const postInviteUserResend = createAsyncThunk(
  'postInviteUserResend',
  async (inviteId: string): Promise<any> => {
    try {
      const { data } = await axios.post(`/users/invite/${inviteId}/resend`)
      return data;
    } catch (error: any) {
      error?.response?.data?.statusCode === 400 ? message.error(error?.response?.data?.message) : message.error('Something Went Wrong !')
    }
  }
)

export const deletePatientFromPhysiotherapist = createAsyncThunk(
  'deletePatientFromPhysiotherapist',
  async (id: string): Promise<any> => {
    try {
      const { data } = await axios.delete(`/users/assigned/${id}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const deletePendingInvitePatients = createAsyncThunk(
  'deletePendingInvitePatients',
  async (id: string): Promise<any> => {
    try {
      const { data } = await axios.delete(`/users/invite/${id}`)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const updateShowPopStatus = createAsyncThunk(
  'updateShowPopStatus',
  async ({ showPopup, id }: { showPopup: boolean; id: string }): Promise<any> => {
    try {
      const payload = { showPopup }
      const { data } = await axios.patch(`users/associate-patient-physiotherapist/${id}/show-popup`, payload)
      return data;
    } catch (error) {
      throw error;
    }
  }
)

export const getStats = createAsyncThunk('getStats', async (): Promise<any> => {
	try {
		const { data } = await axios.get(`/stats`);
		return data;
	} catch (error) {
		throw error;
	}
});

export const adminDashboardPatient = createSlice({
  name: 'adminDashboardPatient',
  initialState,
  reducers: {
    setUnAssignedCount : (state , action) => {
      state.unAssignedCount = action.payload
    },
    setPendingCount : (state , action) => {
      state.pendingCount = action.payload
    },
    setRegisteredCount : (state , action) => {
      state.registeredCount = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUnAssignedPatientList.fulfilled, (state, action) => {
      state.unAssignedPatients.data = action.payload.data;
      state.unAssignedPatients.pagination = action.payload.pagination;
      state.loading = false;
    })
    builder.addCase(getUnAssignedPatientList.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getUnAssignedPatientList.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getPendingInvitesList.fulfilled, (state, action) => {
      state.pendingInvites.data = action.payload.data;
      state.pendingInvites.pagination = action.payload.pagination;
      state.loading = false;
    })
    builder.addCase(getPendingInvitesList.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getPendingInvitesList.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getRegisteredPatientList.fulfilled, (state, action) => {
      state.registeredPatients.data = action.payload.data;
      state.registeredPatients.pagination = action.payload.pagination;
      state.loading = false;
    })
    builder.addCase(getRegisteredPatientList.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getRegisteredPatientList.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAllAdmin.fulfilled, (state, action) => {
      state.allAdminList.data = action.payload.data
      state.allAdminList.pagination = action.payload.pagination
    })
    builder.addCase(getAllAdmin.rejected, (state, action) => { });

    builder.addCase(getAllFeatureFlags.fulfilled, (state, action) => {
      state.allFeatureFlags = action.payload
    })
    builder.addCase(getAllFeatureFlags.rejected, (state, action) => { });

    builder.addCase(getSuperAdminFeatures.fulfilled, (state, action) => {
      state.superAdminFeatureFlags = action.payload
    })

    builder.addCase(getSuperAdminFeatures.rejected, (state, action) => { });

    builder.addCase(upadteClientFeatures.fulfilled, (state, action) => { })

    builder.addCase(upadteClientFeatures.rejected, (state, action) => { })

    builder.addCase(savePhysiotherapistToPatient.fulfilled, (state, action) => { })

    builder.addCase(savePhysiotherapistToPatient.rejected, (state, action) => { })

    builder.addCase(postNewInviteUser.fulfilled, (state, action) => { })

    builder.addCase(postNewInviteUser.rejected, (state, action) => { })

    builder.addCase(postInviteUserResend.fulfilled, (state, action) => { })

    builder.addCase(postInviteUserResend.rejected, (state, action) => { })

    builder.addCase(updateInviteUser.fulfilled, (state, action) => { })

    builder.addCase(updateInviteUser.rejected, (state, action) => { })

    builder.addCase(deletePatientFromPhysiotherapist.fulfilled, (state, action) => { })

    builder.addCase(deletePatientFromPhysiotherapist.rejected, (state, action) => { })

    builder.addCase(deletePendingInvitePatients.fulfilled, (state, action) => { })

    builder.addCase(deletePendingInvitePatients.rejected, (state, action) => { })

    builder.addCase(updateShowPopStatus.fulfilled, (state, action) => { })

    builder.addCase(updateShowPopStatus.rejected, (state, action) => { })

    builder.addCase(getStats.fulfilled, (state, action) => {
      state.statsCount = action.payload
    })

    builder.addCase(getStats.rejected, (state, action) => { })
  }
})

export const { setUnAssignedCount , setPendingCount , setRegisteredCount} = adminDashboardPatient.actions
export default adminDashboardPatient.reducer