import React from 'react';
import { useTranslation } from 'react-i18next';
import { setPainStatusButton, wellCheckNess } from '@stores/onBoard/onBoard';
import { useTypedDispatch, useTypedSelector } from '@stores/index';

export interface FeatureProps {
	title: string;
	options: string[];
	imageSrc: string;
	setProgressPercent: (value: number) => void;
	setActiveStep: (value: number) => void;
	backgroundStyle: string;
	hoverBackgroundStyle: string;
}

const FeatureCard: React.FC<FeatureProps> = ({
	title,
	options,
	hoverBackgroundStyle,
	backgroundStyle,
	imageSrc,
	setProgressPercent,
	setActiveStep,
}) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	return (
		<div
			className="feature-card cursor-pointer rounded-[20px] bg-transparent"
			style={{ height: 'max-content', width: '358px' }}
			onClick={async () => {
				if (options?.length <= 3) {
					setProgressPercent(50);
					setActiveStep(5);
					dispatch(setPainStatusButton('noPain'));
					const params = { userId: user?.id, isWellCheckNess: true };
					await dispatch(wellCheckNess(params));
				} else {
					setProgressPercent(30);
					setActiveStep(3);
					dispatch(setPainStatusButton(''));
					const params = { userId: user?.id, isWellCheckNess: false };
					await dispatch(wellCheckNess(params));
				}
			}}>
			<div className="flip-container">
				<div
					style={{
						backgroundImage: `url(${imageSrc})`,
					}}
					className="flip-container-front">
					<div
						style={{
							background: backgroundStyle,
						}}
						className="flip-container-front-title">
						{title}
					</div>
				</div>
				<div
					style={{
						backgroundImage: `url(${imageSrc})`,
						transition: 'transform 0.6s ease, opacity 0.6s ease',
					}}
					className="flip-container-back">
					<div
						style={{
							background: hoverBackgroundStyle,
						}}
						className="flip-container-back-content">
						<span
							style={{
								color: 'white',
								fontSize: '20px',
								fontWeight: 'lighter',
							}}>
							{title}
						</span>
						<span>
							{options.map((option, idx) => (
								<div key={idx}>
									<p className="flip-container-option">{option}</p>
								</div>
							))}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeatureCard;
