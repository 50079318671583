import { useTypedSelector } from '@stores/index';
import { Progress } from 'antd';
import './UserUploadProgress.css'

const UserUploadProgress = () => {
	const progress = useTypedSelector(
		state => state.aiAssistant.userSlice.uploadProgress,
	);

	return (
		<div className="upload-bar-user-container">
			<div className="upload-bar-user-progress">
				<div className="upload-bar-user-progress-wrapper">
					<Progress
						percent={progress?.progress}
						strokeColor={{
							'0%': '#BDFF00',
							'100%': '#BDFF00',
						}}
						strokeWidth={12}
						showInfo={false}
						className="upload-bar-user-bar"
					/>
					<p className="upload-bar-user-text">{progress?.progress + '%'}</p>
				</div>
			</div>
		</div>
	);
};

export default UserUploadProgress;
