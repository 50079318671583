export const DEFAULT_EMAIL_TEMPLATE = `<html>
  <body style="font-family: Arial, sans-serif; color: #333;">
  <p>Hello {{name}},</p>
  <br/><p>Welcome to our system!</p>
  <br/><p>We are excited to have you on board.</p>
  <br/><p>To access your account, please use the credentials provided below.</p>
  <br/><p>For any questions or support, feel free to contact our team.</p>
  <br/><p>Best regards,</p>
  <p>{{admin_name}}</p>
  <p>Your Virtual Assistant</p>
  </body>
</html>`;

export const DEFAULT_INVITE_TEMPLATE = `<html>
  <body style="font-family: Arial, sans-serif; color: #333;">
    <p style="margin-bottom: 1.5em;">Hello {{name}},</p>
    <br/><p style="margin-bottom: 1.5em;">I'd like to invite you to join our <strong>Virtual Assistant</strong> platform, where we can work together on your recovery.</p>
    <br/><p style="margin-bottom: 1.5em;">On the platform, you will have access to your personalized treatment plan, track your progress with assessment tools, and receive guidance directly from me.</p>
    <br/><p>Click the link below to accept the invitation and start your recovery journey:</p>
    <br/><p style="margin-bottom: 1.5em;">
      <a href="{{link}}" style="color: #4A90E2; text-decoration: none;" target="_blank">Accept Invitation and Access the Platform</a>
    </p>
    <br/><p style="margin-bottom: 1.5em;">We're here to help you achieve the best results. If you need further information, feel free to contact me.</p>
    <br/><p>Best regards,</p>
    <p>{{admin_name}}</p>
    <p>Your Virtual Assistant</p>
  </body>
</html>`;

export const userCredentialsData = `<br/><br/><div style="
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    margin-top: 10px;
  ">
    <p style="margin: 10px 0; font-size: 16px;">
      <strong>Username:</strong> {{user_name}}
    </p>
    <p style="margin: 10px 0; font-size: 16px;">
      <strong>Password:</strong> {{password}}
    </p>
    <p style="margin: 10px 0; font-size: 16px;">
      <strong>URL:</strong> 
      <a href="{{url}}" style="color: #007bff; text-decoration: none;">{{url}}</a>
    </p>
    <p style="margin: 10px 0; font-size: 16px;">
      <strong>Mobile Access Code:</strong> 
      <span style="background: #007bff; color: #fff; padding: 5px 10px; border-radius: 4px;">
        {{invite_code}}
      </span>
    </p>
  </div>`;