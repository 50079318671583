export const BODY_POINTS_OPTION = [
    {
        name: 'Aggravating Factors',
        key: 'strapiAggravatingFactorsIds'
    },
    {
        name: 'Relieving Factors',
        key: 'strapiRelievingFactorsIds'
    },
    {
        name: 'Causes',
        key: 'strapiPainCausesIds'
    },
    {
        name: 'Duration',
        key: 'strapiPainDurationId'
    },
    {
        name: 'Frequency',
        key: 'strapiPainFrequencyId'
    },
    {
        name: 'Status',
        key: 'strapiPainStatusId'
    }
]

export const OPTION_LABEL = {
    AGGRAVATING_FACTORS: 'Aggravating Factors',
    RELIEVING_FACTORS: 'Relieving Factors',
    CAUSES: 'Causes',
    DURATION: 'Duration',
    FREQUENCY: 'Frequency',
    STATUS: 'Status'
}