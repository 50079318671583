import { router } from '@routers/routers';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { createOnBoardSession } from '@stores/rom/main';
import { Button } from 'antd';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PatientDashboardBanner = () => {

  const navigate = useNavigate()
  const user = useTypedSelector(state => state.user)
  const { t } = useTranslation()
  const dispatch = useTypedDispatch();

  const handleMobilityScoreCheck = async (e: MouseEvent) => {
    e.stopPropagation();
    await dispatch(createOnBoardSession({
      userId: user?.id
    }))
    navigate(`/${user?.id}${router.AIASSISTANT_START_ROM_SCAN}`, {
      state: {
        isDashboard: true,
      },
    });
  }

  return (
    <div className="flex flex-col md:flex-row items-stretch justify-between w-full gap-4 mb-5 mt-[8rem]">
      <div className="flex flex-col rounded-3xl flex-1 p-6 md:p-8 min-h-[300px]" style={{backgroundColor:'var(--card-bg-color)'}}>
        <div className="flex flex-col md:flex-row items-center">
          <img
            className="w-full md:w-1/2 rounded-t-3xl md:rounded-l-3xl mb-2"
            src="/images/dashboard/omnirom-dashboard.png"
            alt="Range of Motion"
            style={{ width: "42%" }}
          />
          <div className="w-full md:w-[60%] flex flex-col justify-between">
            <div style={{ height: "40px" }}>
              <img src="/images/dashboard/omnirom-logo.svg" alt="Logo" />
            </div>
            <p className="font-inter font-light text-[16px] md:text-[20px] text-primary-800 mt-6 leading-[24px] md:leading-[30px] flex-1">
              {t('Patient.data.dashboardScreen.romMeasures')}
            </p>
            <div className="w-full pt-2">
              <Button
                className="start-session-css custom-button-patient-dashboard mt-5 w-full text-lg md:text-xl py-2"
                onClick={(e) => handleMobilityScoreCheck(e)}
              >
                <span className='font-inter text-[14px] md:text-[20px] font-bold leading-[20px] whitespace-normal break-words'>{t('Patient.data.dashboardScreen.mobilityScore')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-3xl flex-1 p-6 md:p-8 min-h-[300px] bg-cover bg-center"
        style={{ backgroundImage: 'url("/images/dashboard/posture-banner.png")' }}>
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-[60%] flex flex-col justify-between">
            <div style={{ height: "40px" }}>
              <img src="/images/dashboard/posture-analysis.svg" alt="Posture Analysis" />
            </div>
            <p className="font-inter font-light text-[16px] md:text-[20px] text-white mt-6 leading-[24px] md:leading-[30px] flex-1">
              {t('Patient.data.dashboardScreen.postureAnalysis')}
            </p>
            <div className="w-full pt-2">
              <Button
                className="start-session-css custom-button-patient-dashboard mt-5 w-full text-lg md:text-xl py-2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/${user?.id}${router.AIASSISTANT_POSTURE_SCAN}`);
                }}
              >
                <span className='font-inter text-[14px] md:text-[20px] font-bold leading-[20px] whitespace-normal break-words'>{t('Patient.data.dashboardScreen.startPostureScan')}</span>
              </Button>
            </div>
          </div>
          <img
            className="w-full md:w-1/2 rounded-t-3xl md:rounded-l-3xl mt-5"
            src="/images/dashboard/posture-woman-banner.png"
            alt="Posture Scan"
            style={{ width: "40%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientDashboardBanner;
