import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPostureData } from '@stores/interfaces';
import axios from 'axios';

export const getPostureByPage = createAsyncThunk(
	'getPostureByPage',
	async ({ userId, page }: { userId: string; page: number }): Promise<any> => {
		try {
			const { data } = await axios.get(
				`/posture-analytics/sessions/users/${userId}?limit=10&page=${page}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

const initialState: IPostureData = {
	data: [],
	pagination: null,
	uploadProgress: 0,
};

const postures = createSlice({
	name: 'postures',
	initialState,
	reducers: {
		setUploadProgress: (state, action) => {
			state.uploadProgress = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getPostureByPage.fulfilled, (state, action) => {
			state.data = action.payload.data;
			state.pagination = action.payload.pagination;
		});
		builder.addCase(getPostureByPage.rejected, (state, action) => {});
	},
});

export const { setUploadProgress } = postures.actions;

export default postures.reducer;
