import { useEffect, useState } from 'react';
import { Button, message, Slider, Spin, Space, Segmented } from 'antd';
import { Pie } from '@ant-design/plots';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	getwholeDayActivity,
	patchwholeDayActivity,
	wholeDayActivity,
} from '@stores/onBoard/onBoard';
import { Datum } from '@ant-design/charts';
import { IWholeDayActivity } from '@stores/interfaces';

interface WholeDayActivityProps {
	setActiveStep: (value: number) => void;
	setProgressPercent: (value: number) => void;
}

interface SliderItem {
	color: string;
	img: string;
	key: string;
	name: string;
	value: number;
}

const WholeDayActivity = ({
	setActiveStep,
	setProgressPercent,
}: WholeDayActivityProps) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const userId = useTypedSelector(state => state.user.id);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isWarningShown, setIsWarningShown] = useState(false);
	const savedActivityValues = useTypedSelector(
		state => state.onBoard.onBoard.savedActivityValues,
	);

	const defaultData = [
		{
			key: 'sitting',
			name: t('Patient.data.onboard.sitting'),
			value: 0,
			color: '#D0678F',
			img: '/assets/sitting.svg',
		},
		{
			key: 'standing',
			name: t('Patient.data.onboard.standing'),
			value: 0,
			color: '#16AA9E',
			img: '/assets/standing.svg',
		},
		{
			key: 'bending',
			name: t('Patient.data.onboard.bending'),
			value: 0,
			color: '#1398B9',
			img: '/assets/bending.svg',
		},
		{
			key: 'lifting',
			name: t('Patient.data.onboard.lifting'),
			value: 0,
			color: '#2A7FBF',
			img: '/assets/lifting.svg',
		},
		{
			key: 'walking',
			name: t('Patient.data.onboard.walking'),
			value: 0,
			color: '#7674BD',
			img: '/assets/walking.svg',
		},
		{
			key: 'sleeping',
			name: t('Patient.data.onboard.sleeping'),
			value: 0,
			color: '#A265A8',
			img: '/assets/sleeping.svg',
		},
	];

	const initialData = defaultData.filter(activity =>
		['sitting', 'standing', 'sleeping'].includes(activity.key),
	);

	const [sliderData, setSliderData] = useState(initialData);
	const [activityData, setActivityData] = useState(initialData);
	const [totalHours, setTotalHours] = useState(0);
	const [tab, setTab] = useState('Basic');

	useEffect(() => {
		if (tab === 'Basic') {
			const filteredData = defaultData
				.filter(activity =>
					['sitting', 'standing', 'sleeping'].includes(activity.key),
				)
				.map(activity => {
					const existingActivity = activityData.find(
						a => a.key == activity.key,
					);
					return {
						...activity,
						value: existingActivity ? existingActivity.value : activity.value,
					};
				});
			setSliderData(filteredData);
			setActivityData(filteredData);
		} else {
			const filteredData = defaultData.map(activity => {
				const existingActivity = activityData.find(a => a.key == activity.key);
				return {
					...activity,
					value: existingActivity ? existingActivity.value : activity.value,
				};
			});
			setSliderData(filteredData);
			setActivityData(filteredData);
		}
	}, [tab]);

	useEffect(() => {
		setTotalHours(sliderData.reduce((sum, item) => sum + item.value, 0));
	}, [sliderData]);

	useEffect(() => {
		if (savedActivityValues) {
			const updatedActivityData = sliderData.map(activity => ({
				...activity,
				value: savedActivityValues[activity.key] || activity.value,
			}));
			setSliderData(updatedActivityData);
			setActivityData(updatedActivityData);
			getTotalHours(updatedActivityData);
		}
	}, [savedActivityValues]);

	useEffect(() => {
		getData();
	}, []);

	const getTotalHours = (data: SliderItem[]) => {
		const total = data.reduce(
			(sum: number, activity: { value: number }) => sum + activity.value,
			0,
		);
		if (total > 0) {
			const newData = data.filter(item => item.value > 0);
			setActivityData(newData);
		} else {
			setActivityData(sliderData);
		}
		setTotalHours(total);
	};

	useEffect(() => {
		const warningKey = 'overAllTimeWarning';
		if (totalHours > 24 && !isWarningShown) {
			message.open({
				key: warningKey,
				type: 'warning',
				content: t('Patient.data.onboard.overAllTime'),
				duration: 0,
			});
			setIsWarningShown(true);
		} else if (totalHours <= 24) {
			setIsWarningShown(false);
			message.destroy(warningKey);
		}

		if (totalHours === 0) {
			setActivityData(sliderData);
		}
	}, [totalHours]);

	const getData = async () => {
		await dispatch(getwholeDayActivity({ userId: userId }));
		setIsDataLoaded(true);
	};

	const handleNextClick = async () => {
		if (totalHours <= 0) {
			message.warning(t('Patient.data.onboard.hoursWarn'));
		} else {
			if (totalHours <= 24) {
				const payload: IWholeDayActivity = sliderData.reduce(
					(acc: IWholeDayActivity, activity: SliderItem) => {
						acc[activity.key] = activity.value;
						return acc;
					},
					tab === 'Basic' ? { bending: 0, lifting: 0, walking: 0 } : {},
				);
				savedActivityValues
					? await dispatch(
							patchwholeDayActivity({ userId: userId, body: payload }),
						)
					: await dispatch(wholeDayActivity({ userId: userId, body: payload }));
				setActiveStep!(8);
				setProgressPercent(80);
			}
		}
	};

	const handleSliderChange = (value: number, name: string) => {
		const updatedData = sliderData.map(activity =>
			activity.name === name ? { ...activity, value } : activity,
		);

		setSliderData(updatedData);
		getTotalHours(updatedData);
	};

	const pieConfig = {
		data: activityData.every(item => item.value == 0) ? activityData : activityData.filter(item => item.value > 0),
		angleField: 'value',
		colorField: 'key',
		radius: 1,
		innerRadius: 0.5,
		startAngle: -Math.PI / 2,
		endAngle: (Math.PI * 3) / 2,
		color: activityData.map(activity => activity.color),
		label: {
			type: 'inner',
			offset: '-50%',
			content: (data: Datum) => {
				return `${data.name}\n${data.value}${data.value === 1 ? 'h' : 'hs'}`;
			},
			style: {
				fontSize: 12,
				textAlign: 'center',
				fontWeight: 'bold',
				verticalAlign: 'middle',
			},
			autoRotate: false,
			rotate: 0,
		},
		pieStyle: {
			lineWidth: 5,
			stroke: '#ffffff',
			strokeOpacity: 1,
		},
		interactions: [{ type: 'element-selected' }],
		tooltip: false,
		legend: false,
		statistic: {
			title: { style: { fontSize: 24 } },
			content: {
				style: { fontSize: 24, fontWeight: 800 },
				content: `${totalHours}h`,
			},
		},
		animation: { appear: { animation: 'wave-in', duration: 1000 } },
	};

	if (!isDataLoaded) {
		return <Spin className="spin-css" tip="Loading" size="large" />;
	}

	return (
		<div className="activity-ring-chart-container">
			<div className="whole-day-header-container">
				<h1 className="whole-day-header-title">
					{t('Patient.data.onboard.howSpend')}
				</h1>
				<h3 className="whole-day-header-subtitle">
					{t('Patient.data.onboard.selectApply')}
				</h3>
			</div>
			<Space
				className="flex"
				direction="vertical"
				size="large"
				style={{ marginTop: '25px' }}>
				<Segmented
					options={['Basic', 'Advanced']}
					value={tab}
					onChange={value => setTab(value)}
				/>
			</Space>

			<div className="activity-content">
				<div className="sliders-section">
					{sliderData.map(activity => (
						<div
							key={activity.key}
							className="slider-row flex flex-col items-center justify-center w-60">
							<span
								className="activity-label"
								style={{ color: activity.color }}>
								{activity.name}
							</span>
							<Slider
								min={0}
								max={24}
								value={activity.value}
								onChange={value => handleSliderChange(value, activity.name)}
								className="activity-slider w-full flex items-start"
								trackStyle={{
									borderColor: activity.color,
									backgroundColor: activity.color,
									height: 6,
								}}
								railStyle={{
									height: 6,
								}}
								handleStyle={{
									borderColor: activity.color,
									backgroundColor: activity.color,
									boxShadow: `0 0 8px ${activity.color}`,
									height: 6,
								}}
								marks={{
									0: <div style={{ color: activity.color }}>0h</div>,
									24: <div style={{ color: activity.color }}>24h</div>,
								}}
								dotStyle={{
									borderColor: activity.color,
									height: 11,
									width: 11,
								}}
								activeDotStyle={{
									borderColor: activity.color,
									height: 11,
									width: 11,
								}}
							/>
						</div>
					))}
				</div>
				<div className="ring-chart-section">
					<Pie {...pieConfig} />
				</div>
			</div>
			<div className="whole-day-btn-container">
				<Button
					className="whole-day-mt-2 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6"
					onClick={() => {
						setActiveStep!(6);
					}}
					size="large"
					type="primary"
					block
					style={{ height: '48px', width: '400px' }}>
					{t('Patient.data.onboard.back')}
				</Button>
				<Button
					size="large"
					type="primary"
					className="rounded-full font-inter text-base font-bold leading-6"
					block
					style={{
						height: '48px',
						background: totalHours > 24 ? '#9477d9' : '#6941C6',
						cursor: totalHours > 24 ? 'not-allowed' : '',
						color: '#fff',
						width: '400px',
					}}
					onClick={handleNextClick}>
					{t('Patient.data.onboard.save')}
				</Button>
			</div>
		</div>
	);
};

export default WholeDayActivity;
