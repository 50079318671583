import { ExerciseDataItem } from '@atoms/AExerciseDataItem';
import { IProgramData } from '@stores/interfaces';
import { Empty, Pagination, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	getProgramList,
	getProgramTemplate,
} from '@stores/aiAssistant/program';
import { CreateProgramModal } from '@organisms/OCreateProgramModal';
import { useLocation } from 'react-router-dom';
import { setProgramModal } from '@stores/aiAssistant/aiAssistant';

export const Programs = () => {
	const { t } = useTranslation();
	const [openProgramModal, setOpenProgramModal] = useState(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(true);
	const [session, setSession] = useState<boolean>();
	const [refresh, setRefresh] = useState(false);
	const program = useTypedSelector(state => state.aiAssistant.program.program);
	const [programData, setProgramData] = useState<IProgramData[]>(
		program?.data || [],
	);
	const dispatch = useTypedDispatch();
	const { state } = useLocation();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const { programModal } = useTypedSelector(
		state => state.aiAssistant.aiAssistant,
	);

	useEffect(() => {
		setOpenProgramModal(state?.isGenerate || false);
	}, [state]);

	useEffect(() => setProgramData(program?.data || []), [program, refresh]);

	useEffect(() => {
		fetchHomeData(1);
	}, [user, selectedUser, refresh]);

	const fetchHomeData = async (pageNumber: number) => {
		const payload = {
			userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
			limit: 10,
			page: pageNumber,
			searchValue: searchValue,
		};
		await dispatch(getProgramList(payload)).then(() => setLoading(false));
	};

	const fetchData = (pageNumber: number) => {
		const payload = {
			limit: 10,
			page: pageNumber,
			searchValue: searchValue,
		};
		dispatch(getProgramTemplate(payload)).then(() => setLoading(false));
	};

	useEffect(() => {
		setOpenProgramModal(programModal);
	}, [programModal]);

	return (
		<div className="pl-5 pr-5">
			{user.isPhysioterapist && (
				<div
					className="w-full mb-2 mt-6 h-11 border-2 border-dashed border-gray-300 text-gray-400 font-semibold rounded-xl flex justify-center items-center cursor-pointer"
					onClick={() => setOpenProgramModal(true)}>
					{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.createProgram')}
				</div>
			)}
			<CreateProgramModal
				isVisible={openProgramModal}
				onCancel={() => {
					setOpenProgramModal(false);
					dispatch(setProgramModal(false));
				}}
				fetchData={fetchData}
				refresh={refresh}
				setRefresh={setRefresh}
				setSearchValue={setSearchValue}
				searchValue={searchValue!}
			/>
			{isLoading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{program.data?.length == 0 ? (
						<Empty
							className="bg-gray-50 p-10 m-0"
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<span className="text-gray-300">No Programs Found</span>
							}
						/>
					) : (
						<div className="collapse-wrapper">
							{programData?.map((program, index) => (
								<ExerciseDataItem
									index={index}
									isLoading={isLoading}
									setLoading={setLoading}
									refresh={refresh}
									program={program}
									programExercises={program?.exercises}
									setRefresh={setRefresh}
									session={session!}
									setSession={setSession}
								/>
							))}
						</div>
					)}
					{program?.data?.length != 0 && (
						<div>
							<Pagination
								current={program?.pagination?.currentPage}
								showSizeChanger={false}
								onChange={fetchHomeData}
								total={program?.pagination?.totalCount}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};
