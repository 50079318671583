import { IRehabVideoHeaderDataProps, RehabVideoState } from '@stores/interfaces'
import {Col,Row, Space, Tooltip, Typography } from 'antd'
import { MdFullscreen, MdFullscreenExit, MdInfoOutline, MdMenu } from 'react-icons/md'
import { useTypedSelector } from '@stores/index'
import { ETransitionsAdmin, ETransitionsUser } from '@stores/rehab/main'
import { useTranslation } from 'react-i18next'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { useEffect, useRef } from 'react'
import RehabHeaderContent from './RehabHeaderContent'

const { Text } = Typography

function RehabVideoHeader(props: IRehabVideoHeaderDataProps) {
	const { isFullscreen,	videoState,onFullscreen,onToggleMenu,onToggleInfo,savedVoice} = props

	const { currentExercise, exercises, isPhysioterapist, recordConsult, progress, sequence } = useTypedSelector((state) => state.rehab.main)
	const exerciseNumber = exercises?.findIndex(
		(exer) => exer.id === currentExercise?.id
	)

	const micRef = useRef<LottieRefCurrentProps>(null)
	const { t } = useTranslation()

	useEffect(() => {
		micRef.current?.play();
		setTimeout(() => {
			micRef.current?.stop();
		}, 2000);
	}, [savedVoice])

	if (sequence?.value !== ETransitionsAdmin.VIDEO || sequence?.value !== ETransitionsUser.VIDEO) {
		return (
			<Row
				align="middle"
				justify="end"
				style={{ height: '35px', backgroundColor: 'black' }}
			>
				<Col span={12}>
					<Row
						justify="end"
						style={{ textAlign: 'end', lineHeight: '35px' }}
					>
						<Col
							span={24}
							style={{
								height: '35px',
								display: 'flex',
								justifyContent: 'end',
								alignItems: 'center',
							}}
						>
							<Tooltip title={t('Patient.data.rehab.fullScreen')}>
								{isFullscreen ? (
									<MdFullscreenExit
										size={30}
										style={{
											verticalAlign: 'middle',
											cursor: 'pointer',
										}}
										onClick={onFullscreen}
									/>
								) : (
									<MdFullscreen
										size={30}
										style={{
											verticalAlign: 'middle',
											cursor: 'pointer',
										}}
										onClick={onFullscreen}
									/>
								)}
							</Tooltip>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}

	return (
		<Row
			align="middle"
			justify="center"
			style={{ height: '35px', backgroundColor: 'black' }}
		>
			<Col span={12}>
				<Row style={{ paddingLeft: 8, lineHeight: '35px' }}>
					{!isPhysioterapist && sequence?.value !== ETransitionsUser.EVALUATION && (
						<Col
							span={videoState != RehabVideoState.RECORDING && videoState != RehabVideoState.READY ? '3' :'1'}
							style={{
								height: '35px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Space>
								{videoState != RehabVideoState.RECORDING && videoState != RehabVideoState.READY && <Tooltip title="Menu">
									<MdMenu
										size={30}
										style={{ cursor: 'pointer' }}
										onClick={onToggleMenu}
										color='#5e5e5e'
									/>
								</Tooltip>}
								<Tooltip title="Info">
									<MdInfoOutline
										size={26}
										style={{ cursor: 'pointer' }}
										onClick={onToggleInfo}
										color='#5e5e5e'
									/>
								</Tooltip>
							</Space>
						</Col>
					)}
					<Col className={videoState != RehabVideoState.RECORDING && videoState != RehabVideoState.READY ? '' :'ml-[10px]'} span={20} style={{ top: '1px' }}>
						<Text style={{ color: 'white' }}>
							{currentExercise &&
								`${exerciseNumber + 1}/${progress.total} ${(currentExercise?.rehabExercisesLibrary?.title?.toUpperCase() || currentExercise?.strapiExercise?.name?.toUpperCase() || recordConsult?.title?.toUpperCase())}`}
						</Text>
					</Col>
				</Row>
			</Col>
			<Col span={12}>
			<RehabHeaderContent {...props}/>
			</Col>
		</Row>
	)
}

export default RehabVideoHeader