import { UseAuth } from '@contexts/AuthContext';
import Admin from './Admin';
import SuperAdmin from './SuperAdmin';
import Patient from './Patient';
import { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getUser } from '@stores/user';
import Loading from '@atoms/Loading';
import { USER_ROLES } from '@stores/constants';

function Private() {
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const { credentials } = UseAuth();

	const role = credentials?.roles ? credentials.roles[0] : undefined;

	useEffect(() => {
		if (!user?.id && credentials?.sub) {
			dispatch(getUser(credentials?.sub));
		}
	}, [user, credentials, dispatch]);

	if (role === USER_ROLES.USER) return <Patient />;
	if (role === USER_ROLES.ADMIN) return <Admin />;
	if (role === USER_ROLES.SUPER_ADMIN) return <SuperAdmin />;

	return <Loading />;
}

export default Private;
