function Logo() {
	return (
		<img
			style={{
				bottom: '40px',
				left: '40px',
				position: 'absolute',
				zIndex: 2,
			}}
			className="select-none"
			src={'/images/OmniRome.svg'}
			alt="omnirom"
		/>
	);
}

export default Logo;
