import React from "react";

export interface FeatureProps {
  title: string;
  description: string;
  imageSrc: string;
  setProgressPercent?: (value: number) => void;
  setActiveStep?: (value: number) => void;
  backgroundStyle: string;
  hoverBackgroundStyle: string;
  handleCardSelection: (title: string) => void;
  isSelected: boolean;
}

const AnimationCard: React.FC<FeatureProps> = ({
  title,
  description,
  hoverBackgroundStyle,
  backgroundStyle,
  imageSrc,
  handleCardSelection,
  isSelected,
}) => {
 
  return (
    <div
      className='slider-card'
      style={{
        height: 'max-content',
        width: '358px',
        borderRadius: '20px',
        transition: 'border 0.3s ease-in-out',
      }}>
      <div className={`slide-container ${isSelected ? 'selected' : ''}`} onClick={() => handleCardSelection(title)}>
        <div
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
          className="slide-container-front">
          <div
            style={{
              background: backgroundStyle,
            }}
            className="slide-container-front-title">
            {title}
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
          className="slide-container-back">
          <div
            style={{
              background: hoverBackgroundStyle,
            }}
            className="slide-container-back-content">
            <span
              style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: 'lighter',
              }}>
              {title}
            </span>
            <span>
              <p className="slide-container-option">{description}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimationCard;
