import { Layout, Row, Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'

const fullpage = { minHeight: '100vh' }
const partialPage = { paddingTop: '50%' }

const Loading = ({ topbar = false }) => {
	return (
		<Layout>
			<Content>
				<Row
					align="middle"
					justify="center"
					style={topbar ? partialPage : fullpage}
				>
					<Spin tip="Loading" size="large" />
				</Row>
			</Content>
		</Layout>
	)
}

export default Loading
