import { VideoRecorder } from "@carespace-icons/media/videoRecorder"
import { setActiveTab } from "@stores/aiAssistant/aiAssistant"
import { postPreAssesmentResult } from "@stores/aiAssistant/program"
import { router } from '@routers/routers';
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { IProgramPreAssesment } from "@stores/interfaces"
import { Button } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft } from "react-icons/fa"
import { MdOutlineAssignment } from "react-icons/md"
import { useNavigate } from "react-router-dom"

interface UnReportedInjuriesProps {
	activeStep: number
	setActiveStep: (activeStep: number) => void
	userPreassesmentData: IProgramPreAssesment
	savedVoice: string
	setSavedVoice: (savedVoice: string) => void
}

export const UnReportedInjuries = (props: UnReportedInjuriesProps) => {
	const { activeStep, setActiveStep, userPreassesmentData, savedVoice, setSavedVoice } = props
	const dispatch = useTypedDispatch()
	const [isYes, setYes] = useState(false)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const user = useTypedSelector(state => state.user)
	const { selectedUser } = useTypedSelector((state) => state.contacts.main);

	useEffect(() => {
		if (activeStep === 3) {
			if (savedVoice.toLowerCase().includes("virtual evaluation") || savedVoice.toLowerCase().includes("virtual evolution") || savedVoice.toLowerCase().includes("virtual")) {
				navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_VIRTUAL_EVALUATION}`);
				dispatch(setActiveTab('virtualEvaluation'))
			}
			else if (savedVoice.toLowerCase().includes("start session") || savedVoice.toLowerCase().includes("session") || savedVoice.toLowerCase().includes("start")) setActiveStep(activeStep + 1)
			else if (savedVoice.toLowerCase() === "no") handleNo()
			else if (savedVoice.toLowerCase() === "yes") handleYes()
		}
	}, [savedVoice])

	const handleNo = () => {
		setActiveStep(activeStep + 1)
		setYes(false)
		dispatch(postPreAssesmentResult({ ...userPreassesmentData }))
	}

	const handleYes = () => {
		setYes(true)
		dispatch(postPreAssesmentResult({ ...userPreassesmentData }))
	}

	const handleBackArrow = () => {
		setSavedVoice('');
		setActiveStep(activeStep - 1)
	}

	const buttonStyle = {
		color: '#533F85',
		border: 'inherit',
	};

	return !isYes
		? <div className="flex flex-col items-center select-none">
			<div className="mt-[130px] mb-[15px] w-[468px] h-[28px] flex justify-around items-center">
				<Button
					className="!rounded-full flex justify-center items-center"
					onClick={() => handleBackArrow()}
					icon={<FaArrowLeft />}
				/>
				<p className="w-[280px] text-white text-lg font-semibold text-center m-auto ml-[72px]">{t('Admin.data.rehab.rehabPreAssessment.unreportedInjuries1')}<br />{t('Admin.data.rehab.rehabPreAssessment.unreportedInjuries2')}</p>
			</div>
			<div className="w-[250px] h-[74px] mt-[15px]">
				<div className="flex justify-center gap-[25px] p-[15px]">
					<Button
						className="!w-[100px] !h-11 !bg-transparent border-2 !border-white hover:!bg-success-700 !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
						onClick={() => handleNo()}
					>
						{t('Admin.data.rehab.rehabPreAssessment.no')}
					</Button>
					<Button
						className="!w-[100px] !h-11 !bg-transparent border-2 !border-white hover:!bg-warning-300 !text-white flex justify-center items-center !font-semibold !text-base !rounded-full"
						onClick={() => handleYes()}
					>
						{t('Admin.data.rehab.rehabPreAssessment.yes')}
					</Button>
				</div>
			</div>
		</div>
		: <div className="flex flex-col items-center select-none">
			<div className="mt-[130px] mb-[15px] w-[636px] h-[56px] flex justify-around items-center">
				<Button
					className="!rounded-full flex justify-center items-center"
					onClick={() => setYes(false)}
					icon={<FaArrowLeft />}
				/>
				<p className="w-[280px] text-white text-lg font-semibold text-center m-auto ">{t('Admin.data.rehab.rehabPreAssessment.title')}</p>
			</div>
			<div className="h-auto w-[636px] rounded-xl bg-[#F9FAFB1A]">
				<div className="p-[25px] pb-0">
					<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.dearPatient')}</p><br />
					<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.unreportedInjuriesDescription1')}</p><br />
					<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.noticeDescription2')}</p><br />
					<p className="text-white text-base">{t('Admin.data.rehab.rehabPreAssessment.noticeDescription3')}</p>
				</div>
				<div className="p-[25px] flex justify-center gap-[25px]">
					<Button
						className="!w-[180px] !bg-[#9E77ED] !border-2 !border-white hover:!bg-[#6941C6] !text-white flex justify-center items-center !font-semibold !text-base"
						icon={<MdOutlineAssignment width={20} height={20} color={'stroke-white'} />}
						onClick={() => {
							navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_VIRTUAL_EVALUATION}`)
							dispatch(setActiveTab('virtualEvaluation'))
						}}
					>
						{t('Admin.data.rehab.rehabPreAssessment.virtualEvaluation')}
					</Button>
					<Button
						className="!bg-success-500 !border-2 !border-white !text-white hover:!bg-[#6941C6] hover:!border-none"
						icon={<VideoRecorder height={18} width={18} />}
						onClick={(e) => { e.stopPropagation(); setActiveStep(4) }}
					>
						{t('Admin.data.rehab.rehabPreAssessment.startSession')}
					</Button>
				</div>
			</div>
		</div>
}