import React from 'react'
import { useTranslation } from 'react-i18next'

const ANoComponentFound = () => {

  const {t} = useTranslation()
  
  return (
    <div className="no-component-found-div">{t('Admin.data.menu.aiAssistant.aiAssistantSearchBar.permissionDenied')}</div>
  )
}

export default ANoComponentFound