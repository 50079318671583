import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import 'regenerator-runtime/runtime';
import Routers from '@routers/index';
import stores from '@stores/index';
import '@utils/Api';
import './App.less';
import './Changes.less';
import './i18n';

const App = () => {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#6739b7',
					borderRadius: 7,
					colorBgContainer: '#ffffff',
					fontFamily: 'Inter, Helvetica Neue, Arial, Helvetica, sans-serif'
				},
			}}>
			<Provider store={stores}>
				<Routers />
			</Provider>
		</ConfigProvider>
	);
};

export default App;
