import { Edit05 } from '@carespace-icons/general/edit05';
import { Modal, Tabs } from 'antd';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { LibraryData } from './LibraryData';
import AddRomPopup from './AddRomPopup';
import { Check } from '@carespace-icons/general/check';
import RomTemplate from './RomTemplate';
import { ICustomRom, IRomProgramExercise } from '@stores/interfaces';

interface CreateRomPlaylistModalProps {
  isVisible: boolean;
  onCancel: () => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  activeKey: string
  setActiveKey: (val: string) => void
  subActiveKey: string
  setSubActiveKey: (val: string) => void;
}

export default function CreateRomModal(props: CreateRomPlaylistModalProps) {
  const { isVisible, onCancel, setRefresh, refresh, activeKey, setActiveKey, subActiveKey, setSubActiveKey } = props
  const { t } = useTranslation();
  const [selectedExercises, setSelectedExercises] = useState<IRomProgramExercise[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRom, setSelectedRom] = useState<ICustomRom | null>();

  const handleCancel = () => {
    onCancel();
    setSelectedExercises([]);
    setSelectedRom(null);
    setActiveKey("1")
  };

  return (
    <Modal
      title={
        <div className="flex gap-4">
          <span>
            <Edit05 width={20} height={20} color="stroke-primary-600" />
          </span>
          <span className="titleStyle">
            {t('Patient.data.omnirom.createRomProgram')}
          </span>
        </div>
      }
      style={{ top: 20 }}
      open={isVisible}
      onCancel={handleCancel}
      footer={false}
      width={974}
      className="select-none createProgramModal"
    >
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        className="createProgramTab select-none"
        tabBarStyle={{ margin: 0, backgroundColor: 'white' }}
        onChange={(value) => setActiveKey(value)}
        items={[
          {
            label: t(
              'Admin.data.menu.aiAssistant.aiAssistantPrograms.chooseLibrary',
            ),
            key: '1',
            children: <LibraryData
              selectedExercises={selectedExercises}
              setSelectedExercises={setSelectedExercises}
              setModalVisible={setModalVisible}
              subActiveKey={subActiveKey}
              setSubActiveKey={setSubActiveKey}
            />
          },
          {
            label: t(
              'Admin.data.menu.aiAssistant.aiAssistantPrograms.selectTemplate',
            ),
            key: '2',
            children: <RomTemplate onCancel={handleCancel} refresh={refresh} setRefresh={setRefresh} />
          },
        ].filter(Boolean)}
      />
      {isModalVisible && <AddRomPopup
        isVisible={isModalVisible}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRom(null);
        }}
        onOk={() => {
          setModalVisible(false);
          setSelectedExercises([]);
          handleCancel();
          setRefresh(!refresh);
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedExercises={selectedExercises}
        setSelectedExercises={setSelectedExercises}
        selectedRom={selectedRom}
        subActiveKey={subActiveKey}
        setSubActiveKey={setSubActiveKey}
        outerModal={onCancel}
      />}
    </Modal>
  )
}
