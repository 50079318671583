import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { IProgramExercise, RehabVideoState } from '@stores/interfaces';
import { setFullScreen } from '@stores/rehab/main';
import { useEffect, useRef, useState } from 'react';
import { PiArrowDownRightBold, PiArrowUpLeftBold } from 'react-icons/pi';
import './style.css';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import TextAnimation from '@atoms/TextAnimation/TextAnimation';

interface IInstructionalVideoProps {
	currentExercise: IProgramExercise;
	onStartRecord: () => void;
	videoState: RehabVideoState;
}

function InstructionalVideo(props: IInstructionalVideoProps) {
	const { currentExercise, onStartRecord, videoState } = props;
	const videoRef = useRef<HTMLVideoElement>(null);
	const [open, setOpen] = useState(false);
	const { transitionTime } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const [isFull, setFull] = useState(true);
	const [enableTrueForm, setEnableTrueForm] = useState(false);
	const { isAuto } = useTypedSelector(state => state.aiAssistant.program.main);
	const dispatch = useTypedDispatch();
	const [showVideo, setShowVideo] = useState(false);
	const onToggleVideoOpened = () => {
		if (open && isFull) {
			setFull(false);
			dispatch(setFullScreen(false));
		}
		setOpen(!open);
	};

	const { t } = useTranslation();

	useEffect(() => {
		if (currentExercise) {
			setOpen(true);
			setFull(true);
			dispatch(setFullScreen(true));
			setShowVideo(false);
			const timer = setTimeout(() => {
				setShowVideo(true);
			}, 4500);

			return () => clearTimeout(timer);
		}
	}, [currentExercise]);

	const onPlay = () => {
		if (videoRef?.current) {
			videoRef.current.currentTime = 0;
			videoRef.current.play();

			if (isAuto) {
				onStartRecord();
				setTimeout(() => {
					onended();
				}, transitionTime * 1000);
			}
		}
	};

	const onended = () => {
		setFull(false);
		dispatch(setFullScreen(false));
		videoRef.current?.play();
	};

	if (!currentExercise) return <></>;

	const video = currentExercise?.video
		? currentExercise?.video
		: currentExercise?.exerciseLibrary?.videoUrl;

	return (
		<div
			className="md:w-[180px] lg:w-[250px] xl:w-[330px] 2xl:w-[420px]"
			style={{
				position: 'absolute',
				width: isFull ? '100%' : '',
				zIndex: 3,
				minHeight: '237px',
				backgroundColor: isFull ? '#5E59C1' : 'transparent',
				opacity: enableTrueForm ? 1 : 0.85,
				height: isFull ? '100%' : 'auto',
				display: 'flex',
				flexDirection: 'column',
				background: isFull ? '#5E59C1' : 'transparent',
			}}>
			<div style={{ position: 'relative', height: isFull ? '100%' : 'auto' }}>
				{isFull && !showVideo && (
					<div
						style={{
							backgroundColor: 'rgba(105, 65, 198, 0.85)',
							overflow: 'hidden',
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							opacity: 0.85,
						}}>
						<TextAnimation
							text={
								currentExercise?.name?.toUpperCase() ||
								currentExercise?.exerciseLibrary?.title?.toUpperCase()
							}
						/>
					</div>
				)}

				{showVideo && (
					<video
						style={{
							visibility: open ? 'visible' : 'hidden',
							width: isFull ? '100%' : '420px',
							aspectRatio: isFull ? '16/9' : '',
							backgroundColor: '#5E59C1',
						}}
						id="instructionalvideo"
						ref={videoRef}
						loop={!isFull}
						autoPlay
						muted
						preload="metadata"
						key={video}
						src={video}
						onLoadedMetadata={onPlay}
						onEnded={onended}
					/>
				)}
				{!enableTrueForm && showVideo && (
					<>
						{open ? (
							<PiArrowUpLeftBold
								size={40}
								style={{
									color: '#fff',
									position: 'absolute',
									right: 0,
									bottom: 0,
									backgroundColor: '#5E59C1',
									cursor: 'pointer',
								}}
								onClick={onToggleVideoOpened}
							/>
						) : (
							<PiArrowDownRightBold
								size={40}
								style={{
									color: '#fff',
									position: 'absolute',
									left: 0,
									top: 0,
									backgroundColor: '#5E59C1',
									cursor: 'pointer',
								}}
								onClick={onToggleVideoOpened}
							/>
						)}
					</>
				)}
			</div>
			{!isAuto && !isFull && videoState === RehabVideoState.START && (
				<span
					onClick={() => {
						setFull(true);
						setEnableTrueForm(true);
						dispatch(setFullScreen(true));
						onPlay();
					}}
					className="true-to-form-label">
					{t('Admin.data.rehab.rehabPreAssessment.true2Form')}
				</span>
			)}
			{isFull && enableTrueForm && (
				<div
					onClick={() => {
						setFull(false);
						setEnableTrueForm(false);
						dispatch(setFullScreen(false));
					}}
					className="true-to-form-close">
					<MdClose size={24} color="#53389e" />
				</div>
			)}
		</div>
	);
}

export default InstructionalVideo;
