import { Layout } from 'antd';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { TransitionStates, UseTransition } from '../context/Transition.context';
import InstructionalVideo from './InstructionalVideo';
import ReadySetGo from '@atoms/APTransitionReadySetGo';
import TransitionCalibration from '@atoms/APTransitionCalibration';
import TransitionClosing from '@atoms/APTransitionClosing';
import TransitionOpenning from '@atoms/APTransitionOpenning';
import { PosturalAnalytics, UseControls } from '../context/Controls.context';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import EventEmitter from '@services/EventEmitter';
import { UseFullScreen } from '../context/FullScreen.context';
import { useTypedSelector } from '@stores/index';
import SingleResult from '@molecules/MPTransitionSingleResultRom';

const { Content } = Layout;

const landMarks = {
	front: [
		0, 2, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28, 29,
		30,
	],
	back: [7, 8, 11, 12, 13, 14, 23, 24, 25, 26, 27, 28, 29, 30],
	left: [0, 2, 7, 11, 13, 15, 23, 25, 27],
	right: [0, 5, 8, 12, 14, 16, 24, 26, 28],
};

const customLabel = {
	front: 'FACE FORWARD',
	back: 'FACE BACKWARD',
	left: 'FACE LEFT',
	right: 'FACE RIGHT',
};

function Transitions({ children }: Readonly<PropsWithChildren>) {
	const { transition, onNextTransition, onResetTransition } = UseTransition();
	const { onNext, onGetCurrent, isRepeat } = UseControls();
	const { uploadProgress } = useTypedSelector(state => state.postures.postures);

	const [bodyPointsVisible, setBodyPointsVisible] = useState<boolean>(true);

	const current = onGetCurrent() as Partial<PosturalAnalytics>;

	const frameCallback = useCallback(
		(results: NormalizedLandmark[]) => {
			if (transition?.value === TransitionStates.CALIBRATION) {
				const isVisible = landMarks[current.view!]?.every(index => {
					return results[index]?.visibility > 0.6;
				});
				setBodyPointsVisible(isVisible);
			}
		},
		[transition],
	);

	useEffect(() => {
		if (current || isRepeat) onResetTransition();
	}, [current, isRepeat, onResetTransition]);

	useEffect(() => {
		if (transition?.value === TransitionStates.INTRO) {
			setBodyPointsVisible(false);
		}
	}, [transition]);

	useEffect(() => {
		if (
			transition?.value === TransitionStates.CALIBRATION &&
			bodyPointsVisible
		) {
			onNextTransition();
		}
	}, [transition, bodyPointsVisible, onNextTransition]);

	useEffect(() => {
		EventEmitter.on('results', results => {
			frameCallback(results);
		});

		return () => {
			EventEmitter.removeListener('results');
		};
	}, [frameCallback]);

	console.count('Transitions render');

	return (
		<Content className="h-full overflow-hidden">
			<InstructionalVideo />
			{!bodyPointsVisible &&
				transition?.value === TransitionStates.CALIBRATION && (
					<TransitionCalibration label={customLabel[current.view!]} />
				)}

			{transition?.value === TransitionStates.READYSETGO && (
				<ReadySetGo
					isPaused={false}
					onNextTransition={onNextTransition}
					transitionTime={3}
				/>
			)}
			{transition?.value === TransitionStates.CLOSING && (
				<TransitionClosing
					isPaused={false}
					onNextTransition={onNextTransition}
				/>
			)}

			{transition?.value === TransitionStates.OPENNING && (
				<TransitionOpenning isPaused={false} onNextExercise={onNext} />
			)}

			{transition?.value === TransitionStates.RESULT && uploadProgress > 0 && (
				<SingleResult
					exerciseName={current.view!}
					onNextTransition={onNextTransition}
				/>
			)}

			<div
				id="printscreen_posture_analytics"
				style={{
					position: 'relative',
					display: 'block'
				}}>
				{children}
			</div>
		</Content>
	);
}

export default Transitions;
