import { memo, useCallback, useEffect, useRef } from 'react';
import EventEmitter from '@services/EventEmitter';
import { UseTransition } from '../context/Transition.context';
import { NormalizedLandmark, PoseLandmarker } from '@mediapipe/tasks-vision';
import { drawOptions, landmarksToRemove, videoSize } from '../constants';
import { UseFullScreen } from '../context/FullScreen.context';
import Mediapipe from './Mediapipe';

function Capture() {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const { isFullScreen } = UseFullScreen();
	const { transition } = UseTransition();

	const frameCallback = useCallback(
		(results: NormalizedLandmark[]) => {
			const canvasCtx = canvasRef.current?.getContext(
				'2d',
			) as CanvasRenderingContext2D;

			const width: number = videoSize.width;
			const height: number = videoSize.height;

			canvasCtx.save();
			canvasCtx.clearRect(0, 0, width, height);
			canvasCtx.fillStyle = 'white';

			PoseLandmarker.POSE_CONNECTIONS.forEach(connection => {
				const startLandmarkIndex = connection.start;
				const endLandmarkIndex = connection.end;

				if (
					!landmarksToRemove.includes(startLandmarkIndex) &&
					!landmarksToRemove.includes(endLandmarkIndex)
				) {
					const start = results[startLandmarkIndex];
					const end = results[endLandmarkIndex];

					if (
						start.visibility < drawOptions.visibilityMin ||
						end.visibility < drawOptions.visibilityMin
					) {
						canvasCtx.restore();
						return;
					}

					const startX = start.x * width;
					const startY = start.y * height;
					const endX = end.x * width;
					const endY = end.y * height;

					canvasCtx.globalCompositeOperation = 'destination-over';
					canvasCtx.lineWidth = drawOptions.lineWidth;
					canvasCtx.strokeStyle = drawOptions.color;
					canvasCtx.beginPath();
					canvasCtx.moveTo(startX, startY);
					canvasCtx.lineTo(endX, endY);
					canvasCtx.stroke();

					canvasCtx.globalCompositeOperation = 'source-over';
					canvasCtx.fillStyle =
						startLandmarkIndex % 2 === 0 ? 'rgb(0,217,231)' : 'rgb(255,138,0)';
					canvasCtx.beginPath();
					canvasCtx.arc(
						startX,
						startY,
						drawOptions.lineWidth + 2,
						0,
						2 * Math.PI,
					);
					canvasCtx.arc(endX, endY, drawOptions.lineWidth + 2, 0, 2 * Math.PI);
					canvasCtx.fill();
				}

				canvasCtx.restore();
			});
		},
		[canvasRef],
	);

	useEffect(() => {
		EventEmitter.on('results', results => {
			frameCallback(results);
		});

		return () => {
			EventEmitter.removeListener('results');
		};
	}, [frameCallback, transition]);

	return (
		<div
			style={{
				maxWidth: isFullScreen ? '100%' : 1280,
				width: '100%',
				aspectRatio: '16/9',
				display: 'block',
				zIndex: 2,
				position: 'relative',
			}}>
			<Mediapipe />
			<canvas
				id="canvas"
				ref={canvasRef}
				width={videoSize.width}
				height={videoSize.height}
				style={{
					maxWidth: isFullScreen ? '100%' : 1280,
					width: '100%',
					aspectRatio: '16/9',
					zIndex: 3,
					position: 'absolute',
					pointerEvents: 'none',
					top: 0,
					left: 0,
				}}
			/>
		</div>
	);
}

export default memo(Capture);
