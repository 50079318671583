import axios from 'axios';
import EventEmitter from '@services/EventEmitter';
import {
	STORAGE_KEY_TOKEN,
	STORAGE_KEY_TYPE,
	EVENT_REFRESH_TOKEN,
} from '@constants/authContants';
import { router } from '@routers/routers';

axios.defaults.baseURL = process.env.REACT_APP_ADMIN_HOST;

let requestsToRefresh: Array<(token: string | null) => void> = [];
let isRefreshRequesting = false;

// Add a request interceptor
axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem(STORAGE_KEY_TOKEN);
		const tokenType = localStorage.getItem(STORAGE_KEY_TYPE);
		if (token && tokenType && config?.headers) {
			config.headers['Authorization'] = `${tokenType} ${token}`;
		}
		return config;
	},
	error => {
		Promise.reject(error);
	},
);

axios.interceptors.response.use(
	response => response,
	function (error) {
		const { response, config } = error;
		if (response.status === 401) {
			const token = localStorage.getItem(STORAGE_KEY_TOKEN);

			if (!token) {
				window.location.href = router.ROOT;
				return Promise.reject(error);
			}

			if (!isRefreshRequesting) {
				isRefreshRequesting = true;

				const callback = () => {
					return function (token: string | null) {
						if (token) {
							requestsToRefresh.forEach(cb => cb(token));
						} else {
							requestsToRefresh = [];
							isRefreshRequesting = false;
						}
					};
				};

				EventEmitter.emit(EVENT_REFRESH_TOKEN, callback());
			}

			return new Promise((resolve, reject) => {
				requestsToRefresh.push((token: string | null) => {
					if (token) {
						const tokenType = localStorage.getItem(STORAGE_KEY_TYPE);
						if (token && tokenType && config?.headers) {
							config.headers['Authorization'] = `${tokenType} ${token}`;
						}
						resolve(axios(config));
					}

					reject(error);
				});
			});
		}
		return Promise.reject(error);
	},
);
