
import { useTypedDispatch, useTypedSelector } from '@stores/index'
import { List, Spin, Typography } from 'antd'
import { MdCheck } from 'react-icons/md'
import { IProgramExercise } from '@stores/interfaces'
import { useTranslation } from 'react-i18next'
import { goToExercise } from '@stores/aiAssistant/program'

function Menu({ onToggleMenu }: { onToggleMenu: () => void }) {
	const dispatch = useTypedDispatch()
	const { t } = useTranslation()
	const { exercises, currentExercise, completedExercises } = useTypedSelector((state) => state.aiAssistant.program.main)

	const onClickMenu = (exercise: IProgramExercise) => {
		if (currentExercise?.id !== exercise.id) {
			onToggleMenu()
			dispatch(goToExercise(exercise))
		}
	}

	if (!exercises) {
		return (
			<Spin
				spinning
				size={'large'}
				style={{
					backgroundColor: '#ffffffb8',
					height: '100%',
					maxHeight: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			/>
		)
	}

	return (
		<List
			style={{
				height: '100%',
				maxHeight: '100%',
			}}
			header={
				<Typography.Title
					level={5}
					style={{
						padding: '0 10px 0 40px',
						margin: 0,
						color: '#ffffff80',
					}}
				>
					{t('Patient.data.omnirom.exercises')}
				</Typography.Title>
			}
			dataSource={exercises || []}
			renderItem={(exercise) => {
				const isDone = completedExercises.find(exer => exer.id == exercise.id)
				return (
					<List.Item
						style={{
							padding: '10px 20px 10px 40px',
							borderColor: '#ffffff33',
							cursor: currentExercise?.id !== exercise.id ? 'pointer' : 'default',
						}}
						onClick={() => onClickMenu(exercise)}
					>
						<Typography.Text
							style={{ color: isDone ? '#ffffff' : '#ffffff80' }}
						>
							{exercise?.name ? <>
							{exercise?.name?.toUpperCase()}
							</>:<>{exercise?.exerciseLibrary?.title?.toUpperCase()}</>}
						</Typography.Text>
						{isDone && <MdCheck
							className="exercise-check md-icon"
							size="24px"
						/>}
					</List.Item>
				)
			}}
		/>
	)
}

export default Menu
