import { FaceFrown } from '@carespace-icons/users/faceFrown';
import { FaceHappy } from '@carespace-icons/users/faceHappy';
import { FaceNeutral } from '@carespace-icons/users/faceNeutral';
import { FaceSad } from '@carespace-icons/users/faceSad';
import { FaceSmile } from '@carespace-icons/users/faceSmile';
import { IRebhabProgramExercise } from '@stores/interfaces';
import { Rate } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRating {
	selectedRating: number;
	exercise: IRebhabProgramExercise[];
	onRatingChange: (rating: number) => void;
}

const RateExercise = (props: IRating) => {
	const { selectedRating, onRatingChange } = props;
	const [hoverIndex, setHoverIndex] = useState(-1);
	const { t } = useTranslation();

	const desc = ['TOO EASY', 'EASY', 'NORMAL', 'HARD', 'TOO HARD'];

	const hoverColors = [
		'stroke-green-200',
		'stroke-green-500',
		'stroke-yellow-400',
		'stroke-orange-400',
		'stroke-rose-500',
	];

	const getColor = (index: number) => {
		if (hoverIndex >= 0)
			return index == hoverIndex ? hoverColors[index] : 'stroke-white';
		return index + 1 == selectedRating ? hoverColors[index] : 'stroke-white';
	};

	const customIcons: Record<number, React.ReactNode> = {
		0: <FaceHappy color={getColor(0)} width={33} height={33} />,
		1: <FaceSmile color={getColor(1)} width={33} height={33} />,
		2: <FaceNeutral color={getColor(2)} width={33} height={33} />,
		3: <FaceFrown color={getColor(3)} width={33} height={33} />,
		4: <FaceSad color={getColor(4)} width={33} height={33} />,
	};

	const handleRating = (value: number) => {
		onRatingChange(value);
	};

	return (
		<div className="flex flex-col justify-center items-center">
			<span className="mt-1 flex items-center gap-3">
				<p className="!text-white font-semibold text-sm">
					{t('Admin.data.addNotes.tooEasy').toUpperCase()}
				</p>
				<Rate
					value={selectedRating}
					character={({ index }) => (
						<div className="flex flex-col justify-center items-center">
							{customIcons[index]}
						</div>
					)}
					tooltips={desc}
					onChange={handleRating}
					onHoverChange={index => setHoverIndex(index - 1)}
				/>
				<p className="!text-white font-semibold text-sm">
					{t('Admin.data.addNotes.tooHard').toUpperCase()}
				</p>
			</span>
		</div>
	);
};

export default RateExercise;
