import { Col, Row, Image, Modal, Empty } from 'antd';
import moment from 'moment';
import { CheckCircleFilled } from '@ant-design/icons';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ReactCompareImage from 'react-compare-image';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PostureAnalyticsItem } from '@stores/interfaces';

export default function CustomCaptureScreenshots({ exercise }) {
  const { t } = useTranslation();
  const [compareImage, setCompareImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState<PostureAnalyticsItem[]>([]);
  const showCompare = () => setCompareImage(!compareImage);

  const onSelectImage = (image: PostureAnalyticsItem, b: number) => {
    setSelectedImages((prevSelectedImages) => {
      const exists = prevSelectedImages.find((img) => img.id === image.id);
      if (exists) {
        return prevSelectedImages.filter((img) => img.id !== image.id);
      } else if (prevSelectedImages.length < 2) {
        return [...prevSelectedImages, { ...image, value: b }];
      }
      return prevSelectedImages;
    });
  };

  const filteredImages = exercise[0]?.romResults?.map((result) => ({
    dataItem: result?.romPatientResult,
    b: result?.value,
  }));

  return (
    <>
      {selectedImages?.length > 0 && (
        <div
          className={`gallery-top ${selectedImages?.length === 2
            ? 'active'
            : ''
            }`}>
          <button
            disabled={
              selectedImages?.length < 2
            }
            className={`btn-compare ${selectedImages?.length === 2
              ? 'active'
              : ''
              }`}
            onClick={showCompare}
          >
            {t('Patient.data.myProgress.omniRom.compare')}
          </button>
        </div>
      )}
      <Row gutter={[14, 8]} style={{ padding: '12px' }}>
        {filteredImages && filteredImages?.length > 0 ? (
          filteredImages?.map(({ dataItem, b }, index) => {
            const DateUtc = moment(dataItem?.createdAt).local().format('LLL');
            return (
              <Col
                key={`col-${index}`}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                lg={{ span: 5 }}
                xxl={{ span: 5 }}
              >
                <div className="screenshot tipChart">
                  <Image
                    className={selectedImages?.some((img) => img.id === dataItem.id) ? 'active' : ''}
                    style={{ objectFit: 'cover' }}
                    width="100%"
                    height={125}
                    src={dataItem.screenshot}
                    preview={{
                      src: dataItem.screenshot,
                      mask: <MdOutlineRemoveRedEye size={18} />,
                      width: 'auto',
                      height: 'auto',
                    }}
                  />
                  <CheckCircleFilled
                    className={selectedImages?.some((img) => img.id === dataItem.id) ? 'select-icon active' : 'select-icon'}
                    onClick={() => onSelectImage(dataItem, b)}
                  />
                  <div className="date">{DateUtc}</div>
                  <div className="value">{b}°</div>
                </div>
              </Col>
            );
          })
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%' }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className="text-gray-300">{t('Patient.data.postures.noCaptureFound')}</span>
              }
            />
          </div>
        )}
      </Row>
      {compareImage && selectedImages?.length === 2 && (
        <Modal
          title={t('Patient.data.myProgress.omniRom.compareImages')}
          open={compareImage}
          onOk={showCompare}
          onCancel={showCompare}
          className="gallery-modal"
          centered
          width="60%"
        >
          <div className="screen-gallery">
            <ReactCompareImage
              className="gallery-compare"
              leftImage={selectedImages[0]?.screenshot}
              rightImage={selectedImages[1]?.screenshot}
              leftImageLabel={
                <>
                  {moment(selectedImages[0]?.createdAt).local().format('LLL')}
                  <div
                    style={{
                      color: '#fff',
                      fontWeight: 'bold',
                      position: 'relative',
                    }}
                    className="flex justify-start"
                  >
                    <p
                      className="text-[3rem] w-10 flex items-center justify-center p-2 rounded-full"
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                      }}
                    >
                      {selectedImages[0]?.value}°
                    </p>
                  </div>
                </>
              }
              rightImageLabel={
                <>
                  {moment(selectedImages[1]?.createdAt).local().format('LLL')}
                  <div
                    style={{
                      color: '#fff',
                      fontWeight: 'bold',
                      position: 'relative',
                    }}
                    className="flex justify-end"
                  >
                    <p
                      className="text-[3rem] w-10 flex items-center justify-center p-2 rounded-full"
                      style={{
                        position: 'absolute',
                        top: '100%',
                        right: 0,
                      }}
                    >
                      {selectedImages[1]?.value}°
                    </p>
                  </div>
                </>
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
}
