import {
	createContext,
	useContext,
	useState,
	useMemo,
	useCallback,
	PropsWithChildren,
	useEffect,
} from 'react';

interface SwitchVideoContextData {
	isSwitchMode: boolean;
	cameraId: string | null;
	onSwitchCamera: () => void;
}

const SwitchVideoContext = createContext<SwitchVideoContextData>({
	isSwitchMode: false,
	cameraId: null,
	onSwitchCamera: () => {},
});

export function SwitchVideoProvider({ children }: Readonly<PropsWithChildren>) {
	const [switchMode, setSwitchMode] = useState(false);
	const [cameraId, setCameraId] = useState<string | null>(null);

	const onSwitchCamera = async () => {
		await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
		const devices = await navigator.mediaDevices.enumerateDevices();
		const cameras = devices.filter(device => device.kind === 'videoinput');
		setSwitchMode(cameras.length > 1);

		if (cameras.length > 0) {
			const currentCameraIndex =
				cameras.findIndex(camera => camera.deviceId === cameraId) || 0;

			const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
			const nextCamera = cameras[nextCameraIndex];

			if (nextCamera.deviceId !== '') {
				return setCameraId(nextCamera.deviceId);
			}

			setTimeout(() => onSwitchCamera(), 1000);
		}
	};

	useEffect(() => {
		onSwitchCamera();
		return () => {
			setSwitchMode(false);
			setCameraId(null);
		};
	}, []);

	const values = useMemo(
		() => ({ isSwitchMode: switchMode, cameraId }),
		[switchMode, cameraId],
	);

	return (
		<SwitchVideoContext.Provider value={{ ...values, onSwitchCamera }}>
			{children}
		</SwitchVideoContext.Provider>
	);
}

export function UseSwitchVideo() {
	return useContext(SwitchVideoContext);
}
