import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IProgramFilterOptions, IStrapiPagination } from '@stores/interfaces';
import axios from 'axios';
import { stringify } from 'qs';
import strapi from 'src/Strapi';

interface FunctionalGoalsState {
	popoverState: boolean;
	functionalGoals: IGetDetails | null;
	joints: IProperDetails | null;
	categories: IGetDetails | null;
	bodyRegions: IProperDetails | null;
	loading: boolean;
	error: string | null;
	jointWithBodyRegion: IProperDetails | null;
	selectedSideName: string;
}

interface SystemLibrary {
	page: number;
	limit: number;
	title: string;
	filterOptions: IProgramFilterOptions;
}

interface SystemTemplates {
	page: number;
	limit: number;
	title: string;
	filterOptions: IProgramFilterOptions;
}
export interface IGetDetails {
	data: [
		{
			id: string;
			attributes: {
				name: string;
				createdAt: string;
				updatedAt: string;
				publishedAt: string;
			};
		},
	];
	meta: {
		pagination: IStrapiPagination;
	};
}

export interface IProperDetails {
	data: [
		{
			id: number;
			name: string;
			locale: string;
			createdAt: string;
			updatedAt: string;
			publishedAt: string;
		},
	];
	meta: {
		pagination: IStrapiPagination;
	};
}
export const getFunctionalGoals = createAsyncThunk(
	'getFunctionalGoals',
	async (): Promise<IGetDetails> => {
		const { data } = await strapi.get('/functional-goals/');
		return data;
	},
);

export const getMySystemLibraryData = createAsyncThunk(
	'getMySystemLibraryData',
	async (payload: SystemLibrary): Promise<any> => {
		const { title } = payload;
		let queryObject: any = {
			pagination: {
				page: payload.page,
				pageSize: payload.limit,
			},
			populate: {
				exercise_video: {
					fields: 'url',
				},
				exercise_image: {
					fields: 'url',
				},
				joints: {
					attributes: true,
				},
				functional_goals: {
					attributes: true,
				},
				body_regions: {
					attributes: true,
				},
				exercise_categories: {
					attributes: true,
				},
			},
			filters: {
				body_regions: {
					id: {
						$in: payload.filterOptions.bodyRegionIds,
					},
				},
				exercise_categories: {
					id: {
						$in: payload.filterOptions.exerciseCategoriesIds,
					},
				},
				joints: {
					id: {
						$in: payload.filterOptions.jointsIds,
					},
				},
				functional_goals: {
					id: {
						$in: payload.filterOptions.functionalGoalsIds,
					},
				},
			},
		};

		// Handle "left" or "right" alone case
		if (title.toLowerCase() === 'left' || title.toLowerCase() === 'right') {
			queryObject.filters['$or'] = [
				{
					name: {
						$containsi: title.toLowerCase(),
					},
				},
				{
					description: {
						$containsi: title.toLowerCase(),
					},
				},
			];
		} else if (title.toLowerCase() === 'both') {
			queryObject.filters['$and'] = [
				{
					$or: [
						{
							name: {
								$notContainsi: 'left',
							},
						},
						{
							name: {
								$notContainsi: 'right',
							},
						},
					],
				},
				{
					$or: [
						{
							description: {
								$notContainsi: 'left',
							},
						},
						{
							description: {
								$notContainsi: 'right',
							},
						},
					],
				},
			];
		} else {
			queryObject.filters['$or'] = [
				{
					name: { $contains: title },
				},
				{
					reasons: {
						name: { $contains: title },
					},
				},
			];
		}

		const filters = queryObject.filters;
		if (filters['$or']) {
			filters['$or'] = filters['$or'].reduce(
				(acc: any, currentValue: any, index: number) => {
					acc[`$or[${index}]`] = currentValue;
					return acc;
				},
				{},
			);
		}
		const queryString = stringify(queryObject, { arrayFormat: 'brackets' });
		const { data } = await strapi.get(`/exercises?${queryString}`);
		return data;
	},
);

export const getMySystemTemplatesData = createAsyncThunk(
  'getMySystemTemplatesData',
  async (payload: SystemTemplates): Promise<SystemTemplates> => {
    const queryObject = stringify({
      pagination: {
        page: payload?.page || 1,
        pageSize: 10,
      },
      populate: {
        thumbnail: 'url',
        exercises: {
          populate: {
            strapiExerciseId: {
              populate: {
                exercise_video: {
                  fields: "url",
                },
                exercise_image: {
                  fields: "url",
                },
              },
            },
          },
        },
      },
      filters: {
        $or: [
          {
            name: {
              $contains: payload?.title || "",
            },
          },
        ],
        ...(payload.filterOptions.bodyRegionIds.length && {
          body_regions: {
            id: {
              $in: payload.filterOptions.bodyRegionIds,
            },
          },
        }),
        ...(payload.filterOptions.exerciseCategoriesIds.length && {
          categories: {
            id: {
              $in: payload.filterOptions.exerciseCategoriesIds,
            },
          },
        }),
        ...(payload.filterOptions.jointsIds.length && {
          joints: {
            id: {
              $in: payload.filterOptions.jointsIds,
            },
          },
        }),
        ...(payload.filterOptions.functionalGoalsIds.length && {
          functional_goals: {
            id: {
              $in: payload.filterOptions.functionalGoalsIds,
            },
          },
        }),
      },
    });

		const { data } = await strapi.get(`/program-templates?${queryObject}`);
		return data;
	},
);

export const getJoints = createAsyncThunk(
	'getJoints',
	async (): Promise<IProperDetails> => {
		const { data } = await strapi.get('/joints/');
		return data;
	},
);

export const getCategories = createAsyncThunk(
	'getCategories',
	async (): Promise<IGetDetails> => {
		const { data } = await strapi.get('/exercise-categories/');
		return data;
	},
);

export const getBodyRegions = createAsyncThunk(
	'getBodyRegions',
	async (): Promise<IProperDetails> => {
		const { data } = await strapi.get('/body-regions/');
		return data;
	},
);

export const getJointWithBodyRegion = createAsyncThunk(
	'getJointWithBodyRegion',
	async (): Promise<IProperDetails> => {
		const { data } = await strapi.get('/body-regions?populate[joints]=true');
		return data;
	},
);

const initialState: FunctionalGoalsState = {
	popoverState: false,
	functionalGoals: null,
	joints: null,
	categories: null,
	bodyRegions: null,
	loading: false,
	error: null,
	jointWithBodyRegion: null,
	selectedSideName: '',
};

export const functionalGoals = createSlice({
	name: 'functionalGoals',
	initialState,
	reducers: {
		setPopoverState: (state, action) => {
			state.popoverState = action.payload;
		},
		setSelectedSideName: (state, action) => {
			state.selectedSideName = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getFunctionalGoals.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getFunctionalGoals.fulfilled, (state, action) => {
				state.loading = false;
				state.functionalGoals = action.payload;
			})
			.addCase(getFunctionalGoals.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getJoints.fulfilled, (state, action) => {
				state.joints = action.payload;
			})
			.addCase(getCategories.fulfilled, (state, action) => {
				state.categories = action.payload;
			})
			.addCase(getBodyRegions.fulfilled, (state, action) => {
				state.bodyRegions = action.payload;
			})
			.addCase(getJointWithBodyRegion.fulfilled, (state, action) => {
				state.jointWithBodyRegion = action.payload;
			});
	},
});

export const { setPopoverState, setSelectedSideName } = functionalGoals.actions;

export default functionalGoals.reducer;
