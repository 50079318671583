import { useEffect } from 'react';

export const VoiceControl = (handleVoiceRecognition: (updatedTranscript: string) => void) => {
  useEffect(() => {
    const handleSpeech = () => {
      const recognition = new (window as any).webkitSpeechRecognition || new (window as any).SpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true; 
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event: { results: { transcript: any; }[][]; }) => {
        const transcript = event.results[0][0].transcript;
        const updatedTranscript = transcript.toLowerCase();
        handleVoiceRecognition(updatedTranscript);
      }

      recognition.onerror = () => {
        recognition.stop();
      };

      recognition.start();

      const onStopRecognition = () => {
        recognition.stop();
      };
    
      return () => {
        onStopRecognition();
      };
    };

    handleSpeech();
  }, [handleVoiceRecognition]); 
};

