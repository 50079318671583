import PostureScan from '@pages/PostureScan';
import { Button } from 'antd';
import './PostureAnalysis.css';
import { TOnBoardPostureProps } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setActiveStep as SActiveStep } from '@stores/onBoard/onBoard';
import { useEffect, useState } from 'react';

const PostureAnalysis = (props: TOnBoardPostureProps) => {
  const { setActiveStep, setProgressPercent, onComplete } = props;
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const videoRecordState = useTypedSelector((state) => state.onBoard.onBoard.videoRecordState)
  const [scanState, setScanState] = useState(false)
  const buttonStyle = {
    color: '#533F85',
    border: 'inherit',
    width: '100%',
    height: "50px",
    marginTop: '6px',
    marginBottom: '6px',
  };

  useEffect(()=>{
    setActiveStep!(9)
  },[])

  return (
    <div className="posture-medical-container">
      {!scanState ?
        <div className="flex mb-5 items-center justify-center flex-col md:flex-row rounded-3xl flex-1 h-[350px] w-[750px] mt-5"
          style={{
            backgroundImage: 'url("/images/dashboard/posture-banner.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="p-6 md:p-8 w-full md:w-[60%]">
            <div style={{ height: "40px" }}><img src="/images/dashboard/posture-analysis.svg" alt="Posture Analysis" /></div>
            <p className="font-inter font-light text-[16px] md:text-[20px] text-white mt-6 leading-[24px] md:leading-[30px]">
              {t('Patient.data.dashboardScreen.postureAnalysis')}
              <div className="w-full pt-2">
                <Button
                  className="start-session-css"
                  style={buttonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    setScanState(true)
                  }}
                >
                  <span className='font-inter text-primary-700 text-[18px] font-bold leading-[38px]'>{t('Patient.data.dashboardScreen.startPostureScan')}</span>
                </Button>
              </div>
            </p>
          </div>
          <img
            className="w-full md:w-1/2 rounded-t-3xl md:rounded-tl-3xl md:rounded-bl-3xl mt-5"
            src="/images/dashboard/posture-woman-banner.png"
            alt="Posture Scan"
            style={{ width: "40%" }}
          />
        </div>
        : <>
          <div className="posture-header-container">
            <h1 className="posture-header-title">{t('Patient.data.onboard.postureAnalysis')}</h1>
            <h3 className="posture-header-subtitle">
              {t('Patient.data.onboard.analysePosture')}
            </h3>
          </div>
          <div className="posture-scan-container">
            <PostureScan isDashboard={true!} />
          </div></>}
      {!scanState &&
        <div className={`posture-button-container ${videoRecordState ? 'opacity-50' : ''}`}>
          <Button disabled={videoRecordState} className="mt-2 bg-gray-200 text-primary-800 rounded-full font-inter text-base font-bold leading-6" onClick={() => { (setActiveStep!(8)); dispatch(SActiveStep!(8)); }} size="large" type="primary" block style={{ height: '48px', width: "384px" }}>
            {t('Patient.data.onboard.back')}
          </Button>
          <Button size="large" className="rounded-full font-inter text-base font-bold leading-6" disabled={videoRecordState} type="primary" block style={{ height: '48px', background: "#6941C6", color: "#fff", width: "384px" }} onClick={() => { onComplete(); setProgressPercent(90) }} >
            {t('Patient.data.onboard.skip')}

          </Button>
        </div>}
    </div >
  );
};

export default PostureAnalysis;
