import { useState, useRef } from "react";
import { ChevronRight } from "@carespace-icons/arrows/chevronRight";
import { ChevronLeft } from "@carespace-icons/arrows/chevronLeft";
import { Button } from "antd";
import moment from "moment";
import { Link01 } from "@carespace-icons/general/link01";
import { useTranslation } from "react-i18next";
import { ISelectedProgram } from "@pages/NewPatientOnboardDashboard";
import { useNavigate } from "react-router-dom";
import { router } from "@routers/routers";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { Calendar } from "@carespace-icons/time/calendar";

export interface ISuggestedProgramItem {
  id: string
  attributes: {
    exercise_video: {
      data: {
        attributes: {
          url: string;
        };
      }[];
    };
  };
  exercises?: {
    strapiExerciseId: {
      name: string,
      exercise_image: {
        url: string;
      }[];
    };
    exerciseLibrary: {
      videoUrl: string | undefined;
    };
  }[];
  name: string;
  updatedAt: string;
  thumbnail: { url: string } | string;
  duration: number;
  durationType: string
}


export default function SuggestedPrograms(props: { suggestedProgramData: ISuggestedProgramItem[], currentPage: number, setCurrentPage: (value: (prev: number) => number) => void; handleEnroll: (item: ISuggestedProgramItem) => void }) {
  const { suggestedProgramData, setCurrentPage, handleEnroll } = props
  const [activeImage, setActiveImage] = useState<number | null>(null);
  const [activeVideoUrl, setActiveVideoUrl] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [arrows, setArrows] = useState(false)
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const navigate = useNavigate()
  const scrollLeft = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition - 400;
      scrollToPosition(targetPosition, 300);
    }
  };
  const user = useTypedSelector((state) => state.user)
  const scrollRight = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition + 400;
      scrollToPosition(targetPosition, 300);
    }
    if (
      scrollRef.current &&
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >= scrollRef.current.scrollWidth
    ) {
      handleNextPage();
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev: number) => prev + 1);
  };

  const scrollToPosition = (targetPosition: number, duration: number) => {
    const startTime = performance.now();
    const start = scrollRef.current?.scrollLeft;

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = start! + (targetPosition - start!) * progress;
      }
      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const handleImageClick = (index: number, videoUrl: string) => {
    setActiveImage(index);
    setActiveVideoUrl(videoUrl);
  }

  return (
    <div className="rounded-[16px] p-4 mb-5" style={{backgroundColor:'var(--card-bg-color)', height:'410px'}}>
      <p className="flex flex-col pb-4 sm:flex-row sm:items-center sm:justify-between text-primary-800 font-inter text-[22px] font-bold">
        <span>{t('Patient.data.newDashboard.suggestedPrograms')}</span>
        <span className="text-gray-800 text-[16px] font-normal">
          {t('Patient.data.newDashboard.suggestedProgramsTips')}
        </span>
      </p>
      <div className="image-navigation-container">
        <div className="horizontal-scroll-container relative" ref={scrollRef}>
          {suggestedProgramData?.map((item: ISuggestedProgramItem, index: number) => {
            const videoUrl = item?.attributes?.exercise_video?.data[0]?.attributes?.url;
            return (
              <div
                key={index}
                className={`custom-image-container-form ${activeImage === index ? "active-image" : ""}`}
                onMouseOver={() => handleImageClick(index, videoUrl)}
                onMouseLeave={() => setActiveImage(null)}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/${user?.id}/overview`, {
                    state: {
                      selectedProgram: item,
                    }
                  })
                }}
              >
                <div className="image-wrapper-form">
                  {item?.exercises![0] ? (
                    <img
                      src={item?.thumbnail ? item?.thumbnail?.url || item?.thumbnail : item?.exercises[0] && item?.exercises[0]?.strapiExerciseId?.exercise_image[0]?.url}
                      alt={item?.exercises[0]?.strapiExerciseId?.name}
                      className="w-full h-[200px] rounded-t-lg"
                    />
                  ) : (
                    <video
                      preload="metadata"
                      src={item?.exercises![0]?.exerciseLibrary?.videoUrl}
                      className="w-full h-[200px] rounded-t-lg"
                    />
                  )}
                  <div className="overlay-content-css">
                    <p className={`text-white font-medium text-lg ${activeImage === index ? "mb-2" : ""}`}>{item?.name}</p>
                    <Button
                      className='start-session-css font-semibold px-1 my-2 z-10 w-full'
                      style={{border:"0px"}}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEnroll(item)
                      }}
                    >
                      {t('Patient.data.newDashboard.enroll')}
                    </Button>

                    <div className="hover-details">
                      {item?.exercises && (
                        <>
                          <div className="exercise-count">
                            {`${item?.exercises.length} ${t(
                              `Patient.data.newDashboard.${item?.exercises.length === 1 ? 'exercise' : 'exercises'}`
                            )}`}
                          </div>
                          <div className="exercise-count-duration">
                            <Calendar height={20} width={20} /> {item?.duration}{" "}
                            {item?.durationType
                              ? item?.duration > 1
                                ? item.durationType.charAt(0).toUpperCase() + item.durationType.slice(1)
                                : item.durationType.charAt(0).toUpperCase() + item.durationType.slice(1).replace(/s$/, "")
                              : ""}
                          </div></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="navigation-buttons">
          <span
            className="p-2 absolute top-1/2 left-3 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
            onClick={scrollLeft}
          >
            <ChevronLeft width={30} height={30} />
          </span>
          <span
            className="p-2 absolute top-1/2 right-3 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
            onClick={scrollRight}
          >
            <ChevronRight width={30} height={30} />
          </span>
        </div>
      </div>
    </div>
  );
}
