import { CheckCircleBroken } from "@carespace-icons/general/checkCircleBroken";
import { Edit02 } from "@carespace-icons/general/edit02";
import { Plus } from "@carespace-icons/general/plus";
import { Trash01 } from "@carespace-icons/general/trash01";
import { Play } from "@carespace-icons/media/play";
import { setActiveTab } from "@stores/aiAssistant/aiAssistant";
import { router } from '@routers/routers';
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { ISurvey, ISurveyPaginated } from "@stores/interfaces";
import { deleteSurvey } from "@stores/survey/survey";
import { Image, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface IEditSurveyCard {
  surveyEdit: boolean;
  setSurveyEdit: (value: boolean) => void,
  item: ISurvey,
  setSelectedSurvey: (value: ISurvey) => void,
  setModalVisible: (value: boolean) => void,
  handleApproveSurvey: (item: ISurvey, value: boolean) => void,
  fetchHomeData: (value: number) => void
  survey: ISurveyPaginated
}
export const EditSurveyCard = (props: IEditSurveyCard) => {
  const { surveyEdit, setSurveyEdit, survey, fetchHomeData, item, setSelectedSurvey, setModalVisible, handleApproveSurvey } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [approved, setApproved] = useState(item?.status?.toLowerCase() === 'approved')
  const [active, setActive] = useState(item?.active)
  const user = useTypedSelector(state => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const dispatch = useTypedDispatch();
  useEffect(() => {
    setApproved(item?.status?.toLowerCase() === 'approved')
    setActive(item?.active)
  }, [item])
  const [hover, setHover] = useState(false)

  const handleDelete = async (id: string) => {
    await dispatch(deleteSurvey(id))
    message.success(t("Admin.data.survey.surveyDelete"))
    fetchHomeData(survey?.data?.length == 1 ? survey?.pagination?.currentPage! - 1 : survey?.pagination?.currentPage!)
  }

  return <div className="edit-survey-card" key={item.id} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <div className={`survey-card`}>
      <div className="image-container">
        {item?.image ?
          <Image src={item?.image} className="image-not-found" alt={t('Admin.data.survey.noImage')} onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = "images/white-image.png";
          }}
            preview={{
              src: item?.image || "images/white-image.png",
              mask: <MdOutlineRemoveRedEye size={18} />,
            }}
          /> :
          <Image src="/images\white-image.png" className="image-not-found" alt={t('Admin.data.survey.noImage')}
            preview={{
              src: "images/white-image.png",
              mask: <MdOutlineRemoveRedEye size={18} />,
            }} />
        }
      </div>
      <div className="card-content">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', height: "30px" }}>
          {!hover ? <div style={{ gap: "10px", display: "flex" }}>
            <p className={`${active ? "active-button-container" : "non-active-button-container"}`}>
              {active ? t('Admin.data.survey.active') : t('Admin.data.survey.nonActive')}
            </p>
            <p className="template-button-container">
              {item?.clinicallyValidated && t("Admin.data.survey.clinicallyValidatedSurvey")}
            </p>
          </div> : <>
            <p className="action-button-container">
              <span className={`approve-button`}>
                {approved ? (
                  <Tooltip placement="top" title={t('Admin.data.survey.disableSurvey')}>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        handleApproveSurvey(item, false);
                        setApproved(false);
                        setActive(false)
                      }}
                    >
                      <CheckCircleBroken
                        width={20}
                        height={20}
                        color={"stroke-green-500"}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('Admin.data.survey.enableSurvey')}>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        handleApproveSurvey(item, true);
                        setApproved(true);
                        setActive(true)
                      }}
                    >
                      <Plus
                        width={20}
                        height={20}
                        color={"stroke-gray-700"}
                      />
                    </div>
                  </Tooltip>
                )}
              </span>
              <Tooltip placement="top" title={t('Admin.data.survey.editSurvey')}>
                <span className="edit-button"
                  onClick={(e) => {
                    setSurveyEdit(true)
                    setSelectedSurvey(item);
                    setModalVisible(true);
                  }}
                >
                  <Edit02 width={18} height={18} color={"stroke-gray-700"} />
                </span>
              </Tooltip>
              <Tooltip placement="top" title={t('Admin.data.survey.deleteSurvey')}>
                <div className="delete-button" onClick={() => { handleDelete(item?.id) }}>
                  <Trash01 width={20} height={20} color={"stroke-gray-700"} />
                </div>
              </Tooltip>
              <Tooltip placement="top" title={t('Admin.data.survey.startSurvey')}>
                <div className="play-icon" onClick={() => {
                  navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_SURVEY_USER}`, {
                    state: {
                      item: item,
                      active: true
                    }
                  });
                  dispatch(setActiveTab('startSurveyUser'))
                }}>
                  <Play width={18} height={18} />
                </div>
              </Tooltip>
            </p> </>
          }</div>
        <div className="card-header">
          <p className="survey-title">{item?.title}</p> {item?.isFinished && <span style={{ color: "rgb(152 162 179)" }}>({item?.isFinished && (t('Admin.data.survey.completed'))})</span>}
        </div>
        <p className="survey-question-count ">{t('Admin.data.survey.questions')}: {item?.questionList?.length}</p>
        <p className="survey-description-container description-container">
          <span className="description-text">{item?.description}</span>
        </p>
      </div>
    </div>
  </div>
}