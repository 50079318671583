import { useEffect, useRef, useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '@stores/index';

interface ITransitionReadySetGo {
	isPaused: boolean;
	onNextTransition: () => void;
	transitionTime: number;
}

function TransitionReadySetGo(props: ITransitionReadySetGo) {
	const { isPaused, onNextTransition, transitionTime } = props;
	const screenshotSound = '/assets/screenshotSound.mp3';
	const counterSound = '/assets/counterSound.mp3';
	const activeStep = useTypedSelector(state => state.onBoard.onBoard.activeStep)
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const counterAudioRef = useRef<HTMLAudioElement | null>(null);
	const [countdown, setCountdown] = useState<number>(
		transitionTime === -1 ? 3 : transitionTime,
	);
	const { t } = useTranslation();
	const currentLocation = window.location.pathname
	useEffect(() => {
		setCountdown(transitionTime === -1 ? 3 : transitionTime);
	}, [transitionTime]);

	useEffect(() => {
		let interval: NodeJS.Timeout | undefined;
		if (!isPaused && countdown > 0) {
			audioRef?.current?.play();

			interval = setInterval(() => {
				setCountdown(prev => {
					const newCountdown = prev - 1;

					if (audioRef.current) {
						audioRef.current.currentTime = 0;
						audioRef.current.play();
					}
					return newCountdown;
				});
			}, 1000);
		}

		if (countdown === 1) {
			clearInterval(interval);

			setTimeout(() => {
				if (counterAudioRef.current && activeStep !=8 && !currentLocation.includes('rom/start-scan')) {
					counterAudioRef.current.currentTime = 0;
					counterAudioRef.current.play();
				}
			}, 1000);

			setTimeout(() => {
				onNextTransition();
			}, 2000);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [countdown, isPaused, onNextTransition]);

	return (
		<>
			<audio ref={audioRef} src={counterSound} preload="auto" />
			<audio ref={counterAudioRef} src={screenshotSound} preload="auto" />
			{countdown !== 0 && (
				<div
					style={{
						width: '100%',
						position: 'absolute',
						background: '#00000000',
						zIndex: 10,
						height: '100%',
						fontSize: 40,
						fontWeight: 'bold',
						color: 'white',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					{t('Patient.data.omnirom.getPosition')}
					<br />
					<span
						className="bg-primary-600 rounded-full flex items-center justify-center"
						style={{
							width: 100,
							height: 100,
							fontSize: 40,
							fontWeight: 'bold',
							color: 'white',
						}}>
						{countdown}
					</span>
				</div>
			)}
		</>
	);
}

export default TransitionReadySetGo;
