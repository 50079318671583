import { Check } from '@carespace-icons/general/check';
import { message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactVideorRecordRef, RehabVideoState } from '@stores/interfaces';
import { useTypedSelector } from '@stores/index';
import { countdownTime } from '@stores/constants';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import VideoModal from '@pages/ActivityStream/VideoModal';
import PopoverModal from './PopoverModal';
import './AddNotes.css';

interface AddNotesProps {
  sendMessage: (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => void;
  onClick: () => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const AddNotes: React.FC<AddNotesProps> = ({ onClick, onChange, sendMessage }) => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [videoState, setVideoState] = useState<RehabVideoState>(RehabVideoState.START);
  const [imgFile, setImgFile] = useState<File[] | undefined>([]);
  const { facingMode } = useTypedSelector((state) => state.rehab.main);
  const [videoBlob, setVideoBlob] = useState<null | Blob>(null);
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const imgInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<ReactVideorRecordRef>(null);
  const [isStartingTimer, setStartingTimer] = useState(false);
  const [messageDescription, setMessageDescription] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [messages, setMessage] = useState('');
  const [recordingState, setRecordingState] = useState(false);
  const [recordedState, setRecordedState] = useState(false);
  const report = useTypedSelector(state => state.reports.report);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [tempValue, setTempValue] = useState('');

  if (!browserSupportsSpeechRecognition) {
    return <span>{t("Admin.data.addNotes.browserErr")}</span>;
  }

  const onRecordFinished = (videoBlob: Blob) => {
    if (videoBlob) {
      setVideoBlob(videoBlob);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setTempValue(value);
    onChange(event);
  };

  const openImgInput = () => {
    imgInputRef.current?.click();
  };

  const handleClose = () => {
    videoRef?.current?.handleStopReplaying();
    if (videoRef?.current?.recordedBlobs) videoRef.current.recordedBlobs = [];
    videoRef?.current?.handleStopRecording();
    setVideoState(RehabVideoState.START);
    setStartingTimer(false);
    setVideoModalVisible(false);
    setImageModalVisible(false);
    setImgFile([]);
    setVideoBlob(null);
    setMessageDescription('');
    onChange({ target: { value: tempValue } } as React.ChangeEvent<HTMLTextAreaElement>);
  };

  const addMessage = async () => {
    if (messageDescription.trim() !== '' && (videoBlob || imgFile)) {
      sendMessage(report?.id || '', messageDescription, imgFile || [], videoBlob!);
      setImgFile([]);
      setVideoBlob(null);
      setVideoModalVisible(false);
      setImageModalVisible(false);
      setMessageDescription('');
      handleClose()
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"));
    }
  };

  const handleKeyPress = (e: { shiftKey: string; key: string; preventDefault: () => void; }) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (messages.trim() !== '') {
        addMessage();
      } else {
        message.error(t('Patient.data.activityStream.messageErr'));
      }
    }
  };

  const onDiscardRecord = () => {
    videoRef?.current?.handleStopReplaying();
    if (videoRef?.current?.recordedBlobs) videoRef.current.recordedBlobs = [];
    setVideoBlob(null);
    setVideoState(RehabVideoState.START);
  };

  const onStartRecord = () => {
    videoRef?.current?.handleStartRecording();
    setVideoState(RehabVideoState.READY);
    setTimeout(() => {
      setStartingTimer(true);
      setVideoState(RehabVideoState.RECORDING);
    }, countdownTime);
  };

  const showImageModal = () => {
    setImageModalVisible(true);
  };

  const onEndedTimer = () => {
    onStopRecord();
  };

  const onStopRecord = () => {
    videoRef?.current?.handleStopRecording!();
    setVideoState(RehabVideoState.REPLAYING);
    setStartingTimer(false);
  };

  useEffect(() => {
    if (listening && isRecording) {
      setTempValue(transcript); 
      onChange({ target: { value: transcript } } as React.ChangeEvent<HTMLTextAreaElement>);
    }
  }, [transcript, listening, onChange, isRecording]);

  const showVideoModal = () => {
    setVideoModalVisible(true);
    setMessage('');
  };

  const startListening = () => SpeechRecognition.startListening({ continuous: true });
  const stopListening: any = SpeechRecognition.stopListening;
  
  const toggleRecording = () => {
    if (!isRecording) {
      setTempValue("")
      onChange({ target: { value: "" } } as React.ChangeEvent<HTMLTextAreaElement>);
      startListening();
      message.info(t('Patient.data.activityStream.recStarted'));
    } else {
      stopListening();
      message.warning(t('Patient.data.activityStream.recorded'));
      onChange({ target: { value: transcript } } as React.ChangeEvent<HTMLTextAreaElement>);
      inputRef?.current?.focus();
    }
    setIsRecording((prevState) => !prevState);
  };

  const handleImgChange = (e: { target: { files: FileList | null } }) => {
    const files = e.target.files;
    if (files && files[0]) {
      setImgFile([files[0]]);
      message.success(t('Patient.data.activityStream.imageSelected'));
      if (imgInputRef.current) {
        imgInputRef.current.value = ''; 
      }
    }
  };
  

  const handleSave = () => {
    if (tempValue.trim() ===""){
      message.error(t("Admin.data.addNotes.requiredErr"))
    }else{
    setTempValue('');
    setRecordedState(false);
    resetTranscript();
    onClick();
    }
  };

  return (
    <div className="add-notes-container note-section">
      <p className="add-notes-label">
        {t('Admin.data.addToReports.addNotes')}
        <div className='popover-modal'>
          <PopoverModal
            isRecording={isRecording}
            showVideoModal={showVideoModal}
            showImageModal={showImageModal}
            setRecordingState={setRecordingState}
            toggleRecording={toggleRecording}
            resetTranscript={resetTranscript}
            onChange={onChange}
            tempValue={tempValue}
            setTempValue={setTempValue}
            recordedState={recordedState}
            setRecordedState={setRecordedState}
          />
          <input
            type='file'
            accept='image/*'
            className='hidden'
            ref={imgInputRef}
            onChange={handleImgChange}
          />
          <TextArea
            value={tempValue}
            placeholder={t('Admin.data.addNotes.enterDescription')}
            style={{ marginTop: "0.5rem" }}
            onChange={handleChange}
            rows={3}
          />
        </div>
        {!isRecording && <span className="notes-save-button" onClick={handleSave}>
          <Check width={20} height={20} color="stroke-white" /> Save
        </span>}
      </p>
      <VideoModal isReport={true} videoBlob={videoBlob} videoState={videoState} imgFile={imgFile} facingMode={facingMode} onRecordFinished={onRecordFinished} isVideoModalVisible={isVideoModalVisible} isImageModal={isImageModalVisible}
        openImgInput={openImgInput} handleClose={handleClose} onStartRecord={onStartRecord} onStopRecord={onStopRecord} onDiscardRecord={onDiscardRecord} onEndedTimer={onEndedTimer}
        isStartingTimer={isStartingTimer} videoRef={videoRef} messageDescription={messageDescription} setMessageDescription={setMessageDescription} sendMessage={addMessage} handleKeyPress={handleKeyPress}
      />
    </div>
  );
};

export default AddNotes;
