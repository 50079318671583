import { IRomPatientResult } from "@stores/interfaces"
import { Col, Image, message, Row } from "antd"
import moment from "moment"
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import AddNotes from "./AddNotes"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { updateReportNotes } from "@stores/report/reports"
import ReportNotesTemplate from "./ReportNotes/ReportNotesTemplate"
import ReportNotesDownloadTemplate from "./ReportNotes/ReportNotesDownloadTemplate"

interface ReportRomCapturesDataProps {
  isEditMode: boolean
  isDownload?: boolean;
}

export default function ReportRomCapturesData(props: ReportRomCapturesDataProps) {
  const { isEditMode, isDownload } = props
  const { t } = useTranslation();
  const report = useTypedSelector(state => state.reports.report)
  const [romResultsNotes, setRomResultsNotes] = useState<string>()
  const dispatch = useTypedDispatch()

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      romResultsNotes: {
        notes: romResultsNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setRomResultsNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('romResultsNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId || ''
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  return (
    <>
    <div className='pb-2'>
      {!isDownload?<>
        <p className="font-bold text-base text-gray-600 mt-5 text-center mb-5">{t('Admin.data.addToReports.romCapturesData')}</p>
        <Row gutter={[14, 8]} style={{display:"flex", alignItems:"center", columnGap:"40px", paddingLeft:"20px"}}>
          {report?.romResultsIds?.map((item: IRomPatientResult) => {
            const DateUtc = moment(item?.createdAt)
              .local()
              .format('LLL');

            return (
              <Col
                key={`col-${item.id}`}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                lg={{ span: 5 }}
                xxl={{ span: 5 }}
              >
                <div className="screenshot tipChart">
                  <Image
                    style={{ objectFit: 'contain' }}
                    width="100%"
                    height={125}
                    src={item.screenshot}
                    preview={{
                      src: item.screenshot,
                      mask: <MdOutlineRemoveRedEye size={18} />,
                      width: 'auto',
                      height: 'auto',
                    }}
                  />
                  <div className="date">{DateUtc}</div>
                  <div className="value">{item.value}°</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </>:<>
      <p className="font-bold text-base text-gray-600 mt-5 text-center mb-5">{t('Admin.data.addToReports.romCapturesData')}</p>
      {report?.romResultsIds?.map((item: IRomPatientResult) => {
      return (
        <Fragment>
          <p className='py-[15px] text-gray-600'><b className='font-bold'>{t('Admin.data.addToReports.sessionDate')}</b> {moment(item.createdAt).local().format('LLL')}</p>
          <div className='p-[15px] my-2.5 bg-gray-100 mt-1 rounded-lg border-1 border-inherit flex flex-col gap-2 text-sm text-gray-500'>
            <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.screenshot')} </b><a href={item.screenshot} target='_blank'>{item.screenshot}</a></p>
            <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.value')} </b>{item.value}°</p>
          </div>
        </Fragment>
      )
    })}
      </>}
        {
          report?.romResultsNotes?.some(
            (note) =>
              note.notes?.trim()
          ) && (<>
            {report?.romResultsNotes?.length! > 0 ? (
                <>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
                  </div>
                  {!isDownload ? <>
                  {report?.romResultsNotes?.map((note, index) => {
                    return <ReportNotesTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                  })}</>:<>
                  {report?.romResultsNotes?.map((note, index) => {
                    return <ReportNotesDownloadTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />})}
                  </>}
                </>) : null}</>)}
      {
        report?.romResultsIds?.length != 0 && !isDownload ? 
          <AddNotes sendMessage={sendMessage} onClick={() => handleSubmit()} onChange={(event) => setRomResultsNotes(event.target.value)} />
        : null
      }
    </div>
    </>
  )
}
