import { ChevronLeft } from '@carespace-icons/arrows/chevronLeft';
import './arrow.css';

type TLeftArrow = {
  onPreviousWeek: () => void
}

const LeftArrow = (props: TLeftArrow) => {

  const {onPreviousWeek} = props

  return (
    <p className='custom-weekly-arrow' onClick={onPreviousWeek}>
      <ChevronLeft color='stroke-gray-900'/>
    </p>
  );
};

export default LeftArrow;
