import { Line } from '@ant-design/charts';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import { InfoCircle } from '@carespace-icons/general/infoCircle';
import { Button, Modal, Row, Tag, Typography } from 'antd';
import moment from 'moment';
import { FaceHappy } from '@carespace-icons/users/faceHappy';
import { FaceSmile } from '@carespace-icons/users/faceSmile';
import { FaceNeutral } from '@carespace-icons/users/faceNeutral';
import { FaceFrown } from '@carespace-icons/users/faceFrown';
import { FaceSad } from '@carespace-icons/users/faceSad';
import { ProgramSessionResult } from '@stores/interfaces';

interface SessionInfoProps {
	setSessionClicked: (value: boolean) => void;
	exerciseTitle: string;
	exerciseProgramData: ProgramSessionResult[];
	programDescription: string;
	programTutorialVideo: string;
}

const customIcons = [
	{
		key: 0,
		value: 'TOO EASY',
		icon: <FaceHappy color="stroke-success-400" width={24} height={24} />,
		color: 'rgb(50 213 131)',
	},
	{
		key: 1,
		value: 'EASY',
		icon: <FaceSmile color="stroke-green-500" width={24} height={24} />,
		color: 'rgb(22 179 100)',
	},
	{
		key: 2,
		value: 'NORMAL',
		icon: <FaceNeutral color="stroke-yellow-200" width={24} height={24} />,
		color: 'rgb(254 238 149)',
	},
	{
		key: 3,
		value: 'HARD',
		icon: <FaceFrown color="stroke-yellow-500" width={24} height={24} />,
		color: 'rgb(234 170 8)',
	},
	{
		key: 4,
		value: 'TOO HARD',
		icon: <FaceSad color="stroke-yellow-700" width={24} height={24} />,
		color: 'rgb(161 92 7)',
	},
];

const config = {
	data: [],
	xField: 'date',
	yField: 'REHAB',

	tooltip: {
		customContent: (title: string, data) => {
			const sessionData = data[0];
			return (
				<div className="w-[310px] h-[228px] flex flex-col justify-center items-center gap-2">
					<div className="w-[299px] flex justify-between items-center">
						<div className="text-sm">{title}</div>
						<div className="flex items-center gap-2">
							<div
								style={{
									color: customIcons[sessionData?.data.REHAB - 1]?.color,
								}}
								className="text-yellow-700 font-semibold text-sm"
							>
								{customIcons[sessionData?.data.REHAB - 1]?.value}
							</div>
							{customIcons[sessionData?.data?.REHAB - 1]?.icon}
						</div>
					</div>
					<div>
						<video width="299px" src={sessionData?.data?.printScreen} />
					</div>
				</div>
			);
		},
	},
	label: {
		formatter: (datum: { REHAB: any; }) => {
			return datum.REHAB;
		},
	},
	xAxis: { tickCount: 10 },
	point: {
		size: 4,
		shape: 'circle',
		style: {
			fill: '#5B8FF9',
			stroke: 'white',
			shadowColor: 'rgba(0, 0, 0, 0)',
			lineWidth: 1,
		},
	},
	meta: {
		REHAB: {
			values: ['0', '1', '2', '3', '4', '5'],
		},
	},
	slider: {
		start: 0.0,
		height: 25,
		end: 1,
	},
};

const CustomModalInfo = (props: {
	name: string;
	description: string;
	video: string;
}) => {
	const { name, description, video } = props;
	const modalStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	const modalContent = (
		<div
			className="select-none"
			style={{ textAlign: 'center', marginTop: '10%' }}
		>
			<video
				controls
				className="video"
				preload="metadata"
				src={video}
				width="100%"
				height="100%"
			/>
			<div className="select-none" style={{ marginTop: '24px' }}>
				<Typography.Title level={5}>{name}</Typography.Title>
				<Typography.Text>{description}</Typography.Text>
			</div>
		</div>
	);
	Modal.info({
		title: null,
		content: modalContent,
		maskClosable: true,
		icon: null,
		okButtonProps: { style: { display: 'none' } },
		style: modalStyle,
		closable: true,
	});
};

export const SessionExerciseChart = ({
	setSessionClicked,
	exerciseTitle,
	exerciseProgramData,
	programDescription,
	programTutorialVideo,
}: SessionInfoProps) => {
	config.data = exerciseProgramData
		? exerciseProgramData
				?.map((data: ProgramSessionResult) => {
					const DateUtc = moment(data.createdAt).local().format('LLL');
					return {
						date: DateUtc,
						REHAB: data?.exerciseDifficultyLevel
							? data?.exerciseDifficultyLevel
							: 0,
						printScreen: data?.video,
					};
				})
				.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
		: [];

	return (
		<div className="bg-gray-neutral-50 p-5">
			<div className="mb-3 flex items-center gap-2">
				<Button
					className="!bg-primary-600 !w-9 !h-9 !p-1"
					onClick={() => setSessionClicked(false)}
				>
					<ArrowLeft />
				</Button>
				<p className="font-semibold text-base">{exerciseTitle}</p>
				<Tag color="purple">{exerciseProgramData?.length} Sessions</Tag>
			</div>
			<Line
				height={300}
				padding={[40, 40, 80, 40]}
				className="bg-white"
				{...config}
			/>
			<Row className="grid-cols-1 gap-3">
				{exerciseProgramData?.map((data: ProgramSessionResult) => {
					return (
						<div className="relative rounded-md pb-3">
							<video
								controls
								className="video rounded-t-2xl"
								preload="metadata"
								src={data?.video}
								width="250px"
								height="160px"
							/>
							<div>
								<span
									className=" bg-white rounded-md p-1 cursor-pointer absolute z-10 top-2 right-2"
									onClick={() =>
										CustomModalInfo({
											video: programTutorialVideo,
											name: exerciseTitle,
											description: programDescription,
										})
									}
								>
									<InfoCircle width={17} height={17} color="stroke-gray-700" />
								</span>
							</div>
							<div className="bg-gray-700 text-white p-2 -mt-1 relative z-10 rounded-b-2xl flex justify-between">
								<span>{moment(data.createdAt).local().format('LLL')}</span>
								<span></span>
							</div>
						</div>
					);
				})}
			</Row>
		</div>
	);
};
