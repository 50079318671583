import { Download02 } from '@carespace-icons/general/download02';
import { XClose } from '@carespace-icons/general/xClose';
import { InvitationData, UploadedFile } from '@stores/interfaces';
import { Button, Checkbox, Progress, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import './BulkInvite.css';
import TextEditor from './TextEditor';

export interface IBulkInvites {
	beforeUpload: (file: File) => boolean | string;
	rowData: InvitationData;
	renderMessageField: () => void;
	setIsInvitePatientModalOpen: (value: boolean) => void;
	handleUpload: ({ file }: { file: File }) => void;
	handleRemove: () => void;
	uploading: boolean;
	progress: number;
	file: UploadedFile;
	setMustSendEmail: (value: boolean) => void,
	mustSendEmail: boolean
	content: string
	setContent: (value:string) => void;
	activeKey: string | number
	instanceLink: string
	username: string
	password: string
	inviteCode: string
}
const BulkInvite = (props: IBulkInvites) => {
	const {
		beforeUpload,
		rowData,
		setIsUploaded,
		setIsInvitePatientModalOpen,
		handleUpload,
		handleRemove,
		uploading,
		progress,
		file,
		setMustSendEmail,
		mustSendEmail,
		inviteCode,
		password,
		username,
		instanceLink,
		activeKey,
		setContent,
		content
	} = props;
	const { t } = useTranslation();

	const formatFileSize = (size: number) => {
		return size < 1024 * 1024
			? `${(size / 1024).toFixed(1)} KB`
			: `${(size / 1024 / 1024).toFixed(2)} MB`;
	};

	const onSlectIsMail =(value:boolean)=> {
		setMustSendEmail(value)
	}

	return (
		<div className="bulk-invite-wrapper mt-6">
			<div className="bulk-invite-header">
				<p className="bulk-invite-text">
					{t('Admin.data.menu.userRoles.invitePatientModal.allowFormat')}:{' '}
					<strong>
						{t('Admin.data.menu.userRoles.invitePatientModal.csv')}
					</strong>{' '}
					| {t('Admin.data.menu.userRoles.invitePatientModal.maximumFile')}:{' '}
					<strong>3 MB</strong>
				</p>
				<p
					className="bulk-invite-download"
					onClick={() => (window.location.href = '/assets/sampledata.xlsx')}>
					<Download02 width={16} height={16} color="stroke-gray-700" />
					<span>
						{t('Admin.data.menu.userRoles.invitePatientModal.downloadSample')}
					</span>
				</p>
			</div>
			{!file ? (
				<Upload.Dragger
					showUploadList={false}
					beforeUpload={beforeUpload}
					customRequest={handleUpload}
					accept=".csv,.xlsx">
					{uploading ? (
						<div className="bulk-invite-uploading">
							<p>
								<strong>
									{t('Admin.data.menu.userRoles.invitePatientModal.drag')}
									<br />{' '}
									{t('Admin.data.menu.userRoles.invitePatientModal.uploading')}
									...
								</strong>
							</p>
							<div style={{ width: '50%' }}>
								<Progress
									percent={progress}
									trailColor="#7F56D9"
									strokeColor="#7F56D9"
									status="active"
								/>
							</div>
						</div>
					) : (
						<div className="bulk-invite-drag-area">
							<img src="/assets/uploadIcon.svg" width={70} />
							<p>
								{t('Admin.data.menu.userRoles.invitePatientModal.drag')}{' '}
								{t('Admin.data.menu.userRoles.invitePatientModal.clickUpload')}
							</p>
						</div>
					)}
				</Upload.Dragger>
			) : (
				<div className="bulk-invite-file-uploaded">
					<p style={{ display: 'flex', gap: '5px' }}>
						<img src="/assets/downloadedIcon.svg" />{' '}
						{t('Admin.data.menu.userRoles.invitePatientModal.fileUploaded')}{' '}
					</p>
					<div className="bulk-invite-file-info">
						<p>
							{file.name} <span>({formatFileSize(file.size)})</span>
						</p>
						<div onClick={handleRemove} style={{ cursor: 'pointer' }}>
							<XClose height={20} width={20} color="stroke-gray-400" />
						</div>
					</div>
				</div>
			)}
			<div style={{width: '100%', display: 'flex', justifyContent: 'end', marginTop: 10}}>
				<Checkbox 
					checked={mustSendEmail}
					onChange={(e:any) => onSlectIsMail(e.target.checked)}
				>{t('Admin.data.menu.userRoles.invitePatientModal.sentMail')}</Checkbox>
			</div>
			{mustSendEmail && !rowData && <TextEditor
				content={content}
				setContent={setContent}
				activeKey={activeKey}
				instanceLink={instanceLink}
				username={username}
				password={password}
				inviteCode={inviteCode}
			/>}
			<div className="bulk-invite-actions">
				<Button
					size="large"
					style={{ width: '50%' }}
					onClick={() => {
						setIsInvitePatientModalOpen(false);
					}}
					type="default">
					{t('Admin.data.menu.userRoles.invitePatientModal.cancel')}
				</Button>{' '}
				<Button
					size="large"
					style={{ width: '50%' }}
					htmlType="submit"
					onClick={() => setIsUploaded(true)}
					type="primary">
					{t('Admin.data.menu.userRoles.invitePatientModal.save')}
				</Button>
			</div>
		</div>
	);
};

export default BulkInvite;