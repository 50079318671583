import { ReportNotes } from '@stores/interfaces'
import moment from 'moment'

interface IReportNotesTemplate{
  isEditMode: boolean,
  index:number,
  note:ReportNotes
}

const ReportNotesDownloadTemplate = (props:IReportNotesTemplate) => {
  const { isEditMode, index, note } = props
  return (
    <>
    <p className="font-semibold text-gray-600 mt-3">Date : <span className="font-regular">{moment(note?.createdAt).local().format('LLL')}</span></p>
    <ul contentEditable={isEditMode} className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
      <div key={index} className="container bg-gray-100 mt-1 rounded-lg border-1 border-inherit">
        <li key={index} className="grid text-sm mb-1 mt-1 leading-7 pl-4 pt-2 pb-2 pr-2 ">
          <p className='font-normal'><a href={note?.image} target='_blank'>{note?.image}</a></p>
          <p className='font-normal'><a href={note?.video} target='_blank'>{note?.video}</a></p>
          <p className='font-normal'>{note?.notes}</p>
        </li></div></ul>
    </>
  )
}

export default ReportNotesDownloadTemplate