import axios from 'axios'

const strapi = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_HOST,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`,
    'Content-Type': 'application/json',
  }
});

export default strapi





