import { Button, Carousel, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import RateExercise from './RateExercise';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { saveProgramExercise, setCompleted } from '@stores/aiAssistant/program';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { IRebhabProgramExercise } from '@stores/interfaces';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { UploadBar } from '../UploadBar';
import VideoPlayer from './VideoPlayer';
import { useTranslation } from 'react-i18next';
import { CarouselRef } from 'antd/es/carousel';

interface CompletionScreenProps {
	savedVoice: string;
	exercises: IRebhabProgramExercise[];
	setExercises: (value: IRebhabProgramExercise[]) => void;
	onFullscreenChange: () => void;
	patchAndSave: () => void;
}

export const PreCompletionScreen = (props: CompletionScreenProps) => {
	const {
		savedVoice,
		exercises,
		setExercises,
		onFullscreenChange,
		patchAndSave,
	} = props;
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
	const dispatch = useTypedDispatch();
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const { uploadProgress } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const { t } = useTranslation();

	useEffect(() => {
		onFullscreenChange();
	}, []);

	useEffect(() => {
		if (
			savedVoice.toLowerCase().includes('save') ||
			savedVoice.toLowerCase().includes('save.')
		) {
			dispatch(setCompleted(true));
		}
	}, [savedVoice]);

	useEffect(() => {
		setCurrentSlide(currentExerciseIndex);
	}, [currentExerciseIndex]);

	const handleClick = async () => {
		setLoading(true);
		const submitExercisePromises = exercises.map(async (exercise, index) => {
			const form = new FormData();
			form.append('video', exercise.video);
			form.append('programSessionId', exercise.programSessionId);
			form.append('programExerciseId', exercise.programExerciseId);
			form.append('exerciseDifficultyLevel', exercise.exerciseDifficultyLevel);

			await dispatch(
				saveProgramExercise({
					user: selectedUser,
					exercise: exercise,
					index: index,
					form: form,
				}),
			);
		});

		await Promise.all(submitExercisePromises);
		setLoading(false);
		patchAndSave();
	};

	const updateExerciseDifficulty = (index: number, difficulty: number) => {
		const updatedExercises = exercises.map((exercise, i) =>
			i === index
				? { ...exercise, exerciseDifficultyLevel: difficulty }
				: exercise,
		);
		setExercises(updatedExercises);
	};

	const showModal = (index: number) => {
		setCurrentExerciseIndex(index);
		setIsModalVisible(true);
		setTimeout(() => {
			carouselRef.current?.goTo(index, true);
		}, 0);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const carouselRef = useRef<CarouselRef>(null);
	const [currentSlide, setCurrentSlide] = useState(0);

	const handlePrev = () => {
		carouselRef?.current?.prev();
	};

	const handleNext = () => {
		carouselRef?.current?.next();
	};

	return (
		<div className="h-full flex flex-col items-center gap-3 select-none">
			<p className="text-white p-5 pt-10 text-lg font-semibold">
				{t('Admin.data.rehab.rehabPreAssessment.howDifficult')}
			</p>
			<div style={{ height: '450px', overflowY: 'auto', width: '90%' }}>
				{exercises?.map((exercise, index) => (
					<div
						key={index}
						style={{
							// padding: '10px',
							marginBottom: '10px',
							backgroundColor: 'rgba(83, 56, 158, 0.34)',
							height: '90px',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<div className="flex items-center">
							{loading ? (
								<div className="w-[150px] h-[90px] mr-3 bg-black/75 flex flex-col items-center justify-center gap-2">
									<p className="text-white">
										Saving {index + 1} of {exercises.length}
									</p>
									<UploadBar
										uploadProgress={
											uploadProgress.length > 0
												? uploadProgress[uploadProgress.length - 1].progress[
														index
													]
												: 0
										}
									/>
								</div>
							) : (
								<div
									className="image-wrapper gap-2 flex-shrink-0 mr-3 flex items-center"
									onClick={() => showModal(index)}>
									<VideoPlayer
										video={exercise?.video}
										className="h-[90px]"
										controls={false}
									/>
									<div className="play-button">
										<PlayCircle width={50} height={50} />
									</div>
								</div>
							)}
							<p className="text-white">{exercise?.name?.toUpperCase()}</p>
						</div>
						<div className="bg-primary-800 mr-5 py-2 px-7 rounded-full flex justify-center items-center">
							<RateExercise
								selectedRating={exercise?.exerciseDifficultyLevel}
								exercise={exercise}
								onRatingChange={rating =>
									updateExerciseDifficulty(index, rating)
								}
							/>
						</div>
					</div>
				))}
			</div>
			{!loading && (
				<Button
					className="!bg-success-500 !text-white !border-2 !border-white !py-4 !px-8 !rounded-full !h-10 flex justify-center items-center mt-4"
					onClick={handleClick}>
					{t('Admin.data.rehab.rehabPreAssessment.saveText')}
				</Button>
			)}
			<Modal
				visible={isModalVisible}
				className="background-color"
				onCancel={handleCancel}
				footer={null}
				width={800}
				closable={false}
				bodyStyle={{ background: 'blueviolet', borderRadius: 8 }}>
				<div
					style={{
						backgroundColor: 'rgba(83, 56, 158)',
						padding: '0px',
						borderRadius: '8px',
					}}>
					<div style={{ position: 'relative' }}>
						<Carousel
							ref={carouselRef}
							afterChange={current => setCurrentSlide(current)}
							dots={false}>
							{exercises.map((exercise, index) => (
								<div
									key={index}
									style={{
										padding: '20px',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}>
									{exercise?.video && (
										<VideoPlayer
											video={exercise?.video}
											controls={true}
											className={''}
										/>
									)}
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											width: '100%',
											alignItems: 'center',
											padding: '20px',
										}}>
										<p className="text-white font-semibold mt-2">
											{exercise?.name?.toUpperCase()}
										</p>
										<div className="bg-primary-800 py-2 px-4 rounded-full">
											<RateExercise
												selectedRating={exercise?.exerciseDifficultyLevel}
												exercise={exercise}
												onRatingChange={rating =>
													updateExerciseDifficulty(index, rating)
												}
											/>
										</div>
									</div>
								</div>
							))}
						</Carousel>

						{exercises.length > 1 && currentSlide !== 0 && (
							<LeftOutlined
								onClick={handlePrev}
								style={{
									position: 'absolute',
									top: '50%',
									left: '-200px',
									transform: 'translateY(-50%)',
									fontSize: '40px',
									color: 'white',
									cursor: 'pointer',
									zIndex: 1,
								}}
							/>
						)}

						{exercises.length > 1 && currentSlide !== exercises.length - 1 && (
							<RightOutlined
								onClick={handleNext}
								style={{
									position: 'absolute',
									top: '50%',
									right: '-200px',
									transform: 'translateY(-50%)',
									fontSize: '40px',
									color: 'white',
									cursor: 'pointer',
									zIndex: 1,
								}}
							/>
						)}
					</div>
					<Button
						type="primary"
						onClick={handleCancel}
						style={{
							bottom: '0px',
							left: '50%',
							transform: 'translateX(-50%)',
							border: '2px solid',
							borderColor: 'white',
							color: 'white',
							borderRadius: '20px',
							padding: '10px 20px',
							margin: '20px',
							zIndex: 1000,
						}}>
						{t('Admin.data.rehab.rehabPreAssessment.closeText')}
					</Button>
				</div>
			</Modal>
		</div>
	);
};
