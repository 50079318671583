import {
	Checkbox,
	Collapse,
	Dropdown,
	Empty,
	Menu,
	message,
	Pagination,
	Space,
	Spin,
} from 'antd';
import Badge from '@atoms/Badge';
import { useTranslation } from 'react-i18next';
import { AddToReports } from '@atoms/AddToReports';
import moment from 'moment';
import {
	IOnchangeStatus,
	IPosture,
	IstatusIcon,
	Status,
} from '@stores/interfaces';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { updatePostureSessionStatus as updatePostureSessionStatusOutOfParams } from '@stores/outOfParams';
import { updatePostureSessionStatus as updatePostureSessionStatusPendingReview } from '@stores/pendingReview';
import { updatePostureSessionStatus as updatePostureSessionStatusReviewed } from '@stores/reviewed';
import { updatePostureSessionStatus as updatePostureSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updatePostureSessionStatus as updatePostureSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import { StatusNormalized } from '@stores/constants';
import CoachPostureSummary from './CoachPostureSummary';
import './style.css';
import { useEffect, useState } from 'react';
import { getPostureByPage } from '@stores/postures/postures';
import { addReportId, deleteReportId } from '@stores/report/reports';
import { useLocation } from 'react-router-dom';

const AiPostureSummary = () => {
	const reportIds = useTypedSelector(state => state.reports.reportIds);
	const { t } = useTranslation();
	const { Panel } = Collapse;
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const postureData = useTypedSelector(state => state.postures.postures.data);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const totalPage = useTypedSelector(
		state => state?.postures?.postures?.pagination,
	);
	const perPage = useTypedSelector(
		state => state.aiAssistant.aiAssistant.perpage,
	);
	const [loading, setLoading] = useState(false);
	const isReportModal = useTypedSelector(
		state => state.aiAssistant.aiAssistant.isReportModal,
	);
	const { state } = useLocation();
	const onPageChange = (page: number) => {
		setCurrentPage(page);
		handlePostureGetApi(page);
	};

	useEffect(() => {
		setLoading(true);
		handlePostureGetApi(1);
	}, [user, selectedUser]);

	const handlePostureGetApi = async (page: number) => {
		await dispatch(
			getPostureByPage({
				userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
				page,
			}),
		);
		setLoading(false);
	};

	const headerPanel = (item: IPosture) => {
		return (
			<div>
				<p>{moment(item.createdAt).local().format('LLL')}</p>
			</div>
		);
	};

	const statusIcons: IstatusIcon = {
		outOfParams: <Badge color="black" />,
		pendingReview: <Badge color="yellow" />,
		reviewed: <Badge color="green" />,
		escalationRequired: <Badge color="rose" />,
		followUpRequired: <Badge color="orange" />,
	};

	type IUpdatePostureSessionStatus = Omit<Record<Status, any>, 'newPatients'>;

	const updatePostureSession: IUpdatePostureSessionStatus = {
		outOfParams: updatePostureSessionStatusOutOfParams,
		pendingReview: updatePostureSessionStatusPendingReview,
		reviewed: updatePostureSessionStatusReviewed,
		escalationRequired: updatePostureSessionStatusEscalationRequired,
		followUpRequired: updatePostureSessionStatusFollowUpRequired,
	};

	const onChangeStatus = async (params: IOnchangeStatus, item: any) => {
		const { sessionId, status } = params;
		message.loading({
			content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
			key: sessionId,
		});
		await dispatch(
			updatePostureSession[item.status as keyof IUpdatePostureSessionStatus]({
				sessionId,
				body: { status },
			}),
		);
		await dispatch(
			getPostureByPage({
				userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
				page: currentPage,
			}),
		);
		message.success({
			content: t(
				'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
			),
			key: sessionId,
			duration: 3,
		});
	};

	const healthStatus = (item: any) => {
		const menu = (
			<Menu>
				{Object.entries(StatusNormalized)
					.filter(([key]) => key !== item.status)
					.map(([status, label]) => {
						return (
							<Menu.Item
								key={status}
								onClick={() =>
									onChangeStatus(
										{ sessionId: item.id, status: status as Status },
										item,
									)
								}>
								<Space>
									<span className="dropdown-badge">
										{statusIcons[status as keyof IstatusIcon]}
									</span>
									<span>{label}</span>
								</Space>
							</Menu.Item>
						);
					})}
			</Menu>
		);
		return (
			<div className="posture-dropdown-container">
				<div className="posture-dropdown-section">
					{user.isPhysioterapist ? (
						<Dropdown overlay={menu}>
							<a onClick={e => e.stopPropagation()}>
								{statusIcons[item.status as keyof IstatusIcon]}
							</a>
						</Dropdown>
					) : (
						''
					)}
				</div>
				<div
					onClick={event => {
						event.stopPropagation();
					}}>
					<Checkbox
						checked={reportIds?.postureSessionsIds?.includes(item?.id)}
						disabled={
							!item.postureAnalytics || item.postureAnalytics.length === 0
						}
						onChange={e => {
							if (e.target.checked) {
								dispatch(
									addReportId({
										type: 'postureSessionsIds',
										id: item.id,
									}),
								);
							} else {
								dispatch(
									deleteReportId({
										type: 'postureSessionsIds',
										id: item.id,
									}),
								);
							}
						}}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="posture-container">
			{!isReportModal && (
				<div className="posture-add-to-reports">
					<AddToReports />
				</div>
			)}
			{loading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{postureData?.length! > 0 ? (
						<>
							<Collapse
								className={`posture-collapse`}
								bordered={false}
								defaultActiveKey={state?.id}>
								{postureData?.map((item: IPosture) => (
									<Panel
										className="header-panel"
										style={{
											backgroundColor: 'white',
											border: '1px solid #e5e7eb',
											borderRadius: '0.5rem',
											marginTop: '0.5rem',
										}}
										key={item.id}
										header={headerPanel(item)}
										extra={healthStatus(item)}>
										<CoachPostureSummary item={item} />
									</Panel>
								))}
							</Collapse>
							{totalPage?.totalCount! > 1 && !perPage && (
								<Pagination
									current={currentPage}
									onChange={onPageChange}
									total={totalPage?.totalCount}
									showSizeChanger={false}
								/>
							)}
						</>
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={<span>{t('Admin.data.addToReports.noData')}</span>}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default AiPostureSummary;
