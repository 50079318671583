import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

type BadgeColorKeys = Exclude<Colors, "white">;
type BadgeColors = Record<BadgeColorKeys, string>;

type SizeKeys = "sm" | "md" | "lg" | "xl" | "full"
type Sizes = Record<SizeKeys, string>

type TButtonProps = {
    children?: string | ReactNode
    onClick?: () => void
    color?: BadgeColorKeys
    disabled?: boolean
    size?: SizeKeys
}

const colors: BadgeColors = {
    gray: 'bg-gray-600 hover:bg-gray-700 active:shadow-gray-100 text-white',
    black: 'bg-gray-900 hover:bg-gray-600 active:shadow-gray-100 text-white',
    "white-bg": 'bg-white hover:bg-gray-200 active:shadow-gray-100 text-gray-700 border-2',
    primary: 'bg-primary-600 hover:bg-primary-700 active:shadow-primary-100 text-white',
    error: 'bg-error-600 hover:bg-error-700 active:shadow-error-100 text-white',
    warning: 'bg-warning-600 hover:bg-warning-700 active:shadow-warning-100 text-white',
    success: 'bg-success-600 hover:bg-success-700 active:shadow-success-100 text-white',
    'gray-blue': 'bg-gray-blue-600 hover:bg-gray-blue-700 active:shadow-gray-blue-100 text-white',
    'gray-modern': 'bg-gray-modern-600 hover:bg-gray-modern-700 active:shadow-gray-modern-100 text-white',
    'gray-neutral': 'bg-gray-neutral-600 hover:bg-gray-neutral-700 active:shadow-gray-neutral-100 text-white',
    'gray-iron': 'bg-gray-iron-600 hover:bg-gray-iron-700 active:shadow-gray-iron-100 text-white',
    'gray-true': 'bg-gray-true-600 hover:bg-gray-true-700 active:shadow-gray-true-100 text-white',
    'gray-warm': 'bg-gray-warm-600 hover:bg-gray-warm-700 active:shadow-gray-warm-100 text-white',
    moss: 'bg-green-light-400 hover:bg-green-light-500 active:shadow-moss-100 text-white',
    'green-light': 'bg-green-light-600 hover:bg-green-light-700 active:shadow-green-light-100 text-white',
    green: 'bg-green-600 hover:bg-green-700 active:shadow-green-100 text-white',
    teal: 'bg-teal-600 hover:bg-teal-700 active:shadow-teal-100 text-white',
    cyan: 'bg-cyan-600 hover:bg-cyan-700 active:shadow-cyan-100 text-white',
    'blue-light': 'bg-blue-light-600 hover:bg-blue-light-700 active:shadow-blue-light-100 text-white',
    blue: 'bg-[#8098F9] hover:bg-blue-300 active:shadow-blue-100 text-white',
    'blue-dark': 'bg-blue-dark-600 hover:bg-blue-dark-700 active:shadow-blue-dark-100 text-white',
    indigo: 'bg-indigo-600 hover:bg-indigo-700 active:shadow-indigo-100 text-white',
    violet: 'bg-violet-600 hover:bg-violet-700 active:shadow-violet-100 text-white',
    purple: 'bg-purple-600 hover:bg-purple-700 active:shadow-purple-100 text-white',
    fuchsia: 'bg-fuchsia-600 hover:bg-fuchsia-700 active:shadow-fuchsia-100 text-white',
    pink: 'bg-pink-600 hover:bg-pink-700 active:shadow-pink-100 text-white',
    rose: 'bg-orange-dark-600 hover:bg-orange-dark-400 active:shadow-red-100 text-white',
    'orange-dark': 'bg-orange-dark-600 hover:bg-orange-dark-700 active:shadow-orange-dark-100 text-white',
    orange: 'bg-orange-dark-300 hover:bg-orange-dark-200 active:shadow-orange-100 text-white',
    yellow: 'bg-yellow-300 hover:bg-yellow-400 active:shadow-yellow-100 text-white',
};

const colorsDisabled: BadgeColors = {
    gray: 'bg-gray-200',
    "white-bg": 'bg-white text-gray-700 border-2',
    black: 'bg-gray-300',
    primary: 'bg-primary-200',
    error: 'bg-error-200',
    warning: 'bg-warning-200',
    success: 'bg-success-200',
    'gray-blue': 'bg-gray-blue-200',
    'gray-modern': 'bg-gray-modern-200',
    'gray-neutral': 'bg-gray-neutral-200',
    'gray-iron': 'bg-gray-iron-200',
    'gray-true': 'bg-gray-true-200',
    'gray-warm': 'bg-gray-warm-200',
    moss: 'bg-green-light-400',
    'green-light': 'bg-green-light-200',
    green: 'bg-green-200',
    teal: 'bg-teal-200',
    cyan: 'bg-cyan-200',
    'blue-light': 'bg-blue-light-200',
    blue: 'bg-blue-200',
    'blue-dark': 'bg-blue-dark-200',
    indigo: 'bg-indigo-200',
    violet: 'bg-violet-200',
    purple: 'bg-purple-200',
    fuchsia: 'bg-fuchsia-200',
    pink: 'bg-pink-200',
    rose: 'bg-orange-dark-600',
    'orange-dark': 'bg-orange-dark-200',
    orange: 'bg-orange-dark-300',
    yellow: 'bg-yellow-200',
};

const sizes: Sizes = {
    sm: "w-3 h-3 text-sm",
    md: "w-4 h-4 text-sm",
    lg: "w-5 h-5 text-md",
    xl: "w-6 h-6 text-md",
    full: 'w-full p-2'
}

function Badge(props: TButtonProps) {
    const { children, color = 'primary', onClick, disabled = false, size = "md" } = props

    const sizesCss = sizes[size as keyof Sizes]
    const colorsCss = disabled ? colorsDisabled[color as keyof BadgeColors] : colors[color as keyof BadgeColors]

    let css = 'inline-block w-4 h-4 text-white rounded-full active:shadow-button'
    css = twJoin(css, colorsCss, sizesCss)


    return (
        <button className={css} onClick={onClick}>
            {children}
        </button>
    )
}

export default Badge