import { IProgram, ProgramSummaryProps } from '@stores/interfaces'
import { Collapse, Empty, Pagination, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getExerciseByProgram, getProgramByIdList, getProgramSummaryList } from '@stores/aiAssistant/program'
import { useTypedDispatch, useTypedSelector } from '@stores/index'
import React from 'react'
import { SessionExerciseChart } from './SessionExerciseChart'
import AiProgramSummaryContent from './AiProgramSummaryContent'

const { Panel } = Collapse

export default function AiProgramSummary(props: ProgramSummaryProps) {
  const { antIcon, CustomModalInfo } = props
  const { t } = useTranslation()
  const perPage = useTypedSelector(state => state.aiAssistant.aiAssistant.perpage)
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const [programSummaryData, setProgramSummaryData] = useState([])
  const [programSessionData, setProgramSessionData] = useState([])
  const [exerciseProgramData, setExerciseProgramData] = useState([])
  const [programTutorialVideo, setProgramuTutorialVideo] = useState('');
  const [programDescription, setProgramDescription] = useState('')
  const [exerciseTitle, setExerciseTitle] = useState<string | undefined>()
  const [rehabExerciseToPatientId, setRehabExerciseToPatientId] = useState<string | undefined>()
  const [isSessionClicked, setSessionClicked] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState(true)
  const [programIdData, setProgramIdData] = useState('')
  const [currentCount, setCurrentCount] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(10)
  const [activePanelKey, setActivePanelKey] = useState<string | string[] | undefined>(undefined);
	const programStateId = useTypedSelector(state => state.aiAssistant.aiAssistant.programStateId)
	const stateId = useTypedSelector(state => state.aiAssistant.aiAssistant.stateId)

	useEffect(()=>{
    handleClick(programStateId)
    handlePanelChange(programStateId)
  },[stateId,programStateId])

  const handlePanelChange = (key: string | string[]) => {
    setActivePanelKey(key);
  };

  useEffect(() => {
    fetchData(1);
  }, [user, selectedUser, perPage, dispatch])

  const fetchData = async (page: number) => {
	setIsLoading(true);
	try {
	  const payload = {
		userId: user.isPhysioterapist ? selectedUser.id : user.id,
		limit: perPage === 0 ? 10 : perPage,
		page: page,
		sessions: true
	  };
	  
	  const response = await dispatch(getProgramSummaryList(payload));
	  if (response?.payload) {
		setProgramSummaryData(response.payload.data || []);
		setCurrentPage(response.payload.pagination?.currentPage || 0);
		setTotalPage(response.payload.pagination?.totalCount || 0);
	  }
	} catch (error) {
	  console.error("Error fetching data:", error);
	} finally {
	  setIsLoading(false);
	}
  };
  

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const fetchProgramByIdList = async (programId: string, page: number) => {
    const payload = {
      programId: programId,
      limit: 10,
      page: page,
    };
    const getProgramByIdData!: { payload } = await dispatch(getProgramByIdList(payload));
    setProgramSessionData(getProgramByIdData?.payload?.data || []);
    setCurrentCount(getProgramByIdData?.payload?.pagination?.currentPage || 0);
    setTotalCount(getProgramByIdData?.payload?.pagination?.totalCount || 0);
    setIsLoading(false)
  };

  const handleClick = (programId: string) => {
    if (activePanelKey !== programId) {
      setProgramIdData(programId);
      setIsLoading(true);
      fetchSessionData(programId, 1)
    } else {
      setActivePanelKey('');
    }
  }

  const fetchSessionData = async (programId: string, page: number) => {
    const payload = {
      programId: programId,
      limit: perPage === 0 ? 10 : perPage,
      page: page,
    };
		if(programId) {
    const getProgramByIdData = await dispatch(getProgramByIdList(payload));
    setProgramSessionData(getProgramByIdData?.payload?.data);
    setCurrentCount(getProgramByIdData?.payload?.pagination?.currentPage || 0);
    setTotalCount(getProgramByIdData?.payload?.pagination?.totalCount || 0);
		}
    setIsLoading(false)
  };

  useEffect(() => {
    if (programIdData && rehabExerciseToPatientId) {
      fetchProgramSessionData(programIdData, 1, rehabExerciseToPatientId)
    }
  }, [programIdData, rehabExerciseToPatientId, user, selectedUser, perPage])

  const fetchProgramSessionData = async (programId: string, page: number, exerciseId: string) => {
    const payload = {
      programId: programId,
      exerciseId: exerciseId,
      limit: perPage === 0 ? 10 : perPage,
      page: page,
    };
    const getExerciseByProgramData = await dispatch(getExerciseByProgram(payload));
    setExerciseProgramData(getExerciseByProgramData?.payload?.data);
    setIsLoading(false)
  };

  const pageHandle = (page: number) => {
    setCurrentCount(page);
    fetchSessionData(programIdData, page)
  }

	return (
		<>
			{programSummaryData?.length === 0 && isLoading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{programSummaryData?.length === 0 ? (
						<Empty
							description={t(
								'Admin.data.managePatient.rehab.captures.noCapturesFound',
							)}
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					) : (
						<>
							{!isSessionClicked &&
							<div className='collapse-wrapper'> 
								{programSummaryData?.map((item: IProgram) => (
									<Collapse
										bordered={false}
										className={`collapse-panel-container`}
										activeKey={
											activePanelKey === item.id ? [item.id] : undefined
										}
										onChange={() => {
											handlePanelChange(item.id);
											handleClick(item.id);
										}}
										defaultActiveKey={programStateId}
									>
										<React.Fragment key={item.id}>
											<Panel
												className="header-panel bg-white !border !border-gray-200 !rounded-lg"
												key={item.id}
												header={item?.name}
											>
												<div 
															style={{backgroundColor:'var(--collapse-bg-color)', padding:'8px', borderRadius:'8px'}}>
												{isLoading ? (
													<Spin
														tip="Loading"
														className="flex items-center justify-center p-2"
														size="large"
													/>
												) : (
													
															
													<AiProgramSummaryContent
														fetchProgramByIdList={fetchProgramByIdList}
														currentCount={currentCount}
														programIdData={programIdData}
														programSessionData={programSessionData}
														isLoading={isLoading}
														antIcon={antIcon}
														CustomModalInfo={CustomModalInfo}
														setSessionClicked={setSessionClicked}
														setRehabExerciseToPatientId={setRehabExerciseToPatientId}
														setProgramuTutorialVideo={setProgramuTutorialVideo}
														setProgramDescription={setProgramDescription}
														setExerciseTitle={setExerciseTitle}
													/>
												)}
												<div>
													{totalCount > 9 && !perPage && (
														<Pagination
															current={currentCount}
															onChange={pageHandle}
															total={totalCount}
														/>
													)}
												</div>
												</div>
											</Panel>
										</React.Fragment>
									</Collapse>
								))}
									</div> }
							{isSessionClicked && (
								<SessionExerciseChart
									setSessionClicked={setSessionClicked}
									exerciseTitle={exerciseTitle!}
									programDescription={programDescription!}
									programTutorialVideo={programTutorialVideo!}
									perPage={perPage}
									exerciseProgramData={exerciseProgramData}
								/>
							)}
							<div>
								{totalPage > 1 && !perPage && !isSessionClicked && (
									<Pagination
										current={currentPage}
										onChange={onPageChange}
										total={totalPage}
									/>
								)}
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}

