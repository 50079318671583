import { Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
	AddProgramPopupProps,
	DeleteExerciseIds,
	IProgramExercise,
  IThumbnailProps,
} from '@stores/interfaces';
import { useTranslation } from 'react-i18next';
import {
	createProgram,
	createProgramTemplate,
	updateProgram,
} from '@stores/aiAssistant/program';
import { AddLibraryExerciseModal } from './AddLibraryExerciseModal';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Edit05 } from '@carespace-icons/general/edit05';
import Button from '@atoms/Button';
import AddProgramItem from './AddProgramItem';

export const AddProgramPopup = ({
	isVisible,
	onOk,
	onCancel,
	isSaveTemplateVisible,
	selectedExercises,
	deleteProgram,
	isEdit,
	setSelectedExercises,
	program,
	refresh,
	setRefresh,
	programId,
	strapiId,
	activeKey, thumbnailValue
}: AddProgramPopupProps) => {
	const [modalLabel, setModalLabel] = useState<string[]>();
	const [outLabel, setOutLabel] = useState<string[]>();
	const [isSaveTemplate, setSaveTemplate] = useState(false);
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [programName, setProgramName] = useState<string>(program?.name!);
	const [programDescription, setProgramDescription] = useState<string>(
		program?.description!,
	);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [duration, setDuration] = useState<number>(program?.duration!);
	const [durationType, setDurationType] = useState<string>(
		program?.durationType!,
	);
	const [programStartDate, setProgramStartDate] = useState<string>(
		program
			? dayjs(program?.startAt ? program?.startAt : program?.createdAt).format(
					'YYYY-MM-DD',
				)
			: '',
	);
	const [isLibraryModalVisible, SetLibraryModalVisible] = useState(false);
	const [deleteArray, setDeleteArray] = useState<DeleteExerciseIds[]>([]);
	const [imgFile, setImgFile] = useState<string>(thumbnailValue || '/images/programs/ProgramDefaultImage.jpg');
	const [previewImage, setPreviewImage] = useState<string | null>(thumbnailValue || '/images/programs/ProgramDefaultImage.jpg');
	const [previewUnSplashedImage, setPreviewUnSplashedImage] = useState<string | IThumbnailProps>(program?.thumbnail! || '/images/programs/ProgramDefaultImage.jpg');

	const isOutOfLimit = selectedExercises?.some(
		item =>
			item.reps > 100 ||
			item.reps < 1 ||
			item.sets > 100 ||
			item.sets < 1 ||
			item.weeklyReps > 100 ||
			item.weeklyReps < 1 ||
			item.dailyReps > 100 ||
			item.dailyReps < 1,
	);
	const isWarning = selectedExercises?.some(
		item =>
			item.reps >= 15 ||
			item.sets >= 15 ||
			item.weeklyReps >= 15 ||
			item.dailyReps >= 15,
	);

	useEffect(() => {
		if (isOutOfLimit) {
			const modalLabelSet = new Set<string>();
			selectedExercises.forEach(item => {
				if (item.reps > 100 || item.reps < 1) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.repetitions'),
					);
				}
				if (item.sets > 100 || item.sets < 1) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.setsPerSession'),
					);
				}
				if (item.weeklyReps > 100 || item.weeklyReps < 1) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.sessionsPerWeek'),
					);
				}
				if (item.dailyReps > 100 || item.dailyReps < 1) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.setsPerDay'),
					);
				}
			});
			setOutLabel(Array.from(modalLabelSet));
			return;
		}

		if (isWarning) {
			const modalLabelSet = new Set<string>();
			selectedExercises.forEach(item => {
				if (item.reps >= 15) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.repetitions'),
					);
				}
				if (item.sets >= 15) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.setsPerSession'),
					);
				}
				if (item.weeklyReps >= 15) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.sessionsPerWeek'),
					);
				}
				if (item.dailyReps >= 15) {
					modalLabelSet.add(
						t('Admin.data.managePatient.rehab.exercises.setsPerDay'),
					);
				}
			});
			setModalLabel(Array.from(modalLabelSet));
			return;
		}
	}, [selectedExercises]);

	useEffect(() => {
		if (program) {
			setProgramStartDate(
				dayjs(program?.startAt ? program?.startAt : program?.createdAt).format(
					'YYYY-MM-DD',
				),
			);
			setProgramName(program?.name!);
			setDuration(program?.duration!);
			setDurationType(program?.durationType!);
			setProgramDescription(program?.description!);
			setImgFile(thumbnailValue);
			setPreviewImage(thumbnailValue);
			setPreviewUnSplashedImage(program?.thumbnail!);
		}
	}, [isVisible]);

	const handleCreateProgram = async () => {
		try {
			onCancel();
			let exercises = [];
			selectedExercises?.forEach(
				(exercise: IProgramExercise, index: number) => {
					const tempExercise = {
						strapiExerciseId: exercise.strapiExerciseId || '',
						exerciseLibraryId: exercise.exerciseLibraryId || '',
						weeklyReps: exercise.weeklyReps,
						dailyReps: exercise.dailyReps,
						sets: exercise.sets,
						reps: exercise.reps,
						order: index + 1,
					};
					exercises.push(tempExercise);
				},
			);
			const formData = new FormData();
			formData.append('name', programName);
			formData.append('duration', duration);
			formData.append('durationType', durationType);
			formData.append('description', programDescription);
			formData.append('thumbnail', imgFile);
			if (selectedUser?.id) {
				formData.append('userId', String(selectedUser?.id));
			} else {
				console.error('userId is missing or invalid');
				return;
			}
			if (activeKey === '2') {
				if (programId) {
					formData.append('programTemplateId', programId);
				}
				if (strapiId) {
					formData.append('strapiProgramTemplateId', strapiId);
				}
			}
			exercises?.forEach((exercise, index) => {
				Object.keys(exercise).forEach(key => {
					if (exercise[key]) {
						formData.append(
							`exercises[create][${index}][${key}]`,
							exercise[key],
						);
					}
				});
			});
			await dispatch(createProgram(formData));
			if (isSaveTemplate) {
				handleCreateProgramTemplate();
			}
			handleReset();
		} catch (error) {
			console.error('Error occurred while creating the program:', error);
		}
	};

	const handleReset = () => {
		setRefresh(!refresh);
		setSelectedExercises([]);
		setProgramName('');
		setDuration(parseInt(''));
		setDurationType('');
		setProgramStartDate('');
		onOk();
		setSaveTemplate(false);
	};

	const handleRemoveExercise = (id: number | string) => {
		setSelectedExercises(
			selectedExercises.filter(exercise => exercise?.id != id),
		);
		let selected: IProgramExercise = selectedExercises.find(
			exercise => exercise?.id === id,
		)!;
		let deleteData: DeleteExerciseIds[] = [...deleteArray];
		if (selected?.programId) {
			deleteData.push({
				id: selected.id,
			});
		}
		setDeleteArray(deleteData);
	};

	const validateProgram = () => {
		if (selectedExercises.length <= 0) {
			message.error(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.selectAtLeast'));
		} else if (!programName?.trim()) {
			message.error(
				t('Admin.data.menu.aiAssistant.aiAssistantPrograms.programNameErr'),
			);
		} else if (!programDescription?.trim()) {
			message.error(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.programDescriptionErr'));
		} else if (!programStartDate) {
			message.error(
				t('Admin.data.menu.aiAssistant.aiAssistantPrograms.startDateErr'),
			);
		} else if (!duration) {
			message.error(
				t('Admin.data.menu.aiAssistant.aiAssistantPrograms.durationErr'),
			);
		} else if (duration <= 0) {
			message.error(
				t('Admin.data.menu.aiAssistant.aiAssistantPrograms.durationValid'),
			);
		} else if (!durationType) {
			message.error(
				t('Admin.data.menu.aiAssistant.aiAssistantPrograms.durationTypeErr'),
			);
		} else {
			isOutOfLimit
				? message.error({
						content:
							outLabel && outLabel.length > 0
								? outLabel.length > 1
									? `${outLabel.slice(0, -1).join(', ')} ${t('and')} ${outLabel[outLabel.length - 1]} should be between 1 and 100`
									: `${outLabel[0]} should be between 1 and 100`
								: t('No values exceeded the limit'),
					})
				: isWarning
					? message.info({
							content: (
								<div className="m-5" style={{ color: 'black' }}>
									{modalLabel && modalLabel.length > 1
										? modalLabel.slice(0, -1).join(', ') +
											' ' +
											t('Admin.data.rehab.addExerciseFromLibrary.and') +
											' ' +
											modalLabel[modalLabel.length - 1]
										: modalLabel?.[0]}{' '}
									{t('Admin.data.rehab.addExerciseFromLibrary.greaterThan')}
									<div className="mt-4 flex justify-center">
										<div className="mr-[10px]">
											<Button
												onClick={() => message.destroy()}
												children={t(
													'Admin.data.rehab.addExerciseFromLibrary.cancel',
												)}
												ghost={true}
												size="sm"
											/>
										</div>
										<Button
											onClick={() => {
												message.destroy();
												isEdit ? handleEditProgram() : handleCreateProgram();
											}}
											children={t('Admin.data.rehab.addExerciseFromLibrary.ok')}
											size="sm"
										/>
									</div>
								</div>
							),
							duration: 0,
							icon: <></>,
						})
					: isEdit
						? handleEditProgram()
						: handleCreateProgram();
		}
	};

	const handleEditProgram = async () => {
		if (isSaveTemplate) handleCreateProgramTemplate();
		let exercises: IProgramExercise[] = [];
		let newItemList: IProgramExercise[] = [];
		selectedExercises?.map((exercise: IProgramExercise, index: number) => {
			const tempExercise: IProgramExercise = {
				weeklyReps: exercise.weeklyReps,
				dailyReps: exercise.dailyReps,
				sets: exercise.sets,
				reps: exercise.reps,
				order: index + 1,
				id: exercise.id,
			};
			if (exercise.programId) {
				tempExercise.id = exercise.id;
				exercises.push(tempExercise);
			} else {
				if (exercise.strapiExerciseId) {
					tempExercise.strapiExerciseId = exercise.strapiExerciseId;
				} else {
					tempExercise.exerciseLibraryId = exercise.exerciseLibraryId;
				}
				newItemList.push(tempExercise);
			}
		});
		const payload = {
			name: programName,
			duration: duration,
			durationType: durationType,
			startAt: programStartDate,
			description: programDescription,
			thumbnail: imgFile,
			exercises: {
				update: exercises,
				delete: deleteArray,
				create: newItemList,
			},
		};
		const data = await dispatch(
			updateProgram({ programId: program?.id!, programData: payload }),
		);
		if (data?.payload) {
			message.success('Updated Successfully!!');
		}
		handleReset();
	};

	const handleCreateProgramTemplate = async () => {
		const formData = new FormData();
		formData.append('name', programName);
		formData.append('duration', duration.toString());
		formData.append('durationType', durationType);
		formData.append('description', programDescription);
		if (imgFile) {
			formData.append('thumbnail', imgFile);
		}
		const exercises = selectedExercises?.map((exercise, index) => {
			const tempExercise = {
				weeklyReps: exercise.weeklyReps,
				dailyReps: exercise.dailyReps,
				sets: exercise.sets,
				reps: exercise.reps,
				order: index + 1,
			};
			return exercise.strapiExerciseId
				? { ...tempExercise, strapiExerciseId: exercise.strapiExerciseId }
				: { ...tempExercise, exerciseLibraryId: exercise.exerciseLibraryId };
		});
		exercises.forEach((exercise, index) => {
			Object.keys(exercise).forEach(key => {
				formData.append(
					`exercises[create][${index}][${key}]`,
					exercise[key].toString(),
				);
			});
		});
		try {
			await dispatch(createProgramTemplate(formData));
			handleReset();
		} catch (error) {
			console.error('Error creating program template:', error);
		}
	};

	const itemSelection = (item: IProgramExercise[]) => {
		const results = selectedExercises.filter(
			({ id: id1 }) => !item.some(({ id: id2 }) => id2 === id1),
		);
		setSelectedExercises(item);
		let deleteData: DeleteExerciseIds[] = [...deleteArray];
		if (results.length > 0 && results[0]?.programId) {
			deleteData.push({
				id: results[0]?.id,
			});
		}
		setDeleteArray(deleteData);
	};

	return (
		<Modal
			title={
				<>
					<div className="flex gap-4">
						<span>
							<Edit05 width={20} height={20} color="stroke-primary-600" />
						</span>
						<span className="titleStyle">
							{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.create')}
						</span>
					</div>
				</>
			}
			onOk={onOk}
			open={isVisible}
			style={{ top: 20 }}
			onCancel={onCancel}
			footer={false}
			width={900}
			className="select-none"
			maskClosable={false}>
			<AddProgramItem
				selectedExercises={selectedExercises}
				isSaveTemplate={isSaveTemplate}
				isSaveTemplateVisible={isSaveTemplateVisible}
				setSelectedExercises={setSelectedExercises}
				validateProgram={validateProgram}
				handleRemoveExercise={handleRemoveExercise}
				setSaveTemplate={setSaveTemplate}
				SetLibraryModalVisible={SetLibraryModalVisible}
				programName={programName}
				setProgramName={setProgramName}
				setProgramStartDate={setProgramStartDate}
				programStartDate={programStartDate}
				duration={duration}
				setDuration={setDuration}
				setDurationType={setDurationType}
				durationType={durationType}
				programDescription={programDescription}
				setProgramDescription={setProgramDescription}
				imgFile={imgFile}
				setImgFile={setImgFile}
				previewImage={previewImage}
				setPreviewImage={setPreviewImage}
				previewUnSplashedImage={previewUnSplashedImage}
				setPreviewUnSplashedImage={setPreviewUnSplashedImage}
			/>
			<AddLibraryExerciseModal
				isVisible={isLibraryModalVisible}
				onOk={() => SetLibraryModalVisible(false)}
				onCancel={() => SetLibraryModalVisible(false)}
				selectedExercises={selectedExercises}
				setSelectedExercises={itemSelection}
			/>
		</Modal>
	);
};
