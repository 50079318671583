import { Edit01 } from "@carespace-icons/general/edit01"
import { PlayCircle } from "@carespace-icons/media/playCircle"
import { updateProgram } from "@stores/aiAssistant/program"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { IProgramData } from "@stores/interfaces"
import { Button, message, Switch } from "antd"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

interface IExerciseHeaderProps {
  approved: boolean
  setApproved: (value: boolean) => void
  program: IProgramData
  startProgramSession: () => void
  setModalVisible: (value: boolean) => void
  hoveredSession: string | null
}

export default function ExerciseHeader(props: IExerciseHeaderProps) {
  const { approved, setApproved, program, startProgramSession, setModalVisible, hoveredSession } = props
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)

  const buttonStyle = {
    color: "var(--button-text-color)",
    border: "inherit",
    marginTop: "-5px",
  };

  const handleApproveProgram = useCallback(async (program: IProgramData, value: boolean) => {
    setApproved(value);
    const programData = {
      physioterapistId: user?.id,
      active: value,
      status: value ? 'approved' : 'draft'
    };
    try {
      await dispatch(updateProgram({
        programId: program?.id,
        programData: programData
      }));
      value ? message.success(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.approved')) : message.success(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.disapproved'));
    } catch (error) {
      setApproved(!value);
      message.error(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.updateFailed'));
    }
  }, [program, user?.id]);

  return (
    <div className="flex gap-3 h-6">
      {hoveredSession === program.id && <>
        <span className="flex h-fit items-center gap-1 px-1.5 py-0.5 pb-1 rounded-lg bg-gray-100"
          onClick={(e) => {
            e.stopPropagation()
            setModalVisible(true)
          }}>
          <Edit01 width={12} height={12} color="stroke-gray-700" />
          <p className="text-gray-700 text-xs">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.edit')}</p>
        </span>
        <span className={`flex h-fit items-center gap-1 px-1.5 rounded-lg`}>
          <Switch
            checked={approved}
            onChange={(value, event) => {
              event.stopPropagation()
              handleApproveProgram(program, value)
            }}
            style={{
              backgroundColor: approved ? 'var(--mainmenu-bg-color)' : '#98a2b3',
            }} />
        </span>
        {!approved && <Button disabled style={{marginTop: '-5px'}} icon={<PlayCircle height={12} width={12} color="#cfcfcf" />} 
        >{t('Patient.data.myProgress.rehab.startSession')}
        </Button>}
      </>}
      {approved && <Button style={buttonStyle} className='start-session-css' icon={<PlayCircle height={12} width={12} color="#533F85" />} onClick={(e) => {
        e.stopPropagation()
        approved && startProgramSession()
      }}>{t('Patient.data.myProgress.rehab.startSession')}</Button>}
    </div>
  )
}
