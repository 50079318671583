import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

export const getActivityStream = createAsyncThunk(
  'getActivityStream',
  async (payload: {id: string}): Promise<any> => {
    const { id } = payload
    const { data } = await axios.get(`/activity-stream/${id}`);
    return data
  }
);

export const getEvaluationActivityStreamById = createAsyncThunk(
  'getEvaluationActivityStreamById',
  async (payload: {evaluationId: string}): Promise<any> => {
    const { evaluationId } = payload
    const { data } = await axios.get(`/evaluation/sessions/${evaluationId}`);
    return data
  }
);

export const getRomActivityStreamById = createAsyncThunk(
  'getRomActivityStreamById',
  async (payload: {romId: string}): Promise<any> => {
    const { romId } = payload
    const { data } = await axios.get(`/rom/session/${romId}`);
    return data
  }
);

export const getActivityStreamByDate = createAsyncThunk(
  'getActivityStreamByDate',
  async (payload: {id: string, startDate: string, endDate: string}): Promise<any> => {
    const { id, startDate, endDate } = payload
    const { data } = await axios.get(`/activity-stream/history/${id}?endDate=${endDate}&&startDate=${startDate}`);
    return data
  }
);
export const getProgramActivityStreamByData = createAsyncThunk(
  'getProgramActivityStreamByData',
  async (id: string): Promise<any> => {
    const { data } = await axios.get(`program/sessions/${id}`);
    return data
  }
);

export const getSurveyActivityStreamByData = createAsyncThunk(
  'getSurveyActivityStreamByData',
  async (id: string): Promise<any> => {
    const { data } = await axios.get(`survey/result/${id}`);
    return data
  }
);

export const getActivityUpdateStream = createAsyncThunk(
  'getActivityUpdateStream',
  async (payload: { id: string, formattedToday:string, formattedStartDate:string,endCursor:string }): Promise<any> => {
    const { id, formattedToday, formattedStartDate,endCursor } = payload
    const { data } = await axios.get(`/activity-stream/history/${id}?endDate=${formattedToday}&&startDate=${formattedStartDate}&&endCursor=${endCursor}`);
    return data
  }
);

export const getActivityStreamHistory = createAsyncThunk(
  'getActivityStreamHistory',
  async (): Promise<any> => {
    const { data } = await axios.get(`/activity-stream/history/unread`);
    return data
  }
);

export const patchActivityStreamHistory = createAsyncThunk(
  'patchActivityStreamHistory',
  async (payload:any) => {
    const { id } = payload
		const { data } = await axios.patch(
			`activity-stream/history/read/${id}`
		)
		return data
	}
);

export const postActivityStream = createAsyncThunk(
  'postActivityStream',
  async (payload: {
    userId: string;
    activityStreamId?: string;
    physioterapistId?: string;
    image?: File[];
    video?: Blob | null;
    message?: string;
  }): Promise<any> => {
    const formData = new FormData();
    formData.append("userId", payload.userId);
    if (payload.activityStreamId) {
      formData.append("activityStreamId", payload.activityStreamId);
    }
    if (payload.physioterapistId) {
      formData.append("physioterapistId", payload.physioterapistId);
    }
    if (payload.message) {
      formData.append("message", payload.message);
    }

    if (payload.image) {
      payload.image.forEach((image) => {
        formData.append("images", image);
      });
    }

    if (payload.video) {
        formData.append("videos", payload.video);
    }
    const { data } = await axios.post(`/activity-stream/post`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
);

const initialState = {
  getActivityStream: null,
  getActivityStreamByDate: null
};

const activityStreamSlice = createSlice({
  name: 'activityStream',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivityStream.fulfilled, (state, action) => {
      state.getActivityStream = action.payload;
    });
    builder.addCase(getActivityStream.rejected, (state, action) => { });

    builder.addCase(getActivityStreamByDate.fulfilled, (state, action) => {
      state.getActivityStreamByDate = action.payload;
    });
    builder.addCase(getActivityStreamByDate.rejected, (state, action) => { });

    builder.addCase(postActivityStream.fulfilled, (state, action) => { });
    builder.addCase(postActivityStream.rejected, (state, action) => { });
  },
});

export default activityStreamSlice.reducer;