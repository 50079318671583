import React, { useRef } from 'react';
import { Modal, Row, Col, Input } from 'antd';
import VideoRecord from '@molecules/MPVideoRecord'
import { patientTimeLimit } from '@stores/constants'
import ActivityStreamVideoHeader from '../ActivityStreamVideoHeader';
import Button from '@atoms/Button';
import { VideoModalProps } from '@stores/interfaces';
import { BiImageAdd } from 'react-icons/bi';
import { Image } from 'antd/lib';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import './style.css'

const VideoModal: React.FC<VideoModalProps> = ({
  isReport, videoBlob, isVideoModalVisible, isImageModal, openImgInput, imgFile, facingMode, handleClose,
  onStartRecord, onStopRecord, onDiscardRecord, onEndedTimer, onRecordFinished, isStartingTimer,
  videoRef, messageDescription, setMessageDescription, sendMessage, handleKeyPress,
  videoState
}) => {
  const { t } = useTranslation()

  const videoElementRef = useRef<HTMLVideoElement>(null);
  const handleModalClose = () => {
    if (videoElementRef.current) {
      videoElementRef.current.pause();
    }
  }
  return (
    <Modal
      title=""
      open={isVideoModalVisible || isImageModal}
      footer={null}
      onCancel={() => {
        handleModalClose()
        handleClose();
      }}
      width={850}
      closable={videoState !== 'READY' && videoState !== 'RECORDING'}
      style={{ userSelect: "none" }}
    >
      <Row align="middle" justify="center" gutter={[16, 16]}>
        {isVideoModalVisible && (
          <Col span={16}>
            <ActivityStreamVideoHeader
              videoState={videoState}
              onStartRecord={onStartRecord}
              onStopRecord={onStopRecord}
              onDiscardRecord={onDiscardRecord}
              onEndedTimer={onEndedTimer}
              isStartingTimer={isStartingTimer}
            />
            {videoBlob ? (
              <video
                src={URL.createObjectURL(videoBlob!)}
                ref={videoElementRef}
                controls
                autoPlay
                loop
                muted
                style={{ width: '100%' }}
              />
            ) : (
              <>
                <ActivityStreamVideoHeader
                  videoState={videoState}
                  onStartRecord={onStartRecord}
                  onStopRecord={onStopRecord}
                  onDiscardRecord={onDiscardRecord}
                  onEndedTimer={onEndedTimer}
                  isStartingTimer={isStartingTimer}
                />
                <VideoRecord
                  ref={videoRef}
                  onRecordFinished={onRecordFinished}
                  facingMode={facingMode}
                  timeLimit={patientTimeLimit}
                  audio={true}
                />
              </>
            )}
          </Col>
        )}
        {isImageModal && (imgFile ?? []).length === 0 && (
          <Col span={16} className='custom-col' onClick={openImgInput}>
            <div className='flexible-modal'>
              <BiImageAdd style={{ height: "50px", width: "50px", color: "gray" }} />
              <p className="custom-text">{t('Patient.data.activityStream.uploadImage')}</p>
            </div>
          </Col>
        )}

        {imgFile && imgFile.length > 0 && (
          <Col span={16}>
            <div className="flexCenter">
              <Image
                src={URL.createObjectURL(imgFile[0])}
                alt="Selected Preview"
                className="custom-border"
                height={330}
                preview={{
                  mask: <MdOutlineRemoveRedEye size={18} />,
                }}
              />
              <div
                className="absolute-bottom-left cursor-pointer"
                onClick={openImgInput}
              >
                <BiImageAdd style={{ height: "20px", width: "20px" }} /> {t('Patient.data.activityStream.editImage')}
              </div>
            </div>
          </Col>
        )}

        <Col span={8}>
          <div className="flex-center">
            <p className="custom-label">{isReport ? t("Admin.data.addNotes.addNotesHere") : t('Patient.data.activityStream.addMessage')} :</p>
          </div>
          <Input.TextArea
            id="message"
            name="message"
            onKeyDown={handleKeyPress}
            placeholder={isReport ? t("Admin.data.addNotes.enterNotesHere") :t('Patient.data.activityStream.messageHere')}
            rows={10}
            value={messageDescription}
            onChange={(e) => {setMessageDescription(e.target.value)}}
            className='mb-3'
            style={{ border: "1px solid rgba(212, 212, 212, 1)", borderRadius: "8px" }}
          />
          <Button onClick={() => sendMessage()} size="full">{t('Patient.data.activityStream.submit')}</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default VideoModal;
