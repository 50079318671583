import { USER_ROLES } from '@stores/constants';
import { useTypedSelector } from '@stores/index';
import { AllFeatureFlagsInterface, InvitationData } from '@stores/interfaces';
import { Button, Form, Input, Select, Spin } from 'antd';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from './TextEditor';

const { Option } = Select;

interface Feature {
	featureId: Key | null | undefined;
	feature: {
		name: string;
	};
}

type FeatureFlagList = Feature[];

interface Invitation {
	firstName: string;
	lastName: string;
	email: string;
	invitedRole: string;
	features: string[];
	message: string;
}

export interface IDefault {
	rowData: InvitationData;
	renderMessageField: () => void;
	userFormData: InvitationData;
	setUserFormData: (value: InvitationData) => void;
	updateForm: () => void;
	onFinish: () => void;
	isRegistered: boolean;
	featureFlagList: FeatureFlagList[];
	handleFeatureChange: () => void;
	isBulkInvite?: boolean;
	setIsEditPatientModalOpen?: (val: boolean) => void;
	handleUserUpdate?: () => void;
	form: Invitation;
	isUpdating: boolean
	content: string
	setContent: (value:string) => void;
	activeKey: string | number
	instanceLink: string
	username: string
	password: string
	inviteCode: string
}

export const selectRole = [
	{ id: 1, name: 'Super Admin', value: USER_ROLES.SUPER_ADMIN },
	{ id: 2, name: 'Admin', value: USER_ROLES.ADMIN },
	{ id: 3, name: 'User', value: USER_ROLES.USER },
];

const Default = (props: IDefault) => {
	const { t } = useTranslation();
	const {
		rowData,
		renderMessageField,
		updateForm,
		onFinish,
		userFormData,
		setUserFormData,
		isRegistered,
		handleFeatureChange,
		featureFlagList,
		isBulkInvite = false,
		setIsEditPatientModalOpen,
		handleUserUpdate,
		isUpdating,
		form,
		inviteCode,
		password,
		username,
		instanceLink,
		activeKey,
		setContent,
		content
	} = props;
	const user = useTypedSelector(state => state.user);

	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const allFeatureFlags = useTypedSelector(
		state => state.adminDashboardPatient.allFeatureFlags,
	);

	const validateSubmit = () => {
		if (
			userFormData?.email &&
			userFormData?.firstName &&
			userFormData?.lastName
		) {
			handleUserUpdate!();
			setIsEditPatientModalOpen!(false);
		}
	};

	const handleResetToDefault = () => {
		const allFeatures = allFeatureFlags?.map(feature => feature?.feature?.name);
		setUserFormData({
			...userFormData,
			features: allFeatures,
		});
		form.setFieldsValue({ features: allFeatures });
	};

	return (
		<Form
			form={form}
			className=" default-invite-page"
			layout="vertical"
			onFinish={rowData ? updateForm : onFinish}
			initialValues={userFormData}>
			{
				isUpdating ? <div className='flex items-center h-[200px] justify-center'>
					<Spin tip="Loading" size="large" />
				</div> :
					<>
						<div className="flex gap-5 w-full mt-6">
							<Form.Item
								label={t('Patient.data.completeProfile.firstName')}
								name="firstName"
								className="w-1/2"
								rules={[
									{
										required: true,
										message: t(
											'Admin.data.menu.userRoles.invitePatientModal.firstNameRequired',
										),
									},
								]}>
								<Input
									className="input-item"
									placeholder={t(
										'Admin.data.menu.userRoles.invitePatientModal.firstNamePlaceholder',
									)}
									defaultValue={userFormData.firstName}
									onChange={event =>
										setUserFormData({
											...userFormData,
											firstName: event.target.value,
										})
									}
								/>
							</Form.Item>
							<Form.Item
								label={t('Patient.data.completeProfile.lastName')}
								name="lastName"
								className="w-1/2"
								rules={[
									{
										required: true,
										message: t(
											'Admin.data.menu.userRoles.invitePatientModal.lastNameRequired',
										),
									},
								]}>
								<Input
									className="input-item"
									placeholder={t(
										'Admin.data.menu.userRoles.invitePatientModal.lastNamePlaceholder',
									)}
									defaultValue={userFormData.lastName}
									onChange={event =>
										setUserFormData({
											...userFormData,
											lastName: event.target.value,
										})
									}
								/>
							</Form.Item>
						</div>
						<div className="flex gap-5 w-full">
							<Form.Item
								label={t('Admin.data.menu.userRoles.invitePatientModal.email')}
								name="email"
								className={`${isSuperAdmin ? 'w-4/5' : 'w-full'}`}
								rules={[
									{
										required: true,
										message: t(
											'Admin.data.menu.userRoles.invitePatientModal.emailRequired',
										),
									},
								]}>
								<Input
									className="input-item"
									type="email"
									placeholder={t(
										'Admin.data.menu.userRoles.invitePatientModal.emailPlaceholder',
									)}
									defaultValue={userFormData.email}
									onChange={event =>
										setUserFormData({
											...userFormData,
											email: event.target.value,
										})
									}
								/>
							</Form.Item>
							{isBulkInvite && (
								<Form.Item
									label={t('Admin.data.menu.userRoles.invitePatientModal.phone')}
									name="phone"
									className={`${isSuperAdmin ? 'w-4/5' : 'w-full'}`}>
									<Input
										className="input-item"
										disabled={isRegistered ? true : false}
										type="number"
										placeholder={'Phone number required'}
										defaultValue={userFormData.phone}
										onChange={event =>
											setUserFormData({
												...userFormData,
												phone: event.target.value,
											})
										}
									/>
								</Form.Item>
							)}
							{!isBulkInvite && isSuperAdmin && (
								<Form.Item
									label={t('Admin.data.menu.userRoles.invitePatientModal.role')}
									name="invitedRole"
									className="w-1/5"
									rules={[
										{
											required: true,
											message: t(
												'Admin.data.menu.userRoles.invitePatientModal.roleRequired',
											),
										},
									]}>
									<Select
										placeholder={t(
											'Admin.data.menu.userRoles.invitePatientModal.roleRequired',
										)}
										className="custom-select-class input-item"
										onChange={value => {
											const selectedRole = selectRole.find(
												option => option.id === value,
											);
											setUserFormData({
												...userFormData,
												invitedRole: selectedRole?.value,
											});
										}}
										allowClear>
										{selectRole?.map(option => (
											<Option key={option?.id} value={option?.id}>
												{option.name}
											</Option>
										))}
									</Select>
								</Form.Item>
							)}
						</div>
						{!isBulkInvite && (
							<Form.Item
								label={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											width: '100vw',
										}}>
										<span>
											{t(
												'Admin.data.menu.userRoles.invitePatientModal.selectFeature',
											)}
										</span>
										<Button
											type="link"
											className="!text-primary-800"
											style={{ padding: 0, margin: 0 }}
											onClick={handleResetToDefault}>
											{t(
												'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.resetDefault',
											)}
										</Button>
									</div>
								}
								name="features"
								rules={[
									{
										required: true,
										message: t(
											'Admin.data.menu.userRoles.invitePatientModal.selectFeatureRequired',
										),
									},
								]}>
								<Select
									placeholder={t(
										'Admin.data.menu.userRoles.invitePatientModal.selectFeatureRequired',
									)}
									className="select-field-input-item"
									mode="multiple"
									value={userFormData.features}
									onChange={handleFeatureChange}
									allowClear>
									{featureFlagList?.map(
										(feature: {
											featureId: Key | null | undefined;
											feature: { name: string };
										}) => (
											<Option key={feature.featureId} value={feature?.feature?.name}>
												{feature?.feature?.name}
											</Option>
										),
									)}
								</Select>
							</Form.Item>
						)}
					</>}
			{!rowData && <TextEditor
				content={content}
				setContent={setContent}
				activeKey={activeKey}
				instanceLink={instanceLink}
				username={username}
				password={password}
				inviteCode={inviteCode}
			/>}
			<Form.Item style={{ width: '100%' }}>
				{isBulkInvite ? (
					<div className="bulk-invite-actions">
						<Button
							size="large"
							style={{ width: '50%' }}
							onClick={() => {
								setIsEditPatientModalOpen!(false);
							}}
							type="default">
							{t('Admin.data.menu.userRoles.invitePatientModal.cancel')}
						</Button>{' '}
						<Button
							size="large"
							style={{ width: '50%' }}
							htmlType="submit"
							onClick={() => validateSubmit()}
							type="primary">
							{t('Admin.data.menu.userRoles.invitePatientModal.save')}
						</Button>
					</div>
				) : (
					<Button
						size="large"
						htmlType="submit"
						type="primary"
						style={{ width: '100%' }} disabled={isUpdating}>
						{rowData
							? t('Admin.data.menu.userRoles.invitePatientModal.update')
							: t('Admin.data.menu.userRoles.invitePatientModal.save')}
					</Button>
				)}
			</Form.Item>
		</Form>
	);
};

export default Default;
