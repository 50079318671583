import CoachReport from '@pages/Contacts/Details/CoachReport'
import { useTranslation } from 'react-i18next';

export default function AiAssistantMyReport({perPage}: {perPage?: number}) {
  const { t } = useTranslation();
  
  return (
    <div className='m-6'>
        <CoachReport perPage={perPage} isAssistant/>
    </div>
  )
}
