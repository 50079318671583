import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect, useState } from 'react';
import { measurementTypes } from '@pages/PatientOnboard/Constants';
import { getUser, updateUserWeight } from '@stores/user';
import { Input, message, Popover } from 'antd';
import { Speedometer01 } from '@carespace-icons/general/speedometer01';
import { Line } from '@ant-design/charts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getUserById } from '@stores/contacts/contacts';
import { useTheme } from '@pages/Themes/ThemeContext';

const createChartConfig = (data: any, max: number, min: number) => {
	return {
		data,
		xField: 'createdAt',
		yField: 'metricWeight',
		smooth: true,
		color: document.documentElement.getAttribute("data-theme") === 'vibrant' ? '#ff6600' :'#ccff00',
		area: {
			style: {
				fill: 'l(90) 0:rgba(204, 255, 0, 0.5) 1:rgba(204, 255, 0, 0)',
			},
		},
		lineStyle: {
			stroke: document.documentElement.getAttribute("data-theme") === 'vibrant' ? '#ff6600' :'#ccff00',
			lineWidth: 3,
		},
		meta: {
			value: {
				min: min,
				max: max,
				// tickInterval: Math.ceil((max - min) / 5),
			},
		},
		tooltip: false,
		xAxis: {
			line: null,
			tickLine: null,
			label: null,
		},
		yAxis: {
			line: null,
			tickLine: null,
			grid: null,
			label: null,
			min: min,
			max: max,
		},
		legend: false,
	};
};

export const WeightHistoryChart = ({ userDetails }) => {
	const { toggleTheme } = useTheme(); 
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [open, setOpen] = useState(false);
	const dispatch = useTypedDispatch();
	const [weightFormData, setWeightFormData] = useState({
		imperialWeight: 0,
		metricWeight: 0,
		measurementSystem: 'imperial',
	});
	const [unit, setUnit] = useState('');

	useEffect(() => {
		setWeightFormData({
			imperialWeight:
				userDetails.profile?.weight &&
				userDetails.profile?.weight[0]?.imperialWeight,
			metricWeight:
				userDetails.profile?.weight &&
				userDetails.profile?.weight[0]?.metricWeight,
			measurementSystem: userDetails.profile?.measurementSystem,
		});

		setUnit(
			userDetails.profile?.measurementSystem === measurementTypes.METRIC
				? measurementTypes.METRIC
				: measurementTypes.IMPERIAL,
		);
	}, [userDetails, selectedUser]);

	const chartConfig = userDetails?.profile?.weight
		? createChartConfig(
				userDetails?.profile?.weight?.slice().reverse(),
				Math.max(
					...userDetails?.profile?.weight
						.slice()
						?.map((o: { metricWeight: any }) => o.metricWeight),
				),
				Math.min(
					...userDetails?.profile?.weight
						.slice()
						?.map((o: { metricWeight: any }) => o.metricWeight),
				),
			)
		: null;

	const handleWeightChange = (unit: string) => {
		setUnit(
			unit === measurementTypes.KILOGRAM
				? measurementTypes.METRIC
				: measurementTypes.IMPERIAL,
		);

		setWeightFormData(prev => ({
			...prev,
			measurementSystem:
				unit === measurementTypes.KILOGRAM
					? measurementTypes.METRIC
					: measurementTypes.IMPERIAL,
		}));
	};

	const handleUpdateWeight = async () => {
		try {
			const payload = {
				measurementSystem: weightFormData?.measurementSystem,
				...(weightFormData?.measurementSystem == 'imperial'
					? { imperialWeight: weightFormData.imperialWeight }
					: { metricWeight: weightFormData.metricWeight }),
			};
			await dispatch(
				updateUserWeight({
					userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
					data: payload,
				}),
			);
			!user.isPhysioterapist
				? await dispatch(getUser(user?.id))
				: await dispatch(getUserById(selectedUser?.id));
			setOpen(false);
		} catch (error) {
			console.error(`${t('Patient.data.completeProfile.saveError')}`, error);
			message.error(`${t('Patient.data.completeProfile.saveError')}`);
		}
	};

	const formatWeight = (
		weight: { imperialWeight?: string; metricWeight?: string }[],
		measurementSystem: string,
		pos: number = 0,
	) => {
		if (!weight || weight.length === 0) {
			return `${t('Admin.data.managePatient.na')}`;
		}

		return measurementSystem === measurementTypes.IMPERIAL
			? `${weight[pos]?.imperialWeight} ${measurementTypes.LB}`
			: `${weight[pos]?.metricWeight}  ${measurementTypes.KILOGRAM}`;
	};

	const recentWeightchange = (
		weight: { imperialWeight?: number; metricWeight?: number }[],
		measurementSystem: string,
	) => {
		const metricDiff = weight[0]?.metricWeight! - weight[1]?.metricWeight!;
		const imperialDiff =
			weight[0]?.imperialWeight! - weight[1]?.imperialWeight!;

		return measurementSystem === measurementTypes.IMPERIAL
			? `${imperialDiff > 0 ? '+' : ''} ${imperialDiff} ${measurementTypes.LB}`
			: `${metricDiff > 0 ? '+' : ''} ${metricDiff}  ${measurementTypes.KILOGRAM}`;
	};

	const handleOnChangeForm = (key: string, value: number | null) => {
		setWeightFormData(prevData => ({ ...prevData, [key]: value }));
	};

	return (
		<ul
			style={{
				background: 'var(--user-menu-bg-color)',
				borderRadius: '0.5rem',
				height: 'auto',
				marginTop: '10px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}>
			<li className="weight-chart-title flex justify-between items-center mx-3 mt-4">
				<p className="leading-3 ">Weight</p>
				<Popover
					placement="top"
					open={open}
					onOpenChange={() => {
						setOpen(!open);
					}}
					content={
						<div className="flex flex-col gap-3 justify-center items-center p-3 w-72">
							<p className="font-bold">Add your weight</p>
							<p>Keep track of your progress by adding your current weight.</p>
							<div style={{ textAlign: 'left', marginBottom: '16px' }}>
								<div
									className="weight-input-container"
									style={{ display: 'flex', alignItems: 'center' }}>
									<Input
										type="number"
										prefix={
											<Speedometer01
												width={13}
												height={15}
												color="stroke-gray-600"
											/>
										}
										placeholder="Weight"
										value={
											unit === measurementTypes.METRIC
												? weightFormData.metricWeight
												: weightFormData.imperialWeight || ''
										}
										onChange={e => {
											const value = parseFloat(e.target.value);
											if (value >= 0 || e.target.value === '') {
												handleOnChangeForm(
													unit === measurementTypes.METRIC
														? 'metricWeight'
														: 'imperialWeight',
													value || null,
												);
											}
										}}
										style={{ flex: 1, marginRight: '8px', padding: '8px' }}
									/>
									<select
										value={unit === measurementTypes.METRIC ? 'Kg' : 'Lb'}
										onChange={e => handleWeightChange(e.target.value)}
										style={{
											width: 80,
											height: 40,
											borderRadius: '6px',
											border: '1px solid gainsboro',
										}}>
										<option value="Lb">Lb</option>
										<option value="Kg">Kg</option>
									</select>
								</div>
							</div>
							<div
								onClick={() => {
									weightFormData?.measurementSystem == 'imperial'
										? weightFormData?.imperialWeight !=
											userDetails?.profile?.weight[0]?.imperialWeight
											? !weightFormData?.imperialWeight
												? message.warning("weight can't be empty")
												: handleUpdateWeight()
											: message.warning("weight can't be same")
										: weightFormData?.metricWeight !=
											  userDetails?.profile?.weight[0]?.metricWeight
											? !weightFormData?.metricWeight
												? message.warning("weight can't be empty")
												: handleUpdateWeight()
											: message.warning("weight can't be same");
								}}
								className={`p-2 bg-[var(--button-color-primary)] cursor-pointer w-full text-white font-semibold text-center rounded-lg`}>
								Save
							</div>
						</div>
					}
					trigger="click">
					<span
						className="start-session-css text-xs px-2 py-1 rounded-full font-semibold cursor-pointer"
						style={{
							color: 'var(--button-text-color)',
							border: 'inherit',
						}}
						onClick={e => {
							e.stopPropagation();
						}}>
						New Input
					</span>
				</Popover>
			</li>
			<li className="text-md font-bold ml-3 leading-4" style={{color:'var(--button-color)'}}>
				{formatWeight(
					userDetails?.profile?.weight,
					userDetails?.profile?.measurementSystem,
				)}
				{userDetails?.profile?.weight?.length > 1 && (
					<p className="text-xs font-bold leading-4" style={{color:'var(--button-color)'}}>
						{recentWeightchange(
							userDetails?.profile?.weight,
							userDetails?.profile?.measurementSystem,
						)}
					</p>
				)}
			</li>
			{userDetails?.profile?.weight?.length > 1 && (
				<li style={{ height: 50, marginBottom: 10 }}>
					<Line {...chartConfig} />
				</li>
			)}
			<li className="text-xs w-full text-right pr-1 pb-1 text-gray-300/50">
				Last Update{' '}
				{moment(userDetails?.profile?.weight[0].createdAt)
					.local()
					.format('DD/MM/YYYY')}
			</li>
		</ul>
	);
};
