import React from 'react';

interface VideoPlayerProps {
  video: File | null | Blob;
  className: string;
  controls: boolean
}

const VideoPlayer = React.memo(({ video, className, controls }: VideoPlayerProps) => {
  return video ? <video src={URL.createObjectURL(video)} muted controls={controls} className={className} /> : null;
});

export default VideoPlayer;
