import { AddToReports } from "@atoms/AddToReports"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { Collapse, Empty, Pagination, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { FaceSad } from "@carespace-icons/users/faceSad"
import { FaceFrown } from "@carespace-icons/users/faceFrown"
import { FaceHappy } from "@carespace-icons/users/faceHappy"
import { FaceSmile } from "@carespace-icons/users/faceSmile"
import { FaceNeutral } from "@carespace-icons/users/faceNeutral"
import { useEffect, useState } from "react"
import { getSurveyByIdList, getSurveyResult } from "@stores/survey/survey"
import { ISurveyResult, ISurveyResultPaginated, ISurveyResultQuestionList } from "@stores/interfaces"
import SurveySummaryContent from "./SurveySummaryContent"
import { FaceWink } from "@carespace-icons/users/faceWink"

export default function AiAssistantSurveySummary() {
  const [activePanelKey, setActivePanelKey] = useState<string | string[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true)
  const [surveyIdData, setSurveyIdData] = useState('')
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const user = useTypedSelector((state) => state.user)
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)
  const surveyResult = useTypedSelector((state) => state.survey.survey.surveyResult)
  const [surveySessionData, setSurveySessionData] = useState<ISurveyResult[]>([])
  const [currentCount, setCurrentCount] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(10)
  const [selectedCollapse, setSelectedCollapse] = useState<string[] | string | undefined>();
  const stateId = useTypedSelector(state => state.aiAssistant.aiAssistant.stateId)
  const surveyStateId = useTypedSelector(state => state.aiAssistant.aiAssistant.surveyStateId)
  const isReportModal = useTypedSelector(state => state.aiAssistant.aiAssistant.isReportModal)

  const getPainLevel = (painLevel: number, question: ISurveyResultQuestionList) => {
    switch (painLevel) {
      case 1:
        return (
          <div className='!flex gap-2'>
            <FaceFrown color={'stroke-rose-700'} width={20} height={20} />
            <span className='text-sm font-semibold text-rose-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 2:
        return (
          <div className='!flex gap-2'>
            <FaceFrown color={'stroke-rose-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-rose-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 3:
        return (
          <div className='!flex gap-2'>
            <FaceSad color={'stroke-orange-400'} width={20} height={20} />
            <span className='text-sm font-semibold text-orange-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 4:
        return (
          <div className='!flex gap-2'>
            <FaceSad color={'stroke-orange-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-orange-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 5:
        return (
          <div className='!flex gap-2'>
            <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} />
            <span className='text-sm font-semibold text-yellow-400'>{question?.selectedAnswer}</span>
          </div>
        );
      case 6:
        return (
          <div className='!flex gap-2'>
            <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-yellow-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 7:
        return (
          <div className='!flex gap-2'>
            <FaceSmile color={'stroke-green-300'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-300'>{question?.selectedAnswer}</span>
          </div>
        );
      case 8:
        return (
          <div className='!flex gap-2'>
            <FaceSmile color={'stroke-green-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-500'>{question?.selectedAnswer}</span>
          </div>
        );
      case 9:
        return (
          <div className='!flex gap-2'>
            <FaceWink color={'stroke-green-700'} width={20} height={20} />
            <span className='text-sm font-semibold text-green-700'>{question?.selectedAnswer}</span>
          </div>
        );
      case 10:
        return (
          <div className='!flex gap-2'>
            <FaceHappy color={'stroke-cyan-500'} width={20} height={20} />
            <span className='text-sm font-semibold text-cyan-500'>{question?.selectedAnswer}</span>
          </div>
        );
      default:
        return null;
    }
  }
  useEffect(() => {
    fetchSurveyData(1)
  }, [user, selectedUser])

  const fetchSurveyData = async (page: number) => {
    const payload = {
      userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
      limit: 10,
      page: page,
    }
    await dispatch(getSurveyResult(payload)).then(() => setIsLoading(false))
  }


  useEffect(() => {
    handleClick(surveyStateId)
    handlePanelChange(surveyStateId)
  }, [stateId, surveyStateId])

  const handlePanelChange = (key: string | string[]) => {
    setActivePanelKey(key);
  };

  const handleClick = (surveyId: string) => {
    if (activePanelKey !== surveyId) {
      setSurveyIdData(surveyId);
      setIsLoading(true);
      fetchSurveyByIdData(surveyId, 1)
    } else {
      setActivePanelKey('');
    }
  }

  const fetchSurveyByIdData = async (surveyId: string, page: number) => {
    const payload = {
      surveyId: surveyId,
      limit: 10,
      page: page,
    };
    if(surveyId){
    const getSurveyByIdData!: { payload: ISurveyResultPaginated } = await dispatch(getSurveyByIdList(payload));
    setSurveySessionData(getSurveyByIdData?.payload?.data || []);
    setCurrentCount(getSurveyByIdData?.payload?.pagination?.currentPage || 0);
    setTotalCount(getSurveyByIdData?.payload?.pagination?.totalCount || 0);
    }
    setIsLoading(false)
  };

  const pageHandle = (page: number) => {
    setCurrentCount(page);
    fetchSurveyByIdData(surveyIdData, 1)
  }


  return (
    <div className="pl-5 pr-5">
      <>
        {surveyResult?.data?.length === 0 && isLoading ? <Spin className="flex items-center justify-center p-2" tip="Loading" size="large" /> :
          (<>
            {surveyResult?.data?.length === 0 ? (
              <Empty
                description={t('Admin.data.survey.noResult')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <>
                {!isReportModal && <div className="flex justify-end gap-2">
                  <AddToReports />
                </div>}
                {
                  surveyResult?.data?.length! > 0 ?
                    <>
                      <div className='collapse-wrapper'>
                        {
                          surveyResult?.data?.map((item: ISurveyResult, index: number) => {
                            return (
                              <SurveySummaryContent handlePanelChange={handlePanelChange} handleClick={handleClick} item={item} activePanelKey={activePanelKey} index={index} selectedCollapse={selectedCollapse}
                                setSelectedCollapse={setSelectedCollapse} isLoading={isLoading} surveySessionData={surveySessionData} getPainLevel={getPainLevel} setSurveyIdData={setSurveyIdData}
                                totalCount={totalCount} currentCount={currentCount} pageHandle={pageHandle} fetchSurveyByIdData={fetchSurveyByIdData} surveyIdData={surveyIdData} />
                            )
                          })
                        }
                      </div>
                      <Pagination
                        current={surveyResult?.pagination?.currentPage}
                        showSizeChanger={false}
                        onChange={fetchSurveyData}
                        total={surveyResult?.pagination?.totalCount}
                      />
                    </>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-gray-300'>No Results Found</span>} />
                }
              </>
            )}</>)
        }
      </>
    </div>
  )
}