import { Button, Spin } from 'antd';
import checkLogo from './CompletionLogo.json';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setActiveStep, setVideoRecordState } from '@stores/onBoard/onBoard';
import { ArrowNarrowRight } from '@carespace-icons/arrows/arrowNarrowRight';
import { useEffect, useState } from 'react';
import { getCustomRomSessionById } from '@stores/rom/customRom';
import { ICustonRomSessionPaginated } from '@stores/interfaces';
import CustomSummarySelectionContent from '@pages/AiAssistantCustomRomSummary/CustomSummarySelectionContent';
import './CompletionScreen.css';
import { closeSession } from '@stores/rom/main';
interface CompletionScreenProps {
	savedVoice: string;
}

export const CompletionScreen = ({ savedVoice }: CompletionScreenProps) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [isLoading, setLoading] = useState(true);
	const { session } = useTypedSelector(state => state.rom.main);

	const handleClick = async () => {
		dispatch(setActiveStep(9));
		dispatch(setVideoRecordState(false));
	};
	const customRomSession = useTypedSelector(state => state.rom.main.session);

	const [customRomSessionExercise, setCustomRomSessionExercise] = useState<ICustonRomSessionPaginated | null>();
	
	const fetchSessionData = async (sessionId: string, page: number) => {
		await dispatch(closeSession(customRomSession?.id!));
		const data = await dispatch(
			getCustomRomSessionById({ customRomId: sessionId, page: page }),
		);
		setCustomRomSessionExercise(data?.payload?.data[0]);
	};
	
		useEffect(() => {
			if (session?.id) {
				fetchSessionData(session?.romProgramId, 1);
			}
			setLoading(false);
		}, []);

	return (
		<div className="completion-screen-omnirom-wrapper">
			<div className="completion-screen-omnirom-content">
				<div className="completion-screen-omnirom-logo-container">
					<Lottie animationData={checkLogo} loop={false} autoplay={true} />
				</div>
				<p className="completion-screen-omnirom-title">
					{t('Admin.data.rehab.rehabPostAssessment.congratulations')}
				</p>
				<p className="completion-screen-omnirom-description">
					{t('Admin.data.rehab.rehabPostAssessment.description')}
				</p>
				{isLoading ? (
					<Spin
						className="completion-screen-omnirom-spinner"
						tip="Loading"
						size="large"
					/>
				) : (
					<div className="completion-screen-omnirom-summary">
						{customRomSessionExercise && (
							<CustomSummarySelectionContent
								item={customRomSessionExercise?.romPatientResults}
							/>
						)}
					</div>
				)}

				<Button
					className="completion-screen-omnirom-next-button"
					onClick={() => handleClick()}>
					{t('Patient.data.onboard.next')}
					<ArrowNarrowRight width={20} height={20} />
				</Button>
			</div>
		</div>
	);
};
