import { useEffect, useRef, useState, useTransition } from 'react';
import { UnReportedInjuries } from './UnReportedInjuries';
import { IProgramPreAssesment, IRehabPreAssesment } from '@stores/interfaces';
import { useTypedSelector } from '@stores/index';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import checkLogo from '@atoms/ARehabPreAssesment/VoiceRecordingLogo.json';
import { GestureControl } from '@atoms/ARehabPreAssesment/GestureControl';
import { Rate } from '@atoms/ARehabPreAssesment/Rate';
import { OverallCondition } from '@atoms/ARehabPreAssesment/OverallCondition';
import { ImportantNotice } from '@atoms/ARehabPreAssesment/ImportantNotice';
import GetStarted from '@atoms/ARehabPreAssesment/GetStarted';
import { useTranslation } from 'react-i18next';

interface PreAssesmentProps {
	setGestureEnabled: (isGestureEnabled: boolean) => void;
	setActiveStep: (activeStep: number) => void;
	savedVoice: string;
	activeStep: number;
	setSavedVoice: (savedVoice: string) => void;
}

export const PreAssesment = (props: PreAssesmentProps) => {
	const {
		activeStep,
		setActiveStep,
		savedVoice,
		setGestureEnabled,
		setSavedVoice,
	} = props;
	const [painLevel, setPainLevel] = useState(-1);
	const [overallCondition, setOverallCondition] = useState<string>('');
	const { currentExercise } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const micRef = useRef<LottieRefCurrentProps>(null);
	const { t } = useTranslation();
	const userPreassesmentData: IProgramPreAssesment = {
		programId: currentExercise?.programId!,
		overallCondition: overallCondition,
		painLevel: painLevel,
	};

	useEffect(() => {
		if (savedVoice.toLowerCase().includes('back'))
			setActiveStep(activeStep - 1);
		micRef.current?.play();
		setTimeout(() => {
			micRef.current?.stop();
		}, 2000);
	}, [savedVoice]);

	return (
		<div className="h-full flex flex-col items-center justify-start md:-mt-24 lg:-mt-12 xl:mt-0 md:scale-[60%] lg:scale-[70%] xl:scale-[90%] 2xl:scale-[100%]">
			{activeStep === 1 && (
				<Rate
					savedVoice={savedVoice}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setPainLevel={setPainLevel}
				/>
			)}
			{activeStep === 2 && painLevel < 8 && (
				<OverallCondition
					savedVoice={savedVoice}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setOverallCondition={setOverallCondition}
					painLevel={painLevel}
					setSavedVoice={setSavedVoice}
				/>
			)}
			{activeStep === 2 && painLevel >= 8 && (
				<ImportantNotice
					savedVoice={savedVoice}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setPainLevel={setPainLevel}
					painLevel={painLevel}
					setSavedVoice={setSavedVoice}
				/>
			)}
			{activeStep === 3 && (
				<UnReportedInjuries
					savedVoice={savedVoice}
					userPreassesmentData={userPreassesmentData}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setSavedVoice={setSavedVoice}
				/>
			)}
			{activeStep === 4 && (
				<GetStarted
					savedVoice={savedVoice}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setSavedVoice={setSavedVoice}
				/>
			)}
			{activeStep === 5 && (
				<GestureControl
					savedVoice={savedVoice}
					setGestureEnabled={setGestureEnabled}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					setSavedVoice={setSavedVoice}
				/>
			)}
			{activeStep != 4 && activeStep != 6 && (
				<div className="flex flex-col justify-center items-center">
					<div className="flex justify-end items-center">
						<div
							className="flex items-center justify-center align-middle"
							style={{ height: '50px', width: '150px' }}>
							<Lottie
								lottieRef={micRef}
								animationData={checkLogo}
								loop={false}
								autoplay={false}
							/>
						</div>
						<img src="/images/microphone.svg" />
					</div>
					{activeStep === 1 ? (
						<div>
							<p className="max-w-[360px] text-center font-semibold text-lg text-white">
								{t('Admin.data.rehab.rehabPreAssessment.sayNumber')}
							</p>
							<p className="font-semibold text-lg text-white">
								{t('Admin.data.rehab.rehabPreAssessment.speakDesired')}
							</p>
						</div>
					) : (
						<p className="font-semibold text-lg text-white">
							{t('Admin.data.rehab.rehabPreAssessment.speakDesired')}
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default PreAssesment;
