export const imageMapping: Record<number, string> = {
  1: '/assets/painManagement.svg',
  2: '/assets/improveActivities.svg',
  3: '/assets/restoreRom.svg',
  4: '/assets/prePost.svg',
  5: '/assets/improveCardivasal.svg',
  6: '/assets/performanceImprovements.svg',
  7: '/assets/improveActivities.svg',
  8: '/assets/fallPrevention.svg',
  9: '/assets/improveActivities.svg',
  10: '/assets/improveApproval.svg'
};

export const newImageMapping: Record<number, string> = {
  1: '/assets/prePost.svg',
  2: '/assets/improveActivities.svg',
  3: '/assets/performanceImprovements.svg',
  4: '/assets/painManagement.svg'
};
