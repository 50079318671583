import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { EvaluationItem } from '@stores/interfaces';
import { addReportId, deleteReportId } from '@stores/report/reports';
import { Checkbox } from 'antd';

interface ActivityReportProps {
  item: EvaluationItem;
}

const ActivityReport = (props: ActivityReportProps) => {
  const { item } = props;
  const reportIds = useTypedSelector(state => state.reports.reportIds);
  const dispatch = useTypedDispatch();
  
  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <Checkbox
        checked={
          reportIds?.evaluationSessionsIds?.includes(item?.data?.activityStream?.evaluationSessionId!) ||
          reportIds?.programSessionsIds?.includes(item?.data?.activityStream?.programSessionId!) ||
          reportIds?.romSessionsIds?.includes(item?.data?.activityStream?.romSessionId!) ||
          reportIds?.surveyResultIds?.includes(item?.data?.activityStream?.surveyResultId!)
        }
        onChange={(e) => {
          const sessionType =
            item.data?.activityStream?.evaluationSessionId ? "evaluationSessionsIds" :
            item.data?.activityStream?.programSessionId ? "programSessionsIds" :
            item.data?.activityStream?.surveyResultId ? "surveyResultIds" :
            item.data?.activityStream?.romSessionId ? "romSessionsIds" :
            null;

          const sessionId =
            item.data?.activityStream?.evaluationSessionId ||
            item.data?.activityStream?.programSessionId ||
            item.data?.activityStream?.surveyResultId ||
            item.data?.activityStream?.romSessionId;

          if (sessionType && sessionId) {
            if (e.target.checked) {
              dispatch(addReportId({ type: sessionType, id: sessionId }));
            } else {
              dispatch(deleteReportId({ type: sessionType, id: sessionId }));
            }
          }
        }}
      />
    </div>
  );
};

export default ActivityReport;
