export const templateGroup = [
	{
		"id": 5,
		"name": "Upper Limb",
		"key": "upperBody",
		"frontend": {
			"styles": "{ right: '60px', top: '10px' }"
		}
	},
	{
		"id": 7,
		"name": "Upper Limb Right",
		"key": "upperRight",
		"frontend": {
			"styles": "{ left: '-400px', top: '140px' }"
		}
	},
	{
		"id": 6,
		"name": "Upper Limb Left",
		"key": "upperLeft",
		"frontend": {
			"styles": "{ right: '-160px', top: '140px' }"
		}
	},
	{
		"id": 2,
		"name": "Lower Limb",
		"key": "lowerBody",
		"frontend": {
			"styles": "{ right: '50px', bottom: '-110px' }"
		}
	},
	{
		"id": 4,
		"name": "Lower Limb Right",
		"key": "lowerRight",
		"frontend": {
			"styles": "{ left: '-400px', bottom: '40px' }"
		}
	},
	{
		"id": 3,
		"name": "Lower Limb Left",
		"key": "lowerLeft",
		"frontend": {
			"styles": "{ right: '-160px', bottom: '40px' }"
		}
	},
	{
		"id": 1,
		"name": "Full Scan",
		"key": "full",
		"frontend": {
			"styles": "{ right: '-270px', bottom: '200px' }"
		}
	},
	{
		"id": 9,
		"name": "Baseline Scan",
		"key": "full",
		"frontend": {
			"styles": "{ left: '-540px', bottom: '200px' }"
		}
	}
];

export const startScanGroup = [
	{
		id: 1,
		key: 'full',
		name: 'Full Scan',
	},
	{
		id: 2,
		key: 'lowerBody',
		name: 'Lower Limb',
	},
	{
		id: 3,
		key: 'lowerLeft',
		name: 'Lower Limb Left',
	},
	{
		id: 4,
		key: 'lowerRight',
		name: 'Lower Limb Right',
	},
	{
		id: 5,
		key: 'upperBody',
		name: 'Upper Limb',
	},
	{
		id: 6,
		key: 'upperLeft',
		name: 'Upper Limb Left',
	},
	{
		id: 7,
		key: 'upperRight',
		name: 'Upper Limb Right',
	},
	{
		id: 8,
		key: 'spine',
		name: 'Spine',
	},
	{
		id: 9,
		key: 'full',
		name: 'Baseline Scan',
	},
	{
		id: 10,
		key: 'neck',
		name: 'Neck',
	},
	{
		id: 11,
		key: 'rightShoulder',
		name: 'Right Shoulder',
	},
	{
		id: 12,
		key: 'rightElbow',
		name: 'Right Elbow',
	},
	{
		id: 13,
		key: 'rightWrist',
		name: 'Right Wrist',
	},
	{
		id: 14,
		key: 'leftShoulder',
		name: 'Left Shoulder',
	},
	{
		id: 15,
		key: 'leftElbow',
		name: 'Left Elbow',
	},
	{
		id: 16,
		key: 'leftWrist',
		name: 'Left Wrist',
	},
	{
		id: 17,
		key: 'rightHip',
		name: 'Right Hip',
	},
	{
		id: 18,
		key: 'rightKnee',
		name: 'Right Knee',
	},
	{
		id: 19,
		key: 'leftHip',
		name: 'Left Hip',
	},
	{
		id: 20,
		key: 'leftKnee',
		name: 'Left Knee',
	},
];

export const mainTemplateGroup = [
	{
		"id": 1,
		"name": "Full Scan",
		"key": "full",
		"frontend": {
			"styles": "{ right: '645px', top: '15px' }"
		}
	},
	{
		"id": 9,
		"name": "Baseline Scan",
		"key": "full",
		"frontend": {
			"styles": "{ right: '465px', top: '15px' }"
		}
	}
]