import React from 'react'
import { Button } from 'antd'
import { GrPrevious, GrNext } from 'react-icons/gr'

interface INavegationButtonsProps {
	nextHide: boolean
	onClickNext: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onClickPrev: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	prevHide: boolean
}

function NavigationButtons(props: INavegationButtonsProps) {
	const { onClickPrev, onClickNext, prevHide, nextHide } = props

	return (
		<>
			{prevHide === false && (
				<Button
					className="btn-prev"
					type="primary"
					shape="circle"
					icon={<GrPrevious size="32px" />}
					onClick={onClickPrev}
				/>
			)}
			{nextHide === false && (
				<Button
					className="btn-next"
					type="primary"
					shape="circle"
					icon={<GrNext size="32px" />}
					onClick={onClickNext}
				/>
			)}
		</>
	)
}

export default NavigationButtons
