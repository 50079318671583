import { COMMON_SEARCHES_DATA } from '@pages/AiAssistantSearchBar/AI_MENU_DATA';
import { SearchItem } from '@stores/interfaces';
import './style.css';
import { Button } from 'antd';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { useTypedDispatch } from '@stores/index';

export default function AiAssistantNotFoundComponent() {
  const dispatch = useTypedDispatch()
  
  const handleDropdown = (value: SearchItem) => {
    dispatch(setActiveTab(value.key))
  }

  return (
    <div className='create-report-container'>
      <div className='common-searches-main-container'>
        <p className='common-searches-container'>
          {COMMON_SEARCHES_DATA.data.map((item, index) => (
            <div key={index} className='common-div-css'>
              {item.list.map((value, subIndex) => (
                <Button key={subIndex} onClick={() => handleDropdown(value)}>{value?.name}</Button>
              ))}
            </div>
          ))}
        </p>
      </div>
    </div>
  )
}
