import { ChevronRight } from '@carespace-icons/arrows/chevronRight';
import VideoPlayer from '@organisms/OProgram/CompletionScreen/VideoPlayer';
import { UploadBar } from '@organisms/OProgram/UploadBar';
import { useTypedSelector } from '@stores/index';
import { IProgramExerciseUpload } from '@stores/interfaces';
import { Avatar, Popover, Progress } from 'antd';

export const ProgramUploadProgress = ({
	progressData,
}: {
	progressData: IProgramExerciseUpload;
}) => {
	return (
		<Popover
			title={progressData.program?.name}
			content={
				<div className="flex flex-col gap-3">
					{progressData.exercises.map((item, index) => {
						return (
							<div className="w-80 h-16 flex items-center justify-between rounded-xl gap-2">
								<span className="flex items-center">
									<VideoPlayer
										className="w-20 h-14 p-1 m-1 rounded-lg"
										video={item?.video}
										controls={false}
									/>
									<p className="font-semibold">{item?.name?.toUpperCase()}</p>
								</span>
								<Progress
									type="circle"
									percent={progressData.progress[index]}
									strokeColor="#08A045"
									width={50}
									format={percent => (
										<span style={{ color: '#08A045', fontWeight: 'bold' }}>
											{percent}%
										</span>
									)}
								/>
							</div>
						);
					})}
				</div>
			}
			placement="right">
			<div className="flex justify-around items-center bg-black/35 h-16 w-full rounded-2xl cursor-pointer mb-4">
				<div className="flex items-center h-12 p-2 w-[100%]">
					<Avatar
						style={{
							backgroundColor:
								progressData?.user?.profile?.avatarColor || '#1890FF',
							color: '#FFF',
							fontSize: '25px',
							display: 'inline-flex',
							alignItems: 'center',
						}}
						alt="avatar"
						size={45}>
						{progressData?.user?.profile?.fullName?.charAt(0).toUpperCase()}
					</Avatar>
					<span className=" ml-3">
						{progressData?.user?.profile?.fullName}
						<UploadBar
							uploadProgress={Math.ceil(
								progressData?.progress?.reduce(
									(partialSum, a) => partialSum + a,
									0,
								) / progressData?.progress.length,
							)}
						/>
					</span>
				</div>
				<ChevronRight color="stroke-white mr-3" />
			</div>
		</Popover>
	);
};
