import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { FunctionalGoalsProps, IWholeDayActivity, TProfileData } from "@stores/interfaces"
import strapi from "@strapi"
import { message } from "antd";
import axios from "axios"
import qs from 'qs';

const initialState = {
  onBoardCompletion: false,
  videoRecordState: false,
  savedActivityValues: null,
  activeStep: 0,
  profileDetails: null,
  getSuggestedProgram: null,
  painStatusButton: '',
  existingPatientData: false,
  savedFunctionalGoals: [],
  wellCheckNess: null,
  onBoardRomCompletion: false,
  selectedFunctionalGoalTitle : '',
  displayedFunctionalGoalTitle : '',
  preExistingConditionAgree : null
}

export const getwholeDayActivity = createAsyncThunk(
  'getwholeDayActivity',
  async (params: { userId: string }) => {
    const { userId } = params
    const { data } = await axios.get(`/users/daily-activity-distribution/${userId}`)
    return data
  }
)

export const wellCheckNess = createAsyncThunk(
  'wellCheckNess',
  async (params: { userId: string, isWellCheckNess: boolean }) => {
    const { userId, isWellCheckNess } = params
    const { data } = await axios.patch(`/users/${userId}/wellness-check`, { isWellnessCheck: isWellCheckNess })
    return data
  }
)

export const patchwholeDayActivity = createAsyncThunk(
  'patchwholeDayActivity',
  async (params: { userId: string, body: IWholeDayActivity }) => {
    const { userId, body } = params
    const { data } = await axios.patch(`/users/daily-activity-distribution/${userId}`, body)
    return data
  }
)

export const wholeDayActivity = createAsyncThunk(
  'wholeDayActivity',
  async (params: { userId: string, body: IWholeDayActivity }) => {
    const { userId, body } = params
    const { data } = await axios.post(`/users/daily-activity-distribution/${userId}`, body)
    return data
  }
)

export const saveProfileDetails = createAsyncThunk(
  'saveProfileDetails',
  async ({ id, payload }: { id: string; payload: TProfileData }): Promise<any> => {
    try {
      const response = await axios.patch(`/users/${id}`, payload);
      return response.data;
    } catch (error: any) {
      error?.response?.data?.statusCode === 409 ? message.error(error?.response?.data?.message) : message.error('Something Went Wrong !')
    }
  },
);

export const getSuggestedProgram = createAsyncThunk(
  'getSuggestedProgram',
  async ({ current, functionalGoalsIds }: { current: number; functionalGoalsIds: number[] }): Promise<any> => {
    const query = {
      pagination: {
        page: current,
        pageSize: 10,
      },
      filters: {
        functional_goals: {
          $eq: functionalGoalsIds,
        },
      },
      populate: {
        thumbnail: 'url',
        description: 'url',
        functional_goals: 'url',
        exercises: {
          populate: {
            strapiExerciseId: {
              populate: {
                exercise_video: {
                  fields: ['url'],
                },
                exercise_image: {
                  fields: ['url'],
                },
              },
            },
          },
        },
      },
    };

    const queryString = qs.stringify(query, { encode: false });

    const response = await strapi.get(`/program-templates?${queryString}`);
    return response.data;
  },
);

export const savedFunctionalGoal = createAsyncThunk(
  'savedFunctionalGoal',
  async ({ userId, payload }: { userId: string; payload: FunctionalGoalsProps }): Promise<any> => {
    const response = await axios.post(`/users/functional-goals/${userId}`, payload);
    return response.data;
  },
);

export const updateFunctionalGoal = createAsyncThunk(
  'updateFunctionalGoal',
  async ({ userId, payload }: { userId: string; payload: FunctionalGoalsProps }): Promise<any> => {
    const response = await axios.patch(`/users/functional-goals/${userId}`, payload);
    return response.data;
  },
);

export const updatePreExistingConditionAgree = createAsyncThunk(
  'updatePreExistingConditionAgree',
  async (userId: string) => {
    const { data } = await axios.patch(`/users/${userId}/pre-existing-conditions/agree`)
    return data
  }
)

const onBoard = createSlice({
  name: 'onBoard',
  initialState,
  reducers: {
    setVideoRecordState: (state, action) => {
      state.videoRecordState = action.payload
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
    setOnBoardCompletion: (state, action) => {
      state.onBoardCompletion = action.payload
    },
    setOnBoardRomCompletion: (state, action) => {
      state.onBoardRomCompletion = action.payload
    },
    setPainStatusButton: (state, action) => {
      state.painStatusButton = action.payload
    },
    setExistingPatientData: (state, action) => {
      state.existingPatientData = action.payload
    },
    setSelectedFunctionalGoalTitle: (state, action) => {
      state.selectedFunctionalGoalTitle = action.payload
    },
    setDisplayedFunctionalGoalTitle: (state, action) => {
      state.displayedFunctionalGoalTitle = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(wholeDayActivity.fulfilled, (state, action) => {
      state.savedActivityValues = action.payload
    });
    builder.addCase(getwholeDayActivity.fulfilled, (state, action) => {
      state.savedActivityValues = action.payload
    });
    builder.addCase(wellCheckNess.fulfilled, (state, action) => {
      state.wellCheckNess = action.payload.isWellnessCheck
    });
    builder.addCase(wellCheckNess.rejected, (state, action) => { });
    builder.addCase(patchwholeDayActivity.fulfilled, (state, action) => {
      state.savedActivityValues = action.payload
    });

    builder.addCase(saveProfileDetails.fulfilled, (state, action) => {
      state.profileDetails = action.payload;
    });
    builder.addCase(saveProfileDetails.rejected, (state, action) => { });

    builder.addCase(getSuggestedProgram.fulfilled, (state, action) => {
      state.getSuggestedProgram = action.payload;
    });
    builder.addCase(getSuggestedProgram.rejected, (state, action) => { });

    builder.addCase(savedFunctionalGoal.fulfilled, (state, action) => {
      state.savedFunctionalGoals = action.payload;
    });
    builder.addCase(savedFunctionalGoal.rejected, (state, action) => { });

    builder.addCase(updateFunctionalGoal.fulfilled, (state, action) => {
      state.savedFunctionalGoals = action.payload;
    });
    builder.addCase(updateFunctionalGoal.rejected, (state, action) => { });

    builder.addCase(updatePreExistingConditionAgree.fulfilled, (state, action) => {
      state.preExistingConditionAgree = action.payload;
    });
    builder.addCase(updatePreExistingConditionAgree.rejected, (state, action) => { });
  },
})


export const { setVideoRecordState, setExistingPatientData, setActiveStep, setOnBoardCompletion, setOnBoardRomCompletion, setPainStatusButton, setSelectedFunctionalGoalTitle, setDisplayedFunctionalGoalTitle } = onBoard.actions;
export default onBoard.reducer;