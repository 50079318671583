import { CreateSurveyModalProps, QuestionListPayload } from "@stores/interfaces"
import { Modal, Tabs } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTypedSelector } from "@stores/index"
import './style.css'
import { Edit05 } from "@carespace-icons/general/edit05"
import { AddSurveyPopup } from "./AddSurveyPopup"
import { TemplateSurvey } from "./TemplateSurvey"
import "./../style.less"
import { useLocation } from "react-router-dom"

export const CreateSurveyModal = (props: CreateSurveyModalProps) => {
  const {
    surveyEdit, setSurveyEdit,
    onOk, isEdit,
    isVisible,
    onCancel,
    setRefresh,
    refresh,
    fetchData,
    setPreviousQuestion,
    fetchHomeData,
    selectedSurvey,
    setSelectedSurvey
  } = props
  const { t } = useTranslation()
  const { state } = useLocation()
  const activeTab = useTypedSelector(state => state.aiAssistant.aiAssistant.activeTab)
  const [activeKey, setActiveKey] = useState<string>('1')
  const [isModalVisible, setModalVisible] = useState(false)
  const surveyTemplate = useTypedSelector(state => state.survey.survey.surveyTemplate);
  const [templateSearchValue, setTemplateSearchValue] = useState("")
  const [cancelTriggered, setCancelTriggered] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState<string>(selectedSurvey?.title!)
  const [surveyDescription, setSurveyDescription] = useState<string>(selectedSurvey?.description!);
  const [surveyInstructions, setSurveyInstructions] = useState<string>(selectedSurvey?.instructions!);
  const [questionList, setQuestionList] = useState<QuestionListPayload[]>([])
  const [surveyResultFeedback, setSurveyResultFeedback] = useState<string>(selectedSurvey?.resultFeedback! || '');
  const [imgFile, setImgFile] = useState<File[] | undefined>([])
  const handleCancel = () => {
    setImgFile([])
    setTemplateSearchValue("")
    setModalVisible(false)
    activeKey == '2' && (surveyTitle || surveyDescription || surveyInstructions || questionList.length > 0 || surveyResultFeedback) ? setCancelTriggered(true) : onCancel()
  };

  useEffect(() => {
    activeTab === "assignSurvey" ? setActiveKey("1") : setActiveKey("2")
  }, [isVisible])

  const handleOk = () => {
    setModalVisible(false)
    setTemplateSearchValue("")
    setSelectedSurvey(null)
    setModalVisible(false)
    onCancel()
    setRefresh(!refresh)
    setSurveyEdit(false)
  }

  useEffect(() => {
    if (cancelTriggered) {
      setCancelTriggered(false);
    }
  }, [cancelTriggered]);

  useEffect(() => {
    setTemplateSearchValue("")
  }, [activeKey])
  useEffect(() => {
    activeTab === "assignSurvey" ? setActiveKey("1") : setActiveKey("2")
  }, [])
  useEffect(() => {
    if (state?.isGenerate) {
      setActiveKey("1")
    }
  }, [state])

  useEffect(() => {
    if (isEdit) {
      setActiveKey("2")
    }
  }, [isEdit])
  const handleTabChange = (value: string) => {
    if (value === "1" && (surveyTitle || surveyDescription || surveyInstructions || questionList.length > 0 || surveyResultFeedback)) {
      Modal.confirm({
        title: t('Admin.data.survey.discardChangesTitle'),
        content: t('Admin.data.survey.discardChangesContent'),
        onOk: () => {
          setActiveKey(value)
          setSelectedSurvey([])
          setImgFile([])
        },
      });
    } else {
      setActiveKey(value)
    }
  }
  return <Modal
    title={
      <div className='modal-title'>
        <span>
          <Edit05 width={20} height={20} color="stroke-primary-600" />
        </span>
        <span className="titleStyle">{surveyEdit ? t('Admin.data.survey.editSurvey') : t('Admin.data.survey.create')}</span>
      </div>}
    open={isVisible}
    style={{ top: 20 }}
    onCancel={handleCancel}
    footer={false}
    width={974}
    className='select-none create-survey-modal'
  >
    {!surveyEdit ?
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={(value) => { handleTabChange(value) }}
        items={[
          {
            label: t("Admin.data.survey.titleTemplate"),
            key: '1',
            children: <TemplateSurvey setActiveKey={setActiveKey} isModalVisible={isModalVisible} searchValue={templateSearchValue} setSearchValue={setTemplateSearchValue} fetchData={fetchData} surveyTemplate={surveyTemplate} selectedSurvey={selectedSurvey!} setSelectedSurvey={setSelectedSurvey} setPreviousQuestion={setPreviousQuestion} setModalVisible={setModalVisible} />
          },
          {
            label: t("Admin.data.survey.titleCreateSurvey"),
            key: '2',
            children: <AddSurveyPopup imgFile={imgFile} setImgFile={setImgFile}
              surveyTitle={surveyTitle} setSurveyTitle={setSurveyTitle} surveyResultFeedback={surveyResultFeedback} setSurveyResultFeedback={setSurveyResultFeedback}
              surveyDescription={surveyDescription} setSurveyDescription={setSurveyDescription} surveyInstructions={surveyInstructions} setSurveyInstructions={setSurveyInstructions} questionList={questionList} setQuestionList={setQuestionList}
              onOk={() => {
                handleOk();
                setActiveKey(activeTab === "assignSurvey" ? "1" : "2");
              }}
              refresh={refresh}
              setRefresh={setRefresh}
              isVisible={isModalVisible}
              onCancel={() => {
                onCancel()
              }}
              survey={selectedSurvey}
              isEdit={isEdit}
              cancelTriggered={cancelTriggered}
              setSelectedSurvey={setSelectedSurvey}
            />
          },
        ]}
      /> : <AddSurveyPopup setImgFile={setImgFile} imgFile={imgFile}
        surveyTitle={surveyTitle} setSurveyTitle={setSurveyTitle} surveyResultFeedback={surveyResultFeedback} setSurveyResultFeedback={setSurveyResultFeedback}
        surveyDescription={surveyDescription} setSurveyDescription={setSurveyDescription} surveyInstructions={surveyInstructions} setSurveyInstructions={setSurveyInstructions} questionList={questionList} setQuestionList={setQuestionList}
        onOk={() => {
          handleOk();
          setActiveKey(activeTab === "assignSurvey" ? "1" : "2");
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        isVisible={isModalVisible}
        onCancel={() => {
          onCancel()
        }}
        survey={selectedSurvey}
        isEdit={isEdit}
        cancelTriggered={cancelTriggered}
        setSelectedSurvey={setSelectedSurvey}
      />}
    {
      isModalVisible && <AddSurveyPopup
        surveyTitle={surveyTitle} setSurveyTitle={setSurveyTitle} surveyResultFeedback={surveyResultFeedback} setSurveyResultFeedback={setSurveyResultFeedback}
        surveyDescription={surveyDescription} setSurveyDescription={setSurveyDescription} surveyInstructions={surveyInstructions} setSurveyInstructions={setSurveyInstructions} questionList={questionList} setQuestionList={setQuestionList}
        onOk={() => {
          setModalVisible(false)
          handleCancel()
          fetchData(1, "")
          setRefresh(!refresh)
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        isVisible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        survey={selectedSurvey}
        cancelTriggered={cancelTriggered}
        setSelectedSurvey={setSelectedSurvey}
      />
    }
  </Modal>
}