import { router } from '@routers/routers';
import { Result } from 'antd/lib';
import { SmileOutlined } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
import { LoginCallback } from 'src/auth';

function Public() {
	return (
		<Routes>
			<Route path={router.LOGINCALLBACK} element={<LoginCallback />} />
			<Route
				path={router.TEST}
				element={
					<Result icon={<SmileOutlined />} title="Great, Develop updated! #1" />
				}
			/>
		</Routes>
	);
}

export default Public;
