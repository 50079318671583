import { Tabs } from "antd"
import { Plus } from "@carespace-icons/general/plus"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { MyExerciseData } from "./MyExerciseData"
import { CarespaceLibraryData } from "./CarespaceLibraryData"
import { IRomProgramExercise } from "@stores/interfaces"

interface LibraryDataProps {
  setSelectedExercises: (selectedExercises: IRomProgramExercise[]) => void
  selectedExercises: IRomProgramExercise[]
  setModalVisible?: (value: boolean) => void
  onOk?: () => void
  subActiveKey: string
  setSubActiveKey?: (value: string) => void
}

export const LibraryData = ({ selectedExercises, setSelectedExercises, setModalVisible, onOk, subActiveKey, setSubActiveKey }: LibraryDataProps) => {

  const { t } = useTranslation()
  const [isGrid, setGrid] = useState(false)

  return <Tabs
    type="card"
    activeKey={subActiveKey}
    onChange={(value) => setSubActiveKey && setSubActiveKey(value)}
    className='mainTabs select-none p-4 bg-gray-100'
    tabBarStyle={{ margin: 0 }}
    items={[
      {
        label: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.carespaceLibrary'),
        key: '1',
        children: (
          <div className='rounded-none p-4'>
            <CarespaceLibraryData setGrid={setGrid} isGrid={isGrid} selectedExercises={selectedExercises} setSelectedExercises={setSelectedExercises} />
            {
              selectedExercises.length !== 0 &&
              <div className="w-full cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2" onClick={() => onOk ? onOk() : setModalVisible!(true)} >
                <Plus />
                <p>{t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.add')} {selectedExercises.length} {selectedExercises.length === 1 ? t('Admin.data.menu.aiAssistant.aiAssistantPrograms.exercise') : t('Admin.data.menu.aiAssistant.aiAssistantPrograms.exercises')}</p>
              </div>
            }
          </div>
        )
      },
      {
        label: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.myLibrary'),
        key: '2',
        children: (
          <div className='rounded-none p-4'>
            <MyExerciseData setGrid={setGrid} isGrid={isGrid} selectedExercises={selectedExercises} setSelectedExercises={setSelectedExercises} />
            {
              selectedExercises.length !== 0 &&
              <div className="w-full mt-2 cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2" onClick={() => onOk ? onOk() : setModalVisible!(true)} >
                <Plus />
                <p>{t('Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.add')} {selectedExercises.length} {selectedExercises.length === 1 ? t('Admin.data.menu.aiAssistant.aiAssistantPrograms.exercise') : t('Admin.data.menu.aiAssistant.aiAssistantPrograms.exercises')}</p>
              </div>
            }
          </div>
        )
      },
    ]}
  />
}