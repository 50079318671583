import './style.css';
import { Progress } from 'antd';

export const UploadBar = ({ uploadProgress }: { uploadProgress: number }) => {
	return (
		<div style={{ width: 110, display: 'flex', gap: 8 }}>
			<Progress
				percent={uploadProgress}
				strokeColor={{
					'0%': '#BDFF00',
					'100%': '#BDFF00',
				}}
				strokeWidth={12}
				showInfo={false}
				className="upload-progress-bar flex justify-center w-[90px]"
			/>
			<p className="text-white">{uploadProgress + '%'}</p>
		</div>
	);
};
