import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

type ButtonColorKeys = Exclude<Colors, "white" | "black">;
type ButtonColors = Record<ButtonColorKeys, string>;

type SizeKeys = "sm" | "md" | "lg" | "xl" | "2xl" | "full"
type Sizes = Record<SizeKeys, string>

type TButtonProps = {
  children: string | ReactNode
  onClick: () => void
  color?: ButtonColorKeys
  disabled?: boolean
  size?: SizeKeys
  ghost?: boolean
  icon?: JSX.Element
}

const colors: ButtonColors = {
  gray: 'bg-gray-600 hover:bg-gray-700 active:shadow-gray-100 text-white',
  "white-bg": 'bg-white hover:bg-gray-200 active:shadow-gray-100 text-gray-700 border-2',
  primary: 'bg-primary-600 hover:bg-primary-700 active:shadow-primary-100 text-white',
  error: 'bg-error-600 hover:bg-error-700 active:shadow-error-100 text-white',
  warning: 'bg-warning-600 hover:bg-warning-700 active:shadow-warning-100 text-white',
  success: 'bg-success-600 hover:bg-success-700 active:shadow-success-100 text-white',
  'gray-blue': 'bg-gray-blue-600 hover:bg-gray-blue-700 active:shadow-gray-blue-100 text-white',
  'gray-modern': 'bg-gray-modern-600 hover:bg-gray-modern-700 active:shadow-gray-modern-100 text-white',
  'gray-neutral': 'bg-gray-neutral-600 hover:bg-gray-neutral-700 active:shadow-gray-neutral-100 text-white',
  'gray-iron': 'bg-gray-iron-600 hover:bg-gray-iron-700 active:shadow-gray-iron-100 text-white',
  'gray-true': 'bg-gray-true-600 hover:bg-gray-true-700 active:shadow-gray-true-100 text-white',
  'gray-warm': 'bg-gray-warm-600 hover:bg-gray-warm-700 active:shadow-gray-warm-100 text-white',
  moss: 'bg-moss-600 hover:bg-moss-700 active:shadow-moss-100 text-white',
  'green-light': 'bg-green-light-600 hover:bg-green-light-700 active:shadow-green-light-100 text-white',
  green: 'bg-green-600 hover:bg-green-700 active:shadow-green-100 text-white',
  teal: 'bg-teal-600 hover:bg-teal-700 active:shadow-teal-100 text-white',
  cyan: 'bg-cyan-600 hover:bg-cyan-700 active:shadow-cyan-100 text-white',
  'blue-light': 'bg-blue-light-600 hover:bg-blue-light-700 active:shadow-blue-light-100 text-white',
  blue: 'bg-blue-600 hover:bg-blue-700 active:shadow-blue-100 text-white',
  'blue-dark': 'bg-blue-dark-600 hover:bg-blue-dark-700 active:shadow-blue-dark-100 text-white',
  indigo: 'bg-indigo-600 hover:bg-indigo-700 active:shadow-indigo-100 text-white',
  violet: 'bg-violet-600 hover:bg-violet-700 active:shadow-violet-100 text-white',
  purple: 'bg-purple-600 hover:bg-purple-700 active:shadow-purple-100 text-white',
  fuchsia: 'bg-fuchsia-600 hover:bg-fuchsia-700 active:shadow-fuchsia-100 text-white',
  pink: 'bg-pink-600 hover:bg-pink-700 active:shadow-pink-100 text-white',
  rose: 'bg-rose-600 hover:bg-rose-700 active:shadow-rose-100 text-white',
  'orange-dark': 'bg-orange-dark-600 hover:bg-orange-dark-700 active:shadow-orange-dark-100 text-white',
  orange: 'bg-orange-600 hover:bg-orange-700 active:shadow-orange-100 text-white',
  yellow: 'bg-yellow-400 hover:bg-yellow-500 active:shadow-yellow-100 text-white',
};

const ghostColors: ButtonColors = {
  gray: 'bg-white hover:bg-gray-50 active:shadow-gray-100 border border-gray-300 text-gray-700',
  "white-bg": 'bg-white hover:bg-gray-200 active:shadow-gray-100 text-gray-700 border-2',
  primary: 'bg-white hover:bg-primary-50 active:shadow-primary-100 border border-primary-300 text-primary-700',
  error: 'bg-white hover:bg-error-50 active:shadow-error-100 border border-error-300 text-error-700',
  warning: 'bg-white hover:bg-warning-50 active:shadow-warning-100 border border-warning-300 text-warning-700',
  success: 'bg-white hover:bg-success-50 active:shadow-success-100 border border-success-300 text-success-700',
  'gray-blue': 'bg-white hover:bg-gray-blue-50 active:shadow-gray-blue-100 border border-gray-blue-300 text-gray-blue-700',
  'gray-modern': 'bg-white hover:bg-gray-modern-50 active:shadow-gray-modern-100 border border-gray-modern-300 text-gray-700',
  'gray-neutral': 'bg-white hover:bg-gray-neutral-50 active:shadow-gray-neutral-100 border border-gray-neutral-300 text-gray-neutral-700',
  'gray-iron': 'bg-white hover:bg-gray-iron-50 active:shadow-gray-iron-100 border border-gray-iron-300 text-gray-iron-700',
  'gray-true': 'bg-white hover:bg-gray-true-50 active:shadow-gray-true-100 border border-gray-true-300 text-gray-true-700',
  'gray-warm': 'bg-white hover:bg-gray-warm-50 active:shadow-gray-warm-100 border border-gray-warm-300 text-gray-warn-700',
  moss: 'bg-white hover:bg-moss-50 active:shadow-moss-100 border border-moss-300 text-moss-700',
  'green-light': 'bg-white hover:bg-green-light-50 active:shadow-green-light-100 border border-green-light-300 text-green-light-700',
  green: 'bg-white hover:bg-green-50 active:shadow-green-100 border border-green-300 text-green-700',
  teal: 'bg-white hover:bg-teal-50 active:shadow-teal-100 border border-teal-300 text-teal-700',
  cyan: 'bg-white hover:bg-cyan-50 active:shadow-cyan-100 border border-cyan-300 text-cyan-700',
  'blue-light': 'bg-white hover:bg-blue-light-50 active:shadow-blue-light-100 border border-blue-light-300 text-blue-light-700',
  blue: 'bg-white hover:bg-blue-50 active:shadow-blue-100 border border-blue-300 text-blue-700',
  'blue-dark': 'bg-white hover:bg-blue-dark-50 active:shadow-blue-dark-100 border border-blue-dark-300 text-blue-dark-700',
  indigo: 'bg-white hover:bg-indigo-50 active:shadow-indigo-100 border border-indigo-300 text-indigo-700',
  violet: 'bg-white hover:bg-violet-50 active:shadow-violet-100 border border-violet-300 text-violet-700',
  purple: 'bg-white hover:bg-purple-50 active:shadow-purple-100 border border-purple-300 text-purple-700',
  fuchsia: 'bg-white hover:bg-fuchsia-50 active:shadow-fuchsia-100 border border-fuchsia-300 text-fuchsia-700',
  pink: 'bg-white hover:bg-pink-50 active:shadow-pink-100 border border-pink-300 text-pink-700',
  rose: 'bg-white hover:bg-rose-50 active:shadow-rose-100 border border-rose-300 text-rose-700',
  'orange-dark': 'bg-white hover:bg-orange-dark-50 active:shadow-orange-dark-100 border border-orange-dark-300 text-orange-dark-700',
  orange: 'bg-white hover:bg-orange-50 active:shadow-orange-100 border border-orange-300 text-orange-700',
  yellow: 'bg-white hover:bg-yellow-50 active:shadow-yellow-100 border border-yellow-300 text-yellow-700',
};

const colorsDisabled: ButtonColors = {
  gray: 'bg-gray-200',
  "white-bg": 'bg-white text-gray-700 border-2',
  primary: 'bg-primary-200',
  error: 'bg-error-200',
  warning: 'bg-warning-200',
  success: 'bg-success-200',
  'gray-blue': 'bg-gray-blue-200',
  'gray-modern': 'bg-gray-modern-200',
  'gray-neutral': 'bg-gray-neutral-200',
  'gray-iron': 'bg-gray-iron-200',
  'gray-true': 'bg-gray-true-200',
  'gray-warm': 'bg-gray-warm-200',
  moss: 'bg-moss-200',
  'green-light': 'bg-green-light-200',
  green: 'bg-green-200',
  teal: 'bg-teal-200',
  cyan: 'bg-cyan-200',
  'blue-light': 'bg-blue-light-200',
  blue: 'bg-blue-200',
  'blue-dark': 'bg-blue-dark-200',
  indigo: 'bg-indigo-200',
  violet: 'bg-violet-200',
  purple: 'bg-purple-200',
  fuchsia: 'bg-fuchsia-200',
  pink: 'bg-pink-200',
  rose: 'bg-rose-200',
  'orange-dark': 'bg-orange-dark-200',
  orange: 'bg-orange-200',
  yellow: 'bg-yellow-200',
};

const ghostColorsDisabled: ButtonColors = {
  gray: 'bg-gray-200',
  "white-bg": 'bg-white text-gray-700 border-2',
  primary: 'bg-primary-200',
  error: 'bg-error-200',
  warning: 'bg-warning-200',
  success: 'bg-success-200',
  'gray-blue': 'bg-gray-blue-200',
  'gray-modern': 'bg-gray-modern-200',
  'gray-neutral': 'bg-gray-neutral-200',
  'gray-iron': 'bg-gray-iron-200',
  'gray-true': 'bg-gray-true-200',
  'gray-warm': 'bg-gray-warm-200',
  moss: 'bg-moss-200',
  'green-light': 'bg-green-light-200',
  green: 'bg-green-200',
  teal: 'bg-teal-200',
  cyan: 'bg-cyan-200',
  'blue-light': 'bg-blue-light-200',
  blue: 'bg-blue-200',
  'blue-dark': 'bg-blue-dark-200',
  indigo: 'bg-indigo-200',
  violet: 'bg-violet-200',
  purple: 'bg-purple-200',
  fuchsia: 'bg-fuchsia-200',
  pink: 'bg-pink-200',
  rose: 'bg-rose-200',
  'orange-dark': 'bg-orange-dark-200',
  orange: 'bg-orange-200',
  yellow: 'bg-yellow-200',
};

const sizes: Sizes = {
  sm: "px-[14px] py-2 text-sm",
  md: "px-4 py-[10px] text-sm",
  lg: "px-[18px] py-[10px] text-md",
  xl: "px-5 py-3 text-md",
  "2xl": "px-7 py-4 text-lg",
  full: 'w-full p-2'
}


function Button(props: TButtonProps) {
  const { children, color = 'primary', onClick, disabled = false, size = "md", ghost = false, icon } = props
  
  const sizesCss = sizes[size as keyof Sizes]
  const colorsCss = disabled ? colorsDisabled[color as keyof ButtonColors] : colors[color as keyof ButtonColors]
  const ghostCss = disabled ? ghostColorsDisabled[color as keyof ButtonColors] : ghostColors[color as keyof ButtonColors]
  
  let css = 'font-semibold duration-100 rounded-lg active:shadow-button'
  css = twJoin(css, ghost ? ghostCss : colorsCss, sizesCss)
    
  if (icon) {
    return ( 
      <button className={css} onClick={onClick}>
        <div className="flex items-center space-x-2">
          <div className="flex-none">
            {icon}
          </div>
          <div className="flex-initial">
          {children}
          </div>
        </div>
      </button>
    )
  }

  return ( 
    <button className={css} onClick={onClick}  disabled={disabled}>
      {children}
    </button>
  )
}

export default Button