import { useTranslation } from 'react-i18next';
import './style.less';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { PostureCaptureData } from './PostureCaptureData';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getPostureByPage } from '@stores/postures/postures';

export const PostureCaptures = () => {
	const [activeKey, setActiveKey] = useState('1');
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);

	useEffect(() => {
		handlePostureGetApi(1);
	}, [user, selectedUser, dispatch]);

	const handlePostureGetApi = async (page: number) => {
		await dispatch(
			getPostureByPage({
				userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
				page,
			}),
		);
	};

	return (
		<div className="posture-captures">
		<div className="posture-card-container" style={{backgroundColor:'#fff', paddingLeft:'16px',paddingRight:'16px', borderRadius:'8px'}}>
			<Tabs
				defaultActiveKey={activeKey}
				className="tabs"
				activeKey={activeKey}
				onChange={value => setActiveKey(value)}
				items={[
					{
						label: 'Front',
						key: '1',
						children: <PostureCaptureData side={'front'} />,
					},
					{
						label: 'Back',
						key: '2',
						children: <PostureCaptureData side={'back'} />,
					},
					{
						label: 'Left',
						key: '3',
						children: <PostureCaptureData side={'left'} />,
					},
					{
						label: 'Right',
						key: '4',
						children: <PostureCaptureData side={'right'} />,
					},
				]}
			/>
			</div>
		</div>
	);
};
