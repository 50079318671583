import { VoiceControl } from '@atoms/ARehabPreAssesment/VoiceControl';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ETransitions, IRomResultDataProps } from '@stores/interfaces';
import { repetingExercise } from '@stores/rom/main';
import { Button, Col, Divider, Row, Tag, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	MdCheckCircleOutline,
	MdFullscreen,
	MdFullscreenExit,
	MdHighlightOff,
	MdOutlinePauseCircle,
	MdOutlinePlayCircle,
	MdOutlineSwitchCamera,
	MdOutlineVideocam,
} from 'react-icons/md';
import { TbWindowMaximize, TbWindowMinimize } from 'react-icons/tb';

export default function RomResultData(props: IRomResultDataProps) {
	const {
		isCompleted,
		isManual,
		onSavePhysicalAssessmentsMetrics,
		onFullscreen,
		onTogglesSwitchMode,
		flipCamera,
		switchCamera,
		isSwitchMode,
		isSplashOpened,
		isVideoPause,
		onTogglePauseVideo,
		onToggleTutorial,
		isFullscreen,
		transitionTime,
	} = props;
	const [isHoverTryAgain, setHoverTryAgain] = useState(false);
	const [isHoverContinue, setHoverContinue] = useState(false);
	const [savedVoice, setSavedVoice] = useState('');
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const { metricsData, transition, finishedExercises } = useTypedSelector(
		state => state.rom.main,
	);
	const onRepeatExercise = () => {
		dispatch(repetingExercise(true));
	};

	useEffect(() => {
		const lowerVoice = savedVoice?.toLowerCase();
		if (transition?.value == ETransitions.RESULT && lowerVoice) {
			if (
				lowerVoice == 'discard' ||
				lowerVoice == 'try again' ||
				lowerVoice == 'no'
			) {
				onRepeatExercise();
			} else if (lowerVoice == 'submit' || lowerVoice == 'yes') {
				onSavePhysicalAssessmentsMetrics();
			}
		}
	}, [savedVoice]);

	VoiceControl((updatedTranscript: string) => {
		setSavedVoice(updatedTranscript);
	});

	return (
		<Col span={12}>
			<Row
				justify="end"
				style={{ textAlign: 'end', paddingRight: 8, lineHeight: '35px' }}>
				<Col
					span={24}
					style={{
						height: '35px',
						display: 'flex',
						justifyContent: 'end',
						alignItems: 'center',
					}}>
					<Tag color="purple">{t('Patient.data.omnirom.betaRelease')}</Tag>
					{isManual &&
						!isCompleted &&
						transition?.value == ETransitions.RESULT && (
							<>
								{metricsData?.screenshot && (
									<>
										<Button
											onMouseEnter={() => {
												setHoverTryAgain(true);
											}}
											onMouseLeave={() => {
												setHoverTryAgain(false);
											}}
											onClick={onRepeatExercise}
											style={{
												height: '35px',
												border: 0,
												borderRadius: 0,
												backgroundColor: isHoverTryAgain
													? '#f12760'
													: '#E31B54',
												color: '#ffffff',
											}}
											icon={
												<MdHighlightOff
													size={22}
													style={{
														verticalAlign: 'middle',
														marginRight: 5,
													}}
													color="#ffffff"
												/>
											}>
											{t('Patient.data.omnirom.tryAgain')}
										</Button>
										<Button
											onMouseEnter={() => {
												setHoverContinue(true);
											}}
											onMouseLeave={() => {
												setHoverContinue(false);
											}}
											style={{
												height: '35px',
												border: 0,
												borderRadius: 0,
												backgroundColor: isHoverContinue
													? '#31d373'
													: '#01c752',
												color: '#ffffff',
											}}
											onClick={() => {
												onSavePhysicalAssessmentsMetrics();
											}}
											icon={
												<MdCheckCircleOutline
													size={22}
													style={{
														verticalAlign: 'middle',
														marginRight: 5,
													}}
													color="#ffffff"
												/>
											}>
											{t('Patient.data.omnirom.submitToConitinue')}
										</Button>
										<Divider
											type="vertical"
											style={{ height: '34px', borderColor: '#1B1B1B' }}
										/>
									</>
								)}
							</>
						)}
					{!isSplashOpened &&
						transition?.value != ETransitions.RESULT &&
						transitionTime && (
							<div>
								{isVideoPause ? (
									<Tooltip title={t('Patient.data.omnirom.pause')}>
										<MdOutlinePlayCircle
											size={30}
											style={{
												display: 'flex',
												verticalAlign: 'middle',
												cursor: 'pointer',
											}}
											onClick={onTogglePauseVideo}
											color="#5e5e5e"
										/>
									</Tooltip>
								) : (
									<Tooltip title={t('Patient.data.omnirom.play')}>
										<MdOutlinePauseCircle
											size={30}
											style={{
												display: 'flex',
												verticalAlign: 'middle',
												cursor: 'pointer',
											}}
											onClick={onTogglePauseVideo}
											color="#5e5e5e"
										/>
									</Tooltip>
								)}
							</div>
						)}
					<Divider
						type="vertical"
						style={{ height: '34px', borderColor: '#1B1B1B' }}
					/>
					<Tooltip title={t('Patient.data.omnirom.tutorial')}>
						<MdOutlineVideocam
							size={30}
							style={{
								verticalAlign: 'middle',
								cursor: 'pointer',
							}}
							onClick={onToggleTutorial}
							color="#5e5e5e"
						/>
					</Tooltip>
					<Divider
						type="vertical"
						style={{ height: '34px', borderColor: '#1B1B1B' }}
					/>
					<Tooltip title={t('Patient.data.omnirom.fullScreen')}>
						{isFullscreen ? (
							<MdFullscreenExit
								size={30}
								style={{
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
								onClick={onFullscreen}
								color="#5e5e5e"
							/>
						) : (
							<MdFullscreen
								size={30}
								style={{
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
								onClick={onFullscreen}
								color="#5e5e5e"
							/>
						)}
					</Tooltip>

					{!finishedExercises?.finished && !isCompleted && (
						<>
							{!isSplashOpened && (
								<>
									{/* {flipCamera && ( */}
										<>
											<Divider
												type="vertical"
												style={{ height: '34px', borderColor: '#1B1B1B' }}
											/>
											<Tooltip title={t('Patient.data.omnirom.flipCamera')}>
												<MdOutlineSwitchCamera
													size={30}
													style={{
														verticalAlign: 'middle',
														cursor: 'pointer',
													}}
													onClick={switchCamera}
													color="#5e5e5e"
												/>
											</Tooltip>
										</>
									{/* )} */}
									<Divider
										type="vertical"
										style={{ height: '34px', borderColor: '#1B1B1B' }}
									/>
									<Tooltip title={t('Patient.data.omnirom.switchVideo')}>
										{isSwitchMode ? (
											<TbWindowMaximize
												size={30}
												style={{
													verticalAlign: 'middle',
													cursor: 'pointer',
												}}
												onClick={onTogglesSwitchMode}
												color="#5e5e5e"
											/>
										) : (
											<TbWindowMinimize
												size={30}
												style={{
													verticalAlign: 'middle',
													cursor: 'pointer',
												}}
												onClick={onTogglesSwitchMode}
												color="#5e5e5e"
											/>
										)}
									</Tooltip>
								</>
							)}
						</>
					)}
				</Col>
			</Row>
		</Col>
	);
}
