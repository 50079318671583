import { useState, useEffect, useRef } from 'react';
import { Typography } from 'antd';

interface ICountDownTimerProps {
	limit: number;
	isStartingTimer: boolean;
	endedTimer: () => void;
	setMilliseconds: (value: number) => void;
	milliseconds: number;
}

const formatTime = (milliseconds: number) => {
	const seconds = Math.floor(milliseconds / 1000);
	return seconds.toString();
};

const CountDownTimerOnScreen = (props: ICountDownTimerProps) => {
	const { limit, isStartingTimer, endedTimer, milliseconds, setMilliseconds } =
		props;

	const intervalRef = useRef<null | NodeJS.Timeout>(null);

	useEffect(() => {
		if (isStartingTimer) {
			setMilliseconds(limit);

			intervalRef.current = setInterval(() => {
				setMilliseconds((prev: number) => prev - 1000);
			}, 1000);

			return () => {
				if (intervalRef.current !== null) {
					clearInterval(intervalRef.current);
				}
			};
		} else {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
			}
		}
	}, [isStartingTimer, limit]);

	useEffect(() => {
		if (milliseconds <= 0 && intervalRef.current) {
			clearInterval(intervalRef.current);
			endedTimer();
		}
	}, [milliseconds, endedTimer]);

	return (
		<Typography.Text
			style={{ color: 'white', fontWeight: 'bold', fontSize: '60px' }}>
			{formatTime(milliseconds)}
		</Typography.Text>
	);
};

export default CountDownTimerOnScreen;
