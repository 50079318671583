import moment from 'moment';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import { useTranslation } from 'react-i18next';
import { Button, Empty, Flex, Popover, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InboxDataMessage, SelectedUser } from '@stores/interfaces';
import { router } from '@routers/routers';

interface IAdminMenuActivityDataProps {
	selectedItem: SelectedUser | null;
	inboxLoading: boolean
	inboxData: InboxDataMessage[];
	searchQuery: string;
	setSearchQuery: (value: string) => void;
	handleInboxChange: (selected: SelectedUser) => void;
}

export default function AdminMenuActivityData(
	props: IAdminMenuActivityDataProps,
) {
	const {
		selectedItem,
		inboxLoading,
		inboxData,
		searchQuery,
		setSearchQuery,
		handleInboxChange,
	} = props;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const filteredPatientList = inboxData?.filter(item => {
		const firstName = item.profile.firstName ?? '';
		const email = item.profile.email ?? '';

		return (
			firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
			email.toLowerCase().includes(searchQuery.toLowerCase())
		);
	});

	const handleClick = (url: string) => {
		navigate(url);
		setSearchQuery('');
	};

	return (
		<>
			<div>
				{selectedItem && (
					<div className="activity-container">
						<Popover
							title=""
							content="Back"
							placement="left"
							className="inline-block">
							<Button
								className="logout-button"
								shape="circle"
								icon={<ArrowLeft />}
								type="text"
								onClick={() => handleClick(router.SELECTACTIVITYUSER)}
							/>
						</Popover>
					</div>
				)}

				<Flex
					align="center"
					justify="space-between"
					className="flex-container-message">
					<h3> {t('Admin.data.inbox.inbox')}</h3>
					<Button
						onClick={() => handleClick(router.SELECTUSER)}
						ghost={true}
						size="small">
						New Message
					</Button>
				</Flex>
				{inboxLoading ? 
				<Spin className="spin-css spin-css-inbox" tip="Loading" size="large" />
				:
				<div className="scroll-messagebox">
					{filteredPatientList?.length > 0 ? (
						filteredPatientList.map((item: InboxDataMessage, index: number) => (
							<div key={index} style={{ borderWidth: '0px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginBottom: '10px',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginTop: '8px',
										cursor: 'pointer',
									}}
									onClick={() => {
										handleInboxChange(item.id.toString());
									}}>
									<div className="inbox-item-info">
										<p className="name-label">
											{item.profile.firstName} {item.profile.lastName}
										</p>
										<p
											className="email-label"
											style={{
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												maxWidth: '170px',
											}}>
											{item.profile.email}
										</p>
									</div>
									<div className="inbox-item-time">
										<p
											className="name-label"
											style={{
												color: '#fff',
												textAlign: 'right',
											}}>
											{moment(item.lastMessage).local().format('LT')}
										</p>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-end',
											}}>
											<p className="inbox-item-unread">{item.unread}</p>
										</div>
									</div>
								</div>
								<hr
									style={{
										borderTopWidth: '1px',
										backgroundColor:
											'rgb(214 187 251 / var(--tw-border-opacity))',
									}}
								/>
							</div>
						))
					) : (
						<div className="empty-container">
							<Empty
								image="/images/emptyMessages.svg"
								imageStyle={{
									height: 65,
									display: 'inline-block',
									marginTop: '10%',
								}}
								description={
									<>
										<p style={{ color: '#fff' }}>Nice work!</p>
										<p style={{ color: '#fff' }}>You’re all caught up.</p>
									</>
								}></Empty>
						</div>
					)}
				</div>}
			</div>
		</>
	);
}
