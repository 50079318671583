import Timer from '@atoms/APTransitionTimer';
import {
	ICustomRomExercise,
	ResultExerciseValidation,
} from '@stores/interfaces';
import './style.css';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';

interface ISingleResultProps {
	exerciseName: string;
	enableToSendResult?: boolean;
	isPaused?: boolean;
	onNextTransition: () => void;
	resultExerciseValidation?: ResultExerciseValidation | null;
}

function SingleResult(props: ISingleResultProps) {
	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 3,
				width: '100%',
				height: '100%',
				background:
					'linear-gradient(130deg, rgba(166, 126, 255, 0.84) 0%, rgba(89, 101, 255, 0.84) 100%)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				color: '#FFF',
			}}>
			<div style={{ width: '50%' }}>
				<>
					<Typography.Title level={4} style={{ color: '#FFF' }}>
						COMPLETED
					</Typography.Title>
					<Typography.Title
						level={3}
						style={{
							color: '#FFF',
							backgroundColor: '#03c852',
							padding: 10,
							borderRadius: '50px',
						}}>
						{`${props.exerciseName?.toUpperCase()} \u00a0\u00a0\u00a0`}
					</Typography.Title>
					<Timer
						isPaused={props.isPaused!}
						onTimerCountDownFinish={props.onNextTransition}
					/>
				</>
			</div>
		</div>
	);
}

export default SingleResult;
