import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { limitPagination, pagination } from '@stores/constants';
import { IStatusStoreState, ISessionResponsePayload, IUpdateSessionStatusResponsePayload, IUpdateStatusBody, IUserPaginationDefaultResponse, Status, IUpdateProgramStatusResponsePayload, IUpdateEvaluationStatusResponsePayload } from '@stores/interfaces';
import axios from 'axios';

const initialState: IStatusStoreState = {
  omnirom: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  rehab: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  evaluation: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  survey: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  },
  postures: {
    users: [],
    pagination: { ...pagination },
    statusToRefresh: []
  }
}

export const getOmniRomPendingReview = createAsyncThunk(
  'rom/pendingReview',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/rom/sessions/status/${Status.pendingReview}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateOmniRomSessionStatus = createAsyncThunk(
  'rom/pendingReview/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/rom/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getRehabPendingReview = createAsyncThunk(
  'rehab/pendingReview',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/program/sessions/status/${Status.pendingReview}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateRehabSessionStatus = createAsyncThunk(
  'rehab/pendingReview/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params    
    const { data } = await axios.patch(`/program/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getEvaluationPendingReview = createAsyncThunk(
  'evaluation/pendingReview',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/evaluation/sessions/status/${Status.pendingReview}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateEvaluationSessionStatus = createAsyncThunk(
  'evaluation/pendingReview/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params    
    const { data } = await axios.patch(`/evaluation/sessions/${sessionId}/status`, body)
    return data
  }
)

export const getSurveyPendingReview = createAsyncThunk(
  'survey/pendingReview',
  async (pagination: Partial<IUserPaginationDefaultResponse>) => {
    const { nextPage } = pagination
    const { data } = await axios.get(
      `/survey/sessions/status/${Status.pendingReview}/users?page=${nextPage}&limit=${limitPagination}`
    )
    return data
  }
)

export const updateSurveySessionStatus = createAsyncThunk(
  'survey/pendingReview/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params    
    const { data } = await axios.patch(`/survey/sessions/${sessionId}/status`, body)
    return data
  }
)

export const updatePostureSessionStatus = createAsyncThunk(
  'posture-analytics/pendingReview/updateSessionStatus',
  async (params: IUpdateStatusBody) => {
    const { sessionId, body } = params
    const { data } = await axios.patch(`/posture-analytics/sessions/${sessionId}/status`, body)
    return data
  }
)

export const pendingReviewSlice = createSlice({
  name: 'pendingReview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOmniRomPendingReview.pending, (state) => {
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(getOmniRomPendingReview.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.omnirom.users = action.payload.data
      state.omnirom.pagination = action.payload.pagination
      state.omnirom.statusToRefresh = []
    })

    builder.addCase(updateOmniRomSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateSessionStatusResponsePayload>) => {
      state.omnirom.statusToRefresh = [action.payload.status, Status.pendingReview]
    })

    builder.addCase(getRehabPendingReview.pending, (state) => {
      state.rehab.statusToRefresh = []
    })

    builder.addCase(getRehabPendingReview.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.rehab.users = action.payload.data
      state.rehab.pagination = action.payload.pagination
      state.rehab.statusToRefresh = []
    })

    builder.addCase(updateRehabSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateProgramStatusResponsePayload>) => {
      state.rehab.statusToRefresh = [action.payload.status, Status.pendingReview]
    }) 

    builder.addCase(getEvaluationPendingReview.pending, (state) => {
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(getEvaluationPendingReview.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.evaluation.users = action.payload.data
      state.evaluation.pagination = action.payload.pagination
      state.evaluation.statusToRefresh = []
    })

    builder.addCase(updateEvaluationSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateEvaluationStatusResponsePayload>) => {
      state.evaluation.statusToRefresh = [action.payload.status, Status.pendingReview]
    })

    builder.addCase(getSurveyPendingReview.pending, (state) => {
      state.survey.statusToRefresh = []
    })

    builder.addCase(getSurveyPendingReview.fulfilled, (state, action: PayloadAction<ISessionResponsePayload>) => {
      state.survey.users = action.payload.data
      state.survey.pagination = action.payload.pagination
      state.survey.statusToRefresh = []
    })

    builder.addCase(updateSurveySessionStatus.fulfilled, (state, action: PayloadAction<IUpdateEvaluationStatusResponsePayload>) => {
      state.survey.statusToRefresh = [action.payload.status, Status.pendingReview]
    })

    builder.addCase(updatePostureSessionStatus.fulfilled, (state, action: PayloadAction<IUpdateEvaluationStatusResponsePayload>) => {
      state.postures.statusToRefresh = [action.payload.status, Status.pendingReview]
    })
  }
})

export default pendingReviewSlice.reducer