import { useState } from 'react'
import PatientPainStatus from './PatientPainStatus'
import OnboardProfileForm from './OnboardProfileForm'
import AssociatedSymptoms from './AssociatedSymptoms'
import MedicalHistory from './MedicalHistory'
import FunctionalGoals from './FunctionalGoals'
import PainAssessment from './PainAssessment'
import { useTypedSelector } from '@stores/index'
import WholeDayActivity from './WholeDayActivity'
import ProgressBar from './ProgressBar'
import RangeOfMotion from './RangeOfMotion/RangeOfMotion'
import PostureAnalysis from './PostureAnalysis/PostureAnalysis'
import PreExistingCondition from './PreExistingCondition'

export default function PatientOnboard({ onComplete }: { onComplete: () => void }) {
  const [activeStep, setActiveStep] = useState(1);
  const savedActiveStep = useTypedSelector((state) => state.onBoard.onBoard.activeStep)
  const [progressPercent, setProgressPercent] = useState(10);
  const painStatusButton = useTypedSelector((state) => state.onBoard.onBoard.painStatusButton)

  const handleProgressPercent = (value: number) => {
    if(value > progressPercent) setProgressPercent(value)
  }

  const handleStepCompletion = () => {
    if (activeStep === 9) {
      onComplete();
    } else {
      setActiveStep((prev) => prev + 1);
      setProgressPercent((prev) => prev + 10);
    }
  };

  return (
    <>
      <ProgressBar progressPercent={progressPercent} />
      <div className='mt-16 select-none'>
        <div className='mt-6'>
          {activeStep === 1 && (<OnboardProfileForm setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {activeStep === 2 && (<PatientPainStatus setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {activeStep === 3 && (<PainAssessment setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {activeStep === 4 && (<AssociatedSymptoms setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {(activeStep === 5 && painStatusButton !== 'noPain') && (<MedicalHistory setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {(activeStep === 5 && painStatusButton === 'noPain') && (<PreExistingCondition setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {activeStep === 6 && (<FunctionalGoals setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {activeStep === 7 && (<WholeDayActivity setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {(activeStep === 8 && savedActiveStep != 9) && (<RangeOfMotion setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} />)}
          {(savedActiveStep === 9 || activeStep === 9) && (<PostureAnalysis setActiveStep={setActiveStep} setProgressPercent={handleProgressPercent} onComplete={handleStepCompletion} />)}
        </div>
      </div>
    </>
  )
}
